export type AttachmentDownloadStatus =
  | 'FAILED'
  | 'NOT_STARTED'
  | 'PENDING'
  | 'SUCCESSFUL';

export const AttachmentDownloadStatus = {
  FAILED: 'FAILED' as AttachmentDownloadStatus,
  NOT_STARTED: 'NOT_STARTED' as AttachmentDownloadStatus,
  PENDING: 'PENDING' as AttachmentDownloadStatus,
  SUCCESSFUL: 'SUCCESSFUL' as AttachmentDownloadStatus
};

export interface ConversationMessageAttachment {
  id?: string;
  name?: string;
  size?: number;
  link?: string;
  mimeType?: string;
  data?: any;
  downloadStatus?: AttachmentDownloadStatus;
  isImage?: boolean;
}
