import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateModule
} from '@ngx-translate/core';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { CustomTranslationHandler } from '@sofico-framework/utils';
import { VehiclesAuthorizationGuard } from '../guards/vehicles-authorization.guard';
import { BookingService } from '../services/booking.service';
import { LoadingSpinnerModule } from '../shared-ui/components/loading-spinner/loading-spinner.module';
import { VehicleSearchModule } from '../shared-ui/components/vehicle-search/vehicle-search.module';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { VehicleManagementRoutingModule } from './vehicle-management-routing.module';
import { VehicleManagementSandbox } from './vehicle-management.sandbox';
import { CopyVehicleDialogViewComponent } from './views/copy-vehicle-dialog-view/copy-vehicle-dialog-view.component';
import { CreateVehicleDialogViewComponent } from './views/create-vehicle-dialog-view/create-vehicle-dialog-view.component';
import { EditVehicleDialogViewComponent } from './views/edit-vehicle-dialog-view/edit-vehicle-dialog-view.component';
import { VehiclesViewComponent } from './views/vehicles-view/vehicles-view.component';

@NgModule({
  declarations: [
    VehiclesViewComponent,
    EditVehicleDialogViewComponent,
    CreateVehicleDialogViewComponent,
    CopyVehicleDialogViewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    VehicleManagementRoutingModule,
    LoadingSpinnerModule,
    VehicleSearchModule,
    SvgIconModule,
    SharedUiModule
  ],
  exports: [VehiclesViewComponent],
  providers: [
    VehicleManagementSandbox,
    BookingService,
    VehiclesAuthorizationGuard
  ]
})
export class VehicleManagementModule {}
