import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { switchMap } from 'rxjs/operators';
import { CreateDriverGradeRequestDto } from  '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-create-driver-grade-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.CREATE-DRIVER-GRADE' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="driverGradeForm" class="flex-fill">
            <sof-input-wrapper [label]="tc + '.DRIVER-GRADE-NAME' | translate">
              <sof-input-text
                sofFocus
                [sofInput]
                [formControl]="driverGradeForm?.controls?.name"
              >
              </sof-input-text>
            </sof-input-wrapper>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class CreateDriverGradeDialogViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_CREATE-DRIVER-GRADE-DIALOG';
  driverGradeForm: FormGroup = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private organizationViewHelper: OrganizationViewHelper
  ) {}

  onSaveClick(): void {
    if (!this.driverGradeForm.valid) {
      return;
    }

    this.organizationViewHelper.organizationId$
      .pipe(
        switchMap(organizationId => {
          const createDriverGradeRequestDto: CreateDriverGradeRequestDto = {
            driverGradeName: this.driverGradeForm.value.name
          };
          return this.osb.createDriverGrade(
            organizationId,
            createDriverGradeRequestDto
          );
        }),
        takeUntilDestroy(this)
      )
      .subscribe(
        driverGrade => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_CREATE-DRIVER-GRADE',
            true
          );
          this.router
            .navigate(['..'], {
              relativeTo: this.activatedRoute
            })
            .then(() => {
              this.organizationViewHelper.triggerOrganization();
            });
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_CREATE-DRIVER-GRADE'
          );
        }
      );
  }
}
