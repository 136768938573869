export interface BookingColors {
  border: string;
  background: string;
  text: string;
}

export type BookingColorKey =
  | 'default'
  | 'awaiting-approval'
  | 'approved'
  | 'rejected'
  | 'ready'
  | 'active'
  | 'overdue'
  | 'canceled'
  | 'expired'
  | 'stopped';

export const BookingColorKey = {
  DEFAULT: 'default' as BookingColorKey,
  AWAITINGAPPROVAL: 'awaiting-approval' as BookingColorKey,
  APPROVED: 'approved' as BookingColorKey,
  REJECTED: 'rejected' as BookingColorKey,
  READY: 'ready' as BookingColorKey,
  ACTIVE: 'active' as BookingColorKey,
  OVERDUE: 'overdue' as BookingColorKey,
  CANCELED: 'canceled' as BookingColorKey,
  EXPIRED: 'expired' as BookingColorKey,
  STOPPED: 'stopped' as BookingColorKey
};
