import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastUtilService } from '../../../services/toast-util.service';
import { SelectionListOption } from '../../../shared-ui/components/selection-list/types/selection-list-option.type';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationMemberDetailViewHelper } from '../../view-helpers/organization-member-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-member-add-driver-grade-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.MEMBER-ADD-DRIVER-GRADE' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body class="dialog-content">
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-selection-list
            class="flex-fill"
            [multiple]="false"
            [options]="(vh.organization$ | async)?.driverGrades"
            (selectionChange)="onSelectionChange($event)"
          >
            <ng-template let-driverGrade #option>
              <div class="flex-fill">
                {{ driverGrade.name }}
              </div>
            </ng-template>
          </sof-selection-list>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
            [disabled]="!selectedDriverGradeId"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./member-add-driver-grade-dialog-view.component.scss']
})
export class MemberAddDriverGradeDialogViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_MEMBER-ADD-DRIVER-GRADE-DIALOG';
  selectedDriverGradeId: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    public vh: OrganizationMemberDetailViewHelper
  ) {}

  onSelectionChange(option: SelectionListOption): void {
    this.selectedDriverGradeId = option.value.id;
  }

  onSaveClick(): void {
    combineLatest([this.vh.organizationId$, this.vh.userId$])
      .pipe(
        switchMap(([organizationId, userId]) =>
          this.osb.addUserToDriverGrade(
            this.selectedDriverGradeId,
            organizationId,
            userId
          )
        ),
        takeUntilDestroy(this)
      )
      .subscribe(
        result => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_ADD-DRIVER-GRADE',
            true
          );
          this.router
            .navigate(['..'], {
              relativeTo: this.activatedRoute
            })
            .then(() => {
              this.vh.triggerOrganization();
            });
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_ADD-DRIVER-GRADE'
          );
        }
      );
  }
}
