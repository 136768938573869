import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class ValidatorUtils {
  public static getValidatorEmailAddress(): ValidatorFn {
    return (c: AbstractControl) => {
      const emailRegEx: RegExp = RegExp(
        '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}'
      );
      if (emailRegEx.test(c.value)) {
        return null;
      }
      return {
        email: true
      };
    };
  }
}
