import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingDto } from  '../../../../../client';
import { SchedulerViewHelper } from '../../view-helpers/scheduler-view.helper';

@Component({
  selector: 'sof-scheduler-edit-booking-dialog-view',
  template: `
    <ng-container>
      <sof-booking-dialog
        *ngIf="isVisible"
        [bookingDialogData]="schedulerViewHelper.bookingDialogData"
        (cancelDialog)="onCancelDialog()"
        (closeDialogAfterSave)="onCloseDialogAfterSave($event)"
      ></sof-booking-dialog>
    </ng-container>
  `
})
export class SchedulerEditBookingDialogViewComponent implements OnInit {
  isVisible = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public schedulerViewHelper: SchedulerViewHelper
  ) {}

  ngOnInit(): void {
    if (!this.schedulerViewHelper.bookingDialogData?.remoteBookingId) {
      this.onCancelDialog();
    } else {
      this.isVisible = true;
    }
  }

  onCancelDialog(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  onCloseDialogAfterSave(bookingDto: BookingDto): void {
    this.router
      .navigate(['..'], { relativeTo: this.activatedRoute })
      .then(() => {
        this.schedulerViewHelper.triggerRefreshAvailabilityEvents();
      });
  }
}
