/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NonAvailabilityDtoType = 'BLOCKED' | 'BOOKING' | 'NOT_ALLOWED' | 'NOT_AVAILABLE';

export const NonAvailabilityDtoType = {
    BLOCKED: 'BLOCKED' as NonAvailabilityDtoType,
    BOOKING: 'BOOKING' as NonAvailabilityDtoType,
    NOTALLOWED: 'NOT_ALLOWED' as NonAvailabilityDtoType,
    NOTAVAILABLE: 'NOT_AVAILABLE' as NonAvailabilityDtoType
};

