import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BatteryStatusWithDetail } from '../../../types/battery-status-with-detail.type';

@Component({
  selector: 'sof-battery-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="battery-status" [ngClass]="batteryStatus.batteryStatusClasses">
      <sof-battery-status-icon
        [batteryStatus]="batteryStatus"
      ></sof-battery-status-icon>
      <div *ngIf="batteryStatus.inError" class="battery-status-text-error">
        {{ tc + '.BATTERY-STATUS-ERROR' | translate }}
      </div>
      <div
        *ngIf="!batteryStatus.inError"
        class="battery-status-text-percentage"
      >
        {{ batteryStatus?.batteryPercentage }}%
      </div>
      <nz-progress
        *ngIf="!batteryStatus.inError"
        [nzPercent]="batteryStatus?.batteryPercentage"
        [nzShowInfo]="false"
      ></nz-progress>
    </div>
  `,
  styleUrls: ['./battery-status.component.scss']
})
export class BatteryStatusComponent {
  @Input() tc: string;
  @Input() batteryStatus: BatteryStatusWithDetail;
}
