import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-result-found-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="results-found-label" *ngIf="resultCount === 0">
        {{ tc + '.' + noResultLabel | translate }}
      </div>
      <div class="results-found-label" *ngIf="resultCount === 1">
        {{
          tc + '.' + oneResultLabel | translate: { resultCount: resultCount }
        }}
      </div>
      <div class="results-found-label" *ngIf="resultCount > 1">
        {{
          tc + '.' + multipleResultsLabel
            | translate: { resultCount: resultCount }
        }}
      </div>
    </ng-container>
  `,
  styleUrls: ['./results-found-label.component.scss']
})
export class ResultsFoundLabelComponent {
  @Input() tc: string;
  @Input() noResultLabel: string;
  @Input() oneResultLabel: string;
  @Input() multipleResultsLabel: string;
  @Input() resultCount: number;
}
