import { ActionTypes } from '../action-types';
import { ActiveSchedulerFilters } from '../../types/active-scheduler-filters.type';

export class SetActiveSchedulerFilters {
  type = ActionTypes.SET_ACTIVE_SCHEDULER_FILTERS;
  payload: { activeSchedulerFilters: ActiveSchedulerFilters };

  constructor(activeSchedulerFilters: ActiveSchedulerFilters) {
    this.payload = { activeSchedulerFilters };
  }
}

export type ActiveSchedulerFiltersActions = SetActiveSchedulerFilters;
