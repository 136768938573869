import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ActiveFiltersUtils from '../../../../../utils/active-filters.utils';
import { Intent } from '../../../../../types/intent.type';
import { KeyLabel } from '../../../../../types/key-label.type';

@UntilDestroy()
@Component({
  selector: 'sof-vehicle-search-criteria',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="criteria-container">
      <sof-collapsable-section
        [tc]="tc"
        label="WHAT-DO-YOU-WANT-TO-DO"
        [collapsed]="false"
      >
        <sof-intent-list
          [disabled]="disabled"
          [intents]="intents"
          [values]="intentIds"
          (valuesChange)="intentIdsChange.emit($event)"
        ></sof-intent-list>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section
        [tc]="tc"
        label="FILTER-TITLE-GENERAL"
        [collapsed]="false"
      >
        <div class="favorite-instant-container">
          <sof-input-switch
            [label]="tc + '.MY-FAVORITES' | translate"
            [isDisabled]="disabled"
            [selected]="onlyFavorites"
            (changeValue)="onlyFavoritesChange.emit($event)"
          ></sof-input-switch>
          <sof-input-switch
            [label]="tc + '.INSTANT-BOOK' | translate"
            [isDisabled]="disabled"
            [selected]="onlyInstantBookingPossible"
            (changeValue)="onlyInstantBookingPossibleChange.emit($event)"
          ></sof-input-switch>
        </div>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section [tc]="tc" label="FILTER-TITLE-VEHICLE-RANGE">
        <sof-input-slider
          [isDisabled]="disabled"
          [minValue]="VEHICLE_RANGE_MIN_VALUE"
          [maxValue]="VEHICLE_RANGE_MAX_VALUE"
          [labelFormatFn]="formatVehicleRange"
          [value]="vehicleRange"
          (changeValue)="vehicleRangeSub$.next($event)"
        >
        </sof-input-slider>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section
        [tc]="tc"
        label="FILTER-TITLE-PRICE-RANGE-PER-DAY"
      >
        <sof-input-slider
          [isDisabled]="disabled"
          range="true"
          [minValue]="PRICE_RANGE_MIN_VALUE"
          [maxValue]="PRICE_RANGE_MAX_VALUE"
          [labelFormatFn]="formatPrice"
          [value]="priceRange"
          (changeValue)="priceRangeSub$.next($event)"
        >
        </sof-input-slider>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section [tc]="tc" label="FILTER-TITLE-MAKE-MODEL">
        <sof-multiple-choice-list
          [disabled]="disabled"
          [selectedKeys]="modelIds"
          [choices]="vehicleModels"
          (selectedKeysChange)="modelIdsChange.emit($event)"
        ></sof-multiple-choice-list>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section [tc]="tc" label="FILTER-TITLE-NUMBER-OF-SEATS">
        <sof-input-slider
          [isDisabled]="disabled"
          [minValue]="SEATS_MIN_VALUE"
          [maxValue]="SEATS_MAX_VALUE"
          [labelFormatFn]="formatSeats"
          [value]="minimumSeats"
          (changeValue)="minimumSeatsSub$.next($event)"
        >
        </sof-input-slider>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section [tc]="tc" label="FILTER-TITLE-FEATURES">
        <sof-multiple-choice-list
          [disabled]="disabled"
          [selectedKeys]="equipmentIds"
          [choices]="equipments"
          (selectedKeysChange)="equipmentIdsChange.emit($event)"
        ></sof-multiple-choice-list>
      </sof-collapsable-section>
      <sof-divider></sof-divider>
      <sof-collapsable-section [tc]="tc" label="FILTER-TITLE-ALLOWED">
        <div class="allowed-container">
          <sof-allowed-button
            [disabled]="disabled"
            [label]="tc + '.ALLOWED_PETS' | translate"
            [value]="petsAllowed"
            (valueChange)="petsAllowedChange.emit($event)"
          ></sof-allowed-button>
          <sof-allowed-button
            [disabled]="disabled"
            [label]="tc + '.ALLOWED_CHILDREN' | translate"
            [value]="kidsAllowed"
            (valueChange)="kidsAllowedChange.emit($event)"
          ></sof-allowed-button>
          <sof-allowed-button
            [disabled]="disabled"
            [label]="tc + '.ALLOWED_ABROAD' | translate"
            [value]="abroadAllowed"
            (valueChange)="abroadAllowedChange.emit($event)"
          ></sof-allowed-button>
        </div>
      </sof-collapsable-section>
    </div>
  `,
  styleUrls: ['./vehicle-search-criteria.component.scss']
})
export class VehicleSearchCriteriaComponent implements OnInit {
  VEHICLE_RANGE_MIN_VALUE = ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE;
  VEHICLE_RANGE_MAX_VALUE = ActiveFiltersUtils.VEHICLE_RANGE_MAX_VALUE;
  PRICE_RANGE_MIN_VALUE = ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE;
  PRICE_RANGE_MAX_VALUE = ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE;
  SEATS_MIN_VALUE = 0;
  SEATS_MAX_VALUE = 9;

  @Input() disabled: boolean;
  @Input() tc: string;
  @Input() intents: Array<Intent>;
  @Input() equipments: Array<KeyLabel>;
  @Input() vehicleModels: Array<KeyLabel>;
  @Input() intentIds: Array<string>;
  @Input() vehicleName: string;
  @Input() onlyFavorites: boolean;
  @Input() onlyInstantBookingPossible: boolean;
  @Input() vehicleRange: number;
  @Input() priceRange: [number, number];
  @Input() modelIds: Array<string>;
  @Input() minimumSeats: number;
  @Input() petsAllowed: null | boolean;
  @Input() kidsAllowed: null | boolean;
  @Input() abroadAllowed: null | boolean;
  @Input() equipmentIds: Array<string>;
  @Input() vehiclesFound: number;
  @Input() showVehiclesFound: number;

  @Output() intentIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output()
  vehicleNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onlyFavoritesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onlyInstantBookingPossibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  vehicleRangeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() priceRangeChange: EventEmitter<[number, number]> = new EventEmitter<
    [number, number]
  >();
  @Output() modelIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output()
  minimumSeatsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() petsAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() kidsAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() abroadAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() equipmentIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  minimumSeatsSub$: Subject<number> = new Subject<number>();
  vehicleRangeSub$: Subject<number> = new Subject<number>();
  priceRangeSub$: Subject<[number, number]> = new Subject<[number, number]>();

  ngOnInit(): void {
    this.minimumSeatsSub$
      .pipe(debounceTime(500), takeUntilDestroy(this))
      .subscribe(value => {
        this.minimumSeatsChange.emit(value);
      });

    this.priceRangeSub$
      .pipe(debounceTime(500), takeUntilDestroy(this))
      .subscribe(value => {
        this.priceRangeChange.emit(value);
      });

    this.vehicleRangeSub$
      .pipe(debounceTime(500), takeUntilDestroy(this))
      .subscribe(value => {
        this.vehicleRangeChange.emit(value);
      });
  }

  formatPrice(value: number): string {
    return '€' + value;
  }

  formatVehicleRange(value: number): string {
    return !!value ? value + 'km' : 'km';
  }

  formatSeats(value: number): string {
    if (!value && value !== 0) {
      return '';
    }
    // TODO - Search for a way to manage translation there
    // Remark : this.translateService is not working because this is undefined in this method
    if (value === 0) {
      return 'No preference';
    } else if (value === 1) {
      return '1 seat';
    }
    return value + ' seats';
  }
}
