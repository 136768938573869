import * as proxyConf from '../../proxy.conf.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseUrlPrefix = (proxyConf as any)['/api'].target;
const clientId = (proxyConf as any)['/api'].clientId;
export const environment = {
  production: false,
  config: {
    api: {
      baseUrl: baseUrlPrefix + '/api'
    },
    auth: {
      allowedUrls: [baseUrlPrefix + '/api', 'https://localhost:4200/api','https://api.battmobility.com/api'],
      baseUrl: 'https://api.battmobility.com/auth/realms/',
      realm: 'Battmobiel',
      clientId,
      grant: 'implicit'
    },
    debug: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

