import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  OrganizationMemberDto,
  OrganizationRoleDto
} from  '../../../../../client';
import { OrganizationWithDetail } from '../../../types/organization-with-detail.type';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';

@Component({
  selector: 'sof-organization-member-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <sof-no-result
        *ngIf="organization?.membersByRole[organizationRole]?.length === 0"
        [tc]="tc"
        [noResultLabel]="noResultLabel"
      ></sof-no-result>
      <div
        class="sof-card"
        *ngFor="
          let organizationMember of organization?.membersByRole[
            organizationRole
          ]
        "
        (click)="displayOrganizationMemberDetail(organizationMember)"
      >
        <sof-user-avatar
          [userImage]="organizationMember.user | toUserImage: '40'"
        ></sof-user-avatar>
        <div class="user-info">
          <div>{{ organizationMember?.user | toUserDisplayName }}</div>
        </div>
        <div class="driver-grades-container">
          <sof-driver-grade-list
            [driverGrades]="organizationMember.driverGrades"
          ></sof-driver-grade-list>
        </div>
        <button
          sofButton
          class="round-icon-button"
          aria-label="Edit organization"
        >
          <sof-svg-icon icon="batt-icon-chevron-right" size="24"></sof-svg-icon>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-member-list.component.scss']
})
export class OrganizationMemberListComponent {
  @Input() tc: string;
  @Input() organization: OrganizationWithDetail;
  @Input() organizationRole: OrganizationRoleDto;
  @Input() noResultLabel: string;

  constructor(private osb: OrganizationManagementSandbox) {}

  displayOrganizationMemberDetail(
    organizationMember: OrganizationMemberDto
  ): void {
    if (
      organizationMember.organizationRoleDto === OrganizationRoleDto.MANAGER
    ) {
      this.osb.navigateToDetailOrganizationManagerScreen(
        this.organization.id,
        organizationMember.user.remoteId
      );
    } else {
      this.osb.navigateToDetailOrganizationMemberScreen(
        this.organization.id,
        organizationMember.user.remoteId
      );
    }
  }
}
