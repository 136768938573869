import { Booking } from '../../types/booking.type';
import { BookingActions } from '../actions/bookings.actions';
import { ActionTypes } from '../action-types';

export function bookingsReducer(
  state: Booking[] = [],
  action: BookingActions
): Booking[] {
  switch (action.type) {
    case ActionTypes.SET_BOOKINGS:
      return [...action.payload.bookings];
    case ActionTypes.ADD_BOOKING:
      return [...state, action.payload.booking];
    case ActionTypes.UPDATE_BOOKING:
      return state.map((booking: Booking) =>
        booking.internalId ===
        action.payload.bookingUpdate.previousBooking.internalId
          ? { ...booking, ...action.payload.bookingUpdate.newFields }
          : { ...booking }
      );
    case 'CANCEL_BOOKING':
      return [...state].filter(
        booking => booking.remoteId !== action.payload.bookingCancel.id
      );
  }
  return state;
}
