import { RelativePos } from './relative-pos.type';
import { Booking } from './booking.type';
import { Vehicle } from './vehicle.type';
import {
  BookingDto,
  FilterCriteriaForBookingDto
} from '../../../client';
import { UnavailabilityEventWithStyle } from './unavailability-event-with-style.type';
import { AvailabilityEventWithStyle } from './availability-event-with-style.type';
import { BatteryStatusWithDetail } from './battery-status-with-detail.type';

export enum TooltipType {
  BOOKING = 'BOOKING',
  DRAG = 'DRAG',
  DATE = 'DATE',
  POOL_VEHICLE = 'POOL_VEHICLE',
  UNAVAILABILITY = 'UNAVAILABILITY',
  AVAILABILITY = 'AVAILABILITY'
}

// ------ tooltip content types -----
// NOTE: it is recommanded to keep these interfaces flat (1 level deep)
// and all values have to be facultatives (to make easier the update content value event/redux action

export type TooltipContent =
  | TooltipContentBooking
  | TooltipContentDrag
  | TooltipContentDate
  | TooltipContentPoolVehicle
  | TooltipContentUnavailability
  | TooltipContentAvailability;

export interface TooltipContentPoolVehicle {
  vehicle?: Vehicle;
  tooltipParentId?: string;
  batteryStatus?: BatteryStatusWithDetail;
  showRefreshVehicleLocation?: boolean;
}

export interface TooltipContentBooking {
  booking?: Booking;
  userDisplayName?: string;
  userId?: string;
  userImageUrl?: string;
  userName?: string;
  currentUserId?: string;
  bookingDto?: BookingDto;
  filterCriteriaForBookingDto?: FilterCriteriaForBookingDto;
  tooltipParentId?: string;
}

export interface TooltipContentDrag {
  fromDate?: Date;
  toDate?: Date;
  tooltipParentId?: string;
}

export interface TooltipContentDate {
  date: Date;
  tooltipParentId?: string;
}

export interface TooltipContentUnavailability {
  unavailabilityEvent: UnavailabilityEventWithStyle;
  tooltipParentId?: string;
}

export interface TooltipContentAvailability {
  availabilityEvent: AvailabilityEventWithStyle;
  tooltipParentId?: string;
}

export interface Tooltip {
  id: string;
  type: TooltipType;

  // the rect the tooltip targets
  targetRect?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };

  // defines all the positions the tooltip can take according to
  // the targeted rect, ordered by decreasing priorities
  relativeTooltipPos: RelativeTooltipPos[];

  // the content of the tooltip is an object given as input for the related tooltip component
  content: TooltipContent;
}

// the position of a tooltip from its targeted rect
export interface RelativeTooltipPos {
  targetRectAnchor: RelativePos;
  tooltipAnchor: RelativePos;
}
