import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { SchedulerSetting } from '../../../scheduler.setting';
import { TooltipEvent } from '../../../classes/tooltip-events.class';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';

@Component({
  selector: 'sof-booking-scheduler-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-booking-scheduler-header-dates
      [columns]="columns"
      [subColumns]="subColumns"
      [style.height.px]="headerTopHeightPx"
    >
    </sof-booking-scheduler-header-dates>
  `,
  styleUrls: ['./booking-scheduler-header.component.scss']
})
export class BookingSchedulerHeaderComponent {
  @Input() columns: BookingSchedulerDate[];
  @Input() subColumns: BookingSchedulerDate[];

  headerTopHeightPx = SchedulerSetting.headerHeightPx.topPart;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
}
