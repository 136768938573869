import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import {
  AddVehiclesToGroupRequestDto,
  DeleteVehiclesFromGroupRequestDto,
  VehicleDto,
  VehiclePageDto
} from  '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import { SelectionListOption } from '../../../shared-ui/components/selection-list/types/selection-list-option.type';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { VehicleListOption } from '../../types/vehicle-list-option.type';
import { OrganizationVehicleGroupDetailViewHelper } from '../../view-helpers/organization-vehicle-group-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-vehicle-group-add-vehicle-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.EDIT-SELECTED-VEHICLES' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="dialog-content pt-3">
          <div class="info-container">
            <img class="info-image" src="assets/carphoto.svg" />
            <div class="info-text">
              {{ tc + '.EDIT-SELECTED-VEHICLES-TEXT' | translate }}
            </div>
          </div>
          <div class="search-container">
            <input
              placeholder="{{
                tc + '.SEARCH-EXISTING-VEHICLE-PLACEHOLDER' | translate
              }}"
              autocomplete="off"
              class="search-input"
              (input)="onSearchChange($event)"
            />
            <button
              sofButton
              (click)="onDeselectAllClick()"
              class="general-action-button primary"
            >
              {{ tc + '.DESELECT-ALL' | translate }}
            </button>
            <button
              sofButton
              (click)="onSelectAllClick()"
              class="general-action-button primary"
            >
              {{ tc + '.SELECT-ALL' | translate }}
            </button>
          </div>
          <div class="search-result-container">
            <sof-selection-list
              [multiple]="true"
              [options]="result$ | async"
              (selectionChange)="onSelectionChange($event)"
            >
              <ng-template let-vehicle #option>
                <div class="vehicle-option-container">
                  <sof-license-plate
                    class="scheduler-license-plate"
                    [value]="vehicle.licensePlate"
                    countryCode="be"
                  ></sof-license-plate>
                  <div class="vehicle-option-name">{{ vehicle.name }}</div>
                </div>
              </ng-template>
            </sof-selection-list>
          </div>
          <div class="vehicles-selected-count">
            {{
              tc + '.VEHICLES-SELECTED-COUNT'
                | translate: { count: selectedVehicleIds.length }
            }}
          </div>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
            [disabled]="!canSave"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./vehicle-group-edit-vehicles-dialog-view.component.scss']
})
export class VehicleGroupEditVehiclesDialogViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_VEHICLE-GROUP-EDIT-VEHICLES-DIALOG';

  result$: BehaviorSubject<VehicleListOption[]> = new BehaviorSubject<
    VehicleListOption[]
  >([]);
  newSearchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // TODO - To be reworked with vehicleSearch + vehicle already present in the group
  allVehicles$ = this.osb.allVehicle$;
  selectedVehicleIds: Array<string> = new Array<string>();
  originalSelectedVehicleIds: Array<string> = new Array<string>();
  canSave = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    public vh: OrganizationVehicleGroupDetailViewHelper
  ) {}

  ngOnInit(): void {
    this.vh.vehicleGroup$
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(vehicleGroup => {
        const originalSelectedVehicleIds = vehicleGroup?.vehicles?.map(
          vehicle => vehicle.id
        );
        if (!!originalSelectedVehicleIds) {
          this.originalSelectedVehicleIds.push(...originalSelectedVehicleIds);
          this.selectedVehicleIds.push(...originalSelectedVehicleIds);
        }
      });

    combineLatest([
      this.newSearchText$.pipe(debounceTime(250)),
      this.allVehicles$
    ])
      .pipe(takeUntilDestroy(this))
      .subscribe(([searchText, vehiclePage]: [string, VehiclePageDto]) => {
        if (
          !searchText ||
          searchText === '' ||
          searchText.trim().length < 1 ||
          !vehiclePage.vehicles
        ) {
          this.setResult(vehiclePage?.vehicles);
          return;
        }
        let searchStrings = searchText.trim().toLowerCase().split(' ');
        searchStrings = searchStrings.filter(
          searchString => searchString && searchString !== ''
        );
        if (searchStrings.length === 0) {
          this.setResult([]);
          return;
        }
        this.setResult(
          this.getMatchingVehicles(vehiclePage?.vehicles, searchStrings)
        );
      });
  }

  setResult(vehicles: VehicleDto[]): void {
    this.result$.next(
      vehicles.map(vehicle => {
        return {
          ...vehicle,
          selected: this.selectedVehicleIds.indexOf(vehicle.id) !== -1
        };
      })
    );
  }

  searchStringsInString(searchStrings: string[], text: string): boolean {
    if (!text || text === '') {
      return false;
    }
    const lowerCaseText = text.toLowerCase();
    for (const searchString of searchStrings) {
      if (lowerCaseText.indexOf(searchString) === -1) {
        return false;
      }
    }
    return true;
  }

  getMatchingVehicles(
    allVehicles: VehicleDto[],
    searchStrings: string[]
  ): Array<VehicleDto> {
    if (searchStrings && searchStrings.length > 0 && allVehicles) {
      return allVehicles.filter(vehicle => {
        return (
          this.searchStringsInString(searchStrings, vehicle.name) ||
          this.searchStringsInString(searchStrings, vehicle.licensePlate)
        );
      });
    }
    return [];
  }

  onSelectionChange(option: SelectionListOption): void {
    if (option.selected) {
      this.selectedVehicleIds.push(option.value.id);
    } else {
      this.selectedVehicleIds = this.selectedVehicleIds.filter(
        id => id !== option.value.id
      );
    }
    this.updateCanSave();
  }

  updateCanSave(): void {
    this.canSave = this.hasChanges();
  }

  hasChanges(): boolean {
    if (
      this.selectedVehicleIds.length !== this.originalSelectedVehicleIds.length
    ) {
      return true;
    }

    const vehiclesSet: Set<string> = new Set<string>(
      this.selectedVehicleIds.concat(this.originalSelectedVehicleIds)
    );
    return vehiclesSet.size !== this.selectedVehicleIds.length;
  }

  onSearchChange(event): void {
    this.newSearchText$.next(event.currentTarget.value);
  }

  deleteVehiclesFromVehicleGroup(
    vehiclesToDeleteRequest: DeleteVehiclesFromGroupRequestDto
  ): void {
    this.vh.vehicleGroupId$
      .pipe(
        switchMap(vehicleGroupId =>
          this.osb.deleteVehiclesFromVehicleGroup(
            vehicleGroupId,
            vehiclesToDeleteRequest
          )
        ),
        take(1),
        takeUntilDestroy(this)
      )
      .subscribe(
        res => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_UPDATE-SELECTED-VEHICLES-LIST',
            true
          );
          this.closeAndRefresh();
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_DELETE-VEHICLE-FROM-VEHICLE-GROUP'
          );
        }
      );
  }

  closeAndRefresh(): void {
    this.router
      .navigate(['..'], {
        relativeTo: this.activatedRoute
      })
      .then(() => {
        this.vh.triggerVehicleGroup();
      });
  }

  getAddVehiclesToGroupRequestDto(): AddVehiclesToGroupRequestDto {
    const vehiclesToAdd = this.selectedVehicleIds.filter(
      vehicleId => this.originalSelectedVehicleIds.indexOf(vehicleId) === -1
    );
    if (vehiclesToAdd.length > 0) {
      return {
        vehicleIds: vehiclesToAdd
      };
    }
    return null;
  }

  getDeleteVehiclesFromGroupRequestDto(): DeleteVehiclesFromGroupRequestDto {
    const vehiclesToDelete = this.originalSelectedVehicleIds.filter(
      vehicleId => this.selectedVehicleIds.indexOf(vehicleId) === -1
    );
    if (vehiclesToDelete.length > 0) {
      return {
        vehicleIds: vehiclesToDelete
      };
    }
    return null;
  }

  onSaveClick(): void {
    const vehiclesToAddRequest = this.getAddVehiclesToGroupRequestDto();
    const vehiclesToDeleteRequest = this.getDeleteVehiclesFromGroupRequestDto();
    if (!!vehiclesToAddRequest) {
      this.vh.vehicleGroupId$
        .pipe(
          switchMap(vehicleGroupId =>
            this.osb.addVehicleToVehiclesGroup(
              vehicleGroupId,
              vehiclesToAddRequest
            )
          ),
          take(1),
          takeUntilDestroy(this)
        )
        .subscribe(
          res => {
            if (!!vehiclesToDeleteRequest) {
              this.deleteVehiclesFromVehicleGroup(vehiclesToDeleteRequest);
            } else {
              this.toastUtilService.success(
                this.tc + '.SUCCESSFUL_UPDATE-SELECTED-VEHICLES-LIST',
                true
              );
              this.closeAndRefresh();
            }
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_ADD-VEHICLE-TO-VEHICLE-GROUP'
            );
          }
        );
    } else if (!!vehiclesToDeleteRequest) {
      this.deleteVehiclesFromVehicleGroup(vehiclesToDeleteRequest);
    }
  }

  onDeselectAllClick(): void {
    const vehicleIds = this.getResultVehicleIds();
    this.selectedVehicleIds = this.selectedVehicleIds.filter(
      vehicleId => vehicleIds.indexOf(vehicleId) === -1
    );
    this.updateCanSave();
    this.setResult(this.result$.value);
  }

  onSelectAllClick(): void {
    const vehicleIds = this.getResultVehicleIds();
    // Concatenate and remove duplicates
    this.selectedVehicleIds = [
      ...new Set([...vehicleIds, ...this.selectedVehicleIds])
    ];
    this.updateCanSave();
    this.setResult(this.result$.value);
  }

  getResultVehicleIds(): string[] {
    return this.result$.value.map(vehicle => vehicle.id);
  }
}
