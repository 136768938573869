export default class AvatarUtils {
  public static getAvatarInitials(name: string): string {
    if (!name) {
      return '';
    }
    // Split on space or dash
    const strArr = name.split(/[ -]/);
    if (strArr.length === 1) {
      return strArr[0].substring(0, 2).toUpperCase();
    } else {
      return (
        strArr[0].substring(0, 1).toUpperCase() +
        strArr[strArr.length - 1].substring(0, 1).toUpperCase()
      );
    }
  }

  public static getAvatarClass(name: string): string {
    const classes = ['', 'blue', 'yellow', 'green', 'red', 'grey'];
    const initials = AvatarUtils.getAvatarInitials(name);
    if (!initials) {
      return '';
    }
    const charIndex = initials.charCodeAt(0) - 65;
    return classes[charIndex % 6];
  }
}
