import { Component, OnInit, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { AppSandbox } from './app.sandbox';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BookingUserDto,
  Configuration,
  ConversationDto
} from '../../client';
import { GoogleHelper } from './shared-ui/view-helpers/google.helper';
import { UserManagementSandbox } from './user-management-ui/user-management.sandbox';
import { map, shareReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SettingService } from './services/setting.service';
import { NavItem } from './shared-ui/components/top-bar-nav/types/nav-item.type';
import { Loader } from '@googlemaps/js-api-loader';

@UntilDestroy()
@Component({
  selector: 'sof-root',
  template: `
    <sof-icon-set></sof-icon-set>
    <sof-batt-icon-set></sof-batt-icon-set>
    <sof-toast-hook></sof-toast-hook>
    <ng-container *ngIf="isAuthenticated$ | async">
      <div class="top-container">
        <div class="logo-container">
          <img class="logo-image" src="assets/logo.svg" />
        </div>
        <sof-batt-top-bar-nav
          [tc]="tc"
          [menuItems]="menuItems"
        ></sof-batt-top-bar-nav>
        <sof-user-profile-button-menu
          [tc]="tc"
          [user]="currentUser$ | async"
          (logout)="logout()"
          class="user-container"
        ></sof-user-profile-button-menu>
      </div>
      <div class="appContainer">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
  `,
  providers: [GoogleHelper],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  tc = 'APP_HOME';
  isAuthenticated$ = this.sb.isAuthenticated();
  menuItems: NavItem[];
  basePath = 'http://dev-api.battmobility.com';

  currentUser$: Observable<BookingUserDto> = this.sb.currentUser$.pipe(
    shareReplay(1)
  );
  conversations$: Observable<ConversationDto[]> = this.sb.conversations$.pipe(
    shareReplay(1)
  );

  unreadMessages$: Observable<boolean> = this.conversations$.pipe(
    map(conversations => {
      return (
        conversations?.filter(conversation => conversation.unreadMessages > 0)
          ?.length > 0
      );
    })
  );

  constructor(
    @Optional() configuration: Configuration,
    private sb: AppSandbox,
    private usb: UserManagementSandbox,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private settingService: SettingService,
    private googleHelper: GoogleHelper
  ) {
    if (configuration) {
      this.basePath = configuration.basePath;
    }
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang('ENG');
    this.menuItems = this.getMenuItems(
      true,
      false,
      false,
      false,
      false,
      false,
      false
    );
    this.isAuthenticated$.subscribe(isAuthenticated => {
      // Load Google Maps API script
      this.sb
        .getApiKey()
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(apiKey => {
          this.googleHelper.setGoogleApiKeyProvided(!!apiKey);
          const loader = new Loader({
            apiKey,
            libraries: ['places'],
            language: 'en'
          });
          loader.load().then(() => {
            this.googleHelper.setGoogleApiLoaded(true);
          });
        });

      this.sb.getCurrentUser().subscribe();
      this.settingService.featureToggles$.subscribe(featureToggles => {
        this.menuItems = this.getMenuItems(
          featureToggles.scheduler,
          featureToggles.organizations,
          featureToggles.vehicles,
          featureToggles.bookings,
          featureToggles.damages,
          featureToggles.users,
          featureToggles.chat
        );
        if (featureToggles.chat) {
          this.sb.pollConversations();
        }
      });
    });
  }

  logout(): void {
    this.sb.logout();
  }

  private getMenuItems(
    scheduler: boolean,
    organization: boolean,
    vehicles: boolean,
    bookings: boolean,
    damages: boolean,
    users: boolean,
    chat: boolean
  ): NavItem[] {
    const items = new Array<NavItem>();
    if (scheduler) {
      items.push({
        label: 'SCHEDULER',
        routerLink: '/scheduler'
      });
    }
    if (organization) {
      items.push({
        label: 'ORGANIZATIONS',
        routerLink: '/organizations'
      });
    }
    if (vehicles) {
      items.push({
        label: 'VEHICLES',
        routerLink: '/vehicles'
      });
    }
    if (bookings) {
      items.push({
        label: 'BOOKINGS',
        routerLink: '/bookings'
      });
    }
    if (damages) {
      items.push({
        label: 'DAMAGES',
        routerLink: '/damages'
      });
    }
    if (users) {
      items.push({
        label: 'USERS',
        routerLink: '/users'
      });
    }
    if (chat) {
      items.push({
        label: 'CHAT',
        routerLink: '/chat',
        icon: 'batt-icon-comments',
        iconSize: '32',
        displayBubble: this.unreadMessages$
      });
    }
    return items;
  }
}
