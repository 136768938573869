import { Injectable } from '@angular/core';
import {
  AuthenticatedResultService,
  AuthenticationService
} from '@sofico-framework/authentication';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GoogleService } from './services/google.service';
import { AppState } from './statemanagement/app.state';
import { mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { SetCurrentUserAction } from './statemanagement/actions/current-user.actions';
import {
  EquipmentPageDto,
  IntentPageDto,
  UserPageDto,
  UserRoleDto,
  VehicleModelsPageDto,
  VehiclePageDto
} from '../../client';
import { BookingService } from './services/booking.service';
import { ChatService } from './services/chat.service';
import { SetConversationsAction } from './statemanagement/actions/conversations.actions';
import { SetCurrentOrganizationIdAction } from './statemanagement/actions/planning.actions';
import SharedUiUtils from './shared-ui/utils/shared-ui.utils';

@Injectable()
export class AppSandbox {
  currentUser$ = this.store.select(state => state.currentUser);
  conversations$ = this.store.select(state => state.conversations);
  intents$ = this.bookingService.intents$;
  vehicleEquipments$ = this.bookingService.vehicleEquipments$;
  vehicleModels$ = this.bookingService.vehicleModels$;
  allUsers$ = this.userService.allUsers$;
  allVehicles$ = this.bookingService.allVehicles$;

  constructor(
    private authService: AuthenticationService,
    private authenticatedResultService: AuthenticatedResultService,
    private bookingService: BookingService,
    private store: Store<AppState>,
    private userService: UserService,
    private chatService: ChatService,
    private googleService: GoogleService
  ) {}

  isAuthenticated(): Observable<boolean> {
    return this.authenticatedResultService.authenticatedResult$;
  }

  logout(): void {
    this.authService.logout();
  }

  getCurrentUser(): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      // TODO - to be removed when getCurrentUser will be filled out with the organization data
      mergeMap(user => this.userService.getBookingUser(user.remoteId)),
      tap(bookingUserDto => {
        this.store.dispatch(new SetCurrentUserAction(bookingUserDto));
        let currentOrganizationId = bookingUserDto.defaultOrganization?.id;
        if (!currentOrganizationId) {
          if (
            !!bookingUserDto.organizations &&
            bookingUserDto.organizations.length > 0
          ) {
            currentOrganizationId = bookingUserDto.organizations[0].id;
          } else {
            currentOrganizationId =
              bookingUserDto?.role === UserRoleDto.BATTADMIN
                ? SharedUiUtils.NO_ORGANIZATION_ID
                : null;
          }
        }
        this.store.dispatch(
          new SetCurrentOrganizationIdAction(currentOrganizationId)
        );
      }),
      take(1),
      mapTo(true)
    );
  }

  getIntents(): Observable<IntentPageDto> {
    return this.bookingService.getIntents();
  }

  getEquipments(): Observable<EquipmentPageDto> {
    return this.bookingService.getEquipments();
  }

  getVehicleModels(): Observable<VehicleModelsPageDto> {
    return this.bookingService.getVehicleModels();
  }

  pollConversations(): void {
    this.chatService.pollConversations().subscribe(page => {
      this.store.dispatch(new SetConversationsAction(page?.conversations));
    });
  }

  fetchUsers(): Observable<UserPageDto> {
    return this.userService.fetchUsers();
  }

  fetchVehicles(): Observable<VehiclePageDto> {
    return this.bookingService.fetchVehicles();
  }

  getApiKey(): Observable<string> {
    return this.googleService.getApiKey();
  }
}
