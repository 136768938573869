import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@sofico-framework/app-config';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { Tab } from '@sofico-framework/ui-kit/components/tab';
import { DateFormatEnum } from '@sofico-framework/utils';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  ReplaySubject,
  throwError
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {
  ApprovalType,
  BookingDto,
  BookingStatusDto,
  BookingUserDto,
  CancelBookingRequestDto,
  ChangeApprovalStateRequestDto,
  CreateBookingRequestDto,
  EventActionDto,
  FilterCriteriaForBookingDto,
  GpsLocationDto,
  OrganizationMemberDto,
  OrganizationReferenceDto,
  ReplaceBookingRequestDto,
  UpdateBookingRequestDto,
  UserDto,
  UserRoleDto,
  VehicleDto,
  VehicleFilterCriteriaDto,
  VehicleSearchCriteriaDto
} from '../../../../../client';
import { DateUtil } from '../../../helpers/date-util';
import { ToastUtilService } from '../../../services/toast-util.service';
import { ActiveFilter } from '../../../types/active-filter.type';
import { BookingDialogBookingEvent } from '../../../types/booking-dialog-booking-event.type';
import { BookingDialogData } from '../../../types/booking-dialog-data.type';
import { BookingDialogVehicleGroup } from '../../../types/booking-dialog-vehicle-group.type';
import { ErrorWithMessage } from '../../../types/error-with-message.type';
import { VehicleFilterType } from '../../../types/vehicle-filter.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import { SharedUiSandbox } from '../../shared-ui.sandbox';
import SharedUiUtils from '../../utils/shared-ui.utils';
import { DropDownMenuItemAlt } from '../drop-down-menu-alt/types/drop-down-menu-item-alt.type';
import { DropDownConfigAlt } from '../drop-down-alt/types/drop-down-config-alt.type';

@UntilDestroy()
@Component({
  selector: 'sof-booking-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="xl"
      [headerLabel]="
        bookingDialogData.vehicleChange
          ? (tc + '.BOOKING-DIALOG-HEADER-VEHICLE-CHANGE' | translate)
          : (tc + '.BOOKING-DIALOG-HEADER' | translate)
      "
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="booking-dialog-content">
          <sof-tabs
            [tc]="tc"
            [tabs]="tabs$ | async"
            [active]="activeTab"
            (clickedTab)="activeTab = $event"
            class="batt-tabs"
          ></sof-tabs>
          <div
            [class.tab-restricted]="activeTab !== bookingTab"
            class="full-height-container overflow-auto"
          >
            <sof-booking-dialog-general-tab
              [tc]="tc"
              [data]="bookingDialogData"
              [bookingForm]="bookingForm"
              [dateFormat]="dateFormat"
              [groupedVehicles]="groupedVehicles$ | async"
              [userOrganizations]="userOrganizations"
              [displayAdminComments]="displayAdminComments"
              [showCanStopNotification]="showCanStopNotification"
              [canStop]="canStop$ | async"
              [showCanStartNotification]="showCanStartNotification"
              [canStart]="canStart$ | async"
              [showAwaitingApprovalNotification]="
                showAwaitingApprovalNotification
              "
              [canReject]="canReject$ | async"
              [canApprove]="canApprove$ | async"
              [canChangeFromDate]="canChangeFromDate"
              [canChangeToDate]="canChangeToDate"
              (organizationChange)="onOrganizationChange($event)"
              (userChange)="onUserChange($event)"
              (stopBooking)="onStopBooking()"
              (startBooking)="onStartBooking()"
              (rejectBooking)="showRejectBookingDialogSub$.next(true)"
              (approveBooking)="showAddUserToApprovedUsersDialogSub$.next(true)"
            ></sof-booking-dialog-general-tab>
          </div>
          <div
            [class.tab-restricted]="activeTab?.id !== TAB_ID_FILTERS"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-filters
              [tc]="tc"
              [disabled]="!canChangeFilters"
              [intents]="intents$ | async"
              [equipments]="vehicleEquipments$ | async"
              [vehicleModels]="vehicleModels$ | async"
              [intentIds]="selectedIntentsSub$ | async"
              (intentIdsChange)="selectedIntentsSub$.next($event)"
              [vehicleName]="vehicleNameSub$ | async"
              (vehicleNameChange)="vehicleNameSub$.next($event)"
              [onlyFavorites]="onlyFavoritesSub$ | async"
              (onlyFavoritesChange)="onlyFavoritesSub$.next($event)"
              [onlyInstantBookingPossible]="instantBookSub$ | async"
              (onlyInstantBookingPossibleChange)="instantBookSub$.next($event)"
              [vehicleRange]="vehicleRangeSub$ | async"
              (vehicleRangeChange)="vehicleRangeSub$.next($event)"
              [priceRange]="priceRangeSub$ | async"
              (priceRangeChange)="priceRangeSub$.next($event)"
              [modelIds]="selectedVehicleModelsSub$ | async"
              (modelIdsChange)="selectedVehicleModelsSub$.next($event)"
              [minimumSeats]="selectedSeatsSub$ | async"
              (minimumSeatsChange)="selectedSeatsSub$.next($event)"
              [petsAllowed]="petsAllowedSub$ | async"
              (petsAllowedChange)="petsAllowedSub$.next($event)"
              [kidsAllowed]="kidsAllowedSub$ | async"
              (kidsAllowedChange)="kidsAllowedSub$.next($event)"
              [abroadAllowed]="abroadAllowedSub$ | async"
              (abroadAllowedChange)="abroadAllowedSub$.next($event)"
              [equipmentIds]="selectedEquipmentsSub$ | async"
              (equipmentIdsChange)="selectedEquipmentsSub$.next($event)"
              [showVehiclesFound]="true"
              [vehiclesFound]="vehiclesFoundSub$ | async"
            >
            </sof-vehicle-filters>
          </div>
          <div
            [class.tab-restricted]="activeTab !== historyTab"
            class="full-height-container overflow-auto"
          >
            <sof-booking-dialog-events-tab
              [tc]="tc"
              [events]="events"
              [displayAdminComments]="displayAdminComments"
            ></sof-booking-dialog-events-tab>
          </div>
          <div
            [class.tab-restricted]="activeTab !== messagesTab"
            class="full-height-container overflow-auto"
          >
            <sof-no-result
              [tc]="tc"
              noResultLabel="CHAT-NOT-IMPLEMENTED"
            ></sof-no-result>
          </div>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            [disabled]="!canSave"
            (click)="onSaveBookingClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE-BOOKING' | translate }}
          </button>

          <sof-drop-down-menu-alt
            *ngIf="canCancel$ | async"
            [tc]="tc"
            [dropDownConfig]="dropDownConfig$ | async"
            [menuItems]="menuItems"
          ></sof-drop-down-menu-alt>
        </div>
      </div>
      <sof-add-user-to-approved-users-dialog
        *ngIf="showAddUserToApprovedUsersDialogSub$ | async"
        [tc]="tc"
        (cancelDialog)="showAddUserToApprovedUsersDialogSub$.next(false)"
        (okDialog)="onAddUserToApprovedUsers($event)"
      ></sof-add-user-to-approved-users-dialog>
      <sof-reject-booking-dialog
        *ngIf="showRejectBookingDialogSub$ | async"
        [tc]="tc"
        (cancelDialog)="showRejectBookingDialogSub$.next(false)"
        (okDialog)="onRejectBooking($event)"
      ></sof-reject-booking-dialog>
    </sof-dialog>
  `,
  styleUrls: ['./booking-dialog.component.scss']
})
export class BookingDialogComponent implements OnInit {
  tc = 'BOOKING_DIALOG';

  @Input() bookingDialogData: BookingDialogData;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  closeDialogAfterSave: EventEmitter<BookingDto> = new EventEmitter<BookingDto>();

  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  TAB_ID_FILTERS = 'filtersTab';
  bookingTab: Tab = {
    label: 'BOOKING-TAB'
  };
  filtersTab$: Observable<Tab>;
  historyTab: Tab = {
    label: 'HISTORY-TAB'
  };
  messagesTab: Tab = {
    label: 'MESSAGES-TAB'
  };
  tabs$: Observable<Tab[]>;
  activeTab: Tab = this.bookingTab;

  bookingForm: FormGroup;

  showAddUserToApprovedUsersDialogSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showRejectBookingDialogSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  canChangeUser = true;

  priceRangeSub$: BehaviorSubject<[number, number]> = new BehaviorSubject<
    [number, number]
  >([
    ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
    ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
  ]);
  vehicleRangeSub$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE
  );
  selectedIntentsSub$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  onlyFavoritesSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  instantBookSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  selectedSeatsSub$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedEquipmentsSub$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  abroadAllowedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  kidsAllowedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  petsAllowedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  vehicleNameSub$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  vehiclesFoundSub$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  matchingVehicleIdsSub$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(null);
  selectedVehicleModelsSub$: BehaviorSubject<
    Array<string>
  > = new BehaviorSubject<Array<string>>(new Array<string>());
  selectedLocationSub$: BehaviorSubject<GpsLocationDto> = new BehaviorSubject<GpsLocationDto>(
    { ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE }
  );
  selectedMaxDistanceSub$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
  );

  activeFiltersCountSub$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  intents$ = this.sharedUiSandbox.intents$;
  vehicleEquipments$ = this.sharedUiSandbox.vehicleEquipments$;
  vehicleModels$ = this.sharedUiSandbox.vehicleModels$;

  filterCriteriaSub$: BehaviorSubject<FilterCriteriaForBookingDto> = new BehaviorSubject<FilterCriteriaForBookingDto>(
    null
  );

  vehicleCriteria$ = combineLatest([
    this.selectedIntentsSub$,
    this.vehicleNameSub$,
    this.onlyFavoritesSub$,
    this.instantBookSub$,
    this.vehicleRangeSub$,
    this.priceRangeSub$,
    this.selectedSeatsSub$,
    this.abroadAllowedSub$,
    this.kidsAllowedSub$,
    this.petsAllowedSub$,
    this.selectedEquipmentsSub$,
    this.selectedVehicleModelsSub$,
    this.selectedLocationSub$,
    this.selectedMaxDistanceSub$
  ]).pipe(debounceTime(250));

  readyForVehicleSearchSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  vehicleSearchCriteria$ = this.readyForVehicleSearchSub$.pipe(
    filter(readyForVehicleSearch => readyForVehicleSearch),
    switchMap(() => this.vehicleCriteria$)
  );

  vehicleFilterCriteriaDto: VehicleFilterCriteriaDto;
  showAwaitingApprovalNotification = false;
  showCanStartNotification = false;
  showCanStopNotification = false;
  canReject$: Observable<boolean>;
  canApprove$: Observable<boolean>;
  canStart$: Observable<boolean>;
  canStop$: Observable<boolean>;
  canCancel$: Observable<boolean>;
  canChangeFromDate = false;
  canChangeToDate = false;
  canChangeComments = true;
  canChangeTripType = false;
  canChangeFilters = false;
  canSave = true;
  displayAdminComments = false;

  events: BookingDialogBookingEvent[];
  userOrganizations: Array<OrganizationReferenceDto>;

  groupedVehicles$: Observable<
    BookingDialogVehicleGroup[]
  > = this.matchingVehicleIdsSub$.pipe(
    map(matchingVehicleIds => {
      const res: Array<BookingDialogVehicleGroup> = new Array<BookingDialogVehicleGroup>();
      const matching: BookingDialogVehicleGroup = {
        name: 'Matching vehicles',
        vehicles: new Array<VehicleDto>()
      };
      const nonMatching: BookingDialogVehicleGroup = {
        name: 'Non matching vehicles',
        vehicles: new Array<VehicleDto>()
      };
      // Remove phased out vehicles
      // TODO - To be reworked during dialog rework
      this.bookingDialogData.vehicles
        .filter(vehicle => vehicle.electricRange >= 0)
        .forEach(vehicle => {
          if (
            !!matchingVehicleIds &&
            matchingVehicleIds.indexOf(vehicle.id) !== -1
          ) {
            matching.vehicles.push(vehicle);
          } else {
            nonMatching.vehicles.push(vehicle);
          }
        });
      if (matching.vehicles.length > 0) {
        matching.name += ' (' + matching.vehicles.length + ')';
        matching.vehicles = this.sortVehicles(matching.vehicles);
        res.push(matching);
      }
      if (nonMatching.vehicles.length > 0) {
        nonMatching.name += ' (' + nonMatching.vehicles.length + ')';
        nonMatching.vehicles = this.sortVehicles(nonMatching.vehicles);
        res.push(nonMatching);
      }
      return res;
    })
  );

  private setOrganizationId = false;

  // source stream
  private userIdSub$ = new ReplaySubject<string>(1);
  private organizationIdSub$ = new ReplaySubject<string>(1);

  // presentation stream
  bookingUser$: Observable<BookingUserDto>;
  organizationMember$: Observable<OrganizationMemberDto>;
  organizationMemberForUser$: Observable<OrganizationMemberDto>;
  organizationMemberMerged$: Observable<OrganizationMemberDto>;

  dropDownConfig$: Observable<DropDownConfigAlt>;
  // For now it contains only cancel action and *ngIf on the sof-drop-down-menu is enough
  // If an action is added a stream based on canCancel$ will be needed
  menuItems: DropDownMenuItemAlt[] = [
    {
      label: 'CANCEL-BOOKING',
      click: () => {
        this.onCancelBookingClick();
      }
    }
  ];

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private sharedUiSandbox: SharedUiSandbox,
    private translateService: TranslateService,
    private toastUtilService: ToastUtilService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.initDialog();
    this.getBookingFilters();
    this.getBookingHistory();
    this.updateShowNotifications();

    this.bookingUser$ = this.getBookingUser$();
    this.organizationMember$ = this.getOrganizationMember$();
    this.organizationMemberForUser$ = this.getOrganizationMemberForUser$();
    this.organizationMemberMerged$ = merge(
      this.organizationMember$,
      this.organizationMemberForUser$
    );
    this.canStart$ = this.organizationMemberMerged$.pipe(
      map(
        organizationMember =>
          !this.bookingDialogData.vehicleChange &&
          this.isValidNextStatus(BookingStatusDto.ACTIVE) &&
          this.isCurrentUserBookingOwnerOrAdmin(organizationMember)
      )
    );
    this.canStop$ = this.organizationMemberMerged$.pipe(
      map(
        organizationMember =>
          !this.bookingDialogData.vehicleChange &&
          this.isValidNextStatus(BookingStatusDto.STOPPED) &&
          this.isCurrentUserBookingOwnerOrAdmin(organizationMember)
      )
    );
    this.canReject$ = this.organizationMemberMerged$.pipe(
      map(
        organizationMember =>
          !this.bookingDialogData.vehicleChange &&
          this.isValidNextStatus(BookingStatusDto.REJECTED) &&
          this.isCurrentUserVehicleOwnerOrAdmin(organizationMember)
      )
    );
    this.canApprove$ = this.organizationMemberMerged$.pipe(
      map(
        organizationMember =>
          !this.bookingDialogData.vehicleChange &&
          this.isValidNextStatus(BookingStatusDto.APPROVED) &&
          this.isCurrentUserVehicleOwnerOrAdmin(organizationMember)
      )
    );
    this.canCancel$ = this.organizationMemberMerged$.pipe(
      map(
        organizationMember =>
          !this.bookingDialogData.vehicleChange &&
          !!this.bookingDialogData.bookingDto &&
          this.isValidNextStatus(BookingStatusDto.CANCELED) &&
          this.isCurrentUserBookingOwnerOrAdmin(organizationMember)
      )
    );
    this.dropDownConfig$ = this.getDropDownConfig$();

    // subscriptions
    this.bookingUser$.pipe(takeUntilDestroy(this)).subscribe(bookingUser => {
      this.userOrganizations = [...bookingUser.organizations].sort(
        this.sortOnName
      );
      if (this.setOrganizationId) {
        this.bookingForm.controls.organizationId.setValue(
          this.calculateOrganizationId(bookingUser)
        );
      }
    });

    this.filterCriteriaSub$
      .pipe(
        withLatestFrom(
          this.sharedUiSandbox.intents$,
          this.sharedUiSandbox.vehicleModels$,
          this.sharedUiSandbox.vehicleEquipments$
        ),
        takeUntilDestroy(this)
      )
      .subscribe(
        ([filterCriteria, intents, vehicleModels, vehicleEquipments]) => {
          if (!!filterCriteria) {
            const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
            ActiveFiltersUtils.fillOutActiveFilters(
              filterCriteria,
              intents,
              vehicleModels,
              vehicleEquipments,
              activeFilters,
              activeFilters,
              activeFilters
            );
            this.activeFiltersCountSub$.next(activeFilters.length);
          } else {
            this.activeFiltersCountSub$.next(0);
          }
        }
      );
    this.filtersTab$ = this.activeFiltersCountSub$.pipe(
      map(count => {
        return {
          label: 'FILTERS-TAB',
          id: this.TAB_ID_FILTERS,
          count
        };
      })
    );
    this.tabs$ = this.filtersTab$.pipe(
      map(tab => [this.bookingTab, tab, this.historyTab, this.messagesTab])
    );

    this.vehicleSearchCriteria$
      .pipe(takeUntilDestroy(this))
      .subscribe(
        ([
          selectedIntents,
          vehicleName,
          onlyFavorites,
          instantBook,
          vehicleRange,
          priceRange,
          selectedSeats,
          abroadAllowed,
          kidsAllowed,
          petsAllowed,
          selectedEquipments,
          selectedVehicleModels,
          selectedLocation,
          selectedMaxDistance
        ]: [
          Array<string>,
          string,
          boolean,
          boolean,
          number,
          [number, number],
          number,
          boolean,
          boolean,
          boolean,
          Array<string>,
          Array<string>,
          GpsLocationDto,
          number
        ]) => {
          const vehicleSearchCriteriaDto: VehicleSearchCriteriaDto = this.getVehicleSearchCriteriaDto(
            selectedIntents,
            vehicleName,
            onlyFavorites,
            instantBook,
            vehicleRange,
            priceRange,
            selectedSeats,
            abroadAllowed,
            kidsAllowed,
            petsAllowed,
            selectedEquipments,
            selectedVehicleModels,
            selectedLocation,
            selectedMaxDistance,
            true
          );
          this.sharedUiSandbox
            .searchVehicles(vehicleSearchCriteriaDto)
            .pipe(takeUntilDestroy(this))
            .subscribe(
              vehiclePageDto => {
                this.matchingVehicleIdsSub$.next(
                  vehiclePageDto.vehicles?.map(vehicle => vehicle.id)
                );
                this.vehiclesFoundSub$.next(vehiclePageDto?.vehicles?.length);
              },
              error => {
                this.toastUtilService.showError(
                  error,
                  this.tc + '.FAILED_VEHICLE-SEARCH'
                );
              }
            );
        }
      );
    // Is it allowed to change the user?
    this.sharedUiSandbox
      .canChangeUser()
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(canChangeUser => {
        this.canChangeUser = canChangeUser;
        if (!canChangeUser) {
          this.bookingForm.controls.userId.disable();
          this.bookingForm.controls.userId.setValue(
            this.bookingDialogData.currentUser.remoteId
          );
          this.bookingForm.controls.organizationId.disable();
          if (!!this.bookingDialogData.currentUser.defaultOrganization) {
            this.bookingForm.controls.organizationId.setValue(
              this.bookingDialogData.currentUser.defaultOrganization.id
            );
          } else {
            this.bookingForm.controls.organizationId.setValue(
              this.bookingDialogData.currentUser.organizations?.[0].id
            );
          }
        }
      });
  }

  initDialog(): void {
    const fromTime = this.getTime1600();
    const toTime = this.getTime2000();
    if (!!this.bookingDialogData.remoteBookingId) {
      fromTime.setHours(this.bookingDialogData.fromDate.getHours());
      fromTime.setMinutes(this.bookingDialogData.fromDate.getMinutes());
      toTime.setHours(this.bookingDialogData.toDate.getHours());
      toTime.setMinutes(this.bookingDialogData.toDate.getMinutes());
    }

    this.canChangeFromDate =
      !this.bookingDialogData.remoteBookingId ||
      this.bookingDialogData.bookingDto.status === BookingStatusDto.APPROVED ||
      this.bookingDialogData.bookingDto.status ===
        BookingStatusDto.AWAITINGAPPROVAL;
    this.canChangeToDate =
      !this.bookingDialogData.remoteBookingId ||
      this.bookingDialogData.bookingDto.status === BookingStatusDto.APPROVED ||
      this.bookingDialogData.bookingDto.status ===
        BookingStatusDto.AWAITINGAPPROVAL ||
      this.bookingDialogData.bookingDto.status === BookingStatusDto.ACTIVE;
    this.canChangeTripType =
      this.canChangeToDate && !this.bookingDialogData.vehicleChange;
    this.canChangeFilters =
      !this.bookingDialogData.remoteBookingId ||
      this.bookingDialogData.vehicleChange;

    const adminComments = !!this.bookingDialogData?.bookingDto?.adminComments
      ? this.bookingDialogData.bookingDto.adminComments
      : '';
    this.displayAdminComments = SharedUiUtils.isUserAdmin(
      this.bookingDialogData.currentUser
    );

    let userId = this.bookingDialogData.userId;
    let organizationId = this.bookingDialogData.organizationId;
    // Additional checks when a BattAdmin user is creating a booking
    if (
      !this.bookingDialogData.remoteBookingId &&
      this.bookingDialogData.currentUser?.role === UserRoleDto.BATTADMIN
    ) {
      // In case the current user select 'All' organization in the scheduler toolbar dropdown
      if (organizationId === SharedUiUtils.NO_ORGANIZATION_ID) {
        organizationId = this.bookingDialogData.currentUser.defaultOrganization
          ?.id;
      }
      // In case the user has selected an organization is not a member of
      // In such case, don't use the current organization, and clear the default user (which is the current user)
      if (
        !!organizationId &&
        !this.bookingDialogData.currentUser?.organizations.find(
          o => o.id === organizationId
        )
      ) {
        userId = null;
        organizationId = null;
      }
    }

    this.bookingForm = this.fb.group({
      vehicleId: [
        {
          value: this.bookingDialogData.remoteVehicleId,
          disabled: this.bookingDialogData.remoteVehicleId
        },
        Validators.required
      ],
      fromDate: [
        {
          value: this.bookingDialogData.fromDate,
          disabled: !this.canChangeFromDate
        },
        Validators.required
      ],
      fromTime: [
        { value: fromTime, disabled: !this.canChangeFromDate },
        Validators.required
      ],
      toDate: [
        {
          value: this.bookingDialogData.toDate,
          disabled: !this.canChangeToDate
        },
        Validators.required
      ],
      toTime: [
        { value: toTime, disabled: !this.canChangeToDate },
        Validators.required
      ],
      userId: [
        {
          value: userId,
          disabled:
            this.bookingDialogData.userId &&
            this.bookingDialogData.remoteBookingId
        },
        Validators.required
      ],
      tripType: [
        {
          value: this.bookingDialogData.tripType,
          disabled: !this.canChangeTripType
        },
        Validators.required
      ],
      comments: [
        {
          value: this.bookingDialogData.comments,
          disabled: !this.canChangeComments
        }
      ],
      adminComments: [adminComments],
      organizationId: [
        {
          value: organizationId,
          disabled:
            this.bookingDialogData.userId &&
            this.bookingDialogData.remoteBookingId
        },
        Validators.required
      ]
    });

    if (!!this.bookingDialogData.remoteBookingId) {
      this.updateUserData(
        this.bookingDialogData?.bookingDto?.user?.remoteId,
        false
      );
    } else if (!!userId) {
      this.updateUserData(userId, !this.bookingDialogData.organizationId);
    }
  }

  getBookingFilters(): void {
    if (!!this.bookingDialogData.remoteBookingId) {
      this.sharedUiSandbox
        .getBookingFilters(this.bookingDialogData.remoteBookingId)
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(filterCriteriaForBookingDto => {
          if (
            filterCriteriaForBookingDto &&
            filterCriteriaForBookingDto.vehicleFilterCriteriaDto
          ) {
            this.vehicleFilterCriteriaDto =
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto;
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.intentIds
            ) {
              this.selectedIntentsSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.intentIds
              );
            }
            if (filterCriteriaForBookingDto.vehicleFilterCriteriaDto.name) {
              this.vehicleNameSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.name
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.onlyFavorites
            ) {
              this.onlyFavoritesSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                  .onlyFavorites
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                .onlyInstantBookingPossible
            ) {
              this.instantBookSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                  .onlyInstantBookingPossible
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.minimumRange
            ) {
              this.vehicleRangeSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                  .minimumRange
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                .minDayPrice ||
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.maxDayPrice
            ) {
              const minDayPrice = filterCriteriaForBookingDto
                .vehicleFilterCriteriaDto.minDayPrice
                ? filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                    .minDayPrice
                : ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE;
              const maxDayPrice = filterCriteriaForBookingDto
                .vehicleFilterCriteriaDto.maxDayPrice
                ? filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                    .maxDayPrice
                : ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE;
              this.priceRangeSub$.next([minDayPrice, maxDayPrice]);
            }
            if (filterCriteriaForBookingDto.vehicleFilterCriteriaDto.modelIds) {
              this.selectedVehicleModelsSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.modelIds
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.minimumSeats
            ) {
              this.selectedSeatsSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                  .minimumSeats
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.permissions
            ) {
              this.petsAllowedSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.permissions
                  .petsAllowed === undefined
                  ? null
                  : filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                      .permissions.petsAllowed
              );
              this.kidsAllowedSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.permissions
                  .kidsAllowed === undefined
                  ? null
                  : filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                      .permissions.kidsAllowed
              );
              this.abroadAllowedSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.permissions
                  .abroadAllowed === undefined
                  ? null
                  : filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                      .permissions.abroadAllowed
              );
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto.equipmentIds
            ) {
              this.selectedEquipmentsSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                  .equipmentIds
              );
            }
            if (filterCriteriaForBookingDto.vehicleFilterCriteriaDto.location) {
              this.selectedLocationSub$.next({
                address: filterCriteriaForBookingDto.address,
                gpsCoordinateDto:
                  filterCriteriaForBookingDto.vehicleFilterCriteriaDto.location
              });
            }
            if (
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                .maxDistance &&
              filterCriteriaForBookingDto.vehicleFilterCriteriaDto
                .maxDistance !== ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
            ) {
              this.selectedMaxDistanceSub$.next(
                filterCriteriaForBookingDto.vehicleFilterCriteriaDto.maxDistance
              );
            }
          }
          this.readyForVehicleSearchSub$.next(true);
        });
    } else {
      this.readyForVehicleSearchSub$.next(true);
    }
  }

  getBookingHistory(): void {
    if (!!this.bookingDialogData.remoteBookingId) {
      this.sharedUiSandbox
        .searchBookingEventsForBooking(this.bookingDialogData.remoteBookingId)
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(
          bookingEventPageDto => {
            const events = bookingEventPageDto?.events?.map(event => {
              return {
                ...event,
                actionLabel: !!event.action
                  ? this.translateService.instant(
                      '@COMMON' + '.EVENT_ACTION_' + event.action
                    )
                  : '',
                rootActionLabel: !!event.rootEvent?.action
                  ? this.translateService.instant(
                      '@COMMON' + '.EVENT_ACTION_' + event.rootEvent.action
                    )
                  : ''
              } as BookingDialogBookingEvent;
            });
            let prevEvent: BookingDialogBookingEvent = null;
            for (const event of events) {
              if (
                event.action !== EventActionDto.CREATE &&
                event.action !== EventActionDto.UPDATE
              ) {
                continue;
              }
              if (prevEvent != null) {
                event.prevStartDate =
                  prevEvent.period.start !== event.period.start
                    ? prevEvent.period.start
                    : null;
                event.prevEndDate =
                  prevEvent.period.end !== event.period.end
                    ? prevEvent.period.end
                    : null;
                event.highlightAdminComments =
                  prevEvent.adminComments !== event.adminComments;
              }
              prevEvent = event;
            }
            // Revert the order (most recent actions first)
            this.events = events.reverse();
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_BOOKING-EVENTS'
            );
          }
        );
    }
  }

  onUserChange(userId): void {
    if (!!userId) {
      this.updateUserData(userId, true);
    } else {
      this.userOrganizations = null;
    }
  }

  onOrganizationChange(organizationId: string): void {
    this.updateOrganizationMemberData(organizationId);
  }

  updateOrganizationMemberData(organizationId: string): void {
    this.organizationIdSub$.next(organizationId);
  }

  updateUserData(userId: string, setOrganizationId: boolean): void {
    this.setOrganizationId = setOrganizationId;
    this.userIdSub$.next(userId);
  }

  private getBookingUser$(): Observable<BookingUserDto> {
    return this.userIdSub$.pipe(
      filter(userId => !!userId),
      switchMap(userId => this.sharedUiSandbox.getBookingUser(userId)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private getOrganizationMemberForUser$(): Observable<OrganizationMemberDto> {
    return this.bookingUser$.pipe(
      switchMap(bookingUser =>
        this.sharedUiSandbox.getOrganizationMember(
          this.calculateOrganizationId(bookingUser),
          this.bookingDialogData.currentUser.remoteId
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private getOrganizationMember$(): Observable<OrganizationMemberDto> {
    return this.organizationIdSub$.pipe(
      switchMap(organizationId =>
        !!organizationId
          ? this.sharedUiSandbox.getOrganizationMember(
              organizationId,
              this.bookingDialogData.currentUser.remoteId
            )
          : of(null)
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private calculateOrganizationId(bookingUser: BookingUserDto): string {
    if (this.setOrganizationId) {
      // In case the selected organization is part of the user organizations we use the selected organization
      if (
        bookingUser.organizations?.find(
          o => o.id === this.bookingForm.getRawValue().organizationId
        )
      ) {
        return this.bookingForm.getRawValue().organizationId;
      }
      return !!bookingUser.defaultOrganization
        ? bookingUser.defaultOrganization.id
        : bookingUser.organizations?.[0].id;
    }
    return this.bookingForm.getRawValue().organizationId;
  }

  getVehicleSearchCriteriaDto(
    selectedIntents: Array<string>,
    vehicleName: string,
    onlyFavorites: boolean,
    instantBook: boolean,
    vehicleRange: number,
    priceRange: [number, number],
    selectedSeats: number,
    abroadAllowed: boolean,
    kidsAllowed: boolean,
    petsAllowed: boolean,
    selectedEquipments: Array<string>,
    selectedVehicleModels: Array<string>,
    selectedLocation: GpsLocationDto,
    selectedMaxDistance: number,
    updateActiveFilters: boolean
  ): VehicleSearchCriteriaDto {
    const filterCriteria: FilterCriteriaForBookingDto = ActiveFiltersUtils.getFilterCriteriaForBookingDto(
      selectedIntents,
      vehicleName,
      onlyFavorites,
      instantBook,
      vehicleRange,
      priceRange,
      selectedSeats,
      abroadAllowed,
      kidsAllowed,
      petsAllowed,
      selectedEquipments,
      selectedVehicleModels,
      selectedLocation,
      selectedMaxDistance,
      null,
      this.getBookingUserId(),
      this.bookingDialogData.currentUser.remoteId
    );
    const vehicleSearchCriteriaDto: VehicleSearchCriteriaDto = ActiveFiltersUtils.getVehicleSearchCriteriaDtoForFilterCriteriaForBookingDto(
      filterCriteria
    );
    if (updateActiveFilters) {
      this.filterCriteriaSub$.next(filterCriteria);
    }
    return vehicleSearchCriteriaDto;
  }

  onRemoveActiveFilter(activeFilter: ActiveFilter): any {
    switch (activeFilter.vehicleFilterType) {
      case VehicleFilterType.INTENT:
        this.selectedIntentsSub$.next(
          this.selectedIntentsSub$.value.filter(
            intent => intent !== activeFilter.value
          )
        );
        break;
      case VehicleFilterType.VEHICLE_NAME:
        this.vehicleNameSub$.next(null);
        break;
      case VehicleFilterType.ONLY_FAVORITES:
        this.onlyFavoritesSub$.next(false);
        break;
      case VehicleFilterType.ONLY_INSTANT_BOOKING_POSSIBLE:
        this.instantBookSub$.next(false);
        break;
      case VehicleFilterType.MINIMUM_RANGE:
        this.vehicleRangeSub$.next(ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE);
        break;
      case VehicleFilterType.DAY_PRICE:
        this.priceRangeSub$.next([
          ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
          ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
        ]);
        break;
      case VehicleFilterType.VEHICLE_MODEL:
        this.selectedVehicleModelsSub$.next(
          this.selectedVehicleModelsSub$.value.filter(
            vehicleModel => vehicleModel !== activeFilter.value
          )
        );
        break;
      case VehicleFilterType.MINIMUM_SEATS:
        this.selectedSeatsSub$.next(0);
        break;
      case VehicleFilterType.ABROAD_ALLOWED:
        this.abroadAllowedSub$.next(null);
        break;
      case VehicleFilterType.KIDS_ALLOWED:
        this.kidsAllowedSub$.next(null);
        break;
      case VehicleFilterType.PETS_ALLOWED:
        this.petsAllowedSub$.next(null);
        break;
      case VehicleFilterType.EQUIPMENT:
        this.selectedEquipmentsSub$.next(
          this.selectedEquipmentsSub$.value.filter(
            equipment => equipment !== activeFilter.value
          )
        );
        break;
      case VehicleFilterType.MAXIMUM_DISTANCE:
        this.selectedMaxDistanceSub$.next(
          ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
        );
        break;
      case VehicleFilterType.LOCATION:
        this.selectedLocationSub$.next({
          ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE
        });
        break;
    }
  }

  convertToDate(isoString: string): Date {
    return DateUtil.convertToDate(isoString);
  }

  isApproved(): boolean {
    return (
      this.bookingDialogData.bookingDto &&
      this.bookingDialogData.bookingDto.status === BookingStatusDto.APPROVED
    );
  }

  addTimeToDate(date: Date, time: Date): Date {
    const d = new Date(date);
    d.setHours(time.getHours());
    d.setMinutes(time.getMinutes());
    return d;
  }

  onCancelBookingClick(): void {
    const request: CancelBookingRequestDto = {
      reason: 'Cancelled by battmobiel'
    };
    const confirm$ = this.sharedUiSandbox
      .cancelBooking(this.bookingDialogData.remoteBookingId, request)
      .pipe(take(1));
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'DELETE-DIALOG-TITLE',
      'DELETE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() => confirm$.pipe(catchError(error => of(error)))),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(
            v,
            this.tc + '.FAILED_CANCEL-BOOKING'
          );
        } else if (!!v) {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_CANCEL-BOOKING',
            true
          );
          dialog.destroy();
          this.closeDialogAfterSave.emit(v);
        }
      });
  }

  getBookingUserId(): string {
    if (this.bookingDialogData.remoteBookingId) {
      return this.bookingDialogData.userId;
    }
    return this.canChangeUser
      ? this.bookingForm.value.userId
      : this.bookingDialogData.currentUser.remoteId;
  }

  getBookingOrganizationId(): string {
    if (this.bookingDialogData.remoteBookingId) {
      return this.bookingDialogData.organizationId;
    }
    return this.canChangeUser
      ? this.bookingForm.value.organizationId
      : this.bookingDialogData.currentUser.defaultOrganization;
  }

  checkBeforeSave(): boolean {
    if (!this.bookingForm.valid) {
      return false;
    }
    return true;
  }

  getFromDate(): Date {
    return this.addTimeToDate(
      this.bookingForm.controls.fromDate.value,
      this.bookingForm.controls.fromTime.value
    );
  }

  getToDate(): Date {
    return this.addTimeToDate(
      this.bookingForm.controls.toDate.value,
      this.bookingForm.controls.toTime.value
    );
  }

  getRemoteVehicleId(): string {
    return this.bookingForm.value.vehicleId
      ? this.bookingForm.value.vehicleId
      : this.bookingDialogData.remoteVehicleId;
  }

  getReplaceBookingRequestDto(): ReplaceBookingRequestDto {
    const comments =
      this.bookingForm.controls.comments.value !== undefined
        ? this.bookingForm.controls.comments.value
        : this.bookingDialogData.bookingDto.comments;
    let adminComments: string;
    if (this.displayAdminComments) {
      adminComments =
        this.bookingForm.controls.adminComments.value !== undefined
          ? this.bookingForm.controls.adminComments.value
          : this.bookingDialogData.bookingDto.adminComments;
    }

    const request: ReplaceBookingRequestDto = {
      comments,
      adminComments,
      originalBookingId: this.bookingDialogData.remoteBookingId,
      period: DateUtil.convertToPeriod(this.getFromDate(), this.getToDate()),
      vehicleFilterCriteriaDto: this.getVehicleFilterCriteria(),
      vehicleId: this.getRemoteVehicleId()
    };

    return request;
  }

  getUpdateBookingRequest(): UpdateBookingRequestDto {
    const period = DateUtil.convertToPeriod(
      this.getFromDate(),
      this.getToDate()
    );
    const updateBookingRequest: UpdateBookingRequestDto = {
      tripType: this.bookingForm.controls.tripType.value,
      comments: this.bookingForm.controls.comments.value
    };
    // Only send the period if it's possible to change it and if there is a change
    const initPeriod = !!this.bookingDialogData.bookingDto?.plannedPeriod
      ? DateUtil.convertToPeriod(
          DateUtil.convertToDate(
            this.bookingDialogData.bookingDto.plannedPeriod.start
          ),
          DateUtil.convertToDate(
            this.bookingDialogData.bookingDto.plannedPeriod.end
          )
        )
      : DateUtil.convertToPeriod(
          this.bookingDialogData.fromDate,
          this.bookingDialogData.toDate
        );
    if (
      this.canChangeToDate &&
      !DateUtil.compareEqualPeriods(period, initPeriod)
    ) {
      updateBookingRequest.period = period;
    }
    if (this.displayAdminComments) {
      updateBookingRequest.adminComments = this.bookingForm.controls.adminComments.value;
    }
    return updateBookingRequest;
  }

  getCreateBookingRequest(): CreateBookingRequestDto {
    const createBookingRequest: CreateBookingRequestDto = {
      tripType: this.bookingForm.controls.tripType.value,
      comments: this.bookingForm.controls.comments.value,
      period: DateUtil.convertToPeriod(this.getFromDate(), this.getToDate()),
      userId: this.getBookingUserId(),
      organizationId: this.getBookingOrganizationId(),
      vehicleId: this.getRemoteVehicleId(),
      vehicleSearchCriteriaDto: this.getVehicleFilterCriteria()
    };
    if (this.displayAdminComments) {
      createBookingRequest.adminComments = this.bookingForm.controls.adminComments.value;
    }
    return createBookingRequest;
  }

  onSaveBookingClick(): void {
    if (!this.checkBeforeSave()) {
      return;
    }

    if (this.bookingDialogData.vehicleChange) {
      this.sharedUiSandbox
        .replaceBooking(this.getReplaceBookingRequestDto())
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(
          bookingDto => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_VEHICLE-CHANGE',
              true
            );
            this.closeDialogAfterSave.emit(bookingDto);
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_VEHICLE-CHANGE'
            );
          }
        );
    } else if (this.bookingDialogData.remoteBookingId) {
      this.sharedUiSandbox
        .updateBooking(
          this.bookingDialogData.remoteBookingId,
          this.getUpdateBookingRequest()
        )
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(
          bookingDto => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_UPDATE-BOOKING',
              true
            );
            this.closeDialogAfterSave.emit(bookingDto);
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_UPDATE-BOOKING'
            );
          }
        );
    } else {
      this.sharedUiSandbox
        .createBooking(this.getCreateBookingRequest())
        .pipe(take(1), takeUntilDestroy(this))
        .subscribe(
          bookingDto => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_CREATE-BOOKING',
              true
            );
            this.closeDialogAfterSave.emit(bookingDto);
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_CREATE-BOOKING'
            );
          }
        );
    }
  }

  getVehicleFilterCriteria(): VehicleFilterCriteriaDto {
    if (!this.hasActiveFilters()) {
      return null;
    }
    const vehicleSearchCriteria = this.getVehicleSearchCriteriaDto(
      this.selectedIntentsSub$.value,
      this.vehicleNameSub$.value,
      this.onlyFavoritesSub$.value,
      this.instantBookSub$.value,
      this.vehicleRangeSub$.value,
      this.priceRangeSub$.value,
      this.selectedSeatsSub$.value,
      this.abroadAllowedSub$.value,
      this.kidsAllowedSub$.value,
      this.petsAllowedSub$.value,
      this.selectedEquipmentsSub$.value,
      this.selectedVehicleModelsSub$.value,
      this.selectedLocationSub$.value,
      this.selectedMaxDistanceSub$.value,
      false
    );
    return vehicleSearchCriteria.filterCriteria;
  }

  hasActiveFilters(): boolean {
    return (
      this.activeFiltersCountSub$.value && this.activeFiltersCountSub$.value > 0
    );
  }

  onAddUserToApprovedUsers(addUserToApprovedUsers): void {
    const changeApprovalStateRequest: ChangeApprovalStateRequestDto = {
      addUserToApprovedUsers,
      approval: ApprovalType.APPROVED
    };
    this.sharedUiSandbox
      .updateBookingApprovalState(
        this.bookingDialogData.remoteBookingId,
        changeApprovalStateRequest
      )
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(
        bookingDto => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_APPROVE-BOOKING',
            true
          );
          this.showAddUserToApprovedUsersDialogSub$.next(false);
          this.closeDialogAfterSave.emit(bookingDto);
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_APPROVE-BOOKING'
          );
        }
      );
  }

  onRejectBooking(reason: string): void {
    const changeApprovalStateRequest: ChangeApprovalStateRequestDto = {
      reason,
      approval: ApprovalType.REJECTED
    };
    this.sharedUiSandbox
      .updateBookingApprovalState(
        this.bookingDialogData.remoteBookingId,
        changeApprovalStateRequest
      )
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(
        bookingDto => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REJECT-BOOKING',
            true
          );
          this.showRejectBookingDialogSub$.next(false);
          this.closeDialogAfterSave.emit(bookingDto);
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_REJECT-BOOKING'
          );
        }
      );
  }

  onStartBooking(): void {
    this.startBooking(
      this.bookingDialogData.remoteBookingId,
      this.bookingDialogData.remoteVehicleId
    );
  }

  onStopBooking(): void {
    this.stopBooking(
      this.bookingDialogData.remoteBookingId,
      this.bookingDialogData.remoteVehicleId
    );
  }

  getUserDisplayName(user: UserDto): string {
    return SharedUiUtils.getUserDisplayName(user);
  }

  isValidNextStatus(status: BookingStatusDto): boolean {
    return (
      this.bookingDialogData.bookingDto?.nextPossibleStatuses?.indexOf(
        status
      ) !== -1
    );
  }

  getBookingVehicle(): VehicleDto {
    return !!this.bookingDialogData.bookingDto
      ? this.bookingDialogData.bookingDto.vehicle
      : null;
  }

  isCurrentUserBookingOwnerOrAdmin(
    organizationMember: OrganizationMemberDto
  ): boolean {
    return (
      SharedUiUtils.isUserBookingOwnerOrAdmin(
        this.bookingDialogData.currentUser,
        this.bookingDialogData.bookingDto
      ) || SharedUiUtils.isOrganizationMemberAdmin(organizationMember)
    );
  }

  isCurrentUserVehicleOwnerOrAdmin(
    organizationMember: OrganizationMemberDto
  ): boolean {
    return (
      SharedUiUtils.isUserVehicleOwnerOrAdmin(
        this.bookingDialogData.currentUser,
        this.getBookingVehicle()
      ) || SharedUiUtils.isOrganizationMemberAdmin(organizationMember)
    );
  }

  updateShowNotifications(): void {
    this.showAwaitingApprovalNotification =
      !this.bookingDialogData.vehicleChange &&
      this.bookingDialogData.bookingDto &&
      this.bookingDialogData.bookingDto.status ===
        BookingStatusDto.AWAITINGAPPROVAL;
    this.showCanStartNotification =
      !this.bookingDialogData.vehicleChange &&
      this.bookingDialogData.bookingDto &&
      this.bookingDialogData.bookingDto.status === BookingStatusDto.APPROVED &&
      this.bookingDialogData.bookingDto.inActivePeriod;
    this.showCanStopNotification =
      !this.bookingDialogData.vehicleChange &&
      this.bookingDialogData.bookingDto &&
      this.bookingDialogData.bookingDto.status === BookingStatusDto.ACTIVE;
  }

  sortVehicles(vehicles: Array<VehicleDto>): Array<VehicleDto> {
    return vehicles.sort(this.sortOnName);
  }

  sortOnName(
    a: VehicleDto | OrganizationReferenceDto,
    b: VehicleDto | OrganizationReferenceDto
  ): number {
    return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase());
  }

  private getStartVehicleUsage$(
    bookingId: string,
    vehicleId: string
  ): Observable<BookingDto> {
    return this.sharedUiSandbox
      .startVehicleUsage(bookingId, vehicleId)
      .pipe(
        catchError(response =>
          throwError(
            new ErrorWithMessage(response, this.tc + '.FAILED_START-BOOKING')
          )
        )
      );
  }

  startBooking(bookingId: string, vehicleId: string): void {
    let startVehicleUsage$: Observable<BookingDto>;
    if (this.bookingForm.dirty) {
      if (!this.checkBeforeSave()) {
        return;
      }
      startVehicleUsage$ = this.sharedUiSandbox
        .updateBooking(bookingId, this.getUpdateBookingRequest())
        .pipe(
          catchError(response =>
            throwError(
              new ErrorWithMessage(
                response,
                this.tc + '.FAILED_UPDATE-BOOKING-START-BOOKING'
              )
            )
          ),
          mergeMap(() => this.getStartVehicleUsage$(bookingId, vehicleId)),
          take(1)
        );
    } else {
      startVehicleUsage$ = this.getStartVehicleUsage$(
        bookingId,
        vehicleId
      ).pipe(take(1));
    }

    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'START-DIALOG-TITLE',
      'START-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() =>
          startVehicleUsage$.pipe(catchError(error => of(error)))
        ),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof ErrorWithMessage) {
          this.toastUtilService.showError(v.response, v.message);
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_START-BOOKING',
            true
          );
          dialog.destroy();
          this.closeDialogAfterSave.emit(v);
        }
      });
  }

  private getEndVehicleUsage$(
    bookingId: string,
    vehicleId: string
  ): Observable<BookingDto> {
    return this.sharedUiSandbox
      .endVehicleUsage(bookingId, vehicleId)
      .pipe(
        catchError(response =>
          throwError(
            new ErrorWithMessage(response, this.tc + '.FAILED_STOP-BOOKING')
          )
        )
      );
  }

  stopBooking(bookingId: string, vehicleId: string): void {
    let endVehicleUsage$: Observable<BookingDto>;
    if (this.bookingForm.dirty) {
      if (!this.checkBeforeSave()) {
        return;
      }
      endVehicleUsage$ = this.sharedUiSandbox
        .updateBooking(bookingId, this.getUpdateBookingRequest())
        .pipe(
          catchError(response =>
            throwError(
              new ErrorWithMessage(
                response,
                this.tc + '.FAILED_UPDATE-BOOKING-STOP-BOOKING'
              )
            )
          ),
          mergeMap(() => this.getEndVehicleUsage$(bookingId, vehicleId)),
          take(1)
        );
    } else {
      endVehicleUsage$ = this.getEndVehicleUsage$(bookingId, vehicleId).pipe(
        take(1)
      );
    }
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'STOP-DIALOG-TITLE',
      'STOP-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() => endVehicleUsage$.pipe(catchError(error => of(error)))),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof ErrorWithMessage) {
          this.toastUtilService.showError(v.response, v.message);
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_STOP-BOOKING',
            true
          );
          dialog.destroy();
          this.closeDialogAfterSave.emit(v);
        }
      });
  }

  getTime1600(): Date {
    return DateUtil.getTime(16, 0);
  }

  getTime2000(): Date {
    return DateUtil.getTime(20, 0);
  }

  private getDropDownConfig$(): Observable<DropDownConfigAlt> {
    return this.translateService.stream(this.tc + '.DIALOG-ACTIONS').pipe(
      map(translation => {
        return {
          toggleButtonText: translation,
          toggleButtonClasses: 'general-action-button main-action'
        };
      })
    );
  }
}
