import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BookingUserDto } from '../../../../../client';

@Component({
  selector: 'sof-user-profile-button-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!!user">
      <span class="user-info">{{ user | toUserDisplayName }}</span>
      <sof-user-avatar
        class="user-avatar"
        [userImage]="user | toUserImage: '40'"
        nz-popover
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="bottomRight"
      ></sof-user-avatar>
      <ng-template #contentTemplate>
        <button
          sofButton
          class="general-action-button"
          (click)="logout.emit()"
          icon="batt-icon-logout"
          size="32"
        >
          {{ tc + '.LOG-OUT' | translate }}
        </button>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./user-profile-button-menu.component.scss']
})
export class UserProfileButtonMenuComponent {
  @Input() tc: string;
  @Input() user: BookingUserDto;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
}
