export enum VehicleFilterType {
  EQUIPMENT = 'equipment',
  INTENT = 'intent',
  LOCATION = 'location',
  DAY_PRICE = 'dayPrice',
  MAXIMUM_DISTANCE = 'maximumDistance',
  MINIMUM_RANGE = 'minimumRange',
  MINIMUM_SEATS = 'minimumSeats',
  VEHICLE_MODEL = 'vehicleModel',
  VEHICLE_NAME = 'vehicleName',
  ONLY_FAVORITES = 'onlyFavorites',
  ONLY_INSTANT_BOOKING_POSSIBLE = 'onlyInstantBookingPossible',
  ABROAD_ALLOWED = 'abroadAllowed',
  KIDS_ALLOWED = 'kidsAllowed',
  PETS_ALLOWED = 'petsAllowed',
  FAVORITES_USER_ID = 'favoritesUserId',
  VEHICLE_GROUP = 'vehicleGroup',
  MAX_DAY_FUTURE_END = 'maxDayFutureEnd',
  LICENSE_PLATE = 'licensePlate'
}
