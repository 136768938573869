import { Observable } from 'rxjs';
import {
  DamageDto,
  DamagePageDto,
  DamageStatusDto,
  IncidentControllerImplService,
  SearchDamageRequestDto,
  UpdateDamageRequestDto
} from '../../../client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  constructor(
    private incidentControllerImplService: IncidentControllerImplService
  ) {}

  searchDamages(requestDto: SearchDamageRequestDto): Observable<DamagePageDto> {
    return this.incidentControllerImplService.searchDamages(requestDto);
  }

  getDamageById(
    theVehicleId: string,
    theDamageId: string
  ): Observable<DamageDto> {
    return this.incidentControllerImplService.getDamageDetail(
      theDamageId,
      theVehicleId
    );
  }

  changeStatus(
    damageId: string,
    vehicleId: string,
    newStatus: DamageStatusDto
  ): Observable<DamageDto> {
    const request: UpdateDamageRequestDto = {
      status: newStatus
    };

    return this.incidentControllerImplService.updateDamageStatus(
      damageId,
      vehicleId,
      request
    );
  }
}
