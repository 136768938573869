import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/index';
import { switchMap, take } from 'rxjs/operators';
import { OrganizationPageDto } from  '../../../../client';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';

@Injectable()
export class OrganizationsViewHelper {
  triggerOrganizationsSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );
  organizations$: Observable<OrganizationPageDto> = this.triggerOrganizationsSub$.pipe(
    switchMap(() => this.osb.getOrganizations().pipe(take(1)))
  );
  constructor(private osb: OrganizationManagementSandbox) {}

  triggerOrganizations(): void {
    this.triggerOrganizationsSub$.next(new Date());
  }
}
