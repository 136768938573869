import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormComponent } from '@sofico-framework/ui-kit/components/form';
import {
  OnSofFocus,
  SOF_FOCUS_COMPONENT
} from '@sofico-framework/ui-kit/directives/focus';
import { Changes } from 'ngx-reactivetoolkit';
import { Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sof-input-text-autocomplete',
  template: `
    <input
      #htmlInputElement
      type="text"
      [attr.id]="labelForId"
      [value]="internalValue"
      class="form-control"
      [class.is-invalid]="
        invalid ||
        (ngControl?.invalid && (ngControl?.touched || form?.submitted))
      "
      [placeholder]="placeholder"
      [disabled]="isDisabled"
      (input)="onChange($event.target?.value)"
      (blur)="onTouch()"
      [nzAutocomplete]="auto"
    />
    <nz-autocomplete #auto>
      <ng-container *ngFor="let option of formattedOptions$ | async">
        <nz-auto-option
          [nzValue]="option.value"
          (click)="onChange(option.value)"
        >
          {{ option.label }}
        </nz-auto-option>
      </ng-container>
    </nz-autocomplete>
  `,
  providers: [
    {
      provide: SOF_FOCUS_COMPONENT,
      useExisting: InputTextAutocompleteComponent
    }
  ]
})
export class InputTextAutocompleteComponent
  implements ControlValueAccessor, OnDestroy, OnSofFocus, OnChanges, OnInit {
  /**
   * The id of the input to connect to a label tag.
   */
  @Input() labelForId: string;

  /**
   * The placeholder of the input.
   */
  @Input() placeholder = '';

  /**
   *  Determines if the input is disabled.
   */
  @Input() isDisabled: boolean;
  /**
   * Determines whether the input is in a valid state.
   */
  @Input() invalid: boolean;

  /**
   * Determines the value of the control.
   */
  @Input() set value(value: string) {
    this.writeValue(value);
  }

  /**
   * Determines which property that must be used as list label.
   */
  @Input() selectorLabel: (x: any) => any;

  /**
   * Determines which property that must be used as list value.
   */
  @Input() selectorValue: (x: any) => any;

  /**
   * The options that populate the list.
   */
  @Input() options: any[];

  /**
   * EventEmitter that will emit the value when changed.
   */
  @Output() changeValue = new EventEmitter<string>();

  /**
   * EventEmitter that will emit when control is touched.
   */
  @Output() touch = new EventEmitter<any>();

  // source streams
  @Changes('options') options$: Observable<any[]>;

  // presentation streams
  formattedOptions$: Observable<{ label: string; value: any }[]>;

  @ViewChild('htmlInputElement') htmlInputElement: ElementRef;

  internalValue: string = null;
  propagateChange: any;
  propagateTouch: any;

  constructor(
    @Optional() public form: FormComponent,
    @Optional() @Host() public ngControl: NgControl
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  sofFocus(): void {
    this.htmlInputElement.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (this.ngControl?.valueAccessor) {
      // Every time a control is re-created the previous writeValue reference(s) is not cleaned up.
      // So, over time, a lot of these references can be built up. This memory leak is a bug in Angular's implementation of ControlValueAccessor.
      // We hide that problem by assigning an empty function to writeValue every time we destroy the control.
      // An detailed explanation of the problem can be found here: https://github.com/angular/angular/pull/29335
      // The bug issue for it: https://github.com/angular/angular/issues/20007
      this.ngControl.valueAccessor.writeValue = () => {};
    }
  }

  ngOnInit(): void {
    this.formattedOptions$ = this.options$.pipe(
      map(options => options ?? []),
      map(options =>
        options.map(option => ({
          label: this.selectorLabel(option),
          value: this.selectorValue(option)
        }))
      )
    );
  }

  ngOnChanges(): void {}

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  writeValue(value: string): void {
    this.internalValue = value ?? null;
  }

  onChange(value: string): void {
    if (!this.isDisabled) {
      const newInternalValue = value ?? null;

      // emit value
      this.changeValue.emit(newInternalValue);

      // propagate the change
      if (this.propagateChange) {
        this.internalValue = newInternalValue;
        this.propagateChange(newInternalValue);
      }
    }
  }

  onTouch(): void {
    this.touch.emit();

    if (!this.isDisabled && this.propagateTouch) {
      this.propagateTouch();
    }
  }

  setDisabledState(value: boolean): void {
    this.isDisabled = value;
  }
}
