import { HttpErrorResponse } from '@angular/common/http';

export class ErrorWithMessage {
  readonly name = 'ErrorWithMessage';
  readonly message: string;
  readonly response: HttpErrorResponse;

  constructor(response, message) {
    this.response = response;
    this.message = message;
  }
}
