import { Tooltip, TooltipContent } from '../types/tooltip.type';

export class UpdateTooltipTargetRectEvent {
  type = 'UPDATE_TOOLTIP_TARGET_RECT';
  payload: {
    id: string;
    newValues: { width?: number; height?: number; left?: number; top?: number };
  };

  constructor(
    id: string,
    newValues: { width?: number; height?: number; left?: number; top?: number }
  ) {
    this.payload = { id, newValues };
  }
}

export class UpdateTooltipContentValuesEvent {
  type = 'UPDATE_TOOLTIP_CONTENT_VALUES';
  payload: {
    id: string;
    newValues: TooltipContent;
  };

  constructor(id: string, newValues: TooltipContent) {
    this.payload = { id, newValues };
  }
}

export class AddTooltipEvent {
  type = 'ADD_TOOLTIP';
  payload: { tooltip: Tooltip };

  constructor(tooltip: Tooltip) {
    this.payload = { tooltip };
  }
}

export class DeleteTooltipEvent {
  type = 'DELETE_TOOLTIP';
  payload: { id: string };

  constructor(id: string) {
    this.payload = { id };
  }
}

export type TooltipEvent =
  | UpdateTooltipTargetRectEvent
  | UpdateTooltipContentValuesEvent
  | AddTooltipEvent
  | DeleteTooltipEvent;
