import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { TooltipContentBooking } from '../../../../types/tooltip.type';
import { DateUtil } from '../../../../helpers/date-util';
import {
  DeleteTooltipEvent,
  TooltipEvent
} from '../../../../classes/tooltip-events.class';
import { Booking } from '../../../../types/booking.type';
import { ApplyActiveSchedulerFilters } from '../../../../types/apply-active-scheduler-filters.type';
import { ActiveFilter } from '../../../../types/active-filter.type';
import ActiveFiltersUtils from '../../../../utils/active-filters.utils';
import SharedUiUtils from '../../../../shared-ui/utils/shared-ui.utils';
import { UserImage } from '../../../../shared-ui/components/user-avatar/types/user-image.type';

@Component({
  selector: 'sof-tooltip-content-booking',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="header">
      <div class="title">Booking information</div>
      <button
        sofButton
        type="button"
        class="icon-button"
        (click)="onCloseClick()"
      >
        <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
      </button>
    </div>
    <div class="tooltip-list">
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item status-container">
        <div class="status-text">Status</div>
        <div class="status" [ngStyle]="statusStyle">
          {{ this.content?.bookingDto?.status }}
        </div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item column user-container">
        <div class="tooltip-line user-title">Booked by</div>
        <div class="tooltip-line user">
          <sof-user-avatar [userImage]="userImage"></sof-user-avatar>
          <div class="user-info">
            {{
              this.content?.userDisplayName
                ? this.content?.userDisplayName
                : this.content?.userName
            }}
          </div>
        </div>
        <div
          class="tooltip-line comments"
          nz-tooltip
          nzTooltipPlacement="bottom"
          [nzTooltipTitle]="content?.bookingDto?.comments"
          [nzTooltipMouseEnterDelay]="0.5"
          [nzTooltipOverlayStyle]="{ 'min-width': '400px' }"
        >
          {{ this.content?.bookingDto?.comments }}
        </div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item column time-container">
        <div class="tooltip-line time-title">Time</div>
        <div class="tooltip-line time container">
          <div class="row">
            <div class="col-sm">
              <div class="label">Planned pick-up :</div>
              <sof-date-hour [date]="plannedStartDate"></sof-date-hour>
            </div>
            <div class="col-sm">
              <div class="label">Planned drop-off :</div>
              <sof-date-hour [date]="plannedEndDate"></sof-date-hour>
            </div>
          </div>
          <div class="row">
            <div class="col-sm">
              <div class="label">Actual pick-up :</div>
              <sof-date-hour [date]="actualStartDate"></sof-date-hour>
            </div>
            <div class="col-sm">
              <div class="label">Actual drop-off :</div>
              <sof-date-hour [date]="actualEndDate"></sof-date-hour>
            </div>
          </div>
        </div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item edit-container">
        <button
          sofButton
          class="general-action-button primary"
          (click)="onApplyFiltersClick()"
          [disabled]="!this.content?.filterCriteriaForBookingDto"
        >
          Apply filters{{ filtersCount }}
        </button>
        <button
          sofButton
          class="general-action-button primary"
          (click)="onEditBookingClick()"
        >
          Edit booking
        </button>
      </div>
    </div>
  `
})
export class TooltipContentBookingComponent implements OnChanges {
  @Input() content: TooltipContentBooking;
  @Input() tooltipId: string;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output() editBooking: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output()
  applyActiveSchedulerFilters: EventEmitter<ApplyActiveSchedulerFilters> = new EventEmitter<ApplyActiveSchedulerFilters>();
  @Output()
  editConversation: EventEmitter<Booking> = new EventEmitter<Booking>();

  basePath = 'http://dev-api.battmobility.com';
  userImage: UserImage;
  plannedStartDate: string;
  plannedEndDate: string;
  actualStartDate: string;
  actualEndDate: string;
  filtersCount: string;
  statusStyle = null;

  updatePlannedStartDate(): void {
    this.plannedStartDate = this.content.bookingDto.plannedPeriod.start;
  }

  updatePlannedEndDate(): void {
    this.plannedEndDate = this.content.bookingDto.plannedPeriod.end;
  }

  updateActualStartDate(): void {
    this.actualStartDate = null;

    if (this.content.bookingDto.vehicleUsageDto?.period?.start) {
      this.actualStartDate = this.content.bookingDto.vehicleUsageDto.period.start;
    }
  }

  updateActualEndDate(): void {
    this.actualEndDate = null;

    if (this.content.bookingDto.vehicleUsageDto?.period?.end) {
      this.actualEndDate = this.content.bookingDto.vehicleUsageDto.period.end;
    }
  }

  updateUserImage(): void {
    this.userImage = null;

    if (this.content) {
      this.userImage = {
        imageUrl: this.content.userImageUrl,
        imageSize: '40',
        displayName: this.content.userDisplayName,
        userName: this.content.userName
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) {
      this.updateUserImage();
      this.updatePlannedStartDate();
      this.updatePlannedEndDate();
      this.updateActualStartDate();
      this.updateActualEndDate();
      this.updateFiltersCount();
      this.updateStatusStyle();
    }
  }

  onEditBookingClick(): void {
    this.editBooking.emit(this.content.booking);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onEditConversationClick(): void {
    this.editConversation.emit(this.content.booking);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onCloseClick(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onApplyFiltersClick(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
    this.applyActiveSchedulerFilters.emit({
      booking: this.content.bookingDto,
      filterCriteria: this.content.filterCriteriaForBookingDto
    });
  }

  updateStatusStyle(): void {
    if (this.content?.bookingDto) {
      const bookingColors = SharedUiUtils.getBookingColors(
        this.content.bookingDto.status,
        DateUtil.convertToDate(this.content.bookingDto.plannedPeriod.start),
        DateUtil.convertToDate(this.content.bookingDto.plannedPeriod.end),
        true
      );
      this.statusStyle = {
        color: bookingColors.text,
        border: '1.5px solid ' + bookingColors.border,
        'background-color': bookingColors.background
      };
    } else {
      this.statusStyle = null;
    }
  }

  updateFiltersCount(): void {
    if (!this.content?.filterCriteriaForBookingDto) {
      this.filtersCount = '';
      return;
    }
    const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
    ActiveFiltersUtils.fillOutActiveFilters(
      this.content.filterCriteriaForBookingDto,
      [],
      [],
      [],
      activeFilters,
      activeFilters,
      activeFilters
    );
    if (activeFilters.length === 0) {
      this.filtersCount = '';
    }
    this.filtersCount = ' (' + activeFilters.length + ')';
  }
}
