import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropDownConfigAlt } from '../drop-down-alt/types/drop-down-config-alt.type';
import { DropDownMenuItemAlt } from './types/drop-down-menu-item-alt.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: `sof-drop-down-menu-alt`,
  styleUrls: ['./drop-down-menu-alt.component.scss'],
  template: `
    <sof-drop-down-alt
      [config]="dropDownConfig"
      [isWithinNavBar]="isWithinNavBar"
    >
      <ul nz-menu>
        <li
          *ngFor="let menuItem of menuItems"
          nz-menu-item
          class="dropdown-item drop-down-menu-item"
          [ngClass]="menuItem.classes"
          [routerLink]="menuItem.routerLink || []"
          (click)="menuItem.click && menuItem.click($event)"
        >
          <sof-svg-icon
            *ngIf="menuItem.itemIcon"
            class="mr-3"
            [ngClass]="menuItem.itemIcon.classes"
            [icon]="menuItem.itemIcon.icon"
            [size]="menuItem.itemIcon.size"
          ></sof-svg-icon>
          <span>
            {{
              menuItem?.label
                ? (tc + '.' + menuItem.label | translate: menuItem.params)
                : menuItem.translation
            }}
          </span>
        </li>
      </ul>
    </sof-drop-down-alt>
  `
})
export class DropDownMenuAltComponent {
  @Input() tc: string;
  @Input() isWithinNavBar = false;
  @Input() dropDownConfig: DropDownConfigAlt = {};
  /**
   * Both routerLink and click are supported for menu items. They should not be combined though.
   */
  @Input() menuItems: DropDownMenuItemAlt[] = [];
}
