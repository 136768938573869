/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimeZoneDto = 'BRUSSELS' | 'LONDON' | 'NEW_YORK';

export const TimeZoneDto = {
    BRUSSELS: 'BRUSSELS' as TimeZoneDto,
    LONDON: 'LONDON' as TimeZoneDto,
    NEWYORK: 'NEW_YORK' as TimeZoneDto
};

