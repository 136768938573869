import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { VehicleAvailabilityWithStyle } from '../../../types/vehicle-availability-with-style.type';

@Component({
  selector: 'sof-vehicle-global-availability',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./vehicle-global-availability.component.scss'],
  template: `
    <div
      class="availability"
      *ngIf="startAvailabilityVisibility"
      [ngStyle]="vehicleAvailability.startOfAvailabilityWithStyle"
    ></div>

    <div
      class="availability"
      *ngIf="endAvailabilityVisibility"
      [ngStyle]="vehicleAvailability.endOfAvailabilityWithStyle"
    ></div>
  `
})
export class VehicleGlobalAvailabilityComponent implements OnInit {
  @Input() vehicleAvailability: VehicleAvailabilityWithStyle;
  @Input() schedulerDateStart: Date;
  @Input() schedulerDateEnd: Date;

  startAvailabilityVisibility: boolean;
  endAvailabilityVisibility: boolean;

  ngOnInit(): void {
    this.startAvailabilityVisibility =
      this.vehicleAvailability.startOfAvailability > this.schedulerDateStart;
    this.endAvailabilityVisibility =
      this.vehicleAvailability.endOfAvailability < this.schedulerDateEnd;
  }
}
