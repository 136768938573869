import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateFormatEnum } from '@sofico-framework/utils';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import {
  OrganizationReferenceDto,
  TripTypeDto,
  VehicleDto
} from '../../../../../client';
import { AppConstants } from '../../../app.constants';
import { BookingDialogData } from '../../../types/booking-dialog-data.type';
import { BookingDialogVehicleGroup } from '../../../types/booking-dialog-vehicle-group.type';
import { VehicleMarker } from '../../../types/vehicle-marker.type';
import SharedUiUtils from '../../utils/shared-ui.utils';
import { GoogleHelper } from '../../view-helpers/google.helper';

@UntilDestroy()
@Component({
  selector: 'sof-booking-dialog-general-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="d-flex flex-column p-2">
      <sof-form [tc]="tc" [formGroup]="bookingForm" class="container new-data">
        <div class="row" *ngIf="showAwaitingApprovalNotification">
          <div class="notification-container error">
            <div class="notification-title">Awaiting approval</div>
            <div class="notification-text">
              Booking awaiting approval.<span *ngIf="canReject || canApprove">
                Please select the appropriate action.</span
              >
            </div>
            <div class="notification-buttons" *ngIf="canReject || canApprove">
              <div class="spacer"></div>
              <button
                *ngIf="canReject"
                (click)="rejectBooking.emit()"
                class="no-focus-ring secondary"
              >
                Reject
              </button>
              <button
                *ngIf="canApprove"
                (click)="approveBooking.emit()"
                class="no-focus-ring primary"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showCanStartNotification">
          <div class="notification-container info">
            <div class="notification-title">Ready to start</div>
            <div class="notification-text">
              Booking can be started.<span *ngIf="canStart">
                Please select the appropriate action.</span
              >
            </div>
            <div class="notification-buttons" *ngIf="canStart">
              <div class="spacer"></div>
              <button
                *ngIf="canStart"
                (click)="startBooking.emit()"
                class="no-focus-ring primary"
              >
                Start
              </button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showCanStopNotification">
          <div class="notification-container info">
            <div class="notification-title">Ready to be ended</div>
            <div class="notification-text">
              Booking can be ended.<span *ngIf="canStop">
                Please select the appropriate action.</span
              >
            </div>
            <div class="notification-buttons" *ngIf="canStop">
              <div class="spacer"></div>
              <button
                (click)="stopBooking.emit()"
                class="no-focus-ring primary"
              >
                End
              </button>
            </div>
          </div>
        </div>
        <div class="column-container">
          <div class="col-form">
            <div class="row">
              <sof-input-wrapper [label]="'User'" class="col-sm">
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="bookingForm?.controls?.userId"
                  [clearable]="false"
                  [options]="data?.users"
                  [selectorValue]="userValueFn"
                  [selectorLabel]="userLabelFn"
                  (changeValue)="userChange.emit($event)"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper [label]="'Organization'" class="col-sm">
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="bookingForm?.controls?.organizationId"
                  [clearable]="false"
                  [options]="userOrganizations"
                  [selectorValue]="organizationValueFn"
                  [selectorLabel]="organizationLabelFn"
                  (changeValue)="organizationChange.emit($event)"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper [label]="'Vehicle'" class="col-sm">
                <!-- TODO - Grouped Vehicles (matching criteria) -->
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="bookingForm?.controls?.vehicleId"
                  [clearable]="false"
                  [options]="data.vehicles"
                  [selectorValue]="vehicleValueFn"
                  [selectorLabel]="vehicleLabelFn"
                  (changeValue)="onChangeVehicle($event)"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper [label]="'From Date'" class="col-sm">
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="bookingForm?.controls?.fromDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="!canChangeFromDate"
                ></sof-input-date-picker>
              </sof-input-wrapper>
              <sof-input-wrapper [label]="'To Date'" class="col-sm">
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="bookingForm?.controls?.toDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="!canChangeToDate"
                ></sof-input-date-picker>
              </sof-input-wrapper>
            </div>

            <div class="row">
              <sof-input-wrapper [label]="'From Time'" class="col-sm">
                <sof-input-time-picker
                  [sofInput]
                  [formControl]="bookingForm?.controls?.fromTime"
                  [allowEmpty]="false"
                  [isDisabled]="!canChangeFromDate"
                ></sof-input-time-picker>
              </sof-input-wrapper>
              <sof-input-wrapper [label]="'To Time'" class="col-sm">
                <sof-input-time-picker
                  [sofInput]
                  [formControl]="bookingForm?.controls?.toTime"
                  [allowEmpty]="false"
                  [isDisabled]="!canChangeToDate"
                ></sof-input-time-picker>
              </sof-input-wrapper>
            </div>

            <div class="row trip-type">
              <div class="sof-toggle-button-group">
                <sof-input-radio
                  [sofInput]
                  [formControl]="bookingForm?.controls?.tripType"
                  [value]="TRIP_TYPE_PRIVATE"
                >
                  {{ tc + '.TRIP_TYPE_PRIVATE' | translate }}
                </sof-input-radio>
                <sof-input-radio
                  [sofInput]
                  [formControl]="bookingForm?.controls?.tripType"
                  [value]="TRIP_TYPE_BUSINESS"
                >
                  {{ tc + '.TRIP_TYPE_BUSINESS' | translate }}
                </sof-input-radio>
              </div>
            </div>

            <div class="row">
              <sof-input-wrapper [label]="'Comments'" class="col-sm">
                <sof-input-textarea
                  [sofInput]
                  [formControl]="bookingForm?.controls?.comments"
                ></sof-input-textarea>
              </sof-input-wrapper>
            </div>

            <div class="row" *ngIf="displayAdminComments">
              <sof-input-wrapper [label]="'Admin comments'" class="col-sm">
                <sof-input-textarea
                  [sofInput]
                  [formControl]="bookingForm?.controls?.adminComments"
                ></sof-input-textarea>
              </sof-input-wrapper>
            </div>
          </div>
          <div class="col-detail">
            <div class="booking-detail-container">
              <div class="booking-detail-row">
                <div class="booking-detail-label">Status :</div>
                <sof-booking-status
                  *ngIf="!!data?.bookingDto"
                  [booking]="data?.bookingDto"
                ></sof-booking-status>
              </div>
              <div class="booking-detail-row">
                <div class="booking-detail-label">License plate :</div>
                <sof-license-plate
                  class="scheduler-license-plate"
                  countryCode="be"
                  [value]="licensePlate"
                ></sof-license-plate>
              </div>
            </div>
            <google-map
              *ngIf="googleHelper.googleApiLoaded$ | async"
              [options]="options"
              width="300"
              height="300"
              [center]="vehicleMarker?.position"
            >
              <map-marker
                *ngIf="vehicleMarker"
                [position]="vehicleMarker.position"
                [options]="vehicleMarker.options"
              ></map-marker>
            </google-map>
          </div>
        </div>
      </sof-form>
    </div>
  `,
  styleUrls: ['./booking-dialog.component.scss']
})
export class BookingDialogGeneralTabComponent implements OnInit {
  TRIP_TYPE_PRIVATE = TripTypeDto.PRIVATE;
  TRIP_TYPE_BUSINESS = TripTypeDto.BUSINESS;

  @Input() tc: string;
  @Input() data: BookingDialogData;
  @Input() bookingForm: FormGroup;
  @Input() dateFormat: DateFormatEnum;
  @Input() groupedVehicles: BookingDialogVehicleGroup[];
  @Input() userOrganizations: Array<OrganizationReferenceDto>;
  @Input() displayAdminComments: boolean;
  @Input() showCanStopNotification: boolean;
  @Input() canStop: boolean;
  @Input() showCanStartNotification: boolean;
  @Input() canStart: boolean;
  @Input() showAwaitingApprovalNotification: boolean;
  @Input() canReject: boolean;
  @Input() canApprove: boolean;
  @Input() canChangeFromDate: boolean;
  @Input() canChangeToDate: boolean;

  @Output()
  organizationChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() userChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() stopBooking: EventEmitter<void> = new EventEmitter<void>();
  @Output() startBooking: EventEmitter<void> = new EventEmitter<void>();
  @Output() rejectBooking: EventEmitter<void> = new EventEmitter<void>();
  @Output() approveBooking: EventEmitter<void> = new EventEmitter<void>();

  vehicle: VehicleDto = null;
  licensePlate = null;
  vehicleMarker: VehicleMarker = null;
  options: google.maps.MapOptions;

  constructor(public googleHelper: GoogleHelper) {}

  ngOnInit(): void {
    this.googleHelper.googleApiLoaded$
      .pipe(takeUntilDestroy(this))
      .subscribe(loaded => {
        if (!!loaded) {
          this.options = SharedUiUtils.getDefaultMapOptions(13);
          if (
            this.data.remoteBookingId &&
            this.data?.bookingDto?.vehicle?.id === this.data.remoteVehicleId
          ) {
            this.updateVehicleData(this.data?.bookingDto?.vehicle);
          } else {
            this.updateVehicleData(this.getVehicle(this.data.remoteVehicleId));
          }
        }
      });
  }

  updateVehicleData(vehicle: VehicleDto): void {
    this.vehicle = vehicle;
    if (!vehicle) {
      this.vehicleMarker = null;
      this.licensePlate = null;
      return;
    }
    this.vehicleMarker = {
      internalVehicleId: null,
      remoteVehicleId: vehicle.id,
      vehicle: null,
      position: new google.maps.LatLng({
        lat: vehicle.homePosition.latitude,
        lng: vehicle.homePosition.longitude
      }),
      options: {
        icon: AppConstants.ASSETS_ICON_CAR_AVAILABLE_MARKER
      }
    };
    this.licensePlate = vehicle.licensePlate;
  }

  getVehicle(remoteVehicleId: string): VehicleDto {
    return this.data?.vehicles?.find(vehicle => vehicle.id === remoteVehicleId);
  }

  onChangeVehicle(vehicleId: string): void {
    this.updateVehicleData(this.getVehicle(vehicleId));
  }

  userValueFn = u => u.remoteId;
  userLabelFn = u => SharedUiUtils.getUserDisplayName(u);
  organizationValueFn = o => o.id;
  organizationLabelFn = o => o.name;
  vehicleValueFn = v => v.id;
  vehicleLabelFn = v => v.name;
}
