import { Vehicle } from '../../types/vehicle.type';
import { ActionTypes } from '../action-types';
import { VehiclesActions } from '../actions/vehicles.actions';

export function vehiclesReducer(
  state: Vehicle[] = [],
  action: VehiclesActions
): Vehicle[] {
  switch (action.type) {
    case ActionTypes.SET_VEHICLES:
      return [...action.payload.vehicles];
    default:
      return state;
  }
}
