import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  DamageDto,
  DamagePageDto,
  DamageStatusDto
} from '../../../../../client';
import { IncidentService } from '../../../services/incident.service';
import { ToastUtilService } from '../../../services/toast-util.service';

@Component({
  selector: 'sof-damage-detail-view',
  template: `
    <sof-loading-spinner></sof-loading-spinner>
    <div class="d-flex flex-column p-3 gg-05">
      <h1 align="center" class="damage-title">
        {{ tc + '.DAMAGE-DETAIL-TITLE' | translate }}
      </h1>
      <sof-damage-list
        [tc]="tc"
        [damagePageDto]="damages$ | async"
        [showResultFoundLabel]="false"
        class="no-full-height"
      ></sof-damage-list>
      <sof-form [tc]="tc" [formGroup]="damageForm">
        <sof-input-textarea
          [sofInput]
          [formControl]="damageForm?.controls?.damageInfo"
          [placeholder]="tc + '.PASTE-JSON-HERE' | translate"
          class="json"
        ></sof-input-textarea>
      </sof-form>
      <div class="d-flex align-items-center justify-content-center gg-1">
        <button sofButton (click)="goBack()" class="general-action-button">
          {{ tc + '.GO-BACK' | translate }}
        </button>
        <button
          *ngIf="damage?.status === DAMAGE_STATUS_CREATED"
          sofButton
          class="general-action-button main-action"
          (click)="changeStatus()"
        >
          {{ tc + '.CHANGE-STATUS-TO-FIXED' | translate }}
        </button>
        <button
          *ngIf="damage?.status === DAMAGE_STATUS_FIXED"
          sofButton
          class="general-action-button main-action"
          (click)="changeStatus()"
        >
          {{ tc + '.CHANGE-STATUS-TO-CREATED' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./damage-detail-view.component.scss']
})
export class DamageDetailViewComponent implements OnInit {
  tc = 'DAMAGE-MANAGEMENT_DAMAGE-DETAIL';
  DAMAGE_STATUS_CREATED = DamageStatusDto.CREATED;
  DAMAGE_STATUS_FIXED = DamageStatusDto.FIXED;

  damageId: string;
  vehicleId: string;
  damage: DamageDto;
  damageForm: FormGroup;

  damages$: BehaviorSubject<DamagePageDto> = new BehaviorSubject<DamagePageDto>(
    null
  );

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private sb: IncidentService,
    private location: Location,
    private toastUtilService: ToastUtilService
  ) {
    this.damageForm = this.fb.group({
      damageInfo: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params =>
      this.storeDamageInfo(params)
    );
  }

  padStart(utcDay: number): any {
    return utcDay.toString().padStart(2, '0');
  }

  getDamagePageDto(damage: DamageDto): DamagePageDto {
    if (damage == null) {
      return { damages: [] };
    }
    return {
      damages: [damage]
    };
  }

  getJsonContents(): string {
    return JSON.stringify(this.damage, undefined, 2);
  }

  private storeDamageInfo(params: any): void {
    this.damageId = params.damageId;
    this.vehicleId = params.vehicleId;
    if (this.damageId == null) {
      return;
    }
    this.sb.getDamageById(this.vehicleId, this.damageId).subscribe(
      damageDto => {
        this.damage = damageDto;
        this.updateForm();
        this.damages$.next(this.getDamagePageDto(damageDto));
      },
      error => {
        this.toastUtilService.showError(error, this.tc + '.FAILED_GET-DAMAGE');
      }
    );
  }

  private updateForm(): void {
    this.damageForm.controls.damageInfo.setValue(this.getJsonContents());
  }

  goBack(): void {
    this.location.back();
  }

  changeStatus(): void {
    let newStatus = DamageStatusDto.FIXED;
    if (this.damage.status === DamageStatusDto.FIXED) {
      newStatus = DamageStatusDto.CREATED;
    }
    this.sb.changeStatus(this.damageId, this.vehicleId, newStatus).subscribe(
      damageDto => {
        this.toastUtilService.success(
          this.tc + '.SUCCESSFUL_SET-STATUS-AS',
          true,
          { status: newStatus }
        );
        this.damage = damageDto;
        this.damageId = damageDto.id;
        this.updateForm();
        this.damages$.next(this.getDamagePageDto(damageDto));
      },
      error => {
        this.toastUtilService.showError(
          error,
          this.tc + '.FAILED_SET-STATUS-AS',
          { status: newStatus }
        );
      }
    );
  }
}
