import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatEnum, SortingOrderConfig } from '@sofico-framework/utils';
import { Observable } from 'rxjs/index';
import {
  BookingUserDto,
  DamageStatusDto,
  EnumValueDto,
  OrganizationReferenceDto,
  UserDto,
  VehicleDto
} from '../../../../../client';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import TranslateUtils from '../../../utils/translate.utils';

@Component({
  selector: 'sof-damage-filter-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="d-flex p-3">
      <sof-form [tc]="tc" [formGroup]="filterForm" class="flex-fill pr-3">
        <div class="d-flex flex-column">
          <div class="row">
            <sof-input-wrapper
              [label]="tc + '.PERIOD-FROM' | translate"
              class="col-md-2"
            >
              <sof-input-date-picker
                [sofInput]
                [formControl]="filterForm?.controls?.fromPicker"
                [dateFormat]="dateFormat"
                [showToday]="true"
              ></sof-input-date-picker>
            </sof-input-wrapper>
            <sof-input-wrapper
              [label]="tc + '.PERIOD-TO' | translate"
              class="col-md-2"
            >
              <sof-input-date-picker
                [sofInput]
                [formControl]="filterForm?.controls?.toPicker"
                [dateFormat]="dateFormat"
                [showToday]="true"
              ></sof-input-date-picker>
            </sof-input-wrapper>
            <sof-input-wrapper
              [label]="tc + '.STATUSES' | translate"
              class="col-md-8"
            >
              <sof-input-multi-select
                [sofInput]
                [tc]="tc"
                [options]="statuses$ | async"
                [formControl]="filterForm?.controls?.statuses"
                [selectorValue]="enumValueFn"
                [selectorLabel]="enumLabelFn"
              ></sof-input-multi-select>
            </sof-input-wrapper>
          </div>
          <div class="row">
            <!-- TODO - to be replaced by organization search component when existing -->
            <sof-input-wrapper
              [label]="tc + '.ORGANIZATION' | translate"
              class="col-md-4"
            >
              <sof-input-single-select
                [sofInput]
                [tc]="tc"
                [options]="
                  currentUser?.organizations | sofSort: sortingOrganizationName
                "
                [formControl]="filterForm?.controls?.organizationId"
                [selectorValue]="organizationValueFn"
                [selectorLabel]="organizationLabelFn"
              ></sof-input-single-select>
            </sof-input-wrapper>
            <!-- TODO - to be replaced by user search component when existing -->
            <sof-input-wrapper
              [label]="tc + '.USER' | translate"
              class="col-md-4"
            >
              <sof-input-single-select
                [sofInput]
                [tc]="tc"
                [options]="users"
                [formControl]="filterForm?.controls?.userId"
                [selectorValue]="userValueFn"
                [selectorLabel]="userLabelFn"
              ></sof-input-single-select>
            </sof-input-wrapper>
            <!-- TODO - to be replaced by vehicle search component when existing -->
            <sof-input-wrapper
              [label]="tc + '.VEHICLE' | translate"
              class="col-md-4"
            >
              <sof-input-single-select
                [sofInput]
                [tc]="tc"
                [options]="vehicles"
                [formControl]="filterForm?.controls?.vehicleId"
                [selectorValue]="vehicleValueFn"
                [selectorLabel]="vehicleLabelFn"
              ></sof-input-single-select>
            </sof-input-wrapper>
          </div>
        </div>
      </sof-form>
      <div class="buttons-container">
        <button
          sofButton
          class="main-action-button"
          (click)="searchDamages.emit(null)"
        >
          {{ tc + '.SHOW-DAMAGES' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./damage-filter-form.component.scss']
})
export class DamageFilterFormComponent implements OnInit {
  @Input() tc: string;
  @Input() filterForm: FormGroup;
  @Input() vehicles: Array<VehicleDto>;
  @Input() users: Array<UserDto>;
  @Input() currentUser: BookingUserDto;
  @Input() dateFormat: DateFormatEnum;
  @Output() searchDamages: EventEmitter<void> = new EventEmitter<void>();

  statuses = [DamageStatusDto.CREATED, DamageStatusDto.FIXED];

  statuses$: Observable<EnumValueDto[]>;

  sortingOrganizationName: SortingOrderConfig<OrganizationReferenceDto> = {
    prop: 'name'
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.statuses$ = TranslateUtils.getConstantTranslations$(
      this.translateService,
      this.statuses,
      this.tc,
      'DAMAGE_STATUS_'
    );
  }

  enumValueFn = e => e?.enumId;
  enumLabelFn = e => SharedUiUtils.getEnumDescription(e);
  organizationValueFn = o => o?.id;
  organizationLabelFn = o => o?.name;
  userValueFn = u => u?.remoteId;
  userLabelFn = u => SharedUiUtils.getUserDisplayName(u);
  vehicleValueFn = o => o?.id;
  vehicleLabelFn = o => o?.name;
}
