import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DropDownConfigAlt } from './types/drop-down-config-alt.type';

@Component({
  selector: `sof-drop-down-alt`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./drop-down-alt.component.scss'],
  template: `
    <ng-container *ngIf="config !== null">
      <div
        nz-dropdown
        [nzDropdownMenu]="menu"
        nzTrigger="click"
        [(nzVisible)]="visible"
        [nzPlacement]="
          !!config.dropDownPlacement ? config.dropDownPlacement : 'bottomLeft'
        "
        class="d-inline-block"
      >
        <ng-container *ngIf="isWithinNavBar; else alternative">
          <a
            [class.d-none]="isDisabled"
            [routerLink]="null"
            class="nav-link"
            [attr.id]="config.toggleButtonId"
            [ngClass]="config.toggleButtonClasses"
          >
            <ng-container *ngTemplateOutlet="buttonRef"></ng-container>
          </a>
          <a
            [class.d-none]="!isDisabled"
            class="nav-link"
            [ngClass]="config.toggleButtonClasses"
          >
            <ng-container *ngTemplateOutlet="buttonRef"></ng-container>
          </a>
        </ng-container>
        <ng-template #alternative>
          <button
            class="drop-down-button"
            type="button"
            [disabled]="isDisabled"
            [attr.id]="config.toggleButtonId"
            [ngClass]="config.toggleButtonClasses"
          >
            <ng-container *ngTemplateOutlet="buttonRef"></ng-container>
          </button>
        </ng-template>

        <nz-dropdown-menu
          #menu="nzDropdownMenu"
          [attr.aria-labelledby]="config.toggleButtonId"
        >
          <ng-content></ng-content>
        </nz-dropdown-menu>
      </div>
    </ng-container>

    <ng-template #buttonRef>
      <ng-container *ngIf="badge">
        <span class="badge badge-primary">{{ badge }}</span>
      </ng-container>
      <sof-svg-icon
        *ngIf="config.toggleButtonIcon"
        [class.mr-3]="config.toggleButtonText"
        [ngClass]="config.toggleButtonIconClasses"
        [icon]="config.toggleButtonIcon"
        [size]="config.toggleButtonIconSize"
      ></sof-svg-icon>
      <span>{{ config.toggleButtonText }}</span>
      <sof-svg-icon
        icon="batt-icon-menu-down"
        size="8"
        class="ml-2"
      ></sof-svg-icon>
    </ng-template>
  `
})
export class DropDownAltComponent implements OnChanges {
  @Input() config: DropDownConfigAlt = {};
  @Input() isDisabled: boolean;
  @Input() badge: string;
  @Input() isWithinNavBar = false;

  visible = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled?.currentValue && this.visible) {
      this.visible = false;
    }
  }
}
