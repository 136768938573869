import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsPipesModule } from '@sofico-framework/utils';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BookingDialogEventsTabComponent } from './components/booking-dialog/booking-dialog-events-tab.component';
import { BookingDialogGeneralTabComponent } from './components/booking-dialog/booking-dialog-general-tab.component';
import { DividerComponent } from './components/divider/divider.component';
import { DropDownMenuAltComponent } from './components/drop-down-menu-alt/drop-down-menu-alt.component';
import { DropDownAltComponent } from './components/drop-down-alt/drop-down-alt.component';
import { InputTextAutocompleteComponent } from './components/input-text-autocomplete/input-text-autocomplete.component';
import { SelectionListComponent } from './components/selection-list/selection-list.component';
import { UserProfileButtonMenuComponent } from './components/user-profile-button-menu/user-profile-button-menu.component';
import { UserSearchFilterFormComponent } from './components/user-search/components/user-search-filter-form/user-search-filter-form.component';
import { UserSearchListComponent } from './components/user-search/components/user-search-list/user-search-list.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { AvatarClassPipe } from './pipes/avatar-class.pipe';
import { AvatarInitialsPipe } from './pipes/avatar-initials.pipe';
import { IsAdminPipe } from './pipes/is-admin.pipe';
import { SharedUiSandbox } from './shared-ui.sandbox';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { InputSliderModule } from '@sofico-framework/ui-kit/components/input-slider';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { DataItemComponentModule } from '@sofico-framework/ui-kit/components/data-item';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { SoficoFormsModule } from '@sofico-framework/ui-kit/components/sofico-forms';
import { InputTextareaModule } from '@sofico-framework/ui-kit/components/input-textarea';
import { InputTextModule } from '@sofico-framework/ui-kit/components/input-text';
import { InputMultiSelectModule } from '@sofico-framework/ui-kit/components/input-multi-select';
import { InputSingleSelectModule } from '@sofico-framework/ui-kit/components/input-single-select';
import { InputSwitchModule } from '@sofico-framework/ui-kit/components/input-switch';
import { InputDatePickerModule } from '@sofico-framework/ui-kit/components/input-date-picker';
import { InputTimePickerModule } from '@sofico-framework/ui-kit/components/input-time-picker';
import { InputNumberModule } from '@sofico-framework/ui-kit/components/input-number';
import { InputCheckboxModule } from '@sofico-framework/ui-kit/components/input-checkbox';
import { InputRadioModule } from '@sofico-framework/ui-kit/components/input-radio';
import { TabsModule } from '@sofico-framework/ui-kit/components/tabs';
import { LoadingModule } from '@sofico-framework/ui-kit/components/loading';
import { DialogModule } from '@sofico-framework/ui-kit/components/dialog';
import { FocusModule } from '@sofico-framework/ui-kit/directives/focus';

import { SwitchModeBarComponent } from './components/switch-mode-bar/switch-mode-bar.component';
import { BookingDialogComponent } from './components/booking-dialog/booking-dialog.component';
import { ActiveFilterComponent } from './components/active-filter/active-filter.component';
import { VehicleFiltersComponent } from './components/vehicle-filters/vehicle-filters.component';
import { AddUserToApprovedUsersDialogComponent } from './components/add-user-to-approved-users-dialog/add-user-to-approved-users-dialog.component';
import { RejectBookingDialogComponent } from './components/reject-booking-dialog/reject-booking-dialog.component';
import { IntentButtonComponent } from './components/intent-button/intent-button.component';
import { IntentButtonGroupComponent } from './components/intent-button-group/intent-button-group.component';
import { AllowedButtonComponent } from './components/allowed-button/allowed-button.component';
import { AvailabilityDialogComponent } from './components/availability-dialog/availability-dialog.component';
import { AvailabilityRemoveDialogComponent } from './components/availability-remove-dialog/availability-remove-dialog.component';
import { AvailabilityEditChoicesDialogComponent } from './components/availability-edit-choices-dialog/availability-edit-choices-dialog.component';
import { ConversationDialogComponent } from './components/conversation-dialog/conversation-dialog.component';
import { ConversationMessagesComponent } from './components/conversation-messages/conversation-messages.component';
import { ConversationComponent } from './components/conversation/conversation.component';
import { ConversationMessageComponent } from './components/conversation-message/conversation-message.component';
import { ChatComponent } from './components/chat/chat.component';
import { ConversationListComponent } from './components/converstation-list/conversation-list.component';
import { AngularSplitModule } from 'angular-split';
import { TopBarNavComponent } from './components/top-bar-nav/top-bar-nav.component';
import { RouterModule } from '@angular/router';
import { ToUserImagePipe } from './pipes/to-user-image.pipe';
import { ConversationUserPipe } from './pipes/conversation-user.pipe';
import { BookingStatusComponent } from './components/booking-status/booking-status.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { VehiclesFoundLabelComponent } from './components/vehicles-found-label/vehicles-found-label.component';
import { NoResultComponent } from './components/no-result/no-result.component';
import { DateHourModule } from './components/date-hour/date-hour.module';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { ConversationSystemMessageComponent } from './components/conversation-system-message/conversation-system-message.component';
import { ToFormattedConversationDatePipe } from './pipes/to-formatted-conversation-date.pipe';
import { MaxArraySizePipe } from './pipes/max-array-size.pipe';
import { ToFormattedConversationListDatePipe } from './pipes/to-formatted-conversation-list-date.pipe';
import { ToUserDisplayNamePipe } from './pipes/to-user-display-name.pipe';
import { ConversationAttachmentMessageComponent } from './components/conversation-attachment-message/conversation-attachment-message.component';
import { OrganizationMembersToUsersPipe } from './pipes/organization-members-to-users.pipe';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserAvatarsBarComponent } from './components/user-avatars-bar/user-avatars-bar.component';
import { LettersAvatarComponent } from './components/letters-avatar/letters-avatar.component';
import { UsersToDisplayNamesPipe } from './pipes/users-to-display-names.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { BatteryStatusComponent } from './components/battery-status/battery-status.component';
import { BatteryStatusIconComponent } from './components/battery-status-icon/battery-status-icon.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { VehicleDialogComponent } from './components/vehicle-dialog/vehicle-dialog.component';
import { VehicleDialogGeneralTabContentComponent } from './components/vehicle-dialog/vehicle-dialog-general-tab-content.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { AvailabilityConflictResolutionDialogComponent } from './components/availability-conflict-resolution-dialog/availability-conflict-resolution-dialog.component';
import { TextAreaDialogComponent } from './components/text-area-dialog/text-area-dialog.component';
import { ToFormattedDatePipe } from './pipes/to-formatted-date.pipe';
import { EnumDescriptionPipe } from './pipes/enum-description.pipe';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { GpsCoordinateToLatLngLiteralPipe } from './pipes/gps-coordinate-to-lat-lng-literal.pipe';
import { LocationSearchDialogComponent } from './components/location-search-dialog/location-search-dialog.component';
import { GpsLocationToLatLngLiteralPipe } from './pipes/gps-location-to-lat-lng-literal.pipe';
import { VehicleDialogUsageTabContentComponent } from './components/vehicle-dialog/vehicle-dialog-usage-tab-content.component';
import { VehicleDialogDetailTabContentComponent } from './components/vehicle-dialog/vehicle-dialog-detail-tab-content.component';
import { VehicleDialogTelematicsTabContentComponent } from './components/vehicle-dialog/vehicle-dialog-telematics-tab-content.component';
import { VehicleDialogDamagesTabContentComponent } from './components/vehicle-dialog/vehicle-dialog-damages-tab-content.component';
import { ExtractUserDisplayNamePipe } from './pipes/extract-user-display-name.pipe';
import { ResultsFoundLabelComponent } from './components/results-found-label/results-found-label.component';
import { UserDisplayNameFromListPipe } from './pipes/user-display-name-from-list.pipe';
import { DataItemDateHourComponent } from './components/data-item-date-hour/data-item-date-hour.component';

@NgModule({
  declarations: [
    SwitchModeBarComponent,
    BookingDialogComponent,
    BookingDialogGeneralTabComponent,
    BookingDialogEventsTabComponent,
    ActiveFilterComponent,
    VehicleFiltersComponent,
    AddUserToApprovedUsersDialogComponent,
    RejectBookingDialogComponent,
    IntentButtonComponent,
    IntentButtonGroupComponent,
    AllowedButtonComponent,
    AvailabilityDialogComponent,
    AvailabilityRemoveDialogComponent,
    AvailabilityEditChoicesDialogComponent,
    AvailabilityConflictResolutionDialogComponent,
    ConversationDialogComponent,
    TextAreaDialogComponent,
    ConversationMessagesComponent,
    ConversationComponent,
    ConversationMessageComponent,
    ConversationAttachmentMessageComponent,
    ConversationSystemMessageComponent,
    ChatComponent,
    ConversationListComponent,
    TopBarNavComponent,
    ToUserDisplayNamePipe,
    ToUserImagePipe,
    ConversationUserPipe,
    OrganizationMembersToUsersPipe,
    ToFormattedConversationDatePipe,
    ToFormattedConversationListDatePipe,
    UsersToDisplayNamesPipe,
    MaxArraySizePipe,
    EnumToArrayPipe,
    BookingStatusComponent,
    VehiclesFoundLabelComponent,
    NoResultComponent,
    LettersAvatarComponent,
    UserAvatarComponent,
    UserAvatarsBarComponent,
    ClickOutsideDirective,
    BatteryStatusComponent,
    BatteryStatusIconComponent,
    VehicleDialogComponent,
    VehicleDialogGeneralTabContentComponent,
    VehicleDialogUsageTabContentComponent,
    VehicleDialogDetailTabContentComponent,
    VehicleDialogTelematicsTabContentComponent,
    VehicleDialogDamagesTabContentComponent,
    ToFormattedDatePipe,
    EnumDescriptionPipe,
    LocationSelectorComponent,
    GpsCoordinateToLatLngLiteralPipe,
    LocationSearchDialogComponent,
    GpsLocationToLatLngLiteralPipe,
    ExtractUserDisplayNamePipe,
    ResultsFoundLabelComponent,
    BookingStatusComponent,
    UserDisplayNameFromListPipe,
    DataItemDateHourComponent,
    IsAdminPipe,
    InputTextAutocompleteComponent,
    DividerComponent,
    DropDownAltComponent,
    DropDownMenuAltComponent,
    UserProfileButtonMenuComponent,
    SelectionListComponent,
    AvatarClassPipe,
    AvatarInitialsPipe,
    UserSearchFilterFormComponent,
    UserSearchListComponent,
    UserSearchComponent
  ],
  entryComponents: [
    BookingDialogComponent,
    AddUserToApprovedUsersDialogComponent,
    RejectBookingDialogComponent,
    AvailabilityDialogComponent,
    AvailabilityRemoveDialogComponent,
    AvailabilityEditChoicesDialogComponent,
    ConversationDialogComponent,
    VehicleDialogComponent,
    AvailabilityConflictResolutionDialogComponent,
    TextAreaDialogComponent,
    LocationSearchDialogComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputSliderModule,
    LicensePlateComponentModule,
    AngularSplitModule,
    SvgIconModule,
    RouterModule,
    GoogleMapsModule,
    DateHourModule,
    LoadingSpinnerModule,
    NzProgressModule,
    DataItemComponentModule,
    UtilsPipesModule,
    ButtonModule,
    SoficoFormsModule,
    InputTextareaModule,
    InputTextModule,
    InputMultiSelectModule,
    InputSingleSelectModule,
    NzDividerModule,
    InputSwitchModule,
    InputDatePickerModule,
    InputTimePickerModule,
    InputNumberModule,
    NzAutocompleteModule,
    NzDropDownModule,
    NzPopoverModule,
    InputCheckboxModule,
    InputRadioModule,
    TabsModule,
    LoadingModule,
    DialogModule,
    FocusModule,
    NzToolTipModule
  ],
  exports: [
    SwitchModeBarComponent,
    BookingDialogComponent,
    BookingDialogGeneralTabComponent,
    BookingDialogEventsTabComponent,
    ActiveFilterComponent,
    VehicleFiltersComponent,
    IntentButtonComponent,
    IntentButtonGroupComponent,
    AllowedButtonComponent,
    ConversationDialogComponent,
    ConversationMessagesComponent,
    ConversationComponent,
    ConversationMessageComponent,
    ConversationAttachmentMessageComponent,
    ConversationSystemMessageComponent,
    ChatComponent,
    ConversationListComponent,
    TopBarNavComponent,
    ToUserDisplayNamePipe,
    ToUserImagePipe,
    ConversationUserPipe,
    OrganizationMembersToUsersPipe,
    ToFormattedConversationDatePipe,
    ToFormattedConversationListDatePipe,
    UsersToDisplayNamesPipe,
    MaxArraySizePipe,
    EnumToArrayPipe,
    NoResultComponent,
    VehiclesFoundLabelComponent,
    LettersAvatarComponent,
    UserAvatarComponent,
    UserAvatarsBarComponent,
    ClickOutsideDirective,
    BatteryStatusComponent,
    BatteryStatusIconComponent,
    VehicleDialogComponent,
    VehicleDialogGeneralTabContentComponent,
    VehicleDialogUsageTabContentComponent,
    VehicleDialogDetailTabContentComponent,
    VehicleDialogTelematicsTabContentComponent,
    VehicleDialogDamagesTabContentComponent,
    EnumDescriptionPipe,
    LocationSelectorComponent,
    GpsCoordinateToLatLngLiteralPipe,
    LocationSearchDialogComponent,
    GpsLocationToLatLngLiteralPipe,
    ExtractUserDisplayNamePipe,
    ResultsFoundLabelComponent,
    BookingStatusComponent,
    UserDisplayNameFromListPipe,
    DataItemDateHourComponent,
    IsAdminPipe,
    InputTextAutocompleteComponent,
    DividerComponent,
    DropDownAltComponent,
    DropDownMenuAltComponent,
    UserProfileButtonMenuComponent,
    SelectionListComponent,
    AvatarClassPipe,
    AvatarInitialsPipe,
    AvailabilityEditChoicesDialogComponent,
    AvailabilityDialogComponent,
    UserSearchComponent
  ],
  providers: [SharedUiSandbox]
})
export class SharedUiModule {}
