/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BookingStatusDto = 'ACTIVE' | 'APPROVED' | 'AWAITING_APPROVAL' | 'CANCELED' | 'CANCELED_REMOTE' | 'REJECTED' | 'STOPPED';

export const BookingStatusDto = {
    ACTIVE: 'ACTIVE' as BookingStatusDto,
    APPROVED: 'APPROVED' as BookingStatusDto,
    AWAITINGAPPROVAL: 'AWAITING_APPROVAL' as BookingStatusDto,
    CANCELED: 'CANCELED' as BookingStatusDto,
    CANCELEDREMOTE: 'CANCELED_REMOTE' as BookingStatusDto,
    REJECTED: 'REJECTED' as BookingStatusDto,
    STOPPED: 'STOPPED' as BookingStatusDto
};

