import { Component } from '@angular/core';

@Component({
  selector: 'sof-page-not-found-view',
  template: `
    <div class="text">
      {{ tc + '.TEXT' | translate }}
    </div>
  `,
  styleUrls: ['./page-not-found-view.component.scss']
})
export class PageNotFoundViewComponent {
  tc = 'APP_PAGE_NOT_FOUND';
}
