import { doc } from 'prettier';
import { Rect } from '../../types/rect.type';
import { RelativeTooltipPos, Tooltip } from '../../types/tooltip.type';
import { Size } from '../../types/size.type';
import { Pos } from '../../types/pos.type';
import { SchedulerSetting } from '../../scheduler.setting';
import {
  getPointPosOfRect,
  getRectPosFromPoint,
  rectInside
} from './rect.utils';

// return the first absolute tooltip position which is inside the given container
// if all the tooltips are outside the container, returns the first toolitp position
export function getBestTooltipPos(
  targetRect: Rect,
  allRelativeTooltipPos: RelativeTooltipPos[],
  tooltipSize: Size,
  container: Rect
): Pos {
  for (const relativeTooltipPos of allRelativeTooltipPos) {
    // we calculate the tooltip rect
    const tooltipPos = getTooltipPos(
      targetRect,
      relativeTooltipPos,
      tooltipSize
    );
    const tooltipRect: Rect = {
      ...tooltipPos,
      ...tooltipSize
    };
    if (rectInside(tooltipRect, container)) {
      return tooltipPos;
    }
  }

  // if all tooltips dimensions exceed the window, we take the first position
  return getTooltipPos(targetRect, allRelativeTooltipPos[0], tooltipSize);
}

// this method calculate the tooltip position according to the targeted rectangle and the relative position of the tooltip
function getTooltipPos(
  targetRect: Rect,
  relativeTooltipPos: RelativeTooltipPos,
  tooltipSize: Size
): Pos {
  /* For debugging purpose
  const el = document.getElementById('rect-anchor');
  if (el) {
    el.remove();
  }
  const div = document.createElement('div');
  div.id = 'rect-anchor';
  div.style.display = 'block';
  div.style.position = 'fixed';
  div.style.width = targetRect.width + 'px';
  div.style.height = targetRect.height + 'px';
  div.style.left = targetRect.left + 'px';
  div.style.top = targetRect.top + 'px';
  div.style.backgroundColor = 'lightblue';
  div.style.border = '1px sold blue';
  div.innerHTML = '<div>Test JEBO</div>';
  document.body.appendChild(div);
  console.log('[', targetRect.width, ', ', targetRect.height, ', ', targetRect.top, ', ', targetRect.left, ']');
 */

  // get the absolute window position of the anchor point according to its rect and the given relative position
  const targetRectAnchorPos: Pos = getPointPosOfRect(
    targetRect,
    relativeTooltipPos.targetRectAnchor
  );
  // get the absolute tooltip position according to the anchor point position
  const tooltipPos: Pos = getRectPosFromPoint(
    targetRectAnchorPos,
    tooltipSize,
    relativeTooltipPos.tooltipAnchor
  );
  return { ...tooltipPos };
}

// if a is more important than b, return a number > 0
// i a is less important than b, return a number < 0
// as important, return 0
export function priorityComparison(a: Tooltip, b: Tooltip): number {
  return (
    SchedulerSetting.tooltipManager.tooltipsPriority[a.type] -
    SchedulerSetting.tooltipManager.tooltipsPriority[b.type]
  );
}
