import { Injectable } from '@angular/core';
import { BookingService } from '../services/booking.service';
import { Observable, of, combineLatest, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import {
  AvailabilityDto,
  AvailabilityTypeDto,
  BatteryStatusDto,
  BookingDto,
  BookingEventPageDto,
  BookingUserDto,
  BookingUserPageDto,
  CancelBookingRequestDto,
  ChangeApprovalStateRequestDto,
  ConflictResolutionDto,
  ConversationDto,
  ConversationTopicDto,
  CreateBookingRequestDto,
  CreateConversationRequestDto,
  CreateVehicleRequestDto,
  CrudAvailabilityResponseDto,
  DamageDto,
  DamagePageDto,
  DamageStatusDto,
  FilterCriteriaForBookingDto,
  MessageCreateDto,
  MessageDto,
  MessagePageDto,
  MessageTypeDto,
  OrganizationMemberDto,
  RecurringTypeDto,
  ReplaceBookingRequestDto,
  SearchDamageRequestDto,
  SearchUsersRequestDto,
  TelematicsDeviceDto,
  UpdateBookingRequestDto,
  UpdateVehicleRequestDto,
  VehicleDto,
  VehiclePageDto,
  VehicleSearchCriteriaDto
} from '../../../client';
import { TelematicsService } from '../services/telematics.service';
import { Store } from '@ngrx/store';
import { AppState } from '../statemanagement/app.state';
import { AvailabilityService } from '../services/availability.service';
import { VehicleUsageService } from '../services/vehicle-usage.service';
import { SettingService } from '../services/setting.service';
import { ChatService } from '../services/chat.service';
import {
  AppendNewMessagesAction,
  ClearMessagesAction,
  NewMessageAction,
  UpdateMessageAction
} from '../statemanagement/actions/messages.actions';
import {
  SetConversationsAction,
  UpdateConversationAction
} from '../statemanagement/actions/conversations.actions';
import ConvertersUtils from './utils/converters.utils';
import { AttachmentDownloadStatus } from './components/conversation-messages/types/conversation-message-attachment.type';
import { ConversationMessage } from './components/conversation-messages/types/conversation-message.type';
import { DateUtil } from '../helpers/date-util';
import { UserService } from '../services/user.service';
import { OrganizationService } from '../services/organization.service';
import { IncidentService } from '../services/incident.service';

@Injectable()
export class SharedUiSandbox {
  dateFrom$ = this.store.select(state => state.planning.dateFrom);
  viewMode$ = this.store.select(state => state.planning.viewMode);
  vehicles$ = this.store.select(state => state.vehicles);

  conversations$ = this.store.select(state => state.conversations);
  messages$ = this.store.select(state => state.messages);
  currentUser$ = this.store.select(state => state.currentUser);
  currentOrganizationId$ = this.store.select(
    state => state.planning.currentOrganizationId
  );

  vehicleColors$ = this.bookingService.vehicleColors$;
  vehicleBodyStyles$ = this.bookingService.vehicleBodyStyles$;
  vehicleBrands$ = this.bookingService.vehicleBrands$;
  intents$ = this.bookingService.intents$;
  vehicleEquipments$ = this.bookingService.vehicleEquipments$;
  vehicleModels$ = this.bookingService.vehicleModels$;
  allVehicles$ = this.bookingService.allVehicles$;
  allUsers$ = this.userService.allUsers$;

  constructor(
    private store: Store<AppState>,
    private availabilityService: AvailabilityService,
    private bookingService: BookingService,
    private vehicleUsageService: VehicleUsageService,
    private settingService: SettingService,
    private telematicsService: TelematicsService,
    private chatService: ChatService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private incidentService: IncidentService
  ) {}

  cancelBooking(
    bookingId: string,
    request: CancelBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingService.cancelBooking(bookingId, request);
  }

  updateBookingApprovalState(
    bookingId: string,
    changeApprovalStateRequest: ChangeApprovalStateRequestDto
  ): Observable<BookingDto> {
    return this.bookingService.updateBookingApprovalState(
      bookingId,
      changeApprovalStateRequest
    );
  }

  replaceBooking(request: ReplaceBookingRequestDto): Observable<BookingDto> {
    return this.bookingService.replaceBooking(request);
  }

  createBooking(
    createBookingRequest: CreateBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingService.createBooking(createBookingRequest);
  }

  updateBooking(
    bookingId: string,
    updateBookingRequest: UpdateBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingService.updateBooking(bookingId, updateBookingRequest);
  }

  startVehicleUsage(
    bookingId: string,
    vehicleId: string
  ): Observable<BookingDto> {
    return this.vehicleUsageService
      .startVehicleUsage(bookingId, vehicleId)
      .pipe(
        switchMap(vehicleUsageDto => this.bookingService.getBooking(bookingId))
      );
  }

  endVehicleUsage(
    bookingId: string,
    vehicleId: string
  ): Observable<BookingDto> {
    return this.vehicleUsageService
      .endVehicleUsage(bookingId, vehicleId)
      .pipe(
        switchMap(vehicleUsageDto => this.bookingService.getBooking(bookingId))
      );
  }

  updateAvailability(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    createRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<AvailabilityDto> {
    return this.availabilityService.updateAvailability(
      availabilityId,
      vehicleId,
      fromDate,
      toDate,
      createRecurringSchedule,
      removeRecurringSchedule,
      recurringType,
      daysOfWeek,
      endDate,
      interval
    );
  }

  updateAvailabilities(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    createRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<CrudAvailabilityResponseDto> {
    return this.availabilityService.updateAvailabilities(
      availabilityId,
      vehicleId,
      fromDate,
      toDate,
      createRecurringSchedule,
      removeRecurringSchedule,
      allDayEvent,
      conflictResolutionDto,
      recurringType,
      daysOfWeek,
      endDate,
      interval
    );
  }

  createAvailability(
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createRecurringSchedule: boolean,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<AvailabilityDto> {
    return this.availabilityService.createAvailability(
      vehicleId,
      fromDate,
      toDate,
      type,
      createRecurringSchedule,
      recurringType,
      daysOfWeek,
      endDate,
      interval
    );
  }

  createAvailabilities(
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<CrudAvailabilityResponseDto> {
    return this.availabilityService.createAvailabilities(
      vehicleId,
      fromDate,
      toDate,
      type,
      createRecurringSchedule,
      allDayEvent,
      conflictResolutionDto,
      recurringType,
      daysOfWeek,
      endDate,
      interval
    );
  }

  removeAvailability(
    availabilityId: string,
    removeRecurringSchedule?: boolean
  ): Observable<any> {
    return this.availabilityService.removeAvailability(
      availabilityId,
      removeRecurringSchedule
    );
  }

  searchVehicles(
    vehicleSearchCriteriaDto: VehicleSearchCriteriaDto
  ): Observable<VehiclePageDto> {
    return this.bookingService.searchVehicles(vehicleSearchCriteriaDto);
  }

  getBookingFilters(
    bookingId: string
  ): Observable<FilterCriteriaForBookingDto> {
    return this.bookingService.getBookingFilters(bookingId);
  }

  canChangeUser(): Observable<boolean> {
    return this.settingService.canChangeUser$;
  }

  // CHAT METHODS
  getConversation(id: string): Observable<ConversationDto> {
    return this.chatService.getConversation(id);
  }

  addMessage(conversationId: string, message: string): Observable<boolean> {
    const messageCreateDto: MessageCreateDto = {
      message
    };
    return this.chatService.addMessage(conversationId, messageCreateDto).pipe(
      tap((newMessage: MessageDto) =>
        this.handleSentMessage(conversationId, newMessage, null)
      ),
      take(1),
      mapTo(true)
    );
  }

  private handleSentMessage(
    conversationId: string,
    newMessage: MessageDto,
    file: File,
    oldId: string = null
  ): void {
    if (!!oldId) {
      this.store.dispatch(
        new UpdateMessageAction(
          oldId,
          ConvertersUtils.convertMessageDtoToConversationMessage(
            newMessage,
            file
          )
        )
      );
    } else {
      this.store.dispatch(
        new NewMessageAction(
          ConvertersUtils.convertMessageDtoToConversationMessage(
            newMessage,
            file
          )
        )
      );
    }
    this.store.dispatch(
      new UpdateConversationAction(conversationId, {
        lastMessage: {
          ...newMessage
        }
      } as ConversationDto)
    );
  }

  createConversation(
    bookingId?: string,
    firstMessage?: string,
    topic?: ConversationTopicDto,
    vehicleId?: string
  ): Observable<ConversationDto> {
    const request: CreateConversationRequestDto = {
      bookingId,
      firstMessage,
      topic,
      vehicleId
    };
    return this.chatService.createConversation(request);
  }

  stopPolling(): void {
    this.chatService.stopPolling();
  }

  stopMessagesPolling(): void {
    this.chatService.stopMessagesPolling();
  }

  pollConversations(): void {
    this.chatService.pollConversations().subscribe(page => {
      this.store.dispatch(new SetConversationsAction(page?.conversations));
    });
  }

  pollMessagesByConversation(
    conversationId: string
  ): Observable<MessagePageDto> {
    this.store.dispatch(new ClearMessagesAction());
    this.chatService.stopMessagesPolling();
    return this.chatService.pollMessagesByConversation(conversationId).pipe(
      tap(page => {
        this.store.dispatch(
          new AppendNewMessagesAction(
            ConvertersUtils.convertMessageDtosToConversationMessages(
              page?.messages
            )
          )
        );
        // Get attachments
        this.messages$.pipe(take(1)).subscribe(messages => {
          this.getAttachments(conversationId, messages)
            .pipe(take(1))
            .subscribe();
        });
      })
    );
  }

  updateDownloadStatusState(
    message: ConversationMessage,
    downloadStatus: AttachmentDownloadStatus
  ): void {
    this.store.dispatch(
      new UpdateMessageAction(message.id, {
        ...message,
        attachment: {
          ...message.attachment,
          downloadStatus
        }
      })
    );
  }

  downloadAttachment(
    conversationId: string,
    message: ConversationMessage
  ): Observable<any> {
    this.updateDownloadStatusState(message, AttachmentDownloadStatus.PENDING);
    return this.chatService
      .getAttachment(message.attachment.id, conversationId)
      .pipe(
        tap(data =>
          this.updateDownloadStatusState(
            message,
            AttachmentDownloadStatus.NOT_STARTED
          )
        ),
        take(1),
        catchError(error => {
          this.updateDownloadStatusState(
            message,
            AttachmentDownloadStatus.NOT_STARTED
          );
          return throwError(error);
        })
      );
  }

  getAttachments(
    conversationId: string,
    messages: ConversationMessage[]
  ): Observable<any> {
    const filteredMessages = messages
      .filter(
        message => message.messageType === MessageTypeDto.ATTACHMENTMESSAGE
      )
      .filter(
        message =>
          message.attachment &&
          !message.attachment.data &&
          message.attachment.isImage
      )
      .filter(
        message =>
          message.attachment.downloadStatus ===
          AttachmentDownloadStatus.NOT_STARTED
      );
    const arrOfStreams: Array<Observable<any>> = filteredMessages.map(message =>
      this.getAttachment(conversationId, message)
    );
    if (!!arrOfStreams && arrOfStreams.length > 0) {
      return combineLatest(arrOfStreams);
    }
    return of(null);
  }

  getAttachment(
    conversationId: string,
    message: ConversationMessage
  ): Observable<any> {
    this.updateDownloadStatusState(message, AttachmentDownloadStatus.PENDING);
    return this.chatService
      .getAttachment(message.attachment.id, conversationId)
      .pipe(
        tap(data => {
          this.store.dispatch(
            new UpdateMessageAction(message.id, {
              ...message,
              attachment: {
                ...message.attachment,
                data,
                downloadStatus: AttachmentDownloadStatus.SUCCESSFUL
              }
            })
          );
        }),
        take(1),
        catchError(reponse => {
          this.updateDownloadStatusState(
            message,
            AttachmentDownloadStatus.FAILED
          );
          return of(null);
        })
      );
  }

  getConversations(): Observable<boolean> {
    return this.chatService.getConversations().pipe(
      tap(page =>
        this.store.dispatch(new SetConversationsAction(page?.conversations))
      ),
      take(1),
      mapTo(true)
    );
  }

  getMessages(conversationId: string, since?: string): Observable<boolean> {
    return this.chatService.getMessages(conversationId, since).pipe(
      tap(page => {
        this.store.dispatch(new ClearMessagesAction());
        this.store.dispatch(
          new AppendNewMessagesAction(
            ConvertersUtils.convertMessageDtosToConversationMessages(
              page?.messages
            )
          )
        );
      }),
      take(1),
      mapTo(true)
    );
  }

  searchBookingEventsForBooking(
    bookingId: string
  ): Observable<BookingEventPageDto> {
    return this.bookingService
      .searchBookingEventsForBooking(bookingId)
      .pipe(take(1));
  }

  createTemporaryAttachment(
    conversationId: string,
    currentUser: BookingUserDto,
    file: File,
    index: number = 0
  ): ConversationMessage {
    const message = {
      id: new Date().getTime().toString() + index,
      messageType: MessageTypeDto.ATTACHMENTMESSAGE,
      sender: ConvertersUtils.convertBookingUserDtoToUserReferenceDto(
        currentUser
      ),
      timeStamp: DateUtil.toISOString(new Date()),
      temporary: true,
      attachment: {
        name: file.name,
        data: file,
        downloadStatus: AttachmentDownloadStatus.PENDING,
        size: file.size,
        mimeType: ConvertersUtils.completeMimeType(null, file.name)
      }
    } as ConversationMessage;
    message.attachment.isImage = ConvertersUtils.isImageAttachment(
      message.attachment
    );
    return message;
  }

  retryAddAttachment(
    conversationId: string,
    message: ConversationMessage
  ): Observable<boolean> {
    const tempMessage: ConversationMessage = {
      ...message,
      timeStamp: DateUtil.toISOString(new Date()),
      attachment: {
        ...message.attachment,
        downloadStatus: AttachmentDownloadStatus.PENDING
      }
    };
    this.store.dispatch(new UpdateMessageAction(tempMessage.id, tempMessage));
    return this.addAttachmentImpl(conversationId, tempMessage);
  }

  addAttachment(
    conversationId: string,
    currentUser: BookingUserDto,
    file: File
  ): Observable<boolean> {
    const tempMessage: ConversationMessage = this.createTemporaryAttachment(
      conversationId,
      currentUser,
      file
    );
    this.store.dispatch(new NewMessageAction(tempMessage));
    return this.addAttachmentImpl(conversationId, tempMessage);
  }

  private addAttachmentImpl(
    conversationId: string,
    tempMessage: ConversationMessage
  ): Observable<boolean> {
    const file = tempMessage.attachment.data as File;
    return this.chatService.addAttachment(conversationId, file).pipe(
      tap((newMessage: MessageDto) => {
        // TODO - TO BE REMOVED WHEN BACKEND FIXED
        newMessage.attachment.name = tempMessage.attachment.name;
        newMessage.attachment.mimeType = tempMessage.attachment.mimeType;
        this.handleSentMessage(
          conversationId,
          newMessage,
          file,
          tempMessage.id
        );
      }),
      catchError(error => {
        const newMessage = {
          ...tempMessage,
          attachment: {
            ...tempMessage.attachment,
            downloadStatus: AttachmentDownloadStatus.FAILED
          }
        };
        this.store.dispatch(
          new UpdateMessageAction(tempMessage.id, newMessage)
        );
        return throwError(error);
      }),
      take(1),
      mapTo(true)
    );
  }

  getBooking(bookingId: string): Observable<BookingDto> {
    return this.bookingService.getBooking(bookingId).pipe(take(1));
  }

  getBookingUser(userId: string): Observable<BookingUserDto> {
    return this.userService.getBookingUser(userId).pipe(take(1));
  }

  getBatteryStatus(vehicleId: string): Observable<BatteryStatusDto> {
    return this.telematicsService.getBatteryStatus(vehicleId);
  }

  createVehicle(request: CreateVehicleRequestDto): Observable<VehicleDto> {
    return this.bookingService.createVehicleV2(request);
  }

  updateVehicle(
    vehicleId: string,
    request: UpdateVehicleRequestDto
  ): Observable<VehicleDto> {
    return this.bookingService.updateVehicleV2(vehicleId, request);
  }

  uploadVehicleImage(vehicleId: string, file: File): Observable<VehicleDto> {
    return this.bookingService.uploadVehicleImage(vehicleId, file);
  }

  changeLockStatus(vehicleId: string, lock: boolean): Observable<any> {
    return this.telematicsService.changeLockStatus(vehicleId, lock);
  }

  getTelematics(vehicleId: string): Observable<TelematicsDeviceDto> {
    return this.telematicsService.getTelematics(vehicleId);
  }

  updateTelematics(
    vehicleId: string,
    providerDeviceId: string,
    providerId: string,
    maxRange: number
  ): Observable<any> {
    return this.telematicsService.updateTelematics(
      providerDeviceId,
      providerId,
      vehicleId,
      maxRange
    );
  }

  createTelematics(
    vehicleId: string,
    providerDeviceId: string,
    providerId: string,
    maxRange: number
  ): Observable<any> {
    return this.telematicsService.createTelematics(
      providerDeviceId,
      providerId,
      vehicleId,
      maxRange
    );
  }

  getOrganizationMember(
    organizationId: string,
    userId: string
  ): Observable<OrganizationMemberDto> {
    // TODO - replace by a dedicated service when available
    return this.organizationService
      .getOrganization(organizationId)
      .pipe(
        map(organizationDto =>
          organizationDto?.members?.find(
            member => member?.user?.remoteId === userId
          )
        )
      );
  }

  searchDamages(requestDto: SearchDamageRequestDto): Observable<DamagePageDto> {
    return this.incidentService.searchDamages(requestDto);
  }

  changeDamageStatus(
    damageId: string,
    vehicleId: string,
    newStatus: DamageStatusDto
  ): Observable<DamageDto> {
    return this.incidentService.changeStatus(damageId, vehicleId, newStatus);
  }

  searchUsers(
    searchUsersRequestDto: SearchUsersRequestDto
  ): Observable<BookingUserPageDto> {
    return this.userService.searchUsers(searchUsersRequestDto);
  }
}
