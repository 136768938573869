import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-divider',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <nz-divider
        [class.small]="size === 'small'"
        [class.thin]="size === 'thin'"
      ></nz-divider>
    </ng-container>
  `,
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {
  @Input() size: 'normal' | 'small' | 'thin' = 'small';
}
