import { Injectable } from '@angular/core';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  SchedulerLike,
  Subject,
  timer
} from 'rxjs';
import {
  ChatControllerImplService,
  ConversationDto,
  ConversationPageDto,
  CreateConversationRequestDto,
  MessageCreateDto,
  MessageDto,
  MessagePageDto,
  SearchConversationsRequestDto
} from '../../../client';
import {
  catchError,
  finalize,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  // private conversationsPollingThreshold = 5000;
  private conversationsPollingThreshold = 50000;
  private retryAfterFailedPollingAttemptThreshold = 5000;
  private messagesPollingMinThreshold = 1000;
  private messagesPollingMaxThreshold = 20000;
  // private messagesPollingWhenNoLastMessageThreshold = 2500;
  private messagesPollingWhenNoLastMessageThreshold = 25000;
  private lastMessage = null;
  private triggerMessagePoll$ = new BehaviorSubject<boolean>(true);
  private destroyConversationsPolling$ = new Subject();
  private destroyMessagesPolling$ = new Subject();
  scheduler: SchedulerLike = asyncScheduler;
  private pollConversations$: Observable<ConversationPageDto> = null;
  private pollMessages$: Observable<ConversationPageDto> = null;

  constructor(private chatControllerImplService: ChatControllerImplService) {}

  stopPolling(): void {
    this.stopConversationsPolling();
    this.stopMessagesPolling();
  }

  stopConversationsPolling(): void {
    this.destroyConversationsPolling$.next();
  }

  stopMessagesPolling(): void {
    this.destroyMessagesPolling$.next();
  }

  pollConversations(): Observable<ConversationPageDto> {
    if (this.pollConversations$ === null) {
      this.pollConversations$ = timer(
        0,
        this.conversationsPollingThreshold,
        this.scheduler
      ).pipe(
        catchError(() => of({})),
        switchMap(() => this.getConversations()),
        takeUntil(this.destroyConversationsPolling$),
        finalize(() => {
          this.pollConversations$ = null;
        })
      );
    }
    return this.pollConversations$;
  }

  // Simple polling with a timer
  // TODO manage calculateMessageDelay
  pollMessagesByConversation(
    conversationId: string
  ): Observable<MessagePageDto> {
    this.lastMessage = null;
    return combineLatest([
      timer(1, this.messagesPollingWhenNoLastMessageThreshold),
      this.triggerMessagePoll$
    ]).pipe(
      catchError(() => of({})),
      switchMap(() =>
        this.loadCurrentMessagesByDate(conversationId).pipe(
          tap(messagePageDto => {
            if (messagePageDto?.messages?.length > 0) {
              this.lastMessage = messagePageDto.messages[0];
            }
          })
        )
      ),
      takeUntil(this.destroyMessagesPolling$)
    );
  }

  private calculateMessageDelay(): number {
    if (!this.lastMessage) {
      return this.messagesPollingWhenNoLastMessageThreshold;
    }

    const subtractDates =
      new Date().getTime() - new Date(this.lastMessage.timeStamp).getTime();
    const delta =
      Math.floor(subtractDates / 6) + this.messagesPollingMinThreshold;
    return delta > this.messagesPollingMaxThreshold
      ? this.messagesPollingMaxThreshold
      : delta;
  }

  loadCurrentMessagesByDate(
    conversationId: string
  ): Observable<MessagePageDto> {
    const since =
      this.lastMessage && this.lastMessage.timeStamp
        ? this.lastMessage.timeStamp
        : null;
    return this.getMessages(conversationId, since);
  }

  createConversation(
    request: CreateConversationRequestDto
  ): Observable<ConversationDto> {
    return this.chatControllerImplService.createConversation(request);
  }

  searchConversations(
    request: SearchConversationsRequestDto
  ): Observable<ConversationPageDto> {
    return this.chatControllerImplService.searchConversations(request);
  }

  searchBookingConversations(
    bookingId: string
  ): Observable<ConversationPageDto> {
    const request: SearchConversationsRequestDto = {
      bookingId
    };
    return this.chatControllerImplService.searchConversations(request);
  }

  addMessage(
    conversationId: string,
    message: MessageCreateDto
  ): Observable<MessageDto> {
    return this.chatControllerImplService
      .addMessage(conversationId, message)
      .pipe(
        tap(messageDto => {
          this.lastMessage = messageDto;
          this.triggerMessagePoll$.next(true);
        })
      );
  }

  getMessages(
    conversationId: string,
    since?: string
  ): Observable<MessagePageDto> {
    return this.chatControllerImplService.getMessages(conversationId, since);
  }

  getConversation(conversationId: string): Observable<ConversationDto> {
    return this.chatControllerImplService.getConversation(conversationId);
  }

  getConversations(): Observable<ConversationPageDto> {
    return this.chatControllerImplService.getConversations();
  }

  getAttachment(attachmentId: string, conversationId: string): Observable<any> {
    return this.chatControllerImplService.getAttachment(
      attachmentId,
      conversationId
    );
  }

  addAttachment(conversationId: string, file?: Blob): Observable<MessageDto> {
    return this.chatControllerImplService.addAttachment(conversationId, file);
  }
}
