import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { VehicleCardFieldsToDisplay } from '../../../../types/vehicle-card-fields-to-display.type';

@Component({
  selector: 'sof-vehicle-card-fields-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="vehicle-type-title">Pool vehicles</div>

    <div class="vehicle-field-list">
      <label
        *ngFor="
          let fieldAndLabel of vehicleFieldsAndLabels?.poolVehicle;
          trackBy: trackByIndex
        "
        class="vehicle-field"
        [ngClass]="fieldAndLabel.field"
        [class.notSelected]="!fieldsToDisplay.poolVehicle[fieldAndLabel.field]"
        (click)="onVehicleFieldClick('poolVehicle', fieldAndLabel.field)"
      >
        <div class="value">
          {{ fieldAndLabel.label }}
        </div>
      </label>
    </div>
  `,
  styleUrls: ['./vehicle-card-fields-manager.component.scss']
})
export class VehicleCardFieldsManagerComponent {
  @Input() fieldsToDisplay: VehicleCardFieldsToDisplay;
  @Input()
  vehicleFieldsAndLabels: {
    poolVehicle: { field: string; label: string }[];
  };
  @Output()
  newFieldsToDisplay: EventEmitter<VehicleCardFieldsToDisplay> = new EventEmitter<VehicleCardFieldsToDisplay>();

  onVehicleFieldClick(vehicle: 'poolVehicle', field: string): void {
    // local copy of the current state
    const newFieldsToDisplay: VehicleCardFieldsToDisplay = {
      poolVehicle: { ...this.fieldsToDisplay.poolVehicle }
    };
    // change of the state
    newFieldsToDisplay[vehicle][field] = !this.fieldsToDisplay[vehicle][field];

    this.newFieldsToDisplay.emit(newFieldsToDisplay);
  }

  trackByIndex = i => i;
}
