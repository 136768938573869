import { Pipe, PipeTransform } from '@angular/core';
import {
  OrganizationDto,
  UserReferenceDto
} from '../../../../client';

@Pipe({ name: 'organizationMembersToUsers' })
export class OrganizationMembersToUsersPipe implements PipeTransform {
  transform(organization: OrganizationDto): Array<UserReferenceDto> {
    if (!organization?.members) {
      return null;
    }
    return organization.members.map(member => member.user);
  }
}
