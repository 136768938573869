import { Vehicle } from '../../types/vehicle.type';
import { ActionTypes } from '../action-types';

export class SetVehiclesAction {
  type = ActionTypes.SET_VEHICLES;
  payload: { vehicles: Vehicle[] };

  constructor(vehicles: Vehicle[]) {
    this.payload = { vehicles };
  }
}

export type VehiclesActions = SetVehiclesAction;
