import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/index';
import { map, take } from 'rxjs/operators';
import { EnumValueDto } from '../../../client';
import { TranslatedData } from '../types/translated-data.type';

export default class TranslateUtils {
  public static getConstantTranslations$(
    translateService: TranslateService,
    values: string[],
    tc: string,
    constantRoot: string
  ): Observable<EnumValueDto[]> {
    return translateService
      .get(values.map(status => tc + '.' + constantRoot + status))
      .pipe(
        take(1),
        map(translations => {
          return Object.keys(translations).map(key => {
            return {
              enumId: key.replace(tc + '.' + constantRoot, ''),
              translation: translations[key]
            } as EnumValueDto;
          });
        })
      );
  }
  public static getTranslatedData$(
    translateService: TranslateService,
    toBeTranslated: TranslatedData[]
  ): Observable<TranslatedData[]> {
    return translateService.get(toBeTranslated.map(d => d.translation)).pipe(
      take(1),
      map(translations => {
        return toBeTranslated.map(d => {
          return {
            value: d.value,
            translation: translations[d.translation]
          };
        });
      })
    );
  }
}
