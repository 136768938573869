import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-data-item-date-hour',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-data-item>
      <ng-container data-item-label>
        {{ label }}
      </ng-container>
      <ng-container data-item-value>
        <sof-date-hour [date]="date"></sof-date-hour>
      </ng-container>
    </sof-data-item>
  `
})
export class DataItemDateHourComponent {
  @Input() label: string;
  @Input() date: Date | string;
}
