import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { BookingRequest } from '../../../../types/booking-request.type';
import { SchedulerSetting } from '../../../../scheduler.setting';
import { Changes } from 'ngx-reactivetoolkit';
import { fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'sof-requested-vehicles-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./requested-vehicles-list.component.scss'],
  template: `
    <div class="scrollbox" #scrollbox>
      <div class="body">
        <sof-request-line
          *ngFor="let bookingRequest of bookingRequests; trackBy: trackByIndex"
          [style.height.px]="rowHeightPx"
          [bookingRequest]="bookingRequest"
          (click)="onItemClick(bookingRequest)"
          (mouseenter)="onItemHover(bookingRequest.internalId)"
          [class.selected]="bookingRequestSelectedId"
          [class.hovered]="bookingRequestHoveredId"
        >
        </sof-request-line>
      </div>
    </div>
  `
})
export class RequestedVehiclesListComponent
  implements OnChanges, AfterViewInit {
  @Input() bookingRequests: BookingRequest[];
  @Input() bookingRequestSelectedId: string;
  @Input() bookingRequestHoveredId: string;

  @Input() scrollTop: number;
  @Output() newScrollTop: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  newBookingRequestSelected: EventEmitter<BookingRequest> = new EventEmitter<BookingRequest>();

  @Output()
  newBookingRequestHoveredId: EventEmitter<string> = new EventEmitter<string>();

  @Changes('scrollTop') scrollTop$: Observable<number>;

  @ViewChild('scrollbox') scrollBox: ElementRef;

  rowHeightPx = SchedulerSetting.subRowMinHeightPx;

  ngAfterViewInit(): void {
    this.scrollTop$.subscribe(
      scrollTop => (this.scrollBox.nativeElement.scrollTop = scrollTop)
    );

    fromEvent(this.scrollBox.nativeElement, 'scroll').subscribe(
      (event: any) => {
        const scrollTop = event.target.scrollTop;
        if (scrollTop > 0) {
          this.newScrollTop.emit(scrollTop);
        }
      }
    );
  }

  onItemClick(bookingRequest: BookingRequest): void {
    this.newBookingRequestSelected.emit(
      this.bookingRequestSelectedId !== bookingRequest.internalId
        ? bookingRequest
        : null
    );
  }

  onItemHover(itemHoverId: string): void {
    this.newBookingRequestHoveredId.emit(
      this.bookingRequestHoveredId !== itemHoverId ? itemHoverId : null
    );
  }

  trackByIndex = i => i;

  ngOnChanges(): void {}
}
