import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'sof-allowed-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      sofButton
      [icon]="svgIcon"
      [ngClass]="{
        allowed: localValue,
        notallowed: localValue !== null && !localValue
      }"
      (click)="onClick()"
      [disabled]="disabled"
    >
      <div class="allowed-button-label">{{ label }}</div>
    </button>
  `,
  styleUrls: ['./allowed-button.component.scss']
})
export class AllowedButtonComponent {
  @Input() disabled: boolean;
  @Input() set value(value: null | boolean) {
    this.localValue = value;
    this.updateSvgIcon();
  }
  @Input() label: string;
  svgIcon: string;
  localValue: null | boolean = null;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClick(): void {
    if (this.localValue === null) {
      this.localValue = true;
    } else if (this.localValue) {
      this.localValue = false;
    } else {
      this.localValue = null;
    }
    this.updateSvgIcon();
    this.valueChange.emit(this.localValue);
  }

  updateSvgIcon(): void {
    if (this.localValue) {
      this.svgIcon = 'batt-icon-check';
    } else if (this.localValue !== null) {
      this.svgIcon = 'batt-icon-exclude';
    } else {
      this.svgIcon = null;
    }
  }
}
