import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetRouterState, hotSafe } from '@sofico-framework/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap
} from 'rxjs/operators';
import {
  OrganizationDto,
  OrganizationRoleDto,
  VehicleGroupDto,
  VehicleGroupPageDto
} from  '../../../../client';
import { ToastUtilService } from '../../services/toast-util.service';
import { OrganizationWithDetail } from '../../types/organization-with-detail.type';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';

@Injectable()
export class OrganizationViewHelper {
  triggerOrganizationSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );
  triggerVehicleGroupsSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );

  organizationId$ = this.getOrganizationId$();
  organization$ = this.getOrganization$();
  vehicleGroups$ = this.getVehicleGroups$();
  organizationWithDetail$ = this.getOrganizationWithDetail$();

  @GetRouterState()
  private routerState$: Observable<any>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private activatedRoute: ActivatedRoute
  ) {}

  private getOrganization$(): Observable<OrganizationDto> {
    return combineLatest([
      this.organizationId$,
      this.triggerOrganizationSub$
    ]).pipe(
      switchMap(([organizationId, trigger]) => {
        if (!organizationId) {
          return of(null);
        }
        return this.osb.getOrganization(organizationId);
      }),
      shareReplay(1)
    );
  }

  private getVehicleGroups$(): Observable<VehicleGroupDto[]> {
    return combineLatest([
      this.organizationId$,
      this.triggerVehicleGroupsSub$
    ]).pipe(
      switchMap(([organizationId, trigger]) =>
        this.osb.getVehicleGroups(organizationId).pipe(
          catchError(error => {
            this.toastUtilService.showError(
              error,
              '@COMMON.FAILED_GET-VEHICLE-GROUP'
            );
            return of({} as VehicleGroupPageDto);
          })
        )
      ),
      map(vehicleGroupPageDto => vehicleGroupPageDto?.vehicleGroups),
      shareReplay(1)
    );
  }

  private getOrganizationWithDetail$(): Observable<OrganizationWithDetail> {
    return this.organization$.pipe(
      mergeMap(organization => {
        const organizationWithDetail: OrganizationWithDetail = {
          ...organization
        };
        organizationWithDetail.membersByRole = {};
        organizationWithDetail.membersByRole[
          OrganizationRoleDto.MANAGER
        ] = organization?.members?.filter(
          member => member.organizationRoleDto === OrganizationRoleDto.MANAGER
        );
        organizationWithDetail.membersByRole[
          OrganizationRoleDto.MEMBER
        ] = organization?.members?.filter(
          member => member.organizationRoleDto === OrganizationRoleDto.MEMBER
        );
        return of(organizationWithDetail);
      })
    );
  }

  private getOrganizationId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.organizationId),
      switchMap(([p, state]) => of(p.organizationId)),
      hotSafe()
    );
  }

  triggerOrganization(): void {
    this.triggerOrganizationSub$.next(new Date());
    this.triggerVehicleGroups();
  }

  triggerVehicleGroups(): void {
    this.triggerVehicleGroupsSub$.next(new Date());
  }
}
