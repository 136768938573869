import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import {
  CreateDriverGradeFilterRequestDto,
  UpdateDriverGradeFilterRequestDto
} from  '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationDriverGradeDetailViewHelper } from '../../view-helpers/organization-driver-grade-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-driver-grade-filter-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="
        (!vh?.editedDriverGradeFilter
          ? tc + '.CREATE-RULES'
          : tc + '.UPDATE-RULES'
        ) | translate
      "
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="driverGradeForm" class="flex-fill">
            <sof-input-wrapper
              [label]="tc + '.DRIVER-GRADE-DETAIL-NAME' | translate"
            >
              <sof-input-text
                sofFocus
                [sofInput]
                [formControl]="driverGradeForm?.controls?.name"
              >
              </sof-input-text>
            </sof-input-wrapper>
            <sof-divider></sof-divider>
            <div class="row section-title">
              {{ tc + '.DRIVER-GRADE-DETAIL-VEHICLE-SECTION' | translate }}
            </div>
            <sof-input-wrapper
              [label]="
                tc + '.DRIVER-GRADE-DETAIL-PRICE-RANGE-PER-DAY' | translate
              "
            >
              <sof-input-slider
                range="true"
                [minValue]="PRICE_RANGE_MIN_VALUE"
                [maxValue]="PRICE_RANGE_MAX_VALUE"
                [labelFormatFn]="formatPrice"
                formControlName="priceRange"
              ></sof-input-slider>
            </sof-input-wrapper>
            <sof-input-wrapper
              [label]="tc + '.DRIVER-GRADE-DETAIL-MAKE-MODEL' | translate"
            >
              <sof-input-multi-select
                [sofInput]
                [tc]="tc"
                [options]="vh.vehicleModels$ | async"
                [formControl]="driverGradeForm?.controls?.modelIds"
                [selectorValue]="vh.keyLabelValueFn"
                [selectorLabel]="vh.keyLabelLabelFn"
              >
              </sof-input-multi-select>
            </sof-input-wrapper>
            <sof-input-wrapper
              [label]="tc + '.DRIVER-GRADE-DETAIL-VEHICLE-GROUPS' | translate"
            >
              <sof-input-multi-select
                [sofInput]
                [tc]="tc"
                [options]="vh.vehicleGroupOptions$ | async"
                [formControl]="driverGradeForm?.controls?.vehicleGroupIds"
                [selectorValue]="vh.keyLabelValueFn"
                [selectorLabel]="vh.keyLabelLabelFn"
              ></sof-input-multi-select>
            </sof-input-wrapper>
            <sof-divider></sof-divider>
            <div class="row section-title">
              {{ tc + '.DRIVER-GRADE-DETAIL-BOOKING-SECTION' | translate }}
            </div>
            <sof-input-wrapper
              [label]="
                tc + '.DRIVER-GRADE-DETAIL-MAX-WEEKS-IN-FUTURE' | translate
              "
            >
              <sof-input-slider
                [minValue]="MAX_DAY_FUTURE_END_MIN_VALUE"
                [maxValue]="MAX_DAY_FUTURE_END_MAX_VALUE"
                [labelFormatFn]="
                  formatWeeksFn(
                    tc + '.DRIVER-GRADE-DETAIL-NO-PREFERENCE' | translate,
                    tc + '.DRIVER-GRADE-DETAIL-WEEK' | translate,
                    tc + '.DRIVER-GRADE-DETAIL-WEEKS' | translate
                  )
                "
                formControlName="maxDayFutureEnd"
              ></sof-input-slider>
            </sof-input-wrapper>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            *ngIf="!!vh?.editedDriverGradeFilter"
            (click)="onRemoveClick()"
            class="general-action-button"
          >
            {{ tc + '.REMOVE' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./driver-grade-filter-dialog-view.component.scss']
})
export class DriverGradeFilterDialogViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_DRIVER-GRADE-FILTER-DIALOG';

  PRICE_RANGE_MIN_VALUE = ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE;
  PRICE_RANGE_MAX_VALUE = ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE;
  MAX_DAY_FUTURE_END_MIN_VALUE = 0;
  MAX_DAY_FUTURE_END_MAX_VALUE = 53;

  driverGradeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private dialogService: DialogService,
    public vh: OrganizationDriverGradeDetailViewHelper
  ) {}

  ngOnInit(): void {
    const name = this.vh.editedDriverGradeFilter
      ? this.vh.editedDriverGradeFilter.name
      : '';
    const rangePrice: [number, number] = [
      ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
      ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
    ];
    if (!!this.vh.editedDriverGradeFilter?.minDayPrice) {
      rangePrice[0] = this.vh.editedDriverGradeFilter.minDayPrice;
    }
    if (!!this.vh.editedDriverGradeFilter?.maxDayPrice) {
      rangePrice[1] = this.vh.editedDriverGradeFilter.maxDayPrice;
    }
    const modelIds = this.vh.editedDriverGradeFilter
      ? this.vh.editedDriverGradeFilter.modelIds
      : [];
    const vehicleGroupIds = this.vh.editedDriverGradeFilter
      ? this.vh.editedDriverGradeFilter.vehicleGroups.map(
          vehicleGroup => vehicleGroup.id
        )
      : [];

    this.driverGradeForm = this.fb.group({
      name: [name, Validators.required],
      priceRange: [rangePrice],
      modelIds: [modelIds],
      vehicleGroupIds: [vehicleGroupIds],
      maxDayFutureEnd: [
        this.vh.editedDriverGradeFilter
          ? Math.floor(this.vh.editedDriverGradeFilter.maxDayFutureEnd / 7)
          : 0
      ]
    });
  }

  onRemoveClick(): void {
    const confirm$ = combineLatest([
      this.vh.driverGradeId$,
      this.vh.organizationId$
    ]).pipe(
      switchMap(([driverGradeId, organizationId]) => {
        return this.osb.deleteDriverGradeFilter(
          this.vh.editedDriverGradeFilter.id,
          driverGradeId,
          organizationId
        );
      }),
      take(1)
    );
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'DELETE-DIALOG-TITLE',
      'DELETE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() => confirm$.pipe(catchError(error => of(error)))),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(
            v,
            this.tc + '.FAILED_REMOVE-DRIVER-GRADE-FILTER'
          );
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REMOVE-DRIVER-GRADE-FILTER',
            true
          );
          dialog.destroy();
          this.closeAndRefresh();
        }
      });
  }

  closeAndRefresh(): void {
    this.router
      .navigate(['..'], { relativeTo: this.activatedRoute })
      .then(() => {
        this.vh.triggerDriverGradeFilters();
      });
  }

  hasAtLeastOneFilter(): boolean {
    return (
      this.driverGradeForm.value.priceRange[0] !== this.PRICE_RANGE_MIN_VALUE ||
      this.driverGradeForm.value.priceRange[1] !== this.PRICE_RANGE_MAX_VALUE ||
      this.driverGradeForm.value.modelIds?.length > 0 ||
      this.driverGradeForm.value.vehicleGroupIds?.length > 0 ||
      this.driverGradeForm.value.maxDayFutureEnd > 0
    );
  }

  onSaveClick(): void {
    if (!this.driverGradeForm.valid) {
      return;
    }

    if (!this.hasAtLeastOneFilter()) {
      this.toastUtilService.error(
        this.tc + '.PLEASE-ADD-AT-LEAST-ONE-RULE',
        true
      );
      return;
    }

    if (!!this.vh.editedDriverGradeFilter) {
      const requestDto: UpdateDriverGradeFilterRequestDto = {
        name: this.driverGradeForm.value.name,
        driverGradeFilterId: this.vh.editedDriverGradeFilter.id,
        minDayPrice:
          this.driverGradeForm.value.priceRange[0] !==
          this.PRICE_RANGE_MIN_VALUE
            ? this.driverGradeForm.value.priceRange[0]
            : undefined,
        maxDayPrice:
          this.driverGradeForm.value.priceRange[1] !==
          this.PRICE_RANGE_MAX_VALUE
            ? this.driverGradeForm.value.priceRange[1]
            : undefined,
        modelIds:
          this.driverGradeForm.value.modelIds?.length > 0
            ? this.driverGradeForm.value.modelIds
            : [],
        vehicleGroupIds:
          this.driverGradeForm.value.vehicleGroupIds?.length > 0
            ? this.driverGradeForm.value.vehicleGroupIds
            : [],
        maxDayFutureEnd: this.driverGradeForm.value.maxDayFutureEnd * 7
      };
      combineLatest([this.vh.driverGradeId$, this.vh.organizationId$])
        .pipe(
          switchMap(([driverGradeId, organizationId]) => {
            return this.osb.updateDriverGradeFilter(
              this.vh.editedDriverGradeFilter.id,
              driverGradeId,
              organizationId,
              requestDto
            );
          }),
          take(1),
          takeUntilDestroy(this)
        )
        .subscribe(
          driverGrade => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_UPDATE-DRIVER-GRADE-FILTER',
              true
            );
            this.closeAndRefresh();
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_UPDATE-DRIVER-GRADE-FILTER'
            );
          }
        );
    } else {
      const requestDto: CreateDriverGradeFilterRequestDto = {
        name: this.driverGradeForm.value.name,
        minDayPrice:
          this.driverGradeForm.value.priceRange[0] !==
          this.PRICE_RANGE_MIN_VALUE
            ? this.driverGradeForm.value.priceRange[0]
            : undefined,
        maxDayPrice:
          this.driverGradeForm.value.priceRange[1] !==
          this.PRICE_RANGE_MAX_VALUE
            ? this.driverGradeForm.value.priceRange[1]
            : undefined,
        modelIds:
          this.driverGradeForm.value.modelIds?.length > 0
            ? this.driverGradeForm.value.modelIds
            : undefined,
        vehicleGroupIds:
          this.driverGradeForm.value.vehicleGroupIds?.length > 0
            ? this.driverGradeForm.value.vehicleGroupIds
            : undefined,
        maxDayFutureEnd: this.driverGradeForm.value.maxDayFutureEnd * 7
      };
      combineLatest([this.vh.driverGradeId$, this.vh.organizationId$])
        .pipe(
          switchMap(([driverGradeId, organizationId]) => {
            return this.osb.createDriverGradeFilter(
              driverGradeId,
              organizationId,
              requestDto
            );
          }),
          take(1),
          takeUntilDestroy(this)
        )
        .subscribe(
          driverGrade => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_CREATE-DRIVER-GRADE-FILTER',
              true
            );
            this.closeAndRefresh();
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_CREATE-DRIVER-GRADE-FILTER'
            );
          }
        );
    }
  }

  formatPrice(value: number): string {
    return '€' + value;
  }

  formatWeeksFn = (
    noPreferenceLabel: string,
    weekLabel: string,
    weeksLabel: string
  ) => (value: number) => {
    if (value === 0) {
      return `${noPreferenceLabel}`;
    } else if (value === 1) {
      return `${value} ${weekLabel}`;
    }
    return `${value} ${weeksLabel}`;
  };
}
