import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingRequest } from '../../../../types/booking-request.type';

@Component({
  selector: 'sof-request-line',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./request-line.component.scss'],
  template: `
    <div class="vehicle-field" *ngIf="bookingRequest.client.lastname">
      <span class="value">
        {{ bookingRequest.client.firstname }}
        {{ bookingRequest.client.lastname }}
      </span>
    </div>
    <div class="vehicle-field category" *ngIf="bookingRequest.needs?.category">
      <span class="value">{{ bookingRequest.needs?.category }}</span>
    </div>
    <div class="vehicle-field color" *ngIf="bookingRequest.needs?.color">
      <span class="value" [style.color]="bookingRequest.needs?.color">
        {{ bookingRequest.needs?.color }}
        <i class="fa fa-circle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="vehicle-field gear" *ngIf="bookingRequest.needs?.gear">
      <span class="value">{{ bookingRequest.needs?.gear }}</span>
    </div>
  `
})
export class RequestLineComponent {
  @Input() bookingRequest: BookingRequest;
}
