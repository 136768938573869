import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BookingRequest } from '../../../../types/booking-request.type';
import { SchedulerSetting } from '../../../../scheduler.setting';

@Component({
  selector: 'sof-booking-request-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-booking-request-list-header
      [numberOfBookingRequests]="bookingRequests?.length"
      [style.height.px]="headerTopHeightPx"
    >
    </sof-booking-request-list-header>

    <div class="body">
      <sof-booking-request-item
        *ngFor="let bookingRequest of bookingRequests; trackBy: trackByIndex"
        [bookingRequest]="bookingRequest"
        [selected]="bookingRequest.internalId === bookingRequestSelectedId"
        (click)="onItemClick(bookingRequest)"
      >
      </sof-booking-request-item>
      <!-- TODO booking request on click : add an input 'selected' -->
    </div>
  `,
  styleUrls: ['./booking-request-list.component.scss']
})
export class BookingRequestListComponent {
  @Input() bookingRequests: BookingRequest[];
  @Input() bookingRequestSelectedId: string;

  @Output()
  newBookingRequestSelected: EventEmitter<BookingRequest> = new EventEmitter<BookingRequest>();

  headerTopHeightPx = SchedulerSetting.headerHeightPx.topPart;

  trackByIndex = i => i;

  onItemClick(bookingRequest: BookingRequest): void {
    this.newBookingRequestSelected.emit(
      this.bookingRequestSelectedId !== bookingRequest.internalId
        ? bookingRequest
        : null
    );
  }
}
