import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetRouterState, hotSafe } from '@sofico-framework/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap
} from 'rxjs/operators';
import {
  DriverGradeDto,
  DriverGradeFilterDto,
  OrganizationDto,
  VehicleGroupDto,
  VehicleGroupPageDto
} from  '../../../../client';
import { ToastUtilService } from '../../services/toast-util.service';
import { KeyLabel } from '../../types/key-label.type';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';

@Injectable()
export class OrganizationDriverGradeDetailViewHelper {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-DRIVER-GRADE-DETAIL';
  private triggerDriverGradeFiltersSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );
  private triggerDriverGradeSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );

  editedDriverGradeFilter: DriverGradeFilterDto;

  organizationId$ = this.getOrganizationId$();
  organization$ = this.getOrganization$();
  driverGradeId$ = this.getDriverGradeId$();
  driverGrade$ = this.getDriverGrade$();
  driverGradeFilters$ = this.getDriverGradesFilters$();
  vehicleGroups$ = this.getVehicleGroups$();
  vehicleGroupOptions$ = this.getVehicleGroupOptions$();
  vehicleModels$ = this.osb.vehicleModels$;

  @GetRouterState()
  private routerState$: Observable<any>;

  // Selectors
  keyLabelValueFn = v => v?.key;
  keyLabelLabelFn = v => v?.label;

  constructor(
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private activatedRoute: ActivatedRoute
  ) {}

  private getOrganization$(): Observable<OrganizationDto> {
    // As there is no getDriverGrade service, we need to get the organization to have the driver grade
    return combineLatest([
      this.organizationId$,
      this.triggerDriverGradeSub$
    ]).pipe(
      switchMap(([organizationId, trigger]) => {
        if (!organizationId) {
          return of(null);
        }
        return this.osb.getOrganization(organizationId).pipe(
          catchError(error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_GET-ORGANIZATION'
            );
            return of(null);
          })
        );
      }),
      shareReplay(1)
    );
  }

  private getDriverGrade$(): Observable<DriverGradeDto> {
    return combineLatest([this.organization$, this.driverGradeId$]).pipe(
      map(([organization, driverGradeId]) => {
        return organization?.driverGrades?.find(
          driverGrades => driverGrades?.id === driverGradeId
        );
      }),
      shareReplay(1)
    );
  }

  private getDriverGradesFilters$(): Observable<DriverGradeFilterDto[]> {
    return combineLatest([
      this.driverGradeId$,
      this.organizationId$,
      this.triggerDriverGradeFiltersSub$
    ]).pipe(
      switchMap(([driverGradeId, organizationId, trigger]) => {
        if (!driverGradeId || !organizationId) {
          return of(null);
        }
        return this.osb
          .getDriverGradeFilters(driverGradeId, organizationId)
          .pipe(
            map(
              driverGradeFiltersPageDto =>
                driverGradeFiltersPageDto?.driverGradeFilterDtos
            )
          );
      }),
      shareReplay(1)
    );
  }

  private getVehicleGroups$(): Observable<VehicleGroupDto[]> {
    return this.organizationId$.pipe(
      switchMap(organizationId =>
        this.osb.getVehicleGroups(organizationId).pipe(
          catchError(error => {
            this.toastUtilService.showError(
              error,
              '@COMMON.FAILED_GET-VEHICLE-GROUP'
            );
            return of({} as VehicleGroupPageDto);
          })
        )
      ),
      map(vehicleGroupPageDto => vehicleGroupPageDto?.vehicleGroups),
      shareReplay(1)
    );
  }

  private getVehicleGroupOptions$(): Observable<KeyLabel[]> {
    return this.vehicleGroups$.pipe(
      map(vehicleGroups => {
        return vehicleGroups?.map(vehicleGroup => {
          return {
            key: vehicleGroup.id,
            label: vehicleGroup.name
          } as KeyLabel;
        });
      })
    );
  }

  private getOrganizationId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.organizationId),
      switchMap(([p, state]) => of(p.organizationId)),
      hotSafe()
    );
  }

  private getDriverGradeId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.driverGradeId),
      switchMap(([p, state]) => of(p.driverGradeId)),
      hotSafe()
    );
  }

  triggerDriverGradeFilters(): void {
    this.triggerDriverGradeFiltersSub$.next(new Date());
  }

  triggerDriverGrade(): void {
    this.triggerDriverGradeSub$.next(new Date());
  }
}
