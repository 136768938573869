import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  DamagePageDto,
  SearchDamageRequestDto
} from '../../../client';
import { BookingService } from '../services/booking.service';
import { IncidentService } from '../services/incident.service';
import { UserService } from '../services/user.service';
import { AppState } from '../statemanagement/app.state';

@Injectable()
export class DamageManagementSandbox {
  currentUser$ = this.store.select(state => state.currentUser);
  allVehicles$ = this.bookingService.allVehicles$;
  allUsers$ = this.userService.allUsers$;

  constructor(
    private damageService: IncidentService,
    private bookingService: BookingService,
    private userService: UserService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  searchDamages(requestDto: SearchDamageRequestDto): Observable<DamagePageDto> {
    return this.damageService.searchDamages(requestDto);
  }

  navigateToEditDamage(vehicleId: string, damageId: string): void {
    this.router.navigate(['detail-damage', vehicleId, damageId]);
  }
}
