import {
  FilterCriteriaForBookingDto,
  GpsCoordinateDto,
  GpsLocationDto,
  VehicleGroupDto,
  VehicleGroupReferenceDto,
  VehicleSearchCriteriaDto
} from '../../../client';
import { ActiveFilter } from '../types/active-filter.type';
import { VehicleFilterType } from '../types/vehicle-filter.type';
import { Intent } from '../types/intent.type';
import { KeyLabel } from '../types/key-label.type';
import { ActiveSchedulerFilters } from '../types/active-scheduler-filters.type';
import { TranslateService } from '@ngx-translate/core';

export default class ActiveFiltersUtils {
  public static GPS_DE_PUNT_LONGITUDE = 3.749593;
  public static GPS_DE_PUNT_LATITUDE = 51.043928;
  public static PRICE_RANGE_MIN_VALUE = 0;
  public static PRICE_RANGE_MAX_VALUE = 100;
  public static VEHICLE_RANGE_MIN_VALUE = 50;
  public static VEHICLE_RANGE_MAX_VALUE = 510;
  public static MAX_DISTANCE_MIN_VALUE = 0;
  public static MAX_DISTANCE_MAX_VALUE = 25;
  public static MAX_DISTANCE_DEFAULT_VALUE = 10;
  public static GPS_COORDINATE_DEFAULT_VALUE: GpsCoordinateDto = {
    latitude: ActiveFiltersUtils.GPS_DE_PUNT_LATITUDE,
    longitude: ActiveFiltersUtils.GPS_DE_PUNT_LONGITUDE
  };
  public static GPS_LOCATION_DEFAULT_VALUE: GpsLocationDto = {
    address: 'De Punt',
    gpsCoordinateDto: ActiveFiltersUtils.GPS_COORDINATE_DEFAULT_VALUE
  };

  private static areArraysEqual(arr1: Array<any>, arr2: Array<any>): boolean {
    if (!arr1 !== !arr2) {
      return false;
    }
    if (!arr1 && !arr2) {
      return true;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    let i = arr1.length;
    while (i--) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  private static areGpsCoordinatesEqual(
    gps1: GpsCoordinateDto,
    gps2: GpsCoordinateDto
  ): boolean {
    if (!gps1 !== !gps2) {
      return false;
    }
    if (!gps1 && !gps2) {
      return true;
    }
    return gps1.latitude === gps2.latitude && gps1.longitude === gps2.longitude;
  }

  private static areFiltersEqual(
    filter1: ActiveFilter,
    filter2: ActiveFilter
  ): boolean {
    if (filter1.vehicleFilterType !== filter2.vehicleFilterType) {
      return false;
    }
    if (filter1.value instanceof Array) {
      return ActiveFiltersUtils.areArraysEqual(filter1.value, filter2.value);
    }
    if (filter1.vehicleFilterType === VehicleFilterType.LOCATION) {
      return ActiveFiltersUtils.areGpsCoordinatesEqual(
        filter1.value,
        filter2.value
      );
    }
    return filter1.value === filter2.value;
  }

  public static removeActiveFilters(
    activeSchedulerFilters: ActiveSchedulerFilters,
    activeFilter: ActiveFilter
  ): ActiveSchedulerFilters {
    const newActiveSchedulerFilters = JSON.parse(
      JSON.stringify(activeSchedulerFilters)
    );
    newActiveSchedulerFilters.activeFilters = newActiveSchedulerFilters.activeFilters.filter(
      filter => !ActiveFiltersUtils.areFiltersEqual(filter, activeFilter)
    );
    switch (activeFilter.vehicleFilterType) {
      case VehicleFilterType.INTENT:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.intentIds = newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.intentIds.filter(
          intentId => intentId !== activeFilter.value
        );
        break;
      case VehicleFilterType.VEHICLE_NAME:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.name = null;
        break;
      case VehicleFilterType.ONLY_FAVORITES:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.onlyFavorites = false;
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.favoritesUserId = null;
        break;
      case VehicleFilterType.ONLY_INSTANT_BOOKING_POSSIBLE:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.onlyInstantBookingPossible = false;
        break;
      case VehicleFilterType.MINIMUM_RANGE:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.minimumRange =
          ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE;
        break;
      case VehicleFilterType.DAY_PRICE:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.minDayPrice =
          ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE;
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.maxDayPrice =
          ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE;
        break;
      case VehicleFilterType.VEHICLE_MODEL:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.modelIds = newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.modelIds.filter(
          modelId => modelId !== activeFilter.value
        );
        break;
      case VehicleFilterType.MINIMUM_SEATS:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.minimumSeats = 0;
        break;
      case VehicleFilterType.ABROAD_ALLOWED:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.permissions.abroadAllowed = null;
        break;
      case VehicleFilterType.KIDS_ALLOWED:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.permissions.kidsAllowed = null;
        break;
      case VehicleFilterType.PETS_ALLOWED:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.permissions.petsAllowed = null;
        break;
      case VehicleFilterType.EQUIPMENT:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.equipmentIds = newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.equipmentIds.filter(
          equipmentId => equipmentId !== activeFilter.value
        );
        break;
      case VehicleFilterType.MAXIMUM_DISTANCE:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.maxDistance =
          ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE;
        break;
      case VehicleFilterType.LOCATION:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.location = {
          ...ActiveFiltersUtils.GPS_COORDINATE_DEFAULT_VALUE
        };
        break;
      case VehicleFilterType.FAVORITES_USER_ID:
        newActiveSchedulerFilters.vehicleSearchCriteriaDto.filterCriteria.favoritesUserId = null;
        break;
    }
    return newActiveSchedulerFilters;
  }

  public static getGpsLocationDto(
    filterCriteria: FilterCriteriaForBookingDto
  ): GpsLocationDto {
    return filterCriteria.vehicleFilterCriteriaDto.location
      ? {
          address: filterCriteria.address,
          gpsCoordinateDto: {
            ...filterCriteria.vehicleFilterCriteriaDto.location
          }
        }
      : { ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE };
  }

  public static getVehicleSearchCriteriaDtoForFilterCriteriaForBookingDto(
    filterCriteria: FilterCriteriaForBookingDto
  ): VehicleSearchCriteriaDto {
    const criteria = filterCriteria.vehicleFilterCriteriaDto;
    const location: GpsLocationDto = ActiveFiltersUtils.getGpsLocationDto(
      filterCriteria
    );
    const vehicleSearchCriteria: VehicleSearchCriteriaDto = {
      filterCriteria: {
        location: location.gpsCoordinateDto,
        equipmentIds: criteria.equipmentIds,
        permissions: criteria.permissions,
        minimumSeats: criteria.minimumSeats,
        modelIds: criteria.modelIds,
        maxDayPrice: criteria.maxDayPrice,
        minDayPrice: criteria.minDayPrice,
        minimumRange: criteria.minimumRange,
        onlyInstantBookingPossible: criteria.onlyInstantBookingPossible,
        onlyFavorites: criteria.onlyFavorites,
        // TODO - to be uncommented when backend ready
        // favoritesUserId = criteria.favoritesUserId,
        maxDistance: criteria.maxDistance,
        intentIds: criteria.intentIds,
        bodyStyleIds: criteria.bodyStyleIds,
        name: criteria.name,
        licensePlate: criteria.licensePlate
      }
    };
    return vehicleSearchCriteria;
  }

  public static getFilterCriteriaForBookingDto(
    selectedIntents: Array<string>,
    vehicleName: string,
    onlyFavorites: boolean,
    instantBook: boolean,
    vehicleRange: number,
    priceRange: [number, number],
    selectedSeats: number,
    abroadAllowed: boolean,
    kidsAllowed: boolean,
    petsAllowed: boolean,
    selectedEquipments: Array<string>,
    selectedVehicleModels: Array<string>,
    selectedLocation: GpsLocationDto,
    selectedMaxDistance: number,
    licensePlate: string,
    bookingUserId: string,
    currentUserId: string
  ): FilterCriteriaForBookingDto {
    const filterCriteria: FilterCriteriaForBookingDto = {
      vehicleFilterCriteriaDto: {}
    };
    this.updateIntentsCriteria(filterCriteria, selectedIntents);
    this.updateTimeAndPlaceCriteria(
      filterCriteria,
      selectedLocation,
      selectedMaxDistance
    );
    this.updateVehiclesCriteria(
      filterCriteria,
      vehicleName,
      onlyFavorites,
      instantBook,
      vehicleRange,
      priceRange,
      selectedSeats,
      abroadAllowed,
      kidsAllowed,
      petsAllowed,
      selectedEquipments,
      selectedVehicleModels,
      licensePlate,
      bookingUserId,
      currentUserId
    );
    if (!filterCriteria.vehicleFilterCriteriaDto.location) {
      filterCriteria.vehicleFilterCriteriaDto.location = {
        ...this.GPS_LOCATION_DEFAULT_VALUE.gpsCoordinateDto
      };
      filterCriteria.address = this.GPS_LOCATION_DEFAULT_VALUE.address;
    }
    return filterCriteria;
  }

  public static fillOutActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    intents: Array<Intent>,
    vehicleModels: Array<KeyLabel>,
    equipments: Array<KeyLabel>,
    intentActiveFilters: Array<ActiveFilter>,
    timeAndPlaceActiveFilters: Array<ActiveFilter>,
    vehicleActiveFilters: Array<ActiveFilter>
  ): void {
    this.fillOutIntentsActiveFilters(
      filterCriteria,
      intents,
      intentActiveFilters
    );
    this.fillOutTimeAndPlaceActiveFilters(
      filterCriteria,
      timeAndPlaceActiveFilters
    );
    this.fillOutVehicleActiveFilters(
      filterCriteria,
      vehicleModels,
      equipments,
      vehicleActiveFilters
    );
  }

  public static getOtherActiveFiltersForDriverGradeFilter(
    priceRange: [number, number],
    selectedVehicleModels: Array<string>,
    vehicleModels: Array<KeyLabel>
  ): Array<ActiveFilter> {
    const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
    if (this.isActiveFilterPriceRange(priceRange)) {
      activeFilters.push(this.createPriceRangeActiveFilter(priceRange));
    }
    const cleanSelectedVehicleModels = this.removeEmptyValues(
      selectedVehicleModels
    );
    if (this.isActiveFilterVehicleModels(cleanSelectedVehicleModels)) {
      for (const vehicleModelId of cleanSelectedVehicleModels) {
        activeFilters.push(
          this.createVehicleModelActiveFilter(vehicleModels, vehicleModelId)
        );
      }
    }
    return activeFilters;
  }

  public static getBookingActiveFiltersForDriverGradeFilter(
    translateService: TranslateService,
    tc: string,
    maxDayFutureEnd: number
  ): Array<ActiveFilter> {
    const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
    if (maxDayFutureEnd > 0) {
      activeFilters.push(
        this.createMaxDayFutureEndActiveFilter(
          translateService,
          tc,
          maxDayFutureEnd
        )
      );
    }
    return activeFilters;
  }

  public static getVehicleGroupActiveFiltersForDriverGradeFilter(
    selectedVehicleGroups: Array<VehicleGroupReferenceDto>
  ): Array<ActiveFilter> {
    const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
    if (selectedVehicleGroups?.length > 0) {
      for (const vehicleGroup of selectedVehicleGroups) {
        activeFilters.push(this.createVehicleGroupActiveFilter(vehicleGroup));
      }
    }
    return activeFilters;
  }

  private static removeEmptyValues(values: string[]): string[] {
    if (!values) {
      return [];
    }
    return values.filter(value => !!value);
  }

  private static updateIntentsCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    selectedIntents: Array<string>
  ): void {
    const cleanSelectedIntents = this.removeEmptyValues(selectedIntents);
    if (cleanSelectedIntents && cleanSelectedIntents.length > 0) {
      filterCriteria.vehicleFilterCriteriaDto.intentIds = cleanSelectedIntents;
    }
  }

  private static fillOutIntentsActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    intents: Array<Intent>,
    intentActiveFilters: Array<ActiveFilter>
  ): void {
    if (filterCriteria?.vehicleFilterCriteriaDto?.intentIds?.length > 0) {
      filterCriteria.vehicleFilterCriteriaDto.intentIds.forEach(intentId => {
        intentActiveFilters.push({
          active: true,
          value: intentId,
          label: this.getIntentLabel(intents, intentId),
          vehicleFilterType: VehicleFilterType.INTENT
        });
      });
    }
  }

  private static getIntentLabel(
    intents: Array<Intent>,
    enumId: string
  ): string {
    return intents?.find(intent => intent.enumId === enumId)?.label;
  }

  private static updateTimeAndPlaceCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    location: GpsLocationDto,
    maxDistance: number
  ): void {
    if (
      location &&
      (location.gpsCoordinateDto.latitude !== this.GPS_DE_PUNT_LATITUDE ||
        location.gpsCoordinateDto.longitude !== this.GPS_DE_PUNT_LONGITUDE)
    ) {
      filterCriteria.vehicleFilterCriteriaDto.location = {
        ...location.gpsCoordinateDto
      };
      filterCriteria.address = location.address;
    }

    if (maxDistance && maxDistance !== this.MAX_DISTANCE_DEFAULT_VALUE) {
      filterCriteria.vehicleFilterCriteriaDto.maxDistance = maxDistance;
    }
  }

  private static fillOutTimeAndPlaceActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    timeAndPlaceActiveFilters: Array<ActiveFilter>
  ): void {
    if (
      !!filterCriteria?.vehicleFilterCriteriaDto?.location &&
      (filterCriteria.vehicleFilterCriteriaDto.location.latitude !==
        this.GPS_DE_PUNT_LATITUDE ||
        filterCriteria.vehicleFilterCriteriaDto.location.longitude !==
          this.GPS_DE_PUNT_LONGITUDE)
    ) {
      timeAndPlaceActiveFilters.push({
        active: true,
        value: filterCriteria.vehicleFilterCriteriaDto.location,
        vehicleFilterType: VehicleFilterType.LOCATION,
        translateKey: 'FILTER_CRITERIA_LOCATION',
        interpolateParams: {
          address: filterCriteria.address
        }
      });
    }

    if (
      !!filterCriteria?.vehicleFilterCriteriaDto?.maxDistance &&
      filterCriteria.vehicleFilterCriteriaDto.maxDistance !==
        this.MAX_DISTANCE_DEFAULT_VALUE
    ) {
      timeAndPlaceActiveFilters.push({
        active: true,
        value: filterCriteria.vehicleFilterCriteriaDto.maxDistance,
        vehicleFilterType: VehicleFilterType.MAXIMUM_DISTANCE,
        translateKey: 'FILTER_CRITERIA_DISTANCE',
        interpolateParams: {
          maxDistance: filterCriteria.vehicleFilterCriteriaDto.maxDistance
        }
      });
    }
  }

  private static updateVehiclesCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    vehicleName: string,
    onlyFavorites: boolean,
    instantBook: boolean,
    vehicleRange: number,
    priceRange: [number, number],
    selectedSeats: number,
    abroadAllowed: boolean,
    kidsAllowed: boolean,
    petsAllowed: boolean,
    selectedEquipments: Array<string>,
    selectedVehicleModels: Array<string>,
    licensePlate: string,
    bookingUserId: string,
    currentUserId: string
  ): void {
    this.updateLicensePlateCriteria(filterCriteria, licensePlate);
    this.updateVehicleNameCriteria(filterCriteria, vehicleName);
    this.updateOnlyFavoritesCriteria(
      filterCriteria,
      onlyFavorites,
      bookingUserId,
      currentUserId
    );
    this.updateInstantBookCriteria(filterCriteria, instantBook);
    this.updateVehicleRangeCriteria(filterCriteria, vehicleRange);
    this.updatePriceRangeCriteria(filterCriteria, priceRange);
    this.updateVehicleModelsCriteria(filterCriteria, selectedVehicleModels);
    this.updateSeatsCriteria(filterCriteria, selectedSeats);
    this.updatePetsAllowedCriteria(filterCriteria, petsAllowed);
    this.updateKidsAllowedCriteria(filterCriteria, kidsAllowed);
    this.updateAbroadAllowedCriteria(filterCriteria, abroadAllowed);
    this.updateEquipmentsCriteria(filterCriteria, selectedEquipments);
  }

  private static fillOutVehicleActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    vehicleModels: Array<KeyLabel>,
    equipments: Array<KeyLabel>,
    vehicleActiveFilters: Array<ActiveFilter>
  ): void {
    this.fillOutLicensePlateActiveFilter(filterCriteria, vehicleActiveFilters);
    this.fillOutVehicleNameActiveFilter(filterCriteria, vehicleActiveFilters);
    this.fillOutOnlyFavoritesActiveFilters(
      filterCriteria,
      vehicleActiveFilters
    );
    this.fillOutInstantBookActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutVehicleRangeActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutPriceRangeActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutVehicleModelsActiveFilters(
      filterCriteria,
      vehicleModels,
      vehicleActiveFilters
    );
    this.fillOutSeatsActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutPetsAllowedActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutKidsAllowedActiveFilters(filterCriteria, vehicleActiveFilters);
    this.fillOutAbroadAllowedActiveFilters(
      filterCriteria,
      vehicleActiveFilters
    );
    this.fillOutEquipmentsActiveFilters(
      filterCriteria,
      equipments,
      vehicleActiveFilters
    );
  }

  private static updateVehicleNameCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    vehicleName: string
  ): void {
    if (vehicleName && vehicleName.trim().length > 0) {
      filterCriteria.vehicleFilterCriteriaDto.name = vehicleName.trim();
    }
  }

  private static updateLicensePlateCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    licensePlate: string
  ): void {
    if (licensePlate && licensePlate.trim().length > 0) {
      filterCriteria.vehicleFilterCriteriaDto.licensePlate = licensePlate.trim();
    }
  }

  private static fillOutVehicleNameActiveFilter(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (filterCriteria?.vehicleFilterCriteriaDto?.name?.length > 0) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.VEHICLE_NAME,
        value: filterCriteria.vehicleFilterCriteriaDto.name,
        translateKey: 'FILTER_CRITERIA_VEHICLE_NAME',
        interpolateParams: {
          vehicleName: filterCriteria.vehicleFilterCriteriaDto.name
        }
      });
    }
  }

  private static fillOutLicensePlateActiveFilter(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (filterCriteria?.vehicleFilterCriteriaDto?.licensePlate?.length > 0) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.LICENSE_PLATE,
        value: filterCriteria.vehicleFilterCriteriaDto.licensePlate,
        translateKey: 'FILTER_CRITERIA_LICENSE_PLATE',
        interpolateParams: {
          licensePlate: filterCriteria.vehicleFilterCriteriaDto.licensePlate
        }
      });
    }
  }

  private static updateOnlyFavoritesCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    onlyFavorites: boolean,
    bookingUserId: string,
    currentUserId: string
  ): void {
    if (onlyFavorites) {
      filterCriteria.vehicleFilterCriteriaDto.onlyFavorites = onlyFavorites;

      if (
        !!currentUserId &&
        !!bookingUserId &&
        currentUserId !== bookingUserId
      ) {
        // TODO - to be uncommented when backend ready
        // filterCriteria.vehicleFilterCriteriaDto.favoritesUserId = bookingUserId;
      }
    }
  }

  private static fillOutOnlyFavoritesActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (!!filterCriteria?.vehicleFilterCriteriaDto?.onlyFavorites) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.ONLY_FAVORITES,
        value: true,
        translateKey: 'FILTER_CRITERIA_ONLY_FAVORITES'
      });

      /* TODO - to be uncommented when backend ready
      if (!!filterCriteria?.vehicleFilterCriteriaDto?.favoritesUserId) {
        activeFilters.push({
          active: true,
          label: `Requester's favorites`,
          vehicleFilterType: VehicleFilterType.FAVORITES_USER_ID,
          value: filterCriteria?.vehicleFilterCriteriaDto?.favoritesUserId
        });
      }
       */
    }
  }

  private static updateInstantBookCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    instantBook: boolean
  ): void {
    if (instantBook) {
      filterCriteria.vehicleFilterCriteriaDto.onlyInstantBookingPossible = instantBook;
    }
  }

  private static fillOutInstantBookActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (!!filterCriteria.vehicleFilterCriteriaDto.onlyInstantBookingPossible) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.ONLY_INSTANT_BOOKING_POSSIBLE,
        value: true,
        translateKey: 'FILTER_CRITERIA_INSTANT_BOOK'
      });
    }
  }

  private static updateVehicleRangeCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    vehicleRange: number
  ): void {
    if (vehicleRange > this.VEHICLE_RANGE_MIN_VALUE) {
      filterCriteria.vehicleFilterCriteriaDto.minimumRange = vehicleRange;
    } else if (vehicleRange < 0) {
      // TODO - Temporary fix. To be replaced when phase in / phase out of a vehicle will be implemented
      filterCriteria.vehicleFilterCriteriaDto.minimumRange = vehicleRange;
    }
  }

  private static fillOutVehicleRangeActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (
      filterCriteria?.vehicleFilterCriteriaDto?.minimumRange >
      this.VEHICLE_RANGE_MIN_VALUE
    ) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.MINIMUM_RANGE,
        value: filterCriteria.vehicleFilterCriteriaDto.minimumRange,
        translateKey: 'FILTER_CRITERIA_VEHICLE_RANGE',
        interpolateParams: {
          minimumRange: filterCriteria.vehicleFilterCriteriaDto.minimumRange
        }
      });
    }
  }

  private static createMaxDayFutureEndActiveFilter(
    translateService: TranslateService,
    tc: string,
    maxDayFutureEnd: number
  ): ActiveFilter {
    const weeks = Math.floor(maxDayFutureEnd / 7);
    return {
      active: true,
      label: translateService.instant(
        tc + '.ACTIVE-FILTER-MAX-WEEKS-IN-FUTURE',
        { weeks }
      ),
      vehicleFilterType: VehicleFilterType.MAX_DAY_FUTURE_END,
      value: weeks
    };
  }

  private static isActiveFilterPriceRange(
    priceRange: [number, number]
  ): boolean {
    return (
      priceRange &&
      priceRange[0] <= priceRange[1] &&
      priceRange[1] !== this.PRICE_RANGE_MIN_VALUE &&
      (priceRange[0] !== this.PRICE_RANGE_MIN_VALUE ||
        priceRange[1] !== this.PRICE_RANGE_MAX_VALUE)
    );
  }

  private static createPriceRangeActiveFilter(
    priceRange: [number, number]
  ): ActiveFilter {
    return {
      active: true,
      vehicleFilterType: VehicleFilterType.DAY_PRICE,
      value: priceRange,
      translateKey: 'FILTER_CRITERIA_PRICE_RANGE',
      interpolateParams: {
        minPrice: priceRange[0],
        maxPrice: priceRange[1]
      }
    };
  }

  private static updatePriceRangeCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    priceRange: [number, number]
  ): void {
    if (this.isActiveFilterPriceRange(priceRange)) {
      if (priceRange[0] !== this.PRICE_RANGE_MIN_VALUE) {
        filterCriteria.vehicleFilterCriteriaDto.minDayPrice = priceRange[0];
      } else {
        filterCriteria.vehicleFilterCriteriaDto.minDayPrice = this.PRICE_RANGE_MIN_VALUE;
      }
      if (priceRange[1] !== this.PRICE_RANGE_MAX_VALUE) {
        filterCriteria.vehicleFilterCriteriaDto.maxDayPrice = priceRange[1];
      } else {
        filterCriteria.vehicleFilterCriteriaDto.maxDayPrice = this.PRICE_RANGE_MAX_VALUE;
      }
    }
  }

  private static fillOutPriceRangeActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (
      !!filterCriteria?.vehicleFilterCriteriaDto &&
      this.isActiveFilterPriceRange([
        filterCriteria.vehicleFilterCriteriaDto.minDayPrice,
        filterCriteria.vehicleFilterCriteriaDto.maxDayPrice
      ])
    ) {
      activeFilters.push(
        this.createPriceRangeActiveFilter([
          filterCriteria.vehicleFilterCriteriaDto.minDayPrice,
          filterCriteria.vehicleFilterCriteriaDto.maxDayPrice
        ])
      );
    }
  }

  private static updateSeatsCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    selectedSeats: number
  ): void {
    if (selectedSeats !== 0) {
      filterCriteria.vehicleFilterCriteriaDto.minimumSeats = selectedSeats;
    }
  }

  private static fillOutSeatsActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (filterCriteria?.vehicleFilterCriteriaDto?.minimumSeats > 0) {
      activeFilters.push({
        active: true,
        vehicleFilterType: VehicleFilterType.MINIMUM_SEATS,
        value: filterCriteria.vehicleFilterCriteriaDto.minimumSeats,
        translateKey: 'FILTER_CRITERIA_SEATS',
        interpolateParams: {
          minimumSeats: filterCriteria.vehicleFilterCriteriaDto.minimumSeats
        }
      });
    }
  }

  private static getEquipmentLabel(
    equipments: Array<KeyLabel>,
    key: string
  ): string {
    return equipments?.find(equipment => equipment.key === key)?.label;
  }

  private static updateEquipmentsCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    selectedEquipments: Array<string>
  ): void {
    const cleanSelectedEquipments = this.removeEmptyValues(selectedEquipments);
    if (cleanSelectedEquipments && cleanSelectedEquipments.length > 0) {
      filterCriteria.vehicleFilterCriteriaDto.equipmentIds = cleanSelectedEquipments;
    }
  }

  private static fillOutEquipmentsActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    equipments: Array<KeyLabel>,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (filterCriteria?.vehicleFilterCriteriaDto?.equipmentIds?.length > 0) {
      filterCriteria?.vehicleFilterCriteriaDto?.equipmentIds.forEach(
        equipmentId => {
          activeFilters.push({
            active: true,
            label: this.getEquipmentLabel(equipments, equipmentId),
            vehicleFilterType: VehicleFilterType.EQUIPMENT,
            value: equipmentId
          });
        }
      );
    }
  }

  private static getVehicleModelLabel(
    vehicleModels: Array<KeyLabel>,
    key: string
  ): string {
    return vehicleModels?.find(vehicleModel => vehicleModel.key === key)?.label;
  }

  private static getVehicleGroupLabel(
    vehicleGroups: Array<VehicleGroupDto>,
    vehicleGroupId: string
  ): string {
    return vehicleGroups?.find(
      vehicleGroup => vehicleGroup.id === vehicleGroupId
    )?.name;
  }

  private static isActiveFilterVehicleModels(
    vehicleModelIds: Array<string>
  ): boolean {
    return vehicleModelIds && vehicleModelIds.length > 0;
  }

  private static createVehicleModelActiveFilter(
    vehicleModels: Array<KeyLabel>,
    vehicleModelId: string
  ): ActiveFilter {
    return {
      active: true,
      label: this.getVehicleModelLabel(vehicleModels, vehicleModelId),
      vehicleFilterType: VehicleFilterType.VEHICLE_MODEL,
      value: vehicleModelId
    };
  }

  private static createVehicleGroupActiveFilter(
    vehicleGroup: VehicleGroupReferenceDto
  ): ActiveFilter {
    return {
      active: true,
      label: vehicleGroup.name,
      vehicleFilterType: VehicleFilterType.VEHICLE_GROUP,
      value: vehicleGroup.id
    };
  }

  private static updateVehicleModelsCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    selectedVehicleModels: Array<string>
  ): void {
    const cleanSelectedVehicleModels = this.removeEmptyValues(
      selectedVehicleModels
    );
    if (this.isActiveFilterVehicleModels(cleanSelectedVehicleModels)) {
      filterCriteria.vehicleFilterCriteriaDto.modelIds = cleanSelectedVehicleModels;
    }
  }

  private static fillOutVehicleModelsActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    vehicleModels: Array<KeyLabel>,
    activeFilters: Array<ActiveFilter>
  ): void {
    if (
      this.isActiveFilterVehicleModels(
        filterCriteria?.vehicleFilterCriteriaDto?.modelIds
      )
    ) {
      filterCriteria.vehicleFilterCriteriaDto.modelIds.forEach(
        vehicleModelId => {
          activeFilters.push(
            this.createVehicleModelActiveFilter(vehicleModels, vehicleModelId)
          );
        }
      );
    }
  }

  private static updatePetsAllowedCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    petsAllowed: boolean
  ): void {
    if (petsAllowed != null) {
      if (filterCriteria.vehicleFilterCriteriaDto.permissions == null) {
        filterCriteria.vehicleFilterCriteriaDto.permissions = {};
      }
      filterCriteria.vehicleFilterCriteriaDto.permissions.petsAllowed = petsAllowed;
    }
  }

  private static fillOutPetsAllowedActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    // petsAllowed is a 3 states boolean
    if (
      !!filterCriteria?.vehicleFilterCriteriaDto?.permissions &&
      filterCriteria.vehicleFilterCriteriaDto.permissions.petsAllowed !==
        undefined
    ) {
      activeFilters.push({
        active: filterCriteria.vehicleFilterCriteriaDto.permissions.petsAllowed,
        vehicleFilterType: VehicleFilterType.PETS_ALLOWED,
        value: filterCriteria.vehicleFilterCriteriaDto.permissions.petsAllowed,
        translateKey: 'FILTER_CRITERIA_PETS'
      });
    }
  }

  private static updateKidsAllowedCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    kidsAllowed: boolean
  ): void {
    if (kidsAllowed != null) {
      if (filterCriteria.vehicleFilterCriteriaDto.permissions == null) {
        filterCriteria.vehicleFilterCriteriaDto.permissions = {};
      }
      filterCriteria.vehicleFilterCriteriaDto.permissions.kidsAllowed = kidsAllowed;
    }
  }

  private static fillOutKidsAllowedActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    // kidsAllowed is a 3 states boolean
    if (
      !!filterCriteria?.vehicleFilterCriteriaDto?.permissions &&
      filterCriteria.vehicleFilterCriteriaDto.permissions.kidsAllowed !==
        undefined
    ) {
      activeFilters.push({
        active: filterCriteria.vehicleFilterCriteriaDto.permissions.kidsAllowed,
        vehicleFilterType: VehicleFilterType.KIDS_ALLOWED,
        value: filterCriteria.vehicleFilterCriteriaDto.permissions.kidsAllowed,
        translateKey: 'FILTER_CRITERIA_CHILDREN'
      });
    }
  }

  private static updateAbroadAllowedCriteria(
    filterCriteria: FilterCriteriaForBookingDto,
    abroadAllowed: boolean
  ): void {
    if (abroadAllowed != null) {
      if (filterCriteria.vehicleFilterCriteriaDto.permissions == null) {
        filterCriteria.vehicleFilterCriteriaDto.permissions = {};
      }
      filterCriteria.vehicleFilterCriteriaDto.permissions.abroadAllowed = abroadAllowed;
    }
  }

  private static fillOutAbroadAllowedActiveFilters(
    filterCriteria: FilterCriteriaForBookingDto,
    activeFilters: Array<ActiveFilter>
  ): void {
    // abroadAllowed is a 3 states boolean
    if (
      !!filterCriteria?.vehicleFilterCriteriaDto?.permissions &&
      filterCriteria.vehicleFilterCriteriaDto.permissions.abroadAllowed !==
        undefined
    ) {
      activeFilters.push({
        active:
          filterCriteria.vehicleFilterCriteriaDto.permissions.abroadAllowed,
        vehicleFilterType: VehicleFilterType.ABROAD_ALLOWED,
        value:
          filterCriteria.vehicleFilterCriteriaDto.permissions.abroadAllowed,
        translateKey: 'FILTER_CRITERIA_ABROAD'
      });
    }
  }
}
