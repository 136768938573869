import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VehicleDialogData } from '../../types/vehicle-dialog-data.type';

@Injectable()
export class VehiclesViewHelper {
  private triggerRefreshList$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null
  );
  refreshList$ = this.triggerRefreshList$.asObservable();

  vehicleDialogData: VehicleDialogData;

  triggerRefreshList(): void {
    this.triggerRefreshList$.next(new Date());
  }
}
