import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { VehiclesAuthorizationGuard } from '../guards/vehicles-authorization.guard';
import { CopyVehicleDialogViewComponent } from './views/copy-vehicle-dialog-view/copy-vehicle-dialog-view.component';
import { CreateVehicleDialogViewComponent } from './views/create-vehicle-dialog-view/create-vehicle-dialog-view.component';
import { EditVehicleDialogViewComponent } from './views/edit-vehicle-dialog-view/edit-vehicle-dialog-view.component';
import { VehiclesViewComponent } from './views/vehicles-view/vehicles-view.component';

const routes: Routes = [
  {
    path: '',
    component: VehiclesViewComponent,
    canActivate: [AuthenticatedGuard, VehiclesAuthorizationGuard],
    children: [
      {
        path: 'edit-vehicle',
        component: EditVehicleDialogViewComponent,
        canActivate: [AuthenticatedGuard, VehiclesAuthorizationGuard]
      },
      {
        path: 'create-vehicle',
        component: CreateVehicleDialogViewComponent,
        canActivate: [AuthenticatedGuard, VehiclesAuthorizationGuard]
      },
      {
        path: 'copy-vehicle',
        component: CopyVehicleDialogViewComponent,
        canActivate: [AuthenticatedGuard, VehiclesAuthorizationGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehicleManagementRoutingModule {}
