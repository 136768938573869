import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { ActiveSchedulerFilters } from '../../../types/active-scheduler-filters.type';
import { ActiveFilter } from '../../../types/active-filter.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import { SchedulerSetting } from '../../../scheduler.setting';
import { DateUtil } from '../../../helpers/date-util';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import { UserImage } from '../../../shared-ui/components/user-avatar/types/user-image.type';

@Component({
  selector: 'sof-booking-scheduler-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      *ngIf="activeSchedulerFilters?.activeFilters?.length > 0"
      class="scheduler-filters-container"
    >
      <div
        *ngIf="activeSchedulerFilters?.booking"
        class="booking"
        [ngStyle]="bookingStyle"
      >
        <sof-user-avatar [userImage]="userImage"></sof-user-avatar>
        <div class="booking-info">{{ this.bookingInfo }}</div>
        <div class="remove-button-icon">
          <button
            sofButton
            type="button"
            class="icon-button"
            (click)="onRemoveClick()"
          >
            <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
          </button>
        </div>
      </div>
      <div
        *ngIf="!activeSchedulerFilters?.booking"
        class="action-button-container"
      >
        <button
          sofButton
          class="main-action-button"
          icon="batt-icon-delete"
          (click)="onRemoveClick()"
        >
          {{ tc + '.REMOVE-ALL-FILTERS' | translate }}
        </button>
      </div>
      <div class="label">Filters are applied :</div>
      <div class="filters">
        <sof-active-filter
          *ngFor="let activeFilter of activeSchedulerFilters?.activeFilters"
          [tc]="tc"
          [value]="activeFilter.active"
          [label]="activeFilter.label"
          [translateKey]="activeFilter.translateKey"
          [interpolateParams]="activeFilter.interpolateParams"
          [activeFilter]="activeFilter"
          [canRemove]="true"
          (removeActiveFilter)="onRemoveActiveFilter($event)"
        ></sof-active-filter>
      </div>
    </div>
  `,
  styleUrls: ['./booking-scheduler-filters.component.scss']
})
export class BookingSchedulerFiltersComponent implements OnChanges {
  @Input() tc: string;
  @Input() activeSchedulerFilters: ActiveSchedulerFilters;

  @Output()
  newActiveSchedulerFilters: EventEmitter<ActiveSchedulerFilters> = new EventEmitter<ActiveSchedulerFilters>();

  userImage: UserImage = null;
  bookingInfo = '';
  bookingStyle = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeSchedulerFilters) {
      this.userImage = null;
      if (this.activeSchedulerFilters?.booking?.user?.imageUrl) {
        this.userImage = {
          imageUrl: this.activeSchedulerFilters?.booking?.user?.imageUrl,
          imageSize: '30',
          userName: this.activeSchedulerFilters?.booking?.user?.userName
        };
      }
      this.bookingInfo = '';
      if (this.activeSchedulerFilters?.booking?.comments) {
        this.bookingInfo = this.activeSchedulerFilters?.booking?.comments;
      } else if (this.activeSchedulerFilters?.booking?.user?.displayName) {
        this.bookingInfo = this.activeSchedulerFilters?.booking?.user?.displayName;
      }
      this.updateBookingStyle();
    }
  }

  updateBookingStyle(): void {
    if (this.activeSchedulerFilters?.booking) {
      const bookingColors = SharedUiUtils.getBookingColors(
        this.activeSchedulerFilters.booking.status,
        DateUtil.convertToDate(
          this.activeSchedulerFilters.booking.plannedPeriod.start
        ),
        DateUtil.convertToDate(
          this.activeSchedulerFilters.booking.plannedPeriod.end
        )
      );
      this.bookingStyle = {
        color: bookingColors.text,
        border: '1.5px solid ' + bookingColors.border,
        'background-color': bookingColors.background
      };
    } else {
      this.bookingStyle = null;
    }
  }

  onRemoveClick(): void {
    this.newActiveSchedulerFilters.emit({
      ...SchedulerSetting.defaultActiveSchedulerFilters
    });
  }

  onRemoveActiveFilter(activeFilter: ActiveFilter): void {
    this.newActiveSchedulerFilters.emit(
      ActiveFiltersUtils.removeActiveFilters(
        this.activeSchedulerFilters,
        activeFilter
      )
    );
  }
}
