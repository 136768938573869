import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sof-user-search-filter-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="d-flex p-3">
      <sof-form [tc]="tc" [formGroup]="filterForm" class="flex-fill pr-3">
        <div class="d-flex flex-column">
          <div class="row">
            <sof-input-wrapper
              [label]="tc + '.DISPLAY-NAME' | translate"
              class="col-md-6"
            >
              <sof-input-text
                [sofInput]
                [formControl]="filterForm?.controls?.displayName"
              ></sof-input-text>
            </sof-input-wrapper>
            <sof-input-wrapper
              [label]="tc + '.USERNAME' | translate"
              class="col-md-6"
            >
              <sof-input-text
                [sofInput]
                [formControl]="filterForm?.controls?.userName"
              ></sof-input-text>
            </sof-input-wrapper>
            <!-- TODO - Not yet implemented backend side
            <sof-input-wrapper
              [label]="tc + '.EMAIL' | translate"
              class="col-md-4"
            >
              <sof-input-text
                [sofInput]
                [formControl]="filterForm?.controls?.email"
              ></sof-input-text>
            </sof-input-wrapper>
-->
          </div>
        </div>
      </sof-form>
      <div class="buttons-container">
        <button
          sofButton
          class="main-action-button"
          (click)="searchUsers.emit()"
        >
          {{ tc + '.SHOW-USERS' | translate }}
        </button>
        <button
          *ngIf="canCreateUser"
          sofButton
          class="main-action-button"
          (click)="createUser.emit()"
        >
          {{ tc + '.CREATE-USER' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./user-search-filter-form.component.scss']
})
export class UserSearchFilterFormComponent {
  @Input() tc: string;
  @Input() filterForm: FormGroup;
  @Input() canCreateUser: boolean;
  @Output() createUser: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchUsers: EventEmitter<void> = new EventEmitter<void>();
}
