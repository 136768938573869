import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from 'ngx-reactivetoolkit';
import { Observable } from 'rxjs/index';
import {
  EnumValueDto,
  UserLocaleDto,
  UserRoleDto
} from  '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import TranslateUtils from '../../../utils/translate.utils';
import { InviteMemberDialogViewHelper } from '../../view-helpers/invite-member-dialog-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-invite-member-dialog-user-data-step-view',
  template: `
    <sof-form
      [tc]="tc"
      [formGroup]="inviteMemberDialogViewHelper.userFormGroup"
    >
      <sof-input-wrapper [label]="tc + '.EMAIL-ADDRESS' | translate">
        <sof-input-text
          [sofInput]
          [formControl]="
            inviteMemberDialogViewHelper.userFormGroup?.controls?.email
          "
        >
        </sof-input-text>
      </sof-input-wrapper>
      <sof-input-wrapper [label]="tc + '.FIRST-NAME' | translate">
        <sof-input-text
          sofFocus
          [sofInput]
          [formControl]="
            inviteMemberDialogViewHelper.userFormGroup?.controls?.firstName
          "
        >
        </sof-input-text>
      </sof-input-wrapper>
      <sof-input-wrapper [label]="tc + '.LAST-NAME' | translate">
        <sof-input-text
          [sofInput]
          [formControl]="
            inviteMemberDialogViewHelper.userFormGroup?.controls?.lastName
          "
        >
        </sof-input-text>
      </sof-input-wrapper>
      <sof-input-wrapper [label]="tc + '.LOCALE' | translate">
        <!-- TODO : Hard coded locales (see UserLocaleDto) to be replaced -->
        <sof-input-single-select
          [sofInput]
          [tc]="tc"
          [options]="locales$ | async"
          [formControl]="
            inviteMemberDialogViewHelper.userFormGroup?.controls?.userLocale
          "
          [selectorValue]="inviteMemberDialogViewHelper.enumValueFn"
          [selectorLabel]="inviteMemberDialogViewHelper.enumLabelFn"
        ></sof-input-single-select>
      </sof-input-wrapper>
      <sof-input-wrapper [label]="tc + '.ROLES' | translate">
        <!-- TODO : Hard coded roles (see UserRoleDto) to be replaced -->
        <sof-input-multi-select
          [sofInput]
          [tc]="tc"
          [options]="roles$ | async"
          [formControl]="
            inviteMemberDialogViewHelper.userFormGroup?.controls?.userRoles
          "
          [selectorValue]="inviteMemberDialogViewHelper.enumValueFn"
          [selectorLabel]="inviteMemberDialogViewHelper.enumLabelFn"
        ></sof-input-multi-select>
      </sof-input-wrapper>
    </sof-form>
  `
})
export class InviteMemberDialogUserDataStepViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_INVITE-MEMBER-DIALOG';

  locales: UserLocaleDto[] = [UserLocaleDto.EN, UserLocaleDto.NL];
  locales$: Observable<EnumValueDto[]>;
  roles: UserRoleDto[] = [
    UserRoleDto.BATTADMIN,
    UserRoleDto.BATTFAN,
    UserRoleDto.BATTFUN,
    UserRoleDto.BATTMAX
  ];
  roles$: Observable<EnumValueDto[]>;

  constructor(
    private toastUtilService: ToastUtilService,
    private translateService: TranslateService,
    public inviteMemberDialogViewHelper: InviteMemberDialogViewHelper
  ) {}

  ngOnInit(): void {
    this.locales$ = TranslateUtils.getConstantTranslations$(
      this.translateService,
      this.locales,
      this.tc,
      'LOCALE_'
    );
    this.roles$ = TranslateUtils.getConstantTranslations$(
      this.translateService,
      this.roles,
      this.tc,
      'USER_ROLE_'
    );
    this.inviteMemberDialogViewHelper.checkCurrentRoute();
  }
}
