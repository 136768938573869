import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropDownMenuItemAlt } from '../../../../shared-ui/components/drop-down-menu-alt/types/drop-down-menu-item-alt.type';
import { DropDownConfigAlt } from '../../../../shared-ui/components/drop-down-alt/types/drop-down-config-alt.type';
import SharedUiUtils from '../../../../shared-ui/utils/shared-ui.utils';
import { TooltipContentPoolVehicle } from '../../../../types/tooltip.type';
import {
  DeleteTooltipEvent,
  TooltipEvent
} from '../../../../classes/tooltip-events.class';
import { Vehicle } from '../../../../types/vehicle.type';
import { BookingUserDto } from '../../../../../../client';

@Component({
  selector: 'sof-tooltip-content-pool-vehicle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="header">
      <div class="title">
        {{ tc + '.TOOLTIP-POOL-VEHICLE-TITLE' | translate }}
      </div>
      <button
        sofButton
        type="button"
        class="icon-button"
        (click)="onCloseClick()"
      >
        <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
      </button>
    </div>
    <div class="tooltip-list">
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item label-component-container">
        <div class="label">
          {{ tc + '.TOOLTIP-POOL-VEHICLE-LICENSE-PLATE' | translate }}
        </div>
        <sof-license-plate
          class="scheduler-license-plate"
          [value]="content.vehicle?.licensePlate"
          countryCode="be"
        ></sof-license-plate>
      </div>
      <div class="tooltip-list-item label-value-container">
        <div class="label">
          {{ tc + '.TOOLTIP-POOL-VEHICLE-NAME' | translate }}
        </div>
        <div class="value">{{ content.vehicle?.name }}</div>
      </div>
      <sof-divider size="thin" *ngIf="content.batteryStatus"></sof-divider>
      <div
        *ngIf="content.batteryStatus"
        class="tooltip-list-item label-value-container"
      >
        <div class="label">
          {{ tc + '.TOOLTIP-POOL-VEHICLE-VEHICLE-BATTERY' | translate }}
        </div>
        <div class="value"></div>
      </div>
      <div *ngIf="content.batteryStatus" class="tooltip-list-item">
        <sof-battery-status
          [tc]="tc"
          [batteryStatus]="content.batteryStatus"
        ></sof-battery-status>
      </div>
      <div
        *ngIf="content.batteryStatus"
        class="tooltip-list-item estimated-vehicle-radius-container"
      >
        <div class="label">
          {{
            tc + '.TOOLTIP-POOL-VEHICLE-ESTIMATED-VEHICLE-RADIUS' | translate
          }}
          <strong>{{ content.batteryStatus.cruisingRange }}km</strong>
        </div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item edit-container">
        <button
          sofButton
          class="general-action-button primary"
          (click)="onEditClick()"
        >
          {{ tc + '.EDIT' | translate }}
        </button>
        <sof-drop-down-menu-alt
          [tc]="tc"
          [dropDownConfig]="dropDownConfig$ | async"
          [menuItems]="menuItems"
        >
        </sof-drop-down-menu-alt>
      </div>
    </div>
  `,
  styleUrls: ['./tooltip-content-pool-vehicle.component.scss']
})
export class TooltipContentPoolVehicleComponent implements OnInit {
  @Input() content: TooltipContentPoolVehicle;
  @Input() tooltipId: string;
  @Input() tc: string;
  @Input() currentUser: BookingUserDto;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output() editVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output()
  refreshVehicleLocation: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addBookingForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addAvailabilityForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  endVehicleUsage: EventEmitter<string> = new EventEmitter<string>();

  dropDownConfig$: Observable<DropDownConfigAlt>;
  menuItems: DropDownMenuItemAlt[];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.dropDownConfig$ = this.getDropDownConfig$();
    this.menuItems = this.getMenuItems();
  }

  private getDropDownConfig$(): Observable<DropDownConfigAlt> {
    return this.translateService.stream(this.tc + '.ACTIONS').pipe(
      map(translation => {
        return {
          toggleButtonClasses: 'general-action-button primary',
          toggleButtonText: translation
        };
      })
    );
  }

  private getMenuItems(): DropDownMenuItemAlt[] {
    // Button menu is displayed in an overlay, in order to not consider the menu item click as a click outside
    // the class name 'click-outside-tooltip-exception' is used as a flag
    const items = [
      {
        label: 'CREATE-BOOKING',
        classes: 'click-outside-tooltip-exception',
        click: () => {
          this.addBookingForVehicle.emit(this.content.vehicle.remoteId);
        }
      },
      {
        label: 'CREATE-UNAVAILABILITY',
        classes: 'click-outside-tooltip-exception',
        click: () => {
          this.addAvailabilityForVehicle.emit(this.content.vehicle.remoteId);
        }
      },
      {
        label: 'REFRESH-LOCATION',
        classes: 'click-outside-tooltip-exception',
        click: () => {
          this.refreshVehicleLocation.emit(this.content.vehicle.remoteId);
        }
      }
    ];
    if (
      SharedUiUtils.isUserAdmin(this.currentUser) &&
      !!this.content.vehicle.privateUsage
    ) {
      items.push({
        label: 'END-PRIVATE-USAGE',
        classes: 'click-outside-tooltip-exception',
        click: () => {
          this.endVehicleUsage.emit(this.content.vehicle.remoteId);
        }
      });
    }
    return items;
  }

  onCloseClick(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onEditClick(): void {
    this.editVehicle.emit(this.content.vehicle);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }
}
