import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '@sofico-framework/app-config';
import { DateFormatEnum } from '@sofico-framework/utils';
import {
  BookingDto,
  BookingUserDto,
  TripTypeDto
} from '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import { BookingManagementSandbox } from '../../booking-management.sandbox';
import { shareReplay, take, withLatestFrom } from 'rxjs/operators';
import { DateUtil } from '../../../helpers/date-util';
import { BookingDialogData } from '../../../types/booking-dialog-data.type';
import { Observable } from 'rxjs';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BookingsViewHelper } from '../../view-helpers/bookings-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-booking-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-booking-filter-form
        [tc]="tc"
        [filterForm]="bookingsViewHelper.filterForm"
        [canChangeUser]="bookingsViewHelper.canChangeUserSub$ | async"
        [users]="(allUsers$ | async)?.users"
        [vehicles]="(allVehicles$ | async)?.vehicles"
        [currentUser]="currentUser$ | async"
        [dateFormat]="dateFormat"
        (createBooking)="createBooking()"
        (searchBookings)="bookingsViewHelper.refreshList()"
      ></sof-booking-filter-form>
      <sof-booking-list
        [tc]="tc"
        [bookingPageDto]="bookingsViewHelper.bookingPageDto$ | async"
        [showActions]="true"
        [currentUser]="currentUser$ | async"
        (editBooking)="editBooking($event)"
      >
      </sof-booking-list>
      <router-outlet></router-outlet>
    </ng-container>
  `,
  providers: [BookingsViewHelper],
  styleUrls: ['./bookings-view.component.scss']
})
export class BookingsViewComponent implements OnInit {
  tc = 'BOOKING-MANAGEMENT_BOOKING';
  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  // TODO - To be removed when BookingDto will contain User and Vehicle descriptions
  allUsers$ = this.sb.allUsers$;
  allVehicles$ = this.sb.allVehicles$;

  currentUser$: Observable<BookingUserDto>;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private sb: BookingManagementSandbox,
    private toastUtilService: ToastUtilService,
    private configService: ConfigService,
    public bookingsViewHelper: BookingsViewHelper
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.sb.currentUser$.pipe(shareReplay(1));

    // Is it allowed to change the user?
    this.sb
      .canChangeUser()
      .pipe(withLatestFrom(this.currentUser$), take(1), takeUntilDestroy(this))
      .subscribe(([canChangeUser, currentUser]) => {
        this.bookingsViewHelper.canChangeUserSub$.next(canChangeUser);
        if (!canChangeUser) {
          this.bookingsViewHelper.filterForm.controls.user.disable();
          this.bookingsViewHelper.userId = currentUser?.remoteId;
          this.bookingsViewHelper.filterForm.controls.user.setValue(
            this.bookingsViewHelper.userId
          );
        }
      });
  }

  createBooking(): void {
    let hasError = false;
    const userId = this.bookingsViewHelper.getUserId();
    if (!userId) {
      this.toastUtilService.error(this.tc + '.USER-REQUIRED', true);
      hasError = true;
    }
    const vehicleId = this.bookingsViewHelper.filterForm.value.vehicleId;
    if (!vehicleId) {
      this.toastUtilService.error(this.tc + '.VEHICLE-REQUIRED', true);
      hasError = true;
    }
    if (!hasError) {
      const fromDate: Date = this.bookingsViewHelper.filterForm.value
        .fromPicker;
      fromDate.setDate(fromDate.getDate());
      fromDate.setHours(16, 0, 0, 0);
      const toDate: Date = this.bookingsViewHelper.filterForm.value.toPicker;
      toDate.setHours(20);
      const organizationId = this.bookingsViewHelper.filterForm.value
        .organizationId;
      this.openBookingDialog(
        null,
        userId,
        vehicleId,
        organizationId,
        fromDate,
        toDate,
        TripTypeDto.PRIVATE,
        null,
        false,
        null,
        'create-booking'
      );
    }
  }

  openBookingDialog(
    remoteBookingId: string,
    userId: string,
    remoteVehicleId: string,
    organizationId: string,
    fromDate: Date,
    toDate: Date,
    tripType: TripTypeDto,
    comments: string,
    vehicleChange: boolean,
    bookingDto: BookingDto,
    path: string
  ): void {
    this.currentUser$
      .pipe(
        // TODO - To be removed when BookingDto will contain User and Vehicle descriptions
        // Also waiting for user and vehicle search components
        withLatestFrom(this.allUsers$, this.allVehicles$),
        take(1),
        takeUntilDestroy(this)
      )
      .subscribe(([currentUser, allUsers, allVehicles]) => {
        const data: BookingDialogData = {
          internalBookingId: null,
          remoteBookingId,
          userId,
          organizationId,
          fromDate,
          toDate,
          remoteVehicleId,
          tripType,
          comments,
          vehicleChange,
          bookingDto,
          vehicles: allVehicles?.vehicles,
          users: allUsers?.users,
          currentUser
        };
        this.bookingsViewHelper.bookingDialogData = data;
        this.route.navigate([path], { relativeTo: this.activatedRoute });
      });
  }

  editBooking(booking: BookingDto): void {
    this.sb
      .getBooking(booking.id)
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(
        bookingDto => {
          const fromDate: Date = DateUtil.convertToDate(
            bookingDto.plannedPeriod.start
          );
          const toDate: Date = DateUtil.convertToDate(
            bookingDto.plannedPeriod.end
          );
          this.openBookingDialog(
            bookingDto.id,
            bookingDto.user.remoteId,
            bookingDto.vehicle.id,
            bookingDto.organization.id,
            fromDate,
            toDate,
            bookingDto.tripType,
            bookingDto.comments,
            false,
            bookingDto,
            'edit-booking'
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_GET-BOOKING'
          );
        }
      );
  }
}
