import { Component, OnInit } from '@angular/core';
import { FlowStep } from '@sofico-framework/ui-kit/components/wizard-nav-bar';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/index';
import { map, take } from 'rxjs/operators';
import { OrganizationRoleDto } from  '../../../../../client';
import { InviteMemberDialogViewHelper } from '../../view-helpers/invite-member-dialog-view.helper';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@Component({
  selector: 'sof-invite-member-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="
        ((vh.inviteMemberRole$ | async) === ORGANIZATION_ROLE_MANAGER
          ? tc + '.INVITE-NEW-MANAGER'
          : tc + '.INVITE-NEW-MEMBER'
        ) | translate
      "
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="invite-member-dialog-content">
          <div class="info-container">
            <img class="info-image" src="assets/invite-member.svg" />
            <div class="info-text">
              {{ tc + '.INVITE-NEW-MEMBER-TEXT' | translate }}
            </div>
          </div>
          <sof-wizard-nav-bar
            [tc]="tc"
            [enableFutureSteps]="false"
            [steps]="steps$ | async"
          ></sof-wizard-nav-bar>
          <div class="full-height-container overflow-auto p-1">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            [disabled]="vh.disablePrevious$ | async"
            (click)="vh.goToPreviousStep()"
            class="general-action-button"
          >
            {{ tc + '.BACK' | translate }}
          </button>
          <button
            sofButton
            [disabled]="vh.disableNext$ | async"
            (click)="vh.checkAndNext()"
            class="general-action-button"
          >
            {{ tc + '.NEXT' | translate }}
          </button>
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            [disabled]="vh.disableInvite$ | async"
            (click)="onInviteClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.INVITE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  providers: [InviteMemberDialogViewHelper],
  styleUrls: ['./invite-member-dialog-view.component.scss']
})
export class InviteMemberDialogViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_INVITE-MEMBER-DIALOG';

  ORGANIZATION_ROLE_MANAGER = OrganizationRoleDto.MANAGER;

  steps$: Observable<FlowStep[]>;

  constructor(
    public organizationViewHelper: OrganizationViewHelper,
    public vh: InviteMemberDialogViewHelper
  ) {}

  ngOnInit(): void {
    this.steps$ = this.getSteps$();
  }

  private getSteps$(): Observable<FlowStep[]> {
    return combineLatest([
      this.organizationViewHelper.organization$,
      this.vh.inviteMemberRole$
    ]).pipe(
      take(1),
      map(([organization, memberRole]) => {
        const memberRoleRoot =
          memberRole === OrganizationRoleDto.MANAGER ? 'managers' : 'members';
        const steps: FlowStep[] = [
          {
            label: 'STEP-EMAIL-ADDRESS',
            routePath:
              '/organizations/' +
              organization.id +
              '/' +
              memberRoleRoot +
              '/invite-member/email-address-step',
            disabled: true
          },
          {
            label: 'STEP-USER-DATA',
            routePath:
              '/organizations/' +
              organization.id +
              '/' +
              memberRoleRoot +
              '/invite-member/user-data-step',
            disabled: true
          }
        ];
        if (organization.driverGrades?.length > 0) {
          steps.push({
            label: 'STEP-DRIVER-GRADE',
            routePath:
              '/organizations/' +
              organization.id +
              '/' +
              memberRoleRoot +
              '/invite-member/driver-grade-step',
            disabled: true
          });
        }
        return steps;
      })
    );
  }

  onInviteClick(): void {
    if (!this.vh.isInviteDataValid()) {
      return;
    }
    this.vh.inviteUser();
  }
}
