import { ActionTypes } from '../action-types';
import { Booking } from '../../types/booking.type';
import { BookingUpdate } from '../../types/booking-update.type';
import { BookingCancel } from '../../types/booking-cancel.type';

export class SetBookingsAction {
  type = ActionTypes.SET_BOOKINGS;
  payload: { bookings: Booking[] };

  constructor(bookings: Booking[]) {
    this.payload = { bookings };
  }
}

export class AddBookingAction {
  type = ActionTypes.ADD_BOOKING;
  payload: { booking: Booking };

  constructor(booking: Booking) {
    this.payload = { booking };
  }
}

export class UpdateBookingAction {
  type = ActionTypes.UPDATE_BOOKING;
  payload: { bookingUpdate: BookingUpdate };

  constructor(bookingUpdate: BookingUpdate) {
    this.payload = { bookingUpdate };
  }
}

export class CancelBookingAction {
  type = ActionTypes.CANCEL_BOOKING;
  payload: { bookingCancel: BookingCancel };

  constructor(bookingCancel: BookingCancel) {
    this.payload = { bookingCancel };
  }
}

export type BookingActions =
  | SetBookingsAction
  | AddBookingAction
  | UpdateBookingAction
  | CancelBookingAction;
