import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateOrganizationRequestDto } from  '../../../../../client';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { take } from 'rxjs/operators';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-edit-organization-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.EDIT-ORGANIZATION' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="organizationForm" class="flex-fill">
            <sof-input-wrapper [label]="tc + '.ORGANIZATION-NAME' | translate">
              <sof-input-text
                sofFocus
                [sofInput]
                [formControl]="organizationForm?.controls?.name"
              >
              </sof-input-text>
            </sof-input-wrapper>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton class="general-action-button" routerLink="..">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class EditOrganizationDialogViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_EDIT-ORGANIZATION-DIALOG';
  organizationForm: FormGroup = this.fb.group({
    name: ['', Validators.required]
  });

  organizationId: string;

  constructor(
    private fb: FormBuilder,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vh: OrganizationViewHelper
  ) {}

  ngOnInit(): void {
    this.vh.organization$
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(organizationDto => {
        this.organizationId = organizationDto.id;
        this.organizationForm.controls.name.setValue(organizationDto.name);
      });
  }

  onSaveClick(): void {
    if (!this.organizationForm.valid) {
      return;
    }
    const request: UpdateOrganizationRequestDto = {
      organizationId: this.organizationId,
      name: this.organizationForm.value.name
    };
    this.osb.updateOrganization(request).subscribe(
      organizationDto => {
        this.toastUtilService.success(
          this.tc + '.SUCCESSFUL_UPDATE-ORGANIZATION',
          true
        );
        this.router
          .navigate(['..'], {
            relativeTo: this.activatedRoute
          })
          .then(() => {
            this.vh.triggerOrganization();
          });
      },
      error => {
        this.toastUtilService.showError(
          error,
          this.tc + '.FAILED_UPDATE-ORGANIZATION'
        );
      }
    );
  }
}
