import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  FilterCriteriaForBookingDto,
  GpsLocationDto,
  VehicleFilterCriteriaDto
} from '../../../../../client';
import { FormBuilder } from '@angular/forms';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { SchedulerSandbox } from '../../scheduler.sandbox';
import { Intent } from '../../../types/intent.type';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, take, withLatestFrom } from 'rxjs/operators';
import { KeyLabel } from '../../../types/key-label.type';
import { ActiveFilter } from '../../../types/active-filter.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';

@UntilDestroy()
@Component({
  selector: 'sof-apply-vehicle-filters-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="lg"
      [headerLabel]="tc + '.APPLY-VEHICLE-FILTERS' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body class="dialog-content">
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-vehicle-filters
            [tc]="tc"
            [intents]="intents$ | async"
            [equipments]="vehicleEquipments$ | async"
            [vehicleModels]="vehicleModels$ | async"
            [intentIds]="selectedIntents$ | async"
            (intentIdsChange)="selectedIntents$.next($event)"
            [vehicleName]="vehicleName$ | async"
            (vehicleNameChange)="vehicleName$.next($event)"
            [onlyFavorites]="onlyFavorites$ | async"
            (onlyFavoritesChange)="onlyFavorites$.next($event)"
            [onlyInstantBookingPossible]="instantBook$ | async"
            (onlyInstantBookingPossibleChange)="instantBook$.next($event)"
            [vehicleRange]="vehicleRange$ | async"
            (vehicleRangeChange)="vehicleRange$.next($event)"
            [priceRange]="priceRange$ | async"
            (priceRangeChange)="priceRange$.next($event)"
            [modelIds]="selectedVehicleModels$ | async"
            (modelIdsChange)="selectedVehicleModels$.next($event)"
            [minimumSeats]="selectedSeats$ | async"
            (minimumSeatsChange)="selectedSeats$.next($event)"
            [petsAllowed]="petsAllowed$ | async"
            (petsAllowedChange)="petsAllowed$.next($event)"
            [kidsAllowed]="kidsAllowed$ | async"
            (kidsAllowedChange)="kidsAllowed$.next($event)"
            [abroadAllowed]="abroadAllowed$ | async"
            (abroadAllowedChange)="abroadAllowed$.next($event)"
            [equipmentIds]="selectedEquipments$ | async"
            (equipmentIdsChange)="selectedEquipments$.next($event)"
          >
          </sof-vehicle-filters>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CLOSE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./apply-vehicle-filters-dialog.component.scss']
})
export class ApplyVehicleFiltersDialogComponent {
  @Input() tc: string;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();

  priceRange$: BehaviorSubject<[number, number]> = new BehaviorSubject<
    [number, number]
  >([
    ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
    ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
  ]);
  vehicleRange$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE
  );
  selectedIntents$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  onlyFavorites$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  instantBook$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedSeats$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedEquipments$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  abroadAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  kidsAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  petsAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  vehicleName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  selectedVehicleModels$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  selectedLocation$: BehaviorSubject<GpsLocationDto> = new BehaviorSubject<GpsLocationDto>(
    { ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE }
  );
  selectedMaxDistance$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
  );

  intentActiveFilters$: BehaviorSubject<
    Array<ActiveFilter>
  > = new BehaviorSubject<Array<ActiveFilter>>(null);
  timeAndPlaceActiveFilters$: BehaviorSubject<
    Array<ActiveFilter>
  > = new BehaviorSubject<Array<ActiveFilter>>(null);
  vehicleActiveFilters$: BehaviorSubject<
    Array<ActiveFilter>
  > = new BehaviorSubject<Array<ActiveFilter>>(null);

  vehicleCriteria$ = combineLatest([
    this.selectedIntents$,
    this.vehicleName$,
    this.onlyFavorites$,
    this.instantBook$,
    this.vehicleRange$,
    this.priceRange$,
    this.selectedSeats$,
    this.abroadAllowed$,
    this.kidsAllowed$,
    this.petsAllowed$,
    this.selectedEquipments$,
    this.selectedVehicleModels$,
    this.selectedLocation$,
    this.selectedMaxDistance$
  ]).pipe(debounceTime(250));

  vehicleFilterCriteriaDto: VehicleFilterCriteriaDto;

  intents$ = this.schedulerSandbox.intents$;
  vehicleEquipments$ = this.schedulerSandbox.vehicleEquipments$;
  vehicleModels$ = this.schedulerSandbox.vehicleModels$;

  constructor(
    private fb: FormBuilder,
    private schedulerSandbox: SchedulerSandbox
  ) {
    this.vehicleCriteria$
      .pipe(
        withLatestFrom(
          this.intents$,
          this.vehicleEquipments$,
          this.vehicleModels$
        ),
        take(1),
        takeUntilDestroy(this)
      )
      .subscribe(
        ([
          [
            selectedIntents,
            vehicleName,
            onlyFavorites,
            instantBook,
            vehicleRange,
            priceRange,
            selectedSeats,
            abroadAllowed,
            kidsAllowed,
            petsAllowed,
            selectedEquipments,
            selectedVehicleModels,
            selectedLocation,
            selectedMaxDistance
          ],
          intents,
          vehicleEquipments,
          vehicleModels
        ]: [
          [
            Array<string>,
            string,
            boolean,
            boolean,
            number,
            [number, number],
            number,
            boolean,
            boolean,
            boolean,
            Array<string>,
            Array<string>,
            GpsLocationDto,
            number
          ],
          Array<Intent>,
          Array<KeyLabel>,
          Array<KeyLabel>
        ]) => {
          const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
          const filterCriteria: FilterCriteriaForBookingDto = ActiveFiltersUtils.getFilterCriteriaForBookingDto(
            selectedIntents,
            vehicleName,
            onlyFavorites,
            instantBook,
            vehicleRange,
            priceRange,
            selectedSeats,
            abroadAllowed,
            kidsAllowed,
            petsAllowed,
            selectedEquipments,
            selectedVehicleModels,
            selectedLocation,
            selectedMaxDistance,
            null,
            null,
            null
          );
          const vehicleSearchCriteriaDto = ActiveFiltersUtils.getVehicleSearchCriteriaDtoForFilterCriteriaForBookingDto(
            filterCriteria
          );
          ActiveFiltersUtils.fillOutActiveFilters(
            filterCriteria,
            intents,
            vehicleModels,
            vehicleEquipments,
            activeFilters,
            activeFilters,
            activeFilters
          );
          this.schedulerSandbox.setActiveSchedulerFilters({
            booking: null,
            vehicleSearchCriteriaDto,
            activeFilters
          });
        }
      );
  }
}
