import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { DateFormatEnum } from '@sofico-framework/utils';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Intent } from '../../../types/intent.type';
import {
  RentalTypeDto,
  VehicleOperationalStatusDto
} from '../../../../../client';
import { TranslatedData } from '../../../types/translated-data.type';
import TranslateUtils from '../../../utils/translate.utils';

@Component({
  selector: 'sof-vehicle-dialog-usage-tab-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-form
      #vehicleUsageForm
      [tc]="tc"
      [formGroup]="usageForm"
      [errorMap]="errorMap"
      class="vehicle-dialog-form"
    >
      <div class="column-container">
        <div class="col-form">
          <div class="container new-data">
            <div class="row">
              <div class="column col-sm">
                <div class="form-group-input-label">
                  {{ tc + '.ALLOWED' | translate }}
                </div>
                <div class="allowed-container">
                  <sof-allowed-button
                    [label]="tc + '.ALLOWED_PETS' | translate"
                    [disabled]="isReadonly"
                    [value]="petsAllowed"
                    (valueChange)="petsAllowedChange.emit($event)"
                  ></sof-allowed-button>
                  <sof-allowed-button
                    [label]="tc + '.ALLOWED_CHILDREN' | translate"
                    [disabled]="isReadonly"
                    [value]="kidsAllowed"
                    (valueChange)="kidsAllowedChange.emit($event)"
                  ></sof-allowed-button>
                  <sof-allowed-button
                    [label]="tc + '.ALLOWED_ABROAD' | translate"
                    [disabled]="isReadonly"
                    [value]="abroadAllowed"
                    (valueChange)="abroadAllowedChange.emit($event)"
                  ></sof-allowed-button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="column col-sm">
                <div class="form-group-input-label">
                  {{ tc + '.INTENTS' | translate }}
                </div>
                <sof-intent-button-group
                  [intents]="intents"
                  [values]="selectedIntents"
                  [smallButtons]="true"
                  [disabled]="isReadonly"
                  (valuesChange)="selectedIntentsChange.emit($event)"
                ></sof-intent-button-group>
              </div>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.DEFAULT-STRATEGY' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.alwaysAvailable"
                  [clearable]="false"
                  [options]="defaultStrategies$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.RESERVATION-APPROVAL' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.automaticBookingApproval"
                  [clearable]="false"
                  [options]="reservationApprovals$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.RENTAL-TYPE' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.rentalType"
                  [clearable]="false"
                  [options]="rentalTypes$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.INSTANT-BOOKING' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.instantBookingPossible"
                  [clearable]="false"
                  [options]="instantBookingPossibles$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.SEATS' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.seats"
                  [clearable]="false"
                  [options]="seatChoices$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.MAXIMUM-RANGE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="usageForm?.controls?.electricRange"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.DAY-PRICE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="usageForm?.controls?.dayPrice"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.KILOMETER-PRICE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="usageForm?.controls?.kilometerPrice"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.HOUR-PRICE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="usageForm?.controls?.hourPrice"
                ></sof-input-number>
              </sof-input-wrapper>
              <div class="col-sm"></div>
            </div>
          </div>
        </div>
        <div class="col-form">
          <div class="container new-data">
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.OPERATIONAL-STATUS' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="usageForm?.controls?.operationalStatus"
                  [clearable]="false"
                  [options]="operationalStatuses$ | async"
                  [selectorValue]="translatedDataValueFn"
                  [selectorLabel]="translatedDataLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.ACTUAL-IN-FLEET-DATE' | translate"
                class="col-sm"
              >
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="usageForm?.controls?.actualInFleetDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="true"
                ></sof-input-date-picker>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.ACTUAL-OUT-OF-FLEET-DATE' | translate"
                class="col-sm"
              >
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="usageForm?.controls?.actualOutOfFleetDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="true"
                ></sof-input-date-picker>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.EXPECTED-IN-FLEET-DATE' | translate"
                class="col-sm"
              >
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="usageForm?.controls?.expectedInFleetDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="isReadonly"
                ></sof-input-date-picker>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.EXPECTED-OUT-OF-FLEET-DATE' | translate"
                class="col-sm"
              >
                <sof-input-date-picker
                  [sofInput]
                  [formControl]="usageForm?.controls?.expectedOutOfFleetDate"
                  [dateFormat]="dateFormat"
                  [showToday]="true"
                  [isDisabled]="isReadonly"
                ></sof-input-date-picker>
              </sof-input-wrapper>
            </div>
          </div>
        </div>
      </div>
    </sof-form>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogUsageTabContentComponent implements OnInit {
  KEY_SEAT_CHOICE_COUNT = 'SEAT-CHOICE';

  @Input() tc: string;
  @Input() usageForm: FormGroup;
  @Input() dateFormat: DateFormatEnum;
  @Input() isReadonly: boolean;
  @Input() petsAllowed: boolean;
  @Input() kidsAllowed: boolean;
  @Input() abroadAllowed: boolean;
  @Input() intents: Array<Intent>;
  @Input() selectedIntents: Array<string>;
  @Input() errorMap: { [p: string]: string };

  @Output()
  petsAllowedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  kidsAllowedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  abroadAllowedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedIntentsChange: EventEmitter<
    Array<string>
  > = new EventEmitter<Array<string>>();

  // Used by main component to focus on first required field
  @ViewChild('vehicleUsageForm') vehicleUsageForm: ElementRef;

  seatChoices = [2, 3, 4, 5, 6, 7, 8, 9];
  seatChoices$: Observable<TranslatedData[]>;
  defaultStrategies$: Observable<TranslatedData[]>;
  reservationApprovals$: Observable<TranslatedData[]>;
  rentalTypes$: Observable<TranslatedData[]>;
  instantBookingPossibles$: Observable<TranslatedData[]>;
  operationalStatuses$: Observable<TranslatedData[]>;

  constructor(
    private translateService: TranslateService,
    private translateParser: TranslateParser
  ) {}

  ngOnInit(): void {
    this.seatChoices$ = this.getSeatChoices$();
    this.defaultStrategies$ = this.getDefaultStrategies$();
    this.reservationApprovals$ = this.getReservationApprovals$();
    this.rentalTypes$ = this.getRentalTypes$();
    this.instantBookingPossibles$ = this.getInstantBookingPossibles$();
    this.operationalStatuses$ = this.getOperationalStatuses$();
  }

  private getSeatChoices$(): Observable<TranslatedData[]> {
    return this.translateService
      .get([this.tc + '.' + this.KEY_SEAT_CHOICE_COUNT])
      .pipe(
        take(1),
        map(translations => {
          return this.seatChoices.map(seatChoice => {
            return {
              value: seatChoice,
              translation: this.translateParser.interpolate(
                translations[this.tc + '.' + this.KEY_SEAT_CHOICE_COUNT],
                { seatCount: seatChoice }
              )
            };
          });
        })
      );
  }

  private getDefaultStrategies$(): Observable<TranslatedData[]> {
    const strategies: TranslatedData[] = [
      {
        value: true,
        translation: this.tc + '.DEFAULT-STRATEGY_AVAILABLE'
      },
      {
        value: false,
        translation: this.tc + '.DEFAULT-STRATEGY_NOT_AVAILABLE'
      }
    ];
    return TranslateUtils.getTranslatedData$(this.translateService, strategies);
  }

  private getReservationApprovals$(): Observable<TranslatedData[]> {
    const reservationApprovals: TranslatedData[] = [
      {
        value: true,
        translation: this.tc + '.RESERVATION-APPROVAL-AUTOMATIC'
      },
      {
        value: false,
        translation: this.tc + '.RESERVATION-APPROVAL-MANUAL'
      }
    ];
    return TranslateUtils.getTranslatedData$(
      this.translateService,
      reservationApprovals
    );
  }

  private getRentalTypes$(): Observable<TranslatedData[]> {
    const rentalTypes: TranslatedData[] = [
      {
        value: RentalTypeDto.DAILY,
        translation: this.tc + '.RENTAL_TYPE_DAILY'
      },
      {
        value: RentalTypeDto.DAILYLASTMINUTEHOURLY,
        translation: this.tc + '.RENTAL_TYPE_DAILY-LAST-MINUTE-HOURLY'
      },
      {
        value: RentalTypeDto.HOURLY,
        translation: this.tc + '.RENTAL_TYPE_HOURLY'
      }
    ];
    return TranslateUtils.getTranslatedData$(
      this.translateService,
      rentalTypes
    );
  }

  private getInstantBookingPossibles$(): Observable<TranslatedData[]> {
    const instantBookingPossibles: TranslatedData[] = [
      {
        value: true,
        translation: this.tc + '.INSTANT-BOOKING-POSSIBLE'
      },
      {
        value: false,
        translation: this.tc + '.INSTANT-BOOKING-NOT-POSSIBLE'
      }
    ];
    return TranslateUtils.getTranslatedData$(
      this.translateService,
      instantBookingPossibles
    );
  }

  private getOperationalStatuses$(): Observable<TranslatedData[]> {
    const operationalStatuses: TranslatedData[] = [
      {
        value: VehicleOperationalStatusDto.ACTIVE,
        translation: this.tc + '.OPERATIONAL_STATUS_ACTIVE'
      },
      {
        value: VehicleOperationalStatusDto.ONBOARDING,
        translation: this.tc + '.OPERATIONAL_STATUS_ON-BOARDING'
      },
      {
        value: VehicleOperationalStatusDto.PHASEDOUT,
        translation: this.tc + '.OPERATIONAL_STATUS_PHASED-OUT'
      }
    ];
    return TranslateUtils.getTranslatedData$(
      this.translateService,
      operationalStatuses
    );
  }

  translatedDataValueFn = t => t?.value;
  translatedDataLabelFn = t => t?.translation;
}
