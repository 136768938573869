import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ToastUtilService } from '../../../services/toast-util.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'sof-text-area-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.' + titleLabel | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner *ngIf="showLoader"></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill flex-column">
          <div class="text-area-dialog-label">
            {{ tc + '.' + textLabel | translate }}
          </div>
          <sof-form [tc]="tc" [formGroup]="formGroup">
            <sof-input-wrapper [label]="tc + '.' + textAreaLabel | translate">
              <sof-input-textarea
                sofFocus
                [sofInput]
                [formControl]="formGroup?.controls?.message"
                [placeholder]="tc + '.' + textAreaPlaceHolder | translate"
                class="comment"
              >
              </sof-input-textarea>
            </sof-input-wrapper>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.' + cancelLabel | translate }}
          </button>
          <button
            sofButton
            (click)="onConfirmClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.' + confirmLabel | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./text-area-dialog.component.scss']
})
export class TextAreaDialogComponent {
  @Input() tc: string;
  @Input() titleLabel: string;
  @Input() textLabel: string;
  @Input() textAreaLabel: string;
  @Input() textAreaPlaceHolder: string;
  @Input() cancelLabel = 'DIALOG-CANCEL';
  @Input() confirmLabel = 'DIALOG-CONFIRM';
  @Input() showLoader = false;
  @Input() set required(value: boolean) {
    if (value) {
      this.formGroup.controls.message.setValidators(Validators.required);
    } else {
      this.formGroup.controls.message.clearValidators();
    }
  }

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmDialog: EventEmitter<string> = new EventEmitter<string>();

  formGroup = this.fb.group({
    message: ['']
  });

  constructor(
    private toastUtilService: ToastUtilService,
    private fb: FormBuilder
  ) {}

  onConfirmClick(): void {
    if (this.formGroup.valid) {
      this.confirmDialog.emit(this.formGroup.value.message);
    }
  }
}
