import { ActionTypes } from '../action-types';
import { UnavailabilityEvent } from '../../types/unavailability-event.type';

export class SetUnavailabilitiesAction {
  type = ActionTypes.SET_UNAVAILABILITIES;
  payload: { unavailabilities: UnavailabilityEvent[] };

  constructor(unavailabilities: UnavailabilityEvent[]) {
    this.payload = { unavailabilities };
  }
}

export type UnavailabilitiesActions = SetUnavailabilitiesAction;
