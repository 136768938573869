import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-letters-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="avatar size-{{ size ? size : '45' }}" [ngClass]="avatarClass">
        <div class="avatar-letters">{{ avatarInitials }}</div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./letters-avatar.component.scss']
})
export class LettersAvatarComponent {
  @Input() avatarClass: '' | 'blue' | 'yellow' | 'green' | 'red' | 'grey';
  @Input() avatarInitials: string = null;
  @Input() size: '30' | '32' | '40' | '45' | '48';
}
