import { Injectable } from '@angular/core';
import { BookingService } from '../services/booking.service';
import { Observable } from 'rxjs';
import {
  BookingDto,
  BookingsPageDto
} from '../../../client';
import { VehicleUsageService } from '../services/vehicle-usage.service';
import { Store } from '@ngrx/store';
import { AppState } from '../statemanagement/app.state';
import { SettingService } from '../services/setting.service';
import { UserService } from '../services/user.service';

@Injectable()
export class BookingManagementSandbox {
  currentUser$ = this.store.select(state => state.currentUser);
  allVehicles$ = this.bookingService.allVehicles$;
  allUsers$ = this.userService.allUsers$;

  constructor(
    private bookingService: BookingService,
    private vehicleUsageService: VehicleUsageService,
    private settingService: SettingService,
    private userService: UserService,
    private store: Store<AppState>
  ) {}

  searchBookings(searchBookingRequestDto): Observable<BookingsPageDto> {
    return this.bookingService.searchBookings(searchBookingRequestDto);
  }

  getBooking(bookingId: string): Observable<BookingDto> {
    return this.bookingService.getBooking(bookingId);
  }

  canChangeUser(): Observable<boolean> {
    return this.settingService.canChangeUser$;
  }
}
