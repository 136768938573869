/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventActionDto = 'APPROVE' | 'CANCEL' | 'CANCEL_REMOTE' | 'CREATE' | 'DELETE' | 'REJECT' | 'REPLACE' | 'START' | 'STOP' | 'UPDATE';

export const EventActionDto = {
    APPROVE: 'APPROVE' as EventActionDto,
    CANCEL: 'CANCEL' as EventActionDto,
    CANCELREMOTE: 'CANCEL_REMOTE' as EventActionDto,
    CREATE: 'CREATE' as EventActionDto,
    DELETE: 'DELETE' as EventActionDto,
    REJECT: 'REJECT' as EventActionDto,
    REPLACE: 'REPLACE' as EventActionDto,
    START: 'START' as EventActionDto,
    STOP: 'STOP' as EventActionDto,
    UPDATE: 'UPDATE' as EventActionDto
};

