import { ActionTypes } from '../action-types';
import { MessagesActions } from '../actions/messages.actions';
import * as _ from 'lodash';
import { ConversationMessage } from '../../shared-ui/components/conversation-messages/types/conversation-message.type';

export function messagesReducer(
  state: ConversationMessage[] = [],
  action: MessagesActions
): ConversationMessage[] {
  switch (action.type) {
    case ActionTypes.CLEAR_MESSAGES:
      return [];
    case ActionTypes.APPEND_NEW_MESSAGES:
      const messages = action.payload.messages;
      return messages.length < 2 && state.length > 0
        ? state
        : _.uniqBy([...state, ...messages], msg => msg.id);
    case ActionTypes.NEW_MESSAGE:
      return [...state, action.payload.message];
    case ActionTypes.UPDATE_MESSAGE:
      return state.map(item =>
        item.id === action.payload.oldId ? { ...action.payload.message } : item
      );
    default:
      return state;
  }
}
