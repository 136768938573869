import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  OnChanges,
  OnInit
} from '@angular/core';
import { WindowRefService } from '@sofico-framework/utils';
import { BookingWithStyle } from '../../../types/booking-with-style.type';
import { Observable, Subject, combineLatest, fromEvent, merge } from 'rxjs';
import {
  map,
  mapTo,
  startWith,
  filter,
  pairwise,
  withLatestFrom,
  tap
} from 'rxjs/operators';
import { takeUntilDestroy, Changes, UntilDestroy } from 'ngx-reactivetoolkit';

import { Duration } from '../../../classes/duration.class';
import { BookingUpdate } from '../../../types/booking-update.type';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipContentValuesEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import { HandleType } from '../../../types/handle-type.type';
import {
  RelativeTooltipPos,
  Tooltip,
  TooltipType
} from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { BookingUserDto } from  '../../../../../client';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';
import { BookingResizeInfo } from '../../../types/booking-resize-info.type';

@UntilDestroy()
@Component({
  selector: 'sof-booking-scheduler-body-subrow',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="cell"
      *ngFor="let cell of cells; trackBy: trackByIndex"
      [ngClass]="{
        isToday: cell.isToday,
        isWeekend: cell.isWeekend,
        'highlighted-class': highlighting
      }"
    ></div>

    <sof-booking
      class="booking"
      *ngFor="let bookingWithStyle of bookingsWithStyle"
      [booking]="bookingWithStyle"
      [ngStyle]="bookingWithStyle.posAndSize"
      [style.borderBottom]="
        bookingWithStyle.bookingHighlightStyle?.borderBottom
      "
      [isDragged]="
        bookingOnResizeInfos?.internalId === bookingWithStyle.internalId ||
        internalBookingDraggedId === bookingWithStyle.internalId
      "
      [sharedTooltipEvent]="sharedTooltipEvent"
      [currentUser]="currentUser"
      [attr.tooltip-parent-id]="bookingWithStyle.internalId"
      (mousedownOnLeftHandle)="
        onMouseDownClickOnHandle(HANDLE_TYPE_LEFT, bookingWithStyle)
      "
      (mousedownOnRightHandle)="
        onMouseDownClickOnHandle(HANDLE_TYPE_RIGHT, bookingWithStyle)
      "
      (mouseDownOnContent)="mouseDownOnBooking.emit($event)"
      (dblClickOnContent)="dblClickOnBooking.emit($event)"
      (tooltipEvent)="tooltipEvent.emit($event)"
    >
    </sof-booking>

    <sof-booking-scheduler-highlighted-area
      class="highlighted-area booking"
      [schedulerDateFrom]="schedulerDateFrom"
      [msPerPixel]="msPerPixel"
      [smallestDragUnit]="smallestDragUnit"
      [leftPx]="(highlightedAreaLeftAndWidth$ | async)?.left"
      [widthPx]="(highlightedAreaLeftAndWidth$ | async)?.width"
      [shiftKeyPressed]="false"
      [display]="(isResizing$ | async) ? 'block' : 'none'"
      [bookingOnResizeInfos]="bookingOnResizeInfos"
      (fromDate)="highlightedAreaFromDate = $event"
      (toDate)="highlightedAreaToDate = $event"
    >
    </sof-booking-scheduler-highlighted-area>
  `,
  styleUrls: ['./booking-scheduler-body-subrow.component.scss']
})
export class BookingSchedulerBodySubrowComponent implements OnChanges, OnInit {
  HANDLE_TYPE_LEFT = HandleType.LEFT;
  HANDLE_TYPE_RIGHT = HandleType.RIGHT;
  @Input() bookingsWithStyle: BookingWithStyle[];
  @Input() cells: BookingSchedulerDate[];
  @Input() highlighting: boolean;
  @Input() schedulerDateFrom: Date;
  @Input() msPerPixel: number;
  @Input() smallestDragUnit: Duration;
  @Input() internalBookingDraggedId: string;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  isResizingBooking: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  updateBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();

  @Output()
  mouseDownOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();

  @Output()
  dblClickOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();

  @HostBinding('class.isResizing') applyIsResizingClass = false;

  @Output()
  isDraggingBooking: EventEmitter<boolean> = new EventEmitter<boolean>();

  windowMouseUp$: Observable<MouseEvent> = fromEvent(
    this.windowsRef.nativeWindow,
    'mouseup'
  );

  tooltipOnBookingResizing: Tooltip = {
    id: TooltipType.DRAG.toString(),
    type: TooltipType.DRAG,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      fromDate: undefined,
      toDate: undefined
    }
  };
  relativeTooltipPosRightHandle: RelativeTooltipPos[] = [
    {
      targetRectAnchor: RelativePos.BOTTOM_RIGHT,
      tooltipAnchor: RelativePos.TOP_RIGHT
    },
    {
      targetRectAnchor: RelativePos.BOTTOM_LEFT,
      tooltipAnchor: RelativePos.TOP_LEFT
    },
    {
      targetRectAnchor: RelativePos.TOP_RIGHT,
      tooltipAnchor: RelativePos.BOTTOM_RIGHT
    },
    {
      targetRectAnchor: RelativePos.TOP_LEFT,
      tooltipAnchor: RelativePos.BOTTOM_LEFT
    }
  ];

  // contains the informations of the booking currently resized
  bookingOnResizeInfos: BookingResizeInfo;

  // corresponding to the first and last date position of the highlighted area
  highlightedAreaFromDate: Date;
  highlightedAreaToDate: Date;

  // this stream emits 'left' or 'right' when a handle (the left or right one) is mousedown on the subrow
  clickOnHandle$: Subject<HandleType> = new Subject<HandleType>();

  /*
  mouseMove$: Observable<MouseEvent> = fromEvent(
    this.element.nativeElement,
    'mousemove'
  );
 */
  mouseMove$: Observable<MouseEvent> = fromEvent(
    this.windowsRef.nativeWindow,
    'mousemove'
  );

  // simply true when the user is dragging the mouse to resize a booking
  isResizing$: Observable<boolean>;

  // the width of the drag, can be negative if it's on the left direction
  // only emit when the user is dragging
  resizingWidth$: Observable<number>;

  // contains the left position and the width of the highlighted area
  // if the user drag has a width <= 0, the values becomes the ones of the original booking
  highlightedAreaLeftAndWidth$: Observable<{
    width: number;
    left: number;
  }>;

  // this stream emit only when a booking handle is released (a left or right one)
  // and only if the drag width is > 5 pixels
  dragHandleEnd$: Observable<HandleType>;

  constructor(
    private element: ElementRef,
    private windowsRef: WindowRefService
  ) {}

  // on a click on a booking handle
  // - we store infos about the booking
  // - we next the subject
  onMouseDownClickOnHandle(
    type: HandleType,
    bookingWithStyle: BookingWithStyle
  ): void {
    this.bookingOnResizeInfos = {
      internalId: bookingWithStyle.internalId,
      remoteId: bookingWithStyle.remoteId,
      remoteVehicleId: bookingWithStyle.remoteVehicleId,
      width: bookingWithStyle.posAndSize['width.px'],
      left: bookingWithStyle.posAndSize['left.px'],
      fromDate: bookingWithStyle.fromDate,
      toDate: bookingWithStyle.toDate,
      data: bookingWithStyle.data
    };
    this.clickOnHandle$.next(type);
  }

  trackByIndex = i => i;

  ngOnChanges(): void {}

  ngOnInit(): void {
    // simply true when the user is dragging the mouse to resize a booking
    this.isResizing$ = merge(
      this.clickOnHandle$.pipe(mapTo(true)),
      this.windowMouseUp$.pipe(mapTo(false))
    ).pipe(startWith(false));

    // the width of the drag, can be negative if it's on the left direction
    // only emit when the user is dragging
    this.resizingWidth$ = combineLatest([
      this.clickOnHandle$,

      // TODO cabu : it would be better to have a mousemove as input from the container
      // // fromEvent(this.windowsRef.nativeWindow, 'mousemove'), // <-- this approach is not performant
      this.mouseMove$,
      this.isResizing$
    ]).pipe(
      filter(
        ([handle, mouseMove, isResizing]: [HandleType, MouseEvent, boolean]) =>
          isResizing
      ),
      map(
        ([handle, mouseMove, isResizing]: [
          HandleType,
          MouseEvent,
          boolean
        ]) => {
          const nativeElementLeft = this.element.nativeElement.getBoundingClientRect()
            .left;
          const mouseMovePosLeft = mouseMove.pageX - nativeElementLeft;
          const resizingWidth =
            mouseMovePosLeft -
            this.bookingOnResizeInfos.left -
            (handle === HandleType.RIGHT ? this.bookingOnResizeInfos.width : 0);
          return this.fixResizingWidth(handle, resizingWidth);
        }
      )
    );

    // contains the left position and the width of the highlighted area
    // if the user drag has a width <= 0, the values becomes the ones of the original booking
    this.highlightedAreaLeftAndWidth$ = combineLatest([
      this.clickOnHandle$,
      this.resizingWidth$
    ]).pipe(
      map(([handle, resizingWidth]) => {
        // calculation of the width and left position of the highlighted area
        const left =
          this.bookingOnResizeInfos.left +
          (handle === HandleType.LEFT ? resizingWidth : 0);
        const width =
          this.bookingOnResizeInfos.width +
          (handle === HandleType.RIGHT ? resizingWidth : -resizingWidth);

        // it may happen that the width is negative
        // in this case we apply the initial width and left of the booking resized
        return width > 0
          ? {
              width,
              left
            }
          : {
              width: 0,
              left
            };
      })
    );

    // this stream emit only when a booking handle is released (a left or right one)
    // and only if the drag width is > 5 pixels
    this.dragHandleEnd$ = this.isResizing$.pipe(
      pairwise(),
      filter(
        ([lastValue, newValue]) => lastValue === true && newValue === false
      ),
      withLatestFrom(combineLatest([this.resizingWidth$, this.clickOnHandle$])),
      filter(
        ([dragEnd, [resizingWidth, handle]]) => Math.abs(resizingWidth) > 5
      ),
      map(([dragEnd, [resizingWidth, handle]]) => handle)
    );

    // we communicate to the parent that the user is resizing a booking
    this.isResizing$.pipe(takeUntilDestroy(this)).subscribe(isResizing => {
      this.isResizingBooking.emit(isResizing);
      this.applyIsResizingClass = isResizing;
    });

    // when the user begins to resize
    this.clickOnHandle$
      .pipe(withLatestFrom(this.mouseMove$), takeUntilDestroy(this))
      .subscribe(([clickOnHandle, mouseEvent]: [HandleType, MouseEvent]) => {
        const nativeElementLeft = this.element.nativeElement.getBoundingClientRect()
          .left;
        const tooltip: Tooltip = {
          ...this.tooltipOnBookingResizing,
          targetRect: {
            width: this.bookingOnResizeInfos.width,
            height: this.element.nativeElement.getBoundingClientRect().height,
            top: this.element.nativeElement.getBoundingClientRect().top,
            left: this.bookingOnResizeInfos.left + nativeElementLeft
          }
        };
        if (clickOnHandle === HandleType.RIGHT) {
          tooltip.relativeTooltipPos = [...this.relativeTooltipPosRightHandle];
        }
        this.tooltipEvent.emit(new AddTooltipEvent(tooltip));
      });

    // when the user is reszing,
    // we update the tooltip content and position
    combineLatest([this.isResizing$, this.highlightedAreaLeftAndWidth$])
      .pipe(
        filter(([isResizing, highlightedAreaLeftAndWidth]) => isResizing),
        takeUntilDestroy(this)
      )
      .subscribe(
        ([isResizing, highlightedAreaLeftAndWidth]: [
          boolean,
          {
            width: number;
            left: number;
          }
        ]) => {
          const nativeElementLeft = this.element.nativeElement.getBoundingClientRect()
            .left;
          // we update the position
          this.tooltipEvent.emit(
            new UpdateTooltipTargetRectEvent(this.tooltipOnBookingResizing.id, {
              width: highlightedAreaLeftAndWidth.width,
              left: highlightedAreaLeftAndWidth.left + nativeElementLeft
            })
          );
          // we update the content
          this.tooltipEvent.emit(
            new UpdateTooltipContentValuesEvent(
              this.tooltipOnBookingResizing.id,
              {
                fromDate: this.highlightedAreaFromDate,
                toDate: this.highlightedAreaToDate
              }
            )
          );
        }
      );

    // when the user release the handle,
    // we emit the booking and we delete the tooltip
    this.dragHandleEnd$
      .pipe(takeUntilDestroy(this))
      .subscribe((type: HandleType) => {
        // we delete the tooltip
        this.tooltipEvent.emit(
          new DeleteTooltipEvent(this.tooltipOnBookingResizing.id)
        );

        // we emit the update (only if the date is different)
        if (
          type === HandleType.LEFT &&
          this.bookingOnResizeInfos.fromDate.valueOf() !==
            this.highlightedAreaFromDate.valueOf()
        ) {
          this.updateBooking.emit({
            previousBooking: {
              internalId: this.bookingOnResizeInfos.internalId,
              remoteId: this.bookingOnResizeInfos.remoteId,
              remoteVehicleId: this.bookingOnResizeInfos.remoteId,
              fromDate: this.bookingOnResizeInfos.fromDate,
              toDate: this.bookingOnResizeInfos.toDate,
              comments: this.bookingOnResizeInfos.data.comments,
              tripType: null,
              userId: this.bookingOnResizeInfos.data.user.remoteId
            },
            newFields: {
              fromDate: this.highlightedAreaFromDate
            }
          });
        } else if (
          type === HandleType.RIGHT &&
          this.bookingOnResizeInfos.toDate.valueOf() !==
            this.highlightedAreaToDate.valueOf()
        ) {
          this.updateBooking.emit({
            previousBooking: {
              internalId: this.bookingOnResizeInfos.internalId,
              remoteId: this.bookingOnResizeInfos.remoteId,
              remoteVehicleId: this.bookingOnResizeInfos.remoteId,
              fromDate: this.bookingOnResizeInfos.fromDate,
              toDate: this.bookingOnResizeInfos.toDate,
              comments: this.bookingOnResizeInfos.data.comments,
              tripType: null,
              userId: this.bookingOnResizeInfos.data.user.remoteId
            },
            newFields: {
              toDate: this.highlightedAreaToDate
            }
          });
        }
        this.bookingOnResizeInfos = null;
      });
  }

  fixResizingWidth(handle: HandleType, resizingWidth: number): number {
    const nativeElementWidth = this.element.nativeElement.getBoundingClientRect()
      .width;
    let minResizingWidth: number;
    let maxResizingWidth: number;
    if (handle === HandleType.LEFT) {
      minResizingWidth = -this.bookingOnResizeInfos.left;
      maxResizingWidth = this.bookingOnResizeInfos.width;
    } else {
      minResizingWidth = -this.bookingOnResizeInfos.width;
      maxResizingWidth =
        nativeElementWidth -
        this.bookingOnResizeInfos.left -
        this.bookingOnResizeInfos.width;
    }
    if (resizingWidth < minResizingWidth) {
      return minResizingWidth;
    } else if (resizingWidth > maxResizingWidth) {
      return maxResizingWidth;
    }
    return resizingWidth;
  }
}
