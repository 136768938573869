import { ActionTypes } from '../action-types';
import { ViewMode } from '../../types/view-mode.type';

export class SetDateFromAction {
  type = ActionTypes.SET_DATE_FROM;
  payload: { dateFrom: Date };

  constructor(dateFrom: Date) {
    this.payload = { dateFrom };
  }
}

export class SetViewmodeAction {
  type = ActionTypes.SET_VIEWMODE;
  payload: { viewMode: ViewMode };

  constructor(viewMode: ViewMode) {
    this.payload = { viewMode };
  }
}

export class SetShowMapAction {
  type = ActionTypes.SET_SHOW_MAP;
  payload: { showMap: boolean };

  constructor(showMap: boolean) {
    this.payload = { showMap };
  }
}

export class SetCurrentOrganizationIdAction {
  type = ActionTypes.SET_CURRENT_ORGANIZATION_ID;
  payload: { currentOrganizationId: string };

  constructor(currentOrganizationId: string) {
    this.payload = { currentOrganizationId };
  }
}

export class SetShowMyBookingsAction {
  type = ActionTypes.SET_SHOW_MY_BOOKINGS;
  payload: { showMyBookings: boolean };

  constructor(showMyBookings: boolean) {
    this.payload = { showMyBookings };
  }
}

export type PlanningActions =
  | SetDateFromAction
  | SetViewmodeAction
  | SetShowMapAction
  | SetCurrentOrganizationIdAction
  | SetShowMyBookingsAction;
