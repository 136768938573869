import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetRouterState, hotSafe } from '@sofico-framework/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import {
  DriverGradeDto,
  OrganizationDto,
  OrganizationMemberDto
} from  '../../../../client';
import { ToastUtilService } from '../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';

@Injectable()
export class OrganizationMemberDetailViewHelper {
  triggerOrganizationSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );

  organizationId$ = this.getOrganizationId$();
  organization$ = this.getOrganization$();
  userId$ = this.getUserId$();
  organizationMember$ = this.getOrganizationMember$();
  driverGradesToAdd$ = this.getDriverGradesToAdd$();
  disableAddDriverGrade$ = this.getDisableAddDriverGrade$();

  @GetRouterState()
  private routerState$: Observable<any>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private activatedRoute: ActivatedRoute
  ) {}

  private getOrganization$(): Observable<OrganizationDto> {
    return combineLatest([
      this.organizationId$,
      this.triggerOrganizationSub$
    ]).pipe(
      switchMap(([organizationId, trigger]) => {
        if (!organizationId) {
          return of(null);
        }
        return this.osb.getOrganization(organizationId);
      }),
      shareReplay(1)
    );
  }

  private getOrganizationMember$(): Observable<OrganizationMemberDto> {
    return combineLatest([this.organization$, this.userId$]).pipe(
      map(([organization, userId]) => {
        return organization?.members?.find(
          member => member?.user?.remoteId === userId
        );
      }),
      hotSafe()
    );
  }

  private getDriverGradesToAdd$(): Observable<DriverGradeDto[]> {
    return this.organization$.pipe(
      map(organization => [...organization?.driverGrades])
    );
  }

  private getDisableAddDriverGrade$(): Observable<boolean> {
    return this.organizationMember$.pipe(
      map(organizationMember => organizationMember?.driverGrades?.length > 0)
    );
  }

  private getOrganizationId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.organizationId),
      switchMap(([p, state]) => of(p.organizationId)),
      hotSafe()
    );
  }

  private getUserId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.userId),
      switchMap(([p, state]) => of(p.userId)),
      hotSafe()
    );
  }

  triggerOrganization(): void {
    this.triggerOrganizationSub$.next(new Date());
  }
}
