import { Event } from '../../types/event.type';
import { SchedulerDateUtils } from './scheduler-date.utils';
import { Booking } from '../../types/booking.type';

export class EventUtils {
  // this function takes an event and few information about the period displayed,
  // and return a width and left attribute
  static getLeftAndWidth(
    event: Event,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): { 'width.px': number; 'left.px': number } {
    return this.getLeftAndWidthForPeriod(
      event.fromDate,
      event.toDate,
      msPerPixel,
      schedulerDateFrom,
      schedulerDateTo
    );
  }

  static getLeftAndWidthForPeriod(
    fromDate: Date,
    toDate: Date,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): { 'width.px': number; 'left.px': number } {
    // we calculate the event dates bounded by the period displayed.
    const eventVisibleDateFrom: Date = this.calculateVisibleStartDate(
      fromDate,
      schedulerDateFrom
    );
    const eventVisibleDateTo: Date = this.calculateVisibleEndDate(
      toDate,
      schedulerDateFrom,
      schedulerDateTo
    );

    return {
      'width.px':
        (SchedulerDateUtils.getTimeInMillisDSTClean(
          eventVisibleDateTo,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            eventVisibleDateFrom,
            schedulerDateFrom
          )) /
        msPerPixel,
      'left.px':
        (SchedulerDateUtils.getTimeInMillisDSTClean(
          eventVisibleDateFrom,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            schedulerDateFrom,
            schedulerDateFrom
          )) /
        msPerPixel
    };
  }

  static calculateVisibleStartDate(
    fromDate: Date,
    schedulerDateFrom: Date
  ): Date {
    return fromDate < schedulerDateFrom ? schedulerDateFrom : fromDate;
  }

  static calculateVisibleEndDate(
    toDate: Date,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): Date {
    if (toDate < schedulerDateFrom) {
      return schedulerDateFrom;
    }
    return toDate > schedulerDateTo ? schedulerDateTo : toDate;
  }

  // return true if the two events have a common period
  static isEventsOverlap(eventA: Event, eventB: Event): boolean {
    return eventA.fromDate < eventB.toDate && eventA.toDate > eventB.fromDate;
  }

  static isOnThisPeriod(
    dateFrom: Date,
    dateTo: Date,
    periodDateFrom: Date,
    periodDateTo: Date
  ): boolean {
    return dateFrom < periodDateTo && dateTo > periodDateFrom;
  }

  static isEventOnThisPeriod(
    event: Event,
    periodDateFrom: Date,
    periodDateTo: Date
  ): boolean {
    return EventUtils.isOnThisPeriod(
      event.fromDate,
      event.toDate,
      periodDateFrom,
      periodDateTo
    );
  }
}
