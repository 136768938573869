import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import {
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { ToastUtilService } from '../../../services/toast-util.service';
import { SharedUiSandbox } from '../../shared-ui.sandbox';
import {
  BookingDto,
  ConversationDto,
  TripTypeDto,
  UserReferenceDto
} from '../../../../../client';
import { ConversationMessage } from '../conversation-messages/types/conversation-message.type';
import { ConversationWithDetail } from './types/conversation-with-detail.type';
import { DateUtil } from '../../../helpers/date-util';

@Component({
  selector: 'sof-chat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="search-bar">
        <input
          placeholder="{{ tc + '.SEARCH-PLACEHOLDER' | translate }}"
          autocomplete="off"
          class="search-input"
          (input)="onSearchChange($event)"
        />
      </div>
      <sof-conversation-list
        [tc]="tc"
        [currentUser]="currentUser$ | async"
        [conversations]="filteredConversations$ | async"
        [messages]="messages$ | async"
        [selectedConversation]="selectedConversation$ | async"
        [clearMessage]="clearMessage$ | async"
        [canEdit]="canEdit"
        (selectedConversationChange)="onSelectedConversationChange($event)"
        (addMessage)="onAddMessage($event)"
        (addAttachment)="onAddAttachment($event)"
        (retryAddAttachment)="onRetryAddAttachment($event)"
        (downloadAttachment)="onDownloadAttachment($event)"
        (editBooking)="onEditBooking($event)"
      >
      </sof-conversation-list>
    </ng-container>
  `,
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() tc: string;

  conversations$ = this.sharedUiSandbox.conversations$.pipe(shareReplay(1));
  messages$ = this.sharedUiSandbox.messages$.pipe(shareReplay(1));
  currentUser$ = this.sharedUiSandbox.currentUser$.pipe(shareReplay(1));

  newSearchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedConversation$: BehaviorSubject<ConversationWithDetail> = new BehaviorSubject<ConversationWithDetail>(
    null
  );
  clearMessage$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

  canEdit = true;

  filteredConversations$ = combineLatest([
    this.conversations$,
    this.newSearchText$
  ]).pipe(
    map(([conversations, searchText]) => {
      let searchStrings = searchText.toLowerCase().split(' ');
      searchStrings = searchStrings.filter(
        searchString => searchString && searchString !== ''
      );
      if (searchStrings.length === 0) {
        return conversations;
      }
      const filtered = conversations.filter(
        conversation =>
          !this.isConversationFiltered(conversation, searchStrings)
      );
      if (
        !!this.selectedConversation$.value &&
        !filtered?.find(
          conversation =>
            conversation.id === this.selectedConversation$.value.id
        )
      ) {
        this.selectedConversation$.next(null);
        this.sharedUiSandbox.stopMessagesPolling();
      }
      return filtered;
    })
  );

  constructor(
    private sharedUiSandbox: SharedUiSandbox,
    private toastUtilService: ToastUtilService
  ) {
    this.selectedConversation$
      .pipe(
        tap(selectedConversation => {
          this.canEdit = !!selectedConversation && !selectedConversation.closed;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.selectedConversation$
      .pipe(
        filter(conversation => !!conversation),
        switchMap((conversation: ConversationDto) =>
          conversation
            ? this.sharedUiSandbox.pollMessagesByConversation(conversation.id)
            : of(null)
        ),
        share()
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sharedUiSandbox.stopMessagesPolling();
  }

  onStopPolling(): void {
    this.clearMessage$.next(new Date());
  }

  onSearchChange(event): void {
    this.newSearchText$.next(event.currentTarget.value);
  }

  onAddMessage(message: string): void {
    this.sharedUiSandbox
      .addMessage(this.selectedConversation$.value.id, message)
      .subscribe(
        () => {
          this.clearMessage$.next(new Date());
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_ADD-MESSAGE'
          );
        }
      );
  }

  onAddAttachment(files: Array<File>): void {
    // TODO manage multiple attachments
    if (files && files.length > 0) {
      this.currentUser$
        .pipe(
          switchMap(currentUser =>
            this.sharedUiSandbox.addAttachment(
              this.selectedConversation$.value.id,
              currentUser,
              files[0]
            )
          ),
          take(1)
        )
        .subscribe(
          () => {},
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_ADD-ATTACHMENT'
            );
          }
        );
    }
  }

  onRetryAddAttachment(message: ConversationMessage): void {
    this.sharedUiSandbox
      .retryAddAttachment(this.selectedConversation$.value.id, message)
      .subscribe(
        () => {},
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_RETRY-ADD-ATTACHMENT'
          );
        }
      );
  }

  onDownloadAttachment(message: ConversationMessage): void {
    this.sharedUiSandbox
      .downloadAttachment(this.selectedConversation$.value.id, message)
      .subscribe(
        data => {
          if (data) {
            this.saveAs(data as Blob, message.attachment.name);
          }
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_DOWNLOAD-ATTACHMENT'
          );
        }
      );
  }

  saveAs(blob, fileName): void {
    const url = window.URL.createObjectURL(blob);

    const anchorElem: HTMLAnchorElement = document.createElement('a');
    anchorElem.style.display = 'none';
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);

    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, at least on EdgeHTML 15.15048
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  searchStringsInString(searchStrings: string[], text: string): boolean {
    if (!text || text === '') {
      return false;
    }
    const lowerCaseText = text.toLowerCase();
    for (const searchString of searchStrings) {
      if (lowerCaseText.indexOf(searchString) === -1) {
        return false;
      }
    }
    return true;
  }

  searchParticipant(
    conversation: ConversationDto,
    searchStrings: string[]
  ): boolean {
    return !!conversation?.participants?.find(
      participant =>
        this.searchStringsInString(searchStrings, participant.userName) ||
        this.searchStringsInString(searchStrings, participant.displayName)
    );
  }

  searchInitiator(
    conversation: ConversationDto,
    searchStrings: string[]
  ): boolean {
    return (
      conversation.initiator &&
      (this.searchStringsInString(
        searchStrings,
        conversation.initiator.userName
      ) ||
        this.searchStringsInString(
          searchStrings,
          conversation.initiator.displayName
        ))
    );
  }

  isConversationFiltered(
    conversation: ConversationDto,
    searchStrings: string[]
  ): boolean {
    if (!searchStrings || searchStrings.length === 0) {
      return false;
    }
    return (
      !this.searchStringsInString(searchStrings, conversation.title) &&
      !this.searchStringsInString(
        searchStrings,
        conversation?.lastMessage?.message
      ) &&
      !this.searchInitiator(conversation, searchStrings) &&
      !this.searchParticipant(conversation, searchStrings)
    );
  }

  convertConversationDtoToConversationWithDetail(
    conversation: ConversationDto
  ): ConversationWithDetail {
    const allParticipants = new Array<UserReferenceDto>();
    allParticipants.push(conversation.initiator);
    allParticipants.push(...conversation.participants);
    return {
      ...conversation,
      allParticipants,
      previousDriver: conversation.referenceMap.previous_driver
        ? conversation.participants.find(
            user => user.remoteId === conversation.referenceMap.previous_driver
          )
        : null,
      nextDriver: conversation.referenceMap.next_driver
        ? conversation.participants.find(
            user => user.remoteId === conversation.referenceMap.next_driver
          )
        : null
    } as ConversationWithDetail;
  }

  onSelectedConversationChange(conversation: ConversationDto): void {
    const conversationWithDetail: ConversationWithDetail = this.convertConversationDtoToConversationWithDetail(
      conversation
    );
    let bookingId: string = null;
    if (conversation.referenceMap.current_booking) {
      bookingId = conversation.referenceMap.current_booking;
    } else if (conversation.referenceMap.booking) {
      bookingId = conversation.referenceMap.booking;
    }
    if (bookingId) {
      this.sharedUiSandbox.getBooking(bookingId).subscribe(
        booking => {
          conversationWithDetail.booking = booking;
          this.selectedConversation$.next(conversationWithDetail);
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_GET-BOOKING'
          );
        }
      );
    } else {
      this.selectedConversation$.next(conversationWithDetail);
    }
  }

  onEditBooking(bookingId: string): void {
    this.sharedUiSandbox.getBooking(bookingId).subscribe(
      bookingDto => {
        const fromDate: Date = DateUtil.convertToDate(
          bookingDto.plannedPeriod.start
        );
        const toDate: Date = DateUtil.convertToDate(
          bookingDto.plannedPeriod.end
        );
        const vehicleId: string = bookingDto.vehicle.id;
        this.openBookingDialog(
          bookingDto.id,
          bookingDto.user.remoteId,
          bookingDto.organization.id,
          vehicleId,
          fromDate,
          toDate,
          bookingDto.tripType,
          bookingDto.comments,
          false,
          bookingDto
        );
      },
      error => {
        this.toastUtilService.showError(error, this.tc + '.FAILED_GET-BOOKING');
      }
    );
  }

  openBookingDialog(
    remoteBookingId: string,
    userId: string,
    organizationId: string,
    remoteVehicleId: string,
    fromDate: Date,
    toDate: Date,
    tripType: TripTypeDto,
    comments: string,
    vehicleChange: boolean,
    bookingDto: BookingDto
  ): void {
    /* TODO - TO BE ADAPTED : CREATE AN EDIT BOOKING VIEW TO MANAGE THE BOOKING DIALOG
    // TODO - STREAMS TO BE REMOVED WHEN BOOKING POPUP REWORKED
    combineLatest([
      this.sharedUiSandbox.allVehicles$,
      this.sharedUiSandbox.allUsers$,
      this.currentUser$
    ])
      .pipe(take(1))
      .subscribe(([vehicles, users, currentUser]) => {
        const data: BookingDialogData = {
          internalBookingId: null,
          remoteBookingId,
          userId,
          organizationId,
          fromDate,
          toDate,
          remoteVehicleId,
          tripType,
          comments,
          vehicleChange,
          bookingDto,
          vehicles: vehicles?.vehicles,
          users: users?.users,
          currentUser
        };
        const dialogRef = this.dialog.open(BookingDialogComponent, {
          width: '1000px',
          disableClose: true, // Disable closing the dialog with a click outside
          panelClass: 'booking-dialog-container',
          data
        });
        dialogRef.afterClosed().subscribe(resultingBookingDto => {
          // User click on cancel ?
          if (resultingBookingDto !== undefined) {
            this.selectedConversation$.next({
              ...this.selectedConversation$.value,
              booking: resultingBookingDto
            });
          }
        });
      });
*/
  }
}
