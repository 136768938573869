import { UnavailabilityEvent } from '../../types/unavailability-event.type';
import { ActionTypes } from '../action-types';
import { PrivateUsagesActions } from '../actions/private-usages.actions';

export function privateUsagesReducer(
  state: UnavailabilityEvent[] = [],
  action: PrivateUsagesActions
): UnavailabilityEvent[] {
  switch (action.type) {
    case ActionTypes.SET_PRIVATE_USAGES:
      return [...action.payload.privateUsages];
    default:
      return state;
  }
}
