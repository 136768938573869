import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'sof-availability-edit-choices-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.EDITING-A-SERIES-OF-EVENTS' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="formGroup">
            <div class="edit-choices">
              <sof-input-radio
                [sofInput]
                [formControl]="formGroup?.controls?.editChoice"
                [value]="1"
              >
                {{ tc + '.EDIT-THIS-EVENT-ONLY' | translate }}
              </sof-input-radio>
              <sof-input-radio
                [sofInput]
                [formControl]="formGroup?.controls?.editChoice"
                [value]="2"
              >
                {{ tc + '.EDIT-THIS-AND-ALL-THE-FOLLOWING-EVENTS' | translate }}
              </sof-input-radio>
            </div>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onOkClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.OK' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class AvailabilityEditChoicesDialogComponent {
  @Input() tc: string;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() okDialog: EventEmitter<number> = new EventEmitter<number>();

  formGroup = this.fb.group({
    editChoice: [1, Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  onOkClick(): void {
    this.okDialog.emit(this.formGroup.value.editChoice);
  }
}
