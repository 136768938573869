import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Changes, takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';

@UntilDestroy()
@Component({
  selector: 'sof-horizontal-splitter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./horizontal-splitter.component.scss'],
  template: `
    <as-split
      direction="horizontal"
      (dragStart)="isDragging$.next(true)"
      (dragEnd)="isDragging$.next(false)"
      (drag)="splitIsDragging($event)"
    >
      <as-split-area [size]="leftSub$ | async">
        <ng-content select="[location=left]"> </ng-content>
      </as-split-area>

      <as-split-area [size]="rightSub$ | async">
        <ng-content select="[location=right]"> </ng-content>
      </as-split-area>
    </as-split>
  `
})
export class HorizontalSplitterComponent implements OnChanges, OnInit {
  @Input() areaSize: [number, number];
  @Output()
  newAreaSize: EventEmitter<[number, number]> = new EventEmitter<
    [number, number]
  >();

  @Changes('areaSize') areaSizeInput$: Observable<[number, number]>;
  isDragging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  areaSizeToApply$: Observable<[number, number]>;

  leftSub$: Subject<number> = new Subject<number>();
  rightSub$: Subject<number> = new Subject<number>();

  splitIsDragging(event): void {
    this.newAreaSize.emit(event.sizes);
  }

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.areaSizeToApply$ = combineLatest([
      this.areaSizeInput$,
      this.isDragging$
    ]).pipe(
      filter(([areaSizeInput, isDragging]) => !isDragging),
      map(([areaSizeInput, isDragging]) => areaSizeInput)
    );

    this.areaSizeToApply$.pipe(takeUntilDestroy(this)).subscribe(val => {
      this.leftSub$.next(val[0]);
      this.rightSub$.next(val[1]);
    });
  }
}
