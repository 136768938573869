import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { BookingWithStyle } from '../../../types/booking-with-style.type';
import { SchedulerSetting, tooltipSettings } from '../../../scheduler.setting';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import {
  Tooltip,
  TooltipContentBooking,
  TooltipType
} from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { TooltipCreationType } from '../../../types/tooltip-settings.type';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { BookingUserDto } from  '../../../../../client';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import { UserImage } from '../../../shared-ui/components/user-avatar/types/user-image.type';

@Component({
  selector: 'sof-booking',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./booking.component.scss'],
  template: `
    <div
      class="vehicle-usage-container"
      *ngIf="vehicleUsageContainerStyle"
      [ngStyle]="vehicleUsageContainerStyle"
      (click)="this.mouseClick$.next($event)"
      (dblclick)="onContentDblClick()"
    ></div>
    <div class="booking-container" [ngStyle]="bookingContainerStyle">
      <div
        *ngIf="
          !localBooking.bookingBeginBefore &&
          !localBooking.vehicleUsageBeginBefore &&
          bookingWideEnough &&
          startDateChangeAllowed
        "
        class="handle"
        [ngStyle]="handleStyle"
        (mousedown)="mousedownOnLeftHandle.emit()"
      ></div>
      <div
        class="content"
        (mousedown)="onContentMouseDown()"
        (mouseup)="mouseUp = true"
        (dblclick)="onContentDblClick()"
        (touchstart)="onTouchStart($event)"
        (touchend)="onTouchEnd($event)"
        (mouseenter)="onContentMouseEnter($event)"
        (click)="this.mouseClick$.next($event)"
        (mousemove)="onContentMouseMove($event)"
        (mouseleave)="onContentMouseLeave()"
      >
        <sof-user-avatar
          class="booking-user-avatar"
          *ngIf="showImage"
          [userImage]="userImage"
          [showTooltip]="true"
          [ngStyle]="{ 'background-color:hover': 'red' }"
        ></sof-user-avatar>
        <div class="booking-comments">
          {{ this.localBooking?.data?.comments }}
        </div>
      </div>
      <div
        *ngIf="
          !localBooking.bookingEndAfter &&
          !localBooking.vehicleUsageEndAfter &&
          bookingWideEnough &&
          endDateChangeAllowed
        "
        class="handle"
        [ngStyle]="handleStyle"
        (mousedown)="mousedownOnRightHandle.emit()"
      ></div>
    </div>
    <div
      class="extended-period-container"
      *ngIf="extendedPeriodContainerStyle"
      [ngStyle]="extendedPeriodContainerStyle"
      (click)="this.mouseClick$.next($event)"
      (dblclick)="onContentDblClick()"
    ></div>
  `
})
export class BookingComponent {
  @Input()
  set booking(booking: BookingWithStyle) {
    this.localBooking = booking;
    this.updateBookingStyles();
    this.showImage = false;
    this.bookingWideEnough = this.isBookingWideEnough();
    this.startDateChangeAllowed = this.isStartDateChangeAllowed();
    this.endDateChangeAllowed = this.isEndDateChangeAllowed();
    if (this.localBooking?.data) {
      this.showImage =
        !this.localBooking.data.comments ||
        this.localBooking.posAndSize['width.px'] > 60;
      this.userImage = {
        imageUrl: this.localBooking.data.user.imageUrl,
        imageSize: '30',
        displayName: this.localBooking.data.user.displayName,
        userName: this.localBooking.data.user.userName
      };
    }
  }
  @Input() currentUser: BookingUserDto;
  @Input() sharedTooltipEvent: TooltipEvent;

  @Output()
  mousedownOnRightHandle: EventEmitter<null> = new EventEmitter<null>();
  @Output()
  mousedownOnLeftHandle: EventEmitter<null> = new EventEmitter<null>();
  @Output()
  mouseDownOnContent: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();
  @Output()
  dblClickOnContent: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();

  @HostBinding('class.isDragged')
  @Input()
  isDragged = false;

  localBooking: BookingWithStyle;
  userImage: UserImage = null;
  showImage = false;
  bookingWideEnough = false;
  startDateChangeAllowed = false;
  endDateChangeAllowed = false;
  bookingContainerStyle = null;
  vehicleUsageContainerStyle = null;
  extendedPeriodContainerStyle = null;
  handleStyle = null;

  mouseClick$: BehaviorSubject<MouseEvent> = new BehaviorSubject<MouseEvent>(
    null
  );

  mouseSimpleClick$ = this.mouseClick$.pipe(
    debounceTime(250),
    filter(event => {
      return event && event.buttons === 0 && event.detail === 1;
    })
  );

  // ------ tooltip informations -------
  tooltip: Tooltip = {
    id: TooltipType.BOOKING,
    type: TooltipType.BOOKING,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      booking: undefined // assigned when the tooltip is created
    }
  };

  // -----------------------------------

  touchStartTimeStamp: number;
  mouseDownDurationMs = 400;

  // equal to true if there is a mouseup
  mouseUp = false;

  constructor() {
    this.mouseSimpleClick$.subscribe(event => this.onContentSimpleClick(event));
  }

  // mouseDown only emit after 100 ms

  onContentMouseDown(): void {
    this.mouseUp = false;
    // after n ms, if there is no mouseup, we emit a mouse down
    setTimeout(() => {
      if (!this.mouseUp) {
        this.mouseDownOnContent.emit(this.localBooking);
      }
    }, this.mouseDownDurationMs);
  }

  // To simulate a long tactile tap =>
  // difference between touchstart and touchend timeStamps
  onTouchStart(event): void {
    this.touchStartTimeStamp = event.timeStamp;
  }

  onTouchEnd(event): void {
    if (event.timeStamp - this.touchStartTimeStamp >= 1000) {
      this.dblClickOnContent.emit(this.localBooking);
      this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
    }
  }

  // ---- tooltip event ------

  onContentMouseEnter(event: MouseEvent): void {
    // if this in the option chosen, we emit a booking
    if (
      tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER &&
      event.buttons === 0
    ) {
      // NOTE cabu : we only display the tooltip if no buttons are pressed at all
      // it is to prevent a tooltip conflict when the user is dragging over a booking
      this.tooltipEvent.emit(
        new AddTooltipEvent({
          ...this.tooltip,
          targetRect: {
            width: SchedulerSetting.mouseSize.width,
            height: SchedulerSetting.mouseSize.height,
            left: event.pageX,
            top: event.pageY
          },
          content: {
            booking: this.localBooking,
            currentUserId: this.currentUser?.remoteId,
            tooltipParentId: this.localBooking.internalId
          }
        })
      );
    }
  }

  onContentSimpleClick(event: MouseEvent): void {
    // if this in the option chosen, we emit or delete the tooltip
    if (
      !this.tooltipAlreadyOpened() &&
      tooltipSettings.creation === TooltipCreationType.MOUSE_CLICK &&
      event.buttons === 0
    ) {
      this.tooltipEvent.emit(
        new AddTooltipEvent({
          ...this.tooltip,
          targetRect: {
            width: SchedulerSetting.mouseSize.width,
            height: SchedulerSetting.mouseSize.height,
            left: event.pageX,
            top: event.pageY
          },
          content: {
            booking: {
              ...this.localBooking
            },
            userDisplayName: this.localBooking?.data?.user?.displayName,
            userId: this.localBooking?.data?.user?.remoteId,
            userImageUrl: this.localBooking?.data?.user?.imageUrl,
            userName: this.localBooking?.data?.user?.userName,
            currentUserId: this.currentUser?.remoteId,
            tooltipParentId: this.localBooking?.internalId
          }
        })
      );
    }
  }

  onContentMouseMove(event: MouseEvent): void {
    if (tooltipSettings.followMouse && event.buttons === 0) {
      this.tooltipEvent.emit(
        new UpdateTooltipTargetRectEvent(this.tooltip.id, {
          top: event.pageY,
          left: event.pageX
        })
      );
    }
  }

  onContentMouseLeave(): void {
    if (tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER) {
      this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
    }
  }

  onContentDblClick(): void {
    this.dblClickOnContent.emit(this.localBooking);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
  }

  isBookingWideEnough(): boolean {
    return this.localBooking?.posAndSize['width.px'] > 15;
  }

  isStartDateChangeAllowed(): boolean {
    return SharedUiUtils.isStartDateChangeAllowed(
      this.localBooking?.data?.status
    );
  }

  isEndDateChangeAllowed(): boolean {
    return SharedUiUtils.isEndDateChangeAllowed(
      this.localBooking?.data?.status
    );
  }

  updateBookingStyles(): void {
    const overdueColors = SharedUiUtils.getOverdueColors();

    if (
      this.localBooking.bookingPosAndSize &&
      this.localBooking.bookingPosAndSize['width.%'] > 0
    ) {
      this.bookingContainerStyle = {
        ...this.localBooking.bookingPosAndSize,
        color: this.localBooking.bookingColors.text,
        border: '1.5px solid ' + this.localBooking.bookingColors.border,
        'border-left':
          '1.5px ' +
          (this.localBooking.bookingBeginBefore ? 'dotted ' : 'solid ') +
          this.localBooking.bookingColors.border,
        'border-right':
          '1.5px ' +
          (this.localBooking.bookingEndAfter ? 'dotted ' : 'solid ') +
          this.localBooking.bookingColors.border
      };
    } else {
      this.bookingContainerStyle = null;
    }

    if (this.localBooking.vehicleUsagePosAndSize) {
      this.vehicleUsageContainerStyle = {
        ...this.localBooking.vehicleUsagePosAndSize,
        'background-color': this.localBooking.bookingColors.background,
        'border-left':
          '1.5px ' +
          (this.localBooking.vehicleUsageBeginBefore ? 'dotted ' : 'none ') +
          this.localBooking.bookingColors.border,
        'border-right':
          '1.5px ' +
          (this.localBooking.vehicleUsageEndAfter ? 'dotted ' : 'none ') +
          this.localBooking.bookingColors.border
      };
    } else {
      this.vehicleUsageContainerStyle = null;
    }

    if (this.localBooking.activeBookingExtendedPeriodPosAndSize) {
      this.extendedPeriodContainerStyle = {
        ...this.localBooking.activeBookingExtendedPeriodPosAndSize,
        'background-color': overdueColors.background
      };
    } else {
      this.extendedPeriodContainerStyle = null;
    }

    this.handleStyle = {
      'background-color': this.localBooking.bookingColors.border,
      visibility: 'hidden'
    };
  }

  tooltipAlreadyOpened(): boolean {
    if (
      !!this.sharedTooltipEvent &&
      this.sharedTooltipEvent instanceof AddTooltipEvent &&
      this.sharedTooltipEvent.payload.tooltip.type === TooltipType.BOOKING
    ) {
      const content = this.sharedTooltipEvent.payload.tooltip
        .content as TooltipContentBooking;
      return content.booking.internalId === this.localBooking.internalId;
    }
    return false;
  }
}
