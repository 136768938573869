import { Component } from '@angular/core';
import { OrganizationRoleDto } from  '../../../../../client';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@Component({
  selector: 'sof-organization-manager-list-view',
  template: `
    <ng-container>
      <sof-organization-member-list
        [tc]="tc"
        [organization]="organizationWithDetail$ | async"
        [organizationRole]="ORGANIZATION_ROLE_MANAGER"
        noResultLabel="NO-RESULT-MANAGERS"
      ></sof-organization-member-list>
      <router-outlet></router-outlet>
    </ng-container>
  `
})
export class OrganizationManagerListViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-MANAGER-LIST';

  ORGANIZATION_ROLE_MANAGER = OrganizationRoleDto.MANAGER;

  organizationWithDetail$ = this.organizationViewHelper.organizationWithDetail$;

  constructor(private organizationViewHelper: OrganizationViewHelper) {}
}
