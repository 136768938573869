import { Pipe, PipeTransform } from '@angular/core';
import AvatarUtils from '../../utils/avatar.utils';

@Pipe({
  name: 'avatarClass'
})
export class AvatarClassPipe implements PipeTransform {
  transform(name: string): string {
    return AvatarUtils.getAvatarClass(name);
  }
}
