import { Injectable } from '@angular/core';
import {
  AddVehiclesToGroupRequestDto,
  CreateVehicleGroupRequestDto,
  DeleteVehiclesFromGroupRequestDto,
  VehicleGroupControllerImplService,
  VehicleGroupDto,
  VehicleGroupPageDto
} from '../../../client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleGroupService {
  constructor(
    private vehicleGroupControllerImplService: VehicleGroupControllerImplService
  ) {}

  getVehicleGroups(organizationId?: string): Observable<VehicleGroupPageDto> {
    return this.vehicleGroupControllerImplService.getVehicleGroups(
      organizationId
    );
  }

  getVehicleGroup(vehicleGroupId: string): Observable<VehicleGroupDto> {
    return this.vehicleGroupControllerImplService.getVehicleGroup(
      vehicleGroupId
    );
  }

  createVehicleGroup(
    createVehicleGroupRequestDto?: CreateVehicleGroupRequestDto
  ): Observable<VehicleGroupDto> {
    return this.vehicleGroupControllerImplService.createVehicleGroup(
      createVehicleGroupRequestDto
    );
  }

  deleteVehicleGroup(vehicleGroupId: string): Observable<VehicleGroupDto> {
    return this.vehicleGroupControllerImplService.deleteVehicleGroup(
      vehicleGroupId
    );
  }

  addVehicleToVehicleGroup(
    vehicleGroupId: string,
    vehicleId: string
  ): Observable<any> {
    return this.vehicleGroupControllerImplService.addVehicleToVehicleGroup(
      vehicleGroupId,
      vehicleId
    );
  }

  addVehicleToVehiclesGroup(
    vehicleGroupId: string,
    addVehiclesToGroupRequestDto: AddVehiclesToGroupRequestDto
  ): Observable<any> {
    return this.vehicleGroupControllerImplService.addVehicleToVehiclesGroup(
      vehicleGroupId,
      addVehiclesToGroupRequestDto
    );
  }

  deleteVehicleFromVehicleGroup(
    vehicleGroupId: string,
    vehicleId: string
  ): Observable<any> {
    return this.vehicleGroupControllerImplService.deleteVehicleFromVehicleGroup(
      vehicleGroupId,
      vehicleId
    );
  }

  deleteVehiclesFromVehicleGroup(
    vehicleGroupId: string,
    deleteVehiclesFromGroupRequestDto: DeleteVehiclesFromGroupRequestDto
  ): Observable<any> {
    return this.vehicleGroupControllerImplService.deleteVehiclesFromVehicleGroup(
      vehicleGroupId,
      deleteVehiclesFromGroupRequestDto
    );
  }
}
