import { Pipe, PipeTransform } from '@angular/core';
import { UserReferenceDto } from '../../../../client';

@Pipe({ name: 'usersToDisplayNames' })
export class UsersToDisplayNamesPipe implements PipeTransform {
  transform(users: Array<UserReferenceDto>): string {
    if (!users || users.length === 0) {
      return null;
    }
    return users.map(user => user.displayName).join(', ');
  }
}
