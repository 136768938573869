import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent, interval, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  withLatestFrom
} from 'rxjs/operators';
import { Changes, takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { Duration } from '../../../classes/duration.class';
import { Tooltip, TooltipType } from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { TooltipEvent } from '../../../classes/tooltip-events.class';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';

@UntilDestroy()
@Component({
  selector: 'sof-booking-scheduler-body-scrollbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div #content>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./booking-scheduler-body-scrollbox.component.scss']
})
export class BookingSchedulerBodyScrollboxComponent
  implements OnChanges, OnInit {
  @Input() msPerPixel: number;
  @Input() dateFrom: Date;
  @Input() smallestTimeUnit: Duration;
  @Input() applyScrollLeft: number;
  @Input() applyScrollTop: number;
  @Input() isDraggingBooking: boolean;

  @Output() scrollLeft: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();

  @Changes('applyScrollLeft') applyScrollLeft$: Observable<number>;
  @Changes('applyScrollTop') applyScrollTop$: Observable<number>;
  @Changes('isDraggingBooking') isDraggingBooking$: Observable<boolean>;

  @ViewChild('content') content: ElementRef;

  // ----------- toolitp content ----------
  tooltip: Tooltip = {
    id: TooltipType.DATE.toString(),
    type: TooltipType.DATE,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      date: new Date()
    }
  };

  // ----- scroll -----

  // this stream emit when the user is scrolling.
  scrollEvent$: Observable<{ scrollLeft: number; scrollTop: number }>;

  // this stream contains the current mouse position, related to the scrollbox
  mousePosition$: Observable<{
    top: number;
    left: number;
    bottom: number;
    right: number;
  }> = fromEvent(this.element.nativeElement, 'mousemove').pipe(
    map((event: MouseEvent) => {
      const left = event.pageX - this.element.nativeElement.offsetLeft;
      const top = event.pageY - this.element.nativeElement.offsetTop;
      const bottom = this.element.nativeElement.clientHeight - top;
      const right = this.element.nativeElement.clientWidth - left;
      return {
        left,
        top,
        bottom,
        right
      };
    })
  );

  // ----- auto scroll management -----
  autoScrollFrequencyMs = 50;
  autoScrollStepPx = 12;
  //    -> at each x ms, the scroll increments/decrements y px
  autoScrollAreaWidthPx = 10; // the distance is from the borders

  // this stream emits frequently only when the mouse is close to a border
  // it contains 4 flags, to tell the border concerned
  mouseOnBorder$: Observable<{
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
  }> = interval(this.autoScrollFrequencyMs).pipe(
    withLatestFrom(this.mousePosition$),
    map(([counter, mousePosition]) => ({
      left:
        0 < mousePosition.left &&
        mousePosition.left < this.autoScrollAreaWidthPx,
      right:
        0 < mousePosition.right &&
        mousePosition.right < this.autoScrollAreaWidthPx,
      top:
        0 < mousePosition.top && mousePosition.top < this.autoScrollAreaWidthPx,
      bottom:
        0 < mousePosition.bottom &&
        mousePosition.bottom < this.autoScrollAreaWidthPx
    })),
    filter(
      borders => borders.left || borders.right || borders.top || borders.bottom
    )
  );

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    // ------ tooltip management -------
    /*
    // when the mouse enter the content, we create the tooltip
    fromEvent(this.content.nativeElement, 'mouseenter')
      .pipe(takeUntilDestroy(this))
      .subscribe((event: MouseEvent) => {
        this.tooltipEvent.emit(
          new AddTooltipEvent({
            ...this.tooltip,
            targetRect: {
              width: SchedulerSetting.mouseSize.width,
              height: SchedulerSetting.mouseSize.height,
              top: event.pageY,
              left: event.pageX
            },
            content: {
              date: this.getDateFromPageX(event.pageX)
            }
          })
        );
      });

    // when the mouse leave the content, we delete the tooltip
    fromEvent(this.content.nativeElement, 'mouseleave')
      .pipe(takeUntilDestroy(this))
      .subscribe((event: MouseEvent) => {
        this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
      });

    // when the mouse move over the content, we update its position and its content
    fromEvent(this.content.nativeElement, 'mousemove')
      .pipe(takeUntilDestroy(this))
      .subscribe((event: MouseEvent) => {
        // update of the position
        this.tooltipEvent.emit(
          new UpdateTooltipTargetRectEvent(this.tooltip.id, {
            top: event.pageY,
            left: event.pageX
          })
        );
        // update of the content
        this.tooltipEvent.emit(
          new UpdateTooltipContentValuesEvent(this.tooltip.id, {
            date: this.getDateFromPageX(event.pageX)
          })
        );
      });
*/

    // when the mouse is on a border, we increment/decrement the
    // scroll state according to the border
    // only if the the user is dragging a booking
    this.mouseOnBorder$
      .pipe(
        withLatestFrom(this.isDraggingBooking$),
        filter(([mouseOnBorder, isDraggingBooking]) => isDraggingBooking),
        takeUntilDestroy(this)
      )
      .subscribe(([mouseOnBorder, isDraggingBooking]) => {
        const maxScrollTop =
          this.element.nativeElement.scrollHeight -
          this.element.nativeElement.clientHeight;

        const maxScrollLeft =
          this.element.nativeElement.scrollWidth -
          this.element.nativeElement.clientWidth;

        if (mouseOnBorder.left) {
          const valueUpdated = this.applyScrollLeft - this.autoScrollStepPx;
          this.scrollLeft.emit(valueUpdated < 0 ? 0 : valueUpdated);
        } else if (mouseOnBorder.top) {
          const valueUpdated = this.applyScrollTop - this.autoScrollStepPx;
          this.scrollTop.emit(valueUpdated < 0 ? 0 : valueUpdated);
        } else if (mouseOnBorder.right) {
          const valueUpdated = this.applyScrollLeft + this.autoScrollStepPx;
          this.scrollLeft.emit(
            valueUpdated > maxScrollLeft ? maxScrollLeft : valueUpdated
          );
        } else if (mouseOnBorder.bottom) {
          const valueUpdated = this.applyScrollTop + this.autoScrollStepPx;
          this.scrollTop.emit(
            valueUpdated > maxScrollTop ? maxScrollTop : valueUpdated
          );
        }
      });

    // ----- scroll -----

    // apply input scroll values
    this.applyScrollLeft$
      .pipe(takeUntilDestroy(this))
      .subscribe(
        scrollLeft => (this.element.nativeElement.scrollLeft = scrollLeft)
      );
    this.applyScrollTop$
      .pipe(takeUntilDestroy(this))
      .subscribe(
        scrollTop => (this.element.nativeElement.scrollTop = scrollTop)
      );

    // creation of the stream
    this.scrollEvent$ = fromEvent(this.element.nativeElement, 'scroll').pipe(
      map((event: any) => ({
        scrollLeft: event.target.scrollLeft,
        scrollTop: event.target.scrollTop
      }))
    );
    // scroll Left emission
    this.scrollEvent$
      .pipe(
        map(value => value.scrollLeft),
        distinctUntilChanged(),
        takeUntilDestroy(this)
      )
      .subscribe(value => {
        this.scrollLeft.emit(value);
      });
    // scroll Top emission
    this.scrollEvent$
      .pipe(
        map(value => value.scrollTop),
        takeUntilDestroy(this)
      )
      .subscribe(value => this.scrollTop.emit(value));
  }

  // this method return the date on the calendar corresponding to the absolute x position given
  // the position to give is related to the window
  getDateFromPageX(pageX: number): Date {
    return SchedulerDateUtils.roundDate(
      new Date(
        this.dateFrom.valueOf() +
          (pageX - this.content.nativeElement.getBoundingClientRect().left) *
            this.msPerPixel
      ),
      this.smallestTimeUnit
    );
  }

  ngOnChanges(): void {}
}
