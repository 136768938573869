import { Duration } from '../../classes/duration.class';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BookingSchedulerDate } from '../../types/booking-sheduler-date.type';
import { Period } from '../../types/period.type';
import { ViewMode } from '../../types/view-mode.type';

const moment = extendMoment(Moment);

export class SchedulerDateUtils {
  // to always handle an even hour (when the dateQuantum is 2 hours)
  // it is necessary to round only the period from last midnight (because it is possible to have an odd number of hours
  // winter/summer time switch
  // To know :  because it's calculated from the begining of the day, the dateQuantum can't be greater than one day
  // so if the smallest drag period is set to a value grater than a day, it will be necessaray to change that.
  static roundDate(date: Date, dateQuantum: Duration): Date {
    const todayMs = moment(date).startOf('day').valueOf();
    const msAfterToday = date.valueOf() - todayMs;
    return new Date(
      todayMs + msAfterToday - (msAfterToday % dateQuantum.getMs())
    );
  }

  static isToday(date: Date): boolean {
    return moment(date).isSame(new Date(), 'd');
  }

  static isWeekend(date: Date): boolean {
    return moment(date).day() === 0 || moment(date).day() === 6;
  }

  // Add or remove 1h if there a time change
  static getTimeInMillisDSTClean(date: Date, schedulerDateFrom: Date): number {
    const isDST = moment(date).isDST();
    const isDSTScheduler = moment(schedulerDateFrom).isDST();
    if (isDST === isDSTScheduler) {
      return date.valueOf();
    }
    if (isDSTScheduler) {
      return date.valueOf() - 3600000;
    }
    return date.valueOf() + 3600000;
  }

  // Add or remove 1h if there a time change
  static getDateDSTClean(date: Date, durationMs: number): Date {
    const d = moment(date).add(durationMs, 'ms').toDate();
    const isDST = moment(d).isDST();
    const isDSTScheduler = moment(date).isDST();
    if (isDST === isDSTScheduler) {
      return d;
    }
    if (isDSTScheduler) {
      return moment(d).add(1, 'h').toDate();
    }
    return moment(d).subtract(1, 'h').toDate();
  }

  private static toUtcNoOffsetString(date: Date): string {
    const month = date.getMonth() + 1;
    const monthStr = (month < 10 ? '0' : '') + month;
    const day = date.getDate();
    const dayStr = (day < 10 ? '0' : '') + day;
    return (
      date.getFullYear() + '-' + monthStr + '-' + dayStr + 'T00:00:00+0000'
    );
  }

  static getSubDurationsFromDate(
    dateFrom: Date,
    duration: Duration,
    subDuration: Duration,
    dateFormat: string
  ): BookingSchedulerDate[] {
    const subDurations: BookingSchedulerDate[] = [];
    let delta = 0;
    // Use date with no offset for the calculation and use moment to format the date
    // Prevents Daylight Saving Time issues
    const utcNoOffset = this.toUtcNoOffsetString(dateFrom);
    while (delta < duration.getMs()) {
      const date = moment(dateFrom).add(delta, 'ms').toDate();
      const dateMoment = moment(utcNoOffset).utc(false).add(delta, 'ms');
      subDurations.push({
        moment: dateMoment,
        date,
        formattedDate: dateFormat === null ? '' : dateMoment.format(dateFormat),
        isToday: dateMoment.isSame(moment(), 'd'),
        isWeekend: dateMoment.day() === 0 || dateMoment.day() === 6
      });
      delta += subDuration.getMs();
    }
    return subDurations;
  }

  static getPeriodFromDateAndViewMode(
    dateFrom: Date,
    viewMode: ViewMode
  ): Period {
    return this.getSubDurationsFromDate(
      moment(dateFrom).startOf(viewMode.displayFromTheBeginingOf).toDate(), // Calculate the start date based on the viewMode
      viewMode.duration,
      viewMode.calendar.subColumn.duration,
      viewMode.calendar.subColumn.date_format
    ).reduce(
      (obj, item) => {
        if (!obj.start) {
          obj.start = item.date;
        }
        obj.end = item.date;
        return obj;
      },
      { start: null, end: null }
    );
  }
}
