import { Injectable } from '@angular/core';
import {
  AvailabilityControllerImplService,
  AvailabilityEventsPerVehiclePageDto,
  SearchAvailabilityEventsRequestDto,
  CreateAvailabilityRequestDto,
  AvailabilityDto,
  RecurringAvailabilityDto,
  UpdateAvailabilityRequestDto,
  AvailabilityTypeDto,
  RecurringTypeDto,
  TimeZoneDto,
  ConflictResolutionDto,
  CrudAvailabilityResponseDto
} from  '../../../client';
import { Observable } from 'rxjs';
import { DateUtil } from '../helpers/date-util';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  constructor(
    private availabilityControllerImplService: AvailabilityControllerImplService
  ) {}

  getAvailabilityEvents(
    vehicleIds: Array<string>,
    fromDate: Date,
    toDate: Date,
    organizationId: string,
    userId: string
  ): Observable<AvailabilityEventsPerVehiclePageDto> {
    const period = DateUtil.convertToSearchPeriod(fromDate, toDate);
    const request: SearchAvailabilityEventsRequestDto = {
      period,
      vehicleIds,
      organizationId,
      userId
    };
    return this.availabilityControllerImplService.getAvailabilityEvents(
      request
    );
  }

  createAvailability(
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createRecurringSchedule: boolean,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<AvailabilityDto> {
    const createAvailabilityRequest: CreateAvailabilityRequestDto = {
      period: DateUtil.convertToPeriod(fromDate, toDate),
      vehicleId,
      type
    };

    if (createRecurringSchedule) {
      createAvailabilityRequest.recurringSchedule = {
        daysOfWeek,
        endDate: DateUtil.convertToString(endDate),
        interval,
        recurringType,
        timeZone: TimeZoneDto.BRUSSELS
      };
    }

    return this.availabilityControllerImplService.createAvailability(
      createAvailabilityRequest
    );
  }

  createAvailabilities(
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<CrudAvailabilityResponseDto> {
    const createAvailabilityRequest: CreateAvailabilityRequestDto = {
      period: DateUtil.convertToUnavailabilityPeriod(
        fromDate,
        toDate,
        allDayEvent
      ),
      vehicleId,
      type,
      conflictResolutionDto
    };

    if (createRecurringSchedule) {
      createAvailabilityRequest.recurringSchedule = {
        daysOfWeek,
        endDate: DateUtil.convertToString(endDate),
        interval,
        recurringType,
        timeZone: TimeZoneDto.BRUSSELS
      };
    }

    return this.availabilityControllerImplService.createAvailabilities(
      createAvailabilityRequest
    );
  }

  updateAvailability(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    updateRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<AvailabilityDto> {
    const updateAvailabilityRequest: UpdateAvailabilityRequestDto = {
      availabilityId,
      period: DateUtil.convertToPeriod(fromDate, toDate),
      removeRecurringSchedule
    };

    if (updateRecurringSchedule) {
      updateAvailabilityRequest.recurringSchedule = {
        daysOfWeek,
        endDate: DateUtil.convertToString(endDate),
        interval,
        recurringType,
        timeZone: TimeZoneDto.BRUSSELS
      };
    }

    return this.availabilityControllerImplService.updateAvailability(
      availabilityId,
      updateAvailabilityRequest
    );
  }

  updateAvailabilities(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    updateRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): Observable<CrudAvailabilityResponseDto> {
    const updateAvailabilityRequest: UpdateAvailabilityRequestDto = {
      availabilityId,
      period: DateUtil.convertToUnavailabilityPeriod(
        fromDate,
        toDate,
        allDayEvent
      ),
      removeRecurringSchedule,
      conflictResolutionDto
    };

    if (updateRecurringSchedule) {
      updateAvailabilityRequest.recurringSchedule = {
        daysOfWeek,
        endDate: DateUtil.convertToString(endDate),
        interval,
        recurringType,
        timeZone: TimeZoneDto.BRUSSELS
      };
    }

    return this.availabilityControllerImplService.updateAvailabilities(
      availabilityId,
      updateAvailabilityRequest
    );
  }

  getRecurringAvailability(
    recurringAvailabilityId: string
  ): Observable<RecurringAvailabilityDto> {
    return this.availabilityControllerImplService.getRecurringAvailability(
      recurringAvailabilityId
    );
  }

  removeAvailability(
    availabilityId: string,
    removeRecurringSchedule?: boolean
  ): Observable<any> {
    return this.availabilityControllerImplService.removeAvailability(
      availabilityId,
      removeRecurringSchedule
    );
  }
}
