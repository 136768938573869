import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Intent } from '../../../types/intent.type';
import { IntentChange } from '../../../types/intent-change.type';

@Component({
  selector: 'sof-intent-button-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-intent-button
      *ngFor="let intent of intents"
      [value]="intent.enumId"
      [label]="intent.label"
      [svgIcon]="intent.svgIcon"
      [disabled]="disabled"
      [selected]="isSelected(intent.enumId)"
      [smallButton]="smallButtons"
      (selectedChange)="onIntentChange($event)"
    ></sof-intent-button>
  `,
  styleUrls: ['./intent-button-group.component.scss']
})
export class IntentButtonGroupComponent {
  @Input() disabled: boolean;
  @Input() intents: Array<Intent>;
  @Input() values: Array<string>;
  @Input() smallButtons: boolean;
  @Output() valuesChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  onIntentChange(intentChange: IntentChange): void {
    if (!this.values) {
      this.values = new Array<string>();
    }
    if (intentChange.selected) {
      this.values = [...this.values, intentChange.value];
    } else {
      this.values = this.values.filter(value => value !== intentChange.value);
    }
    this.valuesChange.emit(this.values);
  }

  isSelected(value: string): boolean {
    return value && this.values && this.values.indexOf(value) !== -1;
  }
}
