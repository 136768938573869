import { Component, ViewChild } from '@angular/core';
import { InputTextComponent } from '@sofico-framework/ui-kit/components/input-text';
import { InviteMemberDialogViewHelper } from '../../view-helpers/invite-member-dialog-view.helper';

@Component({
  selector: 'sof-invite-member-dialog-email-address-step-view',
  template: `
    <sof-form
      [tc]="tc"
      [formGroup]="inviteMemberDialogViewHelper.emailFormGroup"
    >
      <sof-input-wrapper [label]="tc + '.EMAIL-ADDRESS' | translate">
        <sof-input-text
          sofFocus
          [sofInput]
          [formControl]="
            inviteMemberDialogViewHelper.emailFormGroup?.controls?.email
          "
        >
        </sof-input-text>
      </sof-input-wrapper>
    </sof-form>
  `
})
export class InviteMemberDialogEmailAddressStepViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_INVITE-MEMBER-DIALOG';

  @ViewChild('emailAddress') emailAddress: InputTextComponent;

  constructor(
    public inviteMemberDialogViewHelper: InviteMemberDialogViewHelper
  ) {}
}
