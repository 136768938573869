import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges
} from '@angular/core';
import { BookingUserDto } from '../../../../../../client';
import { VehicleListRow } from '../../../../types/vehicle-list-row.type';
import { SchedulerSetting } from '../../../../scheduler.setting';
import { Observable, fromEvent } from 'rxjs';
import { Changes } from 'ngx-reactivetoolkit';
import { TooltipEvent } from '../../../../classes/tooltip-events.class';
import { Vehicle } from '../../../../types/vehicle.type';

@Component({
  selector: 'sof-vehicle-card-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-vehicle-card-list-header
      [ngClass]="{ 'hide-header': hideHeader }"
      [numberOfVehicles]="vehicleListRows?.length"
      [vehicleFieldsAndLabels]="vehicleFieldsAndLabels"
      [style.height.px]="headerHeightPx"
    >
    </sof-vehicle-card-list-header>

    <div #scrollbox class="scrollbox" [class.hideXScrollBar]="hideXScrollBar">
      <sof-vehicle-card-list-body
        [tc]="tc"
        [vehicleListRows]="vehicleListRows"
        [rowsHeightStyle]="rowsHeightStyle"
        [sharedTooltipEvent]="sharedTooltipEvent"
        [currentUser]="currentUser"
        (tooltipEvent)="tooltipEvent.emit($event)"
        (highlightedVehicle)="highlightedVehicle.emit($event)"
        (newRowsHeightPx)="newRowsHeightPx.emit($event)"
        (dblClickPoolVehicleEvent)="dblClickPoolVehicleEvent.emit($event)"
      >
      </sof-vehicle-card-list-body>
    </div>
  `,
  styleUrls: ['./vehicle-card-list.component.scss']
})
export class VehicleCardListComponent implements AfterViewInit, OnChanges {
  @Input() tc: string;
  @Input() vehicleListRows: VehicleListRow[];
  @Input() applyScrollLeft: number;
  @Input() applyScrollTop: number;
  @Input()
  rowsHeightStyle: {
    [key: string]: { 'minHeight.px': number; 'height.px': number };
  };
  @Input()
  vehicleFieldsAndLabels: {
    poolVehicle: { field: string; label: string }[];
  };
  @Input() hideXScrollBar = false;
  @Input() hideHeader = false;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output() scrollLeft: EventEmitter<number> = new EventEmitter<number>();
  @Output() scrollTop: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  highlightedVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output()
  newRowsHeightPx: EventEmitter<{ [key: string]: number }> = new EventEmitter<{
    [key: string]: number;
  }>();
  @Output()
  dblClickPoolVehicleEvent: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();

  @Changes('applyScrollLeft') applyScrollLeft$: Observable<number>;
  @Changes('applyScrollTop') applyScrollTop$: Observable<number>;

  @ViewChild('scrollbox') scrollBox: ElementRef;

  headerHeightPx = SchedulerSetting.schedulerHeightPx;

  ngAfterViewInit(): void {
    // -- scroll management --

    // we apply the input scroll state
    this.applyScrollLeft$.subscribe(
      scrollLeft => (this.scrollBox.nativeElement.scrollLeft = scrollLeft)
    );
    this.applyScrollTop$.subscribe(
      scrollTop => (this.scrollBox.nativeElement.scrollTop = scrollTop)
    );

    // emission of new scroll state
    fromEvent(this.scrollBox.nativeElement, 'scroll').subscribe(
      (event: any) => {
        const scrollLeft = event.target.scrollLeft;
        const scrollTop = event.target.scrollTop;
        if (scrollLeft > 0) {
          this.scrollLeft.emit(scrollLeft);
        }
        if (scrollTop > 0) {
          this.scrollTop.emit(scrollTop);
        }
      }
    );
  }

  ngOnChanges(): void {}
}
