import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipContentDrag } from '../../../../types/tooltip.type';
import { SchedulerSetting } from '../../../../scheduler.setting';

@Component({
  selector: 'sof-tooltip-content-drag',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- from date -->
    from :
    <span class="date">
      <span class="day">
        {{ content.fromDate | date: dayFormat }}
      </span>

      <span class="hour">
        {{ content.fromDate | date: hourFormat }}
      </span>
    </span>
    <br />
    <!-- to date -->
    to :
    <span class="date">
      <span class="day">
        {{ content.toDate | date: dayFormat }}
      </span>

      <span class="hour">
        {{ content.toDate | date: hourFormat }}
      </span>
    </span>
  `,
  styleUrls: ['./tooltip-content-drag.component.scss']
})
export class TooltipContentDragComponent {
  @Input() content: TooltipContentDrag;

  dayFormat: string = SchedulerSetting.dateFormat.day;
  hourFormat: string = SchedulerSetting.dateFormat.hour;
}
