import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  HostBinding,
  OnInit,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  combineLatest,
  Observable,
  BehaviorSubject,
  interval,
  Subject
} from 'rxjs';
import {
  map,
  distinctUntilChanged,
  debounceTime,
  withLatestFrom,
  startWith,
  filter,
  tap
} from 'rxjs/operators';
import { Changes, takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { AppConstants } from '../../../app.constants';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import { ViewMode } from '../../../types/view-mode.type';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BookingRequest } from '../../../types/booking-request.type';
import { Booking } from '../../../types/booking.type';
import { BookingUpdate } from '../../../types/booking-update.type';
import { BookingWithStyle } from '../../../types/booking-with-style.type';
import { UnavailabilityEventWithStyle } from '../../../types/unavailability-event-with-style.type';
import { SchedulerSetting } from '../../../scheduler.setting';
import { BookingRequestWithStyle } from '../../../types/booking-request-with-style.type';
import {
  createBookingRequestWithStyle,
  isBookingRequestMatchingWithVehicle
} from '../../utils/booking-request.utils';
import { VehicleListRow } from '../../../types/vehicle-list-row.type';
import { BookingSchedulerRowWithStyle } from '../../../types/booking-scheduler-row-with-style.type';
import { BookingSchedulerRow } from '../../../types/booking-scheduler-row.type';
import { UnavailabilityEvent } from '../../../types/unavailability-event.type';
import { BookingAdd } from '../../../types/booking-add.type';
import { Vehicle } from '../../../types/vehicle.type';
import { AvailabilityEventWithStyle } from '../../../types/availability-event-with-style.type';
import { AvailabilityEvent } from '../../../types/availability-event.type';
import {
  BookingStatusDto,
  BookingUserDto
} from  '../../../../../client';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { VehicleMarker } from '../../../types/vehicle-marker.type';
import { Intent } from '../../../types/intent.type';
import { Tooltip, TooltipType } from '../../../types/tooltip.type';
import { ApplyActiveSchedulerFilters } from '../../../types/apply-active-scheduler-filters.type';
import { getBestTooltipPos } from '../../utils/tooltip.utils';
import { Pos } from '../../../types/pos.type';
import { Size } from '../../../types/size.type';
import { Rect } from '../../../types/rect.type';
import {
  sort,
  SortingOrderConfig,
  sortList,
  WindowRefService
} from '@sofico-framework/utils';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';
import { EventUtils } from '../../utils/event.utils';
import { BookingUtils } from '../../utils/booking.utils';
import {
  AddTooltipEvent,
  TooltipEvent
} from '../../../classes/tooltip-events.class';
import { Period } from '../../../types/period.type';
import { VehicleAvailabilityUtils } from '../../utils/vehicle-availability.utils';
import { RelativePos } from '../../../types/relative-pos.type';

const moment = extendMoment(Moment);

@UntilDestroy()
@Component({
  selector: 'sof-cards-and-schedulers',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cards-and-schedulers.component.scss'],
  template: `
    <as-split direction="horizontal" class="splitter">
      <as-split-area [size]="20">
        <div
          class="card-list-top"
          [style.height.px]="headerTopHeightPx"
          (click)="togglePendingRequests$.next(!togglePendingRequests$.value)"
        >
          <div
            class="header-booking-requests"
            [ngClass]="{
              'toggle-pending-requests': togglePendingRequests$ | async
            }"
          >
            <span class="number">{{ bookingRequests?.length }}</span> pending
            requests
          </div>
          <div
            class="header-vehicles"
            [ngClass]="{
              'toggle-pending-requests': togglePendingRequests$ | async
            }"
          >
            <span class="number">{{ (vehicleListRows$ | async)?.length }}</span>
            vehicles
          </div>
        </div>

        <sof-requested-vehicles-list
          [ngClass]="{
            'toggle-pending-requests': togglePendingRequests$ | async
          }"
          [bookingRequests]="bookingRequestsWithStyle$ | async"
          [bookingRequestSelectedId]="bookingRequestSelectedId"
          [bookingRequestHoveredId]="bookingRequestHoveredId"
          [scrollTop]="pendingRequestScrollTop$ | async"
          (newScrollTop)="pendingRequestScrollTop$.next($event)"
          (newBookingRequestSelected)="newBookingRequestSelected.emit($event)"
          (newBookingRequestHoveredId)="bookingRequestHoveredId = $event"
        >
        </sof-requested-vehicles-list>

        <sof-vehicle-card-list
          [ngClass]="{
            'toggle-pending-requests': togglePendingRequests$ | async
          }"
          [tc]="tc"
          [hideHeader]="togglePendingRequests$ | async"
          [vehicleListRows]="vehicleListRows$ | async"
          [applyScrollLeft]="schedulersScrollLeft$ | async"
          [applyScrollTop]="vehicleListAndBookingSchedulerScrollTop$ | async"
          [rowsHeightStyle]="rowsHeightStyle$ | async"
          [sharedTooltipEvent]="sharedTooltipEvent"
          [currentUser]="currentUser"
          (newRowsHeightPx)="vehicleListRowsHeightPx$.next($event)"
          (highlightedVehicle)="highlightedVehicle$.next($event)"
          (scrollTop)="vehicleListAndBookingSchedulerScrollTop$.next($event)"
          [vehicleFieldsAndLabels]="vehicleFieldsAndLabels"
          [hideXScrollBar]="hideXScrollBar"
          (tooltipEvent)="tooltipEvent.emit($event)"
          (dblClickPoolVehicleEvent)="dblClickPoolVehicleEvent.emit($event)"
        >
        </sof-vehicle-card-list>
      </as-split-area>

      <as-split-area [size]="80">
        <sof-booking-scheduler-header
          [columns]="columns$ | async"
          [subColumns]="subColumns$ | async"
          [style.width.px]="periodWidthPx$ | async"
          [style.marginLeft.px]="-(schedulersScrollLeft$ | async)"
          (tooltipEvent)="tooltipEvent.emit($event)"
        >
        </sof-booking-scheduler-header>

        <div
          class="booking-requests-container"
          [ngClass]="{
            'toggle-pending-requests': togglePendingRequests$ | async
          }"
        >
          <sof-booking-scheduler-body-scrollbox
            [msPerPixel]="msPerPixel$ | async"
            [dateFrom]="schedulerDateFrom$ | async"
            [smallestTimeUnit]="viewMode?.smallestDragUnit"
            [applyScrollLeft]="schedulersScrollLeft$ | async"
            [applyScrollTop]="pendingRequestScrollTop$ | async"
            [isDraggingBooking]="false"
            (scrollLeft)="schedulersScrollLeft$.next($event)"
            (scrollTop)="pendingRequestScrollTop$.next($event)"
          >
            <sof-booking-requests-scheduler
              [bookingRequestsWithStyle]="bookingRequestsWithStyle$ | async"
              [cells]="cells$ | async"
              [style.width.px]="periodWidthPx$ | async"
              [bookingRequestHoveredId]="bookingRequestHoveredId"
              (newBookingRequestHoveredId)="bookingRequestHoveredId = $event"
              [bookingRequestSelectedId]="bookingRequestSelectedId"
              (newBookingRequestSelected)="
                newBookingRequestSelected.emit($event)
              "
            >
            </sof-booking-requests-scheduler>
          </sof-booking-scheduler-body-scrollbox>
        </div>

        <!-- use hidden-container instead if ngIf because of the subscriptions -->
        <div
          class="booking-scheduler-container"
          [ngClass]="{
            'toggle-pending-requests': togglePendingRequests$ | async,
            'hidden-container': showMap
          }"
        >
          <div
            class="header"
            [style.height.px]="headerHeightPx"
            [ngClass]="{
              'toggle-pending-requests': togglePendingRequests$ | async
            }"
          ></div>
          <sof-booking-scheduler-body-scrollbox
            [class.hideXScrollBar]="hideXScrollBar"
            [msPerPixel]="msPerPixel$ | async"
            [dateFrom]="schedulerDateFrom$ | async"
            [smallestTimeUnit]="viewMode?.smallestDragUnit"
            [applyScrollLeft]="schedulersScrollLeft$ | async"
            [applyScrollTop]="vehicleListAndBookingSchedulerScrollTop$ | async"
            [isDraggingBooking]="isDraggingBooking$ | async"
            (scrollLeft)="schedulersScrollLeft$.next($event)"
            (scrollTop)="vehicleListAndBookingSchedulerScrollTop$.next($event)"
            (tooltipEvent)="tooltipEvent.emit($event)"
          >
            <sof-booking-scheduler-body
              [cells]="cells$ | async"
              [rowsOfBookingsWithStyle]="rowsOfBookingsWithStyle$ | async"
              [style.width.px]="periodWidthPx$ | async"
              [schedulerDateFrom]="schedulerDateFrom$ | async"
              [schedulerDateTo]="schedulerDateTo$ | async"
              [msPerPixel]="msPerPixel$ | async"
              [smallestDragUnit]="viewMode?.smallestDragUnit"
              [selectedVehicle]="highlightedVehicle$ | async"
              [rowsHeightStyle]="rowsHeightStyle$ | async"
              [windowKeyDown]="windowKeyDown"
              [windowKeyUp]="windowKeyUp"
              [sharedTooltipEvent]="sharedTooltipEvent"
              [currentUser]="currentUser"
              (newBooking)="newBooking.emit($event)"
              (updateBooking)="updateBooking.emit($event)"
              (dblClickOnBooking)="dblClickOnBooking.emit($event)"
              (dblClickOnUnavailability)="dblClickOnUnavailability.emit($event)"
              (dblClickOnAvailability)="dblClickOnAvailability.emit($event)"
              (tooltipEvent)="tooltipEvent.emit($event)"
              (isDraggingBooking)="isDraggingBooking$.next($event)"
            >
            </sof-booking-scheduler-body>
          </sof-booking-scheduler-body-scrollbox>
        </div>
        <!-- use hidden-container instead of ngIf because of the subscriptions -->
        <div
          class="map-scheduler-container"
          [ngClass]="{
            'toggle-pending-requests': togglePendingRequests$ | async,
            'hidden-container': !showMap
          }"
        >
          <google-map
            *ngIf="internalGoogleApiLoaded"
            [options]="options"
            width="100%"
            height="100%"
          >
            <map-marker
              #markerElem="mapMarker"
              *ngFor="
                let marker of markersWithoutHighlightedVehicleSub$ | async;
                trackBy: trackByVehicleId
              "
              [position]="marker.position"
              [options]="marker.options"
              (mapClick)="openMarkerInfo($event, markerElem, marker)"
            >
            </map-marker>
            <map-marker
              *ngIf="highlightedMarker"
              [position]="highlightedMarker.position"
              [options]="highlightedMarker.options"
            ></map-marker>
          </google-map>
        </div>
      </as-split-area>
    </as-split>
    <sof-tooltip
      *ngFor="
        let tooltip of tooltipsToDisplay$ | async;
        trackBy: getUniqueTooltip
      "
      [style.visibility]="tooltipsPos[tooltip.id] ? 'visible' : 'hidden'"
      [style.left.px]="tooltipsPos[tooltip.id]?.left"
      [style.top.px]="tooltipsPos[tooltip.id]?.top"
      [type]="tooltip.type"
      [content]="tooltip.content"
      [tooltipId]="tooltip.id"
      [tc]="tc"
      [currentUser]="currentUser"
      (size)="onNewTooltipSize(tooltip, $event)"
      (tooltipEvent)="this.tooltipEvent.emit($event)"
      (editBooking)="this.editBooking.emit($event)"
      (applyActiveSchedulerFilters)="
        this.applyActiveSchedulerFilters.emit($event)
      "
      (editConversation)="this.editConversation.emit($event)"
      (editUnavailability)="this.editUnavailability.emit($event)"
      (editAvailability)="this.editAvailability.emit($event)"
      (editVehicle)="this.editVehicle.emit($event)"
      (refreshVehicleLocation)="refreshVehicleLocation.emit($event)"
      (addBookingForVehicle)="addBookingForVehicle.emit($event)"
      (addAvailabilityForVehicle)="addAvailabilityForVehicle.emit($event)"
      (endVehicleUsage)="endVehicleUsage.emit($event)"
    >
    </sof-tooltip>
  `
})
export class CardsAndSchedulersComponent implements OnChanges, OnInit {
  options: google.maps.MapOptions;
  @Input() viewMode: ViewMode;
  @Input() dateFrom: Date;
  @Input() showMap: boolean;
  @Input() vehicles: Vehicle[];
  @Input() bookings: Booking[];
  @Input() unavailabilities: UnavailabilityEvent[];
  @Input() availabilities: AvailabilityEvent[];
  @Input() bookingRequestHighlighted: BookingRequest;
  @Input() bookingRequests: BookingRequest[];
  @Input()
  vehicleFieldsAndLabels: {
    poolVehicle: { field: string; label: string }[];
  };

  @Input() bookingRequestSelectedId: string;
  @Input() windowKeyUp: KeyboardEvent;
  @Input() windowKeyDown: KeyboardEvent;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() tooltipsAskedToBeDisplayed: Tooltip[];
  @Input() currentUser: BookingUserDto;
  @Input() tc: string;
  @Input() set googleApiLoaded(googleApiLoaded: boolean) {
    this.internalGoogleApiLoaded = googleApiLoaded;
    if (googleApiLoaded) {
      this.options = SharedUiUtils.getDefaultMapOptions();
    }
  }

  @Output()
  newBookingRequestSelected: EventEmitter<BookingRequest> = new EventEmitter<BookingRequest>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  newBooking: EventEmitter<BookingAdd> = new EventEmitter<BookingAdd>();
  @Output()
  updateBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();
  @Output()
  dblClickOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();
  @Output()
  dblClickOnUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  dblClickOnAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output()
  dblClickPoolVehicleEvent: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output() editBooking: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output()
  applyActiveSchedulerFilters: EventEmitter<ApplyActiveSchedulerFilters> = new EventEmitter<ApplyActiveSchedulerFilters>();
  @Output()
  editConversation: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output()
  editUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  editAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output() editVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output()
  refreshVehicleLocation: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addBookingForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addAvailabilityForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  endVehicleUsage: EventEmitter<string> = new EventEmitter<string>();

  @Changes('viewMode') viewMode$: Observable<ViewMode>;
  @Changes('dateFrom') dateFrom$: Observable<Date>;
  @Changes('bookingRequests') bookingRequests$: Observable<BookingRequest[]>;
  @Changes('vehicles') vehicles$: Observable<Vehicle[]>;
  @Changes('bookings') bookings$: Observable<Booking[]>;
  @Changes('bookingRequestHighlighted')
  bookingRequestHighlighted$: Observable<BookingRequest>;
  @Changes('unavailabilities') unavailabilities$: Observable<
    UnavailabilityEvent[]
  >;
  @Changes('availabilities') availabilities$: Observable<AvailabilityEvent[]>;
  @Changes('showMap') showMap$: Observable<boolean>;
  @Changes('currentUser') currentUser$: Observable<boolean>;

  internalGoogleApiLoaded = false;

  // Tooltips management
  maxTooltipDisplayedAmount =
    SchedulerSetting.tooltipManager.maxTooltipDisplayedAmount;

  tooltipVehicle: Tooltip = {
    id: TooltipType.POOL_VEHICLE,
    type: TooltipType.POOL_VEHICLE,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      vehicle: undefined
    }
  };

  @Changes('tooltipsAskedToBeDisplayed')
  tooltipsAskedToBeDisplayed$: Observable<Tooltip[]>;

  // this stream contains only the tooltip which are displayed
  tooltipsToDisplay$: Observable<Tooltip[]>;

  // contains the position of each tooltip to display
  // if the tooltip has no position, it is displayed but not visible
  tooltipsPos: { [key: string]: Pos } = {};
  // contains the size of each tooltip to display
  tooltipSize: { [key: string]: Size } = {};

  headerCalendarHeightPx = SchedulerSetting.headerHeightPx.calendarPart;
  headerTopHeightPx = SchedulerSetting.headerHeightPx.topPart;
  hideXScrollBar = false;

  @HostBinding('style.height.px')
  elementHeight = this.headerCalendarHeightPx + this.headerTopHeightPx;

  // local state
  horizontalSplitterAreaSize$: BehaviorSubject<
    [number, number]
  > = new BehaviorSubject<[number, number]>([20, 80]);
  togglePendingRequests$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  highlightedVehicle$: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(
    null
  );

  // map markers
  @ViewChildren(GoogleMap) googleMap: QueryList<GoogleMap>;

  intents: Array<Intent>;
  selectedVehicle: Vehicle;
  showRefreshVehicleLocation: boolean;

  // ---- horizontal zoom calculation ----

  schedulerWidthPx$: Observable<number> = interval(100).pipe(
    withLatestFrom(this.horizontalSplitterAreaSize$),
    map(([i, horizontalSplitterAreaSize]) => {
      const gutterWidth = 11;
      const scrollBarWidth = 17;
      return (
        (this.element.nativeElement.getBoundingClientRect().width -
          gutterWidth) *
          (horizontalSplitterAreaSize[1] / 100) -
        scrollBarWidth
      );
    }),
    distinctUntilChanged(),
    debounceTime(100)
  );

  // stream to manage the horizontal zoom, expressed in milliseconds displayed on one pixel
  // the msPerPixel value is bounded
  msPerPixel$: Observable<number>;

  // this is the width (in pixel) of the period of time to display
  // it can be bigger than the available space
  periodWidthPx$: Observable<number>;

  // this is the first moment showed on the scheduler,
  // last monday at 00:00 if we have to display at least one week
  // dateFrom at 00:00 if we have to display less than one week
  schedulerDateFrom$: Observable<Date>;

  schedulerDateTo$: Observable<Date>;

  // streams to manage the header content of the booking scheduler
  columns$: Observable<BookingSchedulerDate[]>;

  subColumns$: Observable<BookingSchedulerDate[]>;

  // Markers

  vehicleMarkers$: Observable<VehicleMarker[]>;

  markers$: Observable<VehicleMarker[]>;

  highlightedMarker: VehicleMarker;
  markerHighlightedVehicle$: Observable<VehicleMarker>;

  markersWithoutHighlightedVehicle$: Observable<VehicleMarker[]>;
  markersWithoutHighlightedVehicleSub$: BehaviorSubject<
    VehicleMarker[]
  > = new BehaviorSubject<VehicleMarker[]>(null);

  headerHeightPx = SchedulerSetting.schedulerHeightPx;

  bookingRequestHoveredId = null;

  // sate shared between the list and the scheduler
  vehicleListAndBookingSchedulerScrollTop$: Subject<number> = new Subject<number>();
  pendingRequestScrollTop$: Subject<number> = new Subject<number>();
  schedulersScrollLeft$: Subject<number> = new Subject<number>();

  bookingRequestsWithStyle$: Observable<BookingRequestWithStyle[]>;

  cells$: Observable<BookingSchedulerDate[]>;

  // calculate bookingSchedulerRows
  // should be renamed to bookingSchedulerRowsContent
  bookingSchedulerRows$: Observable<BookingSchedulerRow[]>;

  // calculate vehicleListRow

  // should be renamed to vehicleListRowsContent
  vehicleListRows$: Observable<VehicleListRow[]>;

  vehicleListRowsHeightPx$: Subject<{ [key: string]: number }> = new Subject<{
    [key: string]: number;
  }>();

  // contains the height and min height of each rows, calculated from the height of the rows
  // in the booking scheduler, and the height of the rows in the vehicle card list
  rowsHeightStyle$: Observable<{
    [key: string]: {
      'minHeight.px': number;
      'height.px': number;
    };
  }>;

  // ----- booking scheduler body ------

  // this stream is simply like row$, but with bookings with a style attribute (a width and a height)
  // and style of the availabilities of the vehicles (date start and date end)
  rowsOfBookingsWithStyle$: Observable<BookingSchedulerRowWithStyle[]>;

  // is equal to true when the user is moving, resizing or creating a booking
  // false otherwise
  isDraggingBooking$: Subject<boolean> = new Subject<boolean>();

  sortingTooltip: SortingOrderConfig<Tooltip> = {
    prop: t => SchedulerSetting.tooltipManager.tooltipsPriority[t.type]
  };

  openMarkerInfo(event, marker: MapMarker, vehicleMarker: VehicleMarker): void {
    this.tooltipEvent.emit(
      new AddTooltipEvent({
        ...this.tooltipVehicle,
        targetRect: {
          ...SchedulerSetting.mouseSize,
          top: event.domEvent.clientY,
          left: event.domEvent.clientX
        },
        content: {
          vehicle: vehicleMarker.vehicle,
          tooltipParentId: vehicleMarker.vehicle.internalId
        }
      })
    );
  }

  // ----- booking scheduler header ------
  constructor(
    private element: ElementRef,
    private windowsRef: WindowRefService
  ) {}

  trackByVehicleId(index, marker): void {
    return marker.vehicleId;
  }

  // when a tooltip output a size, we set it, and we update its position
  onNewTooltipSize(tooltip: Tooltip, tooltipSize: Size): void {
    this.tooltipSize[tooltip.id] = tooltipSize;
    this.updateTooltipPos(tooltip);
  }

  // this method will use the local attribute tooltipSize to define
  // the position of the tooltip
  // if the size of the tooltip given is not in tooltipSize,
  // the position is not calculated
  updateTooltipPos(tooltip: Tooltip): void {
    // we measure the current window size
    const windowSize: Rect = {
      top: 0,
      left: 0,
      width: this.windowsRef.nativeWindow.innerWidth,
      height: this.windowsRef.nativeWindow.innerHeight
    };

    if (this.tooltipSize[tooltip.id]) {
      // we calculate and set the new tooltip position
      this.tooltipsPos[tooltip.id] = getBestTooltipPos(
        // if target rect is not defined, the tooltip is targeting the top left corner
        tooltip.targetRect
          ? tooltip.targetRect
          : { width: 0, left: 0, top: 0, height: 0 },
        tooltip.relativeTooltipPos,
        this.tooltipSize[tooltip.id],
        windowSize
      );
    }
  }

  getUniqueTooltip(index: number, tooltip: Tooltip): string {
    if (!!tooltip?.content?.tooltipParentId) {
      return tooltip.id + '_' + tooltip.content.tooltipParentId;
    }
    return tooltip.id;
  }

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.tooltipsToDisplay$ = this.getTooltipsToDisplay$();
    this.msPerPixel$ = this.getMsPerPixel$();
    this.periodWidthPx$ = this.getPeriodWidthPx$();
    this.schedulerDateFrom$ = this.getSchedulerDateFrom$();
    this.schedulerDateTo$ = this.getSchedulerDateTo$();
    this.columns$ = this.getColumns$();
    this.subColumns$ = this.getSubColumns$();
    this.vehicleMarkers$ = this.getVehicleMarkers$();
    this.markers$ = this.getMarkers$();
    this.markerHighlightedVehicle$ = this.getMarkerHighlightedVehicle$();
    this.markersWithoutHighlightedVehicle$ = this.getMarkersWithoutHighlightedVehicle$();
    this.bookingRequestsWithStyle$ = this.getBookingRequestsWithStyle$();
    this.cells$ = this.getCells$();
    this.bookingSchedulerRows$ = this.getBookingSchedulerRows$();
    this.vehicleListRows$ = this.getVehicleListRows$();
    this.rowsHeightStyle$ = this.getRowsHeightStyle$();
    this.rowsOfBookingsWithStyle$ = this.getRowsOfBookingsWithStyle$();

    // when the new tooltips to display are updated, we set there new positions
    this.tooltipsToDisplay$.pipe(takeUntilDestroy(this)).subscribe(tooltips => {
      // Check if the tooltip is still present, if not, delete pos and size information
      // Avoid tooltip to jump from one position to another (was happening when clicking from an unavailability to a different one,
      // previous position was used until the new one was calculated)
      if (this.tooltipsPos) {
        Object.keys(this.tooltipsPos).forEach(key => {
          const res = tooltips?.find(tooltip => tooltip.id === key);
          if (!res) {
            this.tooltipsPos[key] = null;
            this.tooltipSize[key] = null;
          }
        });
      }
      tooltips.forEach(tooltip => {
        this.updateTooltipPos(tooltip);
      });
    });

    this.bookingRequests$
      .pipe(takeUntilDestroy(this))
      .subscribe(bookingRequests => {
        if (!bookingRequests || bookingRequests.length === 0) {
          this.togglePendingRequests$.next(true);
        }
      });
    this.markerHighlightedVehicle$
      .pipe(takeUntilDestroy(this))
      .subscribe(highlightedMarker => {
        this.highlightedMarker = highlightedMarker;
      });
    // Needed because the google-map component is displayed with a *ngIf
    // It's possible the first time sof-cards-and-schedulers is rendered that google-map
    // is not present, using a BehaviorSubject instead of direct use of the Observable
    // markersWithoutHighlightedVehicle$, makes the markers visible
    this.markersWithoutHighlightedVehicle$
      .pipe(takeUntilDestroy(this))
      .subscribe(val => {
        this.markersWithoutHighlightedVehicleSub$.next(val);
      });
  }

  private getTooltipsToDisplay$(): Observable<Tooltip[]> {
    return this.tooltipsAskedToBeDisplayed$.pipe(
      sort(this.sortingTooltip),
      map(
        tooltips =>
          (tooltips == null ? [] : [...tooltips]).slice(
            tooltips.length - this.maxTooltipDisplayedAmount,
            tooltips.length
          ) // we take the n most important
      )
    );
  }

  private getMsPerPixel$(): Observable<number> {
    return combineLatest([this.viewMode$, this.schedulerWidthPx$]).pipe(
      map(([viewMode, schedulerWidthPx]) => {
        const msPerPixelCalculated =
          viewMode.duration.getMs() / schedulerWidthPx;
        return msPerPixelCalculated >= viewMode.msPerPixelMin
          ? viewMode.msPerPixelMin
          : msPerPixelCalculated;
      })
    );
  }

  private getPeriodWidthPx$(): Observable<number> {
    return combineLatest([this.viewMode$, this.msPerPixel$]).pipe(
      map(([viewMode, msPerPixel]) => viewMode.duration.getMs() / msPerPixel)
    );
  }

  private getSchedulerDateFrom$(): Observable<Date> {
    return combineLatest([this.viewMode$, this.dateFrom$]).pipe(
      map(([viewMode, dateFrom]: [ViewMode, Date]) =>
        moment(dateFrom).startOf(viewMode.displayFromTheBeginingOf).toDate()
      )
    );
  }

  private getSchedulerDateTo$(): Observable<Date> {
    return combineLatest([this.viewMode$, this.schedulerDateFrom$]).pipe(
      map(([viewMode, schedulerDateFrom]) =>
        SchedulerDateUtils.getDateDSTClean(
          schedulerDateFrom,
          viewMode.duration.getMs()
        )
      )
    );
  }

  private getColumns$(): Observable<BookingSchedulerDate[]> {
    return combineLatest([this.viewMode$, this.schedulerDateFrom$]).pipe(
      map(([viewMode, schedulerDateFrom]: [ViewMode, Date]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.column.duration,
          viewMode.calendar.column.date_format
        )
      )
    );
  }

  private getSubColumns$(): Observable<BookingSchedulerDate[]> {
    return combineLatest([this.viewMode$, this.schedulerDateFrom$]).pipe(
      map(([viewMode, schedulerDateFrom]: [ViewMode, Date]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.subColumn.duration,
          viewMode.calendar.subColumn.date_format
        )
      )
    );
  }

  private getVehicleMarkers$(): Observable<VehicleMarker[]> {
    return this.vehicles$.pipe(
      map(vehicles => {
        return vehicles
          .filter((vehicle: Vehicle) => !!vehicle?.data?.homePosition)
          .map(vehicle => {
            return {
              internalVehicleId: vehicle.internalId,
              remoteVehicleId: vehicle.remoteId,
              vehicle,
              position: {
                lat: vehicle.data.homePosition.latitude,
                lng: vehicle.data.homePosition.longitude
              },
              options: {
                icon: AppConstants.ASSETS_ICON_CAR_AVAILABLE_MARKER
              }
            } as VehicleMarker;
          });
      })
    );
  }

  private getMarkers$(): Observable<VehicleMarker[]> {
    return combineLatest([
      this.schedulerDateFrom$,
      this.schedulerDateTo$,
      this.vehicleMarkers$,
      this.bookings$,
      this.unavailabilities$,
      this.availabilities$,
      this.bookingRequestHighlighted$,
      this.showMap$
    ]).pipe(
      debounceTime(50),
      filter(
        ([
          schedulerDateFrom,
          schedulerDateTo,
          vehicleMarkers,
          bookings,
          unavailabilities,
          availabilities,
          bookingRequestHighlighted,
          showMap
        ]: [
          Date,
          Date,
          VehicleMarker[],
          Booking[],
          UnavailabilityEvent[],
          AvailabilityEvent[],
          BookingRequest,
          boolean
        ]) => showMap && !!schedulerDateFrom && !!schedulerDateTo
      ),
      map(
        ([
          schedulerDateFrom,
          schedulerDateTo,
          vehicleMarkers,
          bookings,
          unavailabilities,
          availabilities,
          bookingRequestHighlighted,
          showMap
        ]: [
          Date,
          Date,
          VehicleMarker[],
          Booking[],
          UnavailabilityEvent[],
          AvailabilityEvent[],
          BookingRequest,
          boolean
        ]) => {
          return vehicleMarkers.map(vehicleMarker => {
            let markerUrl =
              AppConstants.ASSETS_ICON_CAR_PARTIALLY_AVAILABLE_MARKER;
            if (
              this.isFullyAvailable(
                vehicleMarker.remoteVehicleId,
                bookings,
                unavailabilities
              )
            ) {
              markerUrl = AppConstants.ASSETS_ICON_CAR_AVAILABLE_MARKER;
            } else if (
              this.isFullyUnavailable(
                vehicleMarker.remoteVehicleId,
                schedulerDateFrom,
                schedulerDateTo,
                bookings,
                unavailabilities
              )
            ) {
              markerUrl = AppConstants.ASSETS_ICON_CAR_NOT_AVAILABLE_MARKER;
            }
            vehicleMarker.options.icon = markerUrl;
            return vehicleMarker;
          });
        }
      ),
      tap(vehicleMarkers => {
        const googleMap: GoogleMap = this.getGoogleMap();
        if (!!googleMap) {
          if (!vehicleMarkers || vehicleMarkers.length === 0) {
            googleMap.zoom = this.options.zoom;
            googleMap.center = this.options.center;
          } else {
            const latLngBounds = new google.maps.LatLngBounds();
            // Use our center as reference in case filters are too restrictive and there is no vehicle
            if (vehicleMarkers) {
              for (const vehicleMarker of vehicleMarkers) {
                latLngBounds.extend(vehicleMarker.position);
              }
            }
            googleMap.fitBounds(latLngBounds);
          }
        }
      })
    );
  }

  private getGoogleMap(): GoogleMap {
    return this.googleMap?.get(0);
  }

  private getMarkerHighlightedVehicle$(): Observable<any> {
    return combineLatest([this.markers$, this.highlightedVehicle$]).pipe(
      debounceTime(50),
      map(([markers, highlightedVehicle]) => {
        if (markers && highlightedVehicle) {
          const highlightedMarker = markers.find(
            marker => marker.internalVehicleId === highlightedVehicle.internalId
          );
          if (!!highlightedMarker) {
            highlightedMarker.options.zIndex = 9999999;
            highlightedMarker.options.animation = google.maps.Animation.BOUNCE;
          }
          return highlightedMarker;
        }
        return null;
      })
    );
  }

  private getMarkersWithoutHighlightedVehicle$(): Observable<VehicleMarker[]> {
    return combineLatest([this.markers$, this.highlightedVehicle$]).pipe(
      debounceTime(50),
      map(([markers, highlightedVehicle]) => {
        if (markers) {
          if (!highlightedVehicle) {
            return markers;
          }
          return markers.filter(
            marker => marker.internalVehicleId !== highlightedVehicle.internalId
          );
        }
        return null;
      })
    );
  }

  private getBookingRequestsWithStyle$(): Observable<
    BookingRequestWithStyle[]
  > {
    return combineLatest([
      this.bookingRequests$,
      this.msPerPixel$,
      this.schedulerDateFrom$,
      this.schedulerDateTo$
    ]).pipe(
      map(([bookingRequests, msPerPixel, schedulerDateFrom, schedulerDateTo]) =>
        bookingRequests
          .filter(bookingRequest =>
            EventUtils.isEventOnThisPeriod(
              bookingRequest,
              moment(schedulerDateFrom).toDate(),
              schedulerDateTo
            )
          )
          .map(bookingRequest =>
            createBookingRequestWithStyle(
              bookingRequest,
              msPerPixel,
              moment(schedulerDateFrom).toDate(),
              schedulerDateTo
            )
          )
      )
    );
  }

  private getCells$(): Observable<BookingSchedulerDate[]> {
    return combineLatest([this.viewMode$, this.schedulerDateFrom$]).pipe(
      map(([viewMode, schedulerDateFrom]: [ViewMode, Date]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.subColumn.duration,
          viewMode.calendar.subColumn.date_format
        )
      )
    );
  }

  private getBookingSchedulerRows$(): Observable<BookingSchedulerRow[]> {
    return combineLatest([
      this.vehicles$,
      this.bookings$,
      this.unavailabilities$,
      this.availabilities$,
      this.bookingRequestHighlighted$
    ]).pipe(
      withLatestFrom(this.schedulerDateFrom$, this.schedulerDateTo$),
      map(
        ([
          [
            vehicles,
            bookings,
            unavailabilities,
            availabilities,
            bookingRequest
          ],
          schedulerDateFrom,
          schedulerDateTo
        ]) =>
          vehicles.map(vehicle => {
            const vehicleRelatedBookings = bookings.filter(
              booking => booking.remoteVehicleId === vehicle.remoteId
            );
            const vehicleRelatedUnavailabilities = unavailabilities.filter(
              unavailability =>
                unavailability.remoteVehicleId === vehicle.remoteId
            );
            const vehicleRelatedAvailabilities = availabilities.filter(
              availability => availability.remoteVehicleId === vehicle.remoteId
            );
            const isBookingRequestRelevent =
              bookingRequest &&
              // the booking request does not have common periods with the vehicle related bookings
              !vehicleRelatedBookings.find(booking =>
                EventUtils.isEventsOverlap(booking, bookingRequest)
              ) &&
              isBookingRequestMatchingWithVehicle(bookingRequest, vehicle);
            return {
              internalVehicleId: vehicle.internalId,
              remoteVehicleId: vehicle.remoteId,
              subRowsOfBooking: BookingUtils.dispatchOnSubRowsBooking(
                vehicleRelatedBookings,
                schedulerDateFrom,
                schedulerDateTo
              ),
              vehicleUnavailabilities: vehicleRelatedUnavailabilities,
              vehicleAvailabilities: vehicleRelatedAvailabilities,
              vehicleAvailability: vehicle.availability,
              bookingRequestHighlighted: isBookingRequestRelevent
                ? bookingRequest
                : null
            } as BookingSchedulerRow;
          })
      )
    );
  }

  private getVehicleListRows$(): Observable<VehicleListRow[]> {
    return this.bookingSchedulerRows$.pipe(
      // NOTE cabu : because bookingSchedulerRows$ is construct on vehicles$, it always emits few ms after.
      // we don't want to emit with the last value of vehicles$ and the previous value of bookingSchedulerRows$
      // this is why we use withLatestFrom instead of combineLatest
      withLatestFrom(this.vehicles$),
      map(([bookingSchedulerRows, vehicles]) => {
        return vehicles.map((vehicle, index) => ({
          vehicle
        }));
      })
    );
  }

  private getRowsHeightStyle$(): Observable<{
    [key: string]: {
      'minHeight.px': number;
      'height.px': number;
    };
  }> {
    return combineLatest([
      this.bookingSchedulerRows$,
      this.vehicleListRowsHeightPx$
    ]).pipe(
      map(([bookingSchedulerRows, vehicleListRowsHeightPx]) => {
        const rowsHeightStyle = {};
        bookingSchedulerRows.forEach(
          row =>
            (rowsHeightStyle[row.internalVehicleId] = {
              'minHeight.px':
                row.subRowsOfBooking.length *
                SchedulerSetting.subRowMinHeightPx,
              'height.px': vehicleListRowsHeightPx[row.internalVehicleId]
            })
        );
        return rowsHeightStyle;
      }),
      startWith({})
    );
  }

  private getRowsOfBookingsWithStyle$(): Observable<
    BookingSchedulerRowWithStyle[]
  > {
    return combineLatest([
      this.bookingSchedulerRows$,
      this.msPerPixel$,
      this.schedulerDateFrom$,
      this.schedulerDateTo$
    ]).pipe(
      map(([rows, msPerPixel, schedulerDateFrom, schedulerDateTo]) => {
        if (!rows) {
          return [];
        }
        return rows.map(row => ({
          internalVehicleId: row.internalVehicleId,
          remoteVehicleId: row.remoteVehicleId,
          subRowsOfBooking: row.subRowsOfBooking.map((subRow: Booking[]) =>
            subRow
              .filter(booking =>
                BookingUtils.isOnThisPeriodBooking(
                  booking,
                  schedulerDateFrom,
                  schedulerDateTo
                )
              )
              .map((booking: Booking) =>
                BookingUtils.createBookingWithStyle(
                  booking,
                  msPerPixel,
                  schedulerDateFrom,
                  schedulerDateTo,
                  this.currentUser
                )
              )
          ),
          vehicleUnavailabilities: row.vehicleUnavailabilities.map(
            (unavailability: UnavailabilityEvent) =>
              VehicleAvailabilityUtils.createVehicleUnavailabilityEventWithStyle(
                unavailability,
                msPerPixel,
                schedulerDateFrom,
                schedulerDateTo
              )
          ),
          vehicleAvailabilities: row.vehicleAvailabilities.map(
            (availability: AvailabilityEvent) =>
              VehicleAvailabilityUtils.createVehicleAvailabilityEventWithStyle(
                availability,
                msPerPixel,
                schedulerDateFrom,
                schedulerDateTo
              )
          ),
          vehicleAvailability: VehicleAvailabilityUtils.createVehicleAvailabilityWithStyle(
            row.vehicleAvailability,
            msPerPixel,
            schedulerDateFrom,
            schedulerDateTo
          ),
          bookingRequestHighlighted: row.bookingRequestHighlighted
            ? createBookingRequestWithStyle(
                row.bookingRequestHighlighted,
                msPerPixel,
                schedulerDateFrom,
                schedulerDateTo
              )
            : null
        }));
      })
    );
  }

  isCanceledBooking(booking: Booking): boolean {
    return (
      !!booking?.data &&
      [BookingStatusDto.CANCELED, BookingStatusDto.REJECTED].indexOf(
        booking.data.status
      ) !== -1
    );
  }

  isFullyAvailable(
    remoteVehicleId: string,
    bookings: Booking[],
    unavailabilities: UnavailabilityEvent[]
  ): boolean {
    if (
      !!bookings &&
      !!bookings.find(
        booking =>
          booking.remoteVehicleId === remoteVehicleId &&
          !this.isCanceledBooking(booking)
      )
    ) {
      return false;
    }
    if (
      !!unavailabilities &&
      !!unavailabilities.find(
        unavailability => unavailability.remoteVehicleId === remoteVehicleId
      )
    ) {
      return false;
    }
    return true;
  }

  isFullyUnavailablePeriod(
    schedulerDateFrom: Date,
    schedulerDateTo: Date,
    periods: Period[]
  ): boolean {
    if (!periods || periods.length === 0) {
      return false;
    }
    if (
      periods[0].start > schedulerDateFrom ||
      schedulerDateTo.valueOf() - periods[periods.length - 1].end.valueOf() >
        60000
    ) {
      return false;
    }
    if (periods.length > 1) {
      let prevPeriod = periods[0];
      for (let i = 1; i < periods.length; i++) {
        if (periods[i].start.valueOf() - prevPeriod.end.valueOf() > 60000) {
          return false;
        }
        prevPeriod = periods[i];
      }
    }
    return true;
  }

  isFullyUnavailable(
    remoteVehicleId: string,
    schedulerDateFrom: Date,
    schedulerDateTo: Date,
    bookings: Booking[],
    unavailabilities: UnavailabilityEvent[]
  ): boolean {
    const bookingPeriods: Period[] = bookings
      ?.filter(
        booking =>
          booking.remoteVehicleId === remoteVehicleId &&
          !this.isCanceledBooking(booking)
      )
      .map(booking => {
        return { start: booking.fromDate, end: booking.toDate };
      });
    const unavailabilityPeriods: Period[] = unavailabilities
      ?.filter(
        unavailability => unavailability.remoteVehicleId === remoteVehicleId
      )
      .map(unavailability => {
        return { start: unavailability.fromDate, end: unavailability.toDate };
      });
    let periods: Period[] = [];
    periods.push(...bookingPeriods);
    periods.push(...unavailabilityPeriods);
    const sorting: SortingOrderConfig<Period> = {
      prop: p => p.start.getTime()
    };
    periods = sortList(periods, sorting);
    return this.isFullyUnavailablePeriod(
      schedulerDateFrom,
      schedulerDateTo,
      periods
    );
  }
}
