import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingDto } from '../../../../../client';
import SharedUiUtils from '../../utils/shared-ui.utils';
import { DateUtil } from '../../../helpers/date-util';

@Component({
  selector: 'sof-booking-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="status-container" [ngStyle]="statusStyle">
        {{ localBooking?.status }}
      </div>
    </ng-container>
  `,
  styleUrls: ['./booking-status.component.scss']
})
export class BookingStatusComponent {
  @Input()
  set booking(booking: BookingDto) {
    this.localBooking = booking;
    if (!!booking) {
      const bookingColors = SharedUiUtils.getBookingColors(
        booking.status,
        DateUtil.convertToDate(booking.plannedPeriod.start),
        DateUtil.convertToDate(booking.plannedPeriod.end),
        true
      );
      this.statusStyle = {
        'background-color': bookingColors.background,
        color: bookingColors.text,
        border: '1.5px solid ' + bookingColors.border
      };
    }
  }

  localBooking: BookingDto = null;
  statusStyle: any = null;
}
