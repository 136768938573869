import { Component, OnInit } from '@angular/core';
import { search } from '@sofico-framework/utils';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/index';
import { debounceTime, map } from 'rxjs/operators';
import {
  OrganizationDto,
  OrganizationPageDto
} from  '../../../../../client';
import { AppSettings } from '../../../app.settings';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationsViewHelper } from '../../view-helpers/organizations-view.helper';

@Component({
  selector: 'sof-organizations-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="organizationViewSwitchModeButtons"
      ></sof-switch-mode-bar>
      <div class="search-bar">
        <input
          placeholder="{{ tc + '.SEARCH-PLACEHOLDER' | translate }}"
          autocomplete="off"
          class="search-input"
          (input)="onSearchChange($event)"
        />
        <button
          sofButton
          icon="batt-icon-plus"
          class="main-action-button"
          [routerLink]="['create-organization']"
        >
          {{ tc + '.ADD-ORGANIZATION' | translate }}
        </button>
      </div>
      <sof-organization-list
        [tc]="tc"
        [organizations]="filteredOrganizations$ | async"
      ></sof-organization-list>
    </ng-container>
    <router-outlet></router-outlet>
  `,
  providers: [OrganizationsViewHelper],
  styleUrls: ['./organizations-view.component.scss']
})
export class OrganizationsViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION';
  organizationViewSwitchModeButtons =
    AppSettings.organizationViewSwitchModeButtons;
  searchableProperties = [
    'name',
    'members.user.userName',
    'members.user.displayName'
  ];

  organizations$: Observable<OrganizationPageDto>;
  newSearchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  filteredOrganizations$: Observable<OrganizationDto[]>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private organizationsViewHelper: OrganizationsViewHelper
  ) {}

  ngOnInit(): void {
    this.organizations$ = this.organizationsViewHelper.organizations$;
    this.filteredOrganizations$ = this.organizations$.pipe(
      map(organizationPageDto => organizationPageDto?.organizations),
      search(
        this.newSearchText$.pipe(debounceTime(250)),
        this.searchableProperties
      )
    );
  }

  onSearchChange(event): void {
    this.newSearchText$.next(event.currentTarget.value);
  }
}
