import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettings } from '../../types/user-settings.type';
import {
  TooltipCreationType,
  TooltipSettings
} from '../../types/tooltip-settings.type';
import { of } from 'rxjs';
import { WindowRefService } from '@sofico-framework/utils';
import { VehicleCardFieldsToDisplay } from '../../types/vehicle-card-fields-to-display.type';

@Injectable()
export class UserSettingsService {
  private localStorageLabel = 'user_settings';

  // when no settings are found in the local storage,
  // the service put these one
  private defaultUserSettings: UserSettings = {
    tooltipSettings: {
      creation: TooltipCreationType.MOUSE_ENTER,
      followMouse: true
    },
    vehicleCardFieldsToDisplay: {
      poolVehicle: {
        licensePlate: true,
        category: true,
        model: true,
        site: true,
        gear: true
      }
    }
  };

  // the settings of the user, on each changes, a copy is saved in the local storage
  private actualUserSettings: UserSettings;

  constructor(private windowsRef: WindowRefService) {
    // if no settings in local storage, we set the default settings
    if (this.getSettingsFromLocalStorage() === null) {
      this.actualUserSettings = this.defaultUserSettings;
      this.saveSettingsIntoLocalStorage();
    } else {
      // otherwise, we get the one from the local storage
      this.actualUserSettings = this.getSettingsFromLocalStorage();
    }
  }

  // return all the user settings
  fetchAll(): Observable<UserSettings> {
    return of(this.actualUserSettings);
  }

  // set the tooltip options
  setTooltipSettings(tooltipSettings: TooltipSettings): Observable<boolean> {
    // we update the tooltip settings
    this.actualUserSettings = {
      ...this.actualUserSettings,
      tooltipSettings: {
        ...tooltipSettings
      }
    };
    // we save all the settings in the local storage
    this.saveSettingsIntoLocalStorage();

    return of(true);
  }

  // set the vehicle card fields to display
  setVehicleCardFieldsToDisplay(
    vehicleCardFieldsToDisplay: VehicleCardFieldsToDisplay
  ): Observable<boolean> {
    // we update the fields to display
    this.actualUserSettings = {
      ...this.actualUserSettings,
      vehicleCardFieldsToDisplay: {
        ...vehicleCardFieldsToDisplay
      }
    };
    // we save all the settings in the local storage
    this.saveSettingsIntoLocalStorage();

    return of(true);
  }

  // ------ local storage management ------

  private saveSettingsIntoLocalStorage(): void {
    this.windowsRef.nativeWindow.localStorage.setItem(
      this.localStorageLabel,
      JSON.stringify(this.actualUserSettings)
    );
  }

  private getSettingsFromLocalStorage(): UserSettings {
    return JSON.parse(
      this.windowsRef.nativeWindow.localStorage.getItem(this.localStorageLabel)
    );
  }
}
