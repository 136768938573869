/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TripTypeDto = 'BUSINESS' | 'COMMUTE' | 'PRIVATE' | 'PUBLIC';

export const TripTypeDto = {
    BUSINESS: 'BUSINESS' as TripTypeDto,
    COMMUTE: 'COMMUTE' as TripTypeDto,
    PRIVATE: 'PRIVATE' as TripTypeDto,
    PUBLIC: 'PUBLIC' as TripTypeDto
};

