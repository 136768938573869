import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '../../../../client';
import SharedUiUtils from '../utils/shared-ui.utils';

@Pipe({ name: 'extractUserDisplayName' })
export class ExtractUserDisplayNamePipe implements PipeTransform {
  transform(userDto: UserDto): string {
    if (!userDto) {
      return null;
    }
    return SharedUiUtils.getUserDisplayName(userDto);
  }
}
