import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ActingErrorMessage, HttpStatusService } from '@sofico-framework/utils';

// Copied from sofico-client-framework
@Injectable({
  providedIn: 'root'
})
export class HttpStatusInterceptor implements HttpInterceptor {
  private loadingCalls = 0;
  private actingCalls = 0;
  private showToastsOn = [500, 404, 0];

  constructor(private httpStatusService: HttpStatusService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const actingMethods = ['PUT', 'POST', 'DELETE'];
    // ignore images
    if (req.responseType === 'blob') {
      return next.handle(req.clone());
    }
    // SPECIFIC PART FOR BATT
    // ignore chat conversation polling
    if (req.url.endsWith('chat/v1/conversations')) {
      return next.handle(req.clone());
    }
    // SPECIFIC PART FOR BATT (END)
    this.changeStatus(true, req.method);
    return next.handle(req.clone()).pipe(
      catchError(e => {
        if (req.method === 'GET' && this.showToastsOn.indexOf(e.status) > -1) {
          this.httpStatusService.getError = 'SOMETHING-WENT-WRONG-GET';
        } else if (actingMethods.indexOf(req.method) > -1) {
          if (e?.error?.isInteractiveWarning) {
            return throwError(e);
          }

          let errors: ActingErrorMessage[];

          if (e?.error?.message) {
            errors = [
              {
                translation: e?.error.message.translation
              }
            ];
          } else if (e?.error?.messages && Array.isArray(e?.error?.messages)) {
            errors = e?.error.messages.map(x => ({
              translation: x.translation
            }));
          } else {
            errors = [
              {
                message: `ERROR_SOMETHING-WENT-WRONG-${req?.method}`
              }
            ];
          }
          this.httpStatusService.actingErrors = errors;
        }
        return throwError(e);
      }),
      finalize(() => {
        this.changeStatus(false, req.method);
      })
    );
  }

  private changeStatus(v: boolean, method: string): void {
    if (['POST', 'PUT', 'DELETE', 'PATCH'].indexOf(method) > -1) {
      v
        ? this.actingCalls++
        : this.actingCalls > 0
        ? this.actingCalls--
        : (this.actingCalls = 0);
      this.httpStatusService.acting = this.actingCalls > 0;
    } else if (method === 'GET') {
      v
        ? this.loadingCalls++
        : this.loadingCalls > 0
        ? this.loadingCalls--
        : (this.loadingCalls = 0);
      this.httpStatusService.loading = this.loadingCalls > 0;
    }
  }
}
