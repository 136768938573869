import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigService } from '@sofico-framework/app-config';
import { DateFormatEnum } from '@sofico-framework/utils';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import {
  BookingUserDto,
  DamageDto,
  DamagePageDto,
  DamageStatusDto,
  SearchDamageRequestDto
} from '../../../../../client';
import { DateUtil } from '../../../helpers/date-util';
import { ToastUtilService } from '../../../services/toast-util.service';
import { DamageManagementSandbox } from '../../damage-management.sandbox';

@Component({
  selector: 'sof-damage-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-damage-filter-form
        [tc]="tc"
        [filterForm]="filterForm"
        [vehicles]="(vehiclePageDto$ | async)?.vehicles"
        [users]="(userPageDto$ | async)?.users"
        [currentUser]="currentUser$ | async"
        [dateFormat]="dateFormat"
        (searchDamages)="onSearchDamages()"
      ></sof-damage-filter-form>
      <sof-damage-list
        [tc]="tc"
        [damagePageDto]="damagePageDto$ | async"
        [canEdit]="true"
        (editDamage)="onEditDamage($event)"
      ></sof-damage-list>
    </ng-container>
  `,
  styleUrls: ['./damage-view.component.scss']
})
export class DamageViewComponent implements OnInit {
  tc = 'DAMAGE-MANAGEMENT_DAMAGE';
  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  // TODO - To be removed when search component will exist for users and vehicles
  userPageDto$ = this.sb.allUsers$;
  vehiclePageDto$ = this.sb.allVehicles$;

  currentUser$: Observable<BookingUserDto>;
  filterForm: FormGroup;

  damagePageDto$: Observable<DamagePageDto>;

  constructor(
    private sb: DamageManagementSandbox,
    private fb: FormBuilder,
    private toastUtilService: ToastUtilService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.sb.currentUser$.pipe(shareReplay(1));
    this.filterForm = this.fb.group({
      fromPicker: null,
      toPicker: null,
      vehicleId: '',
      userId: '',
      organizationId: '',
      statuses: [[DamageStatusDto.CREATED, DamageStatusDto.FIXED]]
    });
  }

  onSearchDamages(): void {
    if (!this.filterForm.valid) {
      this.toastUtilService.error(this.tc + '.FILL-OUT-REQUIRED-DATA', true);
      return;
    }
    if (this.filterForm.value.vehicleId === '') {
      this.filterForm.value.vehicleId = null;
    }
    const requestDto: SearchDamageRequestDto = {
      shownStatusses: this.filterForm.value.statuses
    };
    if (!!this.filterForm.value.vehicleId) {
      requestDto.vehicleId = this.filterForm.value.vehicleId;
    }
    if (!!this.filterForm.value.organizationId) {
      requestDto.managedByOrganizations = [
        this.filterForm.value.organizationId
      ];
    }
    if (!!this.filterForm.value.userId) {
      requestDto.managedByUsers = [this.filterForm.value.userId];
    }
    if (
      !!this.filterForm.value.fromPicker ||
      !!this.filterForm.value.toPicker
    ) {
      requestDto.period = DateUtil.convertToSearchPeriod(
        this.filterForm.value.fromPicker,
        this.filterForm.value.toPicker
      );
    }
    this.damagePageDto$ = this.sb.searchDamages(requestDto).pipe(take(1));
  }

  onEditDamage(damage: DamageDto): void {
    this.sb.navigateToEditDamage(damage.vehicle.id, damage.id);
  }
}
