import { BookingRequest } from '../../types/booking-request.type';
import { BookingRequestsActions } from '../actions/booking-requests.actions';
import { ActionTypes } from '../action-types';

export function bookingRequestsReducer(
  state: BookingRequest[] = [],
  action: BookingRequestsActions
): BookingRequest[] {
  switch (action.type) {
    case ActionTypes.SET_BOOKING_REQUESTS:
      return [...action.payload.bookingRequests];
  }
  return state;
}
