import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '@sofico-framework/app-config';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { ToastUtilService } from '../../../services/toast-util.service';
import { GoogleHelper } from '../../../shared-ui/view-helpers/google.helper';
import { AvailabilityDialogData } from '../../../types/availability-dialog-data.type';
import { VehicleDialogData } from '../../../types/vehicle-dialog-data.type';
import { SchedulerSandbox } from '../../scheduler.sandbox';
import {
  catchError,
  debounceTime,
  filter,
  map,
  pairwise,
  shareReplay,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of
} from 'rxjs';
import { SchedulerSetting } from '../../../scheduler.setting';
import { ViewMode } from '../../../types/view-mode.type';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { Booking } from '../../../types/booking.type';
import { Vehicle } from '../../../types/vehicle.type';
import { BookingRequest } from '../../../types/booking-request.type';
import { UnavailabilityEventWithStyle } from '../../../types/unavailability-event-with-style.type';
import { BookingUpdate } from '../../../types/booking-update.type';
import { UnavailabilityEvent } from '../../../types/unavailability-event.type';
import {
  isPeriodInPeriod,
  isPeriodOverlapPeriod
} from '../../utils/vehicle-overflow.utils';
import { Period } from '../../../types/period.type';
import { DateFormatEnum, WindowRefService } from '@sofico-framework/utils';
import { BookingAdd } from '../../../types/booking-add.type';
import {
  AvailabilityDto,
  BatteryStatusDto,
  BookingDto,
  BookingUserDto,
  ChargingStatusDto,
  FilterCriteriaForBookingDto,
  OrganizationReferenceDto,
  RecurringAvailabilityDto,
  TripTypeDto,
  UserPageDto,
  UserRoleDto,
  VehicleDto,
  VehiclePageDto,
  VehicleSearchCriteriaDto
} from  '../../../../../client';
import { DateUtil } from '../../../helpers/date-util';
import { VehicleManagementSandbox } from '../../../vehicle-management-ui/vehicle-management.sandbox';
import { AvailabilityEventWithStyle } from '../../../types/availability-event-with-style.type';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipContentValuesEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import { ActiveSchedulerFilters } from '../../../types/active-scheduler-filters.type';
import { ApplyActiveSchedulerFilters } from '../../../types/apply-active-scheduler-filters.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import { ActiveFilter } from '../../../types/active-filter.type';
import { AppSandbox } from '../../../app.sandbox';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import { BookingDialogData } from '../../../types/booking-dialog-data.type';
import {
  Tooltip,
  TooltipContentBooking,
  TooltipContentPoolVehicle,
  TooltipType
} from '../../../types/tooltip.type';
import ConvertersUtils from '../../../shared-ui/utils/converters.utils';
import { SchedulerViewHelper } from '../../view-helpers/scheduler-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-scheduler-view',
  template: `
    <ng-container class="scheduler-view-container">
      <sof-loading-spinner></sof-loading-spinner>
      <div class="header">
        <sof-scheduler-toolbar
          class="toolbar"
          [tc]="tc"
          [viewModes]="viewModes"
          [actualViewMode]="viewMode$ | async"
          [dateFrom]="dateFrom$ | async"
          [showMap]="showMap$ | async"
          [organizationId]="currentOrganizationId$ | async"
          [organizations]="organizations$ | async"
          [showMyBookings]="showMyBookings$ | async"
          [dateFormat]="dateFormat"
          [googleApiLoaded]="googleHelper.googleApiLoaded$ | async"
          (setDateFrom)="onSetDateFrom($event)"
          (setViewMode)="onSetViewMode($event)"
          (addBooking)="onAddBooking()"
          (addAvailability)="onAddAvailability()"
          (newSearchText)="searchText$.next($event)"
          (showApplyVehicleFiltersDialog)="showApplyVehicleFiltersDialog = true"
          (setShowMap)="onSetShowMap($event)"
          (setCurrentOrganizationId)="onSetCurrentOrganizationId($event)"
          (setShowMyBookings)="onSetShowMyBookings($event)"
        >
        </sof-scheduler-toolbar>

        <sof-booking-scheduler-filters
          [tc]="tc"
          [activeSchedulerFilters]="activeSchedulerFilters$ | async"
          (newActiveSchedulerFilters)="onNewActiveSchedulerFilters($event)"
        ></sof-booking-scheduler-filters>

        <sof-cards-and-schedulers
          [dateFrom]="dateFrom$ | async"
          [viewMode]="viewMode$ | async"
          [showMap]="showMap$ | async"
          [vehicles]="filteredVehicles$ | async"
          [bookings]="bookings$ | async"
          [availabilities]="availabilities$ | async"
          [unavailabilities]="unavailabilities$ | async"
          [bookingRequestHighlighted]="bookingRequestHighlighted$ | async"
          [vehicleFieldsAndLabels]="vehicleFieldsAndLabels"
          [bookingRequests]="bookingRequests$ | async"
          [bookingRequestSelectedId]="(bookingRequestHighlighted$ | async)?.id"
          [windowKeyUp]="windowKeyUp$ | async"
          [windowKeyDown]="windowKeyDown$ | async"
          [sharedTooltipEvent]="sharedTooltipEvent"
          [tooltipsAskedToBeDisplayed]="tooltipsSub$ | async"
          [currentUser]="currentUser$ | async"
          [tc]="tc"
          [googleApiLoaded]="googleHelper.googleApiLoaded$ | async"
          (newBooking)="onSetNewBooking($event)"
          (updateBooking)="onUpdateBooking($event)"
          (dblClickOnBooking)="onEditBooking($event)"
          (tooltipEvent)="onTooltipEvent($event)"
          (editBooking)="onEditBooking($event)"
          (applyActiveSchedulerFilters)="onApplyActiveSchedulerFilters($event)"
          (dblClickOnUnavailability)="onEditUnavailability($event)"
          (editUnavailability)="onEditUnavailability($event)"
          (dblClickOnAvailability)="onEditAvailability($event)"
          (editAvailability)="onEditAvailability($event)"
          (newBookingRequestSelected)="bookingRequestHighlighted$.next($event)"
          (dblClickPoolVehicleEvent)="onEditPoolVehicle($event)"
          (editVehicle)="onEditPoolVehicle($event)"
          (refreshVehicleLocation)="onRefreshVehicleLocation($event)"
          (addBookingForVehicle)="onAddBookingForVehicle($event)"
          (addAvailabilityForVehicle)="onAddAvailabilityForVehicle($event)"
          (endVehicleUsage)="onEndVehicleUsage($event)"
        >
        </sof-cards-and-schedulers>
      </div>
      <router-outlet></router-outlet>
      <sof-availability-edit-choices-dialog
        *ngIf="showAvailabilityEditChoicesDialog"
        [tc]="tc"
        (cancelDialog)="showAvailabilityEditChoicesDialog = false"
        (okDialog)="onOkAvailabilityEditChoicesDialog($event)"
      ></sof-availability-edit-choices-dialog>
      <sof-apply-vehicle-filters-dialog
        *ngIf="showApplyVehicleFiltersDialog"
        [tc]="tc"
        (cancelDialog)="showApplyVehicleFiltersDialog = false"
      ></sof-apply-vehicle-filters-dialog>
    </ng-container>
  `,
  providers: [SchedulerViewHelper],
  styleUrls: ['./scheduler-view.component.scss']
})
export class SchedulerViewComponent implements OnInit {
  userPageDto: UserPageDto;
  vehiclePageDto: VehiclePageDto;
  tc = 'SCHEDULER_SCHEDULER';
  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  @Output()
  newBooking: EventEmitter<BookingAdd> = new EventEmitter<BookingAdd>();
  @Output()
  updateBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();
  @Output()
  changeVehicleOnBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();
  @Output()
  editBooking: EventEmitter<Booking> = new EventEmitter<Booking>();

  showAvailabilityEditChoicesDialog = false;
  showApplyVehicleFiltersDialog = false;

  dateFrom$ = this.schedulerSandbox.dateFrom$.pipe(shareReplay(1));
  viewMode$ = this.schedulerSandbox.viewMode$.pipe(shareReplay(1));
  showMap$ = this.schedulerSandbox.showMap$.pipe(shareReplay(1));
  currentOrganizationId$ = this.schedulerSandbox.currentOrganizationId$.pipe(
    shareReplay(1)
  );
  showMyBookings$ = this.schedulerSandbox.showMyBookings$.pipe(shareReplay(1));
  vehicles$ = this.schedulerSandbox.vehicles$.pipe(shareReplay(1));
  privateUsages$ = this.schedulerSandbox.privateUsages$.pipe(shareReplay(1));
  bookings$ = this.schedulerSandbox.bookings$.pipe(shareReplay(1));
  unavailabilities$ = this.schedulerSandbox.unavailabilities$.pipe(
    shareReplay(1)
  );
  availabilities$ = this.schedulerSandbox.availabilities$.pipe(shareReplay(1));
  activeSchedulerFilters$ = this.schedulerSandbox.activeSchedulerFilters$.pipe(
    shareReplay(1)
  );
  bookingRequests$ = this.schedulerSandbox.bookingRequests$.pipe(
    shareReplay(1)
  );

  currentUser$ = this.appSandbox.currentUser$.pipe(shareReplay(1));

  tooltipsSub$: BehaviorSubject<Tooltip[]> = new BehaviorSubject<Tooltip[]>([]);

  organizations$: Observable<
    Array<OrganizationReferenceDto>
  > = this.currentUser$.pipe(
    switchMap(user => {
      if (user.role === UserRoleDto.BATTADMIN) {
        return this.schedulerSandbox.getOrganizationsReferences().pipe(
          map(organizationReferencePageDto => {
            const organizations: OrganizationReferenceDto[] = [];
            organizations.push({
              id: SharedUiUtils.NO_ORGANIZATION_ID,
              name: 'All'
            });
            if (!!organizationReferencePageDto?.organizations) {
              return organizations.concat(
                organizationReferencePageDto?.organizations
              );
            }
            return organizations;
          }),
          take(1)
        );
      }
      return of(user.organizations);
    }),
    map(organizations => {
      if (!!organizations) {
        return organizations.sort(this.sortOrganizations);
      }
      return organizations;
    })
  );

  sortOrganizations(
    a: OrganizationReferenceDto,
    b: OrganizationReferenceDto
  ): number {
    if (a?.id === SharedUiUtils.NO_ORGANIZATION_ID) {
      return -1;
    }
    if (b?.id === SharedUiUtils.NO_ORGANIZATION_ID) {
      return 1;
    }
    return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase());
  }

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  viewModes = SchedulerSetting.viewModes; // this line is used to import the view mode constant into the component

  // ------ Activity panel state  -------
  partialBooking: Booking;
  activityPanelVisibility = false;
  activityPanelTypeInput: 'Add' | 'Update' | 'Update Unavailability';

  // ------ vehicles ------

  // array with vehicles
  allVehicles$: Observable<Vehicle[]> = this.vehicles$;

  filteredVehicles$: Observable<Vehicle[]> = combineLatest([
    this.vehicles$,
    this.bookings$,
    this.searchText$,
    this.showMyBookings$,
    this.currentUser$,
    this.privateUsages$
  ]).pipe(
    map(
      ([
        vehicles,
        bookings,
        searchText,
        showMyBookings,
        currentUser,
        privateUsages
      ]) => {
        let filteredVehicles = [...vehicles];
        if (showMyBookings && currentUser) {
          const currentUserId = [currentUser.remoteId];
          filteredVehicles = filteredVehicles.filter(vehicle =>
            this.hasBookingForUser(vehicle, bookings, currentUserId)
          );
        }
        if (!searchText || searchText === '') {
          return this.updateVehiclesWithPrivateUsage(
            filteredVehicles,
            privateUsages
          );
        }
        let searchStrings = searchText.toLowerCase().split(' ');
        searchStrings = searchStrings.filter(searchString => !!searchString);
        if (searchStrings.length === 0) {
          return this.updateVehiclesWithPrivateUsage(
            filteredVehicles,
            privateUsages
          );
        }
        const searchedUserIds = this.getMatchingUserRemoteIds(searchStrings);
        filteredVehicles = filteredVehicles.filter(
          vehicle =>
            !this.isVehicleFiltered(
              vehicle,
              bookings,
              searchStrings,
              searchedUserIds
            )
        );
        return this.updateVehiclesWithPrivateUsage(
          filteredVehicles,
          privateUsages
        );
      }
    )
  );

  // ------ booking requests -------

  bookingRequestHighlighted$: BehaviorSubject<BookingRequest> = new BehaviorSubject<BookingRequest>(
    null
  );
  // NOTE cabu : we could move it to redux
  // should emit a first value null asap, otherwise bookingShedulerRows will wait for its first value to be constructed

  // ----- vehicle card list -----

  // all the fields (and their labels) of the pool vehicles
  vehicleFieldsAndLabels: {
    poolVehicle: { field: string; label: string }[];
  } = this.schedulerSandbox.fetchAllVehicleFieldsAndLabels();

  windowKeyUp$: Observable<KeyboardEvent> = fromEvent(
    this.windowsRef.nativeWindow,
    'keyup'
  );
  windowKeyDown$: Observable<KeyboardEvent> = fromEvent(
    this.windowsRef.nativeWindow,
    'keydown'
  );

  sharedTooltipEvent: TooltipEvent = null;

  period$: Observable<Period> = combineLatest([
    this.dateFrom$,
    this.viewMode$
  ]).pipe(
    map(([dateFrom, viewMode]: [Date, ViewMode]) =>
      SchedulerDateUtils.getPeriodFromDateAndViewMode(dateFrom, viewMode)
    )
  );

  vehiclesData$ = combineLatest([
    this.activeSchedulerFilters$,
    this.currentOrganizationId$,
    this.currentUser$,
    this.schedulerViewHelper.refreshVehicles$
  ]).pipe(
    debounceTime(250),
    switchMap(
      ([
        activeSchedulerFilters,
        currentOrganizationId,
        currentUser,
        triggerFetchVehicles
      ]) =>
        this.schedulerSandbox.fetchVehicles(
          activeSchedulerFilters.vehicleSearchCriteriaDto,
          currentOrganizationId,
          currentUser
        )
    )
  );

  availabilityEventsData$ = combineLatest([
    this.vehicles$,
    this.period$,
    this.currentOrganizationId$,
    this.currentUser$,
    this.schedulerViewHelper.refreshAvailabilityEvents$
  ]).pipe(
    debounceTime(250),
    pairwise(),
    filter(([lastValue, newValue]) => {
      return (
        !this.isEqualVehicles(lastValue[0], newValue[0]) ||
        !this.isEqualPeriod(lastValue[1], newValue[1]) ||
        lastValue[2] !== newValue[2] ||
        !this.isEqualUser(lastValue[3], newValue[3]) ||
        !this.isEqualDate(lastValue[4], newValue[4])
      );
    }),
    map(([lastValue, newValue]) => newValue),
    switchMap(([vehicles, period, currentOrganizationId, currentUser]) =>
      this.schedulerSandbox.fetchAvailabilityEvents(
        vehicles,
        period.start,
        period.end,
        currentOrganizationId,
        currentUser
      )
    )
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private schedulerSandbox: SchedulerSandbox,
    private vehicleManagementSandbox: VehicleManagementSandbox,
    private windowsRef: WindowRefService,
    private toastUtilService: ToastUtilService,
    private dialogService: DialogService,
    private appSandbox: AppSandbox,
    private configService: ConfigService,
    private schedulerViewHelper: SchedulerViewHelper,
    public googleHelper: GoogleHelper
  ) {}

  ngOnInit(): void {
    this.availabilityEventsData$.pipe(takeUntilDestroy(this)).subscribe();
    this.vehiclesData$.pipe(takeUntilDestroy(this)).subscribe();
    this.appSandbox.allUsers$
      .pipe(takeUntilDestroy(this))
      .subscribe(userPageDto => (this.userPageDto = userPageDto));
    this.appSandbox.allVehicles$
      .pipe(takeUntilDestroy(this))
      .subscribe(vehiclePageDto => (this.vehiclePageDto = vehiclePageDto));
  }

  onSetDateFrom(date: Date): void {
    this.schedulerSandbox.setDateFrom(date);
  }

  onSetViewMode(value: ViewMode): void {
    this.schedulerSandbox.setViewMode(value);
  }

  onSetShowMap(value: boolean): void {
    this.schedulerSandbox.setShowMap(value);
  }

  onSetCurrentOrganizationId(value: string): void {
    this.schedulerSandbox.setCurrentOrganizationId(value);
  }

  onSetShowMyBookings(value: boolean): void {
    this.schedulerSandbox.setShowMyBookings(value);
  }

  // ----- activity management -----
  onEditAvailability(event: AvailabilityEventWithStyle): void {
    if (event.data) {
      this.getRecurringAvailabilityAndOpenDialog(event.data as AvailabilityDto);
    }
  }

  onEditUnavailability(event: UnavailabilityEventWithStyle): void {
    if (event.data.availability) {
      this.getRecurringAvailabilityAndOpenDialog(
        event.data.availability as AvailabilityDto
      );
    }
  }

  // ----- pool vehicle management ------
  onEditPoolVehicle(vehicle: Vehicle): void {
    if (!!vehicle?.remoteId) {
      this.schedulerSandbox
        .getVehicle(vehicle.remoteId)
        .pipe(withLatestFrom(this.currentUser$))
        .subscribe(([vehicleDto, currentUser]) => {
          this.openVehicleDialog(vehicleDto.id, vehicleDto, currentUser);
        });
    }
  }

  openVehicleDialog(
    vehicleId: string,
    vehicleDto: VehicleDto,
    currentUser: BookingUserDto
  ): void {
    // TODO : remove appSandbox.allUsers$ when DamageDto will be updated with UserReferenceDto
    this.appSandbox.allUsers$.subscribe(userPage => {
      const data: VehicleDialogData = {
        vehicleId,
        vehicleDto,
        currentUser,
        userPage
      };
      this.schedulerViewHelper.vehicleDialogData = data;
      this.router.navigate(['edit-vehicle'], {
        relativeTo: this.activatedRoute
      });
    });
  }

  // ----- booking management ------

  onEditBooking(booking: Booking): void {
    this.getCurrentUserAndOrganizationThenExecute(
      (currentUser: BookingUserDto, currentOrganizationId: string) => {
        this.editBooking.emit(booking);
        this.schedulerSandbox.getBooking(booking.remoteId).subscribe(
          bookingDto => {
            this.openBookingDialog(
              bookingDto.id,
              bookingDto.user.remoteId,
              bookingDto.organization.id,
              bookingDto.vehicle.id,
              DateUtil.convertToDate(bookingDto.plannedPeriod.start),
              DateUtil.convertToDate(bookingDto.plannedPeriod.end),
              bookingDto.tripType,
              bookingDto.comments,
              false,
              bookingDto,
              currentUser,
              null,
              'edit-booking'
            );
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_GET-BOOKING'
            );
          }
        );
      }
    );
  }

  onSetNewBooking(bookingAdd: BookingAdd): void {
    this.getCurrentUserAndOrganizationThenExecute(
      (currentUser: BookingUserDto, currentOrganizationId: string) => {
        if (
          !this.checkVehicleAvailability(
            bookingAdd.remoteVehicleId,
            bookingAdd.fromDate,
            bookingAdd.toDate
          )
        ) {
          return;
        }

        this.newBooking.emit(bookingAdd);

        this.openBookingDialog(
          null,
          currentUser.remoteId,
          currentOrganizationId,
          bookingAdd.remoteVehicleId,
          bookingAdd.fromDate,
          bookingAdd.toDate,
          TripTypeDto.PRIVATE,
          '',
          false,
          null,
          currentUser,
          null,
          'create-booking'
        );
      }
    );
  }

  onAddBooking(remoteVehicleId?: string): void {
    this.getCurrentUserAndOrganizationThenExecute(
      (currentUser: BookingUserDto, currentOrganizationId: string) => {
        const fromDate: Date = new Date();
        fromDate.setDate(fromDate.getDate() + 1);
        fromDate.setHours(16, 0, 0, 0);
        const toDate: Date = new Date(fromDate);
        toDate.setHours(20);
        this.openBookingDialog(
          null,
          currentUser.remoteId,
          currentOrganizationId,
          remoteVehicleId,
          fromDate,
          toDate,
          TripTypeDto.PRIVATE,
          null,
          false,
          null,
          currentUser,
          null,
          'create-booking'
        );
      }
    );
  }

  openBookingDialog(
    remoteBookingId: string,
    userId: string,
    organizationId: string,
    remoteVehicleId: string,
    fromDate: Date,
    toDate: Date,
    tripType: TripTypeDto,
    comments: string,
    vehicleChange: boolean,
    bookingDto: BookingDto,
    currentUser: BookingUserDto,
    internalBookingId: string,
    path: string
  ): void {
    const data: BookingDialogData = {
      internalBookingId,
      remoteBookingId,
      userId,
      organizationId,
      fromDate,
      toDate,
      remoteVehicleId,
      tripType,
      comments,
      vehicleChange,
      bookingDto,
      vehicles: this.vehiclePageDto?.vehicles,
      users: this.userPageDto?.users,
      currentUser
    };
    this.schedulerViewHelper.bookingDialogData = data;
    this.router.navigate([path], { relativeTo: this.activatedRoute });
  }

  getRecurringAvailabilityAndOpenDialog(
    availabilityDto: AvailabilityDto
  ): void {
    if (!!availabilityDto.recurringAvailabilityId) {
      this.schedulerSandbox
        .getRecurringAvailability(availabilityDto.recurringAvailabilityId)
        .subscribe(
          recurringAvailabilityDto => {
            this.openAvailabilityDialog(
              availabilityDto.id,
              availabilityDto.vehicle.id,
              DateUtil.convertToDate(availabilityDto.period.start),
              DateUtil.convertToDate(availabilityDto.period.end),
              availabilityDto,
              recurringAvailabilityDto
            );
          },
          error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_GET-RECURRING-AVAILABILITY'
            );
          }
        );
    } else {
      this.openAvailabilityDialog(
        availabilityDto.id,
        availabilityDto.vehicle.id,
        DateUtil.convertToDate(availabilityDto.period.start),
        DateUtil.convertToDate(availabilityDto.period.end),
        availabilityDto,
        null
      );
    }
  }

  onAddAvailability(remoteVehicleId?: string): void {
    const fromDate: Date = new Date();
    fromDate.setDate(fromDate.getDate() + 1);
    fromDate.setHours(16, 0, 0, 0);
    const toDate: Date = new Date(fromDate);
    toDate.setHours(20);
    this.openAvailabilityDialog(
      null,
      remoteVehicleId,
      fromDate,
      toDate,
      null,
      null
    );
  }

  onOkAvailabilityEditChoicesDialog(editChoice: number): void {
    this.showAvailabilityEditChoicesDialog = false;
    this.goToAvailabilityDialogView(editChoice);
  }

  openAvailabilityDialog(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    availabilityDto: AvailabilityDto,
    recurringAvailabilityDto: RecurringAvailabilityDto
  ): void {
    const vehicle = this.getVehicle(vehicleId);
    let recurringAvailability = null;
    if (recurringAvailabilityDto) {
      recurringAvailability = {
        ...recurringAvailabilityDto.schedule,
        endDate: DateUtil.convertToDate(
          recurringAvailabilityDto.schedule.endDate
        )
      };
    }
    const availabilityDialogData: AvailabilityDialogData = {
      availabilityId,
      vehicleId,
      fromDate,
      toDate,
      availabilityDto,
      recurringAvailabilityDto,
      licensePlate: vehicle?.licensePlate,
      vehicleName: vehicle?.name,
      recurringAvailability,
      alwaysAvailable: vehicle?.data?.alwaysAvailable,
      vehicles: this.vehiclePageDto?.vehicles,
      editChoice: null
    };
    this.schedulerViewHelper.availabilityDialogData = availabilityDialogData;

    if (!!recurringAvailabilityDto) {
      this.showAvailabilityEditChoicesDialog = true;
    } else {
      this.goToAvailabilityDialogView(null);
    }
  }

  goToAvailabilityDialogView(editChoice: number): void {
    this.schedulerViewHelper.availabilityDialogData = {
      ...this.schedulerViewHelper.availabilityDialogData,
      editChoice
    };
    this.router.navigate(['edit-availability'], {
      relativeTo: this.activatedRoute
    });
  }

  // ----- booking update ------
  onUpdateBooking(bookingUpdate: BookingUpdate): void {
    this.getCurrentUserAndOrganizationThenExecute(
      (currentUser: BookingUserDto, currentOrganizationId: string) => {
        const newBookingData: Booking = {
          ...bookingUpdate.previousBooking,
          ...bookingUpdate.newFields
        };

        if (
          !this.checkVehicleAvailability(
            newBookingData.remoteVehicleId,
            newBookingData.fromDate,
            newBookingData.toDate,
            newBookingData.internalId
          )
        ) {
          return;
        }

        // if only the vehicle id is updated, we do not open the activity panel
        // otherwise (if a date is updated) we open it
        const fieldsUpdated = Object.keys(bookingUpdate.newFields);
        if (fieldsUpdated.length === 1 && fieldsUpdated[0] === 'vehicleId') {
          this.changeVehicleOnBooking.emit(bookingUpdate);
        } else {
          this.updateBooking.emit(bookingUpdate);
        }

        this.schedulerSandbox
          .getBooking(bookingUpdate.previousBooking.remoteId)
          .subscribe(
            bookingDto => {
              bookingUpdate.previousBooking.userId = bookingDto.user.remoteId;
              bookingUpdate.previousBooking.comments = bookingDto.comments;
              bookingUpdate.previousBooking.tripType = bookingDto.tripType;
              bookingUpdate.previousBooking.remoteVehicleId =
                bookingDto.vehicle.id;

              const fromDate: Date = bookingUpdate.newFields.fromDate
                ? bookingUpdate.newFields.fromDate
                : DateUtil.convertToDate(bookingDto.plannedPeriod.start);
              const toDate: Date = bookingUpdate.newFields.toDate
                ? bookingUpdate.newFields.toDate
                : DateUtil.convertToDate(bookingDto.plannedPeriod.end);
              const remoteVehicleId: string = bookingUpdate.newFields
                .remoteVehicleId
                ? bookingUpdate.newFields.remoteVehicleId
                : bookingDto.vehicle.id;

              this.openBookingDialog(
                bookingDto.id,
                bookingDto.user.remoteId,
                bookingDto.organization.id,
                remoteVehicleId,
                fromDate,
                toDate,
                bookingDto.tripType,
                bookingDto.comments,
                bookingUpdate.newFields.remoteVehicleId !== undefined,
                bookingDto,
                currentUser,
                bookingUpdate.previousBooking.internalId,
                'edit-booking'
              );
            },
            error => {
              this.toastUtilService.showError(
                error,
                this.tc + '.FAILED_GET-BOOKING'
              );
            }
          );
      }
    );
  }

  // ------ activity panel ------
  checkVehicleAvailability(
    remoteVehicleId: string,
    fromDate: Date,
    toDate: Date,
    bookingInternalId?: string
  ): boolean {
    const vehicle: Vehicle = this.getVehicle(remoteVehicleId);

    if (
      !!vehicle &&
      vehicle.availability &&
      !isPeriodInPeriod(
        fromDate,
        toDate,
        vehicle.availability.startOfAvailability,
        vehicle.availability.endOfAvailability
      )
    ) {
      this.toastUtilService.error(
        this.tc + '.THE-VEHICLE-IS-NOT-AVAILABLE',
        true
      );
      return false;
    }

    const vehicleUnavailabilities: UnavailabilityEvent[] = this.getVehicleUnavailabilities(
      remoteVehicleId
    );
    if (!!vehicleUnavailabilities) {
      for (const unavailability of vehicleUnavailabilities) {
        if (
          isPeriodOverlapPeriod(
            fromDate,
            toDate,
            unavailability.fromDate,
            unavailability.toDate
          )
        ) {
          this.toastUtilService.error(
            this.tc + '.THE-VEHICLE-IS-NOT-AVAILABLE',
            true
          );
          return false;
        }
      }
    }

    const vehicleBookings = this.getVehicleBookings(remoteVehicleId);
    if (!!vehicleBookings) {
      for (const booking of vehicleBookings) {
        if (!!bookingInternalId && booking.internalId === bookingInternalId) {
          continue;
        }

        if (
          isPeriodOverlapPeriod(
            fromDate,
            toDate,
            booking.fromDate,
            booking.toDate
          )
        ) {
          this.toastUtilService.error(
            this.tc + '.THE-VEHICLE-IS-NOT-AVAILABLE',
            true
          );
          return false;
        }
      }
    }

    return true;
  }

  getVehicle(remoteVehicleId: string): Vehicle {
    let resVehicle: Vehicle = null;
    this.allVehicles$.subscribe(
      vehicles =>
        (resVehicle = vehicles.find(
          vehicle => vehicle.remoteId === remoteVehicleId
        ))
    );
    return resVehicle;
  }

  getVehicleUnavailabilities(remoteVehicleId: string): UnavailabilityEvent[] {
    let vehicleUnavailabilities: UnavailabilityEvent[] = null;
    this.unavailabilities$.subscribe(unavailabilities => {
      vehicleUnavailabilities = unavailabilities.filter(
        unavailability => unavailability.remoteVehicleId === remoteVehicleId
      );
    });
    return vehicleUnavailabilities;
  }

  getVehicleBookings(remoteVehicleId: string): Booking[] {
    let vehicleBookings: Booking[] = null;
    this.bookings$.subscribe(bookings => {
      vehicleBookings = bookings.filter(
        booking => booking.remoteVehicleId === remoteVehicleId
      );
    });
    return vehicleBookings;
  }

  // ----- filter management -----
  onNewActiveSchedulerFilters(
    activeSchedulerFilters: ActiveSchedulerFilters
  ): void {
    // and we dispatch the new filter
    this.schedulerSandbox.setActiveSchedulerFilters(activeSchedulerFilters);
  }

  onApplyActiveSchedulerFilters(
    applyActiveSchedulerFilters: ApplyActiveSchedulerFilters
  ): void {
    combineLatest([
      this.schedulerSandbox.intents$,
      this.schedulerSandbox.vehicleModels$,
      this.schedulerSandbox.vehicleEquipments$
    ])
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(([intents, vehicleModels, equipments]) => {
        const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
        const vehicleSearchCriteriaDto: VehicleSearchCriteriaDto = ActiveFiltersUtils.getVehicleSearchCriteriaDtoForFilterCriteriaForBookingDto(
          applyActiveSchedulerFilters.filterCriteria
        );
        ActiveFiltersUtils.fillOutActiveFilters(
          applyActiveSchedulerFilters.filterCriteria,
          intents,
          vehicleModels,
          equipments,
          activeFilters,
          activeFilters,
          activeFilters
        );
        this.schedulerSandbox.setActiveSchedulerFilters({
          booking: applyActiveSchedulerFilters.booking,
          vehicleSearchCriteriaDto,
          activeFilters
        });
      });
  }

  getCurrentUserAndOrganizationThenExecute(
    callback: (
      currentUser: BookingUserDto,
      currentOrganizationId: string
    ) => void
  ): void {
    this.currentUser$
      .pipe(withLatestFrom(this.currentOrganizationId$), take(1))
      .subscribe(([currentUser, currentOrganizationId]) => {
        callback(currentUser, currentOrganizationId);
      });
  }

  // ------ tooltip management ----
  onTooltipEvent(event: TooltipEvent): void {
    if (event instanceof AddTooltipEvent) {
      if (event.payload.tooltip.type === TooltipType.BOOKING) {
        // Read complete BookingDto (otherwise we just have BookingReferenceDto)
        const tooltipContentBooking = event.payload.tooltip
          .content as TooltipContentBooking;
        combineLatest([
          this.schedulerSandbox.getBooking(
            tooltipContentBooking.booking.remoteId
          ),
          this.schedulerSandbox.getBookingFilters(
            tooltipContentBooking.booking.remoteId
          )
        ])
          .pipe(take(1))
          .subscribe(
            ([bookingDto, filterCriteriaForBookingDto]: [
              BookingDto,
              FilterCriteriaForBookingDto
            ]) => {
              tooltipContentBooking.bookingDto = bookingDto;
              tooltipContentBooking.filterCriteriaForBookingDto = filterCriteriaForBookingDto;
              this.applyAndShareTooltipEvent(event);
            },
            error => {
              this.toastUtilService.showError(
                error,
                this.tc + '.FAILED_GET-BOOKING-FOR-TOOLTIP'
              );
            }
          );
        return;
      } else if (event.payload.tooltip.type === TooltipType.POOL_VEHICLE) {
        this.getCurrentUserAndOrganizationThenExecute(
          (currentUser, currentOrganizationId) => {
            const tooltipContentPoolVehicle = event.payload.tooltip
              .content as TooltipContentPoolVehicle;
            this.schedulerSandbox
              .getBatteryStatus(tooltipContentPoolVehicle.vehicle.remoteId)
              .subscribe(
                batteryStatusDto => {
                  tooltipContentPoolVehicle.batteryStatus = ConvertersUtils.convertBatteryStatusDtoToBatteryStatusWithDetail(
                    batteryStatusDto,
                    false
                  );
                  tooltipContentPoolVehicle.showRefreshVehicleLocation = SharedUiUtils.isUserVehicleOwnerOrAdmin(
                    currentUser,
                    tooltipContentPoolVehicle.vehicle.data
                  );
                  this.applyAndShareTooltipEvent(event);
                },
                error => {
                  const batteryStatusDto: BatteryStatusDto = {
                    batteryPercentage: 0,
                    charging: ChargingStatusDto.UNKNOWN,
                    cruisingRange: 0
                  };
                  tooltipContentPoolVehicle.batteryStatus = ConvertersUtils.convertBatteryStatusDtoToBatteryStatusWithDetail(
                    batteryStatusDto,
                    true
                  );
                  tooltipContentPoolVehicle.showRefreshVehicleLocation = SharedUiUtils.isUserVehicleOwnerOrAdmin(
                    currentUser,
                    tooltipContentPoolVehicle.vehicle.data
                  );
                  this.applyAndShareTooltipEvent(event);
                }
              );
          }
        );
        return;
      }
    }
    this.applyAndShareTooltipEvent(event);
  }

  applyAndShareTooltipEvent(tooltipEvent: TooltipEvent): void {
    if (tooltipEvent instanceof AddTooltipEvent) {
      this.tooltipsSub$.next([
        (tooltipEvent as AddTooltipEvent).payload.tooltip
      ]);
    } else if (tooltipEvent instanceof DeleteTooltipEvent) {
      this.tooltipsSub$.next(
        this.tooltipsSub$.value.filter(
          tooltip =>
            tooltip.id !== (tooltipEvent as DeleteTooltipEvent).payload.id
        )
      );
    } else if (tooltipEvent instanceof UpdateTooltipTargetRectEvent) {
      const event: UpdateTooltipTargetRectEvent = tooltipEvent as UpdateTooltipTargetRectEvent;
      const tooltips = this.tooltipsSub$.value.map(tooltip =>
        tooltip.id === event.payload.id
          ? {
              ...tooltip,
              targetRect: {
                ...tooltip.targetRect,
                ...event.payload.newValues
              }
            }
          : tooltip
      );
      this.tooltipsSub$.next(tooltips);
    } else if (tooltipEvent instanceof UpdateTooltipContentValuesEvent) {
      const event: UpdateTooltipContentValuesEvent = tooltipEvent as UpdateTooltipContentValuesEvent;
      const tooltips = this.tooltipsSub$.value.map(tooltip =>
        tooltip.id === event.payload.id
          ? {
              ...tooltip,
              content: {
                ...tooltip.content,
                ...event.payload.newValues
              }
            }
          : tooltip
      );
      this.tooltipsSub$.next(tooltips);
    }
    this.sharedTooltipEvent = tooltipEvent;
  }

  searchStringsInString(searchStrings: string[], text: string): boolean {
    if (!text || text === '') {
      return false;
    }
    const lowerCaseText = text.toLowerCase();
    for (const searchString of searchStrings) {
      if (lowerCaseText.indexOf(searchString) === -1) {
        return false;
      }
    }
    return true;
  }

  getMatchingUserRemoteIds(searchStrings: string[]): Array<string> {
    if (searchStrings && searchStrings.length > 0 && this.userPageDto?.users) {
      return this.userPageDto.users
        .filter(user => {
          let userCompleteName = user.firstName;
          if (userCompleteName && user.lastName) {
            userCompleteName += ' ' + user.lastName;
          }
          return (
            this.searchStringsInString(searchStrings, userCompleteName) ||
            this.searchStringsInString(searchStrings, user.userName)
          );
        })
        .map(user => user.remoteId);
    }
    return [];
  }

  findBookingForUser(
    vehicle: Vehicle,
    bookings: Booking[],
    searchedUserIds: string[]
  ): Booking {
    if (!bookings || bookings.length === 0) {
      return null;
    }
    return bookings.find(booking => {
      if (
        booking.remoteVehicleId === vehicle.remoteId &&
        booking.data &&
        searchedUserIds.indexOf(booking.data.user.remoteId) !== -1
      ) {
        return booking;
      }
      return null;
    });
  }

  hasBookingForUser(
    vehicle: Vehicle,
    bookings: Booking[],
    searchedUserIds: string[]
  ): boolean {
    if (!searchedUserIds || searchedUserIds.length === 0) {
      return false;
    }
    return this.findBookingForUser(vehicle, bookings, searchedUserIds) != null;
  }

  findBookingWithComments(
    vehicle: Vehicle,
    bookings: Booking[],
    searchStrings: string[]
  ): Booking {
    if (!bookings || bookings.length === 0) {
      return null;
    }
    return bookings.find(booking => {
      if (
        booking.remoteVehicleId === vehicle.remoteId &&
        booking.data &&
        this.searchStringsInString(searchStrings, booking.data.comments)
      ) {
        return booking;
      }
      return null;
    });
  }

  hasBookingWithComments(
    vehicle: Vehicle,
    bookings: Booking[],
    searchStrings: string[]
  ): boolean {
    return (
      this.findBookingWithComments(vehicle, bookings, searchStrings) != null
    );
  }

  isVehicleOwner(vehicle: Vehicle, searchedUserIds: string[]): boolean {
    if (!searchedUserIds || searchedUserIds.length === 0) {
      return false;
    }
    return vehicle.data && searchedUserIds.indexOf(vehicle.data.owner) !== -1;
  }

  isVehicleFiltered(
    vehicle: Vehicle,
    bookings: Booking[],
    searchStrings: string[],
    searchedUserIds: string[]
  ): boolean {
    if (!searchStrings || searchStrings.length === 0) {
      return false;
    }
    return (
      !this.searchStringsInString(searchStrings, vehicle.name) &&
      !this.searchStringsInString(searchStrings, vehicle.licensePlate) &&
      !this.searchStringsInString(searchStrings, vehicle.description) &&
      !this.hasBookingForUser(vehicle, bookings, searchedUserIds) &&
      !this.hasBookingWithComments(vehicle, bookings, searchStrings) &&
      !this.isVehicleOwner(vehicle, searchedUserIds)
    );
  }

  onRefreshVehicleLocation(remoteVehicleId: string): void {
    this.schedulerSandbox.refreshVehicleLocation(remoteVehicleId).subscribe(
      gpsLocation => {
        this.toastUtilService.success(
          this.tc + '.SUCCESSFUL_REFRESH-LOCATION',
          true
        );
        if (!!gpsLocation?.address) {
          const address = gpsLocation.address.replace('\n', '\n');
          this.toastUtilService.success(this.tc + '.NEW-LOCATION', true, {
            address
          });
        }
      },
      error => {
        this.toastUtilService.showError(
          error,
          this.tc + '.FAILED_REFRESH-LOCATION'
        );
      }
    );
  }

  onAddBookingForVehicle(remoteVehicleId: string): void {
    this.applyAndShareTooltipEvent(
      new DeleteTooltipEvent(TooltipType.POOL_VEHICLE)
    );
    this.onAddBooking(remoteVehicleId);
  }

  onAddAvailabilityForVehicle(remoteVehicleId: string): void {
    this.applyAndShareTooltipEvent(
      new DeleteTooltipEvent(TooltipType.POOL_VEHICLE)
    );
    this.onAddAvailability(remoteVehicleId);
  }

  onEndVehicleUsage(vehicleId: string): void {
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'END-VEHICLE-PRIVATE-USAGE-DIALOG-TITLE',
      'END-VEHICLE-PRIVATE-USAGE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() =>
          this.schedulerSandbox.endVehicleUsage(vehicleId).pipe(
            take(1),
            catchError(error => of(error))
          )
        ),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(
            v,
            this.tc + '.FAILED_END-VEHICLE-USAGE'
          );
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_END-VEHICLE-USAGE',
            true
          );
          dialog.destroy();
          this.schedulerViewHelper.triggerRefreshAvailabilityEvents();
        }
      });
  }

  isEqualVehicles(oldValue: Vehicle[], newValue: Vehicle[]): boolean {
    if (oldValue?.length === newValue?.length) {
      // Concatanate vehicle ids
      const concat = new Set(
        oldValue
          .map(vehicle => vehicle.remoteId)
          .concat(newValue.map(vehicle => vehicle.remoteId))
      );
      return concat.size === newValue.length;
    }
    return false;
  }

  isEqualDate(oldValue: Date, newValue: Date): boolean {
    return oldValue?.getTime() === newValue?.getTime();
  }

  isEqualPeriod(oldValue: Period, newValue: Period): boolean {
    return (
      this.isEqualDate(oldValue?.start, newValue?.start) &&
      this.isEqualDate(oldValue?.end, newValue?.end)
    );
  }

  isEqualUser(oldValue: BookingUserDto, newValue: BookingUserDto): boolean {
    return oldValue?.remoteId === newValue.remoteId;
  }

  updateVehiclesWithPrivateUsage(
    vehicles: Vehicle[],
    privateUsages: UnavailabilityEvent[]
  ): Vehicle[] {
    return vehicles?.map(vehicle => {
      const privateUsage = privateUsages?.find(
        usage => usage.remoteVehicleId === vehicle.remoteId
      );
      return { ...vehicle, privateUsage };
    });
  }
}
