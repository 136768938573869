import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EditAvailabilityDialogViewComponent } from './views/edit-availability-dialog-view/edit-availability-dialog-view.component';
import { SchedulerCreateBookingDialogViewComponent } from './views/scheduler-create-booking-dialog-view/scheduler-create-booking-dialog-view.component';
import { SchedulerEditBookingDialogViewComponent } from './views/scheduler-edit-booking-dialog-view/scheduler-edit-booking-dialog-view.component';
import { SchedulerEditVehicleDialogViewComponent } from './views/scheduler-edit-vehicle-dialog-view/scheduler-edit-vehicle-dialog-view.component';
import { SchedulerViewComponent } from './views/scheduler-view/scheduler-view.component';
import {
  MissingTranslationHandler,
  TranslateModule
} from '@ngx-translate/core';
import { CustomTranslationHandler } from '@sofico-framework/utils';
import { SchedulerSandbox } from './scheduler.sandbox';
import { BookingService } from '../services/booking.service';
import { AvailabilityService } from '../services/availability.service';
import { SchedulerRoutingModule } from './scheduler-routing.module';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { InputSliderModule } from '@sofico-framework/ui-kit/components/input-slider';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { InputSingleSelectModule } from '@sofico-framework/ui-kit/components/input-single-select';
import { InputDatePickerModule } from '@sofico-framework/ui-kit/components/input-date-picker';
import { InputSwitchModule } from '@sofico-framework/ui-kit/components/input-switch';
import { DropDownMenuModule } from '@sofico-framework/ui-kit/components/drop-down-menu';
import { DialogModule } from '@sofico-framework/ui-kit/components/dialog';

// components
import { CardsAndSchedulersComponent } from './components/cards-and-schedulers/cards-and-schedulers.component';
import { CardsAndSchedulersHeaderComponent } from './components/cards-and-schedulers-header/cards-and-schedulers-header.component';
import { HorizontalSplitterComponent } from './components/horizontal-splitter/horizontal-splitter.component';
import { InformationPanelBookingComponent } from './components/information-panel-booking/information-panel-booking.component';
import { BookingComponent } from './components/booking/booking.component';
import { BookingRequestHighlightedAreaComponent } from './components/booking-request-highlighted-area/booking-request-highlighted-area.component';
import { BookingRequestItemComponent } from './components/booking-request-list/booking-request-item/booking-request-item.component';
import { BookingRequestListComponent } from './components/booking-request-list/booking-request-list/booking-request-list.component';
import { BookingRequestListHeaderComponent } from './components/booking-request-list/booking-request-list-header/booking-request-list-header.component';
import { RequestLineComponent } from './components/booking-request-list/request-line/request-line.component';
import { BookingRequestPartComponent } from './components/booking-request-part/booking-request-part.component';
import { BookingRequestsSchedulerComponent } from './components/booking-requests-scheduler/booking-requests-scheduler.component';
import { BookingSchedulerBodyComponent } from './components/booking-scheduler-body/booking-scheduler-body.component';
import { BookingSchedulerBodyRowComponent } from './components/booking-scheduler-body-row/booking-scheduler-body-row.component';
import { BookingSchedulerBodyScrollboxComponent } from './components/booking-scheduler-body-scrollbox/booking-scheduler-body-scrollbox.component';
import { BookingSchedulerBodySubrowComponent } from './components/booking-scheduler-body-subrow/booking-scheduler-body-subrow.component';
import { BookingSchedulerHeaderComponent } from './components/booking-scheduler-header/booking-scheduler-header.component';
import { BookingSchedulerHeaderDatesComponent } from './components/booking-scheduler-header-dates/booking-scheduler-header-dates.component';
import { BookingSchedulerHighlightedAreaComponent } from './components/booking-scheduler-highlighted-area/booking-scheduler-highlighted-area.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TooltipComponent } from './components/tooltips/tooltip/tooltip.component';
import { TooltipContentDateComponent } from './components/tooltips/tooltip-content-date/tooltip-content-date.component';
import { TooltipContentDragComponent } from './components/tooltips/tooltip-content-drag/tooltip-content-drag.component';
import { TooltipContentPoolVehicleComponent } from './components/tooltips/tooltip-content-pool-vehicle/tooltip-content-pool-vehicle.component';
import { TooltipContentBookingComponent } from './components/tooltips/tooltip-content-booking/tooltip-content-booking.component';
import { TooltipContentUnavailabilityComponent } from './components/tooltips/tooltip-content-unavailability/tooltip-content-unavailability.component';
import { TooltipContentAvailabilityComponent } from './components/tooltips/tooltip-content-availability/tooltip-content-availability.component';
import { VehicleGlobalAvailabilityComponent } from './components/vehicle-global-availability/vehicle-global-availability.component';
import { PoolVehicleCardComponent } from './components/vehicle-card-list/pool-vehicle-card/pool-vehicle-card.component';
import { RequestedVehiclesListComponent } from './components/vehicle-card-list/requested-vehicles-list/requested-vehicles-list.component';
import { VehicleCardFieldsManagerComponent } from './components/vehicle-card-list/vehicle-card-fields-manager/vehicle-card-fields-manager.component';
import { VehicleCardListComponent } from './components/vehicle-card-list/vehicle-card-list/vehicle-card-list.component';
import { VehicleCardListBodyComponent } from './components/vehicle-card-list/vehicle-card-list-body/vehicle-card-list-body.component';
import { VehicleCardListHeaderComponent } from './components/vehicle-card-list/vehicle-card-list-header/vehicle-card-list-header.component';
import { VehicleUnavailabilityComponent } from './components/vehicle-unavailability/vehicle-unavailability.component';
import { VehicleAvailabilityComponent } from './components/vehicle-availability/vehicle-availability.component';
import { BookingSchedulerFiltersComponent } from './components/booking-scheduler-filters/booking-scheduler-filters.component';

// service
import { VehicleService } from './services/vehicle.service';
import { UserSettingsService } from './services/user-settings.service';

// split
import { AngularSplitModule } from 'angular-split';
import { BookingManagementModule } from '../booking-management-ui/booking-management.module';
import { ApplyVehicleFiltersDialogComponent } from './components/apply-vehicle-filters-dialog/apply-vehicle-filters-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { SchedulerAuthorizationGuard } from '../guards/scheduler-authorization.guard';
import { DateHourModule } from '../shared-ui/components/date-hour/date-hour.module';
import { LoadingSpinnerModule } from '../shared-ui/components/loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [
    SchedulerViewComponent,
    CardsAndSchedulersComponent,
    CardsAndSchedulersHeaderComponent,
    HorizontalSplitterComponent,
    InformationPanelBookingComponent,
    BookingComponent,
    BookingRequestHighlightedAreaComponent,
    BookingRequestItemComponent,
    BookingRequestListComponent,
    BookingRequestListHeaderComponent,
    RequestLineComponent,
    BookingRequestPartComponent,
    BookingRequestsSchedulerComponent,
    BookingSchedulerBodyComponent,
    BookingSchedulerBodyRowComponent,
    BookingSchedulerBodyScrollboxComponent,
    BookingSchedulerBodySubrowComponent,
    BookingSchedulerHeaderComponent,
    BookingSchedulerHeaderDatesComponent,
    BookingSchedulerHighlightedAreaComponent,
    ToolbarComponent,
    TooltipComponent,
    TooltipContentDateComponent,
    TooltipContentDragComponent,
    TooltipContentPoolVehicleComponent,
    TooltipContentBookingComponent,
    TooltipContentUnavailabilityComponent,
    TooltipContentAvailabilityComponent,
    VehicleGlobalAvailabilityComponent,
    PoolVehicleCardComponent,
    RequestedVehiclesListComponent,
    VehicleCardFieldsManagerComponent,
    VehicleCardListComponent,
    VehicleCardListBodyComponent,
    VehicleCardListHeaderComponent,
    VehicleUnavailabilityComponent,
    VehicleAvailabilityComponent,
    BookingSchedulerFiltersComponent,
    ApplyVehicleFiltersDialogComponent,
    SchedulerEditVehicleDialogViewComponent,
    SchedulerEditBookingDialogViewComponent,
    SchedulerCreateBookingDialogViewComponent,
    EditAvailabilityDialogViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    SchedulerRoutingModule,
    // split
    AngularSplitModule.forChild(),
    BookingManagementModule,
    LicensePlateComponentModule,
    InputSliderModule,
    SharedUiModule,
    GoogleMapsModule,
    DateHourModule,
    LoadingSpinnerModule,
    SvgIconModule,
    ButtonModule,
    InputSingleSelectModule,
    InputDatePickerModule,
    InputSwitchModule,
    DropDownMenuModule,
    DialogModule,
    NzToolTipModule
  ],
  entryComponents: [ApplyVehicleFiltersDialogComponent],
  exports: [SchedulerViewComponent],
  providers: [
    SchedulerSandbox,
    BookingService,
    VehicleService,
    UserSettingsService,
    AvailabilityService,
    SchedulerAuthorizationGuard
  ]
})
export class SchedulerModule {}
