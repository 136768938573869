import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { InputTextComponent } from '@sofico-framework/ui-kit/components/input-text';
import { BehaviorSubject } from 'rxjs';
import { GpsLocationDto } from '../../../../../client';
import { AppConstants } from '../../../app.constants';
import SharedUiUtils from '../../utils/shared-ui.utils';

@Component({
  selector: 'sof-location-search-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.SEARCH-FOR-LOCATION' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill flex-column">
          <div class="row">
            <div class="form-group col-sm">
              <div class="form-group-input-label">
                {{ tc + '.LOCATION' | translate }}
              </div>
              <sof-input-text
                #autocompleteAddress
                [value]="gpsLocation?.address"
                (input)="onSearchChange($event)"
              ></sof-input-text>
            </div>
          </div>
          <div class="row map-container">
            <google-map
              [options]="options"
              class="col-sm"
              width="100%"
              height="100%"
              [center]="selectedLocation$ | async | gpsLocationToLatLngLiteral"
            >
              <map-marker
                *ngIf="(selectedLocation$ | async) !== null"
                [position]="
                  selectedLocation$ | async | gpsLocationToLatLngLiteral
                "
                [options]="markerOptions"
              >
              </map-marker>
            </google-map>
          </div>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.DIALOG-CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="confirmDialog.emit(this.selectedLocation$.value)"
            class="general-action-button main-action"
            [disabled]="(selectedLocation$ | async) === null"
          >
            {{ tc + '.DIALOG-CONFIRM' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./location-search-dialog.component.scss']
})
export class LocationSearchDialogComponent implements OnInit, AfterViewInit {
  @Input() gpsLocation: GpsLocationDto;
  @Input() tc: string;
  @Input() locationSearchTitleLabel: string;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  confirmDialog: EventEmitter<GpsLocationDto> = new EventEmitter<GpsLocationDto>();

  options: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions;

  selectedLocation$: BehaviorSubject<GpsLocationDto>;

  @ViewChild('autocompleteAddress')
  autocompleteAddress: InputTextComponent;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.options = SharedUiUtils.getDefaultMapOptions();
    this.markerOptions = {
      icon: AppConstants.ASSETS_ICON_CAR_AVAILABLE_MARKER
    };
    if (!!this.gpsLocation) {
      this.selectedLocation$ = new BehaviorSubject<GpsLocationDto>(
        this.gpsLocation
      );
    } else {
      this.selectedLocation$ = new BehaviorSubject<GpsLocationDto>(null);
    }
  }

  addBreakingLines(
    str: string,
    searchValue: string,
    replaceValue: string
  ): string {
    if (!str) {
      return str;
    }
    let tmp = str;
    // TODO - Temp fix - Mobile app is not supporting address with more than 2 lines
    // while (tmp.indexOf(searchValue) !== -1) {
    tmp = tmp.replace(searchValue, replaceValue);
    // }
    return tmp;
  }

  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteAddress.htmlInputElement.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      this.selectedLocation$.next({
        address: this.addBreakingLines(place.formatted_address, ', ', ',\n'),
        gpsCoordinateDto: {
          longitude: place.geometry.location.lng(),
          latitude: place.geometry.location.lat()
        }
      });
      // 'place_changed' is not an Angular event, call detectChanges to enforce change detection
      this.cdRef.detectChanges();
    });
  }

  onSearchChange(event): void {
    this.selectedLocation$.next(null);
  }
}
