import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { NgModule } from '@angular/core';
import { BookingsViewComponent } from './views/bookings-view/bookings-view.component';
import { BookingsAuthorizationGuard } from '../guards/bookings-authorization.guard';
import { CreateBookingDialogViewComponent } from './views/create-booking-dialog-view/create-booking-dialog-view.component';
import { EditBookingDialogViewComponent } from './views/edit-booking-dialog-view/edit-booking-dialog-view.component';

const routes: Routes = [
  {
    path: '',
    component: BookingsViewComponent,
    canActivate: [AuthenticatedGuard, BookingsAuthorizationGuard],
    children: [
      {
        path: 'create-booking',
        component: CreateBookingDialogViewComponent,
        canActivate: [AuthenticatedGuard, BookingsAuthorizationGuard]
      },
      {
        path: 'edit-booking',
        component: EditBookingDialogViewComponent,
        canActivate: [AuthenticatedGuard, BookingsAuthorizationGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingManagementRoutingModule {}
