import { Pipe, PipeTransform } from '@angular/core';
import { BookingUserDto } from '../../../../client';
import SharedUiUtils from '../utils/shared-ui.utils';

@Pipe({ name: 'isAdmin' })
export class IsAdminPipe implements PipeTransform {
  transform(userDto: BookingUserDto): boolean {
    return SharedUiUtils.isUserAdmin(userDto);
  }
}
