import { Injectable } from '@angular/core';
import {
  CreateDriverGradeFilterRequestDto,
  CreateDriverGradeRequestDto,
  CreateOrganizationRequestDto,
  DriverGradeDto,
  DriverGradeFilterDto,
  DriverGradeFiltersPageDto,
  OrganizationControllerImplService,
  OrganizationDto,
  OrganizationPageDto,
  OrganizationReferencePageDto,
  PutUserInOrganizationRequestDto,
  UpdateDriverGradeFilterRequestDto,
  UpdateDriverGradeRequestDto,
  UpdateOrganizationRequestDto,
  UpdateVehicleGroupRequestDto,
  VehicleGroupDto
} from '../../../client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(
    private organizationControllerImplService: OrganizationControllerImplService
  ) {}

  createOrganization(
    createOrganizationRequest: CreateOrganizationRequestDto
  ): Observable<OrganizationDto> {
    return this.organizationControllerImplService.createOrganization(
      createOrganizationRequest
    );
  }

  updateOrganization(
    updateOrganizationRequest: UpdateOrganizationRequestDto
  ): Observable<OrganizationDto> {
    return this.organizationControllerImplService.updateOrganization(
      updateOrganizationRequest
    );
  }

  putUserInOrganization(
    organizationId: string,
    userId: string,
    request: PutUserInOrganizationRequestDto
  ): Observable<any> {
    return this.organizationControllerImplService.putUserInOrganization(
      organizationId,
      userId,
      request
    );
  }

  addUserToDriverGrade(
    driverGradeId: string,
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationControllerImplService.addUserToDriverGrade(
      driverGradeId,
      organizationId,
      userId
    );
  }

  createDriverGrade(
    organizationId: string,
    createDriverGradeRequestDto: CreateDriverGradeRequestDto
  ): Observable<DriverGradeDto> {
    return this.organizationControllerImplService.createDriverGrade(
      organizationId,
      createDriverGradeRequestDto
    );
  }

  updateDriverGrade(
    updateDriverGradeRequestDto: UpdateDriverGradeRequestDto
  ): Observable<DriverGradeDto> {
    return this.organizationControllerImplService.updateDriverGrade(
      updateDriverGradeRequestDto
    );
  }

  getOrganizations(): Observable<OrganizationPageDto> {
    return this.organizationControllerImplService.getOrganizations();
  }

  getOrganization(organizationId: string): Observable<OrganizationDto> {
    return this.organizationControllerImplService.getOrganization(
      organizationId
    );
  }

  deleteUserFromOrganization(
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationControllerImplService.deleteUserFromOrganization(
      organizationId,
      userId
    );
  }

  deleteOrganization(organizationId: string): Observable<any> {
    return this.organizationControllerImplService.deleteOrganization(
      organizationId
    );
  }

  deleteUserFromDriverGrade(
    driverGradeId: string,
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationControllerImplService.deleteUserFromDriverGrade(
      driverGradeId,
      organizationId,
      userId
    );
  }

  deleteDriverGrade(
    driverGradeId: string,
    organizationId: string
  ): Observable<any> {
    return this.organizationControllerImplService.deleteDriverGrade(
      driverGradeId,
      organizationId
    );
  }

  getDriverGradeFilters(
    driverGradeId: string,
    organizationId: string
  ): Observable<DriverGradeFiltersPageDto> {
    return this.organizationControllerImplService.getDriverGradeFilters(
      driverGradeId,
      organizationId
    );
  }

  createDriverGradeFilter(
    driverGradeId: string,
    organizationId: string,
    createDriverGradeFilterRequestDto: CreateDriverGradeFilterRequestDto
  ): Observable<DriverGradeFilterDto> {
    return this.organizationControllerImplService.createDriverGradeFilter(
      driverGradeId,
      organizationId,
      createDriverGradeFilterRequestDto
    );
  }

  deleteDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string
  ): Observable<any> {
    return this.organizationControllerImplService.deleteDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId
    );
  }

  updateDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    updateDriverGradeFilterRequestDto: UpdateDriverGradeFilterRequestDto
  ): Observable<DriverGradeFilterDto> {
    return this.organizationControllerImplService.updateDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      updateDriverGradeFilterRequestDto
    );
  }

  addVehicleGroupToDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    vehicleGroupId: string
  ): Observable<any> {
    return this.organizationControllerImplService.addVehicleGroupToDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      vehicleGroupId
    );
  }

  deleteVehicleGroupFromDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    vehicleGroupId: string
  ): Observable<any> {
    return this.organizationControllerImplService.deleteVehicleGroupFromDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      vehicleGroupId
    );
  }

  getOrganizationsReferences(): Observable<OrganizationReferencePageDto> {
    return this.organizationControllerImplService.getOrganizationsReferences();
  }

  updateVehicleGroup(
    updateVehicleGroupRequestDto: UpdateVehicleGroupRequestDto
  ): Observable<VehicleGroupDto> {
    return this.organizationControllerImplService.updateVehicleGroup(
      updateVehicleGroupRequestDto
    );
  }
}
