import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationDto } from  '../../../../../client';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';

@Component({
  selector: 'sof-organization-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div
        class="sof-card"
        *ngFor="let organization of organizations"
        (click)="displayOrganizationDetail(organization)"
      >
        <sof-organization-avatar
          [organization]="organization"
        ></sof-organization-avatar>
        <sof-organization-short-info
          [organization]="organization"
        ></sof-organization-short-info>
        <sof-user-avatars-bar
          [users]="organization | organizationMembersToUsers"
          [maxAvatarsCount]="MAX_AVATARS"
        >
        </sof-user-avatars-bar>
        <div class="additional-info">
          <div *ngIf="organization?.members?.length - MAX_AVATARS > 0">
            {{
              tc + '.AND-OTHER-MEMBERS'
                | translate
                  : { count: organization?.members?.length - MAX_AVATARS }
            }}
          </div>
        </div>
        <button
          sofButton
          class="round-icon-button"
          aria-label="Edit organization"
        >
          <sof-svg-icon icon="batt-icon-chevron-right" size="24"></sof-svg-icon>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent {
  MAX_AVATARS = 7;
  @Input() tc: string;
  @Input() organizations: OrganizationDto[];

  constructor(private osb: OrganizationManagementSandbox) {}

  displayOrganizationDetail(organization: OrganizationDto): void {
    this.osb.navigateToDetailOrganizationScreen(organization.id);
  }
}
