import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateUtil } from '../../../helpers/date-util';

@Component({
  selector: 'sof-date-hour',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div
        class="date"
        [ngStyle]="ngStyle"
        [ngClass]="{ highlighted: highlighted }"
      >
        <div class="day">
          {{ formattedDate }}
        </div>
        <div class="hour">
          {{ formattedTime }}
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./date-hour.component.scss']
})
export class DateHourComponent {
  @Input()
  set date(date: Date | string) {
    if (!!date) {
      if (date instanceof Date) {
        this.localDate = date;
      } else {
        this.localDate = DateUtil.convertToDate(date);
      }
      this.formattedDate = DateUtil.formatToDate(this.localDate);
      this.formattedTime = DateUtil.formatToHour(this.localDate);
    } else {
      this.formattedDate = null;
      this.formattedTime = null;
    }
  }

  @Input()
  set direction(direction: 'column' | 'row') {
    this.ngStyle = {
      flexDirection: direction,
      gridGap: direction === 'column' ? 'inherit' : '0.5em'
    };
  }

  @Input() highlighted: boolean;

  ngStyle: any = null;
  localDate: Date = null;
  formattedDate: string = null;
  formattedTime: string = null;
}
