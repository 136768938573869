import { Pipe, PipeTransform } from '@angular/core';
import {
  BookingUserDto,
  UserReferenceDto
} from '../../../../client';

@Pipe({ name: 'toUserDisplayName' })
export class ToUserDisplayNamePipe implements PipeTransform {
  transform(userDto: UserReferenceDto | BookingUserDto): string {
    if (!userDto) {
      return null;
    }
    return !userDto.displayName ? userDto.userName : userDto.displayName;
  }
}
