import { Pipe, PipeTransform } from '@angular/core';
import { UserImage } from '../components/user-avatar/types/user-image.type';
import {
  BookingUserDto,
  UserReferenceDto
} from '../../../../client';

@Pipe({ name: 'toUserImage' })
export class ToUserImagePipe implements PipeTransform {
  transform(
    userDto: BookingUserDto | UserReferenceDto,
    size?: '30' | '32' | '40' | '45' | '48'
  ): UserImage {
    if (!userDto) {
      return null;
    }
    return {
      imageUrl: userDto.imageUrl,
      imageSize: size,
      userName: userDto.userName,
      displayName: userDto.displayName
    };
  }
}
