import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { VehicleCardFieldsToDisplay } from '../../../../types/vehicle-card-fields-to-display.type';
import { SchedulerSetting } from '../../../../scheduler.setting';

@Component({
  selector: 'sof-vehicle-card-list-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="field-manager-btn"
      (click)="openFieldManager = !openFieldManager"
    >
      <i *ngIf="!openFieldManager" class="fa fa-bars" aria-hidden="true"></i>
      <i *ngIf="openFieldManager" class="fa fa-times" aria-hidden="true"></i>
    </div>

    <div class="infos">
      <span class="number">
        {{ numberOfVehicles }}
      </span>
      &nbsp; vehicles
    </div>

    <sof-vehicle-card-fields-manager
      *ngIf="openFieldManager"
      [style.top.px]="elementHeightPx"
      [fieldsToDisplay]="fieldsToDisplay"
      [vehicleFieldsAndLabels]="vehicleFieldsAndLabels"
      (newFieldsToDisplay)="newFieldsToDisplay.emit($event)"
    >
    </sof-vehicle-card-fields-manager>
  `,
  styleUrls: ['./vehicle-card-list-header.component.scss']
})
export class VehicleCardListHeaderComponent {
  @Input() numberOfVehicles: number;
  @Input() fieldsToDisplay: VehicleCardFieldsToDisplay;
  @Input()
  vehicleFieldsAndLabels: {
    poolVehicle: { field: string; label: string }[];
  };
  @Output()
  newFieldsToDisplay: EventEmitter<VehicleCardFieldsToDisplay> = new EventEmitter<VehicleCardFieldsToDisplay>();

  openFieldManager = false;
  elementHeightPx = SchedulerSetting.schedulerHeightPx;
}
