import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  BookingControllerImplService,
  BookingDto,
  BookingEventPageDto,
  BookingsPageDto,
  CancelBookingRequestDto,
  ChangeApprovalStateRequestDto,
  CreateBookingRequestDto,
  CreateVehicleRequestDto,
  EnumValueDto,
  EquipmentPageDto,
  FilterCriteriaForBookingDto,
  IntentPageDto,
  ReplaceBookingRequestDto,
  SearchBookingEventsRequestDto,
  SearchBookingRequestDto,
  UpdateBookingRequestDto,
  UpdateVehicleRequestDto,
  VehicleDto,
  VehicleModelDto,
  VehicleModelsPageDto,
  VehiclePageDto,
  VehicleSearchCriteriaDto
} from  '../../../client';
import { VehicleIntent } from '../enums/vehicle-intent.enum';
import { Intent } from '../types/intent.type';
import { KeyLabel } from '../types/key-label.type';
import { VehicleBrand } from '../types/vehicle-brand.type';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  vehicleColors$: Observable<
    Array<EnumValueDto>
  > = this.bookingControllerImplService.getVehicleColors().pipe(
    map(vehicleColorsPageDto => {
      return vehicleColorsPageDto.vehicleColors;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  vehicleBodyStyles$: Observable<
    Array<EnumValueDto>
  > = this.bookingControllerImplService.getVehicleBodyStyles().pipe(
    map(vehicleBodyStylesPageDto => {
      return vehicleBodyStylesPageDto.vehicleBodyStyles;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  vehicleBrands$: Observable<
    Array<VehicleBrand>
  > = this.bookingControllerImplService.getVehicleModels().pipe(
    map(vehicleModelsPageDto => {
      const brands = new Array<VehicleBrand>();
      vehicleModelsPageDto.vehicleModels.forEach(vehicleModel => {
        let brand: VehicleBrand = brands.find(
          b => b.name === vehicleModel.brand
        );
        if (!brand) {
          brand = {
            name: vehicleModel.brand,
            models: new Array<VehicleModelDto>()
          };
          brands.push(brand);
        }
        brand.models.push(vehicleModel);
      });
      return brands;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  vehicleModels$: Observable<
    Array<KeyLabel>
  > = this.bookingControllerImplService.getVehicleModels().pipe(
    map(vehicleModelsPageDto => {
      const vehicleModels = new Array<KeyLabel>();
      vehicleModelsPageDto.vehicleModels.forEach(vehicleModel => {
        vehicleModels.push({
          key: vehicleModel.id,
          label: vehicleModel.brand + ' ' + vehicleModel.name
        });
      });
      return vehicleModels;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  vehicleEquipments$: Observable<
    Array<KeyLabel>
  > = this.bookingControllerImplService.getEquipment().pipe(
    map(equipmentPageDto => {
      const equipments = new Array<KeyLabel>();
      equipmentPageDto.equipment.forEach(equipment => {
        equipments.push({
          key: equipment.enumId,
          label: equipment.description
        });
      });
      return equipments;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  intents$: Observable<
    Array<Intent>
  > = this.bookingControllerImplService.getIntents().pipe(
    map(intentPageDto => {
      const intents = new Array<Intent>();
      intentPageDto.intents.forEach(intent => {
        intents.push({
          enumId: intent.enumId,
          label: intent.description,
          svgIcon: this.getIntentSvgIcon(intent.enumId)
        });
      });
      return intents;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  // TODO - Temporary solution - To be removed when vehicle search and Dtos will be updated
  allVehicles$: Observable<VehiclePageDto> = this.bookingControllerImplService
    .getAllVehicles()
    .pipe(
      map(vehiclePage => {
        vehiclePage.vehicles = vehiclePage.vehicles?.sort(this.sortOnName);
        return vehiclePage;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  constructor(
    private bookingControllerImplService: BookingControllerImplService
  ) {}

  fetchVehicles(): Observable<VehiclePageDto> {
    return this.bookingControllerImplService.getAllVehicles();
  }

  createVehicleV1(vehicleInfo: any): Observable<VehicleDto> {
    const createVehicleRequest: CreateVehicleRequestDto = {
      id: vehicleInfo.id,
      name: vehicleInfo.name,
      licensePlate: vehicleInfo.licensePlate,
      description: vehicleInfo.description,

      address: vehicleInfo.address,
      homePosition: vehicleInfo.homePosition,
      lastAddress: vehicleInfo.lastAddress,
      lastPosition: vehicleInfo.lastPosition,
      lastPositionTimestamp: '',

      owner: vehicleInfo.owner,
      timeZone: vehicleInfo.timeZone,
      // imageUrl: vehicleInfo.ImageId() == null ? null : IMAGE_URL + vehicleInfo.ImageId,

      seats: vehicleInfo.seats,
      dayPrice: vehicleInfo.dayPrice,
      hourPrice: vehicleInfo.hourPrice,
      kilometerPrice: vehicleInfo.kilometerPrice,
      rentalType: vehicleInfo.rentalType,

      permissions: vehicleInfo.permissions,

      equipmentIds: vehicleInfo.equipmentIds,
      intentIds: vehicleInfo.intentIds,
      details: vehicleInfo.details,

      automaticBookingApproval: vehicleInfo.automaticBookingApproval,
      alwaysAvailable: vehicleInfo.alwaysAvailable,
      electricRange: vehicleInfo.electricRange
    };

    return this.bookingControllerImplService.createVehicle(
      createVehicleRequest
    );
  }

  createVehicleV2(request: CreateVehicleRequestDto): Observable<VehicleDto> {
    return this.bookingControllerImplService.createVehicle(request);
  }

  // TODO, call to delete form vehicles?, currently we only have delete telematics
  /*deleteVehicle(vehicleId: string): Observable<any> {

  }*/

  getVehicle(vehicleId: string): Observable<VehicleDto> {
    return this.bookingControllerImplService.getVehicle(vehicleId);
  }

  updateVehicleV1(vehicleInfo: any): Observable<VehicleDto> {
    const updateVehicleRequest: UpdateVehicleRequestDto = {
      name: vehicleInfo.name,
      licensePlate: vehicleInfo.licensePlate,
      description: vehicleInfo.description,

      address: vehicleInfo.address,
      homePosition: vehicleInfo.homePosition,

      lastAddress: vehicleInfo.lastAddress,
      lastPosition: vehicleInfo.lastPosition,
      lastPositionTimestamp: '',

      owner: vehicleInfo.owner,
      timeZone: vehicleInfo.timeZone,
      // imageUrl: vehicleInfo.ImageId() == null ? null : IMAGE_URL + vehicleInfo.ImageId,

      seats: vehicleInfo.seats,
      dayPrice: vehicleInfo.dayPrice,
      hourPrice: vehicleInfo.hourPrice,
      kilometerPrice: vehicleInfo.kilometerPrice,
      rentalType: vehicleInfo.rentalType,

      permissions: vehicleInfo.permissions,

      equipmentIds: vehicleInfo.equipmentIds,
      intentIds: vehicleInfo.intentIds,
      details: vehicleInfo.details,

      automaticBookingApproval: vehicleInfo.automaticBookingApproval,
      alwaysAvailable: vehicleInfo.alwaysAvailable,
      electricRange: vehicleInfo.electricRange
    };

    return this.bookingControllerImplService.updateVehicle(
      vehicleInfo.id,
      updateVehicleRequest
    );
  }

  updateVehicleV2(
    vehicleId: string,
    request: UpdateVehicleRequestDto
  ): Observable<VehicleDto> {
    return this.bookingControllerImplService.updateVehicle(vehicleId, request);
  }

  uploadVehicleImage(vehicleId: string, file: File): Observable<VehicleDto> {
    return this.bookingControllerImplService.uploadVehicleImage(
      vehicleId,
      file
    );
  }

  searchBookings(
    searchBookingRequestDto: SearchBookingRequestDto
  ): Observable<BookingsPageDto> {
    return this.bookingControllerImplService.searchBookings(
      searchBookingRequestDto
    );
  }

  getBooking(bookingId: string): any {
    return this.bookingControllerImplService.getBooking(bookingId);
  }

  createBooking(
    createBookingRequest: CreateBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingControllerImplService.createBooking(
      createBookingRequest
    );
  }

  updateBooking(
    bookingId: string,
    updateBookingRequest: UpdateBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingControllerImplService.updateBooking(
      bookingId,
      updateBookingRequest
    );
  }

  cancelBooking(
    bookingId: string,
    request: CancelBookingRequestDto
  ): Observable<BookingDto> {
    return this.bookingControllerImplService.cancelBooking(bookingId, request);
  }

  getIntents(): Observable<IntentPageDto> {
    return this.bookingControllerImplService.getIntents();
  }

  getEquipments(): Observable<EquipmentPageDto> {
    return this.bookingControllerImplService.getEquipment();
  }

  getVehicleModels(): Observable<VehicleModelsPageDto> {
    return this.bookingControllerImplService.getVehicleModels();
  }

  searchVehicles(
    vehicleSearchCriteriaDto: VehicleSearchCriteriaDto
  ): Observable<VehiclePageDto> {
    return this.bookingControllerImplService.searchVehicles(
      vehicleSearchCriteriaDto
    );
  }

  getBookingFilters(
    bookingId: string
  ): Observable<FilterCriteriaForBookingDto> {
    return this.bookingControllerImplService.getBookingFilters(bookingId);
  }

  updateBookingApprovalState(
    bookingId: string,
    changeApprovalStateRequest: ChangeApprovalStateRequestDto
  ): Observable<BookingDto> {
    return this.bookingControllerImplService.updateBookingApprovalState(
      bookingId,
      changeApprovalStateRequest
    );
  }

  searchBookingEventsForBooking(
    bookingId: string
  ): Observable<BookingEventPageDto> {
    const request: SearchBookingEventsRequestDto = {
      bookingId
    };
    return this.bookingControllerImplService.searchBookingEvents(request);
  }

  replaceBooking(request: ReplaceBookingRequestDto): Observable<BookingDto> {
    return this.bookingControllerImplService.replaceBooking(request);
  }

  private getIntentSvgIcon(enumId: string): string {
    if (enumId === VehicleIntent.CITY) {
      return 'batt-icon-intent-city';
    }
    if (enumId === VehicleIntent.FAMILY) {
      return 'batt-icon-intent-family';
    }
    if (enumId === VehicleIntent.GROCERIES) {
      return 'batt-icon-intent-groceries';
    }
    if (enumId === VehicleIntent.LUXURY) {
      return 'batt-icon-intent-luxury';
    }
    if (enumId === VehicleIntent.TRANSPORT) {
      return 'batt-icon-intent-transport';
    }
    return 'batt-icon-intent-travel';
  }

  // TODO - To be removed when allVehicles$ will be removed
  sortOnName(a: VehicleDto, b: VehicleDto): number {
    return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase());
  }
}
