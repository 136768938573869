import { Rect } from '../../types/rect.type';
import { Pos } from '../../types/pos.type';
import { RelativePos } from '../../types/relative-pos.type';
import { Size } from '../../types/size.type';

// return true if the rect is inside the rect container
export function rectInside(rect: Rect, container: Rect): boolean {
  // if two opposite corners are in the rect container, the rect is also
  return (
    pointInside({ top: rect.top, left: rect.left }, container) &&
    pointInside(
      { top: rect.top + rect.height, left: rect.left + rect.width },
      container
    )
  );
}

// return true if the point is inside the rect
export function pointInside(point: Pos, container: Rect): boolean {
  return (
    container.left <= point.left &&
    point.left <= container.left + container.width &&
    container.top <= point.top &&
    point.top <= container.top + container.height
  );
}

// return the absolute position of a point, according  to the relative position given
export function getPointPosOfRect(rect: Rect, relativePos: RelativePos): Pos {
  switch (relativePos) {
    case RelativePos.CENTER:
      return {
        top: rect.top + rect.height / 2,
        left: rect.left + rect.width / 2
      };
    case RelativePos.TOP:
      return {
        top: rect.top,
        left: rect.left + rect.width / 2
      };
    case RelativePos.TOP_RIGHT:
      return {
        top: rect.top,
        left: rect.left + rect.width
      };
    case RelativePos.RIGHT:
      return {
        top: rect.top + rect.height / 2,
        left: rect.left + rect.width
      };
    case RelativePos.BOTTOM_RIGHT:
      return {
        top: rect.top + rect.height,
        left: rect.left + rect.width
      };
    case RelativePos.BOTTOM:
      return {
        top: rect.top + rect.height,
        left: rect.left + rect.width / 2
      };
    case RelativePos.BOTTOM_LEFT:
      return {
        top: rect.top + rect.height,
        left: rect.left
      };
    case RelativePos.LEFT:
      return {
        top: rect.top + rect.height / 2,
        left: rect.left
      };
    case RelativePos.TOP_LEFT:
      return {
        top: rect.top,
        left: rect.left
      };
  }
}

// give the absolute position of a rectangle from a point, according to the given relative position
export function getRectPosFromPoint(
  pointPos: Pos,
  rectSize: Size,
  relativePos: RelativePos
): Pos {
  switch (relativePos) {
    case RelativePos.CENTER:
      return {
        top: pointPos.top - rectSize.height / 2,
        left: pointPos.left - rectSize.width / 2
      };
    case RelativePos.TOP:
      return {
        top: pointPos.top,
        left: pointPos.left - rectSize.width / 2
      };
    case RelativePos.TOP_RIGHT:
      return {
        top: pointPos.top,
        left: pointPos.left - rectSize.width
      };
    case RelativePos.RIGHT:
      return {
        top: pointPos.top - rectSize.height / 2,
        left: pointPos.left - rectSize.width
      };
    case RelativePos.BOTTOM_RIGHT:
      return {
        top: pointPos.top - rectSize.height,
        left: pointPos.left - rectSize.width
      };
    case RelativePos.BOTTOM:
      return {
        top: pointPos.top - rectSize.height,
        left: pointPos.left - rectSize.width / 2
      };
    case RelativePos.BOTTOM_LEFT:
      return {
        top: pointPos.top - rectSize.height,
        left: pointPos.left
      };
    case RelativePos.LEFT:
      return {
        top: pointPos.top - rectSize.height / 2,
        left: pointPos.left
      };
    case RelativePos.TOP_LEFT:
      return {
        top: pointPos.top,
        left: pointPos.left
      };
  }
}
