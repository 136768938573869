import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { SortingOrderConfig } from '@sofico-framework/utils';
import { BookingUserDto } from '../../../../../../../client';

@Component({
  selector: 'sof-user-search-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-result-found-label
      [tc]="tc"
      [resultCount]="users?.length"
      noResultLabel="NO-USER-FOUND"
      oneResultLabel="ONE-USER-FOUND"
      multipleResultsLabel="MULTIPLE-USERS-FOUND"
    ></sof-result-found-label>
    <div class="user-list-container">
      <div
        *ngFor="
          let user of users | sofSort: sortingUserDisplayName;
          trackBy: trackByFn
        "
        class="sof-card"
        [class.cursor-pointer]="canEditUser"
        (click)="onCardClick(user)"
      >
        <div class="d-flex align-items-center gg-05 flex1">
          <sof-user-avatar [userImage]="user | toUserImage"> </sof-user-avatar>
          <div>
            {{ user?.displayName }}
          </div>
        </div>
        <!-- TODO - Waiting for backend change to have this attribute on BookingUserDto
        <div class="flex1">
          {{ user.email }}
        </div>
-->
        <div class="flex1">
          {{ user.remoteId }}
        </div>
        <div
          *ngIf="canSendOnboardingMail || canEditUser"
          class="d-flex gg-05 pr-2"
        >
          <button
            *ngIf="canSendOnboardingMail"
            sofButton
            class="round-icon-button primary"
            [attr.aria-label]="tc + '.SEND-ONBOARDING-EMAIL' | translate"
            (click)="sendOnboardingMail.emit(user); $event.stopPropagation()"
          >
            <sof-svg-icon icon="batt-icon-envelope" size="24"></sof-svg-icon>
          </button>
          <button
            *ngIf="canEditUser"
            sofButton
            class="round-icon-button"
            [attr.aria-label]="tc + '.EDIT-USER' | translate"
            (click)="editUser.emit(user); $event.stopPropagation()"
          >
            <sof-svg-icon
              *ngIf="showSelectButtonAsPencil"
              icon="batt-icon-pencil"
              size="24"
            ></sof-svg-icon>
            <sof-svg-icon
              *ngIf="!showSelectButtonAsPencil"
              icon="batt-icon-chevron-right"
              size="24"
            ></sof-svg-icon>
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./user-search-list.component.scss']
})
export class UserSearchListComponent {
  @Input() tc: string;
  @Input() users: Array<BookingUserDto>;
  @Input() canSendOnboardingMail;
  @Input() canEditUser;
  @Input() showSelectButtonAsPencil;
  @Output()
  editUser: EventEmitter<BookingUserDto> = new EventEmitter<BookingUserDto>();
  @Output()
  sendOnboardingMail: EventEmitter<BookingUserDto> = new EventEmitter<BookingUserDto>();

  sortingUserDisplayName: SortingOrderConfig<BookingUserDto> = {
    prop: 'displayName'
  };

  trackByFn = i => i;

  onCardClick(user: BookingUserDto): void {
    if (this.canEditUser) {
      this.editUser.emit(user);
    }
  }
}
