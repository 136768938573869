import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCheckboxModule } from '@sofico-framework/ui-kit/components/input-checkbox';
import { IntentListComponent } from './intent-list.component';

@NgModule({
  imports: [CommonModule, InputCheckboxModule],
  declarations: [IntentListComponent],
  exports: [IntentListComponent]
})
export class IntentListModule {}
