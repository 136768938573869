import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  EnumValueDto,
  VehicleModelDto
} from '../../../../../client';
import { VehicleBrand } from '../../../types/vehicle-brand.type';
import SharedUiUtils from '../../utils/shared-ui.utils';

@Component({
  selector: 'sof-vehicle-dialog-detail-tab-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-form
      #vehicleDetailForm
      [formGroup]="detailForm"
      [tc]="tc"
      [errorMap]="errorMap"
      class="vehicle-dialog-form"
    >
      <div class="column-container">
        <div class="col-form">
          <div class="container new-data">
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.AVERAGE-CONSUMPTION' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.averageConsumption"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.BODY-STYLE' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="detailForm?.controls?.bodyStyle"
                  [options]="vehicleBodyStyles"
                  [selectorValue]="enumValueFn"
                  [selectorLabel]="enumLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.BRAND' | translate"
                class="col-sm"
              >
                <sof-input-text-autocomplete
                  [sofInput]
                  [formControl]="detailForm?.controls?.brand"
                  [options]="brandsFiltered"
                  [selectorValue]="brandValueFn"
                  [selectorLabel]="brandLabelFn"
                  (changeValue)="newBrandSearchText.emit($event)"
                ></sof-input-text-autocomplete>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.MODEL-NAME' | translate"
                class="col-sm"
              >
                <sof-input-text-autocomplete
                  [sofInput]
                  [formControl]="detailForm?.controls?.modelName"
                  [options]="vehicleModelsForBrandFiltered"
                  [selectorValue]="vehicleModelValueFn"
                  [selectorLabel]="vehicleModelLabelFn"
                  (changeValue)="newModelSearchText.emit($event)"
                ></sof-input-text-autocomplete>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.DOORS' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.doors"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.EXTERIOR-COLOR' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="detailForm?.controls?.exteriorColor"
                  [clearable]="false"
                  [options]="vehicleColors"
                  [selectorValue]="colorValueFn"
                  [selectorLabel]="colorLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.RIM-SIZE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.rimSize"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.LUGGAGE-CAPACITY' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.luggageCapacity"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
          </div>
        </div>
        <div class="col-form">
          <div class="container new-data">
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.BATTERY-CAPACITY' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.batteryCapacityKWh"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.POWER' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.powerKW"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.CHARGE-SPEED-AC' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.chargeSpeedAC"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.CHARGE-SPEED-DC' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.chargeSpeedDC"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.MINIMUM-RENTAL-HOURS' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.minimumRentalHours"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.ONBOARDING-TIME-MINUTES' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.onboardingTimeMinutes"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.PREPARATION-TIME-MINUTES' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.preparationTimeMinutes"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.MINIMUM-DRIVER-AGE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="detailForm?.controls?.minimumDriverAge"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.MINIMUM-YEARS-OF-DRIVER-LICENSE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="
                    detailForm?.controls?.minimumYearsOfDriverLicense
                  "
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
          </div>
        </div>
      </div>
    </sof-form>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogDetailTabContentComponent {
  @Input() tc: string;
  @Input() detailForm: FormGroup;
  @Input() vehicleBodyStyles: Array<EnumValueDto>;
  @Input() vehicleColors: Array<EnumValueDto>;
  @Input() brandsFiltered: Array<VehicleBrand>;
  @Input() vehicleModelsForBrandFiltered: Array<VehicleModelDto>;
  @Input() errorMap: { [p: string]: string };

  @Output()
  newBrandSearchText: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  newModelSearchText: EventEmitter<string> = new EventEmitter<string>();

  // Used by main component to focus on first required field
  @ViewChild('vehicleDetailForm') vehicleDetailForm: ElementRef;

  trackByFn = i => i;
  enumValueFn = e => e.enumId;
  enumLabelFn = e => SharedUiUtils.getEnumDescription(e);
  colorValueFn = c => c.enumId;
  colorLabelFn = c => SharedUiUtils.getEnumDescription(c);
  brandValueFn = b => b?.name;
  brandLabelFn = b => b?.name;
  vehicleModelValueFn = v => v?.name;
  vehicleModelLabelFn = v => v?.name;
}
