import { Component } from '@angular/core';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@Component({
  selector: 'sof-organization-driver-grade-list-view',
  template: `
    <ng-container>
      <sof-organization-driver-grade-list
        [tc]="tc"
        [organization]="organizationViewHelper.organization$ | async"
      >
      </sof-organization-driver-grade-list>
      <router-outlet></router-outlet>
    </ng-container>
  `
})
export class OrganizationDriverGradeListViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-DRIVER-GRADE-LIST';

  constructor(public organizationViewHelper: OrganizationViewHelper) {}
}
