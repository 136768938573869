import {
  Directive,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  Input
} from '@angular/core';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { fromEvent, Observable, Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[sofClickOutside]'
})
export class ClickOutsideDirective implements OnInit {
  private listening = false;
  private globalClick$: Observable<Event> = fromEvent(document, 'click').pipe(
    delay(1),
    tap(() => (this.listening = true))
  );

  @Input() forTooltip = false;
  @Output() clickOutside: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.globalClick$
      .pipe(takeUntilDestroy(this))
      .subscribe((event: MouseEvent) => {
        this.onGlobalClick(event);
      });
  }

  onGlobalClick(event: MouseEvent): void {
    if (
      event instanceof MouseEvent &&
      this.listening === true &&
      !this.isDescendant(this.elementRef.nativeElement, event.target, event)
    ) {
      this.clickOutside.emit({
        target: event.target || null,
        value: true
      });
    }
  }

  isDescendant(parent, child, event: MouseEvent): boolean {
    let node = child;
    while (node !== null) {
      if (
        node === parent ||
        (this.forTooltip && this.haveSameTooltipParent(parent, node, event))
      ) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }

  haveSameTooltipParent(parent, node, event: MouseEvent): boolean {
    // On document?
    if (node.parentNode === null) {
      return false;
    }
    const classNames = node.getAttribute('class')?.split(' ');
    if (
      !!classNames &&
      !!classNames.find(
        className => className === 'click-outside-tooltip-exception'
      )
    ) {
      // Specific case of click on button menu in a tooltip
      return true;
    } else if (
      !!classNames &&
      !!classNames.find(className => className === 'cdk-overlay-container')
    ) {
      // Specific case of click on button menu overlay
      // Check if one the component at mouse click position is not a tooltip
      const elements: Element[] = document.elementsFromPoint(
        event.clientX,
        event.clientY
      );
      if (
        !!elements &&
        !!elements.find(element =>
          this.haveSameTooltipParentCheck(parent, element)
        )
      ) {
        return true;
      }
    }
    return this.haveSameTooltipParentCheck(parent, node);
  }

  haveSameTooltipParentCheck(parent, node): boolean {
    const parentTooltipParentId = parent.getAttribute('tooltip-parent-id');
    const nodeTooltipParentId = node.getAttribute('tooltip-parent-id');
    return (
      !!parentTooltipParentId &&
      !!nodeTooltipParentId &&
      parentTooltipParentId === nodeTooltipParentId
    );
  }
}
