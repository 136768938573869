import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundViewComponent } from './page-not-found-view/page-not-found-view.component';

const appRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'scheduler',
    loadChildren: () =>
      import('./scheduler-ui/scheduler.module').then(
        mod => mod.SchedulerModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import(
        './organization-management-ui/organization-management.module'
      ).then(mod => mod.OrganizationManagementModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'vehicles',
    loadChildren: () =>
      import('./vehicle-management-ui/vehicle-management.module').then(
        mod => mod.VehicleManagementModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./booking-management-ui/booking-management.module').then(
        mod => mod.BookingManagementModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'damages',
    loadChildren: () =>
      import('./damage-management-ui/damage-management.module').then(
        mod => mod.DamageManagementModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./user-management-ui/user-management.module').then(
        mod => mod.UserManagementModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./chat-management-ui/chat-management.module').then(
        mod => mod.ChatManagementModule
      ),
    canActivate: [AuthenticatedGuard]
  } /*,
  {
    path: 'page-not-found',
    component: PageNotFoundViewComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }*/
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'disabled',
      paramsInheritanceStrategy: 'always'
    }),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
