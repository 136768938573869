import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { VehicleDto } from  '../../../../../client';

@Component({
  selector: 'sof-vehicle-group-vehicle-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="sof-card" *ngFor="let vehicle of vehicles">
        <sof-license-plate
          class="scheduler-license-plate"
          [value]="vehicle.licensePlate"
          countryCode="be"
        ></sof-license-plate>
        <div class="vehicle-name">
          {{ vehicle.name }}
        </div>
        <div class="spacer-container"></div>
        <button
          sofButton
          class="icon-button primary"
          iconSize="20"
          icon="batt-icon-delete"
          (click)="onDeleteVehicle(vehicle.id)"
        ></button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./vehicle-group-vehicle-list.component.scss']
})
export class VehicleGroupVehicleListComponent {
  @Input() vehicles: VehicleDto[];

  @Output() deleteVehicle: EventEmitter<string> = new EventEmitter<string>();

  onDeleteVehicle(vehicleId: string): void {
    this.deleteVehicle.emit(vehicleId);
  }
}
