import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { DriverGradeDto } from  '../../../../../client';

@Component({
  selector: 'sof-driver-grade',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="driver-grade">
        <div class="driver-grade-name">
          {{ driverGrade.name }}
          <div
            *ngIf="canRemove"
            class="remove-button-icon"
            (click)="onRemoveClick(driverGrade)"
          >
            <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./driver-grade.component.scss']
})
export class DriverGradeComponent {
  @Input() driverGrade: DriverGradeDto;
  @Input() canRemove: boolean;

  @Output()
  removeDriverGrade: EventEmitter<DriverGradeDto> = new EventEmitter<DriverGradeDto>();

  onRemoveClick(driverGrade: DriverGradeDto): void {
    this.removeDriverGrade.emit(driverGrade);
  }
}
