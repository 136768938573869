import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BookingRequestWithStyle } from '../../../types/booking-request-with-style.type';
import { SchedulerSetting } from '../../../scheduler.setting';
import { BookingRequest } from '../../../types/booking-request.type';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';

@Component({
  selector: 'sof-booking-requests-scheduler',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./booking-requests-scheduler.component.scss'],
  template: `
    <div
      *ngFor="
        let bookingRequestWithStyle of bookingRequestsWithStyle;
        trackBy: trackByIndex
      "
      class="row"
      [style.height.px]="rowHeightPx"
      [class.selected]="bookingRequestWithStyle.id === bookingRequestSelectedId"
      [class.hovered]="bookingRequestWithStyle.id === bookingRequestHoveredId"
      (mouseenter)="newBookingRequestHoveredId.emit(bookingRequestWithStyle.id)"
      (mouseleave)="newBookingRequestHoveredId.emit(null)"
      (click)="onBookingRequestClick(bookingRequestWithStyle)"
    >
      <div
        class="cell"
        *ngFor="let cell of cells; trackBy: trackByIndex"
        [ngClass]="{ isToday: cell.isToday, isWeekend: cell.isWeekend }"
      ></div>

      <div
        class="booking-request booking"
        [ngStyle]="bookingRequestWithStyle.posAndSize"
      ></div>
    </div>
  `
})
export class BookingRequestsSchedulerComponent {
  @Input() bookingRequestsWithStyle: BookingRequestWithStyle[];
  @Input() cells: BookingSchedulerDate[];

  @Input() bookingRequestSelectedId: string;
  @Input() bookingRequestHoveredId: string;
  @Output()
  newBookingRequestHoveredId: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  newBookingRequestSelected: EventEmitter<BookingRequest> = new EventEmitter<BookingRequest>();

  rowHeightPx = SchedulerSetting.subRowMinHeightPx;

  onBookingRequestClick(bookingRequestWithStyle): void {
    if (bookingRequestWithStyle.id === this.bookingRequestSelectedId) {
      this.newBookingRequestSelected.emit(null);
    } else {
      this.newBookingRequestSelected.emit(bookingRequestWithStyle);
    }
  }

  trackByIndex = i => i;
}
