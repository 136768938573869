import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { DriverGradeDto } from  '../../../../../client';

@Component({
  selector: 'sof-driver-grade-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <sof-driver-grade
        *ngFor="let driverGrade of driverGrades"
        [driverGrade]="driverGrade"
        [canRemove]="canRemove"
        (removeDriverGrade)="onRemoveClick($event)"
      ></sof-driver-grade>
    </ng-container>
  `,
  styleUrls: ['./driver-grade-list.component.scss']
})
export class DriverGradeListComponent {
  @Input() driverGrades: DriverGradeDto[];
  @Input() canRemove: boolean;

  @Output()
  removeDriverGrade: EventEmitter<DriverGradeDto> = new EventEmitter<DriverGradeDto>();

  onRemoveClick(driverGrade: DriverGradeDto): void {
    this.removeDriverGrade.emit(driverGrade);
  }
}
