import { VehicleAvailability } from '../../types/vehicle-availability.type';
import { VehicleAvailabilityWithStyle } from '../../types/vehicle-availability-with-style.type';
import {
  UnavailabilityEvent,
  UnavailabilityType
} from '../../types/unavailability-event.type';
import { UnavailabilityEventWithStyle } from '../../types/unavailability-event-with-style.type';
import { Event } from '../../types/event.type';
import { AvailabilityEventWithStyle } from '../../types/availability-event-with-style.type';
import { AvailabilityEvent } from '../../types/availability-event.type';
import { EventUtils } from './event.utils';

export class VehicleAvailabilityUtils {
  // A function to create the VehicleAvailabilityWithStyle object,
  // containing all the attributes + attributes containing display data needed for styling
  static createVehicleAvailabilityWithStyle(
    vehicleAvailability: VehicleAvailability,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): VehicleAvailabilityWithStyle {
    let startOfAvailabilityWithStyle = null;
    if (vehicleAvailability && vehicleAvailability.startOfAvailability) {
      startOfAvailabilityWithStyle = EventUtils.getLeftAndWidth(
        {
          internalId: null,
          remoteId: null,
          fromDate: schedulerDateFrom,
          toDate: vehicleAvailability.startOfAvailability
        },
        msPerPixel,
        schedulerDateFrom,
        schedulerDateTo
      );
    }

    let endOfAvailabilityWithStyle = null;
    if (vehicleAvailability && vehicleAvailability.endOfAvailability) {
      endOfAvailabilityWithStyle = EventUtils.getLeftAndWidth(
        {
          internalId: null,
          remoteId: null,
          fromDate: vehicleAvailability.endOfAvailability,
          toDate: schedulerDateTo
        },
        msPerPixel,
        schedulerDateFrom,
        schedulerDateTo
      );
    }

    return {
      ...vehicleAvailability,
      startOfAvailabilityWithStyle,
      endOfAvailabilityWithStyle
    };
  }

  // A function to create the UnavailabilityEventWithStyle object,
  // containing all the attributes + attributes containing display data needed for styling
  static createVehicleUnavailabilityEventWithStyle(
    unavailability: UnavailabilityEvent,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): UnavailabilityEventWithStyle {
    return {
      ...unavailability,
      positionAndSize: EventUtils.getLeftAndWidth(
        unavailability as Event,
        msPerPixel,
        schedulerDateFrom,
        schedulerDateTo
      ),
      classes: {
        beginBefore: schedulerDateFrom > unavailability.fromDate,
        endAfter: schedulerDateTo < unavailability.toDate,
        privateUsageOverdue:
          unavailability.type === UnavailabilityType.PRIVATE_USAGE_OVERDUE
      }
    };
  }

  static createVehicleAvailabilityEventWithStyle(
    availability: AvailabilityEvent,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): AvailabilityEventWithStyle {
    return {
      ...availability,
      positionAndSize: EventUtils.getLeftAndWidth(
        availability as Event,
        msPerPixel,
        schedulerDateFrom,
        schedulerDateTo
      ),
      classes: {
        beginBefore: schedulerDateFrom > availability.fromDate,
        endAfter: schedulerDateTo < availability.toDate
      }
    };
  }
}
