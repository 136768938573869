/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventSubjectDto = 'AVAILABILITY' | 'BLOCKED' | 'BOOKING' | 'RECURRING_AVAILABILITY' | 'RECURRING_BLOCKED';

export const EventSubjectDto = {
    AVAILABILITY: 'AVAILABILITY' as EventSubjectDto,
    BLOCKED: 'BLOCKED' as EventSubjectDto,
    BOOKING: 'BOOKING' as EventSubjectDto,
    RECURRINGAVAILABILITY: 'RECURRING_AVAILABILITY' as EventSubjectDto,
    RECURRINGBLOCKED: 'RECURRING_BLOCKED' as EventSubjectDto
};

