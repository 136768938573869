import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional
} from '@angular/core';
import { UserImage } from './types/user-image.type';
import { Configuration } from '../../../../../client';
import AvatarUtils from '../../../utils/avatar.utils';

@Component({
  selector: 'sof-user-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div
        class="user-image-container size-{{ localSize }}"
        nz-tooltip
        [nzTooltipTitle]="localTooltip"
        nzTooltipPlacement="bottom"
      >
        <img
          *ngIf="localShowImage"
          [src]="basePath + localUserImage.imageUrl"
        />
        <sof-letters-avatar
          *ngIf="localShowLetters"
          [avatarClass]="localAvatarClass"
          [avatarInitials]="localAvatarInitials"
          [size]="localSize"
        ></sof-letters-avatar>
      </div>
    </ng-container>
  `,
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  @Input()
  set userImage(userImage: UserImage) {
    this.localUserImage = userImage;
    if (!userImage) {
      this.localShowImage = false;
      this.localShowLetters = false;
    } else {
      this.localSize = userImage.imageSize ? userImage.imageSize : '32';
      this.localUserName = !!userImage.displayName
        ? userImage.displayName
        : userImage.userName;
      this.localAvatarClass = AvatarUtils.getAvatarClass(this.localUserName);
      this.localAvatarInitials = AvatarUtils.getAvatarInitials(
        this.localUserName
      );

      this.localShowImage = !!userImage.imageUrl;
      this.localShowLetters =
        !this.localShowImage && !!this.localAvatarInitials;
    }
    this.updateTooltip();
  }

  @Input()
  set showTooltip(showTooltip: boolean) {
    this.localShowTooltip = showTooltip;
    this.updateTooltip();
  }

  localShowTooltip = false;
  localTooltip: string = null;
  localUserImage: UserImage = null;
  localUserName: string = null;
  localAvatarClass: string = null;
  localAvatarInitials: string = null;
  localSize: '30' | '32' | '40' | '45' | '48' = '32';
  localShowImage = false;
  localShowLetters = false;

  basePath = 'http://dev-api.battmobility.com';

  constructor(@Optional() configuration: Configuration) {
    if (configuration) {
      this.basePath = configuration.basePath;
    }
  }

  updateTooltip(): void {
    this.localTooltip = this.localShowTooltip ? this.localUserName : null;
  }
}
