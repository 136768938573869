import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AddVehiclesToGroupRequestDto,
  CreateDriverGradeFilterRequestDto,
  CreateDriverGradeRequestDto,
  CreateOrganizationRequestDto,
  CreateVehicleGroupRequestDto,
  DeleteVehiclesFromGroupRequestDto,
  DriverGradeDto,
  DriverGradeFilterDto,
  DriverGradeFiltersPageDto,
  OrganizationDto,
  OrganizationPageDto,
  PutUserInOrganizationRequestDto,
  UpdateDriverGradeFilterRequestDto,
  UpdateDriverGradeRequestDto,
  UpdateOrganizationRequestDto,
  UpdateVehicleGroupRequestDto,
  UserDto,
  VehicleGroupDto,
  VehicleGroupPageDto
} from  '../../../client';
import { BookingService } from '../services/booking.service';
import { OrganizationService } from '../services/organization.service';
import { UserService } from '../services/user.service';
import { VehicleGroupService } from '../services/vehicle-group.service';

@Injectable()
export class OrganizationManagementSandbox {
  allVehicle$ = this.bookingService.allVehicles$;
  vehicleModels$ = this.bookingService.vehicleModels$;

  constructor(
    private organizationService: OrganizationService,
    private vehicleGroupService: VehicleGroupService,
    private userService: UserService,
    private bookingService: BookingService,
    private router: Router
  ) {}

  createOrganization(
    createOrganizationRequest: CreateOrganizationRequestDto
  ): Observable<OrganizationDto> {
    return this.organizationService.createOrganization(
      createOrganizationRequest
    );
  }

  updateOrganization(
    updateOrganizationRequest: UpdateOrganizationRequestDto
  ): Observable<OrganizationDto> {
    return this.organizationService.updateOrganization(
      updateOrganizationRequest
    );
  }

  putUserInOrganization(
    organizationId: string,
    userId: string,
    request: PutUserInOrganizationRequestDto
  ): Observable<any> {
    return this.organizationService.putUserInOrganization(
      organizationId,
      userId,
      request
    );
  }

  addUserToDriverGrade(
    driverGradeId: string,
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationService.addUserToDriverGrade(
      driverGradeId,
      organizationId,
      userId
    );
  }

  createDriverGrade(
    organizationId: string,
    createDriverGradeRequestDto: CreateDriverGradeRequestDto
  ): Observable<DriverGradeDto> {
    return this.organizationService.createDriverGrade(
      organizationId,
      createDriverGradeRequestDto
    );
  }

  updateDriverGrade(
    updateDriverGradeRequestDto: UpdateDriverGradeRequestDto
  ): Observable<DriverGradeDto> {
    return this.organizationService.updateDriverGrade(
      updateDriverGradeRequestDto
    );
  }

  getOrganizations(): Observable<OrganizationPageDto> {
    return this.organizationService.getOrganizations();
  }

  getOrganization(organizationId: string): Observable<OrganizationDto> {
    return this.organizationService.getOrganization(organizationId);
  }

  deleteUserFromOrganization(
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationService.deleteUserFromOrganization(
      organizationId,
      userId
    );
  }

  deleteOrganization(organizationId: string): Observable<any> {
    return this.organizationService.deleteOrganization(organizationId);
  }

  deleteUserFromDriverGrade(
    driverGradeId: string,
    organizationId: string,
    userId: string
  ): Observable<any> {
    return this.organizationService.deleteUserFromDriverGrade(
      driverGradeId,
      organizationId,
      userId
    );
  }

  deleteDriverGrade(
    driverGradeId: string,
    organizationId: string
  ): Observable<any> {
    return this.organizationService.deleteDriverGrade(
      driverGradeId,
      organizationId
    );
  }

  getDriverGradeFilters(
    driverGradeId: string,
    organizationId: string
  ): Observable<DriverGradeFiltersPageDto> {
    return this.organizationService.getDriverGradeFilters(
      driverGradeId,
      organizationId
    );
  }

  createDriverGradeFilter(
    driverGradeId: string,
    organizationId: string,
    createDriverGradeFilterRequestDto: CreateDriverGradeFilterRequestDto
  ): Observable<DriverGradeFilterDto> {
    return this.organizationService.createDriverGradeFilter(
      driverGradeId,
      organizationId,
      createDriverGradeFilterRequestDto
    );
  }

  deleteDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string
  ): Observable<any> {
    return this.organizationService.deleteDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId
    );
  }

  updateDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    updateDriverGradeFilterRequestDto: UpdateDriverGradeFilterRequestDto
  ): Observable<DriverGradeFilterDto> {
    return this.organizationService.updateDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      updateDriverGradeFilterRequestDto
    );
  }

  getVehicleGroups(organizationId: string): Observable<VehicleGroupPageDto> {
    return this.vehicleGroupService.getVehicleGroups(organizationId);
  }

  getVehicleGroup(vehicleGroupId: string): Observable<VehicleGroupDto> {
    return this.vehicleGroupService
      .getVehicleGroup(vehicleGroupId)
      .pipe(take(1));
  }

  deleteVehicleGroup(vehicleGroupId: string): Observable<VehicleGroupDto> {
    return this.vehicleGroupService
      .deleteVehicleGroup(vehicleGroupId)
      .pipe(take(1));
  }

  createVehicleGroup(
    request: CreateVehicleGroupRequestDto
  ): Observable<VehicleGroupDto> {
    return this.vehicleGroupService.createVehicleGroup(request);
  }

  updateVehicleGroup(
    updateVehicleGroupRequestDto: UpdateVehicleGroupRequestDto
  ): Observable<VehicleGroupDto> {
    return this.organizationService.updateVehicleGroup(
      updateVehicleGroupRequestDto
    );
  }

  addVehicleToVehicleGroup(
    vehicleGroupId: string,
    vehicleId: string
  ): Observable<any> {
    return this.vehicleGroupService
      .addVehicleToVehicleGroup(vehicleGroupId, vehicleId)
      .pipe(take(1));
  }

  addVehicleToVehiclesGroup(
    vehicleGroupId: string,
    addVehiclesToGroupRequestDto: AddVehiclesToGroupRequestDto
  ): Observable<any> {
    return this.vehicleGroupService
      .addVehicleToVehiclesGroup(vehicleGroupId, addVehiclesToGroupRequestDto)
      .pipe(take(1));
  }

  deleteVehicleFromVehicleGroup(
    vehicleGroupId: string,
    vehicleId: string
  ): Observable<any> {
    return this.vehicleGroupService
      .deleteVehicleFromVehicleGroup(vehicleGroupId, vehicleId)
      .pipe(take(1));
  }

  deleteVehiclesFromVehicleGroup(
    vehicleGroupId: string,
    deleteVehiclesFromGroupRequestDto: DeleteVehiclesFromGroupRequestDto
  ): Observable<any> {
    return this.vehicleGroupService
      .deleteVehiclesFromVehicleGroup(
        vehicleGroupId,
        deleteVehiclesFromGroupRequestDto
      )
      .pipe(take(1));
  }

  addVehicleGroupToDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    vehicleGroupId: string
  ): Observable<any> {
    return this.organizationService.addVehicleGroupToDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      vehicleGroupId
    );
  }

  deleteVehicleGroupFromDriverGradeFilter(
    driverGradeFilterId: string,
    driverGradeId: string,
    organizationId: string,
    vehicleGroupId: string
  ): Observable<any> {
    return this.organizationService.deleteVehicleGroupFromDriverGradeFilter(
      driverGradeFilterId,
      driverGradeId,
      organizationId,
      vehicleGroupId
    );
  }

  getUserByUsername(username: string): Observable<UserDto> {
    return this.userService.getUserByUsername(username).pipe(take(1));
  }

  createUser(userDto: UserDto): Observable<UserDto> {
    return this.userService.createUser(userDto).pipe(take(1));
  }

  sendOnboardingMail(userId: string): Observable<any> {
    return this.userService.sendOnboardingMail(userId);
  }

  navigateToOrganizationsScreen(): void {
    this.router.navigate(['/organizations']);
  }

  navigateToDetailOrganizationScreen(organizationId: string): void {
    this.router.navigate(['organizations', organizationId]);
  }

  navigateToDetailOrganizationMemberScreen(
    organizationId: string,
    userId: string
  ): void {
    this.router.navigate(['organizations', organizationId, 'members', userId]);
  }

  navigateToDetailOrganizationManagerScreen(
    organizationId: string,
    userId: string
  ): void {
    this.router.navigate(['organizations', organizationId, 'managers', userId]);
  }

  navigateToDetailOrganizationDriverGradeScreen(
    organizationId: string,
    driverGradeId: string
  ): void {
    this.router.navigate([
      'organizations',
      organizationId,
      'driver-grades',
      driverGradeId
    ]);
  }

  navigateToDetailOrganizationVehicleGroupScreen(
    organizationId: string,
    vehicleGroupId: string
  ): void {
    this.router.navigate([
      'organizations',
      organizationId,
      'vehicle-groups',
      vehicleGroupId
    ]);
  }
}
