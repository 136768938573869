import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '../../../../client';
import SharedUiUtils from '../utils/shared-ui.utils';

@Pipe({ name: 'userDisplayNameFromList' })
// TODO - To be removed when BookingDto/UserDto/... will contain UserReferenceDto
export class UserDisplayNameFromListPipe implements PipeTransform {
  transform(userName: string, users: Array<UserDto>): string {
    if (!userName || !users) {
      return userName;
    }
    const userDto = users.find(user => user.userName === userName);
    return !!userDto ? SharedUiUtils.getUserDisplayName(userDto) : userName;
  }
}
