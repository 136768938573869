import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { BookingRequest } from '../../../types/booking-request.type';
import { Changes, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingRequestWithStyle } from '../../../types/booking-request-with-style.type';
import { createBookingRequestWithStyle } from '../../utils/booking-request.utils';
import { ViewMode } from '../../../types/view-mode.type';
import { SchedulerSetting } from '../../../scheduler.setting';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';
import { EventUtils } from '../../utils/event.utils';

@UntilDestroy()
@Component({
  selector: 'sof-booking-request-part',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./booking-request-part.component.scss'],
  template: `
    <sof-horizontal-splitter
      [areaSize]="horizontalSplitterAreaSize"
      (newAreaSize)="newHorizontalSplitterAreaSize.emit($event)"
    >
      <div location="left" class="left">
        <sof-requested-vehicles-list
          [bookingRequests]="bookingRequestsWithStyle$ | async"
          [bookingRequestSelectedId]="bookingRequestSelectedId"
          [bookingRequestHoveredId]="bookingRequestHoveredId"
          [scrollTop]="vehicleListAndBookingSchedulerScrollTop$ | async"
          (newScrollTop)="vehicleListAndBookingSchedulerScrollTop$.next($event)"
          (newBookingRequestSelected)="newBookingRequestSelected.emit($event)"
          (newBookingRequestHoveredId)="bookingRequestHoveredId = $event"
        >
        </sof-requested-vehicles-list>

        <!--
        <b> booking requests card list </b> <br>
        booking request hovered id : {{ bookingRequestHoveredId | json }} <br>
        booking request selected id : {{ bookingRequestSelectedId | json }}<br>
        pendings request on this period : {{ (bookingRequestsWithStyle$ | async).length }}
        -->
      </div>
      <div location="right" class="right">
        <div class="header" [style.height.px]="headerHeightPx"></div>
        <sof-booking-scheduler-body-scrollbox
          [msPerPixel]="msPerPixel"
          [dateFrom]="schedulerDateFrom"
          [smallestTimeUnit]="viewMode?.smallestDragUnit"
          [applyScrollLeft]="scrollLeft"
          [applyScrollTop]="vehicleListAndBookingSchedulerScrollTop$ | async"
          [isDraggingBooking]="false"
          (scrollLeft)="newSchedulerScrollLeft.emit($event)"
          (scrollTop)="vehicleListAndBookingSchedulerScrollTop$.next($event)"
        >
          <sof-booking-requests-scheduler
            [bookingRequestsWithStyle]="bookingRequestsWithStyle$ | async"
            [cells]="cells$ | async"
            [style.width.px]="periodWidthPx"
            [bookingRequestHoveredId]="bookingRequestHoveredId"
            (newBookingRequestHoveredId)="bookingRequestHoveredId = $event"
            [bookingRequestSelectedId]="bookingRequestSelectedId"
            (newBookingRequestSelected)="newBookingRequestSelected.emit($event)"
          >
          </sof-booking-requests-scheduler>
        </sof-booking-scheduler-body-scrollbox>
      </div>
    </sof-horizontal-splitter>
  `
})
export class BookingRequestPartComponent implements OnChanges, OnInit {
  @Input() horizontalSplitterAreaSize: [number, number];
  @Input() schedulerDateFrom: Date;
  @Input() schedulerDateTo: Date;
  @Input() msPerPixel: number;
  @Input() periodWidthPx: number;
  @Input() viewMode: ViewMode;
  @Input() scrollLeft: number;
  @Input() bookingRequests: BookingRequest[];

  @Input() bookingRequestSelectedId: string;

  @Output()
  newBookingRequestSelected: EventEmitter<BookingRequest> = new EventEmitter<BookingRequest>();

  @Output()
  newHorizontalSplitterAreaSize: EventEmitter<
    [number, number]
  > = new EventEmitter<[number, number]>();

  @Output()
  newSchedulerScrollLeft: EventEmitter<number> = new EventEmitter<number>();

  @Changes('bookingRequests') bookingRequests$: Observable<BookingRequest[]>;
  @Changes('schedulerDateFrom') schedulerDateFrom$: Observable<Date>;
  @Changes('schedulerDateTo') schedulerDateTo$: Observable<Date>;
  @Changes('msPerPixel') msPerPixel$: Observable<number>;
  @Changes('periodWidthPx') periodWidthPx$: Observable<number>;
  @Changes('viewMode') viewMode$: Observable<ViewMode>;

  headerHeightPx = SchedulerSetting.schedulerHeightPx;

  bookingRequestHoveredId = null;

  // sate shared between the list and the scheduler
  vehicleListAndBookingSchedulerScrollTop$: Subject<number> = new Subject<number>();

  bookingRequestsWithStyle$: Observable<BookingRequestWithStyle[]>;

  cells$: Observable<BookingSchedulerDate[]>;

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.bookingRequestsWithStyle$ = combineLatest([
      this.bookingRequests$,
      this.msPerPixel$,
      this.schedulerDateFrom$,
      this.schedulerDateTo$
    ]).pipe(
      map(([bookingRequests, msPerPixel, schedulerDateFrom, schedulerDateTo]) =>
        bookingRequests
          .filter(bookingRequest =>
            EventUtils.isEventOnThisPeriod(
              bookingRequest,
              schedulerDateFrom,
              schedulerDateTo
            )
          )
          .map(bookingRequest =>
            createBookingRequestWithStyle(
              bookingRequest,
              msPerPixel,
              schedulerDateFrom,
              schedulerDateTo
            )
          )
      )
    );

    this.cells$ = combineLatest([this.schedulerDateFrom$, this.viewMode$]).pipe(
      map(([schedulerDateFrom, viewMode]: [Date, ViewMode]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.subColumn.duration,
          viewMode.calendar.subColumn.date_format
        )
      )
    );
  }
}
