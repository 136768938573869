import { Injectable } from '@angular/core';
import {
  BookingUserDto,
  BookingUserPageDto,
  SearchUsersRequestDto,
  UpdateUserRequestDto,
  UserControllerImplService,
  UserDto,
  UserPageDto
} from '../../../client';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import SharedUiUtils from '../shared-ui/utils/shared-ui.utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // TODO - Temporary solution - To be removed when user search and Dtos will be updated
  allUsers$: Observable<UserPageDto> = this.userControllerImplService
    .getAllUsers()
    .pipe(
      map(userPage => {
        userPage.users = userPage.users?.sort(this.sortOnName);
        return userPage;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  constructor(private userControllerImplService: UserControllerImplService) {}

  fetchUsers(): Observable<UserPageDto> {
    return this.userControllerImplService.getAllUsers();
  }

  getCurrentUser(): Observable<BookingUserDto> {
    return this.userControllerImplService.getCurrentUser();
  }

  getUserByUsername(username: string): Observable<UserDto> {
    return this.userControllerImplService.getUserByUsername(username);
  }

  createUser(userDto: UserDto): Observable<UserDto> {
    return this.userControllerImplService.createUser(userDto);
  }

  updateUser(
    userId: string,
    updateUserRequestDto: UpdateUserRequestDto
  ): Observable<UserDto> {
    return this.userControllerImplService.updateUser(
      userId,
      updateUserRequestDto
    );
  }

  deleteUser(userId: string): Observable<any> {
    return this.userControllerImplService.deleteUser(userId);
  }

  sendOnboardingMail(userId: string): Observable<any> {
    return this.userControllerImplService.sendOnboardingMail(userId);
  }

  getBookingUser(userId: string): Observable<BookingUserDto> {
    return this.userControllerImplService.getBookingUser(userId);
  }

  // TODO - To be removed when allUsers$ will be removed
  sortOnName(a: UserDto, b: UserDto): number {
    return SharedUiUtils.getUserDisplayName(a)
      .toLowerCase()
      .localeCompare(SharedUiUtils.getUserDisplayName(b).toLowerCase());
  }

  searchUsers(
    searchUsersRequestDto: SearchUsersRequestDto
  ): Observable<BookingUserPageDto> {
    return this.userControllerImplService.searchUsers(searchUsersRequestDto);
  }
}
