import { Event } from './event.type';
import { NonAvailabilityDto } from '../../../client';

export interface UnavailabilityEvent extends Event {
  remoteVehicleId: string;
  type: UnavailabilityType;
  data?: NonAvailabilityDto;
}

export enum UnavailabilityType {
  STOLEN = 'STOLEN',
  MAINTENANCE = 'MAINTENANCE',
  VEHICLE_CRASH = 'VEHICLE_CRASH',
  OTHER = 'OTHER',
  CANTBEUSED = 'CANT_BE_USED',
  PRIVATE_USAGE_OVERDUE = 'PRIVATE_USAGE_OVERDUE',
  PRIVATE_USAGE = 'PRIVATE_USAGE'
}
