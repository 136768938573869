import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserImage } from '../user-avatar/types/user-image.type';
import { ConversationMessage } from '../conversation-messages/types/conversation-message.type';

@Component({
  selector: 'sof-conversation-system-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div *ngIf="showTimestamp" class="message-timestamp">
        {{ timeStamp | toFormattedConversationDate }}
      </div>
      <div *ngIf="message?.message === 'ADD_PARTCIPANT'" class="message-text">
        {{
          tc + '.ADD-PARTICIPANT'
            | translate: { userDisplayName: message.sender?.displayName }
        }}
      </div>
      <div
        *ngIf="message?.message === 'INITIATE_CONVERSION'"
        class="message-text"
      >
        {{
          tc + '.INITIATE-CONVERSATION'
            | translate: { userDisplayName: message.sender?.displayName }
        }}
      </div>
    </ng-container>
  `,
  styleUrls: ['./conversation-system-message.component.scss']
})
export class ConversationSystemMessageComponent {
  @Input() tc: string;
  @Input() message: ConversationMessage;
  @Input() timeStamp: string;
  @Input() userImage: UserImage;
  @Input() noRoundUp: boolean;
  @Input() noRoundDown: boolean;
  @Input() showUsername: boolean;
  @Input() isOwnMessage: boolean;
  @Input() showTimestamp: boolean;
  @Input() firstMessage: boolean;
}
