import { ActionTypes } from '../action-types';
import { ConversationMessage } from '../../shared-ui/components/conversation-messages/types/conversation-message.type';

export class AppendNewMessagesAction {
  type = ActionTypes.APPEND_NEW_MESSAGES;
  payload: {
    messages: ConversationMessage[];
  };

  constructor(messages: ConversationMessage[]) {
    this.payload = { messages };
  }
}

export class ClearMessagesAction {
  type = ActionTypes.CLEAR_MESSAGES;
}

export class NewMessageAction {
  type = ActionTypes.NEW_MESSAGE;
  payload: {
    message: ConversationMessage;
  };

  constructor(message: ConversationMessage) {
    this.payload = { message };
  }
}

export class UpdateMessageAction {
  type = ActionTypes.UPDATE_MESSAGE;
  payload: {
    oldId: string;
    message: ConversationMessage;
  };

  constructor(oldId: string, message: ConversationMessage) {
    this.payload = { oldId, message };
  }
}

export type MessagesActions =
  | AppendNewMessagesAction
  | ClearMessagesAction
  | NewMessageAction
  | UpdateMessageAction;
