import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2
} from '@angular/core';
import { Acting, Loading } from '@sofico-framework/utils';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sof-loading-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="loader-spinner">
      <sof-loading></sof-loading>
    </div>
  `,
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  @Loading() loading$;
  @Acting() acting$;

  private show = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    combineLatest([this.loading$, this.acting$])
      .pipe(takeUntilDestroy(this))
      .subscribe(([loading, acting]: [boolean, boolean]) => {
        this.show = loading || acting;
        // TODO - Check validity of solution with Renderer2 and Renderer2
        // HostBinding('class.show') was not working because of the ChangeDetection (updating this.show had no effect)
        // An other solution could be to use a Directive to change the class :
        // @Directive()
        // export class HostClassDirective extends NgClass {
        //   apply(value: string | string[] | Set<string> | { [klass: string]: any }) {
        //     this.ngClass = value;
        //     this.ngDoCheck();
        //   }
        // }
        // and use it there like
        // providers: [HostClassDirective]
        // constructor(private hostClassDirective: HostClassDirective)
        // this.hostClassDirective.apply({show: show})
        if (this.show) {
          this.renderer.addClass(this.el.nativeElement, 'show');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'show');
        }
      });
  }
}
