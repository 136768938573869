import { UserSettings } from '../../types/user-settings.type';
import { UserSettingsActions } from '../actions/user-settings.action';
import { ActionTypes } from '../action-types';

const initialUserSettings: UserSettings = {
  tooltipSettings: null,
  vehicleCardFieldsToDisplay: null
};

export function userSettingsReducer(
  state: UserSettings = initialUserSettings,
  action: UserSettingsActions
): UserSettings {
  switch (action.type) {
    case ActionTypes.SET_USER_SETTINGS:
      return { ...action.payload.userSettings };
    case ActionTypes.SET_TOOLTIP_SETTINGS:
      return {
        ...state,
        tooltipSettings: {
          ...action.payload.tooltipSettings
        }
      };
    case ActionTypes.SET_VEHICLE_CARD_FIELDS_TO_DISPLAY:
      return {
        ...state,
        vehicleCardFieldsToDisplay: action.payload.vehicleCardFieldsToDisplay
      };
  }
  return state;
}
