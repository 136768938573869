import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetRouterState, hotSafe } from '@sofico-framework/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import { catchError, filter, shareReplay, switchMap } from 'rxjs/operators';
import {
  OrganizationDto,
  VehicleGroupDto
} from '../../../../client';
import { ToastUtilService } from '../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';

@Injectable()
export class OrganizationVehicleGroupDetailViewHelper {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-VEHICLE-GROUP-DETAIL';
  private triggerVehicleGroupSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date()
  );

  organizationId$ = this.getOrganizationId$();
  organization$ = this.getOrganization$();
  vehicleGroupId$ = this.getVehicleGroupId$();
  vehicleGroup$ = this.getVehicleGroup$();

  @GetRouterState()
  private routerState$: Observable<any>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private activatedRoute: ActivatedRoute
  ) {}

  private getOrganization$(): Observable<OrganizationDto> {
    return this.organizationId$.pipe(
      switchMap(organizationId => {
        if (!organizationId) {
          return of(null);
        }
        return this.osb.getOrganization(organizationId).pipe(
          catchError(error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_GET-ORGANIZATION'
            );
            return of(null);
          })
        );
      }),
      shareReplay(1)
    );
  }

  private getVehicleGroup$(): Observable<VehicleGroupDto> {
    return combineLatest([
      this.vehicleGroupId$,
      this.triggerVehicleGroupSub$
    ]).pipe(
      switchMap(([vehicleGroupId, trigger]) => {
        if (!vehicleGroupId) {
          return of(null);
        }
        return this.osb.getVehicleGroup(vehicleGroupId).pipe(
          catchError(error => {
            this.toastUtilService.showError(
              error,
              this.tc + '.FAILED_GET-VEHICLE-GROUP'
            );
            return of(null);
          })
        );
      }),
      shareReplay(1)
    );
  }

  private getOrganizationId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.organizationId),
      switchMap(([p, state]) => of(p.organizationId)),
      hotSafe()
    );
  }

  private getVehicleGroupId$(): Observable<string> {
    return combineLatest([this.activatedRoute.params, this.routerState$]).pipe(
      filter(([p, state]) => !state?.fromCreation && !!p?.vehicleGroupId),
      switchMap(([p, state]) => of(p.vehicleGroupId)),
      hotSafe()
    );
  }

  triggerVehicleGroup(): void {
    this.triggerVehicleGroupSub$.next(new Date());
  }
}
