import { ActionTypes } from '../action-types';
import { UnavailabilityEvent } from '../../types/unavailability-event.type';

export class SetPrivateUsagesAction {
  type = ActionTypes.SET_PRIVATE_USAGES;
  payload: { privateUsages: UnavailabilityEvent[] };

  constructor(privateUsages: UnavailabilityEvent[]) {
    this.payload = { privateUsages };
  }
}

export type PrivateUsagesActions = SetPrivateUsagesAction;
