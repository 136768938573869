import { PeriodDto, TimeZoneDto } from '../../../client';
import { extendMoment } from 'moment-range';
import * as Moment from 'moment';

const moment = extendMoment(Moment);

export class DateUtil {
  private static dayFormat = 'DD/MM/yy';
  private static hourFormat = 'HH:mm';

  static convertToSearchPeriod(fromDate: Date, toDate: Date): PeriodDto {
    const timeZone = TimeZoneDto.BRUSSELS;
    if (!!fromDate) {
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(0);
      fromDate.setMilliseconds(0);
    }
    const start = !!fromDate ? fromDate.toISOString() : null;
    if (!!toDate) {
      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(59);
      toDate.setMilliseconds(999);
    }
    const end = !!toDate ? toDate.toISOString() : null;
    const period: PeriodDto = {
      start,
      end,
      timeZone
    };
    return period;
  }

  static convertToString(date: Date): string {
    if (!date) {
      return null;
    }
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  }

  // TODO - this method removes seconds and milliseconds, investigate the impact on BookingDto and AvailabilityDto when editing
  // updateBooking and updateAvailability
  static convertToPeriod(fromDate: Date, toDate: Date): PeriodDto {
    const timeZone = TimeZoneDto.BRUSSELS;
    fromDate.setSeconds(0);
    fromDate.setMilliseconds(0);
    const start = moment(fromDate).toISOString(true);
    toDate.setSeconds(0);
    toDate.setMilliseconds(0);
    const end = moment(toDate).toISOString(true);
    const period: PeriodDto = {
      start,
      end,
      timeZone
    };
    return period;
  }

  static compareEqualPeriods(p1: PeriodDto, p2: PeriodDto): boolean {
    return !!p1 && !!p2 && p1.start === p2.start && p1.end === p2.end;
  }

  static convertToUnavailabilityPeriod(
    fromDate: Date,
    toDate: Date,
    allDayEvent: boolean
  ): PeriodDto {
    const timeZone = TimeZoneDto.BRUSSELS;
    fromDate.setSeconds(0);
    fromDate.setMilliseconds(0);
    const start =
      allDayEvent && DateUtil.isToday(fromDate) ? null : fromDate.toISOString();
    toDate.setSeconds(0);
    toDate.setMilliseconds(0);
    const end = toDate.toISOString();
    const period: PeriodDto = {
      start,
      end,
      timeZone
    };
    return period;
  }

  static convertToDate(isoString: string): Date {
    if (!isoString) {
      return null;
    }
    // remove timezone from string
    const split = isoString.split('[');
    const date = new Date(split[0]);
    return date;
  }

  static formatToDate(date: Date): string {
    return date ? moment(date).format(this.dayFormat) : null;
  }

  static formatToHour(date: Date): string {
    return date ? moment(date).format(this.hourFormat) : null;
  }

  static toISOString(
    date: Date,
    timezone: TimeZoneDto = TimeZoneDto.BRUSSELS
  ): string {
    return moment(date).toISOString(true) + '[' + timezone + ']';
  }

  static isToday(date: Date): boolean {
    return moment(date).isSame(new Date(), 'd');
  }

  static getTime(hours: number, minutes: number): Date {
    const date: Date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  }
}
