import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationDto } from  '../../../../../client';

@Component({
  selector: 'sof-organization-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="avatar" [ngClass]="[organization?.name | avatarClass]">
        <div class="avatar-letters">
          {{ organization?.name | avatarInitials }}
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-avatar.component.scss']
})
export class OrganizationAvatarComponent {
  @Input() organization: OrganizationDto;
}
