import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-no-result',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <img src="assets/empty-state-genericnoresults.svg" />
      <div class="no-result-label">
        {{ tc + '.' + noResultLabel | translate }}
      </div>
    </ng-container>
  `,
  styleUrls: ['./no-result.component.scss']
})
export class NoResultComponent {
  @Input() tc: string;
  @Input() noResultLabel: string;
}
