import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BatteryStatusWithDetail } from '../../../types/battery-status-with-detail.type';

@Component({
  selector: 'sof-battery-status-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="battery-status-icon-wrapper">
        <svg class="battery-status-icon-svg" *ngIf="batteryStatus">
          <use attr.href="#{{ batteryStatus.chargingStatusIcon }}"></use>
        </svg>
      </div>
    </ng-container>
  `,
  styleUrls: ['./battery-status-icon.component.scss']
})
export class BatteryStatusIconComponent {
  @Input() batteryStatus: BatteryStatusWithDetail;
}
