import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AvailabilityRemoveDialogData } from '../../../types/availability-remove-dialog-data.type';

@Component({
  selector: 'sof-availability-remove-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.REMOVE-EVENT' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="availabilityForm">
            <div *ngIf="!dialogData.recurringAvailabilityId">
              {{
                tc + '.ARE-YOU-SURE-YOU-WANT-TO-REMOVE-THIS-EVENT' | translate
              }}
            </div>
            <div *ngIf="!!dialogData.recurringAvailabilityId">
              <sof-input-radio
                [sofInput]
                [formControl]="availabilityForm?.controls?.removeChoice"
                [value]="1"
              >
                {{ tc + '.REMOVE-THIS-EVENT-ONLY' | translate }}
              </sof-input-radio>
              <sof-input-radio
                [sofInput]
                [formControl]="availabilityForm?.controls?.removeChoice"
                [value]="2"
              >
                {{ tc + '.REMOVE-ALL-EVENTS' | translate }}
              </sof-input-radio>
            </div>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onOkClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.OK' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class AvailabilityRemoveDialogComponent {
  @Input() tc: string;
  @Input() dialogData: AvailabilityRemoveDialogData;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() okDialog: EventEmitter<number> = new EventEmitter<number>();

  availabilityForm = this.fb.group({
    removeChoice: [1, Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  onOkClick(): void {
    this.okDialog.emit(this.availabilityForm.value.removeChoice ?? 1);
  }
}
