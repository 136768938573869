import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/index';
import {
  EnumValueDto,
  GpsLocationDto,
  OrganizationReferenceDto,
  TimeZoneDto,
  UserDto,
  VehicleDto
} from '../../../../../client';
import { BatteryStatusWithDetail } from '../../../types/battery-status-with-detail.type';
import { KeyLabel } from '../../../types/key-label.type';
import { TrustedUrl } from '../../../types/trusted-url.type';
import { FormGroup } from '@angular/forms';
import TranslateUtils from '../../../utils/translate.utils';
import SharedUiUtils from '../../utils/shared-ui.utils';

@Component({
  selector: 'sof-vehicle-dialog-general-tab-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-form [formGroup]="generalForm" [tc]="tc" class="vehicle-dialog-form">
      <input
        #fileUpload
        hidden="hidden"
        type="file"
        accept="image/jpeg,image/png,image/bmp,image/gif"
        (change)="vehicleImageChange.emit($event)"
      />
      <div #vehicleGeneralForm class="column-container">
        <div class="col-form col-60">
          <div class="container new-data">
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.NAME' | translate"
                class="col-sm"
              >
                <sof-input-text
                  sofFocus
                  [sofInput]
                  [formControl]="generalForm?.controls?.name"
                ></sof-input-text>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.LICENSE-PLATE' | translate"
                class="col-sm"
              >
                <sof-input-text
                  [sofInput]
                  [formControl]="generalForm?.controls?.licensePlate"
                ></sof-input-text>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.MANAGED-BY' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="generalForm?.controls?.owner"
                  [clearable]="false"
                  [options]="allUsers"
                  [selectorValue]="userValueFn"
                  [selectorLabel]="userLabelFn"
                  (changeValue)="changeUser.emit($event)"
                ></sof-input-single-select>
              </sof-input-wrapper>
              <sof-input-wrapper
                [label]="tc + '.ORGANIZATION' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="generalForm?.controls?.organization"
                  [clearable]="false"
                  [options]="userOrganizations"
                  [selectorValue]="organizationValueFn"
                  [selectorLabel]="organizationLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.FEATURES' | translate"
                class="col-sm"
              >
                <sof-input-multi-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="generalForm?.controls?.equipmentIds"
                  [clearable]="false"
                  [options]="equipments"
                  [selectorValue]="keyLabelValueFn"
                  [selectorLabel]="keyLabelLabelFn"
                ></sof-input-multi-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <div class="col-sm column" *ngIf="isGoogleApiEnabled">
                <div class="row-label">
                  {{ tc + '.HOME-LOCATION' | translate }}
                </div>
                <sof-location-selector
                  [gpsLocation]="homeLocation"
                  [tc]="tc"
                  [disabled]="isReadonly"
                  locationSearchTitleLabel="HOME-LOCATION"
                  (newLocationSelected)="newHomeLocation.emit($event)"
                >
                </sof-location-selector>
              </div>
              <sof-input-wrapper
                label="Address"
                class="col-sm"
                *ngIf="!isGoogleApiEnabled"
              >
                <sof-input-textarea
                  [sofInput]
                  [formControl]="generalForm?.controls?.address"
                ></sof-input-textarea>
              </sof-input-wrapper>
            </div>
            <div class="row" *ngIf="!isGoogleApiEnabled">
              <sof-input-wrapper label="Longitude" class="col-sm">
                <sof-input-number
                  [sofInput]
                  [formControl]="generalForm?.controls?.longitude"
                  [maxFraction]="14"
                ></sof-input-number>
              </sof-input-wrapper>
              <sof-input-wrapper label="Latitude" class="col-sm">
                <sof-input-number
                  [sofInput]
                  [formControl]="generalForm?.controls?.latitude"
                  [maxFraction]="14"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.TIME-ZONE' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="generalForm?.controls?.timeZone"
                  [clearable]="false"
                  [options]="timeZones$ | async"
                  [selectorValue]="timeZoneValueFn"
                  [selectorLabel]="timeZoneLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
          </div>
        </div>
        <div class="col-form col-40">
          <div class="container new-data">
            <div class="vehicle-highlighted-data-container">
              <div>
                <sof-license-plate
                  class="scheduler-license-plate"
                  [value]="generalForm.value.licensePlate"
                  countryCode="be"
                ></sof-license-plate>
              </div>
              <div
                *ngIf="!!trustedUrl?.safeUrl"
                class="vehicle-image-container"
              >
                <img [src]="trustedUrl.safeUrl" />
                <button
                  *ngIf="!isReadonly"
                  sofButton
                  icon="batt-icon-camera"
                  iconSize="24"
                  class="round-icon-button primary"
                  (click)="fileUpload.click()"
                ></button>
              </div>
              <div
                *ngIf="!trustedUrl?.safeUrl && !!vehicleDto?.imageUrl"
                class="vehicle-image-container"
              >
                <img [src]="imageBasePath + vehicleDto.imageUrl" />
                <button
                  *ngIf="!isReadonly"
                  sofButton
                  icon="batt-icon-camera"
                  iconSize="24"
                  class="round-icon-button primary"
                  (click)="fileUpload.click()"
                ></button>
              </div>
              <div
                *ngIf="!trustedUrl?.safeUrl && !vehicleDto?.imageUrl"
                class="vehicle-empty-image-container"
              >
                <button
                  *ngIf="!isReadonly"
                  sofButton
                  icon="batt-icon-camera"
                  iconSize="24"
                  class="round-icon-button primary"
                  (click)="fileUpload.click()"
                ></button>
              </div>
              <div class="battery-status-container">
                <sof-battery-status
                  [tc]="tc"
                  [batteryStatus]="batteryStatus"
                ></sof-battery-status>
                <div>{{ batteryStatus.cruisingRange }}km</div>
              </div>
            </div>
            <div class="row pt-1">
              <sof-input-wrapper
                [label]="tc + '.DESCRIPTION' | translate"
                class="col-sm"
              >
                <sof-input-textarea
                  [sofInput]
                  [formControl]="generalForm?.controls?.description"
                  class="comment"
                ></sof-input-textarea>
              </sof-input-wrapper>
            </div>
          </div>
        </div>
      </div>
    </sof-form>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogGeneralTabContentComponent implements OnInit {
  @Input() tc: string;
  @Input() generalForm: FormGroup;
  @Input() isReadonly: boolean;
  @Input() equipments: Array<KeyLabel>;
  @Input() batteryStatus: BatteryStatusWithDetail;
  @Input() trustedUrl: TrustedUrl;
  @Input() vehicleDto: VehicleDto;
  @Input() homeLocation: GpsLocationDto;
  @Input() imageBasePath: string;
  @Input() allUsers: Array<UserDto>;
  @Input() userOrganizations: Array<OrganizationReferenceDto>;
  @Input() isGoogleApiEnabled: boolean;

  @Output() vehicleImageChange: EventEmitter<Event> = new EventEmitter<Event>();
  @Output()
  newHomeLocation: EventEmitter<GpsLocationDto> = new EventEmitter<GpsLocationDto>();
  @Output() changeUser: EventEmitter<string> = new EventEmitter<string>();

  // Used by main component to focus on first required field
  @ViewChild('vehicleGeneralForm') vehicleGeneralForm: ElementRef;

  timeZones$: Observable<EnumValueDto[]>;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const timeZones = [
      TimeZoneDto.BRUSSELS,
      TimeZoneDto.LONDON,
      TimeZoneDto.NEWYORK
    ];
    this.timeZones$ = TranslateUtils.getConstantTranslations$(
      this.translateService,
      timeZones,
      this.tc,
      'TIME_ZONE_'
    );
  }

  userValueFn = u => u.remoteId;
  userLabelFn = u => SharedUiUtils.getUserDisplayName(u);
  organizationValueFn = o => o.id;
  organizationLabelFn = o => o.name;
  keyLabelValueFn = k => k.key;
  keyLabelLabelFn = k => k.label;
  timeZoneValueFn = t => t.enumId;
  timeZoneLabelFn = t => SharedUiUtils.getEnumDescription(t);
}
