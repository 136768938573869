import {
  AttachmentDto,
  AvailabilityDto,
  AvailabilityEventsPerVehicleDto,
  AvailabilityEventsPerVehiclePageDto,
  BatteryStatusDto,
  BookingUserDto,
  ChargingStatusDto,
  MessageDto,
  NonAvailabilityDto,
  NonAvailabilityDtoType,
  UserReferenceDto,
  VehicleDto,
  VehiclePageDto
} from '../../../../client';
import { DateUtil } from '../../helpers/date-util';
import { Booking } from '../../types/booking.type';
import {
  UnavailabilityEvent,
  UnavailabilityType
} from '../../types/unavailability-event.type';
import { AvailabilityEvent } from '../../types/availability-event.type';
import { Vehicle } from '../../types/vehicle.type';
import { VehicleType } from '../../types/vehicle-type.type';
import {
  AttachmentDownloadStatus,
  ConversationMessageAttachment
} from '../components/conversation-messages/types/conversation-message-attachment.type';
import { ConversationMessage } from '../components/conversation-messages/types/conversation-message.type';
import {
  BatteryStatusClasses,
  BatteryStatusWithDetail
} from '../../types/battery-status-with-detail.type';

export default class ConvertersUtils {
  private static count = 0;

  private static getNextId(prefix: string): string {
    return prefix + ++this.count;
  }

  static convertNonAvailabilitiesPageDtoToEvents(
    availabilityEventsPerVehiclePageDto: AvailabilityEventsPerVehiclePageDto
  ): {
    bookings: Booking[];
    unavailabilities: UnavailabilityEvent[];
    availabilities: AvailabilityEvent[];
    privateUsages: UnavailabilityEvent[];
  } {
    const events = {
      bookings: [],
      unavailabilities: [],
      availabilities: [],
      privateUsages: []
    };

    if (
      availabilityEventsPerVehiclePageDto &&
      availabilityEventsPerVehiclePageDto.availabilityEventsPerVehicleDtos
    ) {
      Object.keys(
        availabilityEventsPerVehiclePageDto.availabilityEventsPerVehicleDtos
      ).forEach(vehicleId => {
        const availabilityEventsPerVehicleDto: AvailabilityEventsPerVehicleDto =
          availabilityEventsPerVehiclePageDto.availabilityEventsPerVehicleDtos[
            vehicleId
          ];
        // Private usage
        if (availabilityEventsPerVehicleDto.currentUsageStart) {
          const privateUsage: UnavailabilityEvent = {
            data: null,
            internalId: this.getNextId('UnavailabilityEvent'),
            type: availabilityEventsPerVehicleDto?.currentUsageOverdue
              ? UnavailabilityType.PRIVATE_USAGE_OVERDUE
              : UnavailabilityType.PRIVATE_USAGE,
            remoteId: null,
            fromDate: DateUtil.convertToDate(
              availabilityEventsPerVehicleDto.currentUsageStart
            ),
            toDate: new Date(),
            remoteVehicleId: vehicleId
          };
          if (availabilityEventsPerVehicleDto?.currentUsageOverdue) {
            events.unavailabilities.push(privateUsage);
          }
          events.privateUsages.push(privateUsage);
        }
        if (
          availabilityEventsPerVehicleDto &&
          availabilityEventsPerVehicleDto.nonAvailabilities
        ) {
          availabilityEventsPerVehicleDto.nonAvailabilities.forEach(
            (nonAvailabilityDto: NonAvailabilityDto) => {
              if (nonAvailabilityDto.type2 === NonAvailabilityDtoType.BOOKING) {
                events.bookings.push({
                  remoteVehicleId: nonAvailabilityDto.vehicleId,
                  remoteId: nonAvailabilityDto.booking.id,
                  internalId: this.getNextId('Booking'),
                  fromDate: DateUtil.convertToDate(
                    nonAvailabilityDto.plannedPeriod.start
                  ),
                  toDate: DateUtil.convertToDate(
                    nonAvailabilityDto.plannedPeriod.end
                  ),
                  data: nonAvailabilityDto.booking
                } as Booking);
              } else if (
                nonAvailabilityDto.type2 === NonAvailabilityDtoType.BLOCKED ||
                nonAvailabilityDto.type2 ===
                  NonAvailabilityDtoType.NOTAVAILABLE ||
                nonAvailabilityDto.type2 === NonAvailabilityDtoType.NOTALLOWED
              ) {
                events.unavailabilities.push({
                  remoteVehicleId: nonAvailabilityDto.vehicleId,
                  remoteId: nonAvailabilityDto?.availability?.id,
                  internalId: this.getNextId('UnavailabilityEvent'),
                  type: UnavailabilityType.CANTBEUSED,
                  fromDate: DateUtil.convertToDate(
                    nonAvailabilityDto.plannedPeriod.start
                  ),
                  toDate: DateUtil.convertToDate(
                    nonAvailabilityDto.plannedPeriod.end
                  ),
                  data: nonAvailabilityDto
                } as UnavailabilityEvent);
              }
            }
          );
        }

        if (
          availabilityEventsPerVehicleDto &&
          availabilityEventsPerVehicleDto.availabilities
        ) {
          availabilityEventsPerVehicleDto.availabilities.forEach(
            (availabilityDto: AvailabilityDto) => {
              events.availabilities.push({
                remoteVehicleId: availabilityDto.vehicle.id,
                remoteId: availabilityDto.id,
                internalId: this.getNextId('AvailabilityEvent'),
                fromDate: DateUtil.convertToDate(availabilityDto.period.start),
                toDate: DateUtil.convertToDate(availabilityDto.period.end),
                data: availabilityDto
              } as AvailabilityEvent);
            }
          );
        }
      });
    }

    return events;
  }

  static convertVehicleDtoToVehicle(vehicleDto: VehicleDto): Vehicle {
    return {
      internalId: this.getNextId('Vehicle'),
      remoteId: vehicleDto.id,
      licensePlate: vehicleDto.licensePlate,
      imageUrl: vehicleDto.imageUrl,
      name: vehicleDto.name,
      type: VehicleType.POOL,
      data: vehicleDto,
      filtered: false,
      description: vehicleDto.description,
      availability: null
    };
  }

  static convertVehiclePageDtoToVehiclesArray(
    vehiclePageDto: VehiclePageDto
  ): Vehicle[] {
    let vehicles: Vehicle[] = [];
    if (vehiclePageDto && vehiclePageDto.vehicles) {
      vehicles = vehiclePageDto.vehicles.map(vehicleDto =>
        this.convertVehicleDtoToVehicle(vehicleDto)
      );
    }
    return vehicles;
  }

  static getFileExtension(fileName): string {
    if (!fileName) {
      return null;
    }
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex < 0) {
      return null;
    }
    return fileName.substring(lastIndex + 1, fileName.length);
  }

  static completeMimeType(mimeType: string, fileName: string): string {
    if (!!mimeType) {
      return mimeType;
    }
    const fileExtension = this.getFileExtension(fileName);
    if (!fileExtension) {
      return null;
    }
    switch (fileExtension) {
      case 'bmp':
        return 'image/bmp';
      case 'gif':
        return 'image/gif';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
    }
    return null;
  }

  static convertAttachmentDtoToConversationMessageAttachment(
    attachment: AttachmentDto,
    file: File
  ): ConversationMessageAttachment {
    if (!attachment) {
      return null;
    }
    const fileName = !!attachment.name ? attachment.name : '';
    const attach: ConversationMessageAttachment = {
      ...attachment,
      name: fileName,
      mimeType: this.completeMimeType(attachment.mimeType, fileName),
      downloadStatus: !file
        ? AttachmentDownloadStatus.NOT_STARTED
        : AttachmentDownloadStatus.SUCCESSFUL,
      data: file
    };
    attach.isImage = this.isImageAttachment(attach);
    return attach;
  }

  static isImageAttachment(attachment: ConversationMessageAttachment): boolean {
    return !!attachment.mimeType && attachment.mimeType.startsWith('image/');
  }

  static convertMessageDtoToConversationMessage(
    message: MessageDto,
    file: File
  ): ConversationMessage {
    return {
      id: message.id,
      message: message.message,
      messageType: message.messageType,
      sender: message.sender,
      timeStamp: message.timeStamp,
      attachment: this.convertAttachmentDtoToConversationMessageAttachment(
        message.attachment,
        file
      ),
      userImage: {
        imageUrl: message.sender.imageUrl,
        imageSize: '40',
        displayName: message.sender.displayName,
        userName: message.sender.userName
      }
    };
  }

  static convertMessageDtosToConversationMessages(
    messages: MessageDto[]
  ): ConversationMessage[] {
    return messages.map(message =>
      this.convertMessageDtoToConversationMessage(message, null)
    );
  }

  static convertBookingUserDtoToUserReferenceDto(
    user: BookingUserDto
  ): UserReferenceDto {
    return {
      displayName: user.displayName,
      remoteId: user.remoteId,
      imageUrl: user.imageUrl,
      userName: user.userName
    } as UserReferenceDto;
  }

  static getBatteryStatusClasses(dto: BatteryStatusDto): BatteryStatusClasses {
    const classes: BatteryStatusClasses = {
      'very-low': dto.batteryPercentage <= 20,
      low: dto.batteryPercentage > 20 && dto.batteryPercentage <= 50,
      normal: dto.batteryPercentage > 50 && dto.batteryPercentage <= 85,
      good: dto.batteryPercentage > 85
    };
    return classes;
  }

  static getBatteryStatusChargingIcon(
    dto: BatteryStatusDto,
    inError: boolean
  ): string {
    if (!dto || inError) {
      return 'batt-icon-battery-alert';
    }
    if (
      dto.charging === ChargingStatusDto.NORMALCHARGE &&
      dto.batteryPercentage >= 0 &&
      dto.batteryPercentage < 100
    ) {
      return 'batt-icon-battery-charging';
    }
    if (
      dto.charging === ChargingStatusDto.QUICKCHARGE &&
      dto.batteryPercentage >= 0 &&
      dto.batteryPercentage < 100
    ) {
      return 'batt-icon-battery-fast-charging';
    }
    if (
      (dto.charging === ChargingStatusDto.NOTCHARGING &&
        dto.batteryPercentage >= 0) ||
      dto.batteryPercentage === 100
    ) {
      return 'batt-icon-battery-not-charging';
    }
    return 'batt-icon-battery-unknown';
  }

  static convertBatteryStatusDtoToBatteryStatusWithDetail(
    dto: BatteryStatusDto,
    inError: boolean
  ): BatteryStatusWithDetail {
    const batteryStatus: BatteryStatusWithDetail = {
      ...dto,
      inError,
      chargingStatusIcon: this.getBatteryStatusChargingIcon(dto, inError),
      batteryStatusClasses: this.getBatteryStatusClasses(dto)
    };
    return batteryStatus;
  }
}
