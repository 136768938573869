import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserImage } from '../user-avatar/types/user-image.type';

@Component({
  selector: 'sof-conversation-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div *ngIf="showTimestamp" class="message-timestamp">
        {{ timeStamp | toFormattedConversationDate }}
      </div>
      <div class="message-info">
        <div class="sender-image-container">
          <sof-user-avatar
            *ngIf="showUsername && !isOwnMessage"
            [userImage]="userImage"
          ></sof-user-avatar>
        </div>
        <div
          class="message-text"
          [ngClass]="{
            'owner-message': isOwnMessage,
            'no-round-up': noRoundUp,
            'no-round-down': noRoundDown,
            'first-message': firstMessage
          }"
        >
          {{ message }}
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./conversation-message.component.scss']
})
export class ConversationMessageComponent {
  @Input() message: string;
  @Input() timeStamp: string;
  @Input() userImage: UserImage;
  @Input() noRoundUp: boolean;
  @Input() noRoundDown: boolean;
  @Input() showUsername: boolean;
  @Input() isOwnMessage: boolean;
  @Input() showTimestamp: boolean;
  @Input() firstMessage: boolean;
}
