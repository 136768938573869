import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  OnChanges,
  OnInit
} from '@angular/core';
import { WindowRefService } from '@sofico-framework/utils';
import { SchedulerSetting } from '../../../scheduler.setting';
import { Duration } from '../../../classes/duration.class';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  merge,
  Observable,
  of,
  Subject
} from 'rxjs';
import { takeUntilDestroy, Changes, UntilDestroy } from 'ngx-reactivetoolkit';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pairwise,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { BookingUpdate } from '../../../types/booking-update.type';
import { BookingWithStyle } from '../../../types/booking-with-style.type';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipContentValuesEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import { Tooltip, TooltipType } from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { BookingSchedulerRowWithStyle } from '../../../types/booking-scheduler-row-with-style.type';
import { UnavailabilityEventWithStyle } from '../../../types/unavailability-event-with-style.type';
import { AvailabilityEventWithStyle } from '../../../types/availability-event-with-style.type';
import { BookingAdd } from '../../../types/booking-add.type';
import { BookingUserDto } from '../../../../../client';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';

@UntilDestroy()
@Component({
  selector: 'sof-booking-scheduler-body-row',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="subrows">
      <sof-booking-scheduler-body-subrow
        *ngFor="
          let bookingsWithStyle of rowOfBookingsWithStyle.subRowsOfBooking;
          trackBy: trackByIndex
        "
        [style.height.%]="100 / rowOfBookingsWithStyle.subRowsOfBooking.length"
        [bookingsWithStyle]="bookingsWithStyle"
        [cells]="cells"
        [schedulerDateFrom]="schedulerDateFrom"
        [msPerPixel]="msPerPixel"
        [smallestDragUnit]="smallestDragUnit"
        [highlighting]="highlighting"
        [internalBookingDraggedId]="internalBookingDraggedId"
        [sharedTooltipEvent]="sharedTooltipEvent"
        [currentUser]="currentUser"
        (isResizingBooking)="isResizingBooking$.next($event)"
        (updateBooking)="updateBooking.emit($event)"
        (mouseDownOnBooking)="mouseDownOnBooking.emit($event)"
        (dblClickOnBooking)="dblClickOnBooking.emit($event)"
        (tooltipEvent)="tooltipEvent.emit($event)"
      >
      </sof-booking-scheduler-body-subrow>
    </div>

    <!-- global availability component for all vehicles-->
    <sof-vehicle-global-availability
      [vehicleAvailability]="rowOfBookingsWithStyle.vehicleAvailability"
      [schedulerDateStart]="schedulerDateFrom"
      [schedulerDateEnd]="schedulerDateTo"
    >
    </sof-vehicle-global-availability>

    <!-- unavailability component -->
    <sof-vehicle-availability
      class="vehicle-availability"
      *ngFor="
        let activityWithStyle of rowOfBookingsWithStyle.vehicleAvailabilities;
        trackBy: trackByIndex
      "
      [activity]="activityWithStyle"
      [ngStyle]="activityWithStyle.positionAndSize"
      [ngClass]="activityWithStyle.classes"
      [sharedTooltipEvent]="sharedTooltipEvent"
      [attr.tooltip-parent-id]="activityWithStyle.internalId"
      (dblClickOnContent)="dblClickOnAvailability.emit($event)"
      (tooltipEvent)="tooltipEvent.emit($event)"
    >
    </sof-vehicle-availability>

    <!-- unavailability component -->
    <sof-vehicle-unavailability
      class="vehicle-unavailability"
      *ngFor="
        let activityWithStyle of rowOfBookingsWithStyle.vehicleUnavailabilities;
        trackBy: trackByIndex
      "
      [activity]="activityWithStyle"
      [ngStyle]="activityWithStyle.positionAndSize"
      [ngClass]="{
        'begin-before': activityWithStyle.classes?.beginBefore,
        'end-after': activityWithStyle.classes?.endAfter,
        'private-usage-overdue': activityWithStyle.classes?.privateUsageOverdue
      }"
      [sharedTooltipEvent]="sharedTooltipEvent"
      [attr.tooltip-parent-id]="activityWithStyle.internalId"
      (dblClickOnContent)="dblClickOnUnavailability.emit($event)"
      (tooltipEvent)="tooltipEvent.emit($event)"
    >
    </sof-vehicle-unavailability>

    <!-- highlighted area displayed when the user is dragging to create a booking -->
    <sof-booking-scheduler-highlighted-area
      class="highlighted-area"
      [schedulerDateFrom]="schedulerDateFrom"
      [msPerPixel]="msPerPixel"
      [smallestDragUnit]="smallestDragUnit"
      [leftPx]="(creationHighlightedAreaLeftAndWidth$ | async)?.left"
      [widthPx]="(creationHighlightedAreaLeftAndWidth$ | async)?.width"
      [display]="(isDragging$ | async) ? 'block' : 'none'"
      (fromDate)="creationHighlightedAreaFromDate = $event"
      (toDate)="creationHighlightedAreaToDate = $event"
    >
    </sof-booking-scheduler-highlighted-area>

    <!-- highlighted area displayed in the row when the user is moving a booking, moving logic is in the scheduler body -->
    <sof-booking-scheduler-highlighted-area
      *ngIf="highlightedArea"
      class="highlighted-area-move"
      [schedulerDateFrom]="schedulerDateFrom"
      [msPerPixel]="msPerPixel"
      [smallestDragUnit]="smallestDragUnit"
      [leftPx]="highlightedArea.left"
      [widthPx]="highlightedArea.width"
      [shiftKeyPressed]="highlightedArea.shiftKeyPressed"
      (fromDate)="highlightedAreaFromDate$.next($event)"
      (toDate)="highlightedAreaToDate$.next($event)"
    >
    </sof-booking-scheduler-highlighted-area>

    <!-- the highlighted area displayed when the user wants to display the matching periods of the booking request selected -->
    <sof-booking-request-highlighted-area
      *ngIf="rowOfBookingsWithStyle.bookingRequestHighlighted as bookingRequest"
      [bookingRequest]="bookingRequest"
      [ngStyle]="bookingRequest.posAndSize"
    >
    </sof-booking-request-highlighted-area>
  `,
  styleUrls: ['./booking-scheduler-body-row.component.scss']
})
export class BookingSchedulerBodyRowComponent implements OnChanges, OnInit {
  @Input() rowOfBookingsWithStyle: BookingSchedulerRowWithStyle;
  @Input() cells: BookingSchedulerDate[];
  @Input() schedulerDateFrom: Date;
  @Input() schedulerDateTo: Date;
  @Input() msPerPixel: number;
  @Input() smallestDragUnit: Duration;
  @Input() highlighting: boolean;
  @Input() highlightedArea: {
    width: number;
    left: number;
    shiftKeyPressed: boolean;
  } = null;
  @Input() internalBookingDraggedId: string;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  newBooking: EventEmitter<BookingAdd> = new EventEmitter<BookingAdd>();

  @Output()
  updateBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();

  @Output()
  highlightedAreaDates: EventEmitter<{
    fromDate: Date;
    toDate: Date;
  }> = new EventEmitter<{ fromDate: Date; toDate: Date }>();

  @Output()
  mouseDownOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();

  @Output()
  dblClickOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();
  @Output()
  dblClickOnUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  dblClickOnAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  isDraggingBooking: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.isDragging') applyIsDraggingClass = false;

  windowMouseUp$: Observable<MouseEvent> = fromEvent(
    this.windowsRef.nativeWindow,
    'mouseup'
  );

  mouseDown$: Observable<MouseEvent> = fromEvent(
    this.element.nativeElement,
    'mousedown'
  );
  mouseMove$: Observable<MouseEvent> = fromEvent(
    this.element.nativeElement,
    'mousemove'
  );
  mouseUp$: Observable<Event> = fromEvent(document, 'mouseup');

  // equal to true if the user is resizing a booking in a subrow
  isResizingBooking$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  // unavailabilitiesSubject$ = new BehaviorSubject(this.rowOfBookingsWithStyle$.subscribe());

  tooltipOnBookingCreation: Tooltip = {
    id: TooltipType.DRAG.toString(),
    type: TooltipType.DRAG,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      fromDate: undefined,
      toDate: undefined
    }
  };

  // the first and last date position of the highlighted area used to create a booking
  creationHighlightedAreaFromDate: Date;
  creationHighlightedAreaToDate: Date;

  // the first and last date position of the highlighted area received as input
  highlightedAreaFromDate$: Subject<Date> = new Subject<Date>();
  highlightedAreaToDate$: Subject<Date> = new Subject<Date>();

  // this stream contains the left position (in the row) of the last mouse down event
  // made on a cell (and not on a booking)
  mouseDownOnCellLeft$: Observable<number>;

  longMouseDownOnValidElement$: Observable<number>;

  // this stream contains the width of the drag
  // (the difference between the current mouse left position and the last mouse down position)
  draggingWidth$: Observable<number>;

  // isDragging$ is true if
  //  - the user is not resizing a booking in a subrow
  //  - the drag width is > 5 pixels
  isDragging$: Observable<boolean>;

  // contains the left position and the width of the creation highlighted area
  creationHighlightedAreaLeftAndWidth$: Observable<{
    width: number;
    left: number;
  }>;

  constructor(
    private element: ElementRef,
    private windowsRef: WindowRefService
  ) {}

  hasClass(element: HTMLElement, className: string): boolean {
    return element && element.classList.contains(className);
  }

  isValidClassToStartDragging(event: MouseEvent): boolean {
    const element: HTMLElement = event.target as HTMLElement;
    return (
      this.hasClass(element, 'cell') ||
      this.hasClass(element.parentElement, 'vehicle-unavailability') ||
      this.hasClass(element.parentElement, 'vehicle-availability')
    );
  }

  trackByIndex = i => i;

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.mouseDownOnCellLeft$ = this.mouseDown$.pipe(
      filter((event: MouseEvent) => this.isValidClassToStartDragging(event)),
      map((event: MouseEvent) => {
        return (
          event.pageX - this.element.nativeElement.getBoundingClientRect().left
        );
      })
    );

    this.longMouseDownOnValidElement$ = this.mouseDown$.pipe(
      filter((event: MouseEvent) => this.isValidClassToStartDragging(event)),
      mergeMap(event => of(event).pipe(delay(400), takeUntil(this.mouseUp$))),
      map((event: MouseEvent) => {
        return (
          event.pageX - this.element.nativeElement.getBoundingClientRect().left
        );
      })
    );

    // this stream contains the width of the drag
    // (the difference between the current mouse left position and the last mouse down position)
    this.draggingWidth$ = combineLatest([
      this.longMouseDownOnValidElement$,
      this.mouseMove$
    ]).pipe(
      // calculation of the width according to the actual mouse position and the handle dragged
      map(([mouseDownLeft, mouseMove]: [number, MouseEvent]) => {
        const mouseMovePosLeft =
          mouseMove.pageX -
          this.element.nativeElement.getBoundingClientRect().left;
        return mouseMovePosLeft - mouseDownLeft;
      })
    );

    // isDragging$ is true if
    //  - the user is not resizing a booking in a subrow
    //  - the drag width is > 5 pixels
    this.isDragging$ = combineLatest([
      merge(
        this.longMouseDownOnValidElement$.pipe(mapTo(true)),
        this.windowMouseUp$.pipe(mapTo(false))
      ),
      this.isResizingBooking$,
      this.draggingWidth$
    ]).pipe(
      filter(
        ([isDragging, isResizingBooking, draggingWidth]) =>
          !isResizingBooking && Math.abs(draggingWidth) > 5
      ),
      map(([isDragging, isResizingBooking, draggingWidth]) => isDragging),
      startWith(false),
      distinctUntilChanged(),
      debounceTime(50)
      // we add a debounceTime, otherwise, is Dragging pass from false to true to false very quickly when isResizing
      // booking pass to true
    );

    // contains the left position and the width of the creation highlighted area
    this.creationHighlightedAreaLeftAndWidth$ = combineLatest([
      this.longMouseDownOnValidElement$,
      this.draggingWidth$
    ]).pipe(
      map(([mouseDownLeft, draggingWidth]) => {
        // if the dragging width is negative, we recalculate the width/left of the highlighted booking
        // to allow a drag from right to left
        return draggingWidth > 0
          ? {
              width: draggingWidth,
              left: mouseDownLeft
            }
          : {
              width: -draggingWidth,
              left: mouseDownLeft + draggingWidth
            };
      })
    );

    // when the highlighted booking received as inputs changes
    // we emit the new dates of it
    combineLatest([this.highlightedAreaFromDate$, this.highlightedAreaToDate$])
      .pipe(takeUntilDestroy(this))
      .subscribe(([highlightedAreaFromDate, highlightedAreaToDate]) => {
        this.highlightedAreaDates.emit({
          fromDate: highlightedAreaFromDate,
          toDate: highlightedAreaToDate
        });
      });

    // when is dragging changes, we apply or not the isDragging class
    this.isDragging$
      .pipe(takeUntilDestroy(this))
      .subscribe(isDragging => (this.applyIsDraggingClass = isDragging));

    // when the user begins to drag, we create the tooltip
    this.isDragging$
      .pipe(
        pairwise(),
        filter(
          ([lastValue, newValue]) => lastValue === false && newValue === true
        ),
        withLatestFrom(this.mouseMove$),
        takeUntilDestroy(this)
      )
      .subscribe(
        ([isDragging, mouseEvent]: [[boolean, boolean], MouseEvent]) => {
          this.tooltipEvent.emit(
            new AddTooltipEvent({
              ...this.tooltipOnBookingCreation,
              targetRect: {
                width: SchedulerSetting.mouseSize.width,
                height: SchedulerSetting.mouseSize.height,
                top: mouseEvent.pageY,
                left: mouseEvent.pageX
              }
            })
          );
        }
      );

    // when it is dragging, and the mouse moves, we update the tooltip position and content
    combineLatest([this.isDragging$, this.mouseMove$])
      .pipe(
        filter(([isDragging, mouseMove]) => isDragging),
        takeUntilDestroy(this)
      )
      .subscribe(([isDragging, mouseMove]: [boolean, MouseEvent]) => {
        // we update the position
        this.tooltipEvent.emit(
          new UpdateTooltipTargetRectEvent(this.tooltipOnBookingCreation.id, {
            top: mouseMove.pageY,
            left: mouseMove.pageX
          })
        );
        // we update the content
        this.tooltipEvent.emit(
          new UpdateTooltipContentValuesEvent(
            this.tooltipOnBookingCreation.id,
            {
              fromDate: this.creationHighlightedAreaFromDate,
              toDate: this.creationHighlightedAreaToDate
            }
          )
        );
      });

    // at the end of a drag,
    // we emit a new booking, and we delete the tooltip creation
    this.isDragging$
      .pipe(
        pairwise(),
        filter(
          ([lastValue, newValue]) => lastValue === true && newValue === false
        ),
        takeUntilDestroy(this)
      )
      .subscribe(() => {
        // we emit the new booking
        this.newBooking.emit({
          internalVehicleId: this.rowOfBookingsWithStyle.internalVehicleId,
          remoteVehicleId: this.rowOfBookingsWithStyle.remoteVehicleId,
          fromDate: this.creationHighlightedAreaFromDate,
          toDate: this.creationHighlightedAreaToDate
        });
        // we delete the tooltip
        this.tooltipEvent.emit(
          new DeleteTooltipEvent(this.tooltipOnBookingCreation.id)
        );
      });

    // when the user is creating a booking, or resizing a booking in a subrow, we emit
    merge(this.isResizingBooking$, this.isDragging$)
      .pipe(takeUntilDestroy(this))
      .subscribe(value => this.isDraggingBooking.emit(value));
  }
}
