/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChargingStatusDto = 'NORMAL_CHARGE' | 'NOT_CHARGING' | 'QUICK_CHARGE' | 'UNKNOWN';

export const ChargingStatusDto = {
    NORMALCHARGE: 'NORMAL_CHARGE' as ChargingStatusDto,
    NOTCHARGING: 'NOT_CHARGING' as ChargingStatusDto,
    QUICKCHARGE: 'QUICK_CHARGE' as ChargingStatusDto,
    UNKNOWN: 'UNKNOWN' as ChargingStatusDto
};

