import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipContentDate } from '../../../../types/tooltip.type';
import { SchedulerSetting } from '../../../../scheduler.setting';

@Component({
  selector: 'sof-tooltip-content-date',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <i class="fa fa-clock-o" aria-hidden="true"> </i>

    <span class="date">
      <span class="day">
        {{ content.date | date: dayFormat }}
      </span>

      <span class="hour">
        {{ content.date | date: hourFormat }}
      </span>
    </span>
  `,
  styleUrls: ['./tooltip-content-date.component.scss']
})
export class TooltipContentDateComponent {
  @Input() content: TooltipContentDate;

  hourFormat = SchedulerSetting.dateFormat.hour;
  dayFormat = SchedulerSetting.dateFormat.day;
}
