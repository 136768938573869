import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'sof-conversation-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="lg"
      [headerLabel]="tc + '.CONVERSATION'"
      [hideDestroy]="true"
    >
      <div sof-dialog-body class="dialog-content">
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-conversation></sof-conversation>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CLOSE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./conversation-dialog.component.scss']
})
export class ConversationDialogComponent {
  @Input() tc: string;
  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
}
