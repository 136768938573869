import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchedulerViewHelper } from '../../view-helpers/scheduler-view.helper';

@Component({
  selector: 'sof-scheduler-edit-availability-dialog-view',
  template: `
    <ng-container>
      <sof-availability-dialog
        *ngIf="isVisible"
        [dialogData]="schedulerViewHelper.availabilityDialogData"
        (cancelDialog)="onCancelDialog()"
        (closeDialogAfterSave)="onCloseDialogAfterSave()"
      ></sof-availability-dialog>
    </ng-container>
  `
})
export class EditAvailabilityDialogViewComponent implements OnInit {
  isVisible = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public schedulerViewHelper: SchedulerViewHelper
  ) {}

  ngOnInit(): void {
    if (!this.schedulerViewHelper.availabilityDialogData) {
      this.onCancelDialog();
    } else {
      this.isVisible = true;
    }
  }

  onCancelDialog(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  onCloseDialogAfterSave(): void {
    this.router
      .navigate(['..'], { relativeTo: this.activatedRoute })
      .then(() => {
        this.schedulerViewHelper.triggerRefreshAvailabilityEvents();
      });
  }
}
