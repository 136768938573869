import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ActiveFilter } from '../../../types/active-filter.type';

@Component({
  selector: 'sof-active-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="active-filter"
      [ngClass]="{
        allowed: localValue,
        notallowed: localValue !== null && !localValue
      }"
    >
      <div class="allowed-icon">
        <sof-svg-icon [icon]="svgIcon"></sof-svg-icon>
      </div>
      <div
        *ngIf="!!label"
        class="label"
        nz-tooltip
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="label"
        [nzTooltipMouseEnterDelay]="0.5"
      >
        {{ label }}
      </div>
      <div
        *ngIf="!!translateKey"
        class="label"
        nz-tooltip
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="
          tc + '.' + translateKey | translate: interpolateParams
        "
        [nzTooltipMouseEnterDelay]="0.5"
      >
        {{ tc + '.' + translateKey | translate: interpolateParams }}
      </div>
      <div *ngIf="canRemove" class="remove-button-container">
        <button
          sofButton
          class="icon-button"
          icon="batt-icon-close"
          iconSize="12"
          (click)="onRemoveClick()"
        ></button>
      </div>
    </div>
  `,
  styleUrls: ['./active-filter.component.scss']
})
export class ActiveFilterComponent {
  @Input() tc: string;
  @Input() set value(value: null | boolean) {
    this.localValue = value;
    this.updateSvgIcon();
  }
  @Input() label: string;
  @Input() activeFilter: ActiveFilter;
  @Input() canRemove = true;
  @Input() translateKey: string;
  @Input() interpolateParams: any;
  svgIcon: string;
  localValue: null | boolean = null;

  @Output()
  removeActiveFilter: EventEmitter<ActiveFilter> = new EventEmitter<ActiveFilter>();

  updateSvgIcon(): void {
    if (this.localValue) {
      this.svgIcon = 'batt-icon-check';
    } else if (this.localValue !== null) {
      this.svgIcon = 'batt-icon-exclude';
    } else {
      this.svgIcon = null;
    }
  }

  onRemoveClick(): void {
    this.removeActiveFilter.emit(this.activeFilter);
  }
}
