import { ActionTypes } from '../action-types';
import { BookingUserDto } from '../../../../client';
import { CurrentUserActions } from '../actions/current-user.actions';

export function currentUserReducer(
  state: BookingUserDto = {},
  action: CurrentUserActions
): BookingUserDto {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_USER:
      return { ...action.payload.currentUser };
    default:
      return state;
  }
}
