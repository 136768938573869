export * from './availabilityControllerImpl.service';
import { AvailabilityControllerImplService } from './availabilityControllerImpl.service';
export * from './bookingControllerImpl.service';
import { BookingControllerImplService } from './bookingControllerImpl.service';
export * from './chatControllerImpl.service';
import { ChatControllerImplService } from './chatControllerImpl.service';
export * from './googleControllerImpl.service';
import { GoogleControllerImplService } from './googleControllerImpl.service';
export * from './incidentControllerImpl.service';
import { IncidentControllerImplService } from './incidentControllerImpl.service';
export * from './organizationControllerImpl.service';
import { OrganizationControllerImplService } from './organizationControllerImpl.service';
export * from './settingControllerImpl.service';
import { SettingControllerImplService } from './settingControllerImpl.service';
export * from './telematicsControllerImpl.service';
import { TelematicsControllerImplService } from './telematicsControllerImpl.service';
export * from './userControllerImpl.service';
import { UserControllerImplService } from './userControllerImpl.service';
export * from './vehicleGroupControllerImpl.service';
import { VehicleGroupControllerImplService } from './vehicleGroupControllerImpl.service';
export * from './vehicleUsageControllerImpl.service';
import { VehicleUsageControllerImplService } from './vehicleUsageControllerImpl.service';
export const APIS = [AvailabilityControllerImplService, BookingControllerImplService, ChatControllerImplService, GoogleControllerImplService, IncidentControllerImplService, OrganizationControllerImplService, SettingControllerImplService, TelematicsControllerImplService, UserControllerImplService, VehicleGroupControllerImplService, VehicleUsageControllerImplService];
