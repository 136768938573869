import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleControllerImplService } from '../../../client';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  constructor(
    private googleControllerImplService: GoogleControllerImplService
  ) {}

  getApiKey(): Observable<string> {
    return this.googleControllerImplService.getApiKey();
  }
}
