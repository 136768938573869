import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ConversationMessage } from '../conversation-messages/types/conversation-message.type';
import { ConversationWithDetail } from '../chat/types/conversation-with-detail.type';
import SharedUiUtils from '../../utils/shared-ui.utils';
import { DateUtil } from '../../../helpers/date-util';
import {
  BookingUserDto,
  ConversationTopicDto
} from '../../../../../client';

@Component({
  selector: 'sof-conversation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="conversation-detail-container">
        <div class="conversation-title">
          {{
            tc + '.TOPIC_' + conversation.topic
              | translate: { title: conversation.title }
          }}
        </div>
        <div class="participants-container">
          <sof-user-avatars-bar
            [users]="conversation?.allParticipants"
          ></sof-user-avatars-bar>
          <div class="participant-display-names">
            {{ conversation?.allParticipants | usersToDisplayNames }}
          </div>
        </div>
        <div *ngIf="conversation?.booking" class="booking-detail">
          <sof-license-plate
            *ngIf="conversation?.booking?.vehicle"
            class="scheduler-license-plate"
            countryCode="be"
            [value]="conversation.booking.vehicle.licensePlate"
          ></sof-license-plate>
          <div *ngIf="conversation?.booking?.vehicle" class="vehicle-name">
            {{ conversation?.booking?.vehicle.name }}
          </div>
          <div class="booking-period">
            <div>
              {{ tc + '.PLANNED-PERIOD' | translate }}
            </div>
            <sof-date-hour
              [date]="conversation?.booking.plannedPeriod.start"
            ></sof-date-hour>
            <sof-date-hour
              [date]="conversation?.booking.plannedPeriod.end"
            ></sof-date-hour>
          </div>
          <div class="status" [ngStyle]="statusStyle">
            {{ this.conversation?.booking?.status }}
          </div>
          <button
            *ngIf="canEditBooking"
            sofButton
            class="round-icon-button"
            (click)="editBooking.emit(conversation.booking.id)"
          >
            <sof-svg-icon icon="batt-icon-pencil" size="24"></sof-svg-icon>
          </button>
        </div>
      </div>
      <div
        class="conversation-messages-container"
        (scroll)="onScroll($event)"
        #conversationMessagesContainer
      >
        <sof-conversation-messages
          [tc]="tc"
          [messages]="messages"
          [currentUserId]="currentUser?.remoteId"
          (needEvaluateScrollPosition)="evaluateScrollPosition()"
          (retryAddAttachment)="retryAddAttachment.emit($event)"
          (downloadAttachment)="downloadAttachment.emit($event)"
        >
        </sof-conversation-messages>
      </div>
      <div class="conversation-text-message-container">
        <input
          #fileUpload
          hidden="hidden"
          type="file"
          accept="image/jpeg,image/png,image/bmp,image/gif,application/pdf"
          (change)="onFileChange($event)"
        />
        <button
          sofButton
          icon="batt-icon-share"
          iconSize="24"
          class="round-icon-button upload-button"
          (click)="fileUpload.click()"
        ></button>
        <div class="message">
          <input
            placeholder="Type your message here"
            autocomplete="off"
            class="message-input"
            (input)="onMessageChange($event)"
            (keyup.enter)="onKeyEnter()"
            [(ngModel)]="message"
            [disabled]="!canEdit"
          />
        </div>
        <button
          sofButton
          (click)="onAddMessageClick()"
          class="general-action-button main-action"
          [disabled]="!canSendMessage"
        >
          {{ tc + '.SEND-MESSAGE' | translate }}
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnChanges {
  @Input() tc: string;
  @Input() conversation: ConversationWithDetail;
  @Input() messages: ConversationMessage[];
  @Input() currentUser: BookingUserDto;
  @Input() clearMessage: Date;
  @Input() canEdit: boolean;

  @Output() addMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() addAttachment: EventEmitter<Array<File>> = new EventEmitter<
    Array<File>
  >();
  @Output()
  retryAddAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output()
  downloadAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output() editBooking: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('conversationMessagesContainer')
  conversationMessagesContainer: ElementRef;

  MAXSCROLLTOP = 10000000;
  canSendMessage = false;
  message = null;
  scrollPosition = this.MAXSCROLLTOP;

  statusStyle = null;
  canEditBooking = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearMessage) {
      this.canSendMessage = false;
      this.message = null;
    }
    if (changes.messages) {
      const change =
        changes.messages.currentValue?.length !==
        changes.messages.previousValue?.length;
      if (change) {
        this.scrollPosition = this.MAXSCROLLTOP;
      }
    }
    if (changes.conversation) {
      const booking = changes.conversation.currentValue.booking;
      if (booking) {
        const bookingColors = SharedUiUtils.getBookingColors(
          booking.status,
          DateUtil.convertToDate(booking.plannedPeriod.start),
          DateUtil.convertToDate(booking.plannedPeriod.end)
        );
        this.statusStyle = {
          color: bookingColors.text,
          border: '1.5px solid ' + bookingColors.border,
          'background-color': bookingColors.background
        };
      } else {
        this.statusStyle = null;
      }
    }
    this.updateCanEditBooking();
  }

  updateCanEditBooking(): void {
    this.canEditBooking = false;
    if (this.canEdit && this.conversation.booking && this.currentUser) {
      if (
        this.conversation.topic === ConversationTopicDto.APPROVAL &&
        SharedUiUtils.isUserVehicleOwnerOrAdmin(
          this.currentUser,
          this.conversation.booking.vehicle
        )
      ) {
        this.canEditBooking = true;
      } else if (
        this.conversation.topic !== ConversationTopicDto.APPROVAL &&
        SharedUiUtils.isUserBookingOwnerOrAdmin(
          this.currentUser,
          this.conversation.booking
        )
      ) {
        this.canEditBooking = true;
      }
    }
  }

  onKeyEnter(): void {
    if (this.canSendMessage) {
      this.addMessage.emit(this.message.trim());
    }
  }

  onAddMessageClick(): void {
    this.addMessage.emit(this.message.trim());
  }

  onMessageChange(event): void {
    this.canSendMessage =
      event.currentTarget.value && event.currentTarget.value.trim().length > 0;
  }

  onScroll(event): void {
    if (
      this.conversationMessagesContainer.nativeElement.scrollTop >=
      this.conversationMessagesContainer.nativeElement.clientHeight
    ) {
      this.scrollPosition = this.MAXSCROLLTOP;
    } else {
      this.scrollPosition = this.conversationMessagesContainer.nativeElement.scrollTop;
    }
  }

  evaluateScrollPosition(): void {
    // Scroll to bottom?
    if (this.scrollPosition === this.MAXSCROLLTOP) {
      this.conversationMessagesContainer.nativeElement.scrollTop = this.MAXSCROLLTOP;
    }
  }

  onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: Array<File> = Array.from(target.files);

    // Needed to be able to detect a change if the user select the same file again (can happen for instance if first time attachment fails)
    target.value = null;

    if (files.length < 1) {
      return;
    }
    this.addAttachment.emit(files);
  }
}
