import { ActionTypes } from '../action-types';
import { ActiveSchedulerFilters } from '../../types/active-scheduler-filters.type';
import { SchedulerSetting } from '../../scheduler.setting';
import { ActiveSchedulerFiltersActions } from '../actions/filter-set.actions';

export function activeSchedulerFiltersReducer(
  state: ActiveSchedulerFilters = {
    ...SchedulerSetting.defaultActiveSchedulerFilters
  },
  action: ActiveSchedulerFiltersActions
): ActiveSchedulerFilters {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_SCHEDULER_FILTERS:
      return { ...action.payload.activeSchedulerFilters };
  }
  return state;
}
