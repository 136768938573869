import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TooltipCreationType } from '../../../types/tooltip-settings.type';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import {
  Tooltip,
  TooltipContentAvailability,
  TooltipType
} from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { SchedulerSetting, tooltipSettings } from '../../../scheduler.setting';
import { AvailabilityEventWithStyle } from '../../../types/availability-event-with-style.type';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'sof-vehicle-availability',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./vehicle-availability.component.scss'],
  template: `
    <div
      class="content"
      (click)="this.mouseClick$.next($event)"
      (dblclick)="onContentDblClick($event)"
      (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event)"
      (mouseenter)="onContentMouseEnter($event)"
      (mousemove)="onContentMouseMove($event)"
      (mouseleave)="onContentMouseLeave()"
    ></div>
  `
})
export class VehicleAvailabilityComponent {
  // Containing details of display
  @Input() activity: AvailabilityEventWithStyle;
  @Input() sharedTooltipEvent: TooltipEvent;

  @Output()
  dblClickOnContent: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();

  tooltip: Tooltip = {
    id: TooltipType.AVAILABILITY,
    type: TooltipType.AVAILABILITY,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      availabilityEvent: undefined // assigned when the tooltip is created
    }
  };

  touchStartTimeStamp: number;

  mouseClick$: Subject<MouseEvent> = new Subject<MouseEvent>();
  mouseSimpleClick$ = this.mouseClick$.pipe(
    debounceTime(250),
    filter(event => {
      return event && event.buttons === 0 && event.detail === 1;
    })
  );

  constructor() {
    this.mouseSimpleClick$.subscribe(event => this.onContentSimpleClick(event));
  }

  // ------ Managing the long tap between touchstart and touchend events
  onTouchStart(ev: TouchEvent): void {
    this.touchStartTimeStamp = ev.timeStamp;
  }

  onTouchEnd(ev: TouchEvent): void {
    if (ev.timeStamp - this.touchStartTimeStamp >= 1000) {
      this.dblClickOnContent.emit(this.activity);
    }
  }

  // Triggered only on mouse enter mode
  // Emit an availability when enter
  onContentMouseEnter(ev: MouseEvent): void {
    if (
      tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER &&
      ev.buttons === 0
    ) {
      this.tooltipEvent.emit(
        new AddTooltipEvent({
          ...this.tooltip,
          targetRect: {
            width: SchedulerSetting.mouseSize.width,
            height: SchedulerSetting.mouseSize.height,
            left: ev.pageX,
            top: ev.pageY
          },
          content: {
            availabilityEvent: {
              ...this.activity
            },
            tooltipParentId: this.activity.internalId
          }
        })
      );
    }
  }

  onContentDblClick(ev: MouseEvent): void {
    this.dblClickOnContent.emit(this.activity);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
  }

  // When click on the availability and click mode triggered
  // => display / remove tooltip.
  onContentSimpleClick(ev: MouseEvent): void {
    if (
      !this.tooltipAlreadyOpened() &&
      tooltipSettings.creation === TooltipCreationType.MOUSE_CLICK &&
      ev.buttons === 0 &&
      ev.detail <= 1
    ) {
      this.tooltipEvent.emit(
        new AddTooltipEvent({
          ...this.tooltip,
          targetRect: {
            width: SchedulerSetting.mouseSize.width,
            height: SchedulerSetting.mouseSize.height,
            left: ev.pageX,
            top: ev.pageY
          },
          content: {
            availabilityEvent: this.activity,
            tooltipParentId: this.activity.internalId
          }
        })
      );
    }
  }

  // Triggered only when follow mouse mode is true
  // Displays tooltip following the mouse
  onContentMouseMove(ev: MouseEvent): void {
    if (tooltipSettings.followMouse && ev.buttons === 0) {
      this.tooltipEvent.emit(
        new UpdateTooltipTargetRectEvent(this.tooltip.id, {
          top: ev.pageY,
          left: ev.pageX
        })
      );
    }
  }

  // Triggered only on mouse enter mode
  // Delete the tooltip from the tooltips array
  onContentMouseLeave(): void {
    if (tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER) {
      this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
    }
  }

  tooltipAlreadyOpened(): boolean {
    if (
      !!this.sharedTooltipEvent &&
      this.sharedTooltipEvent instanceof AddTooltipEvent &&
      this.sharedTooltipEvent.payload.tooltip.type === TooltipType.AVAILABILITY
    ) {
      const content = this.sharedTooltipEvent.payload.tooltip
        .content as TooltipContentAvailability;
      return content.availabilityEvent.internalId === this.activity.internalId;
    }
    return false;
  }
}
