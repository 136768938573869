import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { BookingUserDto } from '../../../../../../client';
import { VehicleListRow } from '../../../../types/vehicle-list-row.type';
import { VehicleType } from '../../../../types/vehicle-type.type';
import { TooltipEvent } from '../../../../classes/tooltip-events.class';
import { interval, Observable } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import * as _ from 'lodash';
import { Vehicle } from '../../../../types/vehicle.type';

@UntilDestroy()
@Component({
  selector: 'sof-vehicle-card-list-body',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      *ngFor="let vehicleListRow of vehicleListRows; trackBy: trackByIndex"
      class="row"
      [ngStyle]="rowsHeightStyle[vehicleListRow.vehicle.internalId]"
      (mouseenter)="highlightedVehicle.emit(vehicleListRow.vehicle)"
      (mouseleave)="highlightedVehicle.emit(null)"
    >
      <div class="row-content" #rowContent>
        <sof-pool-vehicle-card
          *ngIf="isPoolVehicle(vehicleListRow.vehicle)"
          [tc]="tc"
          [vehicle]="vehicleListRow.vehicle"
          [sharedTooltipEvent]="sharedTooltipEvent"
          [attr.tooltip-parent-id]="vehicleListRow.vehicle.internalId"
          [currentUser]="currentUser"
          (tooltipEvent)="this.tooltipEvent.emit($event)"
          (dblClickEvent)="this.dblClickPoolVehicleEvent.emit($event)"
        >
        </sof-pool-vehicle-card>
      </div>
    </div>
  `,
  styleUrls: ['./vehicle-card-list-body.component.scss']
})
export class VehicleCardListBodyComponent implements AfterViewInit {
  @Input() tc: string;
  @Input() vehicleListRows: VehicleListRow[];
  @Input()
  rowsHeightStyle: {
    [key: string]: { 'minHeight.px': number; 'height.px': number };
  };
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  highlightedVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output()
  dblClickPoolVehicleEvent: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();

  @Output()
  newRowsHeightPx: EventEmitter<{ [key: string]: number }> = new EventEmitter<{
    [key: string]: number;
  }>();

  @ViewChildren('rowContent') rowsContentElementRef: QueryList<ElementRef>;

  // this stream contains the height in pixel of each rows
  // and only emits when, at least, a row height changes
  rowsHeightPx$: Observable<number[]> = interval(500).pipe(
    // we measure the rows height
    map(() => this.getRowsHeightPx()),
    startWith(null),
    // we filter to only emit when a row height changed
    pairwise(),
    filter(([lastValue, newValue]) => !_.isEqual(lastValue, newValue)),
    map(([lastValue, newValue]) => newValue)
  );

  isPoolVehicle(vehicle: Vehicle): boolean {
    return vehicle.type === VehicleType.POOL;
  }

  ngAfterViewInit(): void {
    this.rowsHeightPx$
      .pipe(
        // instead of indexing the heights with an array indice
        // we use the vehicleId of the row
        map(heightsInArray => {
          const rowsHeightPx: { [key: string]: number } = {};
          heightsInArray.forEach(
            (height, i) =>
              (rowsHeightPx[
                this.vehicleListRows[i].vehicle.internalId
              ] = height)
          );
          return rowsHeightPx;
        }),
        takeUntilDestroy(this)
      )
      .subscribe(rowsHeightPx => {
        // we emit the new value
        this.newRowsHeightPx.emit(rowsHeightPx);
      });
  }

  // returns the height in pixel of each rows
  // ! before ngAfterViewInit call, returns null
  getRowsHeightPx(): number[] {
    return this.rowsContentElementRef
      ? this.rowsContentElementRef.map(
          row => row.nativeElement.getBoundingClientRect().height
        )
      : null;
  }

  trackByIndex = i => i;
}
