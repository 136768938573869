import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  BookingUserDto,
  ConversationDto,
  MessageTypeDto
} from '../../../../../client';
import { ConversationMessage } from '../conversation-messages/types/conversation-message.type';
import { ConversationWithDetail } from '../chat/types/conversation-with-detail.type';

@Component({
  selector: 'sof-conversation-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <as-split direction="horizontal">
        <as-split-area
          [size]="30"
          [minSize]="20"
          class="conversations-container"
        >
          <div
            class="sof-card"
            *ngFor="let conversation of conversations; trackBy: tracker"
            (click)="onConversationClick(conversation)"
            [ngClass]="{
              highlighted: conversation.id === selectedConversation?.id
            }"
          >
            <div class="user-image-container">
              <sof-user-avatar
                [userImage]="
                  conversation
                    | conversationUser: currentUser
                    | toUserImage: '40'
                "
              ></sof-user-avatar>
            </div>
            <div class="conversation-container">
              <div class="user-info">
                {{
                  conversation
                    | conversationUser: currentUser
                    | toUserDisplayName
                }}
              </div>
              <div class="conversation-title">
                {{
                  tc + '.TOPIC_' + conversation.topic
                    | translate: { title: conversation.title }
                }}
              </div>
              <div
                class="last-message"
                *ngIf="
                  conversation.lastMessage &&
                  conversation.lastMessage.messageType === MESSAGE_TYPE_TEXT
                "
              >
                {{ conversation.lastMessage?.message }}
              </div>
              <div
                class="last-message"
                *ngIf="
                  conversation.lastMessage &&
                  conversation.lastMessage.messageType ===
                    MESSAGE_TYPE_ATTACHMENT
                "
              >
                {{ tc + '.ATTACHMENT-HAS-BEEN-UPLOADED' | translate }}
              </div>
            </div>
            <div class="last-message-date">
              {{ conversation | toFormattedConversationListDate }}
            </div>
            <div
              class="unread-message-badge"
              *ngIf="conversation.unreadMessages > 0"
            >
              {{ conversation.unreadMessages }}
            </div>
          </div>
        </as-split-area>
        <as-split-area [size]="70" [minSize]="50" class="messages-container">
          <sof-conversation
            *ngIf="selectedConversation"
            [tc]="tc"
            [conversation]="selectedConversation"
            [messages]="messages"
            [currentUser]="currentUser"
            [clearMessage]="clearMessage"
            [canEdit]="canEdit"
            (addMessage)="addMessage.emit($event)"
            (addAttachment)="addAttachment.emit($event)"
            (retryAddAttachment)="retryAddAttachment.emit($event)"
            (downloadAttachment)="downloadAttachment.emit($event)"
            (editBooking)="editBooking.emit($event)"
          >
          </sof-conversation>
        </as-split-area>
      </as-split>
    </ng-container>
  `,
  styleUrls: ['./conversation-list.component.scss']
})
export class ConversationListComponent {
  MESSAGE_TYPE_TEXT = MessageTypeDto.TEXTMESSAGE;
  MESSAGE_TYPE_ATTACHMENT = MessageTypeDto.ATTACHMENTMESSAGE;

  @Input() tc: string;
  @Input() conversations: ConversationDto[];
  @Input() messages: ConversationMessage[];
  @Input() currentUser: BookingUserDto;
  @Input() selectedConversation: ConversationWithDetail;
  @Input() clearMessage: Date;
  @Input() canEdit: boolean;

  @Output()
  selectedConversationChange: EventEmitter<ConversationDto> = new EventEmitter<ConversationDto>();
  @Output() addMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() addAttachment: EventEmitter<Array<File>> = new EventEmitter<
    Array<File>
  >();
  @Output()
  retryAddAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output()
  downloadAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output() editBooking: EventEmitter<string> = new EventEmitter<string>();

  tracker(index: number, conversation: ConversationDto): string {
    return conversation.id;
  }

  onConversationClick(conversation: ConversationDto): void {
    if (
      !this.selectedConversation ||
      this.selectedConversation.id !== conversation.id
    ) {
      this.selectedConversationChange.emit(conversation);
    }
  }
}
