import { Component } from '@angular/core';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'sof-member-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="memberViewSwitchModeButtons"
      ></sof-switch-mode-bar>
    </ng-container>
  `,
  styleUrls: ['./member-view.component.scss']
})
export class MemberViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_MEMBER';
  memberViewSwitchModeButtons = AppSettings.memberViewSwitchModeButtons;
}
