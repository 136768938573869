import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserReferenceDto } from '../../../../../client';

@Component({
  selector: 'sof-user-avatars-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="maxAvatarsCount" class="avatars-container">
      <sof-user-avatar
        *ngFor="let user of users | slice: 0:maxAvatarsCount"
        [userImage]="user | toUserImage: '40'"
      ></sof-user-avatar>
    </div>
    <div *ngIf="!maxAvatarsCount" class="avatars-container">
      <sof-user-avatar
        *ngFor="let user of users"
        [userImage]="user | toUserImage: '40'"
      ></sof-user-avatar>
    </div>
  `,
  styleUrls: ['./user-avatars-bar.component.scss']
})
export class UserAvatarsBarComponent {
  @Input() users: Array<UserReferenceDto>;
  @Input() maxAvatarsCount: number;
}
