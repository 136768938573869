import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sof-vehicles-found-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <sof-result-found-label
        [tc]="tc"
        [resultCount]="vehiclesFound"
        noResultLabel="NO-VEHICLE-FOUND"
        oneResultLabel="ONE-VEHICLE-FOUND"
        multipleResultsLabel="MULTIPLE-VEHICLES-FOUND"
      >
      </sof-result-found-label>
    </ng-container>
  `
})
export class VehiclesFoundLabelComponent {
  @Input() tc: string;
  @Input() vehiclesFound: number;
}
