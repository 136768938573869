import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maxArraySize' })
export class MaxArraySizePipe implements PipeTransform {
  transform(array: Array<any>, maxSize: number): Array<any> {
    if (!array) {
      return [];
    }
    return array.filter((activeFilter, index) => index < maxSize);
  }
}
