import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@sofico-framework/ui-kit/components/breadcrumb';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { DriverGradeFilterDto } from  '../../../../../client';
import { AppSettings } from '../../../app.settings';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationDriverGradeDetailViewHelper } from '../../view-helpers/organization-driver-grade-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-organization-driver-grade-detail-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="organizationViewSwitchModeButtons"
      ></sof-switch-mode-bar>
      <sof-breadcrumb
        [tc]="tc"
        [breadcrumbs]="breadcrumbs$ | async"
      ></sof-breadcrumb>
      <div class="driver-grade-info-container">
        <sof-driver-grade
          *ngIf="vh.driverGrade$ | async"
          [driverGrade]="vh.driverGrade$ | async"
        ></sof-driver-grade>
        <div class="spacer-container"></div>
        <div class="action-button-container">
          <button
            sofButton
            icon="batt-icon-pencil"
            class="main-action-button"
            [routerLink]="['edit-driver-grade']"
          >
            {{ tc + '.EDIT-DRIVER-GRADE' | translate }}
          </button>
          <button
            sofButton
            icon="batt-icon-delete"
            class="main-action-button"
            (click)="onRemoveClick()"
          >
            {{ tc + '.REMOVE-DRIVER-GRADE' | translate }}
          </button>
        </div>
      </div>
      <div class="filters-container">
        <div class="filters-container-header">
          <div class="filters-label">
            {{ tc + '.DRIVER-GRADE-RULES' | translate }}
          </div>
          <div class="action-button-container">
            <button
              sofButton
              icon="batt-icon-plus"
              class="main-action-button"
              (click)="openDriverGradeFilterPopup()"
            >
              {{ tc + '.CREATE-DRIVER-GRADE-FILTER' | translate }}
            </button>
          </div>
        </div>
        <sof-driver-grade-filter-list
          [tc]="tc"
          [driverGradeFilters]="vh.driverGradeFilters$ | async"
          [organizationId]="vh.organizationId$ | async"
          [driverGradeId]="vh.driverGradeId$ | async"
          (editDriverGradeFilter)="openDriverGradeFilterPopup($event)"
        >
        </sof-driver-grade-filter-list>
      </div>
      <router-outlet></router-outlet>
    </ng-container>
  `,
  providers: [OrganizationDriverGradeDetailViewHelper],
  styleUrls: ['./organization-driver-grade-detail-view.component.scss']
})
export class OrganizationDriverGradeDetailViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-DRIVER-GRADE-DETAIL';

  organizationViewSwitchModeButtons =
    AppSettings.organizationViewSwitchModeButtons;

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastUtilService: ToastUtilService,
    private dialogService: DialogService,
    public vh: OrganizationDriverGradeDetailViewHelper
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.getBreadcrumbs$();
  }

  getBreadcrumbs$(): Observable<Breadcrumb[]> {
    return combineLatest([this.vh.organization$, this.vh.driverGrade$]).pipe(
      map(([organizationDto, driverGradeDto]) => {
        const breadcrumbs = [];
        breadcrumbs.push({
          label: 'ORGANIZATION-VIEW',
          path: '/organizations'
        });
        breadcrumbs.push({
          translation: organizationDto?.name,
          path: '/organizations/' + organizationDto?.id + '/driver-grades'
        });
        breadcrumbs.push({
          translation: 'Driver grade : ' + driverGradeDto?.name
        });
        return breadcrumbs;
      })
    );
  }

  onRemoveClick(): void {
    const confirm$ = combineLatest([
      this.vh.driverGradeId$,
      this.vh.organizationId$
    ]).pipe(
      switchMap(([driverGradeId, organizationId]) =>
        this.osb.deleteDriverGrade(driverGradeId, organizationId)
      )
    );
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'DELETE-DIALOG-TITLE',
      'DELETE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() =>
          confirm$.pipe(
            take(1),
            catchError(error => of(error))
          )
        ),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(
            v,
            this.tc + '.FAILED-REMOVE-DRIVER-GRADE'
          );
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REMOVE-DRIVER-GRADE',
            true
          );
          dialog.destroy();
          this.router.navigate(['..'], {
            relativeTo: this.activatedRoute
          });
        }
      });
  }

  openDriverGradeFilterPopup(
    driverGradeFilter: DriverGradeFilterDto = null
  ): void {
    this.vh.editedDriverGradeFilter = driverGradeFilter;
    this.router.navigate(['driver-grade-filter'], {
      relativeTo: this.activatedRoute
    });
  }
}
