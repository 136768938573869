import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import {
  en_GB,
  NZ_DATE_CONFIG,
  NZ_DATE_LOCALE,
  NZ_I18N
} from 'ng-zorro-antd/i18n';
import enGB from 'date-fns/esm/locale/en-GB';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from '@sofico-framework/authentication';
import { httpLoaderFactory } from '../factories/http-loader.factory';
import {
  CountryEnum,
  CurrencyEnum,
  CustomTranslationHandler,
  DateFormatEnum,
  getConfigurationParameters,
  HttpStatusService,
  setRootInjector,
  TimeFormatEnum,
  UtilServicesModule
} from '@sofico-framework/utils';
import { AppConfigModule } from '@sofico-framework/app-config';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { AppSandbox } from './app.sandbox';
import {
  ApiModule,
  Configuration as ApiConfiguration
} from '../../client';
import { VehicleManagementModule } from './vehicle-management-ui/vehicle-management.module';
import { SchedulerModule } from './scheduler-ui/scheduler.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// redux
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DamageManagementModule } from './damage-management-ui/damage-management.module';
import { OrganizationManagementModule } from './organization-management-ui/organization-management.module';
import { IconSetModule } from '@sofico-framework/ui-kit/components/icon-set';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { TabsModule } from '@sofico-framework/ui-kit/components/tabs';
import { ToastHookModule } from '@sofico-framework/ui-kit/components/toast-hook';
import { TopBarHeaderModule } from '@sofico-framework/ui-kit/components/top-bar-header';
import { TopBarNavModule } from '@sofico-framework/ui-kit/components/top-bar-nav';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundViewComponent } from './page-not-found-view/page-not-found-view.component';
import { BattIconSetComponentModule } from './shared-ui/components/batt-icon-set';
import { SharedUiModule } from './shared-ui/shared-ui.module';
import { HttpStatusInterceptor } from './interceptors/http-status.interceptor';
import { environment } from '../environments/environment';
import { currentUserReducer } from './statemanagement/reducers/current-user.reducer';
import { vehiclesReducer } from './statemanagement/reducers/vehicles.reducer';
import { carsNoReservationReducer } from './statemanagement/reducers/cars-no-reservation.reducer';
import { bookingsReducer } from './statemanagement/reducers/bookings.reducer';
import { activeSchedulerFiltersReducer } from './statemanagement/reducers/active-scheduler-filters.reducer';
import { userSettingsReducer } from './statemanagement/reducers/user-settings.reducer';
import { bookingRequestsReducer } from './statemanagement/reducers/booking-requests.reducer';
import { planningStateReducer } from './statemanagement/reducers/planning-state.reducer';
import { unavailabilitiesReducer } from './statemanagement/reducers/unavailabilities.reducer';
import { availabilitiesReducer } from './statemanagement/reducers/availabilities.reducer';
import { conversationsReducer } from './statemanagement/reducers/conversations.reducer';
import { messagesReducer } from './statemanagement/reducers/messages.reducer';
import { privateUsagesReducer } from './statemanagement/reducers/private-usages.reducer';

export function apiConfigurationFactory(): ApiConfiguration {
  return new ApiConfiguration(
    getConfigurationParameters(environment, 'web-bff-service/v1')
  );
}

@NgModule({
  declarations: [AppComponent, DashboardComponent, PageNotFoundViewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfigurationFactory),
    AuthenticationModule,
    UtilServicesModule,
    AppConfigModule.forRoot({
      defaultCountry: CountryEnum.BELGIUM,
      currencyCode: CurrencyEnum.EURO,
      dateFormat: DateFormatEnum.LITTLE_ENDIAN_SLASH,
      timeFormat: TimeFormatEnum.HH_TIME_M
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    VehicleManagementModule,
    DamageManagementModule,
    OrganizationManagementModule,
    SchedulerModule,
    StoreModule.forRoot({
      currentUser: currentUserReducer,
      vehicles: vehiclesReducer,
      carsNoReservation: carsNoReservationReducer, // NOTE cabu : deprecated
      bookings: bookingsReducer,
      activeSchedulerFilters: activeSchedulerFiltersReducer,
      userSettings: userSettingsReducer,
      bookingRequests: bookingRequestsReducer,
      planning: planningStateReducer,
      unavailabilities: unavailabilitiesReducer,
      availabilities: availabilitiesReducer,
      conversations: conversationsReducer,
      messages: messagesReducer,
      privateUsages: privateUsagesReducer
    }),
    StoreDevtoolsModule.instrument(),
    IconSetModule,
    TabsModule,
    TopBarHeaderModule,
    SvgIconModule,
    TopBarNavModule,
    BattIconSetComponentModule,
    SharedUiModule,
    ToastHookModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    AppSandbox,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      deps: [HttpStatusService],
      useClass: HttpStatusInterceptor
    },
    { provide: NZ_I18N, useValue: en_GB },
    { provide: NZ_DATE_LOCALE, useValue: enGB },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // we have to register the root injector to make it available in the decorators
  constructor(private injector: Injector) {
    setRootInjector(injector);
  }
}
