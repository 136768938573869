import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProviderEnum } from '../../../helpers/provider-enum';

@Component({
  selector: 'sof-vehicle-dialog-telematics-tab-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-form
      #vehicleTelematicsForm
      [tc]="tc"
      [formGroup]="telematicsForm"
      [errorMap]="errorMap"
      class="vehicle-dialog-form"
    >
      <div class="column-container">
        <div class="col-form">
          <div class="container new-data">
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.TELEMATICS-PROVIDER-DEVICE-ID' | translate"
                class="col-sm"
              >
                <sof-input-text
                  [sofInput]
                  [formControl]="telematicsForm?.controls?.providerDeviceId"
                ></sof-input-text>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.TELEMATICS-PROVIDER-ID' | translate"
                class="col-sm"
              >
                <sof-input-single-select
                  [sofInput]
                  [tc]="tc"
                  [formControl]="telematicsForm?.controls?.providerId"
                  [clearable]="false"
                  [options]="providerEnum | enumToArray"
                  [selectorValue]="providerValueFn"
                  [selectorLabel]="providerLabelFn"
                ></sof-input-single-select>
              </sof-input-wrapper>
            </div>
            <div class="row">
              <sof-input-wrapper
                [label]="tc + '.TELEMATICS-MAX-RANGE' | translate"
                class="col-sm"
              >
                <sof-input-number
                  [sofInput]
                  [formControl]="telematicsForm?.controls?.maxRange"
                ></sof-input-number>
              </sof-input-wrapper>
            </div>
            <div class="row telematics-actions-container">
              <button
                *ngIf="!isReadonly"
                sofButton
                class="general-action-button"
                [disabled]="isEditingTelematics"
                (click)="editTelematics.emit()"
              >
                {{ tc + '.EDIT-TELEMATICS' | translate }}
              </button>
              <button
                sofButton
                *ngIf="!isReadonly"
                class="general-action-button main-action"
                [disabled]="!isEditingTelematics"
                (click)="saveTelematics.emit()"
              >
                {{ tc + '.SAVE-TELEMATICS' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </sof-form>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogTelematicsTabContentComponent {
  @Input() tc: string;
  @Input() telematicsForm: FormGroup;
  @Input() isReadonly: boolean;
  @Input() isEditingTelematics: boolean;
  @Input() errorMap: { [p: string]: string };

  @Output() editTelematics: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveTelematics: EventEmitter<void> = new EventEmitter<void>();

  // Used by main component to focus on first required field
  @ViewChild('vehicleTelematicsForm') vehicleTelematicsForm: ElementRef;

  providerEnum = ProviderEnum;
  providerValueFn = p => p.key;
  providerLabelFn = p => p.value;
}
