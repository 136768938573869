import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { TooltipContent, TooltipType } from '../../../../types/tooltip.type';
import { Size } from '../../../../types/size.type';
import { Observable } from 'rxjs';
import { Changes } from 'ngx-reactivetoolkit';
import { delay } from 'rxjs/operators';
import {
  DeleteTooltipEvent,
  TooltipEvent
} from '../../../../classes/tooltip-events.class';
import { Booking } from '../../../../types/booking.type';
import { ApplyActiveSchedulerFilters } from '../../../../types/apply-active-scheduler-filters.type';
import { UnavailabilityEventWithStyle } from '../../../../types/unavailability-event-with-style.type';
import { AvailabilityEventWithStyle } from '../../../../types/availability-event-with-style.type';
import { Vehicle } from '../../../../types/vehicle.type';
import { BookingUserDto } from  '../../../../../../client';

@Component({
  selector: 'sof-tooltip',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="tooltip-content" [ngSwitch]="type">
      <!-- tooltip of a BOOKING -->
      <sof-tooltip-content-booking
        *ngSwitchCase="TooltipType.BOOKING"
        [content]="content"
        [tooltipId]="tooltipId"
        [attr.tooltip-parent-id]="content.tooltipParentId"
        (tooltipEvent)="this.tooltipEvent.emit($event)"
        (editBooking)="this.editBooking.emit($event)"
        (applyActiveSchedulerFilters)="
          this.applyActiveSchedulerFilters.emit($event)
        "
        (editConversation)="this.editConversation.emit($event)"
        sofClickOutside
        (clickOutside)="closeTooltip(tooltipId)"
        [forTooltip]="true"
      >
      </sof-tooltip-content-booking>

      <!-- tooltip of an AVAILABILITY -->
      <sof-tooltip-content-availability
        *ngSwitchCase="TooltipType.AVAILABILITY"
        [content]="content"
        [tooltipId]="tooltipId"
        [tc]="tc"
        [attr.tooltip-parent-id]="content.tooltipParentId"
        (tooltipEvent)="this.tooltipEvent.emit($event)"
        (editAvailability)="this.editAvailability.emit($event)"
        sofClickOutside
        (clickOutside)="closeTooltip(tooltipId)"
        [forTooltip]="true"
      >
      </sof-tooltip-content-availability>

      <!-- tooltip of an UNAVAILABILITY -->
      <sof-tooltip-content-unavailability
        *ngSwitchCase="TooltipType.UNAVAILABILITY"
        [content]="content"
        [tooltipId]="tooltipId"
        [tc]="tc"
        [attr.tooltip-parent-id]="content.tooltipParentId"
        [currentUser]="currentUser"
        (tooltipEvent)="this.tooltipEvent.emit($event)"
        (editUnavailability)="this.editUnavailability.emit($event)"
        (endVehicleUsage)="this.endVehicleUsage.emit($event)"
        sofClickOutside
        (clickOutside)="closeTooltip(tooltipId)"
        [forTooltip]="true"
      >
      </sof-tooltip-content-unavailability>

      <!-- tooltip of a DATE -->
      <!--
      <sof-tooltip-content-date *ngSwitchCase="TooltipType.DATE"
                                [content]="content">
      </sof-tooltip-content-date>
-->

      <!-- tooltip of a DRAG -->
      <sof-tooltip-content-drag
        *ngSwitchCase="TooltipType.DRAG"
        [content]="content"
      >
      </sof-tooltip-content-drag>

      <!-- tooltip of a pool vehicle -->
      <sof-tooltip-content-pool-vehicle
        *ngSwitchCase="TooltipType.POOL_VEHICLE"
        [content]="content"
        [tooltipId]="tooltipId"
        [tc]="tc"
        [attr.tooltip-parent-id]="content.tooltipParentId"
        [currentUser]="currentUser"
        (tooltipEvent)="this.tooltipEvent.emit($event)"
        (editVehicle)="this.editVehicle.emit($event)"
        (refreshVehicleLocation)="refreshVehicleLocation.emit($event)"
        (addBookingForVehicle)="addBookingForVehicle.emit($event)"
        (addAvailabilityForVehicle)="addAvailabilityForVehicle.emit($event)"
        (endVehicleUsage)="endVehicleUsage.emit($event)"
        sofClickOutside
        (clickOutside)="closeTooltip(tooltipId)"
        [forTooltip]="true"
      >
      </sof-tooltip-content-pool-vehicle>
    </div>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnChanges {
  @Input() type: TooltipType;
  @Input() content: TooltipContent;
  @Input() tooltipId: string;
  @Input() tc: string;
  @Input() currentUser: BookingUserDto;

  // we emit the size asynchronously because the emission is made in ngAfterViewInit,
  // and could cause an ExpressionChangedAfterItHasBeenCheckedError
  @Output() size: EventEmitter<Size> = new EventEmitter<Size>(true);
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output() editBooking: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output()
  applyActiveSchedulerFilters: EventEmitter<ApplyActiveSchedulerFilters> = new EventEmitter<ApplyActiveSchedulerFilters>();
  @Output()
  editConversation: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output()
  editUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  editAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output() editVehicle: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output()
  refreshVehicleLocation: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addBookingForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  addAvailabilityForVehicle: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  endVehicleUsage: EventEmitter<string> = new EventEmitter<string>();

  @Changes('content') contentUpdated$: Observable<null>;

  // we copy the reference to the enum to access it in the template
  TooltipType = TooltipType;

  // this is the margin between the targeted rect and the tooltip
  @HostBinding('style.margin.px') marginPx = 5;

  constructor(private element: ElementRef) {
    this.contentUpdated$.pipe(delay(0)).subscribe(() => {
      // we emit the new size of the tooltip
      this.size.emit({
        width:
          this.element.nativeElement.getBoundingClientRect().width +
          this.marginPx * 2,
        height:
          this.element.nativeElement.getBoundingClientRect().height +
          this.marginPx * 2
      });
    });
  }

  ngOnChanges(): void {}

  closeTooltip(tooltipId: string): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(tooltipId));
  }
}
