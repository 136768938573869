import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { EditAvailabilityDialogViewComponent } from './views/edit-availability-dialog-view/edit-availability-dialog-view.component';
import { SchedulerCreateBookingDialogViewComponent } from './views/scheduler-create-booking-dialog-view/scheduler-create-booking-dialog-view.component';
import { SchedulerEditBookingDialogViewComponent } from './views/scheduler-edit-booking-dialog-view/scheduler-edit-booking-dialog-view.component';
import { SchedulerEditVehicleDialogViewComponent } from './views/scheduler-edit-vehicle-dialog-view/scheduler-edit-vehicle-dialog-view.component';
import { SchedulerViewComponent } from './views/scheduler-view/scheduler-view.component';
import { NgModule } from '@angular/core';
import { SchedulerAuthorizationGuard } from '../guards/scheduler-authorization.guard';

const routes: Routes = [
  {
    path: '',
    component: SchedulerViewComponent,
    canActivate: [AuthenticatedGuard, SchedulerAuthorizationGuard],
    children: [
      {
        path: 'edit-vehicle',
        component: SchedulerEditVehicleDialogViewComponent,
        canActivate: [AuthenticatedGuard, SchedulerAuthorizationGuard]
      },
      {
        path: 'create-booking',
        component: SchedulerCreateBookingDialogViewComponent,
        canActivate: [AuthenticatedGuard, SchedulerAuthorizationGuard]
      },
      {
        path: 'edit-booking',
        component: SchedulerEditBookingDialogViewComponent,
        canActivate: [AuthenticatedGuard, SchedulerAuthorizationGuard]
      },
      {
        path: 'edit-availability',
        component: EditAvailabilityDialogViewComponent,
        canActivate: [AuthenticatedGuard, SchedulerAuthorizationGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulerRoutingModule {}
