import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/index';
import { debounceTime, map, take } from 'rxjs/operators';
import { Intent } from '../../../types/intent.type';
import { KeyLabel } from '../../../types/key-label.type';
import { TranslatedData } from '../../../types/translated-data.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';

@UntilDestroy()
@Component({
  selector: 'sof-vehicle-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./vehicle-filters.component.scss'],
  template: `
    <ng-container>
      <div class="row">
        <div class="filter-title">
          {{ tc + '.VEHICLE-FILTERS-WHAT-DO-YOU-WANT-TO-DO' | translate }}
        </div>
        <div class="spacer"></div>
        <sof-vehicles-found-label
          *ngIf="showVehiclesFound"
          [tc]="tc"
          [vehiclesFound]="vehiclesFound"
        ></sof-vehicles-found-label>
      </div>
      <div class="row">
        <sof-intent-button-group
          [disabled]="disabled"
          [intents]="intents"
          [values]="intentIds"
          (valuesChange)="intentIdsChange.emit($event)"
        ></sof-intent-button-group>
      </div>
      <sof-divider></sof-divider>
      <div class="row filter-title">
        {{ tc + '.VEHICLE-FILTERS-FILTERS' | translate }}
      </div>
      <div class="row">
        <input
          [disabled]="disabled"
          [placeholder]="
            tc + '.VEHICLE-FILTERS-SEARCH-ON-VEHICLE-NAME' | translate
          "
          autocomplete="off"
          class="col2 search-input"
          [value]="vehicleName"
          (input)="onVehicleNameChange($event)"
        />
        <sof-input-switch
          class="col1"
          [label]="tc + '.VEHICLE-FILTERS-MY-FAVORITES' | translate"
          [isDisabled]="disabled"
          [selected]="onlyFavorites"
          (changeValue)="onlyFavoritesChange.emit($event)"
        ></sof-input-switch>
        <sof-input-switch
          class="col1"
          [label]="tc + '.VEHICLE-FILTERS-INSTANT-BOOK' | translate"
          [isDisabled]="disabled"
          [selected]="onlyInstantBookingPossible"
          (changeValue)="onlyInstantBookingPossibleChange.emit($event)"
        ></sof-input-switch>
      </div>
      <div class="row">
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-VEHICLE-RANGE' | translate }}
            </div>
            <sof-input-slider
              [isDisabled]="disabled"
              [minValue]="VEHICLE_RANGE_MIN_VALUE"
              [maxValue]="VEHICLE_RANGE_MAX_VALUE"
              [labelFormatFn]="formatVehicleRange"
              [formControl]="formControlVehicleRange"
              (changeValue)="vehicleRangeSub$.next($event)"
            ></sof-input-slider>
          </div>
        </div>
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-PRICE-RANGE-PER-DAY' | translate }}
            </div>
            <sof-input-slider
              [isDisabled]="disabled"
              range="true"
              [minValue]="PRICE_RANGE_MIN_VALUE"
              [maxValue]="PRICE_RANGE_MAX_VALUE"
              [labelFormatFn]="formatPrice"
              [formControl]="formControlPriceRange"
              (changeValue)="priceRangeSub$.next($event)"
            ></sof-input-slider>
          </div>
        </div>
      </div>
      <div class="row top">
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-MAKE-MODEL' | translate }}
            </div>
            <sof-input-multi-select
              [tc]="tc"
              [formControl]="formControlModelIds"
              [isDisabled]="disabled"
              [options]="vehicleModels"
              [selectorValue]="keyLabelValueFn"
              [selectorLabel]="keyLabelLabelFn"
              (changeValue)="modelIdsChange.emit($event)"
            ></sof-input-multi-select>
          </div>
        </div>
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-NUMBER-OF-SEATS' | translate }}
            </div>
            <sof-input-single-select
              [tc]="tc"
              [formControl]="formControlMinimumSeats"
              [isDisabled]="disabled"
              [options]="seatChoices$ | async"
              [selectorValue]="translatedDataValueFn"
              [selectorLabel]="translatedDataLabelFn"
              (changeValue)="minimumSeatsChange.emit($event)"
            ></sof-input-single-select>
          </div>
        </div>
      </div>
      <sof-divider></sof-divider>
      <div class="row top">
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-ALLOWED' | translate }}
            </div>
            <div class="allowed-container">
              <sof-allowed-button
                [disabled]="disabled"
                [label]="tc + '.ALLOWED_PETS' | translate"
                [value]="petsAllowed"
                (valueChange)="petsAllowedChange.emit($event)"
              ></sof-allowed-button>
              <sof-allowed-button
                [disabled]="disabled"
                [label]="tc + '.ALLOWED_CHILDREN' | translate"
                [value]="kidsAllowed"
                (valueChange)="kidsAllowedChange.emit($event)"
              ></sof-allowed-button>
              <sof-allowed-button
                [disabled]="disabled"
                [label]="tc + '.ALLOWED_ABROAD' | translate"
                [value]="abroadAllowed"
                (valueChange)="abroadAllowedChange.emit($event)"
              ></sof-allowed-button>
            </div>
          </div>
        </div>
        <div class="col2 column">
          <div class="form-group">
            <div class="form-group-input-label">
              {{ tc + '.VEHICLE-FILTERS-FEATURES' | translate }}
            </div>
            <sof-input-multi-select
              [tc]="tc"
              [formControl]="formControlEquipmentIds"
              [isDisabled]="disabled"
              [options]="equipments"
              [selectorValue]="keyLabelValueFn"
              [selectorLabel]="keyLabelLabelFn"
              (changeValue)="equipmentIdsChange.emit($event)"
            ></sof-input-multi-select>
          </div>
        </div>
      </div>
    </ng-container>
  `
})
export class VehicleFiltersComponent implements OnInit {
  VEHICLE_RANGE_MIN_VALUE = ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE;
  VEHICLE_RANGE_MAX_VALUE = ActiveFiltersUtils.VEHICLE_RANGE_MAX_VALUE;
  PRICE_RANGE_MIN_VALUE = ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE;
  PRICE_RANGE_MAX_VALUE = ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE;
  KEY_SEAT_CHOICE_NO_PREFERENCE = 'SEAT-CHOICE-NO-PREFERENCE';
  KEY_SEAT_CHOICE_COUNT = 'SEAT-CHOICE';

  @Input() tc: string;
  @Input() disabled: boolean;
  @Input() intents: Array<Intent>;
  @Input() equipments: Array<KeyLabel>;
  @Input() vehicleModels: Array<KeyLabel>;
  @Input() intentIds: Array<string>;
  @Input() vehicleName: string;
  @Input() onlyFavorites: boolean;
  @Input() onlyInstantBookingPossible: boolean;
  @Input() set vehicleRange(vehicleRange: number) {
    this.internalVehicleRange = vehicleRange;
    this.formControlVehicleRange.setValue(vehicleRange);
  }
  @Input() set priceRange(priceRange: [number, number]) {
    this.internalPriceRange = priceRange;
    this.formControlPriceRange.setValue(priceRange);
  }
  @Input() set modelIds(modelIds: string[]) {
    this.internalModelIds = modelIds;
    this.formControlModelIds.setValue(modelIds);
  }
  @Input() set minimumSeats(minimumSeats: number) {
    this.internalMinimumSeats = minimumSeats;
    this.formControlMinimumSeats.setValue(minimumSeats);
  }
  @Input() petsAllowed: null | boolean;
  @Input() kidsAllowed: null | boolean;
  @Input() abroadAllowed: null | boolean;
  @Input() set equipmentIds(equipmentIds: string[]) {
    this.internalEquipmentIds = equipmentIds;
    this.formControlEquipmentIds.setValue(equipmentIds);
  }
  @Input() vehiclesFound: number;
  @Input() showVehiclesFound: number;

  @Output() intentIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output()
  vehicleNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onlyFavoritesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onlyInstantBookingPossibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  vehicleRangeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() priceRangeChange: EventEmitter<[number, number]> = new EventEmitter<
    [number, number]
  >();
  @Output() modelIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output()
  minimumSeatsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() petsAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() kidsAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() abroadAllowedChange: EventEmitter<
    null | boolean
  > = new EventEmitter<boolean>();
  @Output() equipmentIdsChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  seatChoices = [0, 2, 3, 4, 5, 6, 7, 8, 9];
  seatChoices$: Observable<TranslatedData[]>;

  formControlModelIds = this.fb.control(null);
  internalModelIds: string[];
  formControlMinimumSeats = this.fb.control(0);
  internalMinimumSeats = 0;
  formControlEquipmentIds = this.fb.control(null);
  internalEquipmentIds: string[];
  formControlVehicleRange = this.fb.control(null);
  internalVehicleRange: number;
  formControlPriceRange = this.fb.control(null);
  internalPriceRange: number[];

  vehicleRangeSub$: Subject<number> = new Subject<number>();
  priceRangeSub$: Subject<[number, number]> = new Subject<[number, number]>();

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private translateParser: TranslateParser
  ) {}

  ngOnInit(): void {
    this.priceRangeSub$
      .pipe(debounceTime(500), takeUntilDestroy(this))
      .subscribe(value => {
        this.priceRangeChange.emit(value);
      });

    this.vehicleRangeSub$
      .pipe(debounceTime(500), takeUntilDestroy(this))
      .subscribe(value => {
        this.vehicleRangeChange.emit(value);
      });

    this.seatChoices$ = this.translateService
      .get([
        this.tc + '.' + this.KEY_SEAT_CHOICE_NO_PREFERENCE,
        this.tc + '.' + this.KEY_SEAT_CHOICE_COUNT
      ])
      .pipe(
        take(1),
        map(translations => {
          return this.seatChoices.map(seatChoice => {
            if (seatChoice === 0) {
              return {
                value: seatChoice,
                translation:
                  translations[
                    this.tc + '.' + this.KEY_SEAT_CHOICE_NO_PREFERENCE
                  ]
              };
            }
            return {
              value: seatChoice,
              translation: this.translateParser.interpolate(
                translations[this.tc + '.' + this.KEY_SEAT_CHOICE_COUNT],
                { seatCount: seatChoice }
              )
            };
          });
        })
      );
  }

  formatPrice(value: number): string {
    return '€' + value;
  }

  formatVehicleRange(value: number): string {
    return value + 'km';
  }

  onVehicleNameChange(event): void {
    this.vehicleNameChange.emit(event.currentTarget.value);
  }

  keyLabelValueFn = k => k?.key;
  keyLabelLabelFn = k => k?.label;
  translatedDataValueFn = t => t?.value;
  translatedDataLabelFn = t => t?.translation;
}
