import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingRequest } from '../../../../types/booking-request.type';

@Component({
  selector: 'sof-booking-request-list-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` {{ numberOfBookingRequests }} Pending Requests `,
  styleUrls: ['./booking-request-list-header.component.scss']
})
export class BookingRequestListHeaderComponent {
  @Input() numberOfBookingRequests: number;
}
