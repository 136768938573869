import { ActionTypes } from '../action-types';
import { BookingUserDto } from '../../../../client';

export class SetCurrentUserAction {
  type = ActionTypes.SET_CURRENT_USER;
  payload: { currentUser: BookingUserDto };

  constructor(currentUser: BookingUserDto) {
    this.payload = { currentUser };
  }
}

export type CurrentUserActions = SetCurrentUserAction;
