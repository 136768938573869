import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../../helpers/date-util';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

@Pipe({ name: 'toFormattedDate' })
export class ToFormattedDatePipe implements PipeTransform {
  transform(date: string | Date, format: string): string {
    if (date instanceof Date) {
      return moment(date as Date).format(format);
    }
    return moment(DateUtil.convertToDate(date as string)).format(format);
  }
}
