import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserImage } from '../user-avatar/types/user-image.type';
import { ConversationMessage } from '../conversation-messages/types/conversation-message.type';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WindowRefService } from '@sofico-framework/utils';
import { AttachmentDownloadStatus } from '../conversation-messages/types/conversation-message-attachment.type';

@Component({
  selector: 'sof-conversation-attachment-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div *ngIf="showTimestamp" class="message-timestamp">
        {{ timeStamp | toFormattedConversationDate }}
      </div>
      <div class="message-info">
        <div class="sender-image-container">
          <sof-user-avatar
            *ngIf="showUsername && !isOwnMessage"
            [userImage]="userImage"
          ></sof-user-avatar>
        </div>
        <div
          *ngIf="
            message?.attachment?.downloadStatus !== DOWNLOAD_STATUS_SUCCESSFUL
          "
          class="message-text"
          [ngClass]="{
            'owner-message': isOwnMessage,
            'no-round-up': noRoundUp,
            'no-round-down': noRoundDown,
            'first-message': firstMessage,
            error:
              message?.attachment?.downloadStatus === DOWNLOAD_STATUS_FAILED
          }"
        >
          <div
            class="pending"
            *ngIf="
              message?.attachment?.downloadStatus === DOWNLOAD_STATUS_PENDING &&
              (message?.attachment?.isImage || message?.temporary)
            "
            class="message-button-container"
          >
            <div
              class="message"
              *ngIf="message?.attachment?.isImage && !message?.temporary"
            >
              Downloading...
            </div>
            <div class="message" *ngIf="message?.temporary">Uploading...</div>
            <sof-loading size="sm"></sof-loading>
          </div>
          <div
            *ngIf="
              message?.temporary &&
              message?.attachment?.downloadStatus === DOWNLOAD_STATUS_FAILED
            "
            class="message-button-container"
          >
            <div class="message">
              Failed to upload: {{ message?.attachment?.name }}
            </div>
            <a class="retry-button" (click)="retryAddAttachment.emit(message)"
              >Retry</a
            >
          </div>
          <div
            *ngIf="
              !message?.temporary &&
              message?.attachment?.downloadStatus === DOWNLOAD_STATUS_FAILED
            "
          >
            Failed to download: {{ message?.attachment?.name }}
          </div>
          <div
            *ngIf="!message?.attachment?.isImage && !message?.temporary"
            class="message-button-container"
          >
            <div class="message">
              {{
                tc + '.FILE-ATTACHED'
                  | translate: { fileName: message?.attachment?.name }
              }}
            </div>
            <button
              *ngIf="
                message?.attachment?.downloadStatus !== DOWNLOAD_STATUS_PENDING
              "
              sofButton
              icon="batt-icon-download"
              iconSize="24"
              class="round-icon-button"
              (click)="downloadAttachment.emit(message)"
            ></button>
            <sof-loading
              *ngIf="
                message?.attachment?.downloadStatus === DOWNLOAD_STATUS_PENDING
              "
              size="sm"
            ></sof-loading>
          </div>
        </div>
        <img
          *ngIf="
            message?.attachment?.isImage &&
            message?.attachment?.downloadStatus === DOWNLOAD_STATUS_SUCCESSFUL
          "
          [ngClass]="{
            'owner-message': isOwnMessage,
            'no-round-up': noRoundUp,
            'no-round-down': noRoundDown,
            'first-message': firstMessage
          }"
          class="successful"
          nz-tooltip
          nzTooltipPlacement="bottom"
          [nzTooltipTitle]="tc + '.CLICK-TO-OPEN-IMAGE-NEW-TAB' | translate"
          [src]="trustedUrl.safeUrl"
          (click)="download()"
        />
      </div>
    </ng-container>
  `,
  styleUrls: ['./conversation-attachment-message.component.scss']
})
export class ConversationAttachmentMessageComponent implements OnChanges {
  DOWNLOAD_STATUS_FAILED = AttachmentDownloadStatus.FAILED;
  DOWNLOAD_STATUS_PENDING = AttachmentDownloadStatus.PENDING;
  DOWNLOAD_STATUS_SUCCESSFUL = AttachmentDownloadStatus.SUCCESSFUL;

  @Input() tc: string;
  @Input() message: ConversationMessage;
  @Input() timeStamp: string;
  @Input() userImage: UserImage;
  @Input() noRoundUp: boolean;
  @Input() noRoundDown: boolean;
  @Input() showUsername: boolean;
  @Input() isOwnMessage: boolean;
  @Input() showTimestamp: boolean;
  @Input() firstMessage: boolean;

  @Output()
  retryAddAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output()
  downloadAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();

  trustedUrl: {
    url: string;
    safeUrl: SafeUrl;
  };

  constructor(
    private domSanitizer: DomSanitizer,
    private windowRef: WindowRefService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const data = changes?.message?.currentValue?.attachment?.data;
    if (data) {
      const url = this.windowRef.nativeWindow.URL.createObjectURL(data);
      this.trustedUrl = {
        url,
        safeUrl: this.domSanitizer.bypassSecurityTrustUrl(url)
      };
    }
  }

  download(): void {
    // this.windowRef.nativeWindow.location.href = this.trustedUrl.url;
    this.windowRef.nativeWindow.open(this.trustedUrl.url, '_blank');
  }
}
