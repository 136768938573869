import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UntilDestroy } from 'ngx-reactivetoolkit';
import {
  DriverGradeDto,
  OrganizationMemberDto
} from  '../../../../../client';

@UntilDestroy()
@Component({
  selector: 'sof-organization-member-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="member-info-container">
        <sof-user-avatar
          [userImage]="organizationMember?.user | toUserImage: '40'"
        ></sof-user-avatar>
        <div class="user-info">
          {{ organizationMember?.user | toUserDisplayName }}
        </div>
        <div class="action-button-container">
          <button
            sofButton
            icon="batt-icon-delete"
            class="main-action-button"
            (click)="removeOrganizationMember.emit(organizationMember)"
          >
            {{ tc + '.REMOVE-MEMBER' | translate }}
          </button>
        </div>
      </div>
      <div class="membership-container">
        <div class="membership-label">
          {{ tc + '.MEMBERSHIP' | translate }}
        </div>
        <div class="membership-driver-grades-container">
          <div class="membership-text">
            {{
              this.tc + '.MEMBERSHIP-TEXT'
                | translate
                  : { displayName: this.organizationMember?.user?.displayName }
            }}
          </div>
          <div class="driver-grades-list-container">
            <sof-driver-grade-list
              [driverGrades]="organizationMember?.driverGrades"
              [canRemove]="true"
              (removeDriverGrade)="removeDriverGrade.emit($event)"
            >
            </sof-driver-grade-list>
          </div>
          <div>
            <button
              sofButton
              icon="batt-icon-plus"
              class="main-action-button"
              (click)="addDriverGrade.emit()"
              [disabled]="disableAddDriverGrade"
            >
              {{ tc + '.ADD-DRIVER-GRADE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-member-detail.component.scss']
})
export class OrganizationMemberDetailComponent {
  @Input() tc: string;
  @Input() organizationMember: OrganizationMemberDto;
  @Input() disableAddDriverGrade: boolean;

  @Output()
  removeOrganizationMember: EventEmitter<OrganizationMemberDto> = new EventEmitter<OrganizationMemberDto>();
  @Output()
  removeDriverGrade: EventEmitter<DriverGradeDto> = new EventEmitter<DriverGradeDto>();
  @Output() addDriverGrade: EventEmitter<void> = new EventEmitter<void>();
}
