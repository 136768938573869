import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingRequest } from '../../../types/booking-request.type';

@Component({
  selector: 'sof-booking-request-highlighted-area',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="add-request">
      <i class="fa fa-plus" aria-hidden="true"></i>
      {{ bookingRequest.client.lastname }}
    </div>
  `,
  styleUrls: ['./booking-request-highlighted-area.component.scss']
})
export class BookingRequestHighlightedAreaComponent {
  @Input() bookingRequest: BookingRequest;
}
