import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AvailabilityControllerImplService } from './api/availabilityControllerImpl.service';
import { BookingControllerImplService } from './api/bookingControllerImpl.service';
import { ChatControllerImplService } from './api/chatControllerImpl.service';
import { GoogleControllerImplService } from './api/googleControllerImpl.service';
import { IncidentControllerImplService } from './api/incidentControllerImpl.service';
import { OrganizationControllerImplService } from './api/organizationControllerImpl.service';
import { SettingControllerImplService } from './api/settingControllerImpl.service';
import { TelematicsControllerImplService } from './api/telematicsControllerImpl.service';
import { UserControllerImplService } from './api/userControllerImpl.service';
import { VehicleGroupControllerImplService } from './api/vehicleGroupControllerImpl.service';
import { VehicleUsageControllerImplService } from './api/vehicleUsageControllerImpl.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
