import { Injectable } from '@angular/core';
import {
  VehicleUsageControllerImplService,
  VehicleUsageDto,
  VehicleUsageRequestDto
} from '../../../client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleUsageService {
  constructor(
    private vehicleUsageControllerImplService: VehicleUsageControllerImplService
  ) {}

  startVehicleUsage(
    bookingId: string,
    vehicleId: string
  ): Observable<VehicleUsageDto> {
    const vehicleUsageRequestDto: VehicleUsageRequestDto = {
      bookingId,
      vehicleId
    };
    return this.vehicleUsageControllerImplService.startVehicleUsage(
      vehicleUsageRequestDto
    );
  }

  endVehicleUsage(
    bookingId: string,
    vehicleId: string
  ): Observable<VehicleUsageDto> {
    const vehicleUsageRequestDto: VehicleUsageRequestDto = {
      bookingId,
      vehicleId
    };
    return this.vehicleUsageControllerImplService.endVehicleUsage(
      vehicleUsageRequestDto
    );
  }
}
