import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import {
  FilterCriteriaForBookingDto,
  GpsLocationDto,
  VehicleDto,
  VehicleSearchCriteriaDto
} from '../../../../../client';
import { ActiveFilter } from '../../../types/active-filter.type';
import { Intent } from '../../../types/intent.type';
import { KeyLabel } from '../../../types/key-label.type';
import { debounceTime, mergeMap, take } from 'rxjs/operators';
import { SharedUiSandbox } from '../../shared-ui.sandbox';
import { VehicleFilterType } from '../../../types/vehicle-filter.type';
import { Changes } from 'ngx-reactivetoolkit';

@Component({
  selector: 'sof-vehicle-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="search-container">
      <div class="left-side">
        <sof-vehicle-search-criteria
          [disabled]="disabled"
          [tc]="tc"
          [intents]="intents"
          [equipments]="equipments"
          [vehicleModels]="vehicleModels"
          [intentIds]="selectedIntents$ | async"
          (intentIdsChange)="selectedIntents$.next($event)"
          [vehicleName]="vehicleName$ | async"
          (vehicleNameChange)="vehicleName$.next($event)"
          [onlyFavorites]="onlyFavorites$ | async"
          (onlyFavoritesChange)="onlyFavorites$.next($event)"
          [onlyInstantBookingPossible]="instantBook$ | async"
          (onlyInstantBookingPossibleChange)="instantBook$.next($event)"
          [vehicleRange]="vehicleRange$ | async"
          (vehicleRangeChange)="vehicleRange$.next($event)"
          [priceRange]="priceRange$ | async"
          (priceRangeChange)="priceRange$.next($event)"
          [modelIds]="selectedVehicleModels$ | async"
          (modelIdsChange)="selectedVehicleModels$.next($event)"
          [minimumSeats]="selectedSeats$ | async"
          (minimumSeatsChange)="selectedSeats$.next($event)"
          [petsAllowed]="petsAllowed$ | async"
          (petsAllowedChange)="petsAllowed$.next($event)"
          [kidsAllowed]="kidsAllowed$ | async"
          (kidsAllowedChange)="kidsAllowed$.next($event)"
          [abroadAllowed]="abroadAllowed$ | async"
          (abroadAllowedChange)="abroadAllowed$.next($event)"
          [equipmentIds]="selectedEquipments$ | async"
          (equipmentIdsChange)="selectedEquipments$.next($event)"
        >
        </sof-vehicle-search-criteria>
      </div>
      <div class="content">
        <div class="text-search-container">
          <div class="d-flex flex-fill gg-05">
            <input
              [disabled]="disabled"
              [placeholder]="tc + '.SEARCH-ON-LICENSE-PLATE' | translate"
              autocomplete="off"
              class="search-input flex-fill"
              [value]="licensePlate$ | async"
              (input)="onLicensePlateChange($event)"
            />
            <input
              [disabled]="disabled"
              [placeholder]="tc + '.SEARCH-ON-VEHICLE-NAME' | translate"
              autocomplete="off"
              class="search-input flex-fill"
              [value]="vehicleName$ | async"
              (input)="onVehicleNameChange($event)"
            />
          </div>
          <button
            sofButton
            *ngIf="canCreateVehicle"
            class="main-action-button"
            (click)="createVehicle.emit(true)"
          >
            {{ tc + '.ADD-VEHICLE' | translate }}
          </button>
        </div>
        <div class="active-filters">
          <sof-active-filter
            *ngFor="let activeFilter of activeFilters$ | async"
            [tc]="tc"
            [value]="activeFilter.active"
            [label]="activeFilter.label"
            [translateKey]="activeFilter.translateKey"
            [interpolateParams]="activeFilter.interpolateParams"
            [activeFilter]="activeFilter"
            [canRemove]="true"
            (removeActiveFilter)="onRemoveActiveFilter($event)"
          ></sof-active-filter>
        </div>
        <div class="result-container">
          <sof-vehicles-found-label
            [tc]="tc"
            [vehiclesFound]="vehiclesFound$ | async"
          ></sof-vehicles-found-label>
          <div
            *ngFor="let vehicle of matchingVehicles$ | async"
            class="sof-card"
            [ngClass]="{ 'phase-out': vehicle.electricRange < 0 }"
            (click)="selectVehicle.emit(vehicle)"
          >
            <sof-license-plate
              class="scheduler-license-plate"
              [value]="vehicle.licensePlate"
              countryCode="be"
            ></sof-license-plate>
            <div class="vehicle-name">
              {{ vehicle.name }}
            </div>
            <div class="spacer-container"></div>
            <button
              *ngIf="canCopyVehicle"
              sofButton
              class="round-icon-button primary"
              icon="batt-icon-copy"
              iconSize="24"
              (click)="copyVehicle.emit(vehicle); $event.stopPropagation()"
            ></button>
            <button
              sofButton
              class="round-icon-button"
              aria-label="Select vehicle"
            >
              <sof-svg-icon
                *ngIf="!showSelectButtonAsPencil"
                icon="batt-icon-chevron-right"
                size="24"
              ></sof-svg-icon>
              <sof-svg-icon
                *ngIf="showSelectButtonAsPencil"
                icon="batt-icon-pencil"
                size="24"
              ></sof-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./vehicle-search.component.scss']
})
export class VehicleSearchComponent implements OnChanges {
  @Input() tc: string;
  @Input() disabled: boolean;
  @Input() intents: Array<Intent>;
  @Input() equipments: Array<KeyLabel>;
  @Input() vehicleModels: Array<KeyLabel>;
  @Input() canCreateVehicle: boolean;
  @Input() showOutOfFleetVehicles: boolean;
  @Input() canCopyVehicle: boolean;
  @Input() refreshList: Date;
  @Input() showSelectButtonAsPencil: boolean;

  @Output() createVehicle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  selectVehicle: EventEmitter<VehicleDto> = new EventEmitter<VehicleDto>();
  @Output()
  copyVehicle: EventEmitter<VehicleDto> = new EventEmitter<VehicleDto>();

  @Changes('refreshList') refreshList$: Observable<Date>;

  priceRange$: BehaviorSubject<[number, number]> = new BehaviorSubject<
    [number, number]
  >([
    ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
    ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
  ]);
  vehicleRange$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE
  );
  selectedIntents$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  onlyFavorites$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  instantBook$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedSeats$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedEquipments$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  abroadAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  kidsAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  petsAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  vehicleName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  licensePlate$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  selectedVehicleModels$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >(new Array<string>());
  selectedLocation$: BehaviorSubject<GpsLocationDto> = new BehaviorSubject<GpsLocationDto>(
    { ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE }
  );
  selectedMaxDistance$: BehaviorSubject<number> = new BehaviorSubject<number>(
    ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
  );

  vehiclesFound$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  matchingVehicles$: BehaviorSubject<Array<VehicleDto>> = new BehaviorSubject<
    Array<VehicleDto>
  >(null);

  activeFilters$: BehaviorSubject<Array<ActiveFilter>> = new BehaviorSubject<
    Array<ActiveFilter>
  >(new Array<ActiveFilter>());

  vehicleCriteria$ = combineLatest([
    this.selectedIntents$,
    this.vehicleName$,
    this.onlyFavorites$,
    this.instantBook$,
    this.vehicleRange$,
    this.priceRange$,
    this.selectedSeats$,
    this.abroadAllowed$,
    this.kidsAllowed$,
    this.petsAllowed$,
    this.selectedEquipments$,
    this.selectedVehicleModels$,
    this.selectedLocation$,
    this.selectedMaxDistance$,
    this.licensePlate$
  ]).pipe(debounceTime(250));

  constructor(private sharedUiSandbox: SharedUiSandbox) {
    combineLatest([this.vehicleCriteria$, this.refreshList$])
      .pipe(
        // filter(([vehicleCriteria, refreshList]) => !!refreshList),
        mergeMap(
          ([
            [
              selectedIntents,
              vehicleName,
              onlyFavorites,
              instantBook,
              vehicleRange,
              priceRange,
              selectedSeats,
              abroadAllowed,
              kidsAllowed,
              petsAllowed,
              selectedEquipments,
              selectedVehicleModels,
              selectedLocation,
              selectedMaxDistance,
              licensePlate
            ],
            refreshList
          ]: [
            [
              Array<string>,
              string,
              boolean,
              boolean,
              number,
              [number, number],
              number,
              boolean,
              boolean,
              boolean,
              Array<string>,
              Array<string>,
              GpsLocationDto,
              number,
              string
            ],
            Date
          ]) => {
            // TODO - Temporary fix. To be replaced when phase in / phase out of a vehicle will be implemented
            if (
              this.showOutOfFleetVehicles &&
              vehicleRange <= ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE
            ) {
              vehicleRange = -10000000;
            }
            const vehicleSearchCriteriaDto: VehicleSearchCriteriaDto = this.getVehicleSearchCriteriaDto(
              selectedIntents,
              vehicleName,
              onlyFavorites,
              instantBook,
              vehicleRange,
              priceRange,
              selectedSeats,
              abroadAllowed,
              kidsAllowed,
              petsAllowed,
              selectedEquipments,
              selectedVehicleModels,
              selectedLocation,
              selectedMaxDistance,
              licensePlate,
              true
            );
            return sharedUiSandbox.searchVehicles(vehicleSearchCriteriaDto);
          }
        )
      )
      .subscribe(vehiclePageDto => {
        this.matchingVehicles$.next(vehiclePageDto?.vehicles);
        this.vehiclesFound$.next(vehiclePageDto?.vehicles?.length);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private getVehicleSearchCriteriaDto(
    selectedIntents: Array<string>,
    vehicleName: string,
    onlyFavorites: boolean,
    instantBook: boolean,
    vehicleRange: number,
    priceRange: [number, number],
    selectedSeats: number,
    abroadAllowed: boolean,
    kidsAllowed: boolean,
    petsAllowed: boolean,
    selectedEquipments: Array<string>,
    selectedVehicleModels: Array<string>,
    selectedLocation: GpsLocationDto,
    selectedMaxDistance: number,
    licensePlate: string,
    updateActiveFilters: boolean
  ): VehicleSearchCriteriaDto {
    const activeFilters: Array<ActiveFilter> = new Array<ActiveFilter>();
    const filterCriteria: FilterCriteriaForBookingDto = ActiveFiltersUtils.getFilterCriteriaForBookingDto(
      selectedIntents,
      vehicleName,
      onlyFavorites,
      instantBook,
      vehicleRange,
      priceRange,
      selectedSeats,
      abroadAllowed,
      kidsAllowed,
      petsAllowed,
      selectedEquipments,
      selectedVehicleModels,
      selectedLocation,
      selectedMaxDistance,
      licensePlate,
      null,
      null
    );
    const vehicleSearchCriteriaDto: VehicleSearchCriteriaDto = ActiveFiltersUtils.getVehicleSearchCriteriaDtoForFilterCriteriaForBookingDto(
      filterCriteria
    );
    ActiveFiltersUtils.fillOutActiveFilters(
      filterCriteria,
      this.intents,
      this.vehicleModels,
      this.equipments,
      activeFilters,
      activeFilters,
      activeFilters
    );
    if (updateActiveFilters) {
      this.activeFilters$.next(activeFilters);
    }
    return vehicleSearchCriteriaDto;
  }

  onVehicleNameChange(event): void {
    this.vehicleName$.next(event.currentTarget.value);
  }

  onLicensePlateChange(event): void {
    this.licensePlate$.next(event.currentTarget.value);
  }

  onRemoveActiveFilter(activeFilter: ActiveFilter): void {
    switch (activeFilter.vehicleFilterType) {
      case VehicleFilterType.INTENT:
        this.selectedIntents$.pipe(take(1)).subscribe(intentIds => {
          this.selectedIntents$.next(
            intentIds.filter(intentId => intentId !== activeFilter.value)
          );
        });
        break;
      case VehicleFilterType.VEHICLE_NAME:
        this.vehicleName$.next(null);
        break;
      case VehicleFilterType.LICENSE_PLATE:
        this.licensePlate$.next(null);
        break;
      case VehicleFilterType.ONLY_FAVORITES:
        this.onlyFavorites$.next(false);
        break;
      case VehicleFilterType.ONLY_INSTANT_BOOKING_POSSIBLE:
        this.instantBook$.next(false);
        break;
      case VehicleFilterType.MINIMUM_RANGE:
        this.vehicleRange$.next(ActiveFiltersUtils.VEHICLE_RANGE_MIN_VALUE);
        break;
      case VehicleFilterType.DAY_PRICE:
        this.priceRange$.next([
          ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
          ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
        ]);
        break;
      case VehicleFilterType.VEHICLE_MODEL:
        this.selectedVehicleModels$.pipe(take(1)).subscribe(modelIds => {
          this.selectedVehicleModels$.next(
            modelIds.filter(modelId => modelId !== activeFilter.value)
          );
        });
        break;
      case VehicleFilterType.MINIMUM_SEATS:
        this.selectedSeats$.next(0);
        break;
      case VehicleFilterType.ABROAD_ALLOWED:
        this.abroadAllowed$.next(null);
        break;
      case VehicleFilterType.KIDS_ALLOWED:
        this.kidsAllowed$.next(null);
        break;
      case VehicleFilterType.PETS_ALLOWED:
        this.petsAllowed$.next(null);
        break;
      case VehicleFilterType.EQUIPMENT:
        this.selectedEquipments$.pipe(take(1)).subscribe(equipmentIds => {
          this.selectedEquipments$.next(
            equipmentIds.filter(
              equipmentId => equipmentId !== activeFilter.value
            )
          );
        });
        break;
      case VehicleFilterType.MAXIMUM_DISTANCE:
        this.selectedMaxDistance$.next(
          ActiveFiltersUtils.MAX_DISTANCE_DEFAULT_VALUE
        );
        break;
      case VehicleFilterType.LOCATION:
        this.selectedLocation$.next({
          ...ActiveFiltersUtils.GPS_LOCATION_DEFAULT_VALUE
        });
        break;
      case VehicleFilterType.FAVORITES_USER_ID:
        // Not managed yet
        break;
    }
  }
}
