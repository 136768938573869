import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Intent } from '../../../types/intent.type';

@Component({
  selector: 'sof-intent-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-input-checkbox
      *ngFor="let intent of intents; trackBy: trackByFn"
      [label]="intent.label"
      [selected]="values?.indexOf(intent.enumId) > -1"
      [isDisabled]="disabled"
      (changeValue)="onIntentSelect(intent, $event)"
    ></sof-input-checkbox>
  `
})
export class IntentListComponent {
  @Input() disabled: boolean;
  @Input() intents: Array<Intent>;
  @Input() values: Array<string>;
  @Output() valuesChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  onIntentSelect(intent: Intent, selected: boolean): void {
    let keys = [...this.values];
    if (selected) {
      keys.push(intent.enumId);
    } else {
      keys = keys.filter(id => id !== intent.enumId);
    }
    this.valuesChange.emit(keys);
  }

  trackByFn = i => i;
}
