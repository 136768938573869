import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { shareReplay, take, withLatestFrom } from 'rxjs/operators';
import {
  BookingUserDto,
  VehicleDto
} from '../../../../../client';
import { AppSandbox } from '../../../app.sandbox';
import { VehicleDialogData } from '../../../types/vehicle-dialog-data.type';
import { VehicleManagementSandbox } from '../../vehicle-management.sandbox';
import { VehiclesViewHelper } from '../../view-helpers/vehicles-view.helper';

@Component({
  selector: 'sof-vehicle-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-vehicle-search
        [tc]="tc"
        [intents]="intents$ | async"
        [equipments]="vehicleEquipments$ | async"
        [vehicleModels]="vehicleModels$ | async"
        [canCreateVehicle]="true"
        [showOutOfFleetVehicles]="true"
        [refreshList]="vehiclesViewHelper.refreshList$ | async"
        [canCopyVehicle]="true"
        [showSelectButtonAsPencil]="true"
        (selectVehicle)="onSelectVehicle($event)"
        (createVehicle)="onCreateVehicle()"
        (copyVehicle)="onCopyVehicle($event)"
      >
      </sof-vehicle-search>
      <router-outlet></router-outlet>
    </ng-container>
  `,
  providers: [VehiclesViewHelper],
  styleUrls: ['./vehicles-view.component.scss']
})
export class VehiclesViewComponent {
  tc = 'VEHICLE-MANAGEMENT_VEHICLES';

  currentUser$ = this.appSandbox.currentUser$.pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  intents$ = this.appSandbox.intents$;
  vehicleEquipments$ = this.appSandbox.vehicleEquipments$;
  vehicleModels$ = this.appSandbox.vehicleModels$;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vsb: VehicleManagementSandbox,
    private appSandbox: AppSandbox,
    public vehiclesViewHelper: VehiclesViewHelper
  ) {}

  onSelectVehicle(vehicle: VehicleDto): void {
    this.vsb
      .getVehicle(vehicle.id)
      .pipe(withLatestFrom(this.currentUser$))
      .subscribe(([vehicleDto, currentUser]) => {
        this.openVehicleDialog(
          vehicleDto.id,
          vehicleDto,
          currentUser,
          'edit-vehicle'
        );
      });
  }

  onCreateVehicle(): void {
    this.currentUser$.pipe(take(1)).subscribe(currentUser => {
      this.openVehicleDialog(null, null, currentUser, 'create-vehicle');
    });
  }

  onCopyVehicle(vehicle: VehicleDto): void {
    this.vsb
      .getVehicle(vehicle.id)
      .pipe(withLatestFrom(this.currentUser$))
      .subscribe(([vehicleDto, currentUser]) => {
        this.openVehicleDialog(null, vehicleDto, currentUser, 'copy-vehicle');
      });
  }

  openVehicleDialog(
    vehicleId: string,
    vehicleDto: VehicleDto,
    currentUser: BookingUserDto,
    path: string
  ): void {
    // TODO : remove appSandbox.users$ when DamageDto will be updated with UserReferenceDto
    this.appSandbox.allUsers$.subscribe(userPage => {
      const data: VehicleDialogData = {
        vehicleId,
        vehicleDto,
        currentUser,
        userPage
      };
      this.vehiclesViewHelper.vehicleDialogData = data;
      this.router.navigate([path], { relativeTo: this.activatedRoute });
    });
  }
}
