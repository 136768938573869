import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  BookingDto,
  BookingsPageDto,
  BookingUserDto,
  UserRoleDto
} from '../../../../../client';

@Component({
  selector: 'sof-booking-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-result-found-label
      [tc]="tc"
      [resultCount]="bookingPageDto?.bookings?.length"
      noResultLabel="NO-BOOKING-FOUND"
      oneResultLabel="ONE-BOOKING-FOUND"
      multipleResultsLabel="MULTIPLE-BOOKINGS-FOUND"
    >
    </sof-result-found-label>
    <div class="booking-list-container">
      <div
        class="sof-card"
        *ngFor="let booking of bookingPageDto?.bookings; trackBy: trackByFn"
      >
        <div class="card-content-container">
          <div class="row1">
            <div class="vehicle-container">
              <sof-license-plate
                class="scheduler-license-plate"
                [value]="booking.vehicle?.licensePlate"
                countryCode="be"
              >
              </sof-license-plate>
              <div>
                {{ booking.vehicle?.name }}
              </div>
            </div>
            <div class="user-container">
              <sof-user-avatar [userImage]="booking.user | toUserImage">
              </sof-user-avatar>
              <div>
                {{ booking.user.displayName }}
              </div>
            </div>
            <div class="period-container">
              <div class="row">
                <sof-data-item-date-hour
                  class="col-sm"
                  [label]="tc + '.PLANNED-PICK-UP' | translate"
                  [date]="booking.plannedPeriod?.start"
                ></sof-data-item-date-hour>
                <sof-data-item-date-hour
                  class="col-sm"
                  [label]="tc + '.PLANNED-DROP-OFF' | translate"
                  [date]="booking.plannedPeriod?.end"
                ></sof-data-item-date-hour>
              </div>
              <div class="row">
                <sof-data-item-date-hour
                  class="col-sm"
                  [label]="tc + '.ACTUAL-PICK-UP' | translate"
                  [date]="booking.usagePeriod?.start"
                ></sof-data-item-date-hour>
                <sof-data-item-date-hour
                  class="col-sm"
                  [label]="tc + '.ACTUAL-DROP-OFF' | translate"
                  [date]="booking.usagePeriod?.end"
                ></sof-data-item-date-hour>
              </div>
            </div>
            <div class="booking-status-container">
              <sof-booking-status [booking]="booking"> </sof-booking-status>
            </div>
            <div class="trip-type-container">
              {{ tc + '.TRIP_TYPE_' + booking.tripType | translate }}
            </div>
          </div>
          <div
            class="row2"
            *ngIf="
              !!booking.comments ||
              (!!booking.adminComments && currentUser.role === USER_ROLE_ADMIN)
            "
          >
            <div class="comments-container" *ngIf="!!booking.comments">
              <span class="label">{{ tc + '.CARD-COMMENTS' | translate }}</span>
              {{ booking.comments }}
            </div>
            <div
              class="comments-container"
              *ngIf="
                !!booking.adminComments && currentUser.role === USER_ROLE_ADMIN
              "
            >
              <span class="label">{{
                tc + '.CARD-ADMIN-COMMENTS' | translate
              }}</span>
              {{ booking.adminComments }}
            </div>
          </div>
        </div>
        <button
          sofButton
          class="round-icon-button"
          *ngIf="showActions"
          [attr.aria-label]="tc + '.EDIT-BOOKING' | translate"
          (click)="editBooking.emit(booking)"
        >
          <sof-svg-icon icon="batt-icon-pencil" size="24"></sof-svg-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent {
  USER_ROLE_ADMIN = UserRoleDto.BATTADMIN;

  @Input() tc: string;
  @Input() bookingPageDto: BookingsPageDto;
  @Input() showActions = false;
  @Input() currentUser: BookingUserDto;

  @Output()
  editBooking: EventEmitter<BookingDto> = new EventEmitter<BookingDto>();

  trackByFn = i => i;
}
