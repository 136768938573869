import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output
} from '@angular/core';
import {
  Configuration, DamageDto, DamagePageDto
} from '../../../../../client';

@Component({
  selector: 'sof-damage-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-result-found-label
      *ngIf="showResultFoundLabel"
      [tc]="tc"
      [resultCount]="damagePageDto?.damages?.length"
      noResultLabel="NO-DAMAGE-FOUND"
      oneResultLabel="ONE-DAMAGE-FOUND"
      multipleResultsLabel="MULTIPLE-DAMAGES-FOUND"
    >
    </sof-result-found-label>
    <div class="damage-list-container">
      <div
        class="sof-card"
        *ngFor="let damage of damagePageDto?.damages; trackBy: trackByFn"
        [class.cursor-pointer]="canEdit"
        (click)="onDamageClick(damage)"
      >
        <div class="start-date-container">
          <sof-date-hour [date]="damage.creationDate"></sof-date-hour>
        </div>
        <div class="vehicle-container">
          <sof-license-plate
            class="scheduler-license-plate"
            [value]="damage.vehicle.licensePlate"
            countryCode="be"
          >
          </sof-license-plate>
          <div>
            {{ damage.vehicle.name }}
          </div>
        </div>
        <div class="user-container">
          <sof-user-avatar [userImage]="damage.user | toUserImage">
          </sof-user-avatar>
          <div>
            {{ damage.user.displayName }}
          </div>
        </div>
        <div class="description-container">
          {{ damage.description }}
        </div>
        <div class="status-container">
          {{ tc + '.DAMAGE_STATUS_' + damage.status | translate }}
        </div>
        <div class="image-container">
          <img
            *ngIf="!!damage.imageUrl"
            src="{{ basePath }}{{ damage.imageUrl }}"
            alt="{{ damage.vehicle.id }}"
          />
        </div>
        <div *ngIf="canEdit">
          <button
            sofButton
            class="round-icon-button"
            [attr.aria-label]="tc + '.EDIT-DAMAGE' | translate"
          >
            <sof-svg-icon
              icon="batt-icon-chevron-right"
              size="24"
            ></sof-svg-icon>
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./damage-list.component.scss']
})
export class DamageListComponent {
  @Input() tc: string;
  @Input() damagePageDto: DamagePageDto;
  @Input() canEdit = false;
  @Input() showResultFoundLabel = true;
  @Output() editDamage: EventEmitter<DamageDto> = new EventEmitter<DamageDto>();

  public basePath: string;

  constructor(@Optional() configuration: Configuration) {
    if (configuration) {
      this.basePath = configuration.basePath;
    }
  }

  onDamageClick(damage: DamageDto): void {
    if (this.canEdit) {
      this.editDamage.emit(damage);
    }
  }

  trackByFn = i => i;
}
