import { Booking } from '../../types/booking.type';
import { BookingWithStyle } from '../../types/booking-with-style.type';
import { DateUtil } from '../../helpers/date-util';
import {
  BookingStatusDto,
  BookingUserDto,
  PeriodDto
} from  '../../../../client';
import ConvertersUtils from '../../shared-ui/utils/shared-ui.utils';
import { EventUtils } from './event.utils';
import { SchedulerDateUtils } from './scheduler-date.utils';
import { BookingCalculatedDates } from '../../types/booking-calculated-dates.type';

export class BookingUtils {
  static calculateDates(
    booking: Booking,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): BookingCalculatedDates {
    const visibleCurrentDate = EventUtils.calculateVisibleEndDate(
      new Date(),
      schedulerDateFrom,
      schedulerDateTo
    );
    const visibleFromDate = EventUtils.calculateVisibleStartDate(
      booking.fromDate,
      schedulerDateFrom
    );
    const visibleToDate = EventUtils.calculateVisibleEndDate(
      booking.toDate,
      schedulerDateFrom,
      schedulerDateTo
    );
    let visibleVehicleUsageFromDate = visibleFromDate;
    let visibleVehicleUsageToDate = visibleToDate;
    let minFromDate = visibleFromDate;
    let maxToDate = visibleToDate;
    if (!!booking.data?.usagePeriod) {
      const usagePeriodDto = booking.data.usagePeriod as PeriodDto;
      visibleVehicleUsageFromDate = EventUtils.calculateVisibleStartDate(
        DateUtil.convertToDate(usagePeriodDto.start),
        schedulerDateFrom
      );
      // Active booking has no endDate
      if (usagePeriodDto.end) {
        visibleVehicleUsageToDate = EventUtils.calculateVisibleEndDate(
          DateUtil.convertToDate(usagePeriodDto.end),
          schedulerDateFrom,
          schedulerDateTo
        );
      }
    }
    if (visibleVehicleUsageFromDate < minFromDate) {
      minFromDate = visibleVehicleUsageFromDate;
    }
    if (visibleVehicleUsageToDate > maxToDate) {
      maxToDate = visibleVehicleUsageToDate;
    }
    if (
      booking.data?.status === BookingStatusDto.ACTIVE &&
      visibleVehicleUsageToDate < visibleCurrentDate
    ) {
      maxToDate = visibleCurrentDate;
    }
    return {
      visibleCurrentDate,
      visibleFromDate,
      visibleToDate,
      visibleVehicleUsageFromDate,
      visibleVehicleUsageToDate,
      minFromDate,
      maxToDate
    };
  }

  // this functions take a booking and few information about the time displayed,
  // to create a booking with a size and a position in px, ready to display
  static createBookingWithStyle(
    booking: Booking,
    msPerPixel: number,
    schedulerDateFrom: Date,
    schedulerDateTo: Date,
    currentUser: BookingUserDto
  ): BookingWithStyle {
    let bookingPosAndSize = {
      'width.%': 100,
      'left.%': 0
    };
    let vehicleUsagePosAndSize = null;
    let activeBookingExtendedPeriodPosAndSize = null;
    const calculatedDates: BookingCalculatedDates = BookingUtils.calculateDates(
      booking,
      schedulerDateFrom,
      schedulerDateTo
    );
    const visibleCurrentDate = calculatedDates.visibleCurrentDate;
    const visibleFromDate = calculatedDates.visibleFromDate;
    const visibleToDate = calculatedDates.visibleToDate;
    const visibleVehicleUsageFromDate =
      calculatedDates.visibleVehicleUsageFromDate;
    const visibleVehicleUsageToDate = calculatedDates.visibleVehicleUsageToDate;
    const minFromDate = calculatedDates.minFromDate;
    const maxToDate = calculatedDates.maxToDate;

    if (
      booking.data?.status === BookingStatusDto.ACTIVE &&
      visibleVehicleUsageToDate < visibleCurrentDate
    ) {
      activeBookingExtendedPeriodPosAndSize = {
        'width.%':
          ((SchedulerDateUtils.getTimeInMillisDSTClean(
            maxToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              visibleVehicleUsageToDate,
              schedulerDateFrom
            )) /
            (SchedulerDateUtils.getTimeInMillisDSTClean(
              maxToDate,
              schedulerDateFrom
            ) -
              SchedulerDateUtils.getTimeInMillisDSTClean(
                minFromDate,
                schedulerDateFrom
              ))) *
          100,
        'left.%':
          ((SchedulerDateUtils.getTimeInMillisDSTClean(
            visibleVehicleUsageToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              minFromDate,
              schedulerDateFrom
            )) /
            (SchedulerDateUtils.getTimeInMillisDSTClean(
              maxToDate,
              schedulerDateFrom
            ) -
              SchedulerDateUtils.getTimeInMillisDSTClean(
                minFromDate,
                schedulerDateFrom
              ))) *
          100
      };
    }
    bookingPosAndSize = {
      'width.%':
        ((SchedulerDateUtils.getTimeInMillisDSTClean(
          visibleToDate,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            visibleFromDate,
            schedulerDateFrom
          )) /
          (SchedulerDateUtils.getTimeInMillisDSTClean(
            maxToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              minFromDate,
              schedulerDateFrom
            ))) *
        100,
      'left.%':
        ((SchedulerDateUtils.getTimeInMillisDSTClean(
          visibleFromDate,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            minFromDate,
            schedulerDateFrom
          )) /
          (SchedulerDateUtils.getTimeInMillisDSTClean(
            maxToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              minFromDate,
              schedulerDateFrom
            ))) *
        100
    };
    vehicleUsagePosAndSize = {
      'width.%':
        ((SchedulerDateUtils.getTimeInMillisDSTClean(
          visibleVehicleUsageToDate,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            visibleVehicleUsageFromDate,
            schedulerDateFrom
          )) /
          (SchedulerDateUtils.getTimeInMillisDSTClean(
            maxToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              minFromDate,
              schedulerDateFrom
            ))) *
        100,
      'left.%':
        ((SchedulerDateUtils.getTimeInMillisDSTClean(
          visibleVehicleUsageFromDate,
          schedulerDateFrom
        ) -
          SchedulerDateUtils.getTimeInMillisDSTClean(
            minFromDate,
            schedulerDateFrom
          )) /
          (SchedulerDateUtils.getTimeInMillisDSTClean(
            maxToDate,
            schedulerDateFrom
          ) -
            SchedulerDateUtils.getTimeInMillisDSTClean(
              minFromDate,
              schedulerDateFrom
            ))) *
        100
    };

    const bookingWithStyle: BookingWithStyle = {
      ...booking,
      posAndSize: EventUtils.getLeftAndWidthForPeriod(
        minFromDate,
        maxToDate,
        msPerPixel,
        schedulerDateFrom,
        schedulerDateTo
      ),
      bookingBeginBefore:
        booking.fromDate < schedulerDateFrom &&
        visibleFromDate <= visibleVehicleUsageFromDate,
      bookingEndAfter:
        booking.toDate > schedulerDateTo &&
        visibleToDate >= visibleVehicleUsageToDate,
      vehicleUsageBeginBefore: false,
      vehicleUsageEndAfter: false,
      bookingPosAndSize,
      vehicleUsagePosAndSize,
      activeBookingExtendedPeriodPosAndSize,
      bookingColors: ConvertersUtils.getBookingColors(
        booking.data?.status,
        booking.fromDate,
        booking.toDate
      )
    };

    if (!!booking.data?.usagePeriod) {
      const usagePeriodDto = booking.data.usagePeriod as PeriodDto;
      bookingWithStyle.vehicleUsageBeginBefore =
        visibleVehicleUsageFromDate < visibleFromDate &&
        DateUtil.convertToDate(usagePeriodDto.start) < schedulerDateFrom;
      // Active booking has no endDate
      if (usagePeriodDto.end) {
        bookingWithStyle.vehicleUsageEndAfter =
          visibleVehicleUsageToDate > visibleToDate &&
          DateUtil.convertToDate(usagePeriodDto.end) > schedulerDateTo;
      }
    }

    if (
      !!currentUser &&
      booking.data?.user?.remoteId === currentUser.remoteId
    ) {
      bookingWithStyle.bookingHighlightStyle = {
        borderBottom: '2px solid ' + bookingWithStyle.bookingColors.border
      };
    }
    return bookingWithStyle;
  }

  static isOnThisPeriodBooking(
    booking: Booking,
    schedulerDateFrom: Date,
    schedulerDateTo: Date
  ): boolean {
    const calculatedDates: BookingCalculatedDates = BookingUtils.calculateDates(
      booking,
      schedulerDateFrom,
      schedulerDateTo
    );
    return EventUtils.isOnThisPeriod(
      calculatedDates.minFromDate,
      calculatedDates.maxToDate,
      schedulerDateFrom,
      schedulerDateTo
    );
  }

  // this function takes as parameter an array of booking,
  // and dispatch the bookings with a collapsing period on different sub-arrays
  // the marginMs parameter is used to define the minimal period (in milliseconds) between
  // two consecutive booking on a same sub-array
  static dispatchOnSubRowsBooking(
    bookingsFlat: Booking[],
    schedulerDateFrom: Date,
    schedulerDateTo: Date,
    marginMs: number = 1000
  ): Booking[][] {
    const subRows = BookingUtils.dispatchOnSubRows(
      bookingsFlat.map(booking => booking),
      schedulerDateFrom,
      schedulerDateTo,
      marginMs
    );
    return subRows.map(subRow => subRow.map(event => event as Booking));
  }

  // this function takes as parameter an array of bookings,
  // and dispatch the bookings with a collapsing period on different sub-arrays
  // the marginMs parameter is used to define the minimal period (in milliseconds) between
  // two consecutive booking on a same sub-array
  static dispatchOnSubRows(
    bookingsFlat: Booking[],
    schedulerDateFrom: Date,
    schedulerDateTo: Date,
    marginMs: number = 1000
  ): Booking[][] {
    // there is one available row at first
    let subRows: Booking[][] = [[]];
    let rowAvailability: boolean[] = [true];

    // we create a list of 'instant' from the bookings
    // an 'instant' is an booking start or an booking end at a date
    let instants: {
      date: Date;
      booking: Booking;
      type: 'start' | 'end';
    }[] = [];
    bookingsFlat.forEach(booking => {
      const calculatedDates: BookingCalculatedDates = BookingUtils.calculateDates(
        booking,
        schedulerDateFrom,
        schedulerDateTo
      );
      return (instants = [
        ...instants,
        { date: calculatedDates.minFromDate, booking, type: 'start' },
        { date: calculatedDates.maxToDate, booking, type: 'end' }
      ]);
    });
    // we sort the instants by date
    instants.sort((instantA, instantB) => {
      // we calculate the instant timestamps with the margin
      const instantAMs =
        instantA.type === 'start'
          ? instantA.date.valueOf() - marginMs / 2
          : instantA.date.valueOf() + marginMs / 2;

      const instantBMs =
        instantB.type === 'start'
          ? instantB.date.valueOf() - marginMs / 2
          : instantB.date.valueOf() + marginMs / 2;

      // we compare them
      return instantAMs - instantBMs;
    });

    // we fill the subRows, according to the instants type
    instants.forEach(instant => {
      if (instant.type === 'start') {
        // if the instant is a start one, we add the related booking to the first available one
        const i = rowAvailability.findIndex(available => available === true);
        // if there is no, we a create a new empty row and add the booking to this row
        if (i === -1) {
          subRows = [...subRows, [{ ...instant.booking }]];
          rowAvailability = [...rowAvailability, false];
        } else {
          // if the row exists, we add the booking to it
          subRows[i] = [...subRows[i], { ...instant.booking }];
          rowAvailability[i] = false;
        }
      } else {
        // if it's an end one, we make the corresponding row available
        const rowIndex = subRows.findIndex(
          subRow =>
            subRow.findIndex(
              booking => booking.internalId === instant.booking.internalId
            ) !== -1
        );
        rowAvailability[rowIndex] = true;
      }
    });

    return subRows;
  }
}
