import { PlanningState } from '../app.state';
import { PlanningActions } from '../actions/planning.actions';
import { ActionTypes } from '../action-types';
import { getStoredViewMode, storeViewMode } from '../../utils/scheduler.utils';

const initialPlanningState = {
  viewMode: getStoredViewMode(),
  dateFrom: new Date(),
  showMap: false,
  showMyBookings: false,
  currentOrganizationId: null
};

export function planningStateReducer(
  state: PlanningState = initialPlanningState,
  action: PlanningActions
): PlanningState {
  switch (action.type) {
    case ActionTypes.SET_VIEWMODE:
      storeViewMode(action.payload.viewMode);
      return { ...state, viewMode: action.payload.viewMode };
    case ActionTypes.SET_DATE_FROM:
      return { ...state, dateFrom: action.payload.dateFrom };
    case ActionTypes.SET_SHOW_MAP:
      return { ...state, showMap: action.payload.showMap };
    case ActionTypes.SET_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        currentOrganizationId: action.payload.currentOrganizationId
      };
    case ActionTypes.SET_SHOW_MY_BOOKINGS:
      return { ...state, showMyBookings: action.payload.showMyBookings };
  }
  return state;
}
