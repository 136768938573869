/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RecurringTypeDto = 'DAILY' | 'MONTHLY' | 'MONTHLY_SAME_WEEKDAY' | 'WEEKDAYS' | 'WEEKLY';

export const RecurringTypeDto = {
    DAILY: 'DAILY' as RecurringTypeDto,
    MONTHLY: 'MONTHLY' as RecurringTypeDto,
    MONTHLYSAMEWEEKDAY: 'MONTHLY_SAME_WEEKDAY' as RecurringTypeDto,
    WEEKDAYS: 'WEEKDAYS' as RecurringTypeDto,
    WEEKLY: 'WEEKLY' as RecurringTypeDto
};

