import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { BookingRequest } from '../../../../types/booking-request.type';

@Component({
  selector: 'sof-booking-request-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="client-description">
      <div class="client-name">
        {{ bookingRequest.client.lastname }}
      </div>
      <div class="needs">
        <!-- category -->
        <div class="cell" *ngIf="bookingRequest.needs.category">
          <span class="label"> cat. </span>
          <span class="value">
            {{ bookingRequest.needs.category }}
          </span>
        </div>
        <!-- color -->
        <div class="cell" *ngIf="bookingRequest.needs.color">
          <span class="value">
            {{ bookingRequest.needs.color }}
          </span>
        </div>
        <!-- gear -->
        <div class="cell" *ngIf="bookingRequest.needs.gear">
          <span class="value">
            {{ bookingRequest.needs.gear }}
          </span>
        </div>
        <!-- GPS -->
        <div class="cell" *ngIf="bookingRequest.needs.GPS">
          <span class="value"> GPS </span>
        </div>
      </div>
    </div>

    <div class="dates">
      <!-- date from -->
      <span class="date date-from">
        <span class="day">
          {{ bookingRequest.fromDate | date: 'dd/MM/yy' }}
        </span>
        <span class="hour">
          {{ bookingRequest.fromDate | date: 'HH:mm' }}
        </span>
      </span>
      -
      <!-- date-to -->
      <span class="date date-to">
        <span class="day">
          {{ bookingRequest.toDate | date: 'dd/MM/yy' }}
        </span>
        <span class="hour">
          {{ bookingRequest.toDate | date: 'HH:mm' }}
        </span>
      </span>
    </div>
  `,
  styleUrls: ['./booking-request-item.component.scss']
})
export class BookingRequestItemComponent {
  @Input() bookingRequest: BookingRequest;
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
