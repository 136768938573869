import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SwitchModeButton } from '../../../types/switch-mode-button.type';
import { Router } from '@angular/router';

@Component({
  selector: 'sof-switch-mode-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="switch-mode-container">
        <button
          *ngFor="let switchModeButton of switchModeButtons"
          class="switch-mode-button"
          [ngClass]="{ active: switchModeButton.active }"
          (click)="onClick(switchModeButton)"
        >
          <sof-svg-icon [icon]="switchModeButton.icon" size="48"></sof-svg-icon>
          {{ tc + '.' + switchModeButton.label | translate }}
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./switch-mode-bar.component.scss']
})
export class SwitchModeBarComponent {
  @Input() tc: string;
  @Input() switchModeButtons: SwitchModeButton[];

  constructor(private router: Router) {}

  onClick(switchModeButton: SwitchModeButton): void {
    if (!switchModeButton.active) {
      this.router.navigate(switchModeButton.routerLink);
    }
  }
}
