import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Changes } from 'ngx-reactivetoolkit';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DriverGradeFilterDto } from '../../../../../client';
import { ActiveFilter } from '../../../types/active-filter.type';
import { DriverGradeFilterWithActiveFilters } from '../../../types/driver-grade-filter-with-active-filters';
import { KeyLabel } from '../../../types/key-label.type';
import ActiveFiltersUtils from '../../../utils/active-filters.utils';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';

@Component({
  selector: 'sof-driver-grade-filter-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div
        class="sof-card"
        *ngFor="
          let driverGradeFilter of driverGradeFiltersWithActiveFilters$ | async
        "
      >
        <div class="filter-name">
          {{ driverGradeFilter.name }}
        </div>
        <div class="driver-grade-filters-container">
          <div
            class="driver-grade-filters-container-row"
            *ngIf="driverGradeFilter.otherActiveFilters?.length > 0"
          >
            <div>
              {{ tc + '.DRIVER-GRADE-DETAIL-VEHICLE-SECTION' | translate }}
            </div>
            <sof-active-filter
              *ngFor="
                let activeFilter of driverGradeFilter.otherActiveFilters
                  | maxArraySize: MAX_VISIBLE_ACTIVE_FILTERS
              "
              [tc]="tc"
              [value]="activeFilter.active"
              [label]="activeFilter.label"
              [translateKey]="activeFilter.translateKey"
              [interpolateParams]="activeFilter.interpolateParams"
              [activeFilter]="activeFilter"
              [canRemove]="false"
            >
            </sof-active-filter>
            <div
              *ngIf="
                driverGradeFilter.otherActiveFilters.length >
                MAX_VISIBLE_ACTIVE_FILTERS
              "
            >
              {{
                tc + '.AND-OTHER-ACTIVE-FILTERS'
                  | translate
                    : {
                        count:
                          driverGradeFilter.otherActiveFilters.length -
                          MAX_VISIBLE_ACTIVE_FILTERS
                      }
              }}
            </div>
          </div>
          <div
            class="driver-grade-filters-container-row"
            *ngIf="driverGradeFilter.vehicleGroupActiveFilters?.length > 0"
          >
            <div>
              {{ tc + '.DRIVER-GRADE-DETAIL-VEHICLE-GROUPS' | translate }}
            </div>
            <sof-active-filter
              *ngFor="
                let activeFilter of driverGradeFilter.vehicleGroupActiveFilters
                  | maxArraySize: MAX_VISIBLE_ACTIVE_FILTERS
              "
              [tc]="tc"
              [value]="activeFilter.active"
              [label]="activeFilter.label"
              [translateKey]="activeFilter.translateKey"
              [interpolateParams]="activeFilter.interpolateParams"
              [activeFilter]="activeFilter"
              [canRemove]="false"
            >
            </sof-active-filter>
            <div
              *ngIf="
                driverGradeFilter.vehicleGroupActiveFilters.length >
                MAX_VISIBLE_ACTIVE_FILTERS
              "
            >
              {{
                tc + '.AND-OTHER-ACTIVE-FILTERS'
                  | translate
                    : {
                        count:
                          driverGradeFilter.vehicleGroupActiveFilters.length -
                          MAX_VISIBLE_ACTIVE_FILTERS
                      }
              }}
            </div>
          </div>
          <div
            class="driver-grade-filters-container-row"
            *ngIf="driverGradeFilter.bookingActiveFilters?.length > 0"
          >
            <div>
              {{ tc + '.DRIVER-GRADE-DETAIL-BOOKING-SECTION' | translate }}
            </div>
            <sof-active-filter
              *ngFor="
                let activeFilter of driverGradeFilter.bookingActiveFilters
                  | maxArraySize: MAX_VISIBLE_ACTIVE_FILTERS
              "
              [tc]="tc"
              [value]="activeFilter.active"
              [label]="activeFilter.label"
              [translateKey]="activeFilter.translateKey"
              [interpolateParams]="activeFilter.interpolateParams"
              [activeFilter]="activeFilter"
              [canRemove]="false"
            >
            </sof-active-filter>
            <div
              *ngIf="
                driverGradeFilter.bookingActiveFilters.length >
                MAX_VISIBLE_ACTIVE_FILTERS
              "
            >
              {{
                tc + '.AND-OTHER-ACTIVE-FILTERS'
                  | translate
                    : {
                        count:
                          driverGradeFilter.bookingActiveFilters.length -
                          MAX_VISIBLE_ACTIVE_FILTERS
                      }
              }}
            </div>
          </div>
        </div>
        <button
          sofButton
          class="round-icon-button"
          (click)="editDriverGradeFilter.emit(driverGradeFilter)"
        >
          <sof-svg-icon icon="batt-icon-pencil" size="24"></sof-svg-icon>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./driver-grade-filter-list.component.scss']
})
export class DriverGradeFilterListComponent implements OnChanges, OnInit {
  MAX_VISIBLE_ACTIVE_FILTERS = 4;
  @Input() tc: string;
  @Input() driverGradeFilters: DriverGradeFilterDto[];
  @Input() organizationId: number;
  @Input() driverGradeId: number;

  @Output()
  editDriverGradeFilter: EventEmitter<DriverGradeFilterDto> = new EventEmitter<DriverGradeFilterDto>();

  @Changes('driverGradeFilters') driverGradeFilters$: Observable<
    DriverGradeFilterDto[]
  >;

  driverGradeFiltersWithActiveFilters$: Observable<
    DriverGradeFilterWithActiveFilters[]
  >;

  constructor(
    private osb: OrganizationManagementSandbox,
    private translateService: TranslateService
  ) {}

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.driverGradeFiltersWithActiveFilters$ = combineLatest([
      this.driverGradeFilters$,
      this.osb.vehicleModels$
    ]).pipe(
      map(
        ([driverGradeFilters, vehicleModels]: [
          DriverGradeFilterDto[],
          KeyLabel[]
        ]) => {
          if (!driverGradeFilters) {
            return [];
          }
          return driverGradeFilters?.map(driverGradeFilter => {
            return {
              ...driverGradeFilter,
              vehicleGroupActiveFilters: this.getVehicleGroupActiveFilters(
                driverGradeFilter
              ),
              otherActiveFilters: this.getOtherActiveFilters(
                driverGradeFilter,
                vehicleModels
              ),
              bookingActiveFilters: this.getBookingActiveFilters(
                driverGradeFilter
              )
            };
          });
        }
      )
    );
  }

  getVehicleGroupActiveFilters(
    driverGradeFilter: DriverGradeFilterDto
  ): Array<ActiveFilter> {
    return ActiveFiltersUtils.getVehicleGroupActiveFiltersForDriverGradeFilter(
      driverGradeFilter.vehicleGroups
    );
  }

  getOtherActiveFilters(
    driverGradeFilter: DriverGradeFilterDto,
    vehicleModels: Array<KeyLabel>
  ): Array<ActiveFilter> {
    const rangePrice: [number, number] = [
      ActiveFiltersUtils.PRICE_RANGE_MIN_VALUE,
      ActiveFiltersUtils.PRICE_RANGE_MAX_VALUE
    ];
    if (!!driverGradeFilter.minDayPrice) {
      rangePrice[0] = driverGradeFilter.minDayPrice;
    }
    if (!!driverGradeFilter.maxDayPrice) {
      rangePrice[1] = driverGradeFilter.maxDayPrice;
    }
    return ActiveFiltersUtils.getOtherActiveFiltersForDriverGradeFilter(
      rangePrice,
      driverGradeFilter.modelIds,
      vehicleModels
    );
  }

  getBookingActiveFilters(
    driverGradeFilter: DriverGradeFilterDto
  ): Array<ActiveFilter> {
    return ActiveFiltersUtils.getBookingActiveFiltersForDriverGradeFilter(
      this.translateService,
      this.tc,
      driverGradeFilter.maxDayFutureEnd
    );
  }
}
