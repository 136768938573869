import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MissingTranslationHandler,
  TranslateModule
} from '@ngx-translate/core';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { InputDatePickerModule } from '@sofico-framework/ui-kit/components/input-date-picker';
import { InputMultiSelectModule } from '@sofico-framework/ui-kit/components/input-multi-select';
import { InputSingleSelectModule } from '@sofico-framework/ui-kit/components/input-single-select';
import { InputTextModule } from '@sofico-framework/ui-kit/components/input-text';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { SoficoFormsModule } from '@sofico-framework/ui-kit/components/sofico-forms';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import {
  CustomTranslationHandler,
  UtilsPipesModule
} from '@sofico-framework/utils';
import { BookingsAuthorizationGuard } from '../guards/bookings-authorization.guard';
import { BookingService } from '../services/booking.service';
import { DateHourModule } from '../shared-ui/components/date-hour/date-hour.module';
import { LoadingSpinnerModule } from '../shared-ui/components/loading-spinner/loading-spinner.module';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { UserManagementSandbox } from '../user-management-ui/user-management.sandbox';
import { VehicleManagementSandbox } from '../vehicle-management-ui/vehicle-management.sandbox';
import { BookingManagementRoutingModule } from './booking-management-routing.module';
import { BookingManagementSandbox } from './booking-management.sandbox';
import { BookingFilterFormComponent } from './components/booking-filter-form/booking-filter-form.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { BookingsViewComponent } from './views/bookings-view/bookings-view.component';
import { CreateBookingDialogViewComponent } from './views/create-booking-dialog-view/create-booking-dialog-view.component';
import { EditBookingDialogViewComponent } from './views/edit-booking-dialog-view/edit-booking-dialog-view.component';

@NgModule({
  declarations: [
    BookingsViewComponent,
    BookingListComponent,
    BookingFilterFormComponent,
    CreateBookingDialogViewComponent,
    EditBookingDialogViewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    FormsModule,
    BookingManagementRoutingModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    SharedUiModule,
    UtilsPipesModule,
    DateHourModule,
    LicensePlateComponentModule,
    SoficoFormsModule,
    InputDatePickerModule,
    InputMultiSelectModule,
    InputSingleSelectModule,
    InputTextModule,
    ButtonModule,
    SvgIconModule
  ],
  exports: [BookingsViewComponent, BookingListComponent],
  providers: [
    BookingManagementSandbox,
    BookingService,
    UserManagementSandbox,
    VehicleManagementSandbox,
    BookingsAuthorizationGuard
  ]
})
export class BookingManagementModule {}
