import { ViewMode } from '../types/view-mode.type';
import { monthViewMode, SchedulerSetting } from '../scheduler.setting';
import { SchedulerStoredSettings } from '../types/scheduler-stored-settings.type';

const localStorageKey = 'scheduler_settings';
const initialSettings = {
  viewModeLabel: monthViewMode.label
};

function getStoredSettings(): SchedulerStoredSettings {
  const item = localStorage.getItem(localStorageKey);
  if (item) {
    return JSON.parse(item);
  }
  storeSettings(initialSettings);
  return initialSettings;
}

function storeSettings(settings: SchedulerStoredSettings): void {
  localStorage.setItem(localStorageKey, JSON.stringify(settings));
}

export function storeViewMode(viewMode: ViewMode): void {
  const settings = getStoredSettings();
  settings.viewModeLabel = viewMode.label;
  storeSettings(settings);
}

export function getStoredViewMode(): ViewMode {
  const settings = getStoredSettings();
  for (const viewMode of SchedulerSetting.viewModes) {
    if (viewMode.label === settings.viewModeLabel) {
      return viewMode;
    }
  }
  return null;
}
