import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MissingTranslationHandler,
  TranslateModule
} from '@ngx-translate/core';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { InputDatePickerModule } from '@sofico-framework/ui-kit/components/input-date-picker';
import { InputMultiSelectModule } from '@sofico-framework/ui-kit/components/input-multi-select';
import { InputSingleSelectModule } from '@sofico-framework/ui-kit/components/input-single-select';
import { InputTextareaModule } from '@sofico-framework/ui-kit/components/input-textarea';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { SoficoFormsModule } from '@sofico-framework/ui-kit/components/sofico-forms';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import {
  CustomTranslationHandler,
  UtilsPipesModule
} from '@sofico-framework/utils';
import { DamagesAuthorizationGuard } from '../guards/damages-authorization.guard';
import { DateHourModule } from '../shared-ui/components/date-hour/date-hour.module';
import { LoadingSpinnerModule } from '../shared-ui/components/loading-spinner/loading-spinner.module';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { UserManagementSandbox } from '../user-management-ui/user-management.sandbox';
import { VehicleManagementSandbox } from '../vehicle-management-ui/vehicle-management.sandbox';
import { DamageFilterFormComponent } from './components/damage-filter-form/damage-filter-form.component';
import { DamageListComponent } from './components/damage-list/damage-list.component';
import { DamageManagementRoutingModule } from './damage-management-routing.module';
import { DamageManagementSandbox } from './damage-management.sandbox';
import { DamageDetailViewComponent } from './views/damage-detail-view/damage-detail-view.component';
import { DamageViewComponent } from './views/damage-view/damage-view.component';

@NgModule({
  declarations: [
    DamageViewComponent,
    DamageListComponent,
    DamageDetailViewComponent,
    DamageFilterFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    FormsModule,
    DamageManagementRoutingModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    UtilsPipesModule,
    SharedUiModule,
    DateHourModule,
    LicensePlateComponentModule,
    SoficoFormsModule,
    InputDatePickerModule,
    InputMultiSelectModule,
    InputSingleSelectModule,
    ButtonModule,
    SvgIconModule,
    InputTextareaModule
  ],
  exports: [
    DamageViewComponent,
    DamageListComponent,
    DamageDetailViewComponent
  ],
  providers: [
    DamageManagementSandbox,
    UserManagementSandbox,
    VehicleManagementSandbox,
    DamagesAuthorizationGuard
  ]
})
export class DamageManagementModule {}
