import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrganizationDto } from  '../../../../../client';

@Component({
  selector: 'sof-organization-short-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div class="organization-name">
        {{ organization?.name }}
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-short-info.component.scss']
})
export class OrganizationShortInfoComponent {
  @Input() organization: OrganizationDto;
}
