import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@sofico-framework/ui-kit/components/breadcrumb';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationVehicleGroupDetailViewHelper } from '../../view-helpers/organization-vehicle-group-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-organization-vehicle-group-detail-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="organizationViewSwitchModeButtons"
      ></sof-switch-mode-bar>
      <sof-breadcrumb
        [tc]="tc"
        [breadcrumbs]="breadcrumbs$ | async"
      ></sof-breadcrumb>
      <div class="vehicle-group-info-container">
        <div>
          {{ (vh.vehicleGroup$ | async)?.name }}
        </div>
        <div class="spacer-container"></div>
        <div class="action-button-container">
          <button
            sofButton
            icon="batt-icon-pencil"
            class="main-action-button"
            [routerLink]="['edit-vehicle-group']"
            [disabled]="(vh.vehicleGroup$ | async) === null"
          >
            {{ tc + '.EDIT-VEHICLE-GROUP' | translate }}
          </button>
          <button
            sofButton
            icon="batt-icon-delete"
            class="main-action-button"
            (click)="onRemoveClick()"
            [disabled]="(vh.vehicleGroup$ | async) === null"
          >
            {{ tc + '.REMOVE-VEHICLE-GROUP' | translate }}
          </button>
        </div>
      </div>
      <div class="vehicles-container">
        <div class="vehicles-container-header">
          <div class="vehicles-label">
            {{ tc + '.SELECTED-VEHICLES' | translate }}
          </div>
          <div class="action-button-container">
            <button
              sofButton
              icon="batt-icon-plus"
              class="main-action-button"
              (click)="openVehiclesPopup()"
              [disabled]="(vh.vehicleGroup$ | async) === null"
            >
              {{ tc + '.EDIT-SELECTED-VEHICLES' | translate }}
            </button>
          </div>
        </div>
        <sof-vehicle-group-vehicle-list
          [vehicles]="(vh.vehicleGroup$ | async)?.vehicles"
          (deleteVehicle)="onDeleteVehicle($event)"
        >
        </sof-vehicle-group-vehicle-list>
      </div>
      <router-outlet></router-outlet>
    </ng-container>
  `,
  providers: [OrganizationVehicleGroupDetailViewHelper],
  styleUrls: ['./organization-vehicle-group-detail-view.component.scss']
})
export class OrganizationVehicleGroupDetailViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-VEHICLE-GROUP-DETAIL';

  organizationViewSwitchModeButtons =
    AppSettings.organizationViewSwitchModeButtons;

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastUtilService: ToastUtilService,
    private dialogService: DialogService,
    public vh: OrganizationVehicleGroupDetailViewHelper
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.getBreadcrumbs$();
  }

  getBreadcrumbs$(): Observable<Breadcrumb[]> {
    return combineLatest([this.vh.vehicleGroup$, this.vh.organization$]).pipe(
      map(([vehicleGroupDto, organizationDto]) => {
        const breadcrumbs = [];
        breadcrumbs.push({
          label: 'ORGANIZATION-VIEW',
          path: '/organizations'
        });
        breadcrumbs.push({
          translation: organizationDto?.name,
          path: '/organizations/' + organizationDto?.id + '/vehicle-groups'
        });
        breadcrumbs.push({
          translation: 'Vehicle group : ' + vehicleGroupDto?.name
        });
        return breadcrumbs;
      })
    );
  }

  onRemoveClick(): void {
    const confirm$ = this.vh.vehicleGroupId$.pipe(
      switchMap(vehicleGroupId => this.osb.deleteVehicleGroup(vehicleGroupId))
    );
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'DELETE-DIALOG-TITLE',
      'DELETE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() =>
          confirm$.pipe(
            take(1),
            catchError(error => of(error))
          )
        ),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(
            v,
            this.tc + '.FAILED_REMOVE-VEHICLE-GROUP'
          );
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REMOVE-VEHICLE-GROUP',
            true
          );
          dialog.destroy();
          this.router.navigate(['..'], {
            relativeTo: this.activatedRoute
          });
        }
      });
  }

  openVehiclesPopup(): void {
    this.router.navigate(['edit-vehicles'], {
      relativeTo: this.activatedRoute
    });
  }

  onDeleteVehicle(vehicleId: string): void {
    this.vh.vehicleGroupId$
      .pipe(
        switchMap(vehicleGroupId =>
          this.osb.deleteVehicleFromVehicleGroup(vehicleGroupId, vehicleId)
        ),
        take(1),
        takeUntilDestroy(this)
      )
      .subscribe(res => {
        this.vh.triggerVehicleGroup();
      });
  }
}
