import { Injectable } from '@angular/core';
import { OrganizationReferencePageDto } from  '../../../client';
import { BookingService } from '../services/booking.service';
import { Observable } from 'rxjs';
import { map, mapTo, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  BookingDto,
  RecurringAvailabilityDto,
  VehicleSearchCriteriaDto,
  FilterCriteriaForBookingDto,
  ConversationDto,
  BookingUserDto,
  VehicleFilterCriteriaDto,
  BatteryStatusDto,
  VehicleDto,
  GpsLocationDto,
  VehicleUsageDto,
  UserRoleDto
} from  '../../../client';
import { OrganizationService } from '../services/organization.service';
import { TelematicsService } from '../services/telematics.service';
import { Store } from '@ngrx/store';
import { VehicleUsageService } from '../services/vehicle-usage.service';
import { AppState } from '../statemanagement/app.state';
import {
  SetCurrentOrganizationIdAction,
  SetDateFromAction,
  SetShowMapAction,
  SetShowMyBookingsAction,
  SetViewmodeAction
} from '../statemanagement/actions/planning.actions';
import { ViewMode } from '../types/view-mode.type';
import { VehicleService } from './services/vehicle.service';
import { UserSettingsService } from './services/user-settings.service';
import { Vehicle } from '../types/vehicle.type';
import { SetVehiclesAction } from '../statemanagement/actions/vehicles.actions';
import { AvailabilityService } from '../services/availability.service';
import { ActiveSchedulerFilters } from '../types/active-scheduler-filters.type';
import { SetActiveSchedulerFilters } from '../statemanagement/actions/filter-set.actions';
import ConvertersUtils from '../shared-ui/utils/converters.utils';
import { ChatService } from '../services/chat.service';
import SharedUiUtils from '../shared-ui/utils/shared-ui.utils';

@Injectable()
export class SchedulerSandbox {
  dateFrom$ = this.store.select(state => state.planning.dateFrom);
  viewMode$ = this.store.select(state => state.planning.viewMode);
  showMap$ = this.store.select(state => state.planning.showMap);
  currentOrganizationId$ = this.store.select(
    state => state.planning.currentOrganizationId
  );
  showMyBookings$ = this.store.select(state => state.planning.showMyBookings);
  activeSchedulerFilters$ = this.store.select(
    state => state.activeSchedulerFilters
  );
  bookingRequests$ = this.store.select(state => state.bookingRequests);

  unavailabilities$ = this.store.select(state => state.unavailabilities);
  availabilities$ = this.store.select(state => state.availabilities);
  bookings$ = this.store.select(state => state.bookings);
  vehicles$ = this.store.select(state => state.vehicles);
  privateUsages$ = this.store.select(state => state.privateUsages);

  intents$ = this.bookingService.intents$;
  vehicleModels$ = this.bookingService.vehicleModels$;
  vehicleEquipments$ = this.bookingService.vehicleEquipments$;

  constructor(
    private store: Store<AppState>,
    private vehicleService: VehicleService,
    private userSettingsService: UserSettingsService,
    private availabilityService: AvailabilityService,
    private bookingService: BookingService,
    private telematicsService: TelematicsService,
    private chatService: ChatService,
    private vehicleUsageService: VehicleUsageService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  setDateFrom(newDate: Date): void {
    this.store.dispatch(new SetDateFromAction(newDate));
  }

  setViewMode(value: ViewMode): void {
    this.store.dispatch(new SetViewmodeAction(value));
  }

  setShowMap(value: boolean): void {
    this.store.dispatch(new SetShowMapAction(value));
  }

  setCurrentOrganizationId(value: string): void {
    this.store.dispatch(new SetCurrentOrganizationIdAction(value));
  }

  setShowMyBookings(value: boolean): void {
    this.store.dispatch(new SetShowMyBookingsAction(value));
  }

  // ----- vehicle management -----

  // if no parameter is provided, return all the vehicles
  // otherwise, only return the vehicles which are matching with the filters
  fetchVehicles(
    vehicleSearchCriteriaDto: VehicleSearchCriteriaDto,
    organizationId: string,
    user: BookingUserDto
  ): Observable<boolean> {
    // Create a fully new object (issue with state management object)
    const criteria: VehicleSearchCriteriaDto = JSON.parse(
      JSON.stringify({ ...vehicleSearchCriteriaDto })
    );
    if (!criteria.filterCriteria) {
      criteria.filterCriteria = {} as VehicleFilterCriteriaDto;
    }
    // God mode
    if (organizationId === SharedUiUtils.NO_ORGANIZATION_ID) {
      criteria.filterCriteria.organizationId = null;
      criteria.filterCriteria.userRemoteId = null;
    } else {
      criteria.filterCriteria.organizationId = !!organizationId
        ? organizationId
        : null;
      criteria.filterCriteria.userRemoteId = !!user?.remoteId
        ? user.remoteId
        : null;

      // As BattAdmin can access all organizations, he is not necessarily a member of the organization
      // In such case, only filter on the organization
      if (
        !!organizationId &&
        user.role === UserRoleDto.BATTADMIN &&
        !user.organizations.find(o => o.id === organizationId)
      ) {
        criteria.filterCriteria.userRemoteId = null;
      }
    }
    return this.bookingService.searchVehicles(criteria).pipe(
      map(pageDTO =>
        ConvertersUtils.convertVehiclePageDtoToVehiclesArray(pageDTO)
      ),
      tap((vehicles: Vehicle[]) =>
        this.store.dispatch(new SetVehiclesAction(vehicles))
      ),
      take(1),
      mapTo(true)
    );
  }

  fetchAllVehicleFieldsAndLabels(): {
    poolVehicle: { field: string; label: string }[];
  } {
    return {
      poolVehicle: this.vehicleService.fetchFieldInfos().map(fieldInfo => ({
        field: fieldInfo.field,
        label: fieldInfo.label
      }))
    };
  }

  // ----- booking management -----

  fetchAvailabilityEvents(
    vehicles: Vehicle[],
    fromDate: Date,
    toDate: Date,
    organizationId: string,
    user: BookingUserDto
  ): Observable<boolean> {
    // As BattAdmin can access all organizations, he is not necessarily a member of the organization
    // In such case, only filter on the organization
    if (
      !!organizationId &&
      user.role === UserRoleDto.BATTADMIN &&
      !user.organizations.find(o => o.id === organizationId)
    ) {
      user = null;
    }
    return SharedUiUtils.fetchAvailabilityEvents(
      this.availabilityService,
      this.store,
      vehicles,
      fromDate,
      toDate,
      organizationId,
      user
    );
  }

  // ----- filters management -----

  // the filters are not sent to any services, but just stored into the store
  setActiveSchedulerFilters(
    activeSchedulerFilters: ActiveSchedulerFilters
  ): void {
    this.store.dispatch(new SetActiveSchedulerFilters(activeSchedulerFilters));
  }

  // --------------- booking request management ---------------

  getRecurringAvailability(
    recurringAvailabilityId: string
  ): Observable<RecurringAvailabilityDto> {
    return this.availabilityService.getRecurringAvailability(
      recurringAvailabilityId
    );
  }

  getBooking(bookingId: string): Observable<BookingDto> {
    return this.bookingService.getBooking(bookingId);
  }

  getBookingFilters(
    bookingId: string
  ): Observable<FilterCriteriaForBookingDto> {
    return this.bookingService.getBookingFilters(bookingId);
  }

  searchBookingConversations(bookingId: string): Observable<ConversationDto[]> {
    return this.chatService
      .searchBookingConversations(bookingId)
      .pipe(map(page => page?.conversations));
  }

  getBatteryStatus(vehicleId: string): Observable<BatteryStatusDto> {
    return this.telematicsService.getBatteryStatus(vehicleId).pipe(take(1));
  }

  getVehicle(vehicleId: string): Observable<VehicleDto> {
    return this.bookingService.getVehicle(vehicleId).pipe(take(1));
  }

  refreshVehicleLocation(vehicleId: string): Observable<GpsLocationDto> {
    return this.telematicsService.refreshLocation(vehicleId).pipe(take(1));
  }

  endVehicleUsage(
    vehicleId: string,
    bookingId?: string
  ): Observable<VehicleUsageDto> {
    return this.vehicleUsageService.endVehicleUsage(bookingId, vehicleId);
  }

  getOrganizationsReferences(): Observable<OrganizationReferencePageDto> {
    return this.organizationService.getOrganizationsReferences();
  }
}
