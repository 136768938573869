import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@sofico-framework/ui-kit/components/breadcrumb';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { Tab } from '@sofico-framework/ui-kit/components/tab';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/index';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
  OrganizationDto,
  OrganizationRoleDto
} from  '../../../../../client';
import { AppSettings } from '../../../app.settings';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationWithDetail } from '../../../types/organization-with-detail.type';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-organization-detail-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="organizationViewSwitchModeButtons"
      ></sof-switch-mode-bar>
      <sof-breadcrumb
        [tc]="tc"
        [breadcrumbs]="breadcrumbsSub$ | async"
      ></sof-breadcrumb>
      <div class="organization-info-container">
        <sof-organization-avatar
          [organization]="organization$ | async"
        ></sof-organization-avatar>
        <sof-organization-short-info
          [organization]="organization$ | async"
        ></sof-organization-short-info>
        <div class="spacer"></div>
        <div class="action-button-container">
          <button
            sofButton
            icon="batt-icon-pencil"
            class="main-action-button"
            (click)="onEditClick()"
          >
            {{ tc + '.EDIT-ORGANIZATION' | translate }}
          </button>
          <button
            sofButton
            icon="batt-icon-delete"
            class="main-action-button"
            (click)="onRemoveClick()"
          >
            {{ tc + '.REMOVE-ORGANIZATION' | translate }}
          </button>
        </div>
      </div>
      <div class="organization-detail-container">
        <div class="organization-detail-add-actions">
          <button
            *ngIf="isMembersTabActive$ | async"
            sofButton
            icon="batt-icon-plus"
            class="main-action-button"
            (click)="showInviteMemberPopup(ORGANIZATION_ROLE_MEMBER)"
          >
            {{ tc + '.INVITE-NEW-MEMBER' | translate }}
          </button>
          <button
            *ngIf="isManagersTabActive$ | async"
            sofButton
            icon="batt-icon-plus"
            class="main-action-button"
            (click)="showInviteMemberPopup(ORGANIZATION_ROLE_MANAGER)"
          >
            {{ tc + '.INVITE-NEW-MANAGER' | translate }}
          </button>
          <button
            *ngIf="isVehicleGroupsTabActive$ | async"
            sofButton
            icon="batt-icon-plus"
            class="main-action-button"
            (click)="showCreateVehicleGroupPopup()"
          >
            {{ tc + '.CREATE-VEHICLE-GROUP' | translate }}
          </button>
          <button
            *ngIf="isDriverGradesTabActive$ | async"
            sofButton
            icon="batt-icon-plus"
            class="main-action-button"
            (click)="showCreateDriverGradePopup()"
          >
            {{ tc + '.CREATE-DRIVER-GRADE' | translate }}
          </button>
        </div>
        <sof-tabs
          [tc]="tc"
          [tabs]="tabs"
          [active]="activeTabSub$ | async"
          (clickedTab)="activeTabSub$.next($event)"
          class="batt-tabs"
        ></sof-tabs>
        <div class="full-height-container overflow-auto">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>
  `,
  providers: [OrganizationViewHelper],
  styleUrls: ['./organization-detail-view.component.scss']
})
export class OrganizationDetailViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-DETAIL';

  ORGANIZATION_ROLE_MEMBER = OrganizationRoleDto.MEMBER;
  ORGANIZATION_ROLE_MANAGER = OrganizationRoleDto.MANAGER;

  membersTab: Tab = {
    label: 'OVERVIEW-MEMBERS',
    routerLink: ['members']
  };
  managersTab: Tab = {
    label: 'OVERVIEW-MANAGERS',
    routerLink: ['managers']
  };
  vehicleGroupsTab: Tab = {
    label: 'VEHICLE-GROUPS',
    routerLink: ['vehicle-groups']
  };
  driverGradesTab: Tab = {
    label: 'DRIVER-GRADES',
    routerLink: ['driver-grades']
  };
  tabs: Tab[] = [
    this.membersTab,
    this.managersTab,
    this.vehicleGroupsTab,
    this.driverGradesTab
  ];
  activeTabSub$: BehaviorSubject<Tab> = new BehaviorSubject<Tab>(
    this.membersTab
  );
  isMembersTabActive$ = this.activeTabSub$.pipe(
    map(activeTab => activeTab === this.membersTab)
  );
  isManagersTabActive$ = this.activeTabSub$.pipe(
    map(activeTab => activeTab === this.managersTab)
  );
  isVehicleGroupsTabActive$ = this.activeTabSub$.pipe(
    map(activeTab => activeTab === this.vehicleGroupsTab)
  );
  isDriverGradesTabActive$ = this.activeTabSub$.pipe(
    map(activeTab => activeTab === this.driverGradesTab)
  );

  breadcrumbsSub$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<
    Breadcrumb[]
  >([]);
  organizationViewSwitchModeButtons =
    AppSettings.organizationViewSwitchModeButtons;

  private organizationId$: Observable<string>;
  organization$: Observable<OrganizationDto>;
  organizationWithDetail$: Observable<OrganizationWithDetail>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastUtilService: ToastUtilService,
    private organizationViewHelper: OrganizationViewHelper,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.organizationId$ = this.organizationViewHelper.organizationId$;
    this.organization$ = this.organizationViewHelper.organization$;
    this.organizationWithDetail$ = this.organizationViewHelper.organizationWithDetail$;

    this.organization$
      .pipe(takeUntilDestroy(this))
      .subscribe(organizationDto => {
        const breadcrumbs = [];
        breadcrumbs.push({
          label: 'ORGANIZATION-VIEW',
          path: '/organizations'
        });
        breadcrumbs.push({
          translation: organizationDto?.name
        });
        this.breadcrumbsSub$.next(breadcrumbs);

        // Make sure the valid active tab is selected according to the URL
        const baseUrl = '/organizations/' + organizationDto.id + '/';
        if (
          this.router.url.startsWith(baseUrl + this.membersTab.routerLink[0])
        ) {
          this.activeTabSub$.next(this.membersTab);
        } else if (
          this.router.url.startsWith(baseUrl + this.managersTab.routerLink[0])
        ) {
          this.activeTabSub$.next(this.managersTab);
        } else if (
          this.router.url.startsWith(
            baseUrl + this.vehicleGroupsTab.routerLink[0]
          )
        ) {
          this.activeTabSub$.next(this.vehicleGroupsTab);
        } else if (
          this.router.url.startsWith(
            baseUrl + this.driverGradesTab.routerLink[0]
          )
        ) {
          this.activeTabSub$.next(this.driverGradesTab);
        }
      });
  }

  showInviteMemberPopup(organizationRole: OrganizationRoleDto): void {
    if (organizationRole === OrganizationRoleDto.MANAGER) {
      this.router.navigate(
        ['managers', 'invite-member', 'email-address-step'],
        { relativeTo: this.activatedRoute }
      );
    } else {
      this.router.navigate(['members', 'invite-member', 'email-address-step'], {
        relativeTo: this.activatedRoute
      });
    }
  }

  showCreateVehicleGroupPopup(): void {
    this.router.navigate(['vehicle-groups', 'create-vehicle-group'], {
      relativeTo: this.activatedRoute
    });
  }

  showCreateDriverGradePopup(): void {
    this.router.navigate(['driver-grades', 'create-driver-grade'], {
      relativeTo: this.activatedRoute
    });
  }

  onEditClick(): void {
    // Depending on the selected tab, the URL can be :
    //  - organizations/:id/members
    //  - organizations/:id/managers
    //  - organizations/:id/vehicle-groups
    //  - organizations/:id/driver-grades
    // We need to get the current tab in order to display the valid tab when the edit dialog is closed
    const currentTabPath = this.router.url.split('?')[0].split('/').pop();
    this.router.navigate([currentTabPath, 'edit-organization'], {
      relativeTo: this.activatedRoute
    });
  }

  onRemoveClick(): void {
    this.organizationId$
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(organizationId => {
        const dialog = this.dialogService.openConfirmModal(
          this.tc,
          'DELETE-DIALOG-TITLE',
          'DELETE-DIALOG-TEXT',
          'DIALOG-CANCEL',
          'DIALOG-CONFIRM'
        );
        dialog.confirm$
          .pipe(
            switchMap(() =>
              this.osb.deleteOrganization(organizationId).pipe(
                take(1),
                catchError(error => of(error))
              )
            ),
            takeUntilDestroy(this)
          )
          .subscribe(v => {
            if (v instanceof HttpErrorResponse) {
              this.toastUtilService.showError(
                v,
                this.tc + '.FAILED_REMOVE-ORGANIZATION'
              );
            } else {
              this.toastUtilService.success(
                this.tc + '.SUCCESSFUL_REMOVE-ORGANIZATION',
                true
              );
              dialog.destroy();
              this.router.navigate(['..'], {
                relativeTo: this.activatedRoute
              });
            }
          });
      });
  }
}
