/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VehicleUsageStatusDto = 'BLUETOOTH_LOCKING' | 'BLUETOOTH_UNLOCKING' | 'LOCKED' | 'LOCKING' | 'LOCK_FAILED' | 'UNLOCKED' | 'UNLOCKING' | 'UNLOCK_FAILED';

export const VehicleUsageStatusDto = {
    BLUETOOTHLOCKING: 'BLUETOOTH_LOCKING' as VehicleUsageStatusDto,
    BLUETOOTHUNLOCKING: 'BLUETOOTH_UNLOCKING' as VehicleUsageStatusDto,
    LOCKED: 'LOCKED' as VehicleUsageStatusDto,
    LOCKING: 'LOCKING' as VehicleUsageStatusDto,
    LOCKFAILED: 'LOCK_FAILED' as VehicleUsageStatusDto,
    UNLOCKED: 'UNLOCKED' as VehicleUsageStatusDto,
    UNLOCKING: 'UNLOCKING' as VehicleUsageStatusDto,
    UNLOCKFAILED: 'UNLOCK_FAILED' as VehicleUsageStatusDto
};

