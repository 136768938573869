import { Pipe, PipeTransform } from '@angular/core';
import AvatarUtils from '../../utils/avatar.utils';

@Pipe({
  name: 'avatarInitials'
})
export class AvatarInitialsPipe implements PipeTransform {
  transform(name: string): string {
    return AvatarUtils.getAvatarInitials(name);
  }
}
