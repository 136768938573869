import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@sofico-framework/ui-kit/components/toast-hook';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastUtilService {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  error(message: string, translate?: boolean, interpolateParams?: any): void {
    // TODO - to be updated when toastService can take parameters for translation
    if (translate && !!interpolateParams) {
      this.translateService
        .stream(message, interpolateParams)
        .pipe(take(1))
        .subscribe(translation => {
          this.toastService.error(translation);
        });
    } else {
      this.toastService.error(message, translate);
    }
  }

  success(message: string, translate?: boolean, interpolateParams?: any): void {
    // TODO - to be updated when toastService can take parameters for translation
    if (translate && !!interpolateParams) {
      this.translateService
        .stream(message, interpolateParams)
        .pipe(take(1))
        .subscribe(translation => {
          this.toastService.success(translation);
        });
    } else {
      this.toastService.success(message, translate);
    }
  }

  warning(message: string, translate?: boolean, interpolateParams?: any): void {
    // TODO - to be updated when toastService can take parameters for translation
    if (translate && !!interpolateParams) {
      this.translateService
        .stream(message, interpolateParams)
        .pipe(take(1))
        .subscribe(translation => {
          this.toastService.warning(translation);
        });
    } else {
      this.toastService.warning(message, translate);
    }
  }

  showError(
    response: HttpErrorResponse,
    message: string,
    interpolateParams?: any
  ): void {
    // TODO - to be updated when toastService can take parameters for translation
    this.translateService
      .stream(message, interpolateParams)
      .pipe(take(1))
      .subscribe(translation => {
        let detail = response.error;
        if (!!response?.error?.translatedMsg) {
          detail = response.error.translatedMsg;
        } else if (!!response?.message) {
          detail = response.message;
        }
        this.toastService.error(translation + '\n' + detail);
      });
  }
}
