import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@sofico-framework/ui-kit/components/breadcrumb';
import { DialogService } from '@sofico-framework/ui-kit/components/dialog';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { DriverGradeDto } from  '../../../../../client';
import { AppSettings } from '../../../app.settings';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationMemberDetailViewHelper } from '../../view-helpers/organization-member-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-organization-member-detail-view',
  template: `
    <ng-container>
      <sof-loading-spinner></sof-loading-spinner>
      <sof-switch-mode-bar
        [tc]="tc"
        [switchModeButtons]="organizationViewSwitchModeButtons"
      ></sof-switch-mode-bar>
      <sof-breadcrumb
        [tc]="tc"
        [breadcrumbs]="breadcrumbs$ | async"
      ></sof-breadcrumb>
      <sof-organization-member-detail
        [tc]="tc"
        [organizationMember]="vh.organizationMember$ | async"
        [disableAddDriverGrade]="vh.disableAddDriverGrade$ | async"
        (addDriverGrade)="onAddDriverGradeClick()"
        (removeDriverGrade)="onRemoveDriverGrade($event)"
        (removeOrganizationMember)="onRemoveClick()"
      ></sof-organization-member-detail>
      <router-outlet></router-outlet>
    </ng-container>
  `,
  providers: [OrganizationMemberDetailViewHelper],
  styleUrls: ['./organization-member-detail-view.component.scss']
})
export class OrganizationMemberDetailViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-MEMBER-DETAIL';

  organizationViewSwitchModeButtons =
    AppSettings.organizationViewSwitchModeButtons;
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(
    private osb: OrganizationManagementSandbox,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastUtilService: ToastUtilService,
    private dialogService: DialogService,
    public vh: OrganizationMemberDetailViewHelper
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.getBreadcrumbs$();
  }

  getBreadcrumbs$(): Observable<Breadcrumb[]> {
    return combineLatest([
      this.vh.organization$,
      this.vh.organizationMember$
    ]).pipe(
      map(([organizationDto, organizationMemberDto]) => {
        const baseUrl = '/organizations/' + organizationDto.id + '/managers';
        const backPath = this.router.url.startsWith(baseUrl)
          ? '/managers'
          : '/members';
        const breadcrumbs = [];
        breadcrumbs.push({
          label: 'ORGANIZATION-VIEW',
          path: '/organizations'
        });
        breadcrumbs.push({
          translation: organizationDto?.name,
          path: '/organizations/' + organizationDto?.id + backPath
        });
        breadcrumbs.push({
          translation: 'Member : ' + organizationMemberDto?.user?.displayName
        });
        return breadcrumbs;
      })
    );
  }

  onRemoveClick(): void {
    const confirm$ = combineLatest([
      this.vh.organizationId$,
      this.vh.userId$
    ]).pipe(
      switchMap(([organizationId, userId]) =>
        this.osb.deleteUserFromOrganization(organizationId, userId)
      )
    );
    const dialog = this.dialogService.openConfirmModal(
      this.tc,
      'DELETE-DIALOG-TITLE',
      'DELETE-DIALOG-TEXT',
      'DIALOG-CANCEL',
      'DIALOG-CONFIRM'
    );
    dialog.confirm$
      .pipe(
        switchMap(() =>
          confirm$.pipe(
            take(1),
            catchError(error => of(error))
          )
        ),
        takeUntilDestroy(this)
      )
      .subscribe(v => {
        if (v instanceof HttpErrorResponse) {
          this.toastUtilService.showError(v, this.tc + '.FAILED_REMOVE-MEMBER');
        } else {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REMOVE-MEMBER',
            true
          );
          dialog.destroy();
          this.router
            .navigate(['..'], {
              relativeTo: this.activatedRoute
            })
            .then(() => {
              this.vh.triggerOrganization();
            });
        }
      });
  }

  onAddDriverGradeClick(): void {
    this.router.navigate(['member-add-driver-grade'], {
      relativeTo: this.activatedRoute
    });
  }

  onRemoveDriverGrade(driverGrade: DriverGradeDto): void {
    combineLatest([this.vh.organizationId$, this.vh.userId$])
      .pipe(
        switchMap(([organizationId, userId]) =>
          this.osb.deleteUserFromDriverGrade(
            driverGrade.id,
            organizationId,
            userId
          )
        ),
        take(1),
        takeUntilDestroy(this)
      )
      .subscribe(
        result => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_DELETE-USER-FROM-DRIVER-GRADE',
            true
          );
          this.vh.triggerOrganization();
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_DELETE-USER-FROM-DRIVER-GRADE'
          );
        }
      );
  }
}
