import { ActionTypes } from '../action-types';
import { ConversationDto } from '../../../../client';
import { ConversationsActions } from '../actions/conversations.actions';

export function conversationsReducer(
  state: ConversationDto[] = [],
  action: ConversationsActions
): ConversationDto[] {
  switch (action.type) {
    case ActionTypes.SET_CONVERSATIONS:
      return action.payload.conversations;
    case ActionTypes.UPDATE_CONVERSATION:
      return state.map(item =>
        item.id === action.payload.conversationId
          ? { ...item, ...action.payload.conversation }
          : item
      );
    default:
      return state;
  }
}
