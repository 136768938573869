import { ActionTypes } from '../action-types';
import { AvailabilityEvent } from '../../types/availability-event.type';
import { AvailabilitiesActions } from '../actions/availabilities.actions';

export function availabilitiesReducer(
  state: AvailabilityEvent[] = [],
  action: AvailabilitiesActions
): AvailabilityEvent[] {
  switch (action.type) {
    case ActionTypes.SET_AVAILABILITIES:
      return [...action.payload.availabilities];
  }
  return state;
}
