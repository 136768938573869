import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@sofico-framework/app-config';
import { Tab } from '@sofico-framework/ui-kit/components/tab';
import { TabsComponent } from '@sofico-framework/ui-kit/components/tabs';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { ToastUtilService } from '../../../services/toast-util.service';
import { VehicleDialogData } from '../../../types/vehicle-dialog-data.type';
import { SharedUiSandbox } from '../../shared-ui.sandbox';
import {
  BatteryStatusDto,
  BookingUserDto,
  ChargingStatusDto,
  Configuration,
  CreateVehicleRequestDto,
  DamageDto,
  DamageStatusDto,
  EnumValueDto,
  GpsLocationDto,
  OrganizationReferenceDto,
  SearchDamageRequestDto,
  TimeZoneDto,
  UpdateVehicleRequestDto,
  VehicleDetailsDto,
  VehicleDto,
  VehicleModelDto
} from '../../../../../client';
import { BatteryStatusWithDetail } from '../../../types/battery-status-with-detail.type';
import ConvertersUtils from '../../utils/converters.utils';
import { DateUtil } from '../../../helpers/date-util';
import {
  BehaviorSubject,
  combineLatest,
  of,
  ReplaySubject,
  Subject,
  throwError
} from 'rxjs';
import { Observable } from 'rxjs/index';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  pairwise,
  shareReplay,
  skip,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { VehicleBrand } from '../../../types/vehicle-brand.type';
import {
  DateFormatEnum,
  search,
  WindowRefService
} from '@sofico-framework/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import SharedUiUtils from '../../utils/shared-ui.utils';
import { TrustedUrl } from '../../../types/trusted-url.type';
import { DamageStatus } from '../../../types/damage-status.type';
import { GoogleHelper } from '../../view-helpers/google.helper';

@UntilDestroy()
@Component({
  selector: 'sof-vehicle-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="xl"
      [headerLabel]="tc + '.' + headerLabel | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="dialog-content">
          <sof-tabs
            #sofTabs
            [tc]="tc"
            [tabs]="tabs"
            [active]="activeTab"
            (clickedTab)="updateActiveTab($event)"
            class="batt-tabs"
          ></sof-tabs>
          <div
            [class.tab-restricted]="activeTab !== generalTab"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-dialog-general-tab-content
              [tc]="tc"
              [generalForm]="generalForm"
              [isReadonly]="isReadonlySub$ | async"
              [equipments]="vehicleEquipments$ | async"
              [batteryStatus]="batteryStatus"
              [trustedUrl]="trustedUrl"
              [vehicleDto]="vehicleDialogData?.vehicleDto"
              [homeLocation]="homeLocationSub$ | async"
              [imageBasePath]="basePath"
              [allUsers]="vehicleDialogData?.userPage?.users"
              [userOrganizations]="userOrganizationsSub$ | async"
              [isGoogleApiEnabled]="isGoogleApiEnabledSub$ | async"
              (vehicleImageChange)="onVehicleImageChange($event)"
              (newHomeLocation)="homeLocationSub$.next($event)"
              (changeUser)="onChangeUser($event)"
            >
            </sof-vehicle-dialog-general-tab-content>
          </div>
          <div
            [class.tab-restricted]="activeTab !== usageTab"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-dialog-usage-tab-content
              [tc]="tc"
              [usageForm]="usageForm"
              [errorMap]="errorMap"
              [dateFormat]="dateFormat"
              [isReadonly]="isReadonlySub$ | async"
              [petsAllowed]="petsAllowed"
              (petsAllowedChange)="petsAllowed = $event"
              [kidsAllowed]="kidsAllowed"
              (kidsAllowedChange)="kidsAllowed = $event"
              [abroadAllowed]="abroadAllowed"
              (abroadAllowedChange)="abroadAllowed = $event"
              [intents]="intents$ | async"
              [selectedIntents]="selectedIntents"
              (selectedIntentsChange)="selectedIntents = $event"
            >
            </sof-vehicle-dialog-usage-tab-content>
          </div>
          <div
            [class.tab-restricted]="activeTab !== detailTab"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-dialog-detail-tab-content
              [tc]="tc"
              [detailForm]="detailForm"
              [errorMap]="errorMap"
              [vehicleBodyStyles]="vehicleBodyStyles$ | async"
              [vehicleColors]="vehicleColors$ | async"
              [brandsFiltered]="brandsFiltered$ | async"
              [vehicleModelsForBrandFiltered]="
                vehicleModelsForBrandFiltered$ | async
              "
              (newBrandSearchText)="newBrandSearchTextSub$.next($event)"
              (newModelSearchText)="newModelSearchTextSub$.next($event)"
            >
            </sof-vehicle-dialog-detail-tab-content>
          </div>
          <div
            [class.tab-restricted]="activeTab !== telematicsTab"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-dialog-telematics-tab-content
              [tc]="tc"
              [telematicsForm]="telematicsForm"
              [errorMap]="errorMap"
              [isReadonly]="isReadonlySub$ | async"
              [isEditingTelematics]="isEditingTelematicsSub$ | async"
              (editTelematics)="isEditingTelematicsSub$.next(true)"
              (saveTelematics)="saveTelematics()"
            >
            </sof-vehicle-dialog-telematics-tab-content>
          </div>
          <div
            [class.tab-restricted]="activeTab !== damagesTab"
            class="full-height-container overflow-auto"
          >
            <sof-vehicle-dialog-damages-tab-content
              [tc]="tc"
              [isReadonly]="isReadonlySub$ | async"
              [shownGroupStatus]="shownGroupStatusSub$ | async"
              [damages]="damagesSub$ | async"
              [imageBasePath]="basePath"
              (shownGroupStatusChange)="shownGroupStatusSub$.next($event)"
              (damageStatusChange)="onDamageStatusChange($event)"
            >
            </sof-vehicle-dialog-damages-tab-content>
          </div>
          <div
            [class.tab-restricted]="activeTab !== messagesTab"
            class="full-height-container overflow-auto"
          >
            <sof-no-result
              [tc]="tc"
              noResultLabel="CHAT-NOT-IMPLEMENTED"
            ></sof-no-result>
          </div>
        </div>
      </div>
      <div
        sof-dialog-footer
        [class.sof-vehicle-dialog-footer]="!!vehicleDialogData?.vehicleId"
      >
        <div class="d-flex gg-05" *ngIf="!!vehicleDialogData?.vehicleId">
          <button
            *ngIf="(isReadonlySub$ | async) === false"
            sofButton
            icon="batt-icon-lock-open"
            iconSize="24"
            class="round-icon-button unlock"
            aria-label="UnLock"
            [disabled]="isEditingTelematicsSub$ | async"
            (click)="vehicleChangeLockStatus(false)"
          ></button>
          <button
            *ngIf="(isReadonlySub$ | async) === false"
            sofButton
            icon="batt-icon-lock-closed"
            iconSize="24"
            class="round-icon-button lock"
            aria-label="Lock"
            [disabled]="isEditingTelematicsSub$ | async"
            (click)="vehicleChangeLockStatus(true)"
          ></button>
        </div>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            *ngIf="(isReadonlySub$ | async) === false"
            sofButton
            [disabled]="!canSave || (isEditingTelematicsSub$ | async)"
            (click)="onSaveClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogComponent implements OnInit {
  tc = 'VEHICLE_DIALOG';

  @Input() headerLabel: string;
  @Input() vehicleDialogData: VehicleDialogData;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  closeDialogAfterSave: EventEmitter<VehicleDto> = new EventEmitter<VehicleDto>();

  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  DAMAGE_STATUS_GROUP_PENDING = 'PENDING';

  searchablePropertiesForBrand = ['name'];
  searchablePropertiesForModel = ['name'];

  TAB_ID_TELEMATICS = 'telematicsTab';
  generalTab: Tab = {
    label: 'GENERAL'
  };
  usageTab: Tab = {
    label: 'USAGE'
  };
  detailTab: Tab = {
    label: 'DETAIL'
  };
  telematicsTab: Tab = {
    id: this.TAB_ID_TELEMATICS,
    label: 'TELEMATICS'
  };
  damagesTab: Tab = {
    label: 'DAMAGES'
  };
  messagesTab: Tab = {
    label: 'MESSAGES'
  };
  tabs: Tab[] = [
    this.generalTab,
    this.usageTab,
    this.detailTab,
    this.telematicsTab,
    this.damagesTab,
    this.messagesTab
  ];
  activeTab: Tab = this.generalTab;

  vehicleEquipments$ = this.sharedUiSandbox.vehicleEquipments$;
  brands$: Observable<Array<VehicleBrand>>;
  newBrandSearchTextSub$: BehaviorSubject<string>;
  brandsFiltered$: Observable<Array<VehicleBrand>>;
  vehicleModelsForBrand$: Observable<Array<VehicleModelDto>>;
  newModelSearchTextSub$: BehaviorSubject<string>;
  vehicleModelsForBrandFiltered$: Observable<Array<VehicleModelDto>>;
  triggerFetchTelematicsSub$: Subject<boolean> = new Subject<boolean>();
  vehicleBodyStyles$: Observable<Array<EnumValueDto>>;
  vehicleColors$: Observable<Array<EnumValueDto>>;
  homeLocationSub$: BehaviorSubject<GpsLocationDto>;
  triggerFetchDamagesSub$: Subject<boolean> = new Subject<boolean>();
  damagesSub$: BehaviorSubject<Array<DamageDto>> = new BehaviorSubject<
    Array<DamageDto>
  >(null);
  shownGroupStatusSub$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.DAMAGE_STATUS_GROUP_PENDING
  );
  isGoogleApiEnabledSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  canSave = true;
  hasTelematics = false;
  isReadonlySub$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isEditingTelematicsSub$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  setOrganizationId = false;
  userOrganizationsSub$: BehaviorSubject<
    Array<OrganizationReferenceDto>
  > = new BehaviorSubject<Array<OrganizationReferenceDto>>(null);

  // source stream
  private userIdSub$ = new ReplaySubject<string>(1);
  private bookingUser$: Observable<BookingUserDto>;

  @ViewChild('sofTabs')
  sofTabs: TabsComponent;

  errorMap = {
    min: 'VALIDATION_MIN_VALUE'
  };
  generalForm: FormGroup;
  usageForm: FormGroup;
  detailForm: FormGroup;
  telematicsForm: FormGroup;
  petsAllowed: boolean = null;
  kidsAllowed: boolean = null;
  abroadAllowed: boolean = null;
  selectedIntents: Array<string>;

  intents$ = this.sharedUiSandbox.intents$;
  basePath = 'http://dev-api.battmobility.com';
  batteryStatus: BatteryStatusWithDetail = {
    inError: false,
    batteryPercentage: 0,
    batteryStatusClasses: {
      'very-low': true,
      good: false,
      low: false,
      normal: false
    },
    charging: ChargingStatusDto.UNKNOWN,
    chargingStatusIcon: 'batt-icon-battery-unknown',
    cruisingRange: 0
  };

  trustedUrl: TrustedUrl;
  file: File;

  constructor(
    private fb: FormBuilder,
    @Optional() configuration: Configuration,
    private sharedUiSandbox: SharedUiSandbox,
    private toastUtilService: ToastUtilService,
    private domSanitizer: DomSanitizer,
    private windowRef: WindowRefService,
    private configService: ConfigService,
    private renderer: Renderer2,
    private googleHelper: GoogleHelper
  ) {
    if (configuration) {
      this.basePath = configuration.basePath;
    }
  }

  ngOnInit(): void {
    this.initDialogData();

    this.newBrandSearchTextSub$ = new BehaviorSubject<string>(
      !!this.vehicleDialogData?.vehicleDto?.details?.model?.brand
        ? this.vehicleDialogData?.vehicleDto?.details?.model?.brand
        : ''
    );
    this.newModelSearchTextSub$ = new BehaviorSubject<string>(
      !!this.vehicleDialogData?.vehicleDto?.details?.model?.name
        ? this.vehicleDialogData?.vehicleDto?.details?.model?.name
        : ''
    );

    this.homeLocationSub$ = new BehaviorSubject<GpsLocationDto>(
      this.getInitHomeLocation()
    );

    this.vehicleBodyStyles$ = this.sharedUiSandbox.vehicleBodyStyles$;
    this.vehicleColors$ = this.sharedUiSandbox.vehicleColors$;
    this.brands$ = this.sharedUiSandbox.vehicleBrands$;
    this.brandsFiltered$ = this.brands$.pipe(
      search(this.newBrandSearchTextSub$, this.searchablePropertiesForBrand)
    );
    this.vehicleModelsForBrand$ = combineLatest([
      this.brands$,
      this.newBrandSearchTextSub$
    ]).pipe(
      map(
        ([brands, searchText]) =>
          brands?.find(brand => brand.name === searchText)?.models
      )
    );
    this.vehicleModelsForBrandFiltered$ = this.vehicleModelsForBrand$.pipe(
      search(this.newModelSearchTextSub$, this.searchablePropertiesForModel)
    );
    this.newBrandSearchTextSub$
      .pipe(
        pairwise(),
        filter(([prevValue, newValue]) => prevValue !== newValue)
      )
      .subscribe(() => {
        this.newModelSearchTextSub$.next('');
        this.detailForm.controls.modelName.setValue('');
      });

    this.triggerFetchTelematicsSub$
      .pipe(
        first(),
        switchMap(() =>
          this.sharedUiSandbox
            .getTelematics(this.vehicleDialogData?.vehicleId)
            .pipe(take(1))
        )
      )
      .subscribe(
        telematicsDeviceDto => {
          this.hasTelematics = true;
          this.telematicsForm.controls.providerDeviceId.setValue(
            telematicsDeviceDto.providerDeviceId
          );
          this.telematicsForm.controls.providerId.setValue(
            telematicsDeviceDto.providerId
          );
          this.telematicsForm.controls.maxRange.setValue(
            telematicsDeviceDto.maxRange
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_GET-TELEMATICS'
          );
        }
      );
    combineLatest([
      this.isReadonlySub$,
      this.isEditingTelematicsSub$
    ]).subscribe(([isReadonly, isEditingTelematics]) => {
      this.updateTelematicsDisableState(isReadonly, isEditingTelematics);
    });
    this.isReadonlySub$.subscribe(isReadonly => {
      this.updateDisableState(isReadonly);
    });
    this.triggerFetchDamagesSub$
      .pipe(
        withLatestFrom(this.shownGroupStatusSub$),
        switchMap(([trigger, shownGroupStatus]) => {
          const statusses =
            shownGroupStatus === this.DAMAGE_STATUS_GROUP_PENDING
              ? [DamageStatusDto.CREATED]
              : [DamageStatusDto.CREATED, DamageStatusDto.FIXED];
          const requestDto: SearchDamageRequestDto = {
            vehicleId: this.vehicleDialogData?.vehicleId,
            shownStatusses: statusses
          };
          return this.sharedUiSandbox.searchDamages(requestDto).pipe(take(1));
        })
      )
      .subscribe(damagePageDto => {
        this.damagesSub$.next(
          damagePageDto?.damages?.sort(this.compareDamages)
        );
      });
    this.shownGroupStatusSub$.pipe(skip(1)).subscribe(() => {
      this.triggerFetchDamagesSub$.next(true);
    });

    // TODO - Temporary solution - To be removed when user search and Dtos will be updated
    this.bookingUser$ = this.getBookingUser$();
    this.bookingUser$.subscribe(bookingUser => {
      this.userOrganizationsSub$.next([...bookingUser.organizations]);
      if (this.setOrganizationId) {
        // In case the selected organization is part of the user organizations we use the selected organization
        if (
          !bookingUser.organizations?.find(
            o => o.id === this.generalForm.getRawValue().organization
          )
        ) {
          this.generalForm.controls.organization.setValue(
            !!bookingUser.defaultOrganization
              ? bookingUser.defaultOrganization.id
              : bookingUser.organizations?.[0]?.id
          );
        }
      }
    });
    if (!!this.vehicleDialogData?.vehicleId) {
      this.updateUserData(
        this.vehicleDialogData?.vehicleDto?.ownerReference?.remoteId,
        false
      );
    } else {
      this.updateUserData(this.vehicleDialogData?.currentUser?.remoteId, true);
    }
  }

  initDialogData(): void {
    if (!!this.vehicleDialogData?.vehicleDto) {
      this.petsAllowed = this.vehicleDialogData?.vehicleDto.permissions.petsAllowed;
      this.kidsAllowed = this.vehicleDialogData?.vehicleDto.permissions.kidsAllowed;
      this.abroadAllowed = this.vehicleDialogData?.vehicleDto.permissions.abroadAllowed;
      this.selectedIntents = this.vehicleDialogData?.vehicleDto.intentIds;
    }
    // TODO - TAKE INTO ACCOUNT ORGANIZATION ADMIN WHEN GET ORGANIZATION MEMBER SERVICE WILL BE AVAILABLE
    this.isReadonlySub$.next(
      !!this.vehicleDialogData?.vehicleId &&
        !SharedUiUtils.isUserVehicleOwnerOrAdmin(
          this.vehicleDialogData?.currentUser,
          this.vehicleDialogData?.vehicleDto
        )
    );
    // When creating a vehicle, user current user data for own and organization
    const owner = !this.vehicleDialogData?.vehicleId
      ? this.vehicleDialogData?.currentUser?.remoteId
      : this.vehicleDialogData?.vehicleDto?.ownerReference?.remoteId;
    const organizationId = !this.vehicleDialogData?.vehicleId
      ? this.vehicleDialogData?.currentUser?.defaultOrganization?.id
      : this.vehicleDialogData?.vehicleDto?.organization?.id;
    const timeZone = !this.vehicleDialogData?.vehicleDto
      ? TimeZoneDto.BRUSSELS
      : this.vehicleDialogData?.vehicleDto?.timeZone;
    this.generalForm = this.fb.group({
      name: [this.vehicleDialogData?.vehicleDto?.name, Validators.required],
      licensePlate: [
        this.vehicleDialogData?.vehicleDto?.licensePlate,
        Validators.required
      ],
      organization: [organizationId, Validators.required],
      owner: [owner, Validators.required],
      description: [this.vehicleDialogData?.vehicleDto?.description],
      timeZone: [timeZone, Validators.required],
      equipmentIds: [this.vehicleDialogData?.vehicleDto?.equipmentIds],
      latitude: [this.vehicleDialogData?.vehicleDto?.homePosition?.latitude],
      longitude: [this.vehicleDialogData?.vehicleDto?.homePosition?.longitude],
      address: [this.vehicleDialogData?.vehicleDto?.address]
    });
    this.usageForm = this.fb.group({
      alwaysAvailable: [this.vehicleDialogData?.vehicleDto?.alwaysAvailable],
      automaticBookingApproval: [
        this.vehicleDialogData?.vehicleDto?.automaticBookingApproval
      ],
      actualInFleetDate: [
        {
          value: DateUtil.convertToDate(
            this.vehicleDialogData?.vehicleDto?.actualInFleetDate
          ),
          disabled: true
        }
      ],
      actualOutOfFleetDate: [
        {
          value: DateUtil.convertToDate(
            this.vehicleDialogData?.vehicleDto?.actualOutOfFleetDate
          ),
          disabled: true
        }
      ],
      dayPrice: [
        this.vehicleDialogData?.vehicleDto?.dayPrice,
        Validators.min(0)
      ],
      electricRange: [
        this.vehicleDialogData?.vehicleDto?.electricRange,
        Validators.min(0)
      ],
      expectedInFleetDate: [
        DateUtil.convertToDate(
          this.vehicleDialogData?.vehicleDto?.expectedInFleetDate
        )
      ],
      expectedOutOfFleetDate: [
        DateUtil.convertToDate(
          this.vehicleDialogData?.vehicleDto?.expectedOutOfFleetDate
        )
      ],
      hourPrice: [
        this.vehicleDialogData?.vehicleDto?.hourPrice,
        Validators.min(0)
      ],
      instantBookingPossible: [
        {
          value: this.vehicleDialogData?.vehicleDto?.instantBookingPossible,
          disabled: true
        }
      ],
      kilometerPrice: [
        this.vehicleDialogData?.vehicleDto?.kilometerPrice,
        Validators.min(0)
      ],
      operationalStatus: [
        {
          value: this.vehicleDialogData?.vehicleDto?.operationalStatus,
          disabled: true
        }
      ],
      rentalType: [this.vehicleDialogData?.vehicleDto?.rentalType],
      seats: [this.vehicleDialogData?.vehicleDto?.seats]
    });
    this.detailForm = this.fb.group({
      averageConsumption: [
        this.vehicleDialogData?.vehicleDto?.details?.averageConsumption,
        Validators.min(0)
      ],
      batteryCapacityKWh: [
        this.vehicleDialogData?.vehicleDto?.details?.batteryCapacityKWh,
        Validators.min(0)
      ],
      bodyStyle: [
        this.vehicleDialogData?.vehicleDto?.details?.bodyStyle.enumId,
        Validators.required
      ],
      chargeSpeedAC: [
        this.vehicleDialogData?.vehicleDto?.details?.chargeSpeedAC,
        Validators.min(0)
      ],
      chargeSpeedDC: [
        this.vehicleDialogData?.vehicleDto?.details?.chargeSpeedDC,
        Validators.min(0)
      ],
      doors: [
        this.vehicleDialogData?.vehicleDto?.details?.doors,
        Validators.min(0)
      ],
      exteriorColor: [
        this.vehicleDialogData?.vehicleDto?.details?.exteriorColor.enumId,
        Validators.required
      ],
      luggageCapacity: [
        this.vehicleDialogData?.vehicleDto?.details?.luggageCapacity,
        Validators.min(0)
      ],
      minimumDriverAge: [
        this.vehicleDialogData?.vehicleDto?.details?.minimumDriverAge,
        Validators.min(0)
      ],
      minimumRentalHours: [
        this.vehicleDialogData?.vehicleDto?.details?.minimumRentalHours,
        Validators.min(0)
      ],
      minimumYearsOfDriverLicense: [
        this.vehicleDialogData?.vehicleDto?.details?.minimumYearsOfDriverLicense
      ],
      brand: [
        this.vehicleDialogData?.vehicleDto?.details?.model?.brand,
        Validators.required
      ],
      modelName: [
        this.vehicleDialogData?.vehicleDto?.details?.model?.name,
        Validators.required
      ],
      onboardingTimeMinutes: [
        this.vehicleDialogData?.vehicleDto?.details?.onboardingTimeMinutes,
        Validators.min(0)
      ],
      powerKW: [
        this.vehicleDialogData?.vehicleDto?.details?.powerKW,
        Validators.min(0)
      ],
      preparationTimeMinutes: [
        this.vehicleDialogData?.vehicleDto?.details?.preparationTimeMinutes,
        Validators.min(0)
      ],
      rimSize: [
        this.vehicleDialogData?.vehicleDto?.details?.rimSize,
        Validators.min(0)
      ]
    });
    this.telematicsForm = this.fb.group({
      providerDeviceId: ['', Validators.required],
      providerId: ['', Validators.required],
      maxRange: [200, Validators.min(0)]
    });

    if (
      !!this.vehicleDialogData?.vehicleDto &&
      !!this.vehicleDialogData.vehicleId
    ) {
      this.sharedUiSandbox
        .getBatteryStatus(this.vehicleDialogData?.vehicleDto.id)
        .pipe(take(1))
        .subscribe(
          batteryStatusDto => {
            this.batteryStatus = ConvertersUtils.convertBatteryStatusDtoToBatteryStatusWithDetail(
              batteryStatusDto,
              false
            );
          },
          error => {
            const batteryStatusDto: BatteryStatusDto = {
              batteryPercentage: 0,
              charging: ChargingStatusDto.UNKNOWN,
              cruisingRange: 0
            };
            this.batteryStatus = ConvertersUtils.convertBatteryStatusDtoToBatteryStatusWithDetail(
              batteryStatusDto,
              true
            );
          }
        );
    }

    this.googleHelper.googleApiEnabled$
      .pipe(takeUntilDestroy(this))
      .subscribe(googleApiEnabled => {
        this.isGoogleApiEnabledSub$.next(!!googleApiEnabled);
      });
  }

  compareDamages(a: DamageDto, b: DamageDto): number {
    return a?.creationDate.localeCompare(b?.creationDate);
  }

  getInitHomeLocation(): GpsLocationDto {
    const homeLocation: GpsLocationDto = {
      address: '',
      gpsCoordinateDto: null
    };
    if (!!this.vehicleDialogData?.vehicleDto?.address) {
      homeLocation.address = this.vehicleDialogData?.vehicleDto.address;
    }
    if (!!this.vehicleDialogData?.vehicleDto?.homePosition) {
      homeLocation.gpsCoordinateDto = this.vehicleDialogData?.vehicleDto.homePosition;
    }
    return homeLocation;
  }

  getCreateRequestData(): CreateVehicleRequestDto {
    const request: CreateVehicleRequestDto = {
      name: this.generalForm.value.name,
      licensePlate: this.generalForm.value.licensePlate,
      organizationId: this.generalForm.value.organization,
      owner: this.generalForm.value.owner,
      description: this.generalForm.value.description,
      timeZone: this.generalForm.value.timeZone,
      equipmentIds: this.generalForm.value.equipmentIds,
      alwaysAvailable: this.usageForm.value.alwaysAvailable,
      automaticBookingApproval: this.usageForm.value.automaticBookingApproval,
      dayPrice: this.usageForm.value.dayPrice,
      electricRange: this.usageForm.value.electricRange,
      expectedInFleetDate: DateUtil.convertToString(
        this.usageForm.value.expectedInFleetDate
      ),
      expectedOutOfFleetDate: DateUtil.convertToString(
        this.usageForm.value.expectedOutOfFleetDate
      ),
      hourPrice: this.usageForm.value.hourPrice,
      intentIds: this.selectedIntents,
      kilometerPrice: this.usageForm.value.kilometerPrice,
      // lastAddress?: string;
      // lastPosition?: GpsCoordinateDto;
      // lastPositionTimestamp?: string;
      permissions: {
        abroadAllowed: this.abroadAllowed,
        petsAllowed: this.petsAllowed,
        kidsAllowed: this.kidsAllowed
      },
      rentalType: this.usageForm.value.rentalType,
      seats: this.usageForm.value.seats,
      details: this.getVehicleDetailsData()
    };

    if (this.isGoogleApiEnabledSub$.value) {
      request.address = this.homeLocationSub$.value.address;
      request.homePosition = {
        latitude: this.homeLocationSub$.value.gpsCoordinateDto.latitude,
        longitude: this.homeLocationSub$.value.gpsCoordinateDto.longitude
      };
    } else {
      request.address = this.generalForm.value.address;
      request.homePosition = {
        latitude: this.generalForm.value.latitude ?? 0,
        longitude: this.generalForm.value.longitude ?? 0
      };
    }

    return request;
  }

  getVehicleDetailsData(): VehicleDetailsDto {
    const detail: VehicleDetailsDto = {
      averageConsumption: this.detailForm.value.averageConsumption,
      batteryCapacityKWh: this.detailForm.value.batteryCapacityKWh,
      bodyStyle: {
        enumId: this.detailForm.value.bodyStyle
      },
      chargeSpeedAC: this.detailForm.value.chargeSpeedAC,
      chargeSpeedDC: this.detailForm.value.chargeSpeedDC,
      doors: this.detailForm.value.doors,
      exteriorColor: {
        enumId: this.detailForm.value.exteriorColor
      },
      luggageCapacity: this.detailForm.value.luggageCapacity,
      minimumDriverAge: this.detailForm.value.minimumDriverAge,
      minimumRentalHours: this.detailForm.value.minimumRentalHours,
      minimumYearsOfDriverLicense: this.detailForm.value
        .minimumYearsOfDriverLicense,
      model: {
        brand: this.detailForm.value.brand,
        name: this.detailForm.value.modelName
      },
      onboardingTimeMinutes: this.detailForm.value.onboardingTimeMinutes,
      powerKW: this.detailForm.value.powerKW,
      preparationTimeMinutes: this.detailForm.value.preparationTimeMinutes,
      rimSize: this.detailForm.value.rimSize
    };
    return detail;
  }

  uploadImage(vehicleDto: VehicleDto): Observable<VehicleDto> {
    return !!this.file
      ? this.sharedUiSandbox.uploadVehicleImage(vehicleDto.id, this.file).pipe(
          take(1),
          catchError(response =>
            throwError({
              response,
              message: this.tc + '.FAILED_UPLOAD-VEHICLE-IMAGE'
            })
          )
        )
      : of(vehicleDto);
  }

  onSaveClick(): void {
    /* This check is disabled for now - BATT-522
    if (!this.homeLocationSub$.value.gpsCoordinateDto) {
      this.toastUtilService.error(this.tc + '.HOME-LOCATION-IS-REQUIRED', true);
      return;
    }
 */
    if (!this.generalForm.valid) {
      return;
    }
    if (!this.usageForm.valid) {
      return;
    }
    if (!this.detailForm.valid) {
      return;
    }
    if (!!this.vehicleDialogData?.vehicleId) {
      // For now we can use the same method to initialize the request (same data can be updated in create/update service)
      const request: UpdateVehicleRequestDto = this.getCreateRequestData();
      this.sharedUiSandbox
        .updateVehicle(this.vehicleDialogData?.vehicleId, request)
        .pipe(
          take(1),
          catchError(response =>
            throwError({
              response,
              message: this.tc + '.FAILED_UPDATE-VEHICLE'
            })
          ),
          mergeMap(updateVehicleDto => this.uploadImage(updateVehicleDto))
        )
        .subscribe(
          vehicleDto => {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_UPDATE-VEHICLE',
              true
            );
            this.closeDialogAfterSave.emit(vehicleDto);
          },
          (error: { message: string; response: HttpErrorResponse }) => {
            this.toastUtilService.showError(error.response, error.message);
          }
        );
    } else {
      const request: CreateVehicleRequestDto = this.getCreateRequestData();
      this.sharedUiSandbox
        .createVehicle(request)
        .pipe(
          take(1),
          catchError(response =>
            throwError({
              response,
              message: this.tc + '.FAILED_CREATE-VEHICLE'
            })
          ),
          mergeMap(createVehicleDto => this.uploadImage(createVehicleDto))
        )
        .subscribe(vehicleDto => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_CREATE-VEHICLE',
            true
          );
          this.closeDialogAfterSave.emit(vehicleDto);
        });
    }
  }

  onVehicleImageChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: Array<File> = Array.from(target.files);

    // Needed to be able to detect a change if the user select the same file again (can happen for instance if first time attachment fails)
    target.value = null;

    if (files.length < 1) {
      return;
    }

    // Update the trustedUrl (user will be able to see image change on screen)
    // The upload itself is managed on save action
    const url = this.windowRef.nativeWindow.URL.createObjectURL(files[0]);
    this.trustedUrl = {
      url,
      safeUrl: this.domSanitizer.bypassSecurityTrustUrl(url)
    };
    this.file = files[0];
  }

  vehicleChangeLockStatus(lock: boolean): void {
    this.sharedUiSandbox
      .changeLockStatus(this.vehicleDialogData?.vehicleId, lock)
      .pipe(take(1))
      .subscribe(
        response => {
          this.toastUtilService.success(
            this.tc +
              (lock
                ? '.SUCCESSFUL_LOCK-VEHICLE'
                : '.SUCCESSFUL_UNLOCK-VEHICLE'),
            true
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + (lock ? '.FAILED_LOCK-VEHICLE' : '.FAILED_UNLOCK-VEHICLE')
          );
        }
      );
  }

  updateActiveTab(activeTab: Tab): void {
    this.activeTab = activeTab;
    if (
      !!this.vehicleDialogData?.vehicleId &&
      this.activeTab === this.telematicsTab
    ) {
      // Telematics
      this.triggerFetchTelematicsSub$.next(true);
    } else if (
      !!this.vehicleDialogData?.vehicleId &&
      this.activeTab === this.damagesTab
    ) {
      // Damages
      this.triggerFetchDamagesSub$.next(true);
    }
  }

  saveTelematics(): void {
    if (!this.telematicsForm.valid) {
      return;
    }
    if (this.hasTelematics) {
      this.updateTelematics();
    } else {
      this.createTelematics();
    }
  }

  createTelematics(): void {
    this.sharedUiSandbox
      .createTelematics(
        this.vehicleDialogData?.vehicleId,
        this.telematicsForm.value.providerDeviceId,
        this.telematicsForm.value.providerId,
        this.telematicsForm.value.maxRange
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.hasTelematics = true;
          this.isEditingTelematicsSub$.next(false);
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_CREATE-TELEMATICS',
            true
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_CREATE-TELEMATICS'
          );
        }
      );
  }

  updateTelematics(): void {
    this.sharedUiSandbox
      .updateTelematics(
        this.vehicleDialogData?.vehicleId,
        this.telematicsForm.value.providerDeviceId,
        this.telematicsForm.value.providerId,
        this.telematicsForm.value.maxRange
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.isEditingTelematicsSub$.next(false);
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_UPDATE-TELEMATICS',
            true
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_UPDATE-TELEMATICS'
          );
        }
      );
  }

  updateTelematicsDisableState(
    isReadonly: boolean,
    isEditingTelematics: boolean
  ): void {
    if (isReadonly || !isEditingTelematics) {
      this.telematicsForm.disable();
    } else {
      this.telematicsForm.enable();
    }
    // Disable/Enable other tabs
    if (!!this.sofTabs?.tabComponents) {
      this.sofTabs.tabComponents.forEach(tab => {
        if (tab?.tab?.id !== this.TAB_ID_TELEMATICS) {
          if (isEditingTelematics) {
            this.renderer.addClass(tab.elementRef.nativeElement, 'disabled');
          } else {
            this.renderer.removeClass(tab.elementRef.nativeElement, 'disabled');
          }
        }
      });
    }
  }

  updateDisableState(isReadonly: boolean): void {
    if (isReadonly) {
      this.generalForm.disable();
      this.usageForm.disable();
      this.detailForm.disable();
    } else {
      this.generalForm.enable();
      this.usageForm.enable();
      this.detailForm.enable();
      // Always disabled
      this.usageForm.controls.actualInFleetDate.disable();
      this.usageForm.controls.actualOutOfFleetDate.disable();
      this.usageForm.controls.operationalStatus.disable();
      this.usageForm.controls.instantBookingPossible.disable();
    }
  }

  onDamageStatusChange(damageStatus: DamageStatus): void {
    this.sharedUiSandbox
      .changeDamageStatus(
        damageStatus.damageId,
        this.vehicleDialogData?.vehicleId,
        damageStatus.fixed ? DamageStatusDto.FIXED : DamageStatusDto.CREATED
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.triggerFetchDamagesSub$.next(true);
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_UPDATE-DAMAGE-STATUS',
            true
          );
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_UPDATE-DAMAGE-STATUS'
          );
        }
      );
  }

  // TODO - Temporary solution - To be removed when user search and Dtos will be updated
  onChangeUser(userId: string): void {
    if (!!userId) {
      this.updateUserData(userId, true);
    } else {
      this.userOrganizationsSub$.next(null);
    }
  }

  updateUserData(userId: string, setOrganizationId: boolean): void {
    this.setOrganizationId = setOrganizationId;
    this.userIdSub$.next(userId);
  }

  private getBookingUser$(): Observable<BookingUserDto> {
    return this.userIdSub$.pipe(
      filter(userId => !!userId),
      switchMap(userId => this.sharedUiSandbox.getBookingUser(userId)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
}
