import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  ConflictResolutionDto,
  ConflictResolutionModeDto,
  VehicleEventTypeDto
} from '../../../../../client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AvailabilityConflictResolutionDialogData } from '../../../types/availability-conflict-resolution-dialog-data.type';

@Component({
  selector: 'sof-availability-conflict-resolution-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.CONFLICT-RESOLUTION-DIALOG-TITLE' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill dialog-content">
          <sof-form
            [tc]="tc"
            [formGroup]="availabilityForm"
            class="full-height"
          >
            <div class="row">
              <div class="col-sm">
                <label>{{
                  tc + '.CONFLICT-RESOLUTION-INFORMATION-TEXT' | translate
                }}</label>
                <div class="choices-container">
                  <sof-input-radio
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.mode"
                    [value]="MODE_SPLIT"
                  >
                    {{ tc + '.MODE-SPLIT' | translate }}
                  </sof-input-radio>
                  <sof-input-radio
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.mode"
                    [value]="MODE_CANCEL"
                  >
                    {{ tc + '.MODE-CANCEL' | translate }}
                  </sof-input-radio>
                </div>
              </div>
            </div>
          </sof-form>
          <div
            class="row"
            *ngIf="
              availabilityForm.value.mode === MODE_SPLIT ||
              availabilityForm.value.mode === MODE_CANCEL
            "
          >
            <div class="col-sm">
              <label>{{ tc + '.PREVIEW' | translate }}</label>
            </div>
          </div>
          <div
            class="periods-list"
            *ngIf="availabilityForm.value.mode === MODE_SPLIT"
          >
            <div
              class="sof-card no-hover"
              *ngFor="let period of dialogData.conflictDetails.preview"
              [ngClass]="{
                booking:
                  period?.vehicleEvent?.type2 === VEHICLE_EVENT_TYPE_BOOKING
              }"
            >
              <div class="start-date-container">
                <div class="start-date-day-name">
                  {{ period.period.start | toFormattedDate: 'ddd' }}
                </div>
                <div class="start-date-day-number">
                  {{ period.period.start | toFormattedDate: 'D' }}
                </div>
              </div>
              <div class="period-container">
                <div
                  *ngIf="
                    period.vehicleEvent?.type2 !== VEHICLE_EVENT_TYPE_BOOKING
                  "
                  class="period-label"
                >
                  {{ tc + '.BLOCKED' | translate }}
                </div>
                <div
                  *ngIf="
                    period.vehicleEvent?.type2 === VEHICLE_EVENT_TYPE_BOOKING
                  "
                  class="period-label"
                >
                  {{
                    tc + '.BOOKING-BY'
                      | translate
                        : { userName: period.vehicleEvent.bookingUserName }
                  }}
                </div>
                <div class="period-dates">
                  {{ period.period.start | toFormattedDate: 'HH:mm' }} -
                  {{ period.period.end | toFormattedDate: 'HH:mm' }} ({{
                    period.period.end | toFormattedDate: 'DD.MM'
                  }})
                </div>
              </div>
            </div>
          </div>
          <div
            class="periods-list"
            *ngIf="availabilityForm.value.mode === MODE_CANCEL"
          >
            <div
              class="sof-card no-hover"
              *ngFor="let period of dialogData.conflictDetails.availabilities"
            >
              <div class="start-date-container">
                <div class="start-date-day-name">
                  {{ period.period.start | toFormattedDate: 'ddd' }}
                </div>
                <div class="start-date-day-number">
                  {{ period.period.start | toFormattedDate: 'D' }}
                </div>
              </div>
              <div class="period-container">
                <div class="period-label">
                  {{ tc + '.BLOCKED' | translate }}
                </div>
                <div class="period-dates">
                  {{ period.period.start | toFormattedDate: 'HH:mm' }} -
                  {{ period.period.end | toFormattedDate: 'HH:mm' }} ({{
                    period.period.end | toFormattedDate: 'DD.MM'
                  }})
                </div>
              </div>
            </div>
          </div>
          <div
            class="row cancelled-bookings-label"
            *ngIf="availabilityForm.value.mode === MODE_CANCEL"
          >
            <div class="col-sm">
              <label>{{ tc + '.FOLLOWING-CANCELLED' | translate }}</label>
            </div>
          </div>
          <div
            class="periods-list"
            *ngIf="availabilityForm.value.mode === MODE_CANCEL"
          >
            <div
              *ngFor="let vehicleEvent of dialogData.conflictDetails.cancelled"
              class="sof-card no-hover cancelled"
            >
              <div class="start-date-container">
                <div class="start-date-day-name">
                  {{ vehicleEvent.period.start | toFormattedDate: 'ddd' }}
                </div>
                <div class="start-date-day-number">
                  {{ vehicleEvent.period.start | toFormattedDate: 'D' }}
                </div>
              </div>
              <div class="period-container">
                <div class="period-label">
                  {{
                    tc + '.BOOKING-BY'
                      | translate: { userName: vehicleEvent.bookingUserName }
                  }}
                </div>
                <div class="period-dates">
                  {{ vehicleEvent.period.start | toFormattedDate: 'HH:mm' }} -
                  {{ vehicleEvent.period.end | toFormattedDate: 'HH:mm' }} ({{
                    vehicleEvent.period.end | toFormattedDate: 'DD.MM'
                  }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveAvailabilityClick()"
            class="general-action-button main-action"
            [disabled]="!availabilityForm.value.mode"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
    <sof-text-area-dialog
      *ngIf="showTextAreaDialog"
      [tc]="tc"
      titleLabel="SEND-CANCELLATION-DIALOG-TITLE"
      textLabel="SEND_CANCELLATION_DIALOG_TEXT"
      textAreaLabel="SEND_CANCELLATION_DIALOG_TEXTAREA_LABEL"
      textAreaPlaceHolder="SEND_CANCELLATION_DIALOG_TEXTAREA_PLACEHOLDER"
      confirmLabel="DIALOG-SEND"
      (cancelDialog)="showTextAreaDialog = false"
      (confirmDialog)="onConfirmReason($event)"
    ></sof-text-area-dialog>
  `,
  styleUrls: ['./availability-conflict-resolution-dialog.component.scss']
})
export class AvailabilityConflictResolutionDialogComponent {
  @Input() tc: string;
  @Input() dialogData: AvailabilityConflictResolutionDialogData;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  saveDialog: EventEmitter<ConflictResolutionDto> = new EventEmitter<ConflictResolutionDto>();

  showTextAreaDialog = false;
  conflictResolutionDto: ConflictResolutionDto;

  MODE_SPLIT = ConflictResolutionModeDto.SPLIT;
  MODE_CANCEL = ConflictResolutionModeDto.CANCELBOOKINGS;

  VEHICLE_EVENT_TYPE_BOOKING = VehicleEventTypeDto.BOOKING;

  availabilityForm: FormGroup = this.fb.group({
    mode: [null, Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  onConfirmReason(reason: string): void {
    this.showTextAreaDialog = false;
    this.conflictResolutionDto = {
      ...this.conflictResolutionDto,
      cancelReason: reason
    };
    this.saveDialog.emit(this.conflictResolutionDto);
  }

  onSaveAvailabilityClick(): void {
    this.conflictResolutionDto = {
      conflictsHash: this.dialogData.conflictDetails.crudAvailabilityResponse
        .conflictsHash,
      mode: this.availabilityForm.value.mode
    };
    if (
      this.availabilityForm.value.mode ===
      ConflictResolutionModeDto.CANCELBOOKINGS
    ) {
      this.showTextAreaDialog = true;
    } else {
      this.saveDialog.emit(this.conflictResolutionDto);
    }
  }
}
