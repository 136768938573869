import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';

@Component({
  selector: 'sof-booking-scheduler-header-dates',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="header-row">
      <div
        *ngFor="let column of columns; trackBy: trackByIndex"
        class="header-cell"
      >
        {{ column.formattedDate }}
      </div>
    </div>

    <div class="header-row">
      <div
        *ngFor="let subColumn of subColumns; trackBy: trackByIndex"
        class="header-cell"
      >
        {{ subColumn.formattedDate }}
      </div>
    </div>
  `,
  styleUrls: ['./booking-scheduler-header-dates.component.scss']
})
export class BookingSchedulerHeaderDatesComponent {
  @Input() columns: BookingSchedulerDate[];
  @Input() subColumns: BookingSchedulerDate[];

  trackByIndex = i => i;
}
