import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  DamageDto,
  DamageStatusDto
} from '../../../../../client';
import { DamageStatus } from '../../../types/damage-status.type';

@Component({
  selector: 'sof-vehicle-dialog-damages-tab-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="toggle-damage-status-container">
      <div class="sof-toggle-button-group">
        <sof-input-radio
          [checked]="shownGroupStatus === DAMAGE_STATUS_GROUP_PENDING"
          [value]="DAMAGE_STATUS_GROUP_PENDING"
          (changeValue)="shownGroupStatusChange.emit($event)"
        >
          {{ tc + '.DAMAGE-STATUS-GROUP-PENDING' | translate }}
        </sof-input-radio>
        <sof-input-radio
          [checked]="shownGroupStatus === DAMAGE_STATUS_GROUP_ALL"
          [value]="DAMAGE_STATUS_GROUP_ALL"
          (changeValue)="shownGroupStatusChange.emit($event)"
        >
          {{ tc + '.DAMAGE-STATUS-GROUP-ALL' | translate }}
        </sof-input-radio>
      </div>
    </div>
    <div class="damages-card-container">
      <sof-no-result
        *ngIf="damages?.length === 0"
        [tc]="tc"
        noResultLabel="NO-DAMAGE"
      >
      </sof-no-result>
      <div *ngFor="let damage of damages" class="sof-card damage-card">
        <div class="date">
          {{ damage.creationDate | toFormattedDate: 'DD/MM/YYYY HH:mm:ss' }}
        </div>
        <div class="user-description-container">
          <div class="user">
            {{
              tc + '.REGISTERED_BY'
                | translate: { userName: damage.user?.displayName }
            }}
          </div>
          <div class="description">
            {{ damage.description }}
          </div>
        </div>
        <div
          class="status"
          [class.fixed]="damage.status === DAMAGE_STATUS_FIXED"
        >
          <sof-svg-icon icon="batt-icon-car" size="32"></sof-svg-icon>
        </div>
        <div class="image-container">
          <img
            *ngIf="!!damage.imageUrl"
            src="{{ imageBasePath }}{{ damage.imageUrl }}"
            alt="{{ damage.vehicle.id }}"
          />
        </div>
        <div class="action-container">
          <div>{{ tc + '.CHANGE-STATUS-FIXED' | translate }}</div>
          <sof-input-switch
            [isDisabled]="isReadonly"
            [selected]="damage.status === DAMAGE_STATUS_FIXED"
            (changeValue)="onDamageStatusChange(damage.id, $event)"
          ></sof-input-switch>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogDamagesTabContentComponent {
  @Input() tc: string;
  @Input() isReadonly: boolean;
  @Input() shownGroupStatus: string;
  @Input() damages: Array<DamageDto>;
  @Input() imageBasePath: string;

  @Output()
  shownGroupStatusChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  damageStatusChange: EventEmitter<DamageStatus> = new EventEmitter<DamageStatus>();

  DAMAGE_STATUS_CREATED = DamageStatusDto.CREATED;
  DAMAGE_STATUS_FIXED = DamageStatusDto.FIXED;
  DAMAGE_STATUS_GROUP_PENDING = 'PENDING';
  DAMAGE_STATUS_GROUP_ALL = 'ALL';

  onDamageStatusChange(damageId: string, fixed: boolean): void {
    const damageStatus: DamageStatus = {
      damageId,
      fixed
    };
    this.damageStatusChange.emit(damageStatus);
  }
}
