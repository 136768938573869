import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { SettingService } from '../services/setting.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastUtilService } from '../services/toast-util.service';
import GuardUtil from '../utils/guard.util';

@Injectable()
export class DamagesAuthorizationGuard implements CanActivate {
  tc = 'DAMAGES-AUTHORIZATION-GUARD';
  constructor(
    private settingService: SettingService,
    private toastUtilService: ToastUtilService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return GuardUtil.canActivate(
      route,
      state,
      this.settingService,
      this.toastUtilService,
      GuardUtil.FEATURE_DAMAGES,
      this.tc
    );
  }
}
