import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingUserDto } from '../../../../../../client';
import { DropDownConfigAlt } from '../../../../shared-ui/components/drop-down-alt/types/drop-down-config-alt.type';
import { DropDownMenuItemAlt } from '../../../../shared-ui/components/drop-down-menu-alt/types/drop-down-menu-item-alt.type';
import { TooltipContentUnavailability } from '../../../../types/tooltip.type';
import {
  DeleteTooltipEvent,
  TooltipEvent
} from '../../../../classes/tooltip-events.class';
import { UnavailabilityEventWithStyle } from '../../../../types/unavailability-event-with-style.type';
import { UnavailabilityType } from '../../../../types/unavailability-event.type';

@Component({
  selector: 'sof-tooltip-content-unavailability',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tooltip-content-unavailability.component.scss'],
  template: `
    <div class="header">
      <div class="title">
        {{ tc + '.UNAVAILABILITY-INFORMATION' | translate }}
      </div>
      <button
        sofButton
        type="button"
        class="icon-button"
        (click)="onCloseClick()"
      >
        <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
      </button>
    </div>
    <div class="tooltip-list">
      <sof-divider
        size="thin"
        *ngIf="!!content.unavailabilityEvent?.data?.type2"
      ></sof-divider>
      <div
        class="tooltip-list-item label-value-container"
        *ngIf="!!content.unavailabilityEvent?.data?.type2"
      >
        <div class="label title-text">
          {{ tc + '.UNAVAILABILITY-INFORMATION-TYPE' | translate }}
        </div>
        <div class="value">
          {{
            tc +
              '.NON_AVAILABILITY_TYPE_' +
              content.unavailabilityEvent?.data?.type2 | translate
          }}
        </div>
      </div>
      <sof-divider
        size="thin"
        *ngIf="
          content.unavailabilityEvent?.type ===
          UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE
        "
      >
        ></sof-divider
      >
      <div
        class="tooltip-list-item label-value-container"
        *ngIf="
          content.unavailabilityEvent?.type ===
          UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE
        "
      >
        <div class="label title-text private-usage-overdue">
          {{
            tc + '.UNAVAILABILITY-INFORMATION-PRIVATE-USAGE-OVERDUE' | translate
          }}
        </div>
      </div>
      <sof-divider
        size="thin"
        *ngIf="
          !!content.unavailabilityEvent?.data?.availability
            ?.recurringAvailabilityId
        "
      ></sof-divider>
      <div
        class="tooltip-list-item label-value-container"
        *ngIf="
          !!content.unavailabilityEvent?.data?.availability
            ?.recurringAvailabilityId
        "
      >
        <div class="label title-text">
          {{ tc + '.UNAVAILABILITY-INFORMATION-RECURRING-EVENT' | translate }}
        </div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item column time-container">
        <div class="tooltip-line time-title">
          {{ tc + '.UNAVAILABILITY-INFORMATION-TIME' | translate }}
        </div>
        <div class="tooltip-line time container">
          <div class="row">
            <div class="col-sm">
              <div class="label">
                {{ tc + '.UNAVAILABILITY-INFORMATION-FROM' | translate }}
              </div>
              <sof-date-hour
                [date]="content?.unavailabilityEvent.fromDate"
              ></sof-date-hour>
            </div>
            <div class="col-sm">
              <div class="label">
                {{ tc + '.UNAVAILABILITY-INFORMATION-TO' | translate }}
              </div>
              <sof-date-hour
                [date]="content?.unavailabilityEvent.toDate"
              ></sof-date-hour>
            </div>
          </div>
        </div>
      </div>
      <sof-divider
        size="thin"
        *ngIf="
          !!content.unavailabilityEvent?.data?.availability ||
          (content.unavailabilityEvent?.type ===
            UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE && currentUser | isAdmin)
        "
      ></sof-divider>
      <div
        class="tooltip-list-item edit-container"
        *ngIf="
          !!content.unavailabilityEvent?.data?.availability ||
          (content.unavailabilityEvent?.type ===
            UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE && currentUser | isAdmin)
        "
      >
        <button
          *ngIf="!!content.unavailabilityEvent?.data?.availability"
          sofButton
          class="general-action-button primary"
          (click)="onEditClick()"
        >
          {{ tc + '.EDIT' | translate }}
        </button>
        <sof-drop-down-menu-alt
          *ngIf="
            content.unavailabilityEvent?.type ===
              UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE && currentUser | isAdmin
          "
          [tc]="tc"
          [dropDownConfig]="dropDownConfig$ | async"
          [menuItems]="menuItems"
        >
        </sof-drop-down-menu-alt>
      </div>
    </div>
  `
})
export class TooltipContentUnavailabilityComponent implements OnInit {
  @Input() content: TooltipContentUnavailability;
  @Input() tooltipId: string;
  @Input() tc: string;
  @Input() currentUser: BookingUserDto;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  editUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  endVehicleUsage: EventEmitter<string> = new EventEmitter<string>();

  UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE =
    UnavailabilityType.PRIVATE_USAGE_OVERDUE;

  dropDownConfig$: Observable<DropDownConfigAlt>;
  // For now it contains only end private usage action and *ngIf on the sof-drop-down-menu is enough
  // If an action is added a stream based on canCancel$ will be needed
  // Button menu is displayed in an overlay, in order to not consider the menu item click as a click outside
  // the class name 'click-outside-tooltip-exception' is used as a flag
  menuItems: DropDownMenuItemAlt[] = [
    {
      label: 'END-PRIVATE-USAGE',
      classes: 'click-outside-tooltip-exception',
      click: () => {
        this.endVehicleUsage.emit(
          this.content.unavailabilityEvent.remoteVehicleId
        );
      }
    }
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.dropDownConfig$ = this.getDropDownConfig$();
  }

  private getDropDownConfig$(): Observable<DropDownConfigAlt> {
    return this.translateService.stream(this.tc + '.ACTIONS').pipe(
      map(translation => {
        return {
          toggleButtonClasses: 'general-action-button primary',
          toggleButtonText: translation
        };
      })
    );
  }

  onCloseClick(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onEditClick(): void {
    this.editUnavailability.emit(this.content.unavailabilityEvent);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }
}
