import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  BookingUserDto,
  UpdateUserRequestDto,
  UserDto,
  UserPageDto
} from '../../../client';
import { UserService } from '../services/user.service';

@Injectable()
export class UserManagementSandbox {
  constructor(private userService: UserService) {}

  fetchUsers(): Observable<UserPageDto> {
    return this.userService.fetchUsers();
  }

  getCurrentUser(): Observable<BookingUserDto> {
    return this.userService.getCurrentUser();
  }

  getUserByUsername(username: string): Observable<UserDto> {
    return this.userService.getUserByUsername(username).pipe(take(1));
  }

  createUser(userDto: UserDto): Observable<UserDto> {
    return this.userService.createUser(userDto).pipe(take(1));
  }

  updateUser(
    userId: string,
    updateUserRequestDto: UpdateUserRequestDto
  ): Observable<UserDto> {
    return this.userService
      .updateUser(userId, updateUserRequestDto)
      .pipe(take(1));
  }

  deleteUser(userId: string): Observable<any> {
    return this.userService.deleteUser(userId).pipe(take(1));
  }

  sendOnboardingMail(userId: string): Observable<any> {
    return this.userService.sendOnboardingMail(userId).pipe(take(1));
  }
}
