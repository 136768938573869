import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SettingService } from '../services/setting.service';
import { ToastUtilService } from '../services/toast-util.service';

export default class GuardUtil {
  public static FEATURE_BOOKINGS = 'bookings';
  public static FEATURE_CHAT = 'chat';
  public static FEATURE_DAMAGES = 'damages';
  public static FEATURE_ORGANIZATIONS = 'organizations';
  public static FEATURE_SCHEDULER = 'scheduler';
  public static FEATURE_USERS = 'users';
  public static FEATURE_VEHICLES = 'vehicles';

  public static canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    settingService: SettingService,
    toastUtilService: ToastUtilService,
    feature: string,
    tc: string
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return settingService.featureToggles$.pipe(
      catchError(() => of({})),
      tap(featureToggles => {
        if (featureToggles[feature] !== true) {
          toastUtilService.error(tc + '.ACCESS-DENIED', true);
        }
      }),
      map(featureToggles => featureToggles[feature] === true),
      take(1)
    );
  }
}
