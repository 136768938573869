import { Pipe, PipeTransform } from '@angular/core';
import { EnumValueDto } from '../../../../client';
import SharedUiUtils from '../utils/shared-ui.utils';

@Pipe({
  name: 'enumDescription'
})
export class EnumDescriptionPipe implements PipeTransform {
  transform(enumValueDto: EnumValueDto): string {
    return SharedUiUtils.getEnumDescription(enumValueDto);
  }
}
