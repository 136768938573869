import { CarNoReservation } from '../../types/car-no-reservation.type';
import { CarsNoReservationActions } from '../actions/cars-no-reservation.actions';
import { ActionTypes } from '../action-types';

export function carsNoReservationReducer(
  state: CarNoReservation[] = [],
  action: CarsNoReservationActions
): CarNoReservation[] {
  switch (action.type) {
    case ActionTypes.SET_CARS_NO_RESERVATION:
      return [...action.payload.carsNoReservation];
  }
  return state;
}
