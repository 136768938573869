import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { OrganizationsAuthorizationGuard } from '../guards/organizations-authorization.guard';
import { CreateDriverGradeDialogViewComponent } from './views/create-driver-grade-dialog-view/create-driver-grade-dialog-view.component';
import { CreateOrganizationDialogViewComponent } from './views/create-organization-dialog-view/create-organization-dialog-view.component';
import { CreateVehicleGroupDialogViewComponent } from './views/create-vehicle-group-dialog-view/create-vehicle-group-dialog-view.component';
import { DriverGradeFilterDialogViewComponent } from './views/driver-grade-filter-dialog-view/driver-grade-filter-dialog-view.component';
import { EditDriverGradeDialogViewComponent } from './views/edit-driver-grade-dialog-view/edit-driver-grade-dialog-view.component';
import { EditOrganizationDialogViewComponent } from './views/edit-organization-dialog-view/edit-organization-dialog-view.component';
import { EditVehicleGroupDialogViewComponent } from './views/edit-vehicle-group-dialog-view/edit-vehicle-group-dialog-view.component';
import { InviteMemberDialogDriverGradeStepViewComponent } from './views/invite-member-dialog-driver-grade-step-view/invite-member-dialog-driver-grade-step-view.component';
import { InviteMemberDialogEmailAddressStepViewComponent } from './views/invite-member-dialog-email-address-step-view/invite-member-dialog-email-address-step-view.component';
import { InviteMemberDialogUserDataStepViewComponent } from './views/invite-member-dialog-user-data-step-view/invite-member-dialog-user-data-step-view.component';
import { InviteMemberDialogViewComponent } from './views/invite-member-dialog-view/invite-member-dialog-view.component';
import { MemberAddDriverGradeDialogViewComponent } from './views/member-add-driver-grade-dialog-view/member-add-driver-grade-dialog-view.component';
import { MemberViewComponent } from './views/member-view/member-view.component';
import { OrganizationDetailViewComponent } from './views/organization-detail-view/organization-detail-view.component';
import { OrganizationDriverGradeDetailViewComponent } from './views/organization-driver-grade-detail-view/organization-driver-grade-detail-view.component';
import { OrganizationDriverGradeListViewComponent } from './views/organization-driver-grade-list-view/organization-driver-grade-list-view.component';
import { OrganizationManagerListViewComponent } from './views/organization-manager-list-view/organization-manager-list-view.component';
import { OrganizationMemberDetailViewComponent } from './views/organization-member-detail-view/organization-member-detail-view.component';
import { OrganizationMemberListViewComponent } from './views/organization-member-list-view/organization-member-list-view.component';
import { OrganizationVehicleGroupDetailViewComponent } from './views/organization-vehicle-group-detail-view/organization-vehicle-group-detail-view.component';
import { OrganizationVehicleGroupListViewComponent } from './views/organization-vehicle-group-list-view/organization-vehicle-group-list-view.component';
import { OrganizationsViewComponent } from './views/organizations-view/organizations-view.component';
import { VehicleGroupEditVehiclesDialogViewComponent } from './views/vehicle-group-edit-vehicles-dialog-view/vehicle-group-edit-vehicles-dialog-view.component';

const routes: Routes = [
  {
    path: '',
    component: OrganizationsViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: 'create-organization',
        component: CreateOrganizationDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      }
    ]
  },
  {
    path: 'members',
    component: MemberViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
  },
  {
    path: ':organizationId',
    component: OrganizationDetailViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: '',
        redirectTo: 'members',
        pathMatch: 'full'
      },
      {
        path: 'members',
        component: OrganizationMemberListViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
        children: [
          {
            path: 'edit-organization',
            component: EditOrganizationDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          },
          {
            path: 'invite-member',
            component: InviteMemberDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
            children: [
              {
                path: 'email-address-step',
                component: InviteMemberDialogEmailAddressStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              },
              {
                path: 'user-data-step',
                component: InviteMemberDialogUserDataStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              },
              {
                path: 'driver-grade-step',
                component: InviteMemberDialogDriverGradeStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'managers',
        component: OrganizationManagerListViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
        children: [
          {
            path: 'edit-organization',
            component: EditOrganizationDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          },
          {
            path: 'invite-member',
            component: InviteMemberDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
            children: [
              {
                path: 'email-address-step',
                component: InviteMemberDialogEmailAddressStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              },
              {
                path: 'user-data-step',
                component: InviteMemberDialogUserDataStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              },
              {
                path: 'driver-grade-step',
                component: InviteMemberDialogDriverGradeStepViewComponent,
                canActivate: [
                  AuthenticatedGuard,
                  OrganizationsAuthorizationGuard
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'vehicle-groups',
        component: OrganizationVehicleGroupListViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
        children: [
          {
            path: 'edit-organization',
            component: EditOrganizationDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          },
          {
            path: 'create-vehicle-group',
            component: CreateVehicleGroupDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          }
        ]
      },
      {
        path: 'driver-grades',
        component: OrganizationDriverGradeListViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
        children: [
          {
            path: 'edit-organization',
            component: EditOrganizationDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          },
          {
            path: 'create-driver-grade',
            component: CreateDriverGradeDialogViewComponent,
            canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
          }
        ]
      }
    ]
  },
  {
    path: ':organizationId/members/:userId',
    component: OrganizationMemberDetailViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: 'member-add-driver-grade',
        component: MemberAddDriverGradeDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      }
    ]
  },
  {
    path: ':organizationId/managers/:userId',
    component: OrganizationMemberDetailViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: 'member-add-driver-grade',
        component: MemberAddDriverGradeDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      }
    ]
  },
  {
    path: ':organizationId/driver-grades/:driverGradeId',
    component: OrganizationDriverGradeDetailViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: 'driver-grade-filter',
        component: DriverGradeFilterDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      },
      {
        path: 'edit-driver-grade',
        component: EditDriverGradeDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      }
    ]
  },
  {
    path: ':organizationId/vehicle-groups/:vehicleGroupId',
    component: OrganizationVehicleGroupDetailViewComponent,
    canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard],
    children: [
      {
        path: 'edit-vehicles',
        component: VehicleGroupEditVehiclesDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      },
      {
        path: 'edit-vehicle-group',
        component: EditVehicleGroupDialogViewComponent,
        canActivate: [AuthenticatedGuard, OrganizationsAuthorizationGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationManagementRoutingModule {}
