import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject, combineLatest, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/index';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import {
  OrganizationDto,
  OrganizationRoleDto,
  PutUserInOrganizationRequestDto,
  UserDto,
  UserLocaleDto
} from  '../../../../client';
import { ToastUtilService } from '../../services/toast-util.service';
import SharedUiUtils from '../../shared-ui/utils/shared-ui.utils';
import ValidatorUtils from '../../utils/validator.utils';
import { OrganizationManagementSandbox } from '../organization-management.sandbox';
import { OrganizationViewHelper } from './organization-view.helper';

@UntilDestroy()
@Injectable()
export class InviteMemberDialogViewHelper {
  tc = 'ORGANIZATION-MANAGEMENT_INVITE-MEMBER-DIALOG';
  private static DEFAULT_USER_LOCALE: UserLocaleDto = UserLocaleDto.EN;
  public static STEP_EMAIL = 'email-address-step';
  public static STEP_USER_DATA = 'user-data-step';
  public static STEP_DRIVER_GRADE = 'driver-grade-step';

  maxStep$: Observable<string> = this.organizationViewHelper.organization$.pipe(
    take(1),
    map(organization => {
      return organization?.driverGrades?.length > 0
        ? InviteMemberDialogViewHelper.STEP_DRIVER_GRADE
        : InviteMemberDialogViewHelper.STEP_USER_DATA;
    })
  );
  private currentStepSub$: BehaviorSubject<string> = new BehaviorSubject<string>(
    InviteMemberDialogViewHelper.STEP_EMAIL
  );
  disableNext$ = combineLatest([this.maxStep$, this.currentStepSub$]).pipe(
    map(([maxStep, currentStep]) => currentStep === maxStep)
  );
  disablePrevious$ = this.currentStepSub$.pipe(
    map(currentStep => currentStep === InviteMemberDialogViewHelper.STEP_EMAIL)
  );
  disableInvite$ = combineLatest([this.maxStep$, this.currentStepSub$]).pipe(
    map(([maxStep, currentStep]) => currentStep !== maxStep)
  );

  inviteMemberRole$ = combineLatest([
    this.organizationViewHelper.organizationId$,
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      startWith(null)
    )
  ]).pipe(
    map(([organizationId]) => {
      if (
        this.router.url.startsWith(
          '/organizations/' + organizationId + '/managers/invite-member/'
        )
      ) {
        return OrganizationRoleDto.MANAGER;
      }
      return OrganizationRoleDto.MEMBER;
    })
  );

  emailFormGroup: FormGroup = this.fb.group({
    email: [
      '',
      [Validators.required, ValidatorUtils.getValidatorEmailAddress()]
    ]
  });
  userFormGroup: FormGroup = this.fb.group({
    email: [{ value: '', disabled: true }],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    userLocale: [
      InviteMemberDialogViewHelper.DEFAULT_USER_LOCALE,
      Validators.required
    ],
    userRoles: [[], Validators.required]
  });
  organizationMemberFormGroup: FormGroup = this.fb.group({
    driverGradeId: ['', Validators.required]
  });
  private userDto: UserDto;
  private lastEmailChecked: string = null;

  // Selectors
  enumValueFn = e => e?.enumId;
  enumLabelFn = e => SharedUiUtils.getEnumDescription(e);
  driverGradeValueFn = d => d?.id;
  driverGradeLabelFn = d => d?.name;

  constructor(
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private organizationViewHelper: OrganizationViewHelper
  ) {}

  updateFormsAndNext(userDto?: UserDto): void {
    this.lastEmailChecked = this.emailFormGroup.value.email;
    this.userDto = userDto;
    if (!!userDto) {
      this.userFormGroup.reset({
        email: { value: this.emailFormGroup.value.email, disabled: true },
        firstName: { value: userDto.firstName, disabled: true },
        lastName: { value: userDto.lastName, disabled: true },
        userLocale: { value: userDto.userLocale, disabled: true },
        userRoles: { value: userDto.userRoles, disabled: true }
      });
    } else {
      this.userFormGroup.reset({
        email: { value: this.emailFormGroup.value.email, disabled: true },
        firstName: { value: '', disabled: false },
        lastName: { value: '', disabled: false },
        userLocale: {
          value: InviteMemberDialogViewHelper.DEFAULT_USER_LOCALE,
          disabled: false
        },
        userRoles: { value: [], disabled: false }
      });
    }
    this.goToNextStep();
  }

  checkAndNext(): void {
    if (
      this.currentStepSub$.value === InviteMemberDialogViewHelper.STEP_EMAIL
    ) {
      if (this.emailFormGroup.valid) {
        if (
          !!this.lastEmailChecked &&
          this.lastEmailChecked === this.emailFormGroup.value.email
        ) {
          this.goToNextStep();
          return;
        }
        // Check if user exist
        this.organizationViewHelper.organizationId$
          .pipe(
            take(1),
            switchMap(organizationId =>
              this.getUserByUsernameAndOrganization$(organizationId)
            ),
            takeUntilDestroy(this)
          )
          .subscribe(
            (response: {
              userDto: UserDto;
              organizationDto: OrganizationDto;
            }) => {
              if (!!response) {
                const organizationMemberDto = response.organizationDto?.members.find(
                  member => member.user.remoteId === response.userDto.remoteId
                );
                if (!!organizationMemberDto) {
                  this.toastUtilService.warning(
                    this.tc + '.' + 'USER-ALREADY-MEMBER-OF-ORGANIZATION',
                    true,
                    {
                      displayName: SharedUiUtils.getUserDisplayName(
                        response.userDto
                      )
                    }
                  );
                  return;
                }
                this.updateFormsAndNext(response.userDto);
              } else {
                this.updateFormsAndNext();
              }
            },
            (error: { message: string; response: HttpErrorResponse }) => {
              this.toastUtilService.showError(error.response, error.message);
            }
          );
      }
    } else if (
      this.currentStepSub$.value ===
        InviteMemberDialogViewHelper.STEP_USER_DATA &&
      (this.userFormGroup.valid || !!this.userDto)
    ) {
      this.goToNextStep();
    }
  }

  private getUserByUsernameAndOrganization$(
    organizationId: string
  ): Observable<{ userDto: UserDto; organizationDto: OrganizationDto }> {
    return this.osb.getUserByUsername(this.emailFormGroup.value.email).pipe(
      catchError(response =>
        throwError({
          response,
          message: this.tc + '.FAILED_CHECK-EMAIL-EXISTENCE'
        })
      ),
      mergeMap(userDto => {
        return !!userDto
          ? this.osb.getOrganization(organizationId).pipe(
              take(1),
              catchError(response =>
                throwError({
                  response,
                  message:
                    this.tc + '.FAILED_CHECK-EMAIL-EXISTENCE-GET-ORGANIZATION'
                })
              ),
              mergeMap(organizationDto => of({ userDto, organizationDto }))
            )
          : of(undefined);
      })
    );
  }

  getInviteUser$(userId: string): Observable<any> {
    return combineLatest([
      this.organizationViewHelper.organizationId$,
      this.inviteMemberRole$
    ]).pipe(
      take(1),
      switchMap(([organizationId, organizationRole]) => {
        const request: PutUserInOrganizationRequestDto = {
          organizationId,
          userRemoteId: userId,
          role: organizationRole
        };
        return this.osb
          .putUserInOrganization(organizationId, userId, request)
          .pipe(
            catchError(response =>
              throwError({
                response,
                message: this.tc + '.FAILED_INVITE-NEW-MEMBER'
              })
            ),
            mergeMap(() => {
              return this.currentStepSub$.value ===
                InviteMemberDialogViewHelper.STEP_DRIVER_GRADE
                ? this.osb
                    .addUserToDriverGrade(
                      this.organizationMemberFormGroup.value.driverGradeId,
                      organizationId,
                      userId
                    )
                    .pipe(
                      catchError(response =>
                        throwError({
                          response,
                          message:
                            this.tc +
                            '.FAILED_INVITE-NEW-MEMBER-ADD-TO-DRIVER-GRADE'
                        })
                      )
                    )
                : of(undefined);
            })
          );
      })
    );
  }

  showSuccessAndClose(): void {
    this.toastUtilService.success(
      this.tc + '.SUCCESSFUL_INVITE-NEW-MEMBER',
      true
    );
    this.router
      .navigate(['..'], {
        relativeTo: this.activatedRoute
      })
      .then(() => {
        this.organizationViewHelper.triggerOrganization();
      });
  }

  inviteUser(): void {
    if (!!this.userDto) {
      this.getInviteUser$(this.userDto.remoteId).subscribe(
        () => {
          this.showSuccessAndClose();
        },
        (error: { message: string; response: HttpErrorResponse }) => {
          this.toastUtilService.showError(error.response, error.message);
        }
      );
    } else {
      const userDto: UserDto = {
        email: this.emailFormGroup.value.email,
        userName: this.emailFormGroup.value.email,
        firstName: this.userFormGroup.value.firstName,
        lastName: this.userFormGroup.value.lastName,
        userLocale: this.userFormGroup.value.userLocale,
        userRoles: this.userFormGroup.value.userRoles
      };
      this.osb
        .createUser(userDto)
        .pipe(
          catchError(response =>
            throwError({
              response,
              message: this.tc + '.FAILED_INVITE-NEW-MEMBER-CREATE-USER'
            })
          ),
          mergeMap(user => {
            this.userDto = user;
            return this.osb.sendOnboardingMail(user.remoteId).pipe(
              catchError(response => {
                this.toastUtilService.showError(
                  response,
                  this.tc + '.FAILED_SEND-ONBOARDING-EMAIL'
                );
                return of(userDto);
              }),
              mergeMap(() => this.getInviteUser$(user.remoteId))
            );
          }),
          takeUntilDestroy(this)
        )
        .subscribe(
          () => {
            this.showSuccessAndClose();
          },
          (error: { message: string; response: HttpErrorResponse }) => {
            this.toastUtilService.showError(error.response, error.message);
          }
        );
    }
  }

  isInviteDataValid(): boolean {
    if (
      this.currentStepSub$.value ===
        InviteMemberDialogViewHelper.STEP_DRIVER_GRADE &&
      !this.organizationMemberFormGroup.valid
    ) {
      this.toastUtilService.error(
        this.tc + '.PLEASE-SELECT-A-DRIVER-GRADE',
        true
      );
      return false;
    } else if (
      this.currentStepSub$.value ===
        InviteMemberDialogViewHelper.STEP_USER_DATA &&
      !this.userFormGroup.valid &&
      !this.userDto
    ) {
      this.toastUtilService.error(this.tc + '.FILL-OUT-USER-DATA', true);
      return false;
    }
    return true;
  }

  goToNextStep(): void {
    if (
      this.currentStepSub$.value === InviteMemberDialogViewHelper.STEP_EMAIL
    ) {
      this.setCurrentStep(InviteMemberDialogViewHelper.STEP_USER_DATA);
    } else if (
      this.currentStepSub$.value === InviteMemberDialogViewHelper.STEP_USER_DATA
    ) {
      this.setCurrentStep(InviteMemberDialogViewHelper.STEP_DRIVER_GRADE);
    }
  }

  goToPreviousStep(): void {
    if (
      this.currentStepSub$.value === InviteMemberDialogViewHelper.STEP_USER_DATA
    ) {
      this.setCurrentStep(InviteMemberDialogViewHelper.STEP_EMAIL);
    } else if (
      this.currentStepSub$.value ===
      InviteMemberDialogViewHelper.STEP_DRIVER_GRADE
    ) {
      this.setCurrentStep(InviteMemberDialogViewHelper.STEP_USER_DATA);
    }
  }

  private setCurrentStep(step: string): void {
    this.currentStepSub$.next(step);
    this.navigateToCurrentStep();
  }

  private navigateToCurrentStep(): void {
    this.router.navigate([this.currentStepSub$.value], {
      relativeTo: this.activatedRoute
    });
  }

  checkCurrentRoute(): void {
    // Make sure to display the right step (can happen if the user refresh the page when not on the first step)
    if (!this.router.url?.endsWith(this.currentStepSub$.value)) {
      this.navigateToCurrentStep();
    }
  }
}
