import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  VehicleDto,
  VehiclePageDto
} from '../../../client';
import { BookingService } from '../services/booking.service';
import { TelematicsService } from '../services/telematics.service';

@Injectable()
export class VehicleManagementSandbox {
  constructor(
    private bookingService: BookingService,
    private telematicsService: TelematicsService,
    private router: Router
  ) {}

  fetchVehicles(): Observable<VehiclePageDto> {
    return this.bookingService.fetchVehicles();
  }

  navigateToCreateVehicleForm(): void {
    this.router.navigate(['create-vehicle']);
  }

  navigateToDetailVehicleScreen(vehicleId: string): void {
    this.router.navigate(['detail-vehicle', vehicleId]);
  }

  deleteTelematics(vehicleId: string): Observable<any> {
    return this.telematicsService.deleteTelematics(vehicleId);
  }

  getVehicle(vehicleId: string): Observable<VehicleDto> {
    return this.bookingService.getVehicle(vehicleId).pipe(take(1));
  }
}
