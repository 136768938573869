import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Booking } from '../../../types/booking.type';
import { SchedulerSetting } from '../../../scheduler.setting';
import { BookingUpdate } from '../../../types/booking-update.type';

@Component({
  selector: 'sof-information-panel-booking',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="table-container">
      <table>
        <tr>
          <th>ID :</th>
          <td>{{ booking?.remoteId }}</td>
        </tr>
        <tr>
          <th>start date :</th>
          <td>{{ booking?.fromDate | date: dateFormat }}</td>
        </tr>
        <tr>
          <th>end date :</th>
          <td>{{ booking?.toDate | date: dateFormat }}</td>
        </tr>
      </table>
    </div>
  `,
  styleUrls: ['./information-panel-booking.component.scss']
})
export class InformationPanelBookingComponent {
  @Input() booking: Booking;

  dateFormat =
    SchedulerSetting.dateFormat.hour + ' ' + SchedulerSetting.dateFormat.day;
}
