import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Changes, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';
import { TooltipEvent } from '../../../classes/tooltip-events.class';
import { SchedulerSetting } from '../../../scheduler.setting';
import { BookingRequest } from '../../../types/booking-request.type';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';
import { ViewMode } from '../../../types/view-mode.type';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';

@UntilDestroy()
@Component({
  selector: 'sof-cards-and-schedulers-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-horizontal-splitter
      [areaSize]="horizontalSplitterAreaSize"
      (newAreaSize)="newHorizontalSplitterAreaSize.emit($event)"
    >
      <div location="left">
        <div class="card-list-top" [style.height.px]="headerTopHeightPx">
          <div class="header">
            {{ bookingRequests?.length }} pending requests
          </div>
        </div>
      </div>

      <div location="right">
        <sof-booking-scheduler-header
          [columns]="columns$ | async"
          [subColumns]="subColumns$ | async"
          [style.width.px]="periodWidthPx"
          [style.marginLeft.px]="-scrollLeft"
          (tooltipEvent)="tooltipEvent.emit($event)"
        >
        </sof-booking-scheduler-header>
      </div>
    </sof-horizontal-splitter>
  `,
  styleUrls: ['cards-and-schedulers-header.component.scss']
})
export class CardsAndSchedulersHeaderComponent implements OnChanges, OnInit {
  @Input() horizontalSplitterAreaSize: [number, number];
  @Input() schedulerDateFrom: Date;
  @Input() schedulerDateTo: Date;
  @Input() msPerPixel: number;
  @Input() periodWidthPx: number;
  @Input() viewMode: ViewMode;
  @Input() scrollLeft: number;
  @Input() bookingRequests: BookingRequest[];

  @Output()
  newHorizontalSplitterAreaSize: EventEmitter<
    [number, number]
  > = new EventEmitter<[number, number]>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();

  @Changes('viewMode') viewMode$: Observable<ViewMode>;
  @Changes('msPerPixel') msPerPixel$: Observable<number>;
  @Changes('schedulerDateFrom') schedulerDateFrom$: Observable<Date>;
  @Changes('schedulerDateTo') schedulerDateTo$: Observable<Date>;

  headerCalendarHeightPx = SchedulerSetting.headerHeightPx.calendarPart;
  headerTopHeightPx = SchedulerSetting.headerHeightPx.topPart;

  @HostBinding('style.height.px')
  elementHeight = this.headerCalendarHeightPx + this.headerTopHeightPx;

  // streams to manage the header content of the booking scheduler
  columns$: Observable<BookingSchedulerDate[]>;

  subColumns$: Observable<BookingSchedulerDate[]>;

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.columns$ = combineLatest([
      this.schedulerDateFrom$,
      this.viewMode$
    ]).pipe(
      map(([schedulerDateFrom, viewMode]: [Date, ViewMode]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.column.duration,
          viewMode.calendar.column.date_format
        )
      )
    );

    this.subColumns$ = combineLatest([
      this.schedulerDateFrom$,
      this.viewMode$
    ]).pipe(
      map(([schedulerDateFrom, viewMode]: [Date, ViewMode]) =>
        SchedulerDateUtils.getSubDurationsFromDate(
          schedulerDateFrom,
          viewMode.duration,
          viewMode.calendar.subColumn.duration,
          viewMode.calendar.subColumn.date_format
        )
      )
    );
  }
}
