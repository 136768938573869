import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BatteryStatusDto,
  ChangeLockRequestDto,
  CreateTelematicsRequestDto,
  GpsLocationDto,
  TelematicsControllerImplService,
  TelematicsDeviceDto,
  UpdateTelematicsRequestDto
} from '../../../client';

@Injectable({
  providedIn: 'root'
})
export class TelematicsService {
  constructor(
    private telematicsControllerImplService: TelematicsControllerImplService
  ) {}

  createTelematics(
    providerDeviceId: string,
    providerId: string,
    vehicleId: string,
    maxRange: number
  ): Observable<any> {
    const telematicsDeviceDto: TelematicsDeviceDto = {
      providerDeviceId,
      providerId,
      vehicleId,
      maxRange
    };

    const deviceArray: TelematicsDeviceDto[] = [];
    deviceArray.push(telematicsDeviceDto);

    const createTelematicsRequestDto: CreateTelematicsRequestDto = {
      devices: deviceArray
    };

    return this.telematicsControllerImplService.createDevices(
      createTelematicsRequestDto,
      'response'
    );
  }

  deleteTelematics(vehicleId: string): Observable<any> {
    return this.telematicsControllerImplService.deleteDevice(
      vehicleId,
      'response'
    );
  }

  getTelematics(vehicleId: string): Observable<TelematicsDeviceDto> {
    return this.telematicsControllerImplService.getDeviceByVehicle(vehicleId);
  }

  updateTelematics(
    providerDeviceId: string,
    providerId: string,
    vehicleId: string,
    maxRange: number
  ): Observable<any> {
    const updateTelematicsRequestDto: UpdateTelematicsRequestDto = {
      providerDeviceId,
      providerId,
      maxRange
    };

    return this.telematicsControllerImplService.updateDevice(
      vehicleId,
      updateTelematicsRequestDto,
      'response'
    );
  }

  changeLockStatus(vehicleId: string, lock: boolean): Observable<any> {
    let status;
    if (lock) {
      status = 'LOCKED';
    } else {
      status = 'UNLOCKED';
    }
    const changeLockRequestDto: ChangeLockRequestDto = {
      status
    };
    return this.telematicsControllerImplService.changeLockStatus(
      vehicleId,
      changeLockRequestDto,
      'response'
    );
  }

  getBatteryStatus(vehicleId: string): Observable<BatteryStatusDto> {
    return this.telematicsControllerImplService.getBatteryStatus(vehicleId);
  }

  refreshLocation(vehicleId: string): Observable<GpsLocationDto> {
    return this.telematicsControllerImplService.refreshLocation(vehicleId);
  }
}
