import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleDto } from '../../../../../client';
import { VehiclesViewHelper } from '../../view-helpers/vehicles-view.helper';

@Component({
  selector: 'sof-copy-vehicle-view',
  template: `
    <ng-container>
      <sof-vehicle-dialog
        *ngIf="isVisible"
        headerLabel="DIALOG-TITLE-COPY"
        [vehicleDialogData]="vehiclesViewHelper.vehicleDialogData"
        (cancelDialog)="onCancelDialog()"
        (closeDialogAfterSave)="onCloseDialogAfterSave($event)"
      ></sof-vehicle-dialog>
    </ng-container>
  `
})
export class CopyVehicleDialogViewComponent implements OnInit {
  isVisible = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public vehiclesViewHelper: VehiclesViewHelper
  ) {}

  ngOnInit(): void {
    if (
      !!this.vehiclesViewHelper.vehicleDialogData?.vehicleId ||
      !this.vehiclesViewHelper.vehicleDialogData?.vehicleDto ||
      !this.vehiclesViewHelper.vehicleDialogData?.currentUser ||
      !this.vehiclesViewHelper.vehicleDialogData?.userPage
    ) {
      this.onCancelDialog();
    } else {
      this.isVisible = true;
    }
  }

  onCancelDialog(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  onCloseDialogAfterSave(vehicle: VehicleDto): void {
    this.router
      .navigate(['..'], { relativeTo: this.activatedRoute })
      .then(() => {
        this.vehiclesViewHelper.triggerRefreshList();
      });
  }
}
