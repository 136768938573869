import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Changes, takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Duration } from '../../../classes/duration.class';
import SharedUiUtils from '../../../shared-ui/utils/shared-ui.utils';
import { SchedulerDateUtils } from '../../utils/scheduler-date.utils';
import { BookingResizeInfo } from '../../../types/booking-resize-info.type';

@UntilDestroy()
@Component({
  selector: 'sof-booking-scheduler-highlighted-area',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="highlighted-area-container"
      [style.width.px]="widthRounded"
      [ngStyle]="style"
    ></div>
  `,
  styleUrls: ['./booking-scheduler-highlighted-area.component.scss']
})
export class BookingSchedulerHighlightedAreaComponent
  implements OnChanges, OnInit {
  @Input() schedulerDateFrom: Date;
  @Input() msPerPixel: number;
  @Input() smallestDragUnit: Duration;
  @Input() leftPx: number;
  @Input() widthPx: number;
  @Input() shiftKeyPressed: boolean;
  @Input()
  set bookingOnResizeInfos(bookingOnResizeInfos: BookingResizeInfo) {
    this.localBookingOnResizeInfos = bookingOnResizeInfos;
    if (!!this?.localBookingOnResizeInfos?.data) {
      const bookingColors = SharedUiUtils.getBookingColors(
        this.localBookingOnResizeInfos.data.status,
        this.localBookingOnResizeInfos.fromDate,
        this.localBookingOnResizeInfos.toDate
      );
      this.style = {
        'background-color': bookingColors.background,
        color: bookingColors.text,
        border: '1.5px solid ' + bookingColors.border
      };
    }
  }

  @HostBinding('style.display')
  @Input()
  display: 'none' | 'block' = 'block';

  @Output() fromDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() toDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Changes('leftPx') leftPx$: Observable<number>;
  @Changes('widthPx') widthPx$: Observable<number>;

  // from the inputs, we define a dateFrom and dateTo rounded
  dateFromRounded$: Observable<Date>;

  dateToRounded$: Observable<Date>;

  // we define the width and left value of the area
  // this style is the same as the input style, but rounded
  leftRounded$: Observable<number>;

  widthRounded$: Observable<number>;
  @HostBinding('style.left.px') leftRounded: number;
  widthRounded: number;
  localBookingOnResizeInfos: BookingResizeInfo;
  style: any;

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.dateFromRounded$ = this.leftPx$.pipe(
      map(left => {
        if (this.shiftKeyPressed) {
          return new Date(
            this.schedulerDateFrom.valueOf() + left * this.msPerPixel
          );
        } else {
          return SchedulerDateUtils.roundDate(
            new Date(this.schedulerDateFrom.valueOf() + left * this.msPerPixel),
            this.smallestDragUnit
          );
        }
      })
    );

    this.dateToRounded$ = combineLatest([this.leftPx$, this.widthPx$]).pipe(
      map(([left, width]) => {
        if (this.shiftKeyPressed) {
          return new Date(
            this.schedulerDateFrom.valueOf() + (left + width) * this.msPerPixel
          );
        } else {
          return SchedulerDateUtils.roundDate(
            new Date(
              this.schedulerDateFrom.valueOf() +
                (left + width) * this.msPerPixel
            ),
            this.smallestDragUnit
          );
        }
      })
    );

    // we define the width and left value of the area
    // this style is the same as the input style, but rounded
    this.leftRounded$ = this.dateFromRounded$.pipe(
      map(
        dateFrom =>
          (dateFrom.valueOf() - this.schedulerDateFrom.valueOf()) /
          this.msPerPixel
      )
    );

    this.widthRounded$ = combineLatest([
      this.dateFromRounded$,
      this.dateToRounded$
    ]).pipe(
      map(
        ([dateFrom, dateTo]) =>
          (dateTo.valueOf() - dateFrom.valueOf()) / this.msPerPixel
      )
    );

    // we emit the new date rounded
    this.dateFromRounded$.pipe(takeUntilDestroy(this)).subscribe(date => {
      this.fromDate.emit(date);
    });
    this.dateToRounded$.pipe(takeUntilDestroy(this)).subscribe(date => {
      this.toDate.emit(date);
    });

    // we apply to the host style the width and left rounded
    this.widthRounded$
      .pipe(takeUntilDestroy(this))
      .subscribe(widthRounded => (this.widthRounded = widthRounded));
    this.leftRounded$
      .pipe(takeUntilDestroy(this))
      .subscribe(leftRounded => (this.leftRounded = leftRounded));
  }
}
