import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../../helpers/date-util';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

@Pipe({ name: 'toFormattedConversationDate' })
export class ToFormattedConversationDatePipe implements PipeTransform {
  transform(timeStamp: string): string {
    const momentTimestamp = moment(DateUtil.convertToDate(timeStamp));
    if (moment().isSame(momentTimestamp, 'd')) {
      return momentTimestamp.format('HH:mm');
    }
    return momentTimestamp.format('DD/MM/YYYY HH:mm');
  }
}
