import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@sofico-framework/app-config';
import { DateFormatEnum, SortingOrderConfig } from '@sofico-framework/utils';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject } from 'rxjs';
import { Observable, pipe } from 'rxjs/index';
import { map, take } from 'rxjs/operators';
import {
  AvailabilityConflictDto,
  AvailabilityDto,
  AvailabilityTypeDto,
  ConflictResolutionDto,
  CrudAvailabilityResponseDto,
  RecurringTypeDto,
  VehicleDto,
  VehicleEventDto,
  VehicleEventTypeDto
} from '../../../../../client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateUtil } from '../../../helpers/date-util';
import { ToastUtilService } from '../../../services/toast-util.service';
import { AvailabilityConflictResolutionDialogData } from '../../../types/availability-conflict-resolution-dialog-data.type';
import { AvailabilityDialogData } from '../../../types/availability-dialog-data.type';
import { AvailabilityRemoveDialogData } from '../../../types/availability-remove-dialog-data.type';
import { TranslatedData } from '../../../types/translated-data.type';
import TranslateUtils from '../../../utils/translate.utils';
import { SharedUiSandbox } from '../../shared-ui.sandbox';
import {
  ConflictDetails,
  ConflictPeriods
} from '../../../types/conflict-details.type';

@UntilDestroy()
@Component({
  selector: 'sof-availability-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="md"
      [headerLabel]="tc + '.AVAILABILITY-DIALOG-TITLE' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div
          class="current-data"
          *ngIf="!!dialogData.availabilityId && !!licensePlate"
        >
          <div class="current-data-title">
            {{ tc + '.CURRENT-VEHICLE' | translate }}
          </div>
          <div class="vehicle-data-container">
            <sof-license-plate
              class="scheduler-license-plate"
              countryCode="be"
              [value]="licensePlate"
            ></sof-license-plate>
            <span class="vehicle-name">{{ vehicleName }}</span>
          </div>
        </div>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="availabilityForm" class="flex-fill">
            <div class="d-flex flex-column">
              <div class="row" *ngIf="!dialogData.availabilityId">
                <sof-input-wrapper
                  [label]="tc + '.VEHICLE' | translate"
                  class="col-sm"
                >
                  <sof-input-single-select
                    [sofInput]
                    [tc]="tc"
                    [options]="
                      dialogData.vehicles | sofSort: sortingVehicleName
                    "
                    [formControl]="availabilityForm?.controls?.vehicleId"
                    [selectorValue]="vehicleValueFn"
                    [selectorLabel]="vehicleLabelFn"
                    (changeValue)="onVehicleChange($event)"
                  ></sof-input-single-select>
                </sof-input-wrapper>
              </div>
              <div class="row">
                <sof-input-wrapper
                  [label]="tc + '.FROM-DATE' | translate"
                  class="col-sm"
                >
                  <sof-input-date-picker
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.fromDate"
                    [dateFormat]="dateFormat"
                    [showToday]="true"
                    (changeValue)="onFromDateChange($event)"
                  ></sof-input-date-picker>
                </sof-input-wrapper>
                <sof-input-wrapper
                  [label]="tc + '.TO-DATE' | translate"
                  class="col-sm"
                >
                  <sof-input-date-picker
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.toDate"
                    [dateFormat]="dateFormat"
                    [showToday]="true"
                    (changeValue)="onToDateChange($event)"
                  ></sof-input-date-picker>
                </sof-input-wrapper>
              </div>
              <div class="row">
                <div class="col-sm">
                  <sof-input-switch
                    [sofInput]
                    [label]="tc + '.ALL-DAY-EVENT' | translate"
                    [formControl]="availabilityForm?.controls?.allDayEvent"
                    (changeValue)="onAllDayEventChange($event)"
                  ></sof-input-switch>
                </div>
              </div>
              <div class="row">
                <sof-input-wrapper
                  [label]="tc + '.FROM-TIME' | translate"
                  class="col-sm"
                >
                  <sof-input-time-picker
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.fromTime"
                    [allowEmpty]="false"
                    [isDisabled]="fromTimeDisabled"
                  ></sof-input-time-picker>
                </sof-input-wrapper>
                <sof-input-wrapper
                  [label]="tc + '.TO-TIME' | translate"
                  class="col-sm"
                >
                  <sof-input-time-picker
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.toTime"
                    [allowEmpty]="false"
                    [isDisabled]="toTimeDisabled"
                  ></sof-input-time-picker>
                </sof-input-wrapper>
              </div>
              <div class="row">
                <div class="col-sm blocked-button-group-container">
                  <div class="sof-toggle-button-group">
                    <sof-input-radio
                      [sofInput]
                      [formControl]="availabilityForm?.controls?.blocked"
                      [value]="false"
                    >
                      {{ tc + '.AVAILABLE' | translate }}
                    </sof-input-radio>
                    <sof-input-radio
                      [sofInput]
                      [formControl]="availabilityForm?.controls?.blocked"
                      [value]="true"
                    >
                      {{ tc + '.NOT-AVAILABLE' | translate }}
                    </sof-input-radio>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm">
                  <sof-input-switch
                    [sofInput]
                    [label]="tc + '.RECURRING-EVENT' | translate"
                    [formControl]="availabilityForm?.controls?.recurringEvent"
                    (changeValue)="onRecurringEventChange($event)"
                  ></sof-input-switch>
                </div>
              </div>
              <div class="row">
                <sof-input-wrapper
                  [label]="tc + '.EVERY' | translate"
                  class="col-sm"
                >
                  <sof-input-single-select
                    [sofInput]
                    [tc]="tc"
                    [options]="intervals"
                    [formControl]="availabilityForm?.controls?.interval"
                    [selectorValue]="translatedDataValueFn"
                    [selectorLabel]="translatedDataLabelFn"
                  ></sof-input-single-select>
                </sof-input-wrapper>
                <sof-input-wrapper
                  [label]="tc + '.UNIT' | translate"
                  class="col-sm"
                >
                  <sof-input-single-select
                    [sofInput]
                    [tc]="tc"
                    [options]="recurringTypes$ | async"
                    [formControl]="availabilityForm?.controls?.recurringType"
                    [selectorValue]="translatedDataValueFn"
                    [selectorLabel]="translatedDataLabelFn"
                    (changeValue)="onRecurringTypeChange($event)"
                  ></sof-input-single-select>
                </sof-input-wrapper>
                <sof-input-wrapper
                  [label]="tc + '.ENDS-ON' | translate"
                  class="col-sm"
                >
                  <sof-input-date-picker
                    [sofInput]
                    [formControl]="availabilityForm?.controls?.endsOn"
                    [dateFormat]="dateFormat"
                    [showToday]="true"
                    [isDisabled]="endsOnDisabled"
                    (changeValue)="onToDateChange($event)"
                  ></sof-input-date-picker>
                </sof-input-wrapper>
              </div>
              <div class="row">
                <sof-input-wrapper label="Days of week" class="col-sm">
                  <sof-input-multi-select
                    [sofInput]
                    [tc]="tc"
                    [options]="daysOfTheWeek$ | async"
                    [formControl]="availabilityForm?.controls?.daysOfWeek"
                    [selectorValue]="translatedDataValueFn"
                    [selectorLabel]="translatedDataLabelFn"
                  ></sof-input-multi-select>
                </sof-input-wrapper>
              </div>
            </div>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            *ngIf="dialogData?.availabilityId"
            (click)="onRemoveClick()"
            class="general-action-button"
          >
            {{ tc + '.REMOVE' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveAvailabilityClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
    <sof-availability-remove-dialog
      *ngIf="showAvailabilityRemoveDialog"
      [tc]="tc"
      [dialogData]="availabilityRemoveDialogData"
      (cancelDialog)="showAvailabilityRemoveDialog = false"
      (okDialog)="onOkAvailabilityRemoveDialog($event)"
    ></sof-availability-remove-dialog>
    <sof-availability-conflict-resolution-dialog
      *ngIf="showAvailabilityConflictResolutionDialogSub$ | async"
      [tc]="tc"
      [dialogData]="availabilityConflictResolutionDialogDataSub$ | async"
      (cancelDialog)="showAvailabilityConflictResolutionDialogSub$.next(false)"
      (saveDialog)="onSaveAvailabilityConflictResolutionDialog($event)"
    ></sof-availability-conflict-resolution-dialog>
  `,
  styleUrls: ['./availability-dialog.component.scss']
})
export class AvailabilityDialogComponent implements OnInit {
  tc = 'AVAILABILITY_DIALOG';

  @Input() dialogData: AvailabilityDialogData;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialogAfterSave: EventEmitter<void> = new EventEmitter<void>();

  dateFormat: DateFormatEnum = this.configService.config.app.dateFormat;

  showAvailabilityRemoveDialog = false;
  availabilityRemoveDialogData: AvailabilityRemoveDialogData;
  showAvailabilityConflictResolutionDialogSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  availabilityConflictResolutionDialogDataSub$: BehaviorSubject<AvailabilityConflictResolutionDialogData> = new BehaviorSubject<AvailabilityConflictResolutionDialogData>(
    null
  );

  availabilityForm: FormGroup;
  licensePlate = '';
  vehicleName = '';
  intervals: TranslatedData[] = Array.from({ length: 19 }, (v, k) => {
    return {
      value: k + 1,
      translation: String(k + 1)
    };
  });
  editChoice: number = null;
  daysOfTheWeek$: Observable<TranslatedData[]>;
  recurringTypes$: Observable<TranslatedData[]>;

  sortingVehicleName: SortingOrderConfig<VehicleDto> = {
    prop: 'name'
  };

  fromTimeDisabled = true;
  toTimeDisabled = true;
  endsOnDisabled = true;

  constructor(
    private fb: FormBuilder,
    private sharedUiSandbox: SharedUiSandbox,
    private toastUtilService: ToastUtilService,
    private configService: ConfigService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initDialog();
    this.daysOfTheWeek$ = this.getDaysOfTheWeek$();
    this.recurringTypes$ = this.getRecurringTypes$();
  }

  private initDialog(): void {
    const fromTime = this.getTime0000();
    const toTime = this.getTime2359();
    if (this.dialogData.availabilityId) {
      fromTime.setHours(this.dialogData.fromDate.getHours());
      fromTime.setMinutes(this.dialogData.fromDate.getMinutes());
      toTime.setHours(this.dialogData.toDate.getHours());
      toTime.setMinutes(this.dialogData.toDate.getMinutes());
    }

    this.editChoice = this.dialogData.editChoice;
    this.licensePlate = this.dialogData.licensePlate;
    this.vehicleName = this.dialogData.vehicleName;
    const allDayEvent = this.isTime0000(fromTime) && this.isTime2359(toTime);
    const recurringEvent = !!this.dialogData.recurringAvailability;
    const recurringType = recurringEvent
      ? this.dialogData.recurringAvailability.recurringType
      : RecurringTypeDto.WEEKLY;
    const interval = recurringEvent
      ? this.dialogData.recurringAvailability.interval
      : 1;
    const endsOn = recurringEvent
      ? this.dialogData.recurringAvailability.endDate
      : new Date();
    const daysOfWeek = recurringEvent
      ? this.dialogData.recurringAvailability.daysOfWeek
      : [1, 2, 3, 4, 5];
    const disableRecurringFields = this.editChoice === 1;
    let blocked = false;
    if (!!this.dialogData.availabilityId) {
      blocked = this.dialogData.availabilityDto.type === 'BLOCKED';
    } else if (this.dialogData.alwaysAvailable) {
      blocked = true;
    }

    this.fromTimeDisabled = allDayEvent;
    this.toTimeDisabled = allDayEvent;
    this.endsOnDisabled = !recurringEvent || disableRecurringFields;
    this.availabilityForm = this.fb.group({
      vehicleId: [
        {
          value: this.dialogData.vehicleId,
          disabled: this.dialogData.vehicleId
        },
        Validators.required
      ],
      fromDate: [this.dialogData.fromDate, Validators.required],
      fromTime: [
        { value: fromTime, disabled: allDayEvent },
        Validators.required
      ],
      toDate: [this.dialogData.toDate, Validators.required],
      toTime: [{ value: toTime, disabled: allDayEvent }, Validators.required],
      allDayEvent: [allDayEvent],
      blocked: [{ value: blocked, disabled: !!this.dialogData.availabilityId }],
      recurringEvent: [
        { value: recurringEvent, disabled: disableRecurringFields }
      ],
      recurringType: [
        {
          value: recurringType,
          disabled: !recurringEvent || disableRecurringFields
        }
      ],
      interval: [
        { value: interval, disabled: !recurringEvent || disableRecurringFields }
      ],
      endsOn: [
        { value: endsOn, disabled: !recurringEvent || disableRecurringFields },
        Validators.required
      ],
      daysOfWeek: [
        {
          value: daysOfWeek,
          disabled:
            !recurringEvent ||
            recurringType !== RecurringTypeDto.WEEKLY ||
            disableRecurringFields
        }
      ]
    });
  }

  private getDaysOfTheWeek$(): Observable<TranslatedData[]> {
    const daysOfTheWeek = [
      {
        value: 1,
        translation: this.tc + '.DAY_OF_THE_WEEK_MONDAY'
      },
      {
        value: 2,
        translation: this.tc + '.DAY_OF_THE_WEEK_TUESDAY'
      },
      {
        value: 3,
        translation: this.tc + '.DAY_OF_THE_WEEK_WEDNESDAY'
      },
      {
        value: 4,
        translation: this.tc + '.DAY_OF_THE_WEEK_THURSDAY'
      },
      {
        value: 5,
        translation: this.tc + '.DAY_OF_THE_WEEK_FRIDAY'
      },
      {
        value: 6,
        translation: this.tc + '.DAY_OF_THE_WEEK_SATURDAY'
      },
      {
        value: 7,
        translation: this.tc + '.DAY_OF_THE_WEEK_SUNDAY'
      }
    ];
    return this.translateService
      .get(daysOfTheWeek.map(d => d.translation))
      .pipe(
        take(1),
        map(translations => {
          return daysOfTheWeek.map(d => {
            return {
              value: d.value,
              translation: translations[d.translation]
            };
          });
        })
      );
  }

  private getRecurringTypes$(): Observable<TranslatedData[]> {
    const recurringTypes = [
      {
        value: RecurringTypeDto.DAILY,
        translation: this.tc + '.RECURRING_TYPE_' + RecurringTypeDto.DAILY
      },
      {
        value: RecurringTypeDto.WEEKLY,
        translation: this.tc + '.RECURRING_TYPE_' + RecurringTypeDto.WEEKLY
      },
      {
        value: RecurringTypeDto.WEEKDAYS,
        translation: this.tc + '.RECURRING_TYPE_' + RecurringTypeDto.WEEKDAYS
      },
      {
        value: RecurringTypeDto.MONTHLY,
        translation: this.tc + '.RECURRING_TYPE_' + RecurringTypeDto.MONTHLY
      },
      {
        value: RecurringTypeDto.MONTHLYSAMEWEEKDAY,
        translation:
          this.tc + '.RECURRING_TYPE_' + RecurringTypeDto.MONTHLYSAMEWEEKDAY
      }
    ];
    return TranslateUtils.getTranslatedData$(
      this.translateService,
      recurringTypes
    );
  }

  isTime0000(date: Date): boolean {
    return date?.getHours() === 0 && date?.getMinutes() === 0;
  }

  isTime2359(date: Date): boolean {
    return date?.getHours() === 23 && date?.getMinutes() === 59;
  }

  getTime0000(): Date {
    return DateUtil.getTime(0, 0);
  }

  getTime2359(): Date {
    return DateUtil.getTime(23, 59);
  }

  convertToDate(isoString: string): Date {
    return DateUtil.convertToDate(isoString);
  }

  getAvailabilityDto(): AvailabilityDto {
    return this.dialogData.availabilityDto
      ? this.dialogData.availabilityDto
      : null;
  }

  formatTo2DigitsString(num: number): string {
    return (num > 9 ? '' : '0') + num.toString();
  }

  onAllDayEventChange(selected: boolean): void {
    const fromTime = this.availabilityForm.get('fromTime');
    const toTime = this.availabilityForm.get('toTime');
    if (selected) {
      fromTime.setValue(this.getTime0000());
      toTime.setValue(this.getTime2359());
      fromTime.disable();
      toTime.disable();
    } else {
      fromTime.enable();
      toTime.enable();
    }
    this.fromTimeDisabled = selected;
    this.toTimeDisabled = selected;
  }

  onRecurringEventChange(selected: boolean): void {
    const interval = this.availabilityForm.get('interval');
    const recurringType = this.availabilityForm.get('recurringType');
    const endsOn = this.availabilityForm.get('endsOn');
    const daysOfWeek = this.availabilityForm.get('daysOfWeek');
    if (selected) {
      interval.enable();
      recurringType.enable();
      endsOn.enable();
      this.enableDaysOfWeek(recurringType.value);
    } else {
      interval.disable();
      recurringType.disable();
      endsOn.disable();
      daysOfWeek.disable();
    }
    this.endsOnDisabled = !selected;
  }

  enableDaysOfWeek(recurringType: string): void {
    const daysOfWeek = this.availabilityForm.get('daysOfWeek');
    if (recurringType === RecurringTypeDto.WEEKLY) {
      daysOfWeek.enable();
    } else {
      daysOfWeek.disable();
    }
  }

  onRecurringTypeChange(recurringType: string): void {
    this.enableDaysOfWeek(recurringType);
  }

  addTimeToDate(date: Date, time: Date): Date {
    const d = new Date(date);
    d.setHours(time.getHours());
    d.setMinutes(time.getMinutes());
    return d;
  }

  onSaveAvailabilityClick(): void {
    if (!this.availabilityForm.valid) {
      return;
    }

    if (this.availabilityForm.value.vehicleId === '') {
      this.availabilityForm.value.vehicleId = null;
    }

    const fromTime: Date = this.availabilityForm.value.allDayEvent
      ? this.getTime0000()
      : this.availabilityForm.value.fromTime;
    const toTime: Date = this.availabilityForm.value.allDayEvent
      ? this.getTime2359()
      : this.availabilityForm.value.toTime;
    const fromDate: Date = this.addTimeToDate(
      this.availabilityForm.value.fromDate,
      fromTime
    );
    const toDate: Date = this.addTimeToDate(
      this.availabilityForm.value.toDate,
      toTime
    );
    const vehicleId: string = this.availabilityForm.value.vehicleId
      ? this.availabilityForm.value.vehicleId
      : this.dialogData.vehicleId;
    const createUpdateRecurringSchedule =
      this.editChoice !== 1 && this.availabilityForm.value.recurringEvent;
    const removeRecurringSchedule =
      this.editChoice !== 1 &&
      !!this.dialogData.recurringAvailability &&
      !this.availabilityForm.value.recurringEvent;
    let recurringType: RecurringTypeDto = null;
    let daysOfWeek: Array<number> = null;
    let endDate: Date = null;
    let interval: number = null;

    if (this.editChoice !== 1 && this.availabilityForm.value.recurringEvent) {
      recurringType = this.availabilityForm.value.recurringType;
      daysOfWeek = this.availabilityForm.value.daysOfWeek?.sort();
      endDate = this.addTimeToDate(this.availabilityForm.value.endsOn, toTime);
      interval = this.availabilityForm.value.interval;
    }

    if (this.dialogData.availabilityId) {
      this.updateAvailabilities(
        this.dialogData.availabilityId,
        vehicleId,
        fromDate,
        toDate,
        createUpdateRecurringSchedule,
        removeRecurringSchedule,
        this.availabilityForm.value.allDayEvent,
        null,
        recurringType,
        daysOfWeek,
        endDate,
        interval
      );
    } else {
      const type = this.availabilityForm.value.blocked
        ? AvailabilityTypeDto.BLOCKED
        : AvailabilityTypeDto.AVAILABLE;
      this.createAvailabilities(
        vehicleId,
        fromDate,
        toDate,
        type,
        createUpdateRecurringSchedule,
        this.availabilityForm.value.allDayEvent,
        null,
        recurringType,
        daysOfWeek,
        endDate,
        interval
      );
    }
  }

  updateAvailabilities(
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    createUpdateRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): void {
    this.sharedUiSandbox
      .updateAvailabilities(
        this.dialogData.availabilityId,
        vehicleId,
        fromDate,
        toDate,
        createUpdateRecurringSchedule,
        removeRecurringSchedule,
        allDayEvent,
        conflictResolutionDto,
        recurringType,
        daysOfWeek,
        endDate,
        interval
      )
      .subscribe(
        crudAvailabilityResponse => {
          if (
            !conflictResolutionDto &&
            !!crudAvailabilityResponse.conflictsHash
          ) {
            this.manageConflicts(
              crudAvailabilityResponse,
              this.dialogData.availabilityId,
              vehicleId,
              fromDate,
              toDate,
              null,
              createUpdateRecurringSchedule,
              removeRecurringSchedule,
              allDayEvent,
              conflictResolutionDto,
              recurringType,
              daysOfWeek,
              endDate,
              interval
            );
          } else {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_UPDATE-AVAILABILITY',
              true
            );
            this.closeDialogAfterSave.emit();
          }
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_UPDATE-AVAILABILITY'
          );
        }
      );
  }

  createAvailabilities(
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): void {
    this.sharedUiSandbox
      .createAvailabilities(
        vehicleId,
        fromDate,
        toDate,
        type,
        createRecurringSchedule,
        allDayEvent,
        conflictResolutionDto,
        recurringType,
        daysOfWeek,
        endDate,
        interval
      )
      .subscribe(
        crudAvailabilityResponse => {
          if (
            !conflictResolutionDto &&
            !!crudAvailabilityResponse.conflictsHash
          ) {
            this.manageConflicts(
              crudAvailabilityResponse,
              null,
              vehicleId,
              fromDate,
              toDate,
              type,
              createRecurringSchedule,
              null,
              allDayEvent,
              conflictResolutionDto,
              recurringType,
              daysOfWeek,
              endDate,
              interval
            );
          } else {
            this.toastUtilService.success(
              this.tc + '.SUCCESSFUL_CREATE-AVAILABILITY',
              true
            );
            this.closeDialogAfterSave.emit();
          }
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_CREATE-AVAILABILITY'
          );
        }
      );
  }

  onSaveAvailabilityConflictResolutionDialog(
    dialogConflictResolutionDto: ConflictResolutionDto
  ): void {
    const dialogData = this.availabilityConflictResolutionDialogDataSub$.value;
    if (!dialogData.availabilityId) {
      this.createAvailabilities(
        dialogData.vehicleId,
        dialogData.fromDate,
        dialogData.toDate,
        dialogData.type,
        dialogData.createUpdateRecurringSchedule,
        dialogData.allDayEvent,
        dialogConflictResolutionDto,
        dialogData.recurringType,
        dialogData.daysOfWeek,
        dialogData.endDate,
        dialogData.interval
      );
    } else {
      this.updateAvailabilities(
        this.dialogData.availabilityId,
        dialogData.vehicleId,
        dialogData.fromDate,
        dialogData.toDate,
        dialogData.createUpdateRecurringSchedule,
        dialogData.removeRecurringSchedule,
        dialogData.allDayEvent,
        dialogConflictResolutionDto,
        dialogData.recurringType,
        dialogData.daysOfWeek,
        dialogData.endDate,
        dialogData.interval
      );
    }
  }

  manageConflicts(
    crudAvailabilityResponse: CrudAvailabilityResponseDto,
    availabilityId: string,
    vehicleId: string,
    fromDate: Date,
    toDate: Date,
    type: AvailabilityTypeDto,
    createUpdateRecurringSchedule: boolean,
    removeRecurringSchedule: boolean,
    allDayEvent: boolean,
    conflictResolutionDto: ConflictResolutionDto,
    recurringType?: RecurringTypeDto,
    daysOfWeek?: Array<number>,
    endDate?: Date,
    interval?: number
  ): void {
    const conflictResolutionData: AvailabilityConflictResolutionDialogData = {
      availabilityId: this.dialogData.availabilityId,
      fromDate,
      toDate,
      vehicleId,
      createRecurringSchedule: createUpdateRecurringSchedule,
      removeRecurringSchedule,
      recurringType,
      daysOfWeek,
      endDate,
      interval,
      conflictDetails: this.crudAvailabilityResponseDtoToConflictDetails(
        crudAvailabilityResponse
      ),
      type,
      createUpdateRecurringSchedule,
      allDayEvent
    };
    this.availabilityConflictResolutionDialogDataSub$.next(
      conflictResolutionData
    );
    this.showAvailabilityConflictResolutionDialogSub$.next(true);
  }

  crudAvailabilityResponseDtoToConflictDetails(
    crudAvailabilityResponse: CrudAvailabilityResponseDto
  ): ConflictDetails {
    const availabilityConflicts: Array<AvailabilityConflictDto> = Object.values(
      crudAvailabilityResponse.conflictsByPeriodStart
    );
    const conflictDetails: ConflictDetails = {
      crudAvailabilityResponse,
      availabilities: crudAvailabilityResponse.availabilities,
      preview: new Array<ConflictPeriods>(),
      cancelled: new Array<VehicleEventDto>()
    };
    crudAvailabilityResponse.availabilities.forEach(event =>
      this.updateConflictDetails(conflictDetails, event)
    );
    conflictDetails.availabilities = conflictDetails.availabilities.sort(
      (a, b) => a.period.start.localeCompare(b.period.start)
    );
    conflictDetails.preview = conflictDetails.preview.sort((a, b) =>
      a.period.start.localeCompare(b.period.start)
    );
    conflictDetails.cancelled = conflictDetails.cancelled.sort((a, b) =>
      a.period.start.localeCompare(b.period.start)
    );
    return conflictDetails;
  }

  updateConflictDetails(
    conflictDetails: ConflictDetails,
    availability: VehicleEventDto
  ): void {
    if (
      conflictDetails.crudAvailabilityResponse.conflictsByPeriodStart.hasOwnProperty(
        availability.period.start
      )
    ) {
      const availabilityConflict: AvailabilityConflictDto =
        conflictDetails.crudAvailabilityResponse.conflictsByPeriodStart[
          availability.period.start
        ];
      if (availabilityConflict.splitPeriods?.length > 0) {
        availabilityConflict.splitPeriods.forEach(period =>
          conflictDetails.preview.push({
            period,
            vehicleEvent: availability
          })
        );
      }
      if (availabilityConflict.conflictingEvents?.length > 0) {
        availabilityConflict.conflictingEvents.forEach(event => {
          const eventFound = conflictDetails.cancelled.find(
            cancelledEvent =>
              cancelledEvent.type2 === VehicleEventTypeDto.BOOKING &&
              cancelledEvent.id === event.id
          );
          if (!eventFound) {
            conflictDetails.preview.push({
              period: event.period,
              vehicleEvent: event
            });
            conflictDetails.cancelled.push(event);
          }
        });
      }
    } else {
      conflictDetails.preview.push({
        period: availability.period,
        vehicleEvent: availability
      });
    }
  }

  onOkAvailabilityRemoveDialog(removeChoice: number): void {
    this.sharedUiSandbox
      .removeAvailability(this.dialogData.availabilityId, removeChoice === 2)
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(
        availabilityDto => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_REMOVE-AVAILABILITY',
            true
          );
          this.showAvailabilityRemoveDialog = false;
          this.closeDialogAfterSave.emit();
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_REMOVE-AVAILABILITY'
          );
        }
      );
  }

  onRemoveClick(): void {
    const data: AvailabilityRemoveDialogData = {
      availabilityId: this.dialogData.availabilityId,
      vehicleId: this.dialogData.vehicleId,
      recurringAvailabilityId: this.dialogData.recurringAvailabilityDto?.id
    };
    this.availabilityRemoveDialogData = data;
    this.showAvailabilityRemoveDialog = true;
  }

  onFromDateChange(date: Date): void {
    if (
      (!this.availabilityForm.value.toDate && !!date) ||
      date?.getTime() > this.availabilityForm.value.toDate?.getTime()
    ) {
      const toDate = this.availabilityForm.get('toDate');
      toDate.setValue(new Date(date.getTime()));
    }
  }

  onToDateChange(date: Date): void {
    if (
      (!this.availabilityForm.value.fromDate && !!date) ||
      date?.getTime() < this.availabilityForm.value.fromDate?.getTime()
    ) {
      const fromDate = this.availabilityForm.get('fromDate');
      fromDate.setValue(new Date(date.getTime()));
    }
  }

  onVehicleChange(vehicleId: string): void {
    const vehicle = this.dialogData.vehicles?.find(v => v.id === vehicleId);
    if (!!vehicle) {
      const blocked = this.availabilityForm.get('blocked');
      blocked.setValue(vehicle.alwaysAvailable);
    }
  }

  vehicleValueFn = v => v?.id;
  vehicleLabelFn = v => v?.name;
  translatedDataValueFn = t => t?.value;
  translatedDataLabelFn = t => t?.translation;
}
