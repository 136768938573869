import { BookingRequestWithStyle } from '../../types/booking-request-with-style.type';
import { BookingRequest } from '../../types/booking-request.type';
import { Event } from '../../types/event.type';
import { Vehicle } from '../../types/vehicle.type';
import { EventUtils } from './event.utils';

export function createBookingRequestWithStyle(
  bookingRequest: BookingRequest,
  msPerPixel: number,
  schedulerDateFrom: Date,
  schedulerDateTo: Date
): BookingRequestWithStyle {
  return {
    ...bookingRequest,
    needs: {
      ...bookingRequest.needs
    },
    posAndSize: EventUtils.getLeftAndWidth(
      bookingRequest as Event,
      msPerPixel,
      schedulerDateFrom,
      schedulerDateTo
    )
  };
}

// match if the fields of the booking request are similar for the vehicle
export function isBookingRequestMatchingWithVehicle(
  bookingRequest: BookingRequest,
  vehicle: Vehicle
): boolean {
  const testedFields = ['category', 'color', 'gear'];

  for (const field of testedFields) {
    if (
      bookingRequest.needs[field] &&
      bookingRequest.needs[field] !== vehicle[field]
    ) {
      return false;
    }
  }
  return true;
}
