import { Pipe, PipeTransform } from '@angular/core';
import { GpsCoordinateDto } from '../../../../client';

@Pipe({
  name: 'gpsCoordinateToLatLngLiteral'
})
export class GpsCoordinateToLatLngLiteralPipe implements PipeTransform {
  transform(gpsCoordinate: GpsCoordinateDto): google.maps.LatLngLiteral {
    if (!gpsCoordinate) {
      return null;
    }
    return {
      lat: gpsCoordinate.latitude,
      lng: gpsCoordinate.longitude
    } as google.maps.LatLngLiteral;
  }
}
