import { Component } from '@angular/core';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@Component({
  selector: 'sof-organization-vehicle-group-list-view',
  template: `
    <ng-container>
      <sof-organization-vehicle-group-list
        [tc]="tc"
        [vehicleGroups]="vehicleGroups$ | async"
      ></sof-organization-vehicle-group-list>
      <router-outlet></router-outlet>
    </ng-container>
  `
})
export class OrganizationVehicleGroupListViewComponent {
  tc = 'ORGANIZATION-MANAGEMENT_ORGANIZATION-VEHICLE-GROUP-LIST';

  vehicleGroups$ = this.organizationViewHelper.vehicleGroups$;

  constructor(private organizationViewHelper: OrganizationViewHelper) {}
}
