import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { VehicleSearchComponent } from './vehicle-search.component';
import { VehicleSearchCriteriaComponent } from './component/vehicle-search-criteria/vehicle-search-criteria.component';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { CollapsableSectionModule } from '@sofico-framework/ui-kit/components/collapsable-section';
import { InputCheckboxModule } from '@sofico-framework/ui-kit/components/input-checkbox';
import { InputSliderModule } from '@sofico-framework/ui-kit/components/input-slider';
import { InputSwitchModule } from '@sofico-framework/ui-kit/components/input-switch';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { SharedUiModule } from '../../shared-ui.module';
import { MultipleChoiceListComponent } from './component/multiple-choice-list/multiple-choice-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { IntentListModule } from '../intent-list/intent-list.module';

@NgModule({
  imports: [
    CommonModule,
    InputSliderModule,
    SharedUiModule,
    LicensePlateComponentModule,
    TranslateModule.forChild(),
    IntentListModule,
    SvgIconModule,
    CollapsableSectionModule,
    InputSwitchModule,
    NzDividerModule,
    InputCheckboxModule,
    ButtonModule
  ],
  declarations: [
    VehicleSearchComponent,
    VehicleSearchCriteriaComponent,
    MultipleChoiceListComponent
  ],
  exports: [VehicleSearchComponent]
})
export class VehicleSearchModule {}
