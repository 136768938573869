import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sofico-framework/authentication';
import { DamagesAuthorizationGuard } from '../guards/damages-authorization.guard';
import { DamageDetailViewComponent } from './views/damage-detail-view/damage-detail-view.component';
import { DamageViewComponent } from './views/damage-view/damage-view.component';

const routes: Routes = [
  {
    path: 'detail-damage/:vehicleId/:damageId',
    component: DamageDetailViewComponent,
    canActivate: [AuthenticatedGuard, DamagesAuthorizationGuard]
  },
  {
    path: '',
    component: DamageViewComponent,
    canActivate: [AuthenticatedGuard, DamagesAuthorizationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DamageManagementRoutingModule {}
