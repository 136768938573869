import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { take } from 'rxjs/operators';
import { UpdateDriverGradeRequestDto } from  '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';
import { OrganizationDriverGradeDetailViewHelper } from '../../view-helpers/organization-driver-grade-detail-view.helper';

@UntilDestroy()
@Component({
  selector: 'sof-edit-driver-grade-dialog-view',
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="tc + '.EDIT-DRIVER-GRADE' | translate"
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="driverGradeForm" class="flex-fill">
            <sof-input-wrapper [label]="tc + '.DRIVER-GRADE-NAME' | translate">
              <sof-input-text
                sofFocus
                [sofInput]
                [formControl]="driverGradeForm?.controls?.name"
              >
              </sof-input-text>
            </sof-input-wrapper>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button sofButton routerLink=".." class="general-action-button">
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onSaveClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.SAVE' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class EditDriverGradeDialogViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_EDIT-DRIVER-GRADE-DIALOG';
  driverGradeForm: FormGroup = this.fb.group({
    name: ['', Validators.required]
  });
  driverGradeId: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private osb: OrganizationManagementSandbox,
    private toastUtilService: ToastUtilService,
    private vh: OrganizationDriverGradeDetailViewHelper
  ) {}

  ngOnInit(): void {
    this.vh.driverGrade$
      .pipe(take(1), takeUntilDestroy(this))
      .subscribe(driverGradeDto => {
        this.driverGradeId = driverGradeDto.id;
        this.driverGradeForm.controls.name.setValue(driverGradeDto.name);
      });
  }

  onSaveClick(): void {
    if (!this.driverGradeForm.valid) {
      return;
    }

    const updateDriverGradeRequestDto: UpdateDriverGradeRequestDto = {
      driverGradeId: this.driverGradeId,
      name: this.driverGradeForm.value.name
    };
    this.osb
      .updateDriverGrade(updateDriverGradeRequestDto)
      .pipe(takeUntilDestroy(this))
      .subscribe(
        driverGrade => {
          this.toastUtilService.success(
            this.tc + '.SUCCESSFUL_UPDATE-DRIVER-GRADE',
            true
          );
          this.router
            .navigate(['..'], {
              relativeTo: this.activatedRoute
            })
            .then(() => {
              this.vh.triggerDriverGrade();
            });
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_UPDATE-DRIVER-GRADE'
          );
        }
      );
  }
}
