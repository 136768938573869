/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConflictResolutionModeDto = 'CANCEL_BOOKINGS' | 'SPLIT';

export const ConflictResolutionModeDto = {
    CANCELBOOKINGS: 'CANCEL_BOOKINGS' as ConflictResolutionModeDto,
    SPLIT: 'SPLIT' as ConflictResolutionModeDto
};

