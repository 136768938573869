export function isDateInPeriod(
  date: Date,
  fromDate: Date,
  toDate: Date
): boolean {
  if (date == null) {
    return false;
  }
  return (!fromDate || date >= fromDate) && (!toDate || date <= toDate);
}

export function isPeriodInPeriod(
  fromDateP1: Date,
  toDateP1: Date,
  fromDateP2: Date,
  toDateP2: Date
): boolean {
  return (
    isDateInPeriod(fromDateP1, fromDateP2, toDateP2) &&
    isDateInPeriod(toDateP1, fromDateP2, toDateP2)
  );
}

export function isPeriodOverlapPeriod(
  fromDateP1: Date,
  toDateP1: Date,
  fromDateP2: Date,
  toDateP2: Date
): boolean {
  if (!!fromDateP1 && !!toDateP1 && !!fromDateP2 && !!toDateP2) {
    return fromDateP1 <= toDateP2 && toDateP1 >= fromDateP2;
  }
  return false;
}
