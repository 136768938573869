import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import { hotSafe, WindowRefService } from '@sofico-framework/utils';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import {
  delay,
  filter,
  map,
  mapTo,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { NavItem } from './types/nav-item.type';

@Component({
  selector: 'sof-batt-top-bar-nav',
  styleUrls: ['./top-bar-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <nav
      (mouseenter)="enterNav$.next()"
      (mouseleave)="leaveNav$.next()"
      class="navbar navbar-expand sof-navbar-light"
    >
      <div class="container">
        <ul class="navbar-nav">
          <li
            class="nav-item dropdown"
            *ngFor="let menuItem of menuItems; trackBy: trackByFn"
            routerLinkActive="active"
          >
            <div *ngIf="menuItem.displayBubble | async" class="bubble"></div>
            <a
              class="sof-nav-link nav-link"
              [routerLink]="menuItem.routerLink"
              (click)="toggle$.next()"
              (mouseenter)="toggle$.next()"
              *ngIf="!menuItem?.children"
            >
              <ng-container
                *ngTemplateOutlet="innerText; context: { $implicit: menuItem }"
              ></ng-container>
            </a>
            <a
              class="sof-nav-link nav-link clickable"
              [class.active-menu]="(opened$ | async) === menuItem"
              href="javascript:void(0)"
              (click)="toggle$.next(menuItem)"
              (mouseenter)="toggle$.next(menuItem)"
              *ngIf="menuItem?.children"
            >
              <ng-container
                *ngTemplateOutlet="innerText; context: { $implicit: menuItem }"
              ></ng-container>
            </a>
            <div
              class="dropdown-menu"
              [class.open-dropdown]="(opened$ | async) === menuItem"
            >
              <ng-container
                *ngFor="
                  let subMenuItem of menuItem?.children;
                  trackBy: trackByFn
                "
              >
                <div
                  *ngIf="subMenuItem?.divider"
                  class="dropdown-divider"
                ></div>
                <a
                  *ngIf="!subMenuItem?.divider"
                  routerLinkActive="active"
                  class="dropdown-item"
                  [routerLink]="subMenuItem.routerLink"
                  (click)="toggle$.next()"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      innerText;
                      context: { $implicit: subMenuItem }
                    "
                  ></ng-container>
                </a>
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <ng-template #innerText let-item>
      <sof-svg-icon
        *ngIf="item.icon"
        [ngClass]="item.iconClasses"
        [icon]="item.icon"
        [size]="!!item.iconSize ? item.iconSize : '16'"
      ></sof-svg-icon>
      <div>
        {{
          item?.label
            ? (tc + '.' + item.label | translate: item.params)
            : item.translation
        }}
      </div>
    </ng-template>
  `
})
export class TopBarNavComponent implements OnInit {
  /**
   * The translation context
   */
  @Input() tc: string;
  /**
   * MenuItems - The menu only support one level down (children). NavItem with children itself will to trigger navigate.
   * Dividers only work in the dropdown(s)
   */
  @Input() menuItems: NavItem[];

  showMobile = false;
  // Source
  toggle$ = new Subject<NavItem>();
  leaveNav$ = new Subject();
  enterNav$ = new Subject();

  // Intermediate
  clickedOutside$: Observable<undefined>;
  awayTooLong$: Observable<undefined>;

  // Presentation
  opened$: Observable<NavItem>;
  trackByFn = i => i;

  constructor(
    private elRef: ElementRef,
    private windowRefService: WindowRefService
  ) {}

  ngOnInit(): void {
    // Intermediate
    this.clickedOutside$ = this.getClickedOutside$();
    this.awayTooLong$ = this.getAwayTooLong$();
    // Presentation
    this.opened$ = this.getOpened$();
  }

  toggleMobileMenu(): void {
    this.showMobile = !this.showMobile;
  }

  // Intermediate
  /**
   * Emits undefined if click on window was not this component
   */
  private getClickedOutside$(): Observable<undefined> {
    return merge(
      fromEvent(this.windowRefService.nativeWindow, 'click'),
      fromEvent(this.windowRefService.nativeWindow, 'touchstart')
    ).pipe(
      map((x: any) => {
        let currentElem = x.target;
        let found = false;
        while (!found && currentElem) {
          found = currentElem === this.elRef.nativeElement;
          currentElem = currentElem.parentElement;
        }
        return found;
      }),
      filter(x => !x),
      mapTo(undefined)
    );
  }

  /**
   * Emits undefined if mouse leaves nav element longer than 1000ms
   */
  private getAwayTooLong$(): Observable<undefined> {
    return this.leaveNav$.pipe(
      switchMap(() => of(undefined).pipe(delay(250), takeUntil(this.enterNav$)))
    );
  }

  // Presentation
  private getOpened$(): Observable<NavItem> {
    return merge(this.awayTooLong$, this.clickedOutside$, this.toggle$).pipe(
      hotSafe()
    );
  }
}
