import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';
import { DropDownMenuItemAlt } from '../../../shared-ui/components/drop-down-menu-alt/types/drop-down-menu-item-alt.type';
import { DropDownConfigAlt } from '../../../shared-ui/components/drop-down-alt/types/drop-down-config-alt.type';
import { ViewMode } from '../../../types/view-mode.type';
import { fromEvent } from 'rxjs';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { DateFormatEnum, WindowRefService } from '@sofico-framework/utils';
import { OrganizationReferenceDto } from '../../../../../client';

@UntilDestroy()
@Component({
  selector: 'sof-scheduler-toolbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./toolbar.component.scss'],
  template: `
    <div class="toolbar-main-container">
      <div class="search">
        <input
          [placeholder]="tc + '.SEARCH-VEHICLE' | translate"
          autocomplete="off"
          class="search-input"
          (input)="onSearchChange($event)"
        />
      </div>
      <div class="organization-container">
        <sof-input-switch
          [label]="tc + '.MY-BOOKINGS' | translate"
          [selected]="showMyBookings"
          (changeValue)="setShowMyBookings.emit($event)"
        ></sof-input-switch>
      </div>
      <div class="organization-container">
        <sof-input-single-select
          [tc]="tc"
          [formControl]="formControlOrganization"
          [isDisabled]="
            organizations?.length === 1 &&
            organizations[0].id === internalOrganizationId
          "
          [clearable]="false"
          [options]="organizations"
          [selectorValue]="organizationValueFn"
          [selectorLabel]="organizationLabelFn"
          (changeValue)="setCurrentOrganizationId.emit($event)"
        >
        </sof-input-single-select>
      </div>
      <div class="time-control">
        <div class="date-container">
          <sof-input-date-picker
            [formControl]="formControlDateFrom"
            [dateFormat]="dateFormat"
            [showToday]="true"
            (changeValue)="onChangeDateFrom($event)"
          ></sof-input-date-picker>
          <button
            sofButton
            class="round-icon-button"
            aria-label="Previous period"
            (click)="setPreviousPeriod()"
          >
            <sof-svg-icon icon="batt-icon-arrow-left" size="24"></sof-svg-icon>
          </button>
          <button
            sofButton
            class="round-icon-button"
            aria-label="Next period"
            (click)="setNextPeriod()"
          >
            <sof-svg-icon icon="batt-icon-arrow-right" size="24"></sof-svg-icon>
          </button>
        </div>
        <div class="view-mode-group">
          <button
            sofButton
            class="round-mini-fab-button"
            *ngFor="let viewMode of viewModes; trackBy: trackByIndex"
            [class.primary]="viewMode.label == actualViewMode?.label"
            (click)="onSetViewMode($event, viewMode)"
          >
            {{ viewMode.label }}
          </button>
        </div>
      </div>
      <div class="map-toggle-container">
        <sof-input-switch
          *ngIf="googleApiLoaded"
          [label]="tc + '.MAP' | translate"
          [selected]="showMap"
          (changeValue)="setShowMap.emit($event)"
        ></sof-input-switch>
      </div>
      <div class="action-button-container">
        <sof-drop-down-menu-alt
          [tc]="tc"
          [dropDownConfig]="dropDownConfig$ | async"
          [menuItems]="menuItems"
        >
        </sof-drop-down-menu-alt>
      </div>
    </div>
  `
})
export class ToolbarComponent implements OnInit {
  @Input() tc: string;
  @Input() set dateFrom(dateFrom: Date) {
    this.internalDateFrom = dateFrom;
    this.formControlDateFrom.setValue(dateFrom);
  }
  @Input() viewModes: ViewMode[];
  @Input() showMap: boolean;
  @Input() actualViewMode: ViewMode;
  @Input() set organizationId(organizationId: string) {
    this.internalOrganizationId = organizationId;
    this.formControlOrganization.setValue(organizationId);
  }
  @Input() organizations: Array<OrganizationReferenceDto>;
  @Input() showMyBookings: boolean;
  @Input() dateFormat: DateFormatEnum;
  @Input() googleApiLoaded: boolean;

  @Output() setDateFrom: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() setViewMode: EventEmitter<ViewMode> = new EventEmitter<ViewMode>();
  @Output() setShowMap: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addBooking: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  addAvailability: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newSearchText: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  showApplyVehicleFiltersDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  setCurrentOrganizationId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  setShowMyBookings: EventEmitter<boolean> = new EventEmitter<boolean>();

  formControlOrganization = this.fb.control(null);
  internalOrganizationId: string;
  formControlDateFrom = this.fb.control(null);
  internalDateFrom: Date;

  dropDownConfig$: Observable<DropDownConfigAlt>;
  menuItems: DropDownMenuItemAlt[] = [
    {
      label: 'BOOKING',
      click: () => {
        this.addBooking.emit(true);
      }
    },
    {
      label: 'UNAVAILABILITY',
      click: () => {
        this.addAvailability.emit(true);
      }
    },
    {
      label: 'VEHICLE-FILTERS',
      click: () => {
        this.showApplyVehicleFiltersDialog.emit(true);
      }
    }
  ];

  constructor(
    private windowRefService: WindowRefService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    fromEvent(this.windowRefService.nativeWindow, 'keydown')
      .pipe(takeUntilDestroy(this))
      .subscribe((event: KeyboardEvent) => {
        // if the event target is not the body or the drop down view mode
        // we quit
        if (
          !(
            (event.target as HTMLElement).id === 'viewModeDropdown' ||
            (event.target as HTMLElement).tagName === 'BODY'
          )
        ) {
          return;
        }

        if (event.code === 'ArrowLeft') {
          this.setPreviousPeriod();
        } else if (event.code === 'ArrowRight') {
          this.setNextPeriod();
        } else {
          if (this.viewModes) {
            const filterResult = this.viewModes.filter(
              viewMode => viewMode.shortcut === event.key
            );

            if (filterResult.length !== 0) {
              this.onSetViewMode(null, filterResult[0]);
            }
          }
        }
      });
    this.dropDownConfig$ = this.getDropDownConfig$();
  }

  private getDropDownConfig$(): Observable<DropDownConfigAlt> {
    return this.translateService.stream(this.tc + '.TOOLBAR-ACTIONS').pipe(
      map(translation => {
        return {
          toggleButtonIcon: 'batt-icon-plus',
          toggleButtonClasses: 'main-action-button',
          toggleButtonIconSize: '16',
          toggleButtonIconClasses: 'sof-icon-white',
          toggleButtonText: translation,
          dropDownPlacement: 'bottomRight'
        };
      })
    );
  }

  trackByIndex = i => i;

  onChangeDateFrom(date: Date): void {
    if (date != null) {
      this.onSetNewDateFrom(date);
    }
  }

  onSetNewDateFrom(newDateFrom: Date): void {
    this.setDateFrom.emit(newDateFrom);
  }

  onSetViewMode(event, newViewMode: ViewMode): void {
    if (event) {
      // To avoid form submit
      event.preventDefault();
    }
    this.setViewMode.emit(newViewMode);
  }

  setPreviousPeriod(): void {
    // calculation of the new date from
    const duration = this.actualViewMode.duration.getDay();
    const newDateFrom = new Date(this.internalDateFrom);
    newDateFrom.setDate(newDateFrom.getDate() - duration);
    this.onSetNewDateFrom(newDateFrom);
  }

  setNextPeriod(): void {
    // calculation of the new date from
    const duration = this.actualViewMode.duration.getDay();
    const newDateFrom = new Date(this.internalDateFrom);
    newDateFrom.setDate(newDateFrom.getDate() + duration);
    this.onSetNewDateFrom(newDateFrom);
  }

  onSearchChange(event): void {
    this.newSearchText.emit(event.currentTarget.value);
  }

  organizationValueFn = o => o?.id;
  organizationLabelFn = o => o?.name;
}
