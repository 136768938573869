/**
 * Web-Bff For Booking Platform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConversationTopicDto = 'APPROVAL' | 'NEXT_BOOKING' | 'PREVIOUS_BOOKING';

export const ConversationTopicDto = {
    APPROVAL: 'APPROVAL' as ConversationTopicDto,
    NEXTBOOKING: 'NEXT_BOOKING' as ConversationTopicDto,
    PREVIOUSBOOKING: 'PREVIOUS_BOOKING' as ConversationTopicDto
};

