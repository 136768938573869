import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventActionDto } from '../../../../../client';
import { BookingDialogBookingEvent } from '../../../types/booking-dialog-booking-event.type';

@Component({
  selector: 'sof-booking-dialog-events-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="history-cards-container">
      <div class="sof-card history-card" *ngFor="let event of events">
        <div class="history-card-row">
          <span class="history-card-perform-by">
            <sof-user-avatar
              [userImage]="event.performedBy | toUserImage: '40'"
            ></sof-user-avatar>
            {{ event.performedBy.displayName }}
          </span>
          <span class="history-card-action"> - {{ event.actionLabel }} - </span>
          <sof-date-hour
            [date]="event.dateCreated"
            direction="row"
          ></sof-date-hour>
        </div>
        <div
          class="history-card-row"
          *ngIf="
            event.action === EVENT_ACTION_CREATE ||
            event.action === EVENT_ACTION_UPDATE ||
            event.action === EVENT_ACTION_STOP
          "
        >
          <span
            class="label"
            *ngIf="
              event.action === EVENT_ACTION_CREATE ||
              event.action === EVENT_ACTION_UPDATE
            "
          >
            Planned period :
          </span>
          <span class="label" *ngIf="event.action === EVENT_ACTION_STOP">
            Usage period :
          </span>
          <sof-date-hour
            [date]="event.period.start"
            direction="row"
            [highlighted]="!!event.prevStartDate"
          >
          </sof-date-hour>
          <span *ngIf="event.action !== EVENT_ACTION_START">-</span>
          <sof-date-hour
            [date]="event.period.end"
            direction="row"
            [highlighted]="!!event.prevEndDate"
          >
          </sof-date-hour>
        </div>
        <div *ngIf="displayAdminComments" class="history-card-row align-top">
          <span class="label">Admin comments:</span>
          <span
            class="admin-comments"
            [ngClass]="{ highlighted: event.highlightAdminComments }"
            >{{ event.adminComments }}</span
          >
        </div>
        <div class="history-card-row" *ngIf="!!event.rootEvent">
          <span *ngIf="!!event.rootEvent.bookingId"> Due to booking </span>
          <span *ngIf="!!event.rootEvent.availabilityId">
            Due to activity
          </span>
          <span class="history-card-action">
            - {{ event.rootActionLabel }} -
          </span>
          <sof-date-hour
            [date]="event.rootEvent.period.start"
            direction="row"
          ></sof-date-hour>
          -
          <sof-date-hour
            [date]="event.rootEvent.period.end"
            direction="row"
          ></sof-date-hour>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./booking-dialog.component.scss']
})
export class BookingDialogEventsTabComponent {
  EVENT_ACTION_CREATE = EventActionDto.CREATE;
  EVENT_ACTION_UPDATE = EventActionDto.UPDATE;
  EVENT_ACTION_START = EventActionDto.START;
  EVENT_ACTION_STOP = EventActionDto.STOP;

  @Input() tc: string;
  @Input() events: BookingDialogBookingEvent[];
  @Input() displayAdminComments: boolean;
}
