import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MissingTranslationHandler,
  TranslateModule
} from '@ngx-translate/core';
import { BreadcrumbModule } from '@sofico-framework/ui-kit/components/breadcrumb';
import { ButtonModule } from '@sofico-framework/ui-kit/components/button';
import { DialogModule } from '@sofico-framework/ui-kit/components/dialog';
import { FocusModule } from '@sofico-framework/ui-kit/directives/focus';
import { InputMultiSelectModule } from '@sofico-framework/ui-kit/components/input-multi-select';
import { InputSingleSelectModule } from '@sofico-framework/ui-kit/components/input-single-select';
import { InputSliderModule } from '@sofico-framework/ui-kit/components/input-slider';
import { InputTextModule } from '@sofico-framework/ui-kit/components/input-text';
import { LicensePlateComponentModule } from '@sofico-framework/ui-kit/components/license-plate';
import { SoficoFormsModule } from '@sofico-framework/ui-kit/components/sofico-forms';
import { SvgIconModule } from '@sofico-framework/ui-kit/components/svg-icon';
import { TabsModule } from '@sofico-framework/ui-kit/components/tabs';
import { WizardNavBarModule } from '@sofico-framework/ui-kit/components/wizard-nav-bar';
import { CustomTranslationHandler } from '@sofico-framework/utils';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { OrganizationsAuthorizationGuard } from '../guards/organizations-authorization.guard';
import { OrganizationService } from '../services/organization.service';
import { UserService } from '../services/user.service';
import { VehicleGroupService } from '../services/vehicle-group.service';
import { LoadingSpinnerModule } from '../shared-ui/components/loading-spinner/loading-spinner.module';
import { SharedUiModule } from '../shared-ui/shared-ui.module';
import { UserManagementSandbox } from '../user-management-ui/user-management.sandbox';
import { DriverGradeFilterListComponent } from './components/driver-grade-filter-list/driver-grade-filter-list.component';
import { DriverGradeListComponent } from './components/driver-grade-list/driver-grade-list.component';
import { DriverGradeComponent } from './components/driver-grade/driver-grade.component';
import { OrganizationAvatarComponent } from './components/organization-avatar/organization-avatar.component';
import { OrganizationDriverGradeListComponent } from './components/organization-driver-grade-list/organization-driver-grade-list.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { OrganizationMemberDetailComponent } from './components/organization-member-detail/organization-member-detail.component';
import { OrganizationMemberListComponent } from './components/organization-member-list/organization-member-list.component';
import { OrganizationShortInfoComponent } from './components/organization-short-info/organization-short-info.component';
import { OrganizationVehicleGroupListComponent } from './components/organization-vehicle-group-list/organization-vehicle-group-list.component';
import { VehicleGroupVehicleListComponent } from './components/vehicle-group-vehicle-list/vehicle-group-vehicle-list.component';
import { OrganizationManagementRoutingModule } from './organization-management-routing.module';
import { OrganizationManagementSandbox } from './organization-management.sandbox';
import { CreateDriverGradeDialogViewComponent } from './views/create-driver-grade-dialog-view/create-driver-grade-dialog-view.component';
import { CreateOrganizationDialogViewComponent } from './views/create-organization-dialog-view/create-organization-dialog-view.component';
import { CreateVehicleGroupDialogViewComponent } from './views/create-vehicle-group-dialog-view/create-vehicle-group-dialog-view.component';
import { DriverGradeFilterDialogViewComponent } from './views/driver-grade-filter-dialog-view/driver-grade-filter-dialog-view.component';
import { EditDriverGradeDialogViewComponent } from './views/edit-driver-grade-dialog-view/edit-driver-grade-dialog-view.component';
import { EditOrganizationDialogViewComponent } from './views/edit-organization-dialog-view/edit-organization-dialog-view.component';
import { EditVehicleGroupDialogViewComponent } from './views/edit-vehicle-group-dialog-view/edit-vehicle-group-dialog-view.component';
import { InviteMemberDialogDriverGradeStepViewComponent } from './views/invite-member-dialog-driver-grade-step-view/invite-member-dialog-driver-grade-step-view.component';
import { InviteMemberDialogEmailAddressStepViewComponent } from './views/invite-member-dialog-email-address-step-view/invite-member-dialog-email-address-step-view.component';
import { InviteMemberDialogUserDataStepViewComponent } from './views/invite-member-dialog-user-data-step-view/invite-member-dialog-user-data-step-view.component';
import { InviteMemberDialogViewComponent } from './views/invite-member-dialog-view/invite-member-dialog-view.component';
import { MemberAddDriverGradeDialogViewComponent } from './views/member-add-driver-grade-dialog-view/member-add-driver-grade-dialog-view.component';
import { MemberViewComponent } from './views/member-view/member-view.component';
import { OrganizationDetailViewComponent } from './views/organization-detail-view/organization-detail-view.component';
import { OrganizationDriverGradeDetailViewComponent } from './views/organization-driver-grade-detail-view/organization-driver-grade-detail-view.component';
import { OrganizationDriverGradeListViewComponent } from './views/organization-driver-grade-list-view/organization-driver-grade-list-view.component';
import { OrganizationManagerListViewComponent } from './views/organization-manager-list-view/organization-manager-list-view.component';
import { OrganizationMemberDetailViewComponent } from './views/organization-member-detail-view/organization-member-detail-view.component';
import { OrganizationMemberListViewComponent } from './views/organization-member-list-view/organization-member-list-view.component';
import { OrganizationVehicleGroupDetailViewComponent } from './views/organization-vehicle-group-detail-view/organization-vehicle-group-detail-view.component';
import { OrganizationVehicleGroupListViewComponent } from './views/organization-vehicle-group-list-view/organization-vehicle-group-list-view.component';
import { OrganizationsViewComponent } from './views/organizations-view/organizations-view.component';
import { VehicleGroupEditVehiclesDialogViewComponent } from './views/vehicle-group-edit-vehicles-dialog-view/vehicle-group-edit-vehicles-dialog-view.component';

@NgModule({
  declarations: [
    OrganizationsViewComponent,
    OrganizationListComponent,
    OrganizationDetailViewComponent,
    MemberViewComponent,
    CreateOrganizationDialogViewComponent,
    EditOrganizationDialogViewComponent,
    OrganizationAvatarComponent,
    OrganizationShortInfoComponent,
    InviteMemberDialogViewComponent,
    OrganizationMemberListComponent,
    OrganizationMemberDetailViewComponent,
    CreateDriverGradeDialogViewComponent,
    EditDriverGradeDialogViewComponent,
    DriverGradeListComponent,
    DriverGradeComponent,
    MemberAddDriverGradeDialogViewComponent,
    OrganizationDriverGradeListComponent,
    OrganizationDriverGradeDetailViewComponent,
    DriverGradeFilterDialogViewComponent,
    DriverGradeFilterListComponent,
    CreateVehicleGroupDialogViewComponent,
    EditVehicleGroupDialogViewComponent,
    OrganizationVehicleGroupListComponent,
    OrganizationVehicleGroupDetailViewComponent,
    VehicleGroupVehicleListComponent,
    VehicleGroupEditVehiclesDialogViewComponent,
    OrganizationMemberListViewComponent,
    OrganizationManagerListViewComponent,
    OrganizationVehicleGroupListViewComponent,
    OrganizationDriverGradeListViewComponent,
    OrganizationMemberDetailComponent,
    InviteMemberDialogUserDataStepViewComponent,
    InviteMemberDialogEmailAddressStepViewComponent,
    InviteMemberDialogDriverGradeStepViewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    FormsModule,
    BreadcrumbModule,
    OrganizationManagementRoutingModule,
    TabsModule,
    ReactiveFormsModule,
    SharedUiModule,
    InputSliderModule,
    LicensePlateComponentModule,
    LoadingSpinnerModule,
    SvgIconModule,
    ButtonModule,
    SoficoFormsModule,
    InputTextModule,
    NzDividerModule,
    InputMultiSelectModule,
    InputSingleSelectModule,
    DialogModule,
    WizardNavBarModule,
    FocusModule
  ],
  exports: [
    OrganizationsViewComponent,
    OrganizationDetailViewComponent,
    MemberViewComponent,
    OrganizationMemberDetailViewComponent
  ],
  providers: [
    OrganizationService,
    VehicleGroupService,
    OrganizationManagementSandbox,
    UserService,
    UserManagementSandbox,
    OrganizationsAuthorizationGuard
  ]
})
export class OrganizationManagementModule {}
