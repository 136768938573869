import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { KeyLabel } from '../../../../../types/key-label.type';

@Component({
  selector: 'sof-multiple-choice-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-input-checkbox
      *ngFor="let choice of choices; trackBy: trackByFn"
      [label]="choice.label"
      [selected]="selectedKeys?.indexOf(choice.key) > -1"
      [isDisabled]="disabled"
      (changeValue)="onChoiceSelect(choice, $event)"
    ></sof-input-checkbox>
  `,
  styleUrls: ['./multiple-choice-list.component.scss']
})
export class MultipleChoiceListComponent {
  @Input() choices: Array<KeyLabel>;
  @Input() selectedKeys: Array<string>;
  @Input() disabled: boolean;

  @Output() selectedKeysChange: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  onChoiceSelect(choice: KeyLabel, selected: boolean): void {
    let keys = [...this.selectedKeys];
    if (selected) {
      keys.push(choice.key);
    } else {
      keys = keys.filter(id => id !== choice.key);
    }
    this.selectedKeysChange.emit(keys);
  }

  trackByFn = i => i;
}
