import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Changes, takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
  BookingUserDto,
  SearchUsersRequestDto
} from '../../../../../client';
import { ToastUtilService } from '../../../services/toast-util.service';
import { SharedUiSandbox } from '../../shared-ui.sandbox';

@UntilDestroy()
@Component({
  selector: 'sof-user-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-user-search-filter-form
      [tc]="tc"
      [filterForm]="filterForm"
      [canCreateUser]="canCreateUser"
      (searchUsers)="triggerSearchUsers()"
      (createUser)="createUser.emit()"
    >
    </sof-user-search-filter-form>
    <sof-user-search-list
      [tc]="tc"
      [users]="usersSub$ | async"
      [canSendOnboardingMail]="canSendOnboardingMail"
      [canEditUser]="canEditUser"
      [showSelectButtonAsPencil]="showSelectButtonAsPencil"
      (editUser)="editUser.emit($event)"
      (sendOnboardingMail)="sendOnboardingMail.emit($event)"
    >
    </sof-user-search-list>
  `,
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnChanges {
  @Input() tc: string;
  @Input() canCreateUser = false;
  @Input() canSendOnboardingMail = false;
  @Input() canEditUser = false;
  @Input() showSelectButtonAsPencil = false;
  @Input() refreshList: Date;

  @Output() createUser: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  editUser: EventEmitter<BookingUserDto> = new EventEmitter<BookingUserDto>();
  @Output()
  sendOnboardingMail: EventEmitter<BookingUserDto> = new EventEmitter<BookingUserDto>();

  @Changes('refreshList') refreshList$: Observable<Date>;

  private searchUsersSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null
  );

  usersSub$: BehaviorSubject<Array<BookingUserDto>> = new BehaviorSubject<
    Array<BookingUserDto>
  >(null);

  public filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private sharedUiSandbox: SharedUiSandbox,
    private toastUtilService: ToastUtilService
  ) {}

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      displayName: '',
      userName: '',
      email: ''
    });

    combineLatest([this.refreshList$, this.searchUsersSub$])
      .pipe(
        map(() => {
          if (!this.filterForm.valid) {
            return of(null);
          }
          const searchUsersRequestDto: SearchUsersRequestDto = {};
          if (!!this.filterForm.value.displayName) {
            searchUsersRequestDto.displayName = this.filterForm.value.displayName;
          }
          if (!!this.filterForm.value.userName) {
            searchUsersRequestDto.userName = this.filterForm.value.userName;
          }
          return searchUsersRequestDto;
        }),
        filter(searchUsersRequestDto => !!searchUsersRequestDto),
        switchMap((searchUsersRequestDto: SearchUsersRequestDto) =>
          this.sharedUiSandbox.searchUsers(searchUsersRequestDto).pipe(take(1))
        ),
        takeUntilDestroy(this)
      )
      .subscribe(
        bookingUserPageDto => {
          this.usersSub$.next(bookingUserPageDto?.users);
        },
        error => {
          this.toastUtilService.showError(
            error,
            this.tc + '.FAILED_SEARCH-USERS'
          );
        }
      );
  }

  triggerSearchUsers(): void {
    this.searchUsersSub$.next(new Date());
  }
}
