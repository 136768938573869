import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { GpsLocationDto } from '../../../../../client';
import { AppConstants } from '../../../app.constants';
import SharedUiUtils from '../../utils/shared-ui.utils';

@Component({
  selector: 'sof-location-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container class="location-selector-container">
      <div class="address-container">
        <div class="address-label-container">
          {{ gpsLocation?.address }}
        </div>
        <button
          sofButton
          class="round-icon-button"
          *ngIf="!disabled"
          (click)="showLocationSearchDialog = true"
        >
          <sof-svg-icon icon="batt-icon-pencil" size="24"></sof-svg-icon>
        </button>
      </div>
      <div class="map-container">
        <google-map
          [options]="options"
          [center]="
            gpsLocation?.gpsCoordinateDto | gpsCoordinateToLatLngLiteral
          "
          width="100%"
          height="100%"
        >
          <map-marker
            *ngIf="!!gpsLocation?.gpsCoordinateDto"
            [position]="
              gpsLocation.gpsCoordinateDto | gpsCoordinateToLatLngLiteral
            "
            [options]="markerOptions"
          >
          </map-marker>
        </google-map>
      </div>
      <sof-location-search-dialog
        *ngIf="showLocationSearchDialog"
        [tc]="tc"
        [gpsLocation]="gpsLocation"
        [locationSearchTitleLabel]="locationSearchTitleLabel"
        (cancelDialog)="showLocationSearchDialog = false"
        (confirmDialog)="
          showLocationSearchDialog = false; newLocationSelected.emit($event)
        "
      ></sof-location-search-dialog>
    </ng-container>
  `,
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() gpsLocation: GpsLocationDto;
  @Input() tc: string;
  @Input() locationSearchTitleLabel: string;

  @Output()
  newLocationSelected: EventEmitter<GpsLocationDto> = new EventEmitter<GpsLocationDto>();

  options: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions;
  showLocationSearchDialog = false;

  ngOnInit(): void {
    this.options = SharedUiUtils.getDefaultMapOptions();
    this.markerOptions = {
      icon: AppConstants.ASSETS_ICON_CAR_AVAILABLE_MARKER
    };
  }
}
