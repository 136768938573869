export class Duration {
  minute: number;
  hour: number;
  day: number;
  week: number;

  constructor(minute = 0, hour = 0, day = 0, week = 0) {
    this.minute = minute;
    this.hour = hour;
    this.day = day;
    this.week = week;
  }

  getMs(): number {
    let ms = this.minute * 1000 * 60;
    ms += this.hour * 1000 * 60 * 60;
    ms += this.day * 1000 * 60 * 60 * 24;
    ms += this.week * 1000 * 60 * 60 * 24 * 7;
    return ms;
  }

  getDay(): number {
    let day = this.day;
    day += this.week * 7;

    return day;
  }

  getWeek(): number {
    return this.week;
  }
}
