const typeCache: { [label: string]: boolean } = {};

function type<T>(label: T | ''): T {
  // this actually checks whether your action type
  // name is unique!
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[label as string] = true;

  return label as T;
}

// action types
export const ActionTypes = {
  // Current user
  SET_CURRENT_USER: type<'SET_CURRENT_USER'>('SET_CURRENT_USER'),
  // vehicles

  SET_VEHICLES: type<'SET_VEHICLES'>('SET_VEHICLES'),

  SET_PRIVATE_USAGES: type<'SET_PRIVATE_USAGES'>('SET_PRIVATE_USAGES'),

  SET_CARS_NO_RESERVATION: type<'SET_CARS_NO_RESERVATION'>(
    'SET_CARS_NO_RESERVATION'
  ),

  // unavailabilities
  SET_UNAVAILABILITIES: type<'SET_UNAVAILABILITIES'>('SET_UNAVAILABILITIES'),

  // availabilities
  SET_AVAILABILITIES: type<'SET_AVAILABILITIES'>('SET_AVAILABILITIES'),

  // bookings

  SET_BOOKINGS: type<'SET_BOOKINGS'>('SET_BOOKINGS'),

  ADD_BOOKING: type<'ADD_BOOKING'>('ADD_BOOKING'),

  UPDATE_BOOKING: type<'UPDATE_BOOKING'>('UPDATE_BOOKING'),

  CANCEL_BOOKING: type<'CANCEL_BOOKING'>('CANCEL_BOOKING'),

  // dates

  SET_DATE_FROM: type<'SET_DATE_FROM'>('SET_DATE_FROM'),

  SET_VIEWMODE: type<'SET_VIEWMODE'>('SET_VIEWMODE'),

  SET_SHOW_MAP: type<'SET_SHOW_MAP'>('SET_SHOW_MAP'),

  SET_CURRENT_ORGANIZATION_ID: type<'SET_CURRENT_ORGANIZATION_ID'>(
    'SET_CURRENT_ORGANIZATION_ID'
  ),

  SET_SHOW_MY_BOOKINGS: type<'SET_SHOW_MY_BOOKINGS'>('SET_SHOW_MY_BOOKINGS'),

  // filters

  SET_ACTIVE_SCHEDULER_FILTERS: type<'SET_ACTIVE_SCHEDULER_FILTERS'>(
    'SET_ACTIVE_SCHEDULER_FILTERS'
  ),

  // user settings
  SET_TOOLTIP_SETTINGS: type<'SET_TOOLTIP_SETTINGS'>('SET_TOOLTIP_SETTINGS'),

  SET_VEHICLE_CARD_FIELDS_TO_DISPLAY: type<'SET_VEHICLE_CARD_FIELDS_TO_DISPLAY'>(
    'SET_VEHICLE_CARD_FIELDS_TO_DISPLAY'
  ),

  SET_USER_SETTINGS: type<'SET_USER_SETTINGS'>('SET_USER_SETTINGS'),

  // booking requests
  SET_BOOKING_REQUESTS: type<'SET_BOOKING_REQUESTS'>('SET_BOOKING_REQUESTS'),

  // Conversations
  SET_CONVERSATIONS: type<'SET_CONVERSATIONS'>('SET_CONVERSATIONS'),
  UPDATE_CONVERSATION: type<'UPDATE_CONVERSATION'>('UPDATE_CONVERSATION'),
  APPEND_NEW_MESSAGES: type<'APPEND_NEW_MESSAGES'>('APPEND_NEW_MESSAGES'),
  CLEAR_MESSAGES: type<'CLEAR_MESSAGES'>('CLEAR_MESSAGES'),
  NEW_MESSAGE: type<'NEW_MESSAGE'>('NEW_MESSAGE'),
  UPDATE_MESSAGE: type<'UPDATE_MESSAGE'>('UPDATE_MESSAGE')
};
