import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DriverGradeDto,
  OrganizationDto
} from  '../../../../../client';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';

@Component({
  selector: 'sof-organization-driver-grade-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <sof-no-result
        *ngIf="organization?.driverGrades?.length === 0"
        [tc]="tc"
        noResultLabel="NO-RESULT-DRIVER-GRADES"
      ></sof-no-result>
      <div
        class="sof-card"
        *ngFor="let driverGrade of organization?.driverGrades"
        (click)="displayOrganizationDriverGradeDetail(driverGrade)"
      >
        <div class="driver-grade-container">
          <sof-driver-grade [driverGrade]="driverGrade"></sof-driver-grade>
        </div>
        <div class="spacer-container"></div>
        <button
          sofButton
          class="round-icon-button"
          aria-label="Edit organization"
        >
          <sof-svg-icon icon="batt-icon-chevron-right" size="24"></sof-svg-icon>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-driver-grade-list.component.scss']
})
export class OrganizationDriverGradeListComponent {
  @Input() tc: string;
  @Input() organization: OrganizationDto;

  constructor(private osb: OrganizationManagementSandbox) {}

  displayOrganizationDriverGradeDetail(driverGrade: DriverGradeDto): void {
    this.osb.navigateToDetailOrganizationDriverGradeScreen(
      this.organization.id,
      driverGrade.id
    );
  }
}
