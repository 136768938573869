import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IntentChange } from '../../../types/intent-change.type';

@Component({
  selector: 'sof-intent-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      sofButton
      [ngClass]="{ selected: selected, small: smallButton }"
      (click)="onClick()"
      [disabled]="disabled"
      [icon]="svgIcon"
      [iconSize]="smallButton ? '32' : '48'"
    >
      <div class="intent-button-label">{{ label }}</div>
    </button>
  `,
  styleUrls: ['./intent-button.component.scss']
})
export class IntentButtonComponent {
  @Input() disabled: boolean;
  @Input() svgIcon: string;
  @Input() selected = false;
  @Input() value: string;
  @Input() label: string;
  @Input() smallButton: boolean;

  @Output()
  selectedChange: EventEmitter<IntentChange> = new EventEmitter<IntentChange>();

  onClick(): void {
    this.selected = !this.selected;
    this.selectedChange.emit({ value: this.value, selected: this.selected });
  }
}
