import { UnavailabilityEvent } from '../../types/unavailability-event.type';
import { UnavailabilitiesActions } from '../actions/unavailabilities.actions';
import { ActionTypes } from '../action-types';

export function unavailabilitiesReducer(
  state: UnavailabilityEvent[] = [],
  action: UnavailabilitiesActions
): UnavailabilityEvent[] {
  switch (action.type) {
    case ActionTypes.SET_UNAVAILABILITIES:
      return [...action.payload.unavailabilities];
  }
  return state;
}
