import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { SelectionListOption } from './types/selection-list-option.type';

@Component({
  selector: 'sof-selection-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!!internalOptions && optionTemplateRef">
      <div
        *ngFor="let option of internalOptions"
        (click)="onOptionClick(option)"
        class="selection-list-option"
        [class.selected]="selectedOption === option"
      >
        <div class="selection-list-item-content">
          <ng-container
            [ngTemplateOutlet]="optionTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: option.value }"
          ></ng-container>
          <sof-input-checkbox
            *ngIf="!!multiple"
            [selected]="option.selected"
          ></sof-input-checkbox>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./selection-list.component.scss']
})
export class SelectionListComponent {
  @Input() set options(options: any[]) {
    this.internalOptions = options?.map((option, index) => {
      return {
        internalId: index,
        value: option,
        selected: !!option?.selected
      };
    });
  }
  @Input() multiple = false;
  @Output()
  selectionChange: EventEmitter<SelectionListOption> = new EventEmitter<SelectionListOption>();

  @ContentChild('option', { static: false })
  optionTemplateRef: TemplateRef<any>;

  internalOptions: SelectionListOption[];
  selectedOption: SelectionListOption;

  onOptionClick(option: SelectionListOption): void {
    this.internalOptions = this.internalOptions.map(internalOption => {
      if (internalOption.internalId === option.internalId) {
        this.selectedOption = {
          ...internalOption,
          selected: !this.multiple || !internalOption.selected
        };
        return this.selectedOption;
      }
      return {
        ...internalOption,
        selected: this.multiple && internalOption.selected
      };
    });
    this.selectionChange.emit(this.selectedOption);
  }
}
