import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { BookingUserDto } from '../../../../../../client';
import { UnavailabilityType } from '../../../../types/unavailability-event.type';
import { Vehicle } from '../../../../types/vehicle.type';
import { TooltipCreationType } from '../../../../types/tooltip-settings.type';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipTargetRectEvent
} from '../../../../classes/tooltip-events.class';
import {
  Tooltip,
  TooltipContentPoolVehicle,
  TooltipType
} from '../../../../types/tooltip.type';
import { RelativePos } from '../../../../types/relative-pos.type';
import {
  SchedulerSetting,
  tooltipSettings
} from '../../../../scheduler.setting';
import { Pos } from '../../../../types/pos.type';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'sof-pool-vehicle-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./pool-vehicle-card.component.scss'],
  template: `
    <sof-license-plate
      class="scheduler-license-plate"
      [value]="vehicle.licensePlate"
      countryCode="be"
    ></sof-license-plate>
    <span class="vehicle-name"> {{ vehicle.name }} </span>
    <sof-svg-icon
      *ngIf="(currentUser | isAdmin) && !!vehicle.privateUsage"
      class="private-usage"
      [class.overdue]="
        vehicle.privateUsage.type === UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE
      "
      nz-tooltip
      nzTooltipPlacement="bottom"
      [nzTooltipTitle]="
        tc +
          '.POOL-VEHICLE-CARD-PRIVATE-USAGE' +
          (vehicle.privateUsage.type ===
          UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE
            ? '-OVERDUE'
            : '') | translate
      "
      icon="batt-icon-flag-private-usage"
      size="16"
    >
    </sof-svg-icon>
  `
})
export class PoolVehicleCardComponent {
  UNAVAILABILITY_TYPE_PRIVATE_USAGE_OVERDUE =
    UnavailabilityType.PRIVATE_USAGE_OVERDUE;

  @Input() tc: string;
  @Input() vehicle: Vehicle;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output() dblClickEvent: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();

  @HostBinding('class.vehicle-card') true;

  // ------ tooltip management ------
  tooltip: Tooltip = {
    id: TooltipType.POOL_VEHICLE,
    type: TooltipType.POOL_VEHICLE,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      vehicle: undefined
    }
  };

  mouseClick$: Observable<MouseEvent> = fromEvent(
    this.element.nativeElement,
    'click'
  );
  mouseSimpleClick$ = this.mouseClick$.pipe(
    debounceTime(250),
    filter(event => {
      return event && event.buttons === 0 && event.detail === 1;
    })
  );

  constructor(private element: ElementRef) {
    this.mouseSimpleClick$.subscribe(event => this.onContentSimpleClick(event));
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(mouseEvent: MouseEvent): void {
    if (tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER) {
      this.addTooltip({ left: mouseEvent.pageX, top: mouseEvent.pageY });
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (tooltipSettings.creation === TooltipCreationType.MOUSE_ENTER) {
      this.deleteTooltip();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(mouseEvent: MouseEvent): void {
    if (tooltipSettings.followMouse) {
      this.updateTooltipPos({ left: mouseEvent.pageX, top: mouseEvent.pageY });
    }
  }

  @HostListener('dblclick', ['$event'])
  onMouseDoubleClick(mouseEvent: MouseEvent): void {
    this.dblClickEvent.emit(this.vehicle);
    this.deleteTooltip();
  }

  // --- tooltip manipulation methods ---

  addTooltip(mousePos: Pos): void {
    this.tooltipEvent.emit(
      new AddTooltipEvent({
        ...this.tooltip,
        targetRect: {
          ...SchedulerSetting.mouseSize,
          ...mousePos
        },
        content: {
          vehicle: this.vehicle,
          tooltipParentId: this.vehicle.internalId
        }
      })
    );
  }

  deleteTooltip(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltip.id));
  }

  updateTooltipPos(newPos: Pos): void {
    this.tooltipEvent.emit(
      new UpdateTooltipTargetRectEvent(this.tooltip.id, newPos)
    );
  }

  onContentSimpleClick(ev: MouseEvent): void {
    if (
      !this.tooltipAlreadyOpened() &&
      tooltipSettings.creation === TooltipCreationType.MOUSE_CLICK &&
      ev.buttons === 0 &&
      ev.detail <= 1
    ) {
      this.addTooltip({ left: ev.pageX, top: ev.pageY });
    }
  }

  tooltipAlreadyOpened(): boolean {
    if (
      !!this.sharedTooltipEvent &&
      this.sharedTooltipEvent instanceof AddTooltipEvent &&
      this.sharedTooltipEvent.payload.tooltip.type === TooltipType.POOL_VEHICLE
    ) {
      const content = this.sharedTooltipEvent.payload.tooltip
        .content as TooltipContentPoolVehicle;
      return content.vehicle.internalId === this.vehicle.internalId;
    }
    return false;
  }
}
