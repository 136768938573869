import { ActionTypes } from '../action-types';
import { AvailabilityEvent } from '../../types/availability-event.type';

export class SetAvailabilitiesAction {
  type = ActionTypes.SET_AVAILABILITIES;
  payload: { availabilities: AvailabilityEvent[] };

  constructor(availabilities: AvailabilityEvent[]) {
    this.payload = { availabilities };
  }
}

export type AvailabilitiesActions = SetAvailabilitiesAction;
