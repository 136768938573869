import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as packageJson from '../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
(window as any).appVersion = (packageJson as any).version;

if (environment.production) {
  enableProdMode();
} else {
  (window as any).config = environment.config;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
