import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BattIconSetComponent } from './batt-icon-set.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BattIconSetComponent],
  exports: [BattIconSetComponent]
})
export class BattIconSetComponentModule {}
