import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GoogleHelper {
  private googleApiKeyProvidedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private googleApiLoadedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  googleApiKeyProvided$ = this.googleApiKeyProvidedSub$.asObservable();
  googleApiLoaded$ = this.googleApiLoadedSub$.asObservable();
  googleApiEnabled$ = combineLatest([
    this.googleApiKeyProvidedSub$,
    this.googleApiLoadedSub$
  ]).pipe(
    map(
      ([googleApiKeyProvided, googleApiLoaded]) =>
        !!googleApiKeyProvided && !!googleApiLoaded
    )
  );

  setGoogleApiKeyProvided(value: boolean): void {
    this.googleApiKeyProvidedSub$.next(value);
  }

  setGoogleApiLoaded(value: boolean): void {
    this.googleApiLoadedSub$.next(value);
  }
}
