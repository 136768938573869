export const AppSettings = {
  organizationViewSwitchModeButtons: [
    {
      label: 'ORGANIZATION-VIEW',
      routerLink: ['organizations'],
      icon: 'batt-icon-user-register',
      active: true
    },
    {
      label: 'MEMBER-VIEW',
      routerLink: ['members'],
      icon: 'batt-icon-user-profile'
    }
  ],
  memberViewSwitchModeButtons: [
    {
      label: 'ORGANIZATION-VIEW',
      routerLink: ['organizations'],
      icon: 'batt-icon-user-register'
    },
    {
      label: 'MEMBER-VIEW',
      routerLink: ['members'],
      icon: 'batt-icon-user-profile',
      active: true
    }
  ]
};
