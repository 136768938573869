import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { MessageTypeDto } from '../../../../../client';
import { Changes } from 'ngx-reactivetoolkit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DateUtil } from '../../../helpers/date-util';
import { ConversationMessageComponent } from '../conversation-message/conversation-message.component';
import { ConversationMessage } from './types/conversation-message.type';
const moment = extendMoment(Moment);

@Component({
  selector: 'sof-conversation-messages',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      *ngFor="
        let messagePerUser of sortedMessages$ | async;
        trackBy: tracker;
        let i = index
      "
      class="conversation-messages-user-container"
    >
      <div
        *ngFor="
          let messagePerTimestamp of messagePerUser;
          trackBy: tracker;
          let j = index
        "
        class="conversation-messages-timestamp-container"
      >
        <div
          *ngFor="
            let message of messagePerTimestamp;
            trackBy: tracker;
            let k = index
          "
          class="conversation-messages-messages-container"
        >
          <sof-conversation-system-message
            *ngIf="message.messageType === MESSAGE_TYPE_SYSTEM"
            [tc]="tc"
            [timeStamp]="message.timeStamp"
            [message]="message"
            [showTimestamp]="k === 0"
          >
            {{ message.message }}
          </sof-conversation-system-message>
          <sof-conversation-attachment-message
            *ngIf="message.messageType === MESSAGE_TYPE_ATTACHMENT"
            [id]="message.id"
            [tc]="tc"
            [message]="message"
            [timeStamp]="message.timeStamp"
            [userImage]="message.userImage"
            [noRoundUp]="k !== 0"
            [noRoundDown]="
              (k > 0 && k !== messagePerTimestamp.length - 1) ||
              (k === 0 && messagePerTimestamp.length > 1)
            "
            [showUsername]="j === 0 && k === 0"
            [showTimestamp]="k === 0"
            [firstMessage]="k === 0"
            [isOwnMessage]="message.sender.remoteId === currentUserId"
            (retryAddAttachment)="retryAddAttachment.emit($event)"
            (downloadAttachment)="downloadAttachment.emit($event)"
          >
          </sof-conversation-attachment-message>
          <sof-conversation-message
            *ngIf="message.messageType === MESSAGE_TYPE_TEXT"
            [id]="message.id"
            [message]="
              message.messageType === MESSAGE_TYPE_TEXT ? message.message : ''
            "
            [timeStamp]="message.timeStamp"
            [userImage]="message.userImage"
            [noRoundUp]="k !== 0"
            [noRoundDown]="
              (k > 0 && k !== messagePerTimestamp.length - 1) ||
              (k === 0 && messagePerTimestamp.length > 1)
            "
            [showUsername]="j === 0 && k === 0"
            [showTimestamp]="k === 0"
            [firstMessage]="k === 0"
            [isOwnMessage]="message.sender.remoteId === currentUserId"
          >
          </sof-conversation-message>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./conversation-messages.component.scss']
})
export class ConversationMessagesComponent
  implements OnChanges, AfterViewInit, OnInit {
  MESSAGE_TYPE_ATTACHMENT = MessageTypeDto.ATTACHMENTMESSAGE;
  MESSAGE_TYPE_SYSTEM = MessageTypeDto.SYSTEMMESSAGE;
  MESSAGE_TYPE_TEXT = MessageTypeDto.TEXTMESSAGE;
  @Input() tc: string;
  @Input() messages: ConversationMessage[];
  @Input() currentUserId: string;

  @Output()
  needEvaluateScrollPosition: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  retryAddAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();
  @Output()
  downloadAttachment: EventEmitter<ConversationMessage> = new EventEmitter<ConversationMessage>();

  @Changes('messages') messages$: Observable<ConversationMessage[]>;

  @ViewChildren(ConversationMessageComponent)
  conversationMessageElements: QueryList<ConversationMessageComponent>;

  sortedMessages$: Observable<ConversationMessage[][][]>;

  tracker(index: number, message: ConversationMessage): string {
    return message.id;
  }

  isTextMessage(message: ConversationMessage): boolean {
    return message.messageType === MessageTypeDto.TEXTMESSAGE;
  }

  isSystemMessage(message: ConversationMessage): boolean {
    return message.messageType === MessageTypeDto.SYSTEMMESSAGE;
  }

  adaptMessages(messages: ConversationMessage[]): ConversationMessage[][][] {
    return this.groupMessages(messages?.sort(this.compareMessages));
  }

  getBreakingMessageType(message: ConversationMessage): MessageTypeDto {
    // Text messages and attachment messages are considered equal for grouping
    return message.messageType === MessageTypeDto.SYSTEMMESSAGE
      ? MessageTypeDto.SYSTEMMESSAGE
      : MessageTypeDto.TEXTMESSAGE;
  }

  groupMessages(messages: ConversationMessage[]): ConversationMessage[][][] {
    let breakUserOrSystemMessage = true;
    let breakTimestamp = true;
    let lastMessage: ConversationMessage = null;
    const res: ConversationMessage[][][] = [];
    let userMessages: ConversationMessage[][] = [];
    let timestampMessages: ConversationMessage[] = [];
    for (const message of messages) {
      if (lastMessage) {
        if (
          this.getBreakingMessageType(lastMessage) !==
          this.getBreakingMessageType(message)
        ) {
          breakUserOrSystemMessage = true;
          breakTimestamp = true;
        } else if (
          lastMessage.sender.remoteId !== message.sender.remoteId &&
          !this.isSystemMessage(message)
        ) {
          breakUserOrSystemMessage = true;
          breakTimestamp = true;
        } else if (
          this.formatDate(lastMessage.timeStamp) !==
          this.formatDate(message.timeStamp)
        ) {
          breakTimestamp = true;
        }
      }
      if (breakUserOrSystemMessage) {
        breakUserOrSystemMessage = false;
        userMessages = [];
        res.push(userMessages);
      }
      if (breakTimestamp) {
        breakTimestamp = false;
        timestampMessages = [];
        userMessages.push(timestampMessages);
      }
      timestampMessages.push(message);
      lastMessage = { ...message };
    }
    return res;
  }

  compareMessages(a: ConversationMessage, b: ConversationMessage): number {
    return a?.timeStamp.localeCompare(b?.timeStamp);
  }

  formatDate(timeStamp: string): string {
    return moment(DateUtil.convertToDate(timeStamp)).format('DD/MM/YYYY HH:mm');
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    this.conversationMessageElements.changes.subscribe(r => {
      this.needEvaluateScrollPosition.emit(true);
    });
  }

  ngOnInit(): void {
    this.sortedMessages$ = this.messages$.pipe(
      map(messages => this.adaptMessages([...messages]))
    );
  }
}
