import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroy, UntilDestroy } from 'ngx-reactivetoolkit';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  BookingDto,
  BookingStatusDto,
  SearchBookingRequestDto
} from '../../../../client';
import { DateUtil } from '../../helpers/date-util';
import { ToastUtilService } from '../../services/toast-util.service';
import { BookingDialogData } from '../../types/booking-dialog-data.type';
import { BookingManagementSandbox } from '../booking-management.sandbox';

@UntilDestroy()
@Injectable()
export class BookingsViewHelper {
  tc = 'BOOKING-MANAGEMENT_BOOKING';
  bookingDialogData: BookingDialogData;

  filterForm = this.fb.group({
    fromDate: [new Date(), Validators.required],
    toDate: [new Date(), Validators.required],
    fromPicker: [new Date(), Validators.required],
    toPicker: [new Date(), Validators.required],
    userId: '',
    vehicleId: '',
    statuses: [
      [
        BookingStatusDto.AWAITINGAPPROVAL,
        BookingStatusDto.APPROVED,
        BookingStatusDto.ACTIVE,
        BookingStatusDto.STOPPED
      ]
    ],
    tripTypes: null,
    adminComments: '',
    comments: '',
    licensePlate: '',
    vehicleName: '',
    organizationId: ''
  });
  canChangeUserSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  userId: string = null;

  searchBookingRequestSub$: BehaviorSubject<SearchBookingRequestDto> = new BehaviorSubject<SearchBookingRequestDto>(
    null
  );
  bookingPageDto$ = this.searchBookingRequestSub$.pipe(
    filter(request => !!request),
    switchMap(request => this.sb.searchBookings(request)),
    takeUntilDestroy(this)
  );

  constructor(
    private fb: FormBuilder,
    private sb: BookingManagementSandbox,
    private toastUtilService: ToastUtilService
  ) {}

  getUserId(): string {
    if (this.userId) {
      return this.userId;
    }
    if (this.filterForm.value.userId === '') {
      this.filterForm.value.userId = null;
    }
    return this.filterForm.value.userId;
  }

  submit(): void {
    if (!this.filterForm.valid) {
      this.toastUtilService.error(this.tc + '.FILL-OUT-REQUIRED-DATA', true);
      return;
    }
    if (this.filterForm.value.vehicleId === '') {
      this.filterForm.value.vehicleId = null;
    }
    const period = DateUtil.convertToSearchPeriod(
      this.filterForm.value.fromPicker,
      this.filterForm.value.toPicker
    );
    const tripTypes =
      this.filterForm.value.tripTypes?.length > 0
        ? this.filterForm.value.tripTypes
        : null;
    const statuses =
      this.filterForm.value.statuses?.length > 0
        ? this.filterForm.value.statuses
        : null;
    const licensePlate =
      this.filterForm.value.licensePlate?.trim()?.length > 0
        ? this.filterForm.value.licensePlate.trim()
        : null;
    const vehicleName =
      this.filterForm.value.vehicleName?.trim()?.length > 0
        ? this.filterForm.value.vehicleName.trim()
        : null;
    const comments =
      this.filterForm.value.comments?.trim()?.length > 0
        ? this.filterForm.value.comments.trim()
        : null;
    const adminComments =
      this.filterForm.value.adminComments?.trim()?.length > 0
        ? this.filterForm.value.adminComments.trim()
        : null;
    const vehicleId = !!this.filterForm.value.vehicleId
      ? this.filterForm.value.vehicleId
      : null;
    const organizationId =
      this.filterForm.value.organizationId?.trim()?.length > 0
        ? this.filterForm.value.organizationId.trim()
        : null;
    const searchBookingRequestDto: SearchBookingRequestDto = {
      includeCanceled: true,
      period,
      userId: this.getUserId(),
      vehicleId,
      tripTypes,
      statuses,
      licensePlate,
      vehicleName,
      comments,
      adminComments,
      organizationId
    };
    this.searchBookingRequestSub$.next(searchBookingRequestDto);
  }

  refreshList(bookingDto?: BookingDto): void {
    if (!!bookingDto) {
      if (
        this.filterForm.value.fromPicker &&
        this.filterForm.value.fromPicker >
          DateUtil.convertToDate(bookingDto.plannedPeriod.start)
      ) {
        this.filterForm.controls.fromPicker.setValue(
          DateUtil.convertToDate(bookingDto.plannedPeriod.start)
        );
      }
      if (
        this.filterForm.value.toPicker &&
        this.filterForm.value.toPicker <
          DateUtil.convertToDate(bookingDto.plannedPeriod.start)
      ) {
        this.filterForm.controls.toPicker.setValue(
          DateUtil.convertToDate(bookingDto.plannedPeriod.start)
        );
      }
      if (
        !!this.filterForm.value.vehicleId &&
        this.filterForm.value.vehicleId !== bookingDto.vehicle?.id
      ) {
        this.filterForm.controls.vehicle.setValue(bookingDto.vehicle?.id);
      }
      if (
        this.canChangeUserSub$.value &&
        !!this.filterForm.value.userId &&
        this.filterForm.value.userId !== bookingDto.user?.remoteId
      ) {
        this.filterForm.controls.user.setValue(bookingDto.user?.remoteId);
      }
    }
    this.submit();
  }
}
