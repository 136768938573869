import { ActionTypes } from '../action-types';
import { ConversationDto } from '../../../../client';

export class SetConversationsAction {
  type = ActionTypes.SET_CONVERSATIONS;
  payload: {
    conversations: ConversationDto[];
  };

  constructor(conversations: ConversationDto[]) {
    this.payload = { conversations };
  }
}

export class UpdateConversationAction {
  type = ActionTypes.UPDATE_CONVERSATION;
  payload: {
    conversationId: string;
    conversation: ConversationDto;
  };

  constructor(conversationId: string, conversation: ConversationDto) {
    this.payload = { conversationId, conversation };
  }
}

export type ConversationsActions =
  | SetConversationsAction
  | UpdateConversationAction;
