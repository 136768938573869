import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AvailabilityDialogData } from '../../types/availability-dialog-data.type';
import { BookingDialogData } from '../../types/booking-dialog-data.type';
import { VehicleDialogData } from '../../types/vehicle-dialog-data.type';

@Injectable()
export class SchedulerViewHelper {
  private triggerRefreshVehicles$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null
  );
  private triggerRefreshAvailabilityEventsSub$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null
  );
  refreshVehicles$ = this.triggerRefreshVehicles$.asObservable();
  refreshAvailabilityEvents$ = this.triggerRefreshAvailabilityEventsSub$.asObservable();

  vehicleDialogData: VehicleDialogData;
  bookingDialogData: BookingDialogData;
  availabilityDialogData: AvailabilityDialogData;

  triggerRefreshVehicles(): void {
    this.triggerRefreshVehicles$.next(new Date());
  }

  triggerRefreshAvailabilityEvents(): void {
    this.triggerRefreshAvailabilityEventsSub$.next(new Date());
  }
}
