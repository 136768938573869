import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  OnChanges,
  ViewChild,
  OnInit
} from '@angular/core';
import { Duration } from '../../../classes/duration.class';
import {
  BookingUpdate,
  BookingUpdateNewFields
} from '../../../types/booking-update.type';
import { BookingWithStyle } from '../../../types/booking-with-style.type';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  interval,
  merge,
  Observable,
  Subject
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  pairwise,
  startWith,
  withLatestFrom
} from 'rxjs/operators';
import { takeUntilDestroy, Changes, UntilDestroy } from 'ngx-reactivetoolkit';
import {
  AddTooltipEvent,
  DeleteTooltipEvent,
  TooltipEvent,
  UpdateTooltipContentValuesEvent,
  UpdateTooltipTargetRectEvent
} from '../../../classes/tooltip-events.class';
import { Tooltip, TooltipType } from '../../../types/tooltip.type';
import { RelativePos } from '../../../types/relative-pos.type';
import { SchedulerSetting } from '../../../scheduler.setting';
import * as _ from 'lodash';
import { BookingSchedulerRowWithStyle } from '../../../types/booking-scheduler-row-with-style.type';
import { UnavailabilityEventWithStyle } from '../../../types/unavailability-event-with-style.type';
import { BookingAdd } from '../../../types/booking-add.type';
import { AvailabilityEventWithStyle } from '../../../types/availability-event-with-style.type';
import {
  BookingReferenceDto,
  BookingUserDto
} from  '../../../../../client';
import { BookingSchedulerDate } from '../../../types/booking-sheduler-date.type';
import { Vehicle } from '../../../types/vehicle.type';

@UntilDestroy()
@Component({
  selector: 'sof-booking-scheduler-body',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-booking-scheduler-body-row
      *ngFor="
        let rowOfBookingsWithStyle of rowsOfBookingsWithStyle;
        trackBy: trackByIndex
      "
      [rowOfBookingsWithStyle]="rowOfBookingsWithStyle"
      [cells]="cells"
      [schedulerDateFrom]="schedulerDateFrom"
      [schedulerDateTo]="schedulerDateTo"
      [msPerPixel]="msPerPixel"
      [smallestDragUnit]="smallestDragUnit"
      [highlightedArea]="
        (isDraggingOverRowInternalVehicleId$ | async) ===
        rowOfBookingsWithStyle.internalVehicleId
          ? highlightedArea
          : null
      "
      [highlighting]="
        !!selectedVehicle &&
        rowOfBookingsWithStyle.internalVehicleId === selectedVehicle.internalId
      "
      [internalBookingDraggedId]="bookingOnMoveInfo?.internalId"
      [sharedTooltipEvent]="sharedTooltipEvent"
      [currentUser]="currentUser"
      (highlightedAreaDates)="highlightedAreaDates = $event"
      (mouseover)="mouseOverRowSub$.next(rowOfBookingsWithStyle)"
      (mouseDownOnBooking)="onMouseDownOnBooking($event)"
      (mouseup)="onMouseUp($event)"
      (dblClickOnBooking)="dblClickOnBooking.emit($event)"
      (dblClickOnUnavailability)="dblClickOnUnavailability.emit($event)"
      (dblClickOnAvailability)="dblClickOnAvailability.emit($event)"
      (newBooking)="newBooking.emit($event)"
      (updateBooking)="updateBooking.emit($event)"
      (tooltipEvent)="tooltipEvent.emit($event)"
      (isDraggingBooking)="isDraggingBooking.emit($event)"
      [ngStyle]="rowsHeightStyle[rowOfBookingsWithStyle.internalVehicleId]"
    >
    </sof-booking-scheduler-body-row>

    <!-- hidden is used instead of ngIf, because it cause @ViewChild to not working if component if not visible by default -->
    <div #currentTime class="current-time" [hidden]="!showCurrentTime"></div>
  `,
  styleUrls: ['./booking-scheduler-body.component.scss']
})
export class BookingSchedulerBodyComponent implements OnChanges, OnInit {
  @Input() rowsOfBookingsWithStyle: BookingSchedulerRowWithStyle[];
  @Input() cells: BookingSchedulerDate[];
  @Input() msPerPixel: number;
  @Input() smallestDragUnit: Duration;
  @Input() schedulerDateFrom: Date;
  @Input() schedulerDateTo: Date;
  @Input() selectedVehicle: Vehicle;
  @Input() rowsHeightStyle: {
    [key: string]: { 'minHeight.px': number; 'height.px': number };
  };
  @Input() windowKeyUp: KeyboardEvent;
  @Input() windowKeyDown: KeyboardEvent;
  @Input() sharedTooltipEvent: TooltipEvent;
  @Input() currentUser: BookingUserDto;

  @Output()
  newBooking: EventEmitter<BookingAdd> = new EventEmitter<BookingAdd>();
  @Output()
  updateBooking: EventEmitter<BookingUpdate> = new EventEmitter<BookingUpdate>();
  @Output()
  dblClickOnBooking: EventEmitter<BookingWithStyle> = new EventEmitter<BookingWithStyle>();
  @Output()
  dblClickOnUnavailability: EventEmitter<UnavailabilityEventWithStyle> = new EventEmitter<UnavailabilityEventWithStyle>();
  @Output()
  dblClickOnAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();
  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  isDraggingBooking: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.isDragging') applyIsDraggingClass = false;

  @Changes('windowKeyUp') windowKeyUp$: Observable<KeyboardEvent>;
  @Changes('windowKeyDown') windowKeyDown$: Observable<KeyboardEvent>;
  @Changes('schedulerDateFrom') schedulerDateFrom$: Observable<Date>;
  @Changes('schedulerDateTo') schedulerDateTo$: Observable<Date>;
  @Changes('msPerPixel') msPerPixel$: Observable<number>;

  @ViewChild('currentTime') currentTime: ElementRef;
  timer$: Observable<number> = interval(60000).pipe(startWith(0));
  schedulerPeriodWithTimer$: Observable<[Date, Date, number, number]>;
  showCurrentTime = false;

  tooltipOnBookingMoving: Tooltip = {
    id: TooltipType.DRAG.toString(),
    type: TooltipType.DRAG,
    relativeTooltipPos: [
      {
        targetRectAnchor: RelativePos.BOTTOM_RIGHT,
        tooltipAnchor: RelativePos.TOP_LEFT
      },
      {
        targetRectAnchor: RelativePos.BOTTOM_LEFT,
        tooltipAnchor: RelativePos.TOP_RIGHT
      },
      {
        targetRectAnchor: RelativePos.TOP_RIGHT,
        tooltipAnchor: RelativePos.BOTTOM_LEFT
      },
      {
        targetRectAnchor: RelativePos.TOP_LEFT,
        tooltipAnchor: RelativePos.BOTTOM_RIGHT
      }
    ],
    content: {
      fromDate: undefined,
      toDate: undefined
    }
  };

  // the internal id of the vehicle currently under the mouse pointer
  mouseOverRowSub$: Subject<BookingSchedulerRowWithStyle> = new Subject<BookingSchedulerRowWithStyle>();
  // equal to true if the user is dragging, false otherwise
  isDraggingSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // the date of the highlighted area emitted by one of the sub-rows
  highlightedAreaDates: { fromDate: Date; toDate: Date };

  // information about the booking the user is currently moving
  bookingOnMoveInfo: {
    width: number;
    left: number;
    internalId: string;
    remoteId: string;
    fromDate: Date;
    remoteVehicleId: string;
    toDate: Date;
    data: BookingReferenceDto;
  };

  // information about the unavailability when moving
  unavailabilityOnMoveInfo: {
    width: number;
    left: number;
    id: string;
    fromDate: Date;
    vehicleId: string;
    toDate: Date;
  };

  // if the user is dragging, equal to row, otherwise equal to null
  isDraggingOverRow$: Observable<BookingSchedulerRowWithStyle>;

  isDraggingOverRowInternalVehicleId$: Observable<string>;

  // contains the left position of the last mousedown event
  // the position is relative to the booking scheduler body
  bodyMouseDownLeft$: Observable<number>;

  // contains the current mouse left position
  // the position is relative to the booking scheduler body
  bodyMouseMoveLeft$: Observable<number>;

  shiftKeyPressed$: Observable<boolean>;

  // the horizontal drag in pixel of the current drag
  // emit only if the user is dragging
  // equal to 0 if SHIFT is pressed
  horizontalDrag$: Observable<number>;

  // the position and the width of the highlighted area displayed in a row
  highlightedArea: { width: number; left: number; shiftKeyPressed: boolean };

  constructor(
    private element: ElementRef,
    private changeDetection: ChangeDetectorRef
  ) {}

  onMouseDownOnBooking(booking: BookingWithStyle): void {
    // about the booking which is going to move,
    // we store the width and left according to its dates
    // (and not the visible width and left, they can be truncated)
    this.bookingOnMoveInfo = {
      internalId: booking.internalId,
      remoteId: booking.remoteId,
      width:
        (booking.toDate.valueOf() - booking.fromDate.valueOf()) /
        this.msPerPixel,
      left:
        (booking.fromDate.valueOf() - this.schedulerDateFrom.valueOf()) /
        this.msPerPixel,
      remoteVehicleId: booking.remoteVehicleId,
      fromDate: booking.fromDate,
      toDate: booking.toDate,
      data: booking.data
    };
    this.isDraggingSub$.next(true);
  }

  onMouseUp(event): void {
    this.isDraggingSub$.next(false);
  }

  trackByIndex = i => i;

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.isDraggingOverRow$ = combineLatest([
      this.isDraggingSub$,
      this.mouseOverRowSub$
    ]).pipe(
      map(([isDragging, mouseOverRow]) => (isDragging ? mouseOverRow : null)),
      distinctUntilChanged()
    );

    this.isDraggingOverRowInternalVehicleId$ = this.isDraggingOverRow$.pipe(
      map(isDraggingOverRow =>
        !!isDraggingOverRow ? isDraggingOverRow.internalVehicleId : null
      )
    );

    this.bodyMouseDownLeft$ = fromEvent(
      this.element.nativeElement,
      'mousedown'
    ).pipe(
      map(
        (mouseDown: MouseEvent) =>
          mouseDown.clientX -
          this.element.nativeElement.getBoundingClientRect().x
      )
    );

    this.bodyMouseMoveLeft$ = fromEvent(
      this.element.nativeElement,
      'mousemove'
    ).pipe(
      map(
        (mouseMove: MouseEvent) =>
          mouseMove.clientX -
          this.element.nativeElement.getBoundingClientRect().x
      )
    );

    this.shiftKeyPressed$ = merge(
      this.windowKeyDown$.pipe(
        filter(
          (event: KeyboardEvent) => event && event.key && event.key === 'Shift'
        ),
        mapTo(true)
      ),
      this.windowKeyUp$.pipe(
        filter(
          (event: KeyboardEvent) => event && event.key && event.key === 'Shift'
        ),
        mapTo(false)
      )
    ).pipe(startWith(false));

    this.horizontalDrag$ = combineLatest([
      this.bodyMouseDownLeft$,
      this.bodyMouseMoveLeft$,
      this.shiftKeyPressed$,
      this.isDraggingSub$
    ]).pipe(
      filter(
        ([mouseDownLeft, mouseMoveLeft, shiftKeyPressed, isDragging]) =>
          isDragging
      ),
      map(([mouseDownLeft, mouseMoveLeft, shiftKeyPressed, isDragging]) =>
        shiftKeyPressed ? 0 : mouseMoveLeft - mouseDownLeft
      ),
      distinctUntilChanged()
    );

    this.isDraggingSub$
      .pipe(takeUntilDestroy(this))
      .subscribe(isDragging => (this.applyIsDraggingClass = isDragging));

    // TODO mimu: track all 'bookingOnMoveInfo' variables, to see how to manipulate unavailabilityOnMoveInfo
    // Calculation of the highlighted area
    // Made from the booking width/left moved and the horizontal drag
    this.horizontalDrag$
      .pipe(takeUntilDestroy(this))
      .subscribe(horizontalDrag => {
        this.highlightedArea = {
          width: this.bookingOnMoveInfo.width,
          left: this.bookingOnMoveInfo.left + horizontalDrag,
          shiftKeyPressed: horizontalDrag === 0
        };

        // TODO cabu : find how to get rid of manual changeDetection update
        // the highlighted area position is updated every time horizontalDrag$ emit
        // but in the template, its position changes chaotically
        this.changeDetection.detectChanges();
      });

    // ----- tooltip management -----

    // when the drag begins, we create a tooltip
    this.isDraggingSub$
      .pipe(
        pairwise(),
        filter(([lastVal, newVal]) => lastVal === false && newVal === true),
        withLatestFrom(fromEvent(this.element.nativeElement, 'mousemove')),
        takeUntilDestroy(this)
      )
      .subscribe(
        ([isDragging, mouseMove]: [[boolean, boolean], MouseEvent]) => {
          this.tooltipEvent.emit(
            new AddTooltipEvent({
              ...this.tooltipOnBookingMoving,
              targetRect: {
                width: SchedulerSetting.mouseSize.width,
                height: SchedulerSetting.mouseSize.height,
                top: mouseMove.pageY,
                left: mouseMove.pageX
              }
            })
          );
        }
      );

    // when the drag is done ,
    // emit a booking update (when isDraggingOverRow$ pass to null)
    // and delete the tooltip
    this.isDraggingOverRow$
      .pipe(
        pairwise(),
        filter(([previousLine, now]) => now == null),
        map(([previousLine, now]) => previousLine),
        takeUntilDestroy(this)
      )
      .subscribe(row => {
        // we delete the tooltip
        this.tooltipEvent.emit(
          new DeleteTooltipEvent(this.tooltipOnBookingMoving.id)
        );

        let newFields: BookingUpdateNewFields = {};
        if (this.bookingOnMoveInfo.remoteVehicleId !== row.remoteVehicleId) {
          newFields.remoteVehicleId = row.remoteVehicleId;
        }
        if (
          this.bookingOnMoveInfo.fromDate.valueOf() !==
          this.highlightedAreaDates.fromDate.valueOf()
        ) {
          newFields = {
            ...newFields,
            ...this.highlightedAreaDates
          };
        }
        if (!_.isEmpty(newFields)) {
          this.updateBooking.emit({
            previousBooking: {
              toDate: this.bookingOnMoveInfo.toDate,
              fromDate: this.bookingOnMoveInfo.fromDate,
              internalId: this.bookingOnMoveInfo.internalId,
              remoteId: this.bookingOnMoveInfo.remoteId,
              remoteVehicleId: this.bookingOnMoveInfo.remoteVehicleId,
              comments: this.bookingOnMoveInfo.data.comments,
              tripType: null,
              userId: this.bookingOnMoveInfo.data.user.remoteId
            },
            newFields
          });
        }
        this.bookingOnMoveInfo = null;
      });

    // when the user is dragging, we update the tooltip
    combineLatest([
      this.isDraggingSub$,
      fromEvent(this.element.nativeElement, 'mousemove')
    ])
      .pipe(
        filter(([isDragging, mouseMove]) => isDragging),
        takeUntilDestroy(this)
      )
      .subscribe(([isDragging, mouseMove]: [boolean, MouseEvent]) => {
        // we update the content
        this.tooltipEvent.emit(
          new UpdateTooltipContentValuesEvent(this.tooltipOnBookingMoving.id, {
            ...this.highlightedAreaDates
          })
        );
        // we update the position
        this.tooltipEvent.emit(
          new UpdateTooltipTargetRectEvent(this.tooltipOnBookingMoving.id, {
            top: mouseMove.pageY,
            left: mouseMove.pageX
          })
        );
      });

    // when the user is dragging, we emit
    this.isDraggingSub$
      .pipe(takeUntilDestroy(this))
      .subscribe(value => this.isDraggingBooking.emit(value));

    this.schedulerPeriodWithTimer$ = combineLatest([
      this.schedulerDateFrom$,
      this.schedulerDateTo$,
      this.timer$,
      this.msPerPixel$
    ]);

    this.schedulerPeriodWithTimer$
      .pipe(takeUntilDestroy(this))
      .subscribe(([dateFrom, dateTo, timer, msPerPixel]) => {
        const todayMs = new Date().getTime();
        this.showCurrentTime =
          msPerPixel &&
          todayMs >= dateFrom.getTime() &&
          todayMs < dateTo.getTime();
        if (
          this.showCurrentTime &&
          this.currentTime &&
          this.currentTime.nativeElement
        ) {
          const leftPos = (todayMs - dateFrom.getTime()) / msPerPixel;
          // Use this, because style or ngStyle were not refreshing the div automatically (change detection issue?)
          this.currentTime.nativeElement.style.left = leftPos + 'px';
        }
      });
  }
}
