import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TooltipContentAvailability } from '../../../../types/tooltip.type';
import {
  DeleteTooltipEvent,
  TooltipEvent
} from '../../../../classes/tooltip-events.class';
import { AvailabilityEventWithStyle } from '../../../../types/availability-event-with-style.type';

@Component({
  selector: 'sof-tooltip-content-availability',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tooltip-content-availability.component.scss'],
  template: `
    <div class="header">
      <div class="title">Availability information</div>
      <button
        sofButton
        type="button"
        class="icon-button"
        (click)="onCloseClick()"
      >
        <sof-svg-icon icon="batt-icon-close" size="12"></sof-svg-icon>
      </button>
    </div>
    <div class="tooltip-list">
      <sof-divider
        size="thin"
        *ngIf="!!content.availabilityEvent?.data?.recurringAvailabilityId"
      ></sof-divider>
      <div
        class="tooltip-list-item label-value-container"
        *ngIf="!!content.availabilityEvent?.data?.recurringAvailabilityId"
      >
        <div class="label">Recurring event</div>
        <div class="value"></div>
      </div>
      <sof-divider size="thin"></sof-divider>
      <div class="tooltip-list-item column time-container">
        <div class="tooltip-line time-title">Time</div>
        <div class="tooltip-line time container">
          <div class="row">
            <div class="col-sm">
              <div class="label">From :</div>
              <sof-date-hour
                [date]="content?.availabilityEvent.fromDate"
              ></sof-date-hour>
            </div>
            <div class="col-sm">
              <div class="label">To :</div>
              <sof-date-hour
                [date]="content?.availabilityEvent.toDate"
              ></sof-date-hour>
            </div>
          </div>
        </div>
      </div>
      <sof-divider
        size="thin"
        *ngIf="!!content.availabilityEvent?.data"
      ></sof-divider>
      <div
        class="tooltip-list-item edit-container"
        *ngIf="!!content.availabilityEvent?.data"
      >
        <button
          sofButton
          class="general-action-button primary"
          (click)="onEditClick()"
        >
          Edit
        </button>
      </div>
    </div>
  `
})
export class TooltipContentAvailabilityComponent {
  @Input() content: TooltipContentAvailability;
  @Input() tooltipId: string;
  @Input() tc: string;

  @Output()
  tooltipEvent: EventEmitter<TooltipEvent> = new EventEmitter<TooltipEvent>();
  @Output()
  editAvailability: EventEmitter<AvailabilityEventWithStyle> = new EventEmitter<AvailabilityEventWithStyle>();

  onCloseClick(): void {
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }

  onEditClick(): void {
    this.editAvailability.emit(this.content.availabilityEvent);
    this.tooltipEvent.emit(new DeleteTooltipEvent(this.tooltipId));
  }
}
