import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  FeatureToggleDto,
  SettingControllerImplService
} from '../../../client';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private featureToggles: FeatureToggleDto = null;

  constructor(
    private settingControllerImplService: SettingControllerImplService
  ) {}

  get featureToggles$(): Observable<FeatureToggleDto> {
    if (this.featureToggles === null) {
      return this.settingControllerImplService
        .getFeatureToggles()
        .pipe(tap(featureToggles => (this.featureToggles = featureToggles)));
    }
    return of(this.featureToggles);
  }

  get canChangeUser$(): Observable<boolean> {
    return this.featureToggles$.pipe(
      map(featureToggles => featureToggles.bookingUserSelection)
    );
  }

  resetFeatureToggles(): void {
    this.featureToggles = null;
  }
}
