import { Pipe, PipeTransform } from '@angular/core';
import { GpsLocationDto } from '../../../../client';

@Pipe({
  name: 'gpsLocationToLatLngLiteral'
})
export class GpsLocationToLatLngLiteralPipe implements PipeTransform {
  transform(gpsLocation: GpsLocationDto): google.maps.LatLngLiteral {
    if (!gpsLocation || !gpsLocation.gpsCoordinateDto) {
      return null;
    }
    return {
      lat: gpsLocation.gpsCoordinateDto.latitude,
      lng: gpsLocation.gpsCoordinateDto.longitude
    } as google.maps.LatLngLiteral;
  }
}
