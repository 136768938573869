import { Pipe, PipeTransform } from '@angular/core';
import {
  BookingUserDto,
  ConversationDto,
  UserReferenceDto
} from '../../../../client';

@Pipe({ name: 'conversationUser' })
export class ConversationUserPipe implements PipeTransform {
  transform(
    conversation: ConversationDto,
    currentUser: BookingUserDto
  ): UserReferenceDto {
    if (!currentUser) {
      return null;
    }
    if (conversation.initiator.remoteId !== currentUser.remoteId) {
      return conversation.initiator;
    }
    if (conversation.participants) {
      return conversation.participants.find(
        participant => participant.remoteId !== currentUser.remoteId
      );
    }
    return null;
  }
}
