import { Duration } from './classes/duration.class';
import { TooltipType } from './types/tooltip.type';
import { ViewMode } from './types/view-mode.type';
import {
  TooltipCreationType,
  TooltipSettings
} from './types/tooltip-settings.type';

// predefined durations
const DURATION_15MIN: Duration = new Duration(15, 0, 0, 0);
const DURATION_30MIN: Duration = new Duration(30, 0, 0, 0);
const DURATION_HOUR: Duration = new Duration(0, 1, 0, 0);
const DURATION_2HOUR: Duration = new Duration(0, 2, 0, 0);
const DURATION_4HOUR: Duration = new Duration(0, 4, 0, 0);
const DURATION_DAY: Duration = new Duration(0, 0, 1, 0);
const DURATION_WEEK: Duration = new Duration(0, 0, 0, 1);
const DURATION_2WEEK: Duration = new Duration(0, 0, 0, 2);
const DURATION_MONTH: Duration = new Duration(0, 0, 0, 5);
const DURATION_2MONTH: Duration = new Duration(0, 0, 0, 9);
const DURATION_QUARTER: Duration = new Duration(0, 0, 0, 14);

const WEEK_DATE_FORMAT = '[Week] W - MMM/YY'; // example : 'Week 3 - Jan/18'

export const dayViewMode: ViewMode = {
  label: 'D',
  tooltipLabel: `Display 1 day (15 mins increment)`,
  duration: DURATION_DAY,
  displayFromTheBeginingOf: 'day',
  smallestDragUnit: DURATION_15MIN,
  msPerPixelMin: DURATION_HOUR.getMs() / 40,
  shortcut: 'd',
  calendar: {
    column: {
      date_format: 'dddd MMMM D y',
      duration: DURATION_DAY
    },
    subColumn: {
      date_format: 'H[h]',
      duration: DURATION_HOUR
    }
  }
};

export const weekViewMode: ViewMode = {
  label: 'W',
  tooltipLabel: `Display 1 week (30mins increment)`,
  duration: DURATION_WEEK,
  displayFromTheBeginingOf: 'isoWeek',
  smallestDragUnit: DURATION_30MIN,
  msPerPixelMin: DURATION_DAY.getMs() / 100,
  shortcut: 'w',

  calendar: {
    column: {
      date_format: WEEK_DATE_FORMAT,
      duration: DURATION_WEEK
    },
    subColumn: {
      date_format: 'D',
      duration: DURATION_DAY
    }
  }
};

export const twoWeekViewMode: ViewMode = {
  label: '2W',
  tooltipLabel: `Display 2 weeks (1 hour increment)`,
  duration: DURATION_2WEEK,
  displayFromTheBeginingOf: 'isoWeek',
  smallestDragUnit: DURATION_HOUR,
  msPerPixelMin: DURATION_DAY.getMs() / 60,
  shortcut: 'W',
  calendar: {
    column: {
      date_format: WEEK_DATE_FORMAT,
      duration: DURATION_WEEK
    },
    subColumn: {
      date_format: 'D',
      duration: DURATION_DAY
    }
  }
};

export const monthViewMode: ViewMode = {
  label: 'M',
  tooltipLabel: `Display 1 month (2 hours increment)`,
  duration: DURATION_MONTH,
  displayFromTheBeginingOf: 'isoWeek',
  smallestDragUnit: DURATION_2HOUR,
  msPerPixelMin: DURATION_DAY.getMs() / 50,
  shortcut: 'm',
  calendar: {
    column: {
      date_format: WEEK_DATE_FORMAT,
      duration: DURATION_WEEK
    },
    subColumn: {
      date_format: 'D',
      duration: DURATION_DAY
    }
  }
};

export const twoMonthsViewMode: ViewMode = {
  label: '2M',
  tooltipLabel: `Display 2 months (4 hours increment)`,
  duration: DURATION_2MONTH,
  displayFromTheBeginingOf: 'isoWeek',
  smallestDragUnit: DURATION_4HOUR,
  msPerPixelMin: DURATION_DAY.getMs() / 40,
  shortcut: 'M',

  calendar: {
    column: {
      date_format: WEEK_DATE_FORMAT,
      duration: DURATION_WEEK
    },
    subColumn: {
      date_format: 'D',
      duration: DURATION_DAY
    }
  }
};

export const quarterViewMode: ViewMode = {
  label: 'Q',
  tooltipLabel: `Display 3 months (2 hours increment)`,
  duration: DURATION_QUARTER,
  displayFromTheBeginingOf: 'isoWeek',
  smallestDragUnit: DURATION_2HOUR,
  msPerPixelMin: DURATION_DAY.getMs() / 30,
  shortcut: 'q',

  calendar: {
    column: {
      date_format: WEEK_DATE_FORMAT,
      duration: DURATION_WEEK
    },
    subColumn: {
      date_format: 'D',
      duration: DURATION_DAY
    }
  }
};

export const tooltipSettings: TooltipSettings = {
  creation: TooltipCreationType.MOUSE_CLICK,
  followMouse: false
};

export const SchedulerSetting = {
  headerHeightPx: {
    topPart: 50,
    calendarPart: 0
  },
  schedulerHeightPx: 25,

  subRowMinHeightPx: 40,
  dateFormat: {
    day: 'dd/MM/yy',
    hour: 'HH:mm'
  },

  defaultActiveSchedulerFilters: {
    activeFilters: null,
    booking: null,
    vehicleSearchCriteriaDto: {
      filterCriteria: null
    }
  },

  // NOTE : the max duration for the smallest drag unit is one day ( see booking.scheduler.component.ts, function roundDate() )
  viewModes: [
    // DAY VIEW MODE
    dayViewMode,

    // WEEK VIEW MODE
    weekViewMode,

    // 2 WEEKS VIEW MODE
    twoWeekViewMode,

    // MONTH VIEW MODE
    monthViewMode,

    // 2 MONTHS VIEW MODE
    twoMonthsViewMode
  ],

  defaultViewMode: monthViewMode,

  tooltipManager: {
    tooltipsPriority: {
      // the more the toolitp is important, the bigger the number is
      [TooltipType.POOL_VEHICLE]: 4,
      [TooltipType.BOOKING]: 3,
      [TooltipType.DRAG]: 2,
      [TooltipType.DATE]: 1
    },
    maxTooltipDisplayedAmount: 1
  },
  mouseSize: {
    width: 12,
    height: 18
  }
};
