import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleGroupDto } from  '../../../../../client';
import { OrganizationManagementSandbox } from '../../organization-management.sandbox';

@Component({
  selector: 'sof-organization-vehicle-group-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <sof-no-result
        *ngIf="vehicleGroups?.length === 0"
        [tc]="tc"
        noResultLabel="NO-RESULT-VEHICLE-GROUPS"
      ></sof-no-result>
      <div
        class="sof-card"
        *ngFor="let vehicleGroup of vehicleGroups"
        (click)="displayOrganizationVehicleGroupDetail(vehicleGroup)"
      >
        <div class="vehicle-group-container">
          {{ vehicleGroup.name }}
        </div>
        <div class="spacer-container"></div>
        <button
          sofButton
          class="round-icon-button"
          aria-label="Edit vehicle group"
        >
          <sof-svg-icon icon="batt-icon-chevron-right" size="24"></sof-svg-icon>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./organization-vehicle-group-list.component.scss']
})
export class OrganizationVehicleGroupListComponent {
  @Input() tc: string;
  @Input() vehicleGroups: VehicleGroupDto[];

  constructor(private osb: OrganizationManagementSandbox) {}

  displayOrganizationVehicleGroupDetail(vehicleGroup: VehicleGroupDto): void {
    this.osb.navigateToDetailOrganizationVehicleGroupScreen(
      vehicleGroup.organizationId,
      vehicleGroup.id
    );
  }
}
