import {
  BookingDto,
  BookingStatusDto,
  BookingUserDto,
  EnumValueDto,
  OrganizationMemberDto,
  OrganizationRoleDto,
  UserDto,
  UserRoleDto,
  VehicleDto
} from '../../../../client';
import { Duration } from '../../classes/duration.class';
import { SetPrivateUsagesAction } from '../../statemanagement/actions/private-usages.actions';
import { Vehicle } from '../../types/vehicle.type';
import { Observable } from 'rxjs';
import { map, mapTo, take, tap } from 'rxjs/operators';
import ActiveFiltersUtils from '../../utils/active-filters.utils';
import ConvertersUtils from './converters.utils';
import { SetBookingsAction } from '../../statemanagement/actions/bookings.actions';
import { SetUnavailabilitiesAction } from '../../statemanagement/actions/unavailabilities.actions';
import { SetAvailabilitiesAction } from '../../statemanagement/actions/availabilities.actions';
import { AvailabilityService } from '../../services/availability.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../statemanagement/app.state';
import {
  BookingColorKey,
  BookingColors
} from '../../types/booking-colors.type';

export default class SharedUiUtils {
  public static NO_ORGANIZATION_ID = '__no_organization__';

  private static ACTIVE_PERIOD_DELAY_MILLIS = 900000;
  private static bookingColors: Map<BookingColorKey, BookingColors> = new Map([
    [
      BookingColorKey.DEFAULT,
      { border: '#007CBA', background: '#00AEED19', text: '#007CBA' }
    ],
    [
      BookingColorKey.AWAITINGAPPROVAL,
      { border: '#007CBA', background: '#FFFFFF00', text: '#007CBA' }
    ],
    [
      BookingColorKey.APPROVED,
      { border: '#007CBA', background: '#00AEED19', text: '#007CBA' }
    ],
    [
      BookingColorKey.REJECTED,
      { border: '#9E9E9E', background: '#F5F5F5', text: '#9E9E9E' }
    ],
    [
      BookingColorKey.READY,
      { border: '#759D48', background: '#759D4833', text: '#759D48' }
    ],
    [
      BookingColorKey.ACTIVE,
      { border: '#DD9052', background: '#FFCC4A59', text: '#DD9052' }
    ],
    [
      BookingColorKey.OVERDUE,
      { border: '#B00020', background: '#B0002033', text: '#B00020' }
    ],
    [
      BookingColorKey.CANCELED,
      { border: '#9E9E9E', background: '#F5F5F5', text: '#9E9E9E' }
    ],
    [
      BookingColorKey.EXPIRED,
      { border: '#9E9E9E', background: '#F5F5F5', text: '#9E9E9E' }
    ],
    [
      BookingColorKey.STOPPED,
      { border: '#9E9E9E', background: '#F5F5F5', text: '#9E9E9E' }
    ]
  ]);

  static fetchAvailabilityEvents(
    availabilityService: AvailabilityService,
    store: Store<AppState>,
    vehicles: Vehicle[],
    fromDate: Date,
    toDate: Date,
    organizationId: string,
    user: BookingUserDto
  ): Observable<boolean> {
    if (!vehicles) {
      return;
    }
    let userId = !!user?.remoteId ? user.remoteId : null;
    // God mode
    if (organizationId === this.NO_ORGANIZATION_ID) {
      organizationId = null;
      userId = null;
    } else if (!organizationId) {
      organizationId = null;
    }
    const vehicleIds: Array<string> = vehicles.map(vehicle => vehicle.remoteId);
    return availabilityService
      .getAvailabilityEvents(
        vehicleIds,
        fromDate,
        toDate,
        organizationId,
        userId
      )
      .pipe(
        map(pageDTO =>
          ConvertersUtils.convertNonAvailabilitiesPageDtoToEvents(pageDTO)
        ),
        tap(events => {
          store.dispatch(new SetBookingsAction(events.bookings));
          store.dispatch(
            new SetUnavailabilitiesAction(events.unavailabilities)
          );
          store.dispatch(new SetAvailabilitiesAction(events.availabilities));
          store.dispatch(new SetPrivateUsagesAction(events.privateUsages));
        }),
        take(1),
        mapTo(true)
      );
  }

  static isStartDateChangeAllowed(status: string): boolean {
    return (
      status !== BookingStatusDto.ACTIVE &&
      status !== BookingStatusDto.CANCELED &&
      status !== BookingStatusDto.STOPPED
    );
  }

  static isEndDateChangeAllowed(status: string): boolean {
    return (
      status !== BookingStatusDto.CANCELED &&
      status !== BookingStatusDto.STOPPED
    );
  }

  static getOverdueColors(): BookingColors {
    return this.bookingColors.get(BookingColorKey.OVERDUE);
  }

  private static getCurrentDateTimeMillis(): number {
    return new Date().valueOf();
  }

  private static isReady(fromDate: Date): boolean {
    return (
      fromDate.valueOf() - this.ACTIVE_PERIOD_DELAY_MILLIS <=
      this.getCurrentDateTimeMillis()
    );
  }

  static getBookingColors(
    status: BookingStatusDto,
    fromDate: Date,
    toDate: Date,
    withOverdueColor?: boolean
  ): BookingColors {
    switch (status) {
      case BookingStatusDto.AWAITINGAPPROVAL:
        return this.bookingColors.get(BookingColorKey.AWAITINGAPPROVAL);
      case BookingStatusDto.APPROVED:
        if (toDate.valueOf() < this.getCurrentDateTimeMillis()) {
          return this.bookingColors.get(BookingColorKey.EXPIRED);
        } else if (this.isReady(fromDate)) {
          return this.bookingColors.get(BookingColorKey.READY);
        }
        return this.bookingColors.get(BookingColorKey.APPROVED);
      case BookingStatusDto.REJECTED:
        return this.bookingColors.get(BookingColorKey.REJECTED);
      case BookingStatusDto.ACTIVE:
        if (
          withOverdueColor &&
          toDate.valueOf() < this.getCurrentDateTimeMillis()
        ) {
          return this.bookingColors.get(BookingColorKey.OVERDUE);
        }
        return this.bookingColors.get(BookingColorKey.ACTIVE);
      case BookingStatusDto.STOPPED:
        return this.bookingColors.get(BookingColorKey.STOPPED);
      case BookingStatusDto.CANCELED:
        return this.bookingColors.get(BookingColorKey.CANCELED);
    }
    return this.bookingColors.get(BookingColorKey.DEFAULT);
  }

  static getEndDate(
    dateFrom: Date,
    duration: Duration,
    subDuration: Duration
  ): Date {
    const subDurations: Date[] = [];
    let delta = 0;
    while (delta < duration.getMs()) {
      subDurations.push(new Date(dateFrom.valueOf() + delta));
      delta += subDuration.getMs();
    }
    return subDurations[subDurations.length - 1];
  }

  static getUserDisplayName(user: UserDto): string {
    if (!user) {
      return null;
    }
    return this.getDisplayName(user.firstName, user.lastName, user.userName);
  }

  static getDisplayName(
    firstName: string,
    lastName: string,
    userName: string
  ): string {
    let userDisplayName = '';
    if (!!firstName) {
      userDisplayName = firstName;
    }
    if (!!lastName) {
      if (userDisplayName) {
        userDisplayName += ' ';
      }
      userDisplayName += lastName;
    }
    if (!userDisplayName) {
      userDisplayName = userName;
    }
    return userDisplayName;
  }

  static isUserAdmin(user: BookingUserDto): boolean {
    return !!user && user.role === UserRoleDto.BATTADMIN;
  }

  static isOrganizationMemberAdmin(member: OrganizationMemberDto): boolean {
    return (
      !!member && member.organizationRoleDto === OrganizationRoleDto.MANAGER
    );
  }

  static isUserBookingOwnerOrAdmin(
    user: BookingUserDto,
    booking: BookingDto
  ): boolean {
    return (
      !!booking &&
      (booking.user?.remoteId === user.remoteId || this.isUserAdmin(user))
    );
  }

  static isUserVehicleOwnerOrAdmin(
    user: BookingUserDto,
    vehicle: VehicleDto
  ): boolean {
    return (
      !!vehicle &&
      (vehicle.ownerReference?.remoteId === user.remoteId ||
        this.isUserAdmin(user))
    );
  }

  static getEnumDescription(enumValueDto: EnumValueDto): string {
    if (!!enumValueDto.translation) {
      return enumValueDto.translation;
    }
    return !!enumValueDto.description
      ? enumValueDto.description
      : enumValueDto.enumId;
  }

  static getDefaultMapOptions(zoom: number = 11): google.maps.MapOptions {
    const defaultMapOptions: google.maps.MapOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      center: new google.maps.LatLng({
        lat: ActiveFiltersUtils.GPS_DE_PUNT_LATITUDE,
        lng: ActiveFiltersUtils.GPS_DE_PUNT_LONGITUDE
      }),
      zoom,
      styles: [
        {
          featureType: 'administrative.country',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    };
    return defaultMapOptions;
  }
}
