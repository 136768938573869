import { Component, OnInit } from '@angular/core';
import { InviteMemberDialogViewHelper } from '../../view-helpers/invite-member-dialog-view.helper';
import { OrganizationViewHelper } from '../../view-helpers/organization-view.helper';

@Component({
  selector: 'sof-invite-member-dialog-driver-grade-step-view',
  template: `
    <sof-form
      [tc]="tc"
      [formGroup]="inviteMemberDialogViewHelper.organizationMemberFormGroup"
    >
      <sof-input-wrapper [label]="tc + '.DRIVER-GRADE' | translate">
        <sof-input-single-select
          sofFocus
          [sofInput]
          [tc]="tc"
          [options]="
            (organizationViewHelper.organization$ | async)?.driverGrades
          "
          [formControl]="
            inviteMemberDialogViewHelper.organizationMemberFormGroup?.controls
              ?.driverGradeId
          "
          [selectorValue]="inviteMemberDialogViewHelper.driverGradeValueFn"
          [selectorLabel]="inviteMemberDialogViewHelper.driverGradeLabelFn"
        ></sof-input-single-select>
      </sof-input-wrapper>
    </sof-form>
  `
})
export class InviteMemberDialogDriverGradeStepViewComponent implements OnInit {
  tc = 'ORGANIZATION-MANAGEMENT_INVITE-MEMBER-DIALOG';

  constructor(
    public organizationViewHelper: OrganizationViewHelper,
    public inviteMemberDialogViewHelper: InviteMemberDialogViewHelper
  ) {}

  ngOnInit(): void {
    this.inviteMemberDialogViewHelper.checkCurrentRoute();
  }
}
