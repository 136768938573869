import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sof-add-user-to-approved-users-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sof-dialog
      size="sm"
      [headerLabel]="
        tc + '.ADD-USER-TO-APPROVED-USERS-LIST-DIALOG-HEADER' | translate
      "
      [hideDestroy]="true"
    >
      <div sof-dialog-body>
        <sof-loading-spinner></sof-loading-spinner>
        <div class="d-flex pt-3 flex-fill">
          <sof-form [tc]="tc" [formGroup]="formGroup" class="flex-fill">
            <sof-input-checkbox
              [sofInput]
              [formControl]="formGroup?.controls?.addUserToApprovedUsers"
              [label]="
                tc + '.ADD-USER-TO-APPROVED-USERS-LIST-DIALOG-TEXT' | translate
              "
            ></sof-input-checkbox>
          </sof-form>
        </div>
      </div>
      <div sof-dialog-footer>
        <div class="d-flex gg-05">
          <button
            sofButton
            (click)="cancelDialog.emit()"
            class="general-action-button"
          >
            {{ tc + '.CANCEL' | translate }}
          </button>
          <button
            sofButton
            (click)="onOkClick()"
            class="general-action-button main-action"
          >
            {{ tc + '.OK' | translate }}
          </button>
        </div>
      </div>
    </sof-dialog>
  `
})
export class AddUserToApprovedUsersDialogComponent {
  @Input() tc: string;

  @Output() cancelDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() okDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  formGroup: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      addUserToApprovedUsers: [false, Validators.required]
    });
  }

  onOkClick(): void {
    this.okDialog.emit(this.formGroup.value.addUserToApprovedUsers);
  }
}
