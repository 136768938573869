import { Injectable } from '@angular/core';
import { FieldInfo } from '../../types/field-infos.type';
import {
  UnavailabilityEvent,
  UnavailabilityType
} from '../../types/unavailability-event.type';

@Injectable()
export class VehicleService {
  // this object describe each fields of a vehicle
  private fieldsInfo: FieldInfo[] = [
    {
      field: 'licensePlate',
      label: 'Licence plate',
      restrictedValues: [
        '75 WXE 01',
        '75 WXE 02',
        '75 WXE 03',
        '75 WXE 04',
        '75 WXE 05'
      ]
    },
    {
      field: 'category',
      label: 'Category',
      restrictedValues: ['A', 'B', 'C']
    },
    {
      field: 'model',
      label: 'Model',
      restrictedValues: ['City-Dweller', 'City-Dweller', 'Truck']
    },
    {
      field: 'releaseYear',
      label: 'Release year',
      restrictedValues: ['2010', '2011', '2012', '2013', '2017', '2018']
    },
    {
      field: 'site',
      label: 'Site',
      restrictedValues: ['Avignon', 'Bruges', 'Bruxelles', 'Gent', 'Paris']
    },
    {
      field: 'gear',
      label: 'Gear',
      restrictedValues: ['Autom.', 'Manual']
    },
    {
      field: 'color',
      label: 'Color',
      restrictedValues: ['blue', 'green', 'red', 'yellow']
    },
    {
      field: 'km',
      label: 'Km',
      restrictedValues: ['1000', '10 000', '100 000', '15 000']
    },
    {
      field: 'agency',
      label: 'Agency',
      restrictedValues: ['Ghent', 'Avignon']
    },
    {
      field: 'nextVisit',
      label: 'Next visit',
      restrictedValues: [
        '2020/12/17 14:30',
        '2019/12/17 15:00',
        '2022/12/17 17:00'
      ]
    }
  ];

  // if there is no parameters, fetch all the fields informations
  // otherwise, only fetch the ones given in parameters
  // TODO cabu : should retrieve an observable
  fetchFieldInfos(fields: string[] = null): FieldInfo[] {
    return fields
      ? this.fieldsInfo.filter(fieldInfo =>
          fields.find(field => fieldInfo.field === field)
        )
      : this.fieldsInfo;
  }
}
