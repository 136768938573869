import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../../helpers/date-util';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ConversationDto } from '../../../../client';
const moment = extendMoment(Moment);

@Pipe({ name: 'toFormattedConversationListDate' })
export class ToFormattedConversationListDatePipe implements PipeTransform {
  transform(conversation: ConversationDto): string {
    if (!conversation.lastMessage) {
      return null;
    }
    const lastMessageDate = DateUtil.convertToDate(
      conversation.lastMessage.timeStamp
    );
    const momentNow = moment();
    const diffInDays = momentNow.diff(moment(lastMessageDate), 'd');
    if (momentNow.isSame(lastMessageDate, 'd')) {
      return moment(lastMessageDate).format('HH:mm');
    } else if (diffInDays < 7) {
      return moment(lastMessageDate).format('dddd[\n]HH:mm');
    }
    return moment(lastMessageDate).format('DD/MM/YYYY[\n]HH:mm');
  }
}
