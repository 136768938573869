import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateHourComponent } from './date-hour.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DateHourComponent],
  exports: [DateHourComponent]
})
export class DateHourModule {}
